import React, { FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';
import { intl } from 'modules/core/i18n/i18n.config';

const StyledLabelWrapper = styled.div`
	margin: 0 0 6px;
`;

const StyledLabel = styled.h3`
	font-weight: ${fonts.weights.medium};
	font-size: ${fonts.sizes.med};
	margin: 0;
`;

const StyledInputContainer = styled.div`
	display: flex;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	overflow: hidden;
`;

const StyledInput = styled.input`
	width: 100%;
	padding: 10px;
	margin: 0;
	border: none;

	&:focus {
		outline: none;
	}
`;

const StyledSubmit = styled.button`
	background: ${brand.primary};
	min-width: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;

	&:disabled {
		opacity: 0.3;
	}
`;

interface IComponentProps {
	label: ReactNode | string;
	placeholder?: string;
	handleSubmit: Function;
	testId?: string;
}

const SuggestedPairingSearch: FunctionComponent<IComponentProps> = ({
	label,
	handleSubmit,
	placeholder = intl.formatMessage({
		id: 'form.fields.suggestedPairingSearch.placeholder',
	}),
	testId,
}) => {
	const [searchTerm, setSearchTerm] = useState<string>('');

	return (
		<>
			<StyledLabelWrapper>
				{typeof label === 'string' ? (
					<StyledLabel>{label}</StyledLabel>
				) : (
					label
				)}
			</StyledLabelWrapper>
			<StyledInputContainer>
				<StyledInput
					data-testid={testId || 'suggestedPairingSearch'}
					placeholder={placeholder}
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							e.preventDefault();
							if (searchTerm.length > 2) {
								handleSubmit(searchTerm);
							}
						}
					}}
				/>
				<StyledSubmit
					data-testid={
						`submit-${testId}` || 'suggestedPairingSearchSubmit'
					}
					type="button"
					disabled={!searchTerm || searchTerm.length <= 2}
					onClick={() => handleSubmit(searchTerm)}
				>
					<Icon name="search" height={12} width={12} colour="white" />
				</StyledSubmit>
			</StyledInputContainer>
		</>
	);
};

export default SuggestedPairingSearch;
