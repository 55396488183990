import MockAdapter from 'axios-mock-adapter/types';

import { IAccount, IAccountFormValues } from './account.types';

import mockPagination from 'components/pagination/pagination.mock';

/** MOCKED DATA */

const accountId: string = 'd387dd41-0949-4d81-8aa0-8866e3403258';

const accounts: IAccount[] = [
	{
		id: '6432245b-33c2-42fa-abaf-d694034b8aa7',
		name: 'MAJOR Cheeto 5',
		logoUrl: null,
		createdAt: '2020-05-15T14:32:28.625Z',
		deletedAt: null,
	},
	{
		id: 'd387dd41-0949-4d81-8aa0-8866e3403258',
		name: 'OrderPay Clients',
		logoUrl: null,
		createdAt: '2020-05-15T12:09:52.578Z',
		deletedAt: null,
	},
	{
		id: '2aa8175c-8354-4e18-9729-2ad2b037384b',
		name: 'The Food Group',
		logoUrl: null,
		createdAt: '2020-06-03T10:47:53.376Z',
		deletedAt: null,
	},
	{
		id: '2aa8175c-8354-4e18-9729-2ad2b034drff',
		name: 'AAA Account',
		logoUrl: null,
		createdAt: '2020-06-03T10:47:53.376Z',
		deletedAt: null,
	},
	{
		id: '2aa8175c-3ded-4e18-9729-2ad2b037384b',
		name: 'ZZZ account',
		logoUrl: null,
		createdAt: '2020-06-03T10:47:53.376Z',
		deletedAt: null,
	},
];

/** MOCKED FORM VALUES */

const accountFormValues: IAccountFormValues = {
	name: 'OrderPay Clients',
	vatNo: 'vatnumb3r',
	logoUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	zonalEstateId: 'sidgjsgjapsj',
};

/** MOCKED ENDPOINTS CONFIG */

const getAllAccounts = {
	response: {
		pagination: mockPagination.data.pagination,
		accounts,
	},
};

// Get single account mock response
const getAccount = {
	response: {
		...accounts[0],
		logoUrl:
			'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
		zonalApiKey: 'ekjfsajgahggg',
		zonalBundleIdentifier: 'osjgsogjoijds',
		zonalUserAgent: 'sidgjsgjapsj',
		zonalEstateId: 'sidgjsgjapsj',
	},
};

const createAccount = {
	request: accountFormValues,
};

const updateAccount = {
	request: {
		id: 'd387dd41-0949-4d81-8aa0-8866e3403258',
		name: 'OrderPay Clients',
		vatNo: 'vatnumb3r',
		logoUrl:
			'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
		zonalApiKey: 'ekjfsajgahggg',
		zonalBundleIdentifier: 'osjgsogjoijds',
		zonalUserAgent: 'sidgjsgjapsj',
		zonalEstateId: 'sidgjsgjapsj',
	},
	response: {
		id: 'd387dd41-0949-4d81-8aa0-8866e3403258',
		name: 'OrderPay Clients',
		vatNo: 'vatnumb3r',
		logoUrl:
			'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
		zonalApiKey: 'ekjfsajgahggg',
		zonalBundleIdentifier: 'osjgsogjoijds',
		zonalUserAgent: 'sidgjsgjapsj',
		zonalEstateId: 'sidgjsgjapsj',
	},
};

/** ENDPOINT REGEX */

// Regex for account/uuid
export const accountEndpointRegex = new RegExp(
	/^account\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

/** MOCKED ENDPOINTS */

export const mockedAccountEndpoints = (mockHttpClient: MockAdapter) => {
	// Mock list accounts response
	mockHttpClient
		.onGet('account')
		.reply(200, mockAccounts.endpoints.getAllAccounts.response);

	// Mock get account response
	mockHttpClient
		.onGet(accountEndpointRegex)
		.reply(200, mockAccounts.endpoints.getAccount.response);

	// Mock create account response
	mockHttpClient
		.onPost('account/create')
		.reply(200, mockAccounts.endpoints.updateAccount.response);

	// Mock update account response
	mockHttpClient
		.onPost(accountEndpointRegex)
		.reply(200, mockAccounts.endpoints.updateAccount.response);

	// Mock delete account response
	mockHttpClient.onDelete(accountEndpointRegex).reply(200);
};

const mockAccounts = {
	data: {
		accountId,
		accounts,
	},
	endpoints: {
		getAllAccounts,
		getAccount,
		createAccount,
		updateAccount,
	},
};
export default mockAccounts;
