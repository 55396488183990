import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { IOrderAuditLog } from 'modules/order/order.types';

// Props for component
interface IComponentProps {
	item: IOrderAuditLog;
}

const StyledOrderItem = styled.li`
	display: flex;
	background: ${brand.neutral};
	overflow: hidden;
	flex-direction: column;
	margin: 5px 0 0 0;
	border-radius: 10px;
`;

const StyledColumnContainer = styled.div`
	margin: 5px 0 0 0;
	padding: 0 0 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	width: 100%;
	max-width: 130px;
	min-height: 56px;
	padding: 15px 0 12px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;
	padding-right: 10px;

	&.mod-alert {
		font-weight: ${fonts.weights.medium};
		color: ${brand.validation_error};
	}
`;

const StyledFullWidthColumn = styled.div`
	background: #fcfcfd;
	width: 100%;
	min-height: 56px;
	padding: 15px 12px;

	&.mod-alert {
		font-weight: ${fonts.weights.medium};
		color: ${brand.validation_error};
	}
`;

const StyledMetadataContainer = styled.pre`
	text-align: left;
	overflow-y: scroll;
	max-height: 400px;
`;

// Orders audit item component
const OrderAuditLogItem: FunctionComponent<IComponentProps> = ({ item }) => {
	const [showDebug, setShowDebug] = useState(false);
	const toggleDebug = () => {
		setShowDebug(!showDebug);
	};
	const getToggleButtonLabel = () => {
		return !showDebug ? 'Show' : 'Hide';
	};

	return (
		<StyledOrderItem key={item.id} aria-label="order-item">
			<StyledColumnContainer>
				<StyledColumn>
					{intl.formatDate(item.dateCreated, {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					})}{' '}
					{intl.formatTime(item.dateCreated, {
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
						hour12: false,
					})}
				</StyledColumn>
				<StyledColumn>{item.logType}</StyledColumn>
				<StyledColumn>{item.source}</StyledColumn>
				<StyledColumn>{item.message}</StyledColumn>
				<StyledColumn>
					{item.metadata && (
						<Button type="button" onClick={() => toggleDebug()}>
							{getToggleButtonLabel()}
						</Button>
					)}
				</StyledColumn>
			</StyledColumnContainer>
			{showDebug && (
				<StyledFullWidthColumn>
					<StyledMetadataContainer>
						{JSON.stringify(item.metadata, null, 4)}
					</StyledMetadataContainer>
				</StyledFullWidthColumn>
			)}
		</StyledOrderItem>
	);
};

export default OrderAuditLogItem;
