import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

export const StyledTable = styled.table`
	width: 100%;
	font-size: ${fonts.sizes.base};
	font-family: inherit;
	margin: 20px 0;
	border: 1px solid ${brand.borders};
	border-collapse: collapse;
	* {
		font-family: inherit;
	}
	> tbody > tr:nth-child(even) {
		background: ${brand.neutral};
	}
	> tbody > tr:nth-child(odd) {
		background: ${brand.white};
	}
	th,
	td {
		height: 44px;
		width: 100px;
		text-align: center;
		padding: 5px 10px;
		border-left: 1px solid ${brand.borders};
		border-top: 0;
		border-bottom: 0;
		&:first-child {
			border-left: none;
		}
		&.stretch {
			width: auto;
			text-align: left;
		}
		&.slim {
			width: 60px;
			text-align: center;
		}
		&.wide {
			width: 120px;
		}
		&.no-padding {
			padding: 0;
		}
	}
	> thead {
		background: ${brand.body};
		> tr {
			background: none;
		}
		> tr:last-child td,
		> tr:last-child th {
			border-bottom: 1px solid ${brand.borders};
		}
	}
`;
