import { FieldProps } from 'formik';
import get from 'lodash.get';
import { rgba } from 'polished';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';

// Interface for component props
interface ITextAreaProps extends HTMLTextAreaElement {
	label?: string;
	isDisabled?: boolean;
	className: string;
	characterLimit?: number;
}

const StyledLabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledCharacterLimit = styled.div`
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
	color: ${rgba(brand.text, 0.4)};

	&.mod-warning {
		color: ${brand.validation_warning};
	}

	&.mod-limit {
		color: ${brand.validation_error};
	}
`;

// Create styled Textarea component
const StyledTextArea = styled.textarea`
	width: 100%;
	min-height: 100px;
	padding: 14px;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	font-size: ${fonts.sizes.standard};

	&:focus {
		border-color: ${brand.link};
		outline: none;
	}

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: ${rgba(brand.black, 0.3)};
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		color: ${rgba(brand.black, 0.3)};
	}
	:-ms-input-placeholder {
		/* IE 10+ */
		color: ${rgba(brand.black, 0.3)};
	}
	:-moz-placeholder {
		/* Firefox 18- */
		color: ${rgba(brand.black, 0.3)};
	}

	&:disabled {
		background: ${brand.formDisabled};
	}
`;

/** Renders Textarea component */
const TextArea: FunctionComponent<FieldProps & ITextAreaProps> = ({
	field,
	form,
	label,
	placeholder,
	isDisabled,
	className,
	characterLimit,
}) => {
	const { touched, errors } = form;
	const isTouched = get(touched, field.name) || false;
	const hasErrors = get(errors, field.name) || false;

	// variables for charcter limit class warnings
	let characterLimitWarning: boolean = false;
	let characterLimitHit: boolean = false;

	// if we have a character limit, set variables correctly
	if (characterLimit) {
		characterLimitWarning = field.value.length >= characterLimit * 0.75;
		characterLimitHit = field.value.length >= characterLimit;
	}

	return (
		<FormElement className={className} isDisabled={isDisabled}>
			<StyledLabelWrapper>
				{label && (
					<StyledLabel htmlFor={field.name}>{label}</StyledLabel>
				)}
				{characterLimit && (
					<StyledCharacterLimit
						className={`${characterLimitWarning && 'mod-warning'} ${
							characterLimitHit && 'mod-limit'
						}`}
					>
						{characterLimit - field.value.length}
					</StyledCharacterLimit>
				)}
			</StyledLabelWrapper>
			<StyledTextArea
				{...field}
				placeholder={placeholder}
				aria-label={`${field.name}-input`}
				className={isTouched && hasErrors ? 'has-error' : ''}
				data-testid="textarea"
				disabled={isDisabled}
			/>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</FormElement>
	);
};

export default TextArea;
