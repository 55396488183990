import get from 'lodash.get';

import { IPagination } from 'app.types';
/** MAIN TYPES */

export interface IAccount {
	createdAt: string;
	deletedAt: string | null;
	id: string;
	logoUrl: string | null;
	name: string;
	vatNo?: string;
	zonalApiKey?: string;
	zonalBundleIdentifier?: string;
	zonalUserAgent?: string;
	zonalEstateId?: string;
}

/** STATE TYPES */

export interface IAccountState {
	accounts: IAccount[];
	activeAccount?: IAccount;
	eventsInProgress: number;
	pagination: IPagination;
}

/** FORM TYPES */

export interface IAccountFormValues {
	name: string;
	vatNo: string;
	logoUrl: string;
	zonalApiKey?: string;
	zonalBundleIdentifier?: string;
	zonalUserAgent?: string;
	zonalEstateId?: string;
}

export interface IAccountFilters {
	pageNumber?: number;
	pageSize?: number;
	search?: string;
	sortOrder?: string;
	sortBy?: string;
}

/** TYPE GUARDS */

export const isIAccountFormValues = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	account: any
): account is IAccountFormValues => {
	const accountFormValuesFields = [
		'name',
		'zonalApiKey',
		'zonalUserAgent',
		'zonalBundleIdentifier',
	];

	let isValid = true;
	accountFormValuesFields.forEach((field) => {
		if (!get(account as IAccountFormValues, field)) {
			isValid = false;
		}
	});

	return isValid;
};
