import queryString from 'query-string';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { getAppUsersList, resetAppUsersState } from '../app-users.slice';
import { IAppUser, IAppUsersFilters } from '../app-users.types';
import AppUsersListItem from './app-users-list-item/app-users-list-item.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Pagination from 'components/pagination/pagination.component';
import SortIcons from 'components/sort-icons/sort-icons.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';

const StyledWrapper = styled.section`
	width: 100%;
	padding: 15px;
	border-radius: 10px;
	background: ${brand.white};

	h3 {
		margin: 0;
	}
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 150px;
	cursor: pointer;

	:focus {
		outline: none;
	}

	&:nth-of-type(1) {
		width: 300px;
	}

	&:nth-of-type(2),
	&:nth-of-type(3) {
		width: 250px;
	}
`;

const StyledIconHeading = styled.div`
	width: 30px;
	min-width: 30px;
	margin: 0 15px 0 auto;
	display: flex;
	align-items: center;
	text-align: center;
	cursor: pointer;

	:focus {
		outline: none;
	}
`;

const StyledAppUsersList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const StyledSortIcons = styled(SortIcons)`
	margin: 0 10px 0 0;
`;

/** Renders app users list component */
const AppUsersList: FunctionComponent = () => {
	// variables for hooks
	const dispatch = useReduxDispatch();
	const location = useLocation();
	const history = useHistory();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get app users list
	const { appUsers, pagination } = useSelector((state: RootState) => {
		return state.appUsers;
	});

	useEffect(() => {
		// Get filters from query params
		const appUserFilters: IAppUsersFilters = {
			search: query.search,
			sortOrder: query.sortOrder,
			sort: query.sort,
			pageNumber: parseFloat(query.pageNumber!) || 1,
		};
		const getData = async () => {
			await dispatch(getAppUsersList(appUserFilters));
		};

		getData();
		// useEffect cleanup
		return () => {
			dispatch(resetAppUsersState());
		};
	}, [dispatch, query.pageNumber, query.search, query.sort, query.sortOrder]);

	/** Empty message component */
	const EmptyMessage: FunctionComponent = () => {
		return (
			<h3 aria-label="app-users-empty">
				<FormattedMessage id="appUsersList.empty" />
			</h3>
		);
	};

	// Function to update sort query params
	const updateSort = (column: keyof IAppUser) => {
		// sort order from query params
		const { sortOrder } = query;
		// variable to store sort direction
		let sortDir;

		if (sortOrder === 'ASC') {
			sortDir = 'DESC';
		} else if (sortOrder === 'DESC') {
			sortDir = '';
		} else {
			sortDir = 'ASC';
		}

		// Create list of filters
		const filters = {
			...query,
			sortOrder: sortDir,
			sort: column,
		};

		// Remove page number when sorting
		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	return (
		<StyledWrapper>
			{appUsers?.length ? (
				<>
					<StyledHeadings>
						<StyledHeading
							data-testid="sort-email"
							onClick={() => updateSort('email')}
							onKeyPress={() => updateSort('email')}
							role="button"
							tabIndex={0}
						>
							<StyledSortIcons
								active={query.sort === 'email'}
								sort={query.sortOrder}
							/>
							<FormattedMessage id="appUsersList.headings.email" />
						</StyledHeading>
						<StyledHeading
							data-testid="sort-name"
							onClick={() => updateSort('firstName')}
							onKeyPress={() => updateSort('firstName')}
							role="button"
							tabIndex={0}
						>
							<StyledSortIcons
								active={query.sort === 'firstName'}
								sort={query.sortOrder}
							/>
							<FormattedMessage id="appUsersList.headings.firstName" />
						</StyledHeading>
						<StyledHeading
							data-testid="sort-lastname"
							onClick={() => updateSort('lastName')}
							onKeyPress={() => updateSort('lastName')}
							role="button"
							tabIndex={0}
						>
							<StyledSortIcons
								active={query.sort === 'lastName'}
								sort={query.sortOrder}
							/>
							<FormattedMessage id="appUsersList.headings.lastName" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="appUsersList.headings.phoneNumber" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="appUsersList.headings.created" />
						</StyledHeading>
						<StyledIconHeading>
							<FormattedMessage id="appUsersList.headings.view" />
						</StyledIconHeading>
					</StyledHeadings>
					<StyledAppUsersList aria-label="app-users-list">
						{appUsers.map((user) => (
							<AppUsersListItem item={user} key={user.id} />
						))}
					</StyledAppUsersList>
					{pagination && pagination.pageCount > 1 && (
						<Pagination
							pageNumber={
								query.pageNumber
									? parseFloat(query.pageNumber)
									: 1
							}
							pageCount={pagination.pageCount}
							setPageNumber={handlePagination}
						/>
					)}
				</>
			) : (
				<EmptyMessage />
			)}
		</StyledWrapper>
	);
};

export default AppUsersList;
