import { IBrandDetails, IBrandDetailsFormValues } from '../brand.types';


export default function formatFullBrandFromFormValues(formValues: IBrandDetailsFormValues): IBrandDetails {
	
	const { ...rest } = formValues;
	// backend expects number values, passing string numbers instead.
	return {
		...rest,
		fontFamily: '',
		fontUrl: '',
		h1: '0',
		h2: '0',
		h3: '0',
		text: '0',
		highlights: '0',
		bgSecondary: '',
	    lblSecondary: '',
		actPrimary: '',
		actSecondary: '',
		actLblPrimary: '',
		actLblSecondary: '',    
		smallText: '0',
	};
}