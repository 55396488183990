import currency from 'currency.js';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { IReportData, TReportData } from '../../report.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

interface IComponentProps {
	reportData?: IReportData[];
}
// Interface for table data
interface ITableData {
	grossTransaction: TReportData[];
	standardRateFees: TReportData[];
	netTransactionVolume: TReportData[];
	grossTipsVolume: TReportData[];
	tipTransactionFees: TReportData[];
	netTipTransactionVolume: TReportData[];
	grossGiftTransactionVolume: TReportData[];
	giftTransactionFees: TReportData[];
	netGiftTransactionVolume: TReportData[];
}

const StyledResults = styled.div`
	display: grid;
	grid-auto-flow: row;
	overflow: auto;
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: flex-end;
	background: ${brand.black};
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	padding: 0 5px;

	> div {
		display: flex;
		align-items: center;
		min-width: 500px;
		margin: 0 10px 0 0;
		user-select: none;
		width: calc(11%);
		font-size: ${fonts.sizes.standard};
		padding: 7px 5px;

		:focus {
			outline: none;
		}

		&:nth-child(1) {
			min-width: 85px;
		}
	}
`;

const StyledHeading = styled.div`
	color: ${brand.white};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.medium};
`;

const StyledData = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		padding: 0 5px;
		margin: 0;
		display: flex;

		&:nth-child(2n + 2) {
			background: ${brand.neutral};
		}

		> div {
			display: flex;
			align-items: center;
			min-width: 500px;
			margin: 0 10px 0 0;
			user-select: none;
			width: calc(11%);
			font-size: ${fonts.sizes.standard};
			padding: 7px 5px;

			:focus {
				outline: none;
			}

			&:nth-child(1) {
				min-width: 85px;
			}
		}
	}
`;

const StyledHeadingTitle = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-left: 1px solid white;
`;

const StyledTitle = styled.div`
	width: 100%;
	text-align: center;
	color: ${brand.white};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.medium};
`;

const StyledHeadingWrapper = styled.div`
	display: flex;
	width: 100%;

	> div {
		width: 20%;
		min-width: 70px;

		&:nth-child(2) {
			min-width: 128px;
			margin-right: 5px;
		}
	}
`;

const StyledDataWrapper = styled.div`
	display: flex;
	width: 100%;
	padding: 0 5px;
	border-left: 1px solid black;

	> div {
		width: 20%;
		min-width: 70px;

		&:nth-child(2) {
			min-width: 128px;
			margin-right: 5px;
		}
	}
`;

const DailyVolumeReportResults: FunctionComponent<IComponentProps> = ({
	reportData,
}) => {
	const headings: string[] = [
		'grossTransVol',
		'standRateTransFees',
		'netTransVol',
		'grossTipTransVol',
		'tipTransFees',
		'netTipTransVol',
		'grossGiftTransVol',
		'giftTransFee',
		'netGiftTransVol',
	];

	const tableData: ITableData = {
		grossTransaction: [
			'grossTransactionAmex',
			'grossTransactionVisaMastercard',
			'grossTransactionCorportate',
			'grossTransactionOther',
			'grossTransactionSubtotal',
		],
		standardRateFees: [
			'feesTransactionAmex',
			'feesTransactionVisaMastercard',
			'feesTransactionCorportate',
			'feesTransactionOther',
			'feesTransactionSubtotal',
		],
		netTransactionVolume: [
			'netTransactionAmex',
			'netTransactionVisaMastercard',
			'netTransactionCorportate',
			'netTransactionOther',
			'netTransactionSubtotal',
		],
		grossTipsVolume: [
			'grossTipsAmex',
			'grossTipsVisaMastercard',
			'grossTipsCorportate',
			'grossTipsOther',
			'grossTipsSubtotal',
		],
		tipTransactionFees: [
			'feesTipsAmex',
			'feesTipsVisaMastercard',
			'feesTipsCorportate',
			'feesTipsOther',
			'feesTipsSubtotal',
		],
		netTipTransactionVolume: [
			'netTipsAmex',
			'netTipsVisaMastercard',
			'netTipsCorportate',
			'netTipsOther',
			'netTipsSubtotal',
		],
		grossGiftTransactionVolume: [
			'grossGiftsAmex',
			'grossGiftsVisaMastercard',
			'grossGiftsCorportate',
			'grossGiftsOther',
			'grossGiftsSubtotal',
		],
		giftTransactionFees: [
			'feesGiftsAmex',
			'feesGiftsVisaMastercard',
			'feesGiftsCorportate',
			'feesGiftsOther',
			'feesGiftsSubtotal',
		],
		netGiftTransactionVolume: [
			'netGiftsAmex',
			'netGiftsVisaMastercard',
			'netGiftsCorportate',
			'netGiftsOther',
			'netGiftsSubtotal',
		],
	};

	const mappedHeadings = headings.map((heading: string) => (
		<StyledHeadingTitle key={heading}>
			<StyledTitle>
				<FormattedMessage id={`reports.results.headings.${heading}`} />
			</StyledTitle>
			<StyledHeadingWrapper>
				<StyledHeading>
					<FormattedMessage id="reports.results.headings.amex" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="reports.results.headings.visaMaster" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="reports.results.headings.corporate" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="reports.results.headings.other" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="reports.results.headings.subtotal" />
				</StyledHeading>
			</StyledHeadingWrapper>
		</StyledHeadingTitle>
	));

	const styledTableData = (data: IReportData) =>
		Object.entries(tableData).map(
			([entry, values]: [string, TReportData[]]) => (
				<StyledDataWrapper key={entry}>
					{values.map((value: TReportData) => (
						<div key={value}>
							{data?.[value]
								? currency(data[value]!).format({
									symbol: '£',
								  })
								: '£0.00'}
						</div>
					))}
				</StyledDataWrapper>
			)
		);

	return (
		<StyledResults>
			<StyledHeadings>
				<StyledHeading>
					<FormattedMessage id="reports.results.headings.date" />
				</StyledHeading>
				{mappedHeadings}
			</StyledHeadings>
			<StyledData>
				{reportData?.map((data) => (
					<li>
						<div>{data?.date}</div>
						{styledTableData(data)}
					</li>
				))}
			</StyledData>
		</StyledResults>
	);
};

export default DailyVolumeReportResults;
