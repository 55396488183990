import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IStaff } from '../../staff.types';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';

const StyledStaffItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 150px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-of-type(1) {
		width: 300px;
	}
`;

const StyledIconColumn = styled.div`
	width: 30px;
	min-width: 30px;
	height: 56px;
	margin: 0 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

// Props for component
interface IStaffListItemProps {
	item: IStaff;
}
// Staff list item component
const StaffListItem: FunctionComponent<IStaffListItemProps> = ({ item }) => {
	return (
		<StyledStaffItem key={item.id} aria-label="staff-item">
			<StyledColumn data-testid="staff-email" data-private>
				{item.email}
			</StyledColumn>
			<StyledColumn data-private>{item.firstName}</StyledColumn>
			<StyledColumn data-private>{item.lastName}</StyledColumn>
			<StyledIconColumn>
				<Link to={`/staff/edit/${item.id}/details`}>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledIconColumn>
		</StyledStaffItem>
	);
};

export default StaffListItem;
