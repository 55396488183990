import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ModifierItemPriceBandItem from './modifier-item-price-band-list-item/modifier-item-price-band-list-item.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import { getModifierItemPriceBands } from 'modules/menu/menu.slice';

const StyledWrapper = styled.section`
	width: 100%;
	padding: 0 0 15px;
`;

const StyledHeader = styled.h2`
	margin-bottom: 0;
`;

const StyledHeaderContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 0 0 20px;
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	user-select: none;

	:focus {
		outline: none;
	}

	&:nth-child(1) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(100% - 75px);
	}

	&:last-child {
		width: 75px;
		min-width: 75px;
		text-align: center;
		margin: 0 15px 0 0;
	}
`;

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const StyledContainer = styled.div`
	margin-bottom: 20px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	modifierItemId?: string;
}

const ModifierItemPriceBandsList: FunctionComponent<IComponentProps> = ({
	modifierItemId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get modifier item price bands from state
	const modifierItemPriceBands = useSelector(
		(state: RootState) => state.menu.activeModifierItemPriceBands
	);

	useEffect(() => {
		const getData = async (priceBandModifierItemId: string) => {
			await dispatch(getModifierItemPriceBands(priceBandModifierItemId));
		};

		if (modifierItemId) {
			getData(modifierItemId);
		}
	}, [dispatch, modifierItemId]);

	const EmptyMessage: FunctionComponent = () => {
		return (
			<h3 aria-label="price-bands-empty">
				<FormattedMessage id="modifierItemPriceBandList.empty" />
			</h3>
		);
	};

	const PriceBandList: FunctionComponent = () => (
		<>
			<StyledHeadings>
				<StyledHeading>
					<FormattedMessage id="modifierItemPriceBandList.heading.name" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="modifierItemPriceBandList.heading.price" />
				</StyledHeading>
			</StyledHeadings>
			<StyledList aria-label="price-bands-list">
				{(modifierItemPriceBands || []).map((modifierItemPriceBand) => (
					<ModifierItemPriceBandItem
						key={modifierItemPriceBand.id}
						item={modifierItemPriceBand}
					/>
				))}
			</StyledList>
		</>
	);

	return (
		<StyledWrapper>
			<StyledHeaderContainer>
				<StyledHeader>
					<FormattedMessage id="modifierItemPriceBandList.headings.priceBands" />
				</StyledHeader>
			</StyledHeaderContainer>
			<StyledContainer>
				{modifierItemPriceBands?.length ? (
					<PriceBandList />
				) : (
					<EmptyMessage />
				)}
			</StyledContainer>
			<StyledActions>
				<Link to={`/menus/products?activeAccount=${activeAccountId}`}>
					<Button variant="secondary">
						<FormattedMessage id="form.button.back" />
					</Button>
				</Link>
			</StyledActions>
		</StyledWrapper>
	);
};

export default ModifierItemPriceBandsList;
