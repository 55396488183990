import { FieldProps } from 'formik';
import get from 'lodash.get';
import React, {
	FunctionComponent,
	ChangeEvent,
	InputHTMLAttributes,
} from 'react';
import styled from 'styled-components';

import FieldLabel from '../field-label/field-label.component';
import { IToolTip } from '../form-tooltip/form-tooltip.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import { intl } from 'modules/core/i18n/i18n.config';

// Interface for component props
interface ISwitchProps {
	label?: string;
	offText?: string;
	onText?: string;
	handleChangeEvent?: Function;
	size?: 'sm';
	borderBottom?: boolean;
	showText?: boolean;
	description?: string;
	toolTip?: IToolTip;
}

interface IStyledComponent {
	size?: 'sm';
}

const StyledSwitchWrapper = styled.div`
	display: flex;
	align-items: center;
`;

// Create styled Switch component
const StyledSwitch = styled.label<IStyledComponent>`
	width: 40px;
	height: 20px;
	padding: 2px;
	margin: 0 10px 0 0;
	border-radius: 50px;
	background: ${brand.body};
	color: ${brand.text};
	position: relative;
	overflow: hidden;
	cursor: pointer;
	user-select: none;
	display: flex;
	transition: color 0.2s ease;
	align-items: center;
	justify-content: flex-start;

	&:after {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: ${brand.white};
		content: '';
		position: absolute;
		transition: all 0.2s ease;
	}

	&.mod-checked {
		background: ${brand.primary};
		color: ${brand.white};

		&:after {
			transform: translateX(20px);
			left: auto;
		}
	}

	input {
		width: 0;
		height: 0;
		opacity: 0;
		cursor: pointer;
		position: absolute;
	}
`;

const StyledSwitchText = styled.div`
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.regular};
`;

const FormattedDescription = styled.p`
	margin: 10px 0;
`;

/** Renders Switch component */
const Switch: FunctionComponent<
	ISwitchProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	field,
	form,
	label,
	offText = intl.formatMessage({ id: 'form.switch.offText.default' }),
	onText = intl.formatMessage({ id: 'form.switch.onText.default' }),
	handleChangeEvent,
	size,
	borderBottom,
	showText = true,
	className,
	description,
	toolTip,
}) => {
	const { touched, errors } = form;
	const isTouched = get(touched, field.name) || false;
	const hasErrors = get(errors, field.name) || false;

	return (
		<FormElement borderBottom={borderBottom} className={className}>
			{label && (
				<FieldLabel
					fieldName={field.name}
					label={label}
					toolTip={toolTip}
				/>
			)}
			<StyledSwitchWrapper>
				<StyledSwitch
					size={size}
					htmlFor={field.name}
					className={field.value === true ? 'mod-checked' : ''}
				>
					<input
						{...field}
						type="checkbox"
						id={field.name}
						aria-label={`${field.name}-switch`}
						data-testid="switch"
						onChange={(
							changeEvent: ChangeEvent<HTMLInputElement>
						) => {
							form.setFieldValue(
								field.name,
								changeEvent.target.value === 'false'
							);
							if (handleChangeEvent) {
								handleChangeEvent({
									fieldValue:
										changeEvent.target.value === 'false',
									form,
								});
							}
						}}
					/>
				</StyledSwitch>
				{showText && (
					<StyledSwitchText>
						{field.value === true ? onText : offText}
					</StyledSwitchText>
				)}
			</StyledSwitchWrapper>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
			{description && (
				<FormattedDescription>{description}</FormattedDescription>
			)}
		</FormElement>
	);
};

export default Switch;
