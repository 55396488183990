import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IReportState,
	IReportFilters,
	IReportQueueSubmitValues,
	EReportTypes,
} from './report.types';

import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';

// Create initial state
export const initialReportState: IReportState = {
	eventsInProgress: 0,
};

const reportSlice = createSlice({
	name: 'report',
	initialState: initialReportState,
	reducers: {
		GET_TRANSACTION_REPORT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_TRANSACTION_REPORT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_TRANSACTION_REPORT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_REMITTANCE_REPORT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_REMITTANCE_REPORT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_REMITTANCE_REPORT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		QUEUE_REPORT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		QUEUE_REPORT_SUCCESS(state, action) {
			return {
				...state,
				queuedReport: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		QUEUE_REPORT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_REPORT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_REPORT_SUCCESS(state, action) {
			return {
				...state,
				activeReport: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_REPORT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_REPORT_PAGINATED(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_REPORT_PAGINATED_SUCCESS(state, action) {
			return {
				...state,
				paginatedReport: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_REPORT_PAGINATED_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_REPORT_STATE() {
			return initialReportState;
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_TRANSACTION_REPORT,
	GET_TRANSACTION_REPORT_FAIL,
	GET_TRANSACTION_REPORT_SUCCESS,
	GET_REMITTANCE_REPORT,
	GET_REMITTANCE_REPORT_FAIL,
	GET_REMITTANCE_REPORT_SUCCESS,
	QUEUE_REPORT,
	QUEUE_REPORT_FAIL,
	QUEUE_REPORT_SUCCESS,
	GET_REPORT,
	GET_REPORT_FAIL,
	GET_REPORT_SUCCESS,
	GET_REPORT_PAGINATED,
	GET_REPORT_PAGINATED_FAIL,
	GET_REPORT_PAGINATED_SUCCESS,
	RESET_REPORT_STATE,
} = reportSlice.actions;

/** Get transaction report */
export const getTransactionReport = (filters: IReportFilters) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_TRANSACTION_REPORT({
			request: {
				method: 'get',
				url: 'payments/transaction-report',
				params: filters,
			},
		})
	);

	if (payload?.data) {
		// Create a file blob from the response
		const blob = new Blob([payload.data], {
			type: payload.headers['content-type'],
		});

		// Create a new link element in the DOM
		const link = document.createElement('a');
		// Set the link to download the file blob
		link.href = window.URL.createObjectURL(blob);
		link.download = 'transactions.csv';
		// Click the link
		link.click();
	} else {
		fireDialog({
			title: intl.formatMessage({
				id: 'reports.transaction.dialogs.noData.title',
			}),
			text: intl.formatMessage({
				id: 'reports.transaction.dialogs.noData.text',
			}),
		});
	}
};

/** Get remittance report */
export const getRemittanceReport = (
	filters: IReportFilters,
	type: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GET_REMITTANCE_REPORT({
			request: {
				method: 'get',
				url: `payments/remittance-report/${type}`,
				params: filters,
			},
		})
	);

	if (payload?.data) {
		// Create a file blob from the response
		const blob = new Blob([payload.data], {
			type: payload.headers['content-type'],
		});

		// Create a new link element in the DOM
		const link = document.createElement('a');
		// Set the link to download the file blob
		link.href = window.URL.createObjectURL(blob);
		link.download = 'remittance.csv';
		// Click the link
		link.click();
	} else {
		fireDialog({
			title: intl.formatMessage({
				id: 'reports.remittance.dialogs.noData.title',
			}),
			text: intl.formatMessage({
				id: 'reports.remittance.dialogs.noData.text',
			}),
		});
	}
};

/** Thunk to queue a report */
export const queueReport = (
	reportType: keyof typeof EReportTypes,
	data: IReportQueueSubmitValues
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		QUEUE_REPORT({
			request: {
				method: 'post',
				url: `reports/${reportType}`,
				data,
			},
		})
	);

	return payload?.data;
};

/** Thunk to get a report */
export const getReport = (reportId: string) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GET_REPORT({
			request: {
				method: 'get',
				url: `reports/${reportId}`,
			},
		})
	);

	return payload?.data;
};

/** Thunk to get a paginated report */
export const getPaginatedReport = (
	reportId: string,
	filters?: IReportFilters
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GET_REPORT_PAGINATED({
			request: {
				method: 'get',
				url: `reports/${reportId}/paginated`,
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** Reset Report State */
export const resetReportState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_REPORT_STATE());
};

export default reportSlice.reducer;
