import currency from 'currency.js';

import { intl } from 'modules/core/i18n/i18n.config';

/**
 * Represents a price band
 */
export interface IPriceBand {
	id: string;
	applyVAT: boolean;
	price: number;
}

/**
 * An object that has either a price or an array
 * of price bands associated with it
 */
export interface IPriceable {
	price?: number;
	priceBands?: IPriceBand[];
}

/**
 * Format a price in pence to its string representation. If the price is `undefined`,
 * it defaults to <<currency symbol>> + '0.00' i.e. £0.00.
 *
 * For example:
 *
 * formatPrice(500) => '£5.00'
 *
 * formatPrice(6050, '$') => '$60.50'
 *
 * @param price The price in pence to be formatted
 * @param currencySymbol The currency symbol to use. Defaults to '£'
 */
export function formatPrice(
	price: number | undefined,
	currencySymbol = '£'
): string {
	return currency(price || 0, { fromCents: true, precision: 2 }).format({
		symbol: currencySymbol,
	});
}

/**
 * Check if the `IPriceable` parameter has 1 or more price bands associated
 * with it.
 *
 * @param priceable An object that has either a `price` and `priceBands` property.
 */
export function hasPriceBands(priceable: IPriceable | undefined): boolean {
	return (priceable?.priceBands || []).length > 0;
}

/**
 * If the `priceable` parameter has multiple price bands, return 'Multiple prices'; otherwise,
 * return the formatted price.
 *
 * @param priceable An object that has either a `price` and `priceBands` property.
 * @param currencySymbol The currency symbol to use. Defaults to '£'
 */
export function formatWithPriceBands(
	priceable: IPriceable,
	currencySymbol = '£'
): string {
	if (hasPriceBands(priceable)) {
		if (priceable.priceBands!.length === 1) {
			return formatPrice(priceable.priceBands![0].price, currencySymbol);
		}

		return intl.formatMessage({
			id: 'prices.multiplePrices',
		});
	}

	return formatPrice(priceable.price, currencySymbol);
}
