import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import paymentsReducer from '../../payment/payment.slice';

import alertReducer from 'components/alert/alert.slice';
import loadingReducer from 'components/loading/loading.slice';
import toastReducer from 'components/toast/toast.slice';
import accountReducer from 'modules/account/account.slice';
import appUsersReducer from 'modules/app-users/app-users.slice';
import authReducer from 'modules/auth/auth.slice';
import beaconReducer from 'modules/beacon/beacon.slice';
import brandReducer from 'modules/brand/brand.slice';
import storeVersionReducer from 'modules/core/state/store-version/store-version.slice';
import importReducer from 'modules/import/import.slice';
import menuReducer from 'modules/menu/menu.slice';
import navReducer from 'modules/navigation/nav.slice';
import operationsReducer from 'modules/operations/operations.slice';
import orderReducer from 'modules/order/order.slice';
import reportReducer from 'modules/report/report.slice';
import staffReducer from 'modules/staff/staff.slice';
import venueMenuValidationReducer from 'modules/venue/slices/venue-menu-validation.slice';
import venueReducer from 'modules/venue/slices/venue.slice';

// Define root reducer
const rootReducer = combineReducers({
	account: accountReducer,
	alert: alertReducer,
	appUsers: appUsersReducer,
	auth: authReducer,
	beacon: beaconReducer,
	brand: brandReducer,
	importer: importReducer,
	loading: loadingReducer,
	menu: menuReducer,
	nav: navReducer,
	operations: operationsReducer,
	order: orderReducer,
	report: reportReducer,
	staff: staffReducer,
	storeVersion: storeVersionReducer,
	toast: toastReducer,
	venue: venueReducer,
	payments: paymentsReducer,
	venueMenuValidation: venueMenuValidationReducer,
});

// Create and export a type for root reducer
export type RootState = ReturnType<typeof rootReducer>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: any, action: AnyAction) => {
	// Create current state varible
	let currentState = state;

	// if logout, set state to undefined
	action.type === 'auth/LOGOUT' && (currentState = undefined);

	// If reset loading states, set events in progress to 0
	action.type === 'loading/RESET_LOADING_STATES' &&
		(currentState = {
			...state,
			account: {
				...state.account,
				eventsInProgress: 0,
			},
			auth: {
				...state.auth,
				eventsInProgress: 0,
				refreshToken: {
					...state.refreshToken,
					refreshing: false,
				},
			},
			beacon: {
				...state.beacon,
				eventsInProgress: 0,
			},
			brand: {
				...state.brand,
				eventsInProgress: 0,
			},
			loading: {
				...state.loading,
				eventsInProgress: 0,
				loadingConfig: {
					loadingMessage: '',
					loadingTimeout: 0,
				},
			},
			importer: {
				...state.importer,
				eventsInProgress: 0,
			},
			menu: {
				...state.menu,
				eventsInProgress: 0,
			},
			operations: {
				...state.operations,
				eventsInProgress: 0,
			},
			order: {
				...state.order,
				eventsInProgress: 0,
			},
			report: {
				...state.report,
				eventsInProgress: 0,
			},
			staff: {
				...state.staff,
				eventsInProgress: 0,
			},
			venue: {
				...state.venue,
				eventsInProgress: 0,
			},
			payments: {
				...state.payments,
				eventsInProgress: 0,
			},
			venueMenuValidation: {
				...state.venueMenuValidation,
				eventsInProgress: 0,
			},
		});

	// return root reducer
	return rootReducer(currentState, action);
};
