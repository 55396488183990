import { intl } from './i18n.config';

export const validationMessages = {
	email: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.email',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	file: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.file',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	hexValue: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.hexValue',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	maxLength: (fieldName: string, length: number) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.maxLength',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
				length,
			}
		),
	minLength: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.minLength',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),

	minNumber: (fieldName: string, min: number) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.minNumber',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
				number: min,
			}
		),
	maxNumber: (fieldName: string, max: number) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.maxNumber',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
				number: max,
			}
		),
	number: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.number',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	required: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.required',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	telephone: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.telephone',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	url: (fieldName: string) =>
		intl.formatMessage(
			{
				id: 'errors.forms.validation.url',
			},
			{
				field: intl
					.formatMessage({
						id: `form.fields.${fieldName}.label`,
					})
					.toLowerCase(),
			}
		),
	genericRequired: (fieldName: string) =>
		intl.formatMessage(
			{ id: 'errors.forms.validation.generic.required' },
			{
				field: intl.formatMessage({
					id: `form.fields.${fieldName}.label`,
				}),
			}
		),
};
