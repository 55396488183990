import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { intl } from 'modules/core/i18n/i18n.config';
import { IOrderTransaction } from 'modules/order/order.types';

// Props for component
interface IComponentProps {
	item: IOrderTransaction;
}

const StyledOrderItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 0 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	width: 100%;
	max-width: 130px;
	min-height: 56px;
	padding: 15px 0 12px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&.mod-alert {
		font-weight: ${fonts.weights.medium};
		color: ${brand.validation_error};
	}

	&:first-child {
		max-width: 270px;
	}

	&:nth-child(4),
	&:nth-child(5) {
		max-width: 80px;
		justify-content: center;
	}

	&:last-of-type {
		max-width: 86px;
	}
`;

// Orders payment item component
const OrderPaymentItem: FunctionComponent<IComponentProps> = ({ item }) => {
	return (
		<StyledOrderItem key={item.id} aria-label="order-item">
			<StyledColumn>{item.cccTransactionId}</StyledColumn>
			<StyledColumn>
				{intl.formatDate(item.createdAt, {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				})}{' '}
				{intl.formatTime(item.createdAt, {
					hour: 'numeric',
					minute: 'numeric',
					second: 'numeric',
					hour12: false,
				})}
			</StyledColumn>
			<StyledColumn>
				{item.status === 0 &&
					intl.formatMessage({
						id: 'orderPayment.item.status.success',
					})}
				{item.status === 1 &&
					intl.formatMessage({
						id: 'orderPayment.item.status.decined',
					})}
				{item.status === 2 &&
					intl.formatMessage({
						id: 'orderPayment.item.status.refunded',
					})}
			</StyledColumn>
			<StyledColumn className={`${item.isReversed && 'mod-alert'}`}>
				{item.isReversed
					? intl.formatMessage({ id: 'orderPayment.item.reversed' })
					: '-'}
			</StyledColumn>
			<StyledColumn>
				{item.status === 2
					? intl.formatMessage({ id: 'orderPayment.item.refunded' })
					: '-'}
			</StyledColumn>
			<StyledColumn>
				{intl.formatNumber(parseFloat(item.total) / 100, {
					style: 'currency',
					currency: 'GBP',
				})}
			</StyledColumn>
		</StyledOrderItem>
	);
};

export default OrderPaymentItem;
