import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getVenueWorkflowRuns } from '../slices/venue-menu-validation.slice';
import { IWorkflowRunDetails } from '../venue-menu-validation.types';

export default function useValidationRuns(venueId: string) {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [validationRuns, setValidationRuns] = useState<
		IWorkflowRunDetails[]
	>();

	/** Async function to get all runs for a venue */
	const getValidationRuns = useCallback(async () => {
		setLoading(true);
		const data = ((await dispatch(
			getVenueWorkflowRuns(venueId)
		)) as unknown) as IWorkflowRunDetails[];
		if (data) {
			setValidationRuns(
				data.slice().sort((a, b) => {
					// Turn your strings into dates, and then subtract them
					// to get a value that is either negative, positive, or zero.
					return (
						new Date(b.validationTestDate).getTime() -
						new Date(a.validationTestDate).getTime()
					);
				})
			);
		}
		setLoading(false);
	}, [dispatch, venueId]);

	// This will trigger the run on the first render, and again if the venue id is changed
	useEffect(() => {
		getValidationRuns();
	}, [getValidationRuns]);

	return {
		loading,
		validationRuns,
		getValidationRuns,
	};
}
