import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IVenueMenuValidationState } from '../venue-menu-validation.types';

export const initialVenueMenuValidationState: IVenueMenuValidationState = {
	eventsInProgress: 0,
	runDetails: undefined,
	previousRuns: undefined,
};

const venueMenuValidationSlice = createSlice({
	name: 'venueMenuValidation',
	initialState: initialVenueMenuValidationState,
	reducers: {
		POST_WORKFLOW_RUN(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		POST_WORKFLOW_RUN_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_WORKFLOW_RUN_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},

		GET_VENUE_WORKFLOW_RUNS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_WORKFLOW_RUNS_SUCCESS(state, action) {
			return {
				...state,
				previousRuns: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_WORKFLOW_RUNS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},

		GET_WORKFLOW_RUN_DETAILS(state, action) {
			return {
				...state,
				runDetails: undefined,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_WORKFLOW_RUN_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				runDetails: action.payload.data,
			};
		},
		GET_WORKFLOW_RUN_DETAILS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

const {
	POST_WORKFLOW_RUN,
	GET_VENUE_WORKFLOW_RUNS,
	GET_WORKFLOW_RUN_DETAILS,
} = venueMenuValidationSlice.actions;

/** Start a validation workflow run */
export const startWorkflowRun = (venueId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		POST_WORKFLOW_RUN({
			request: {
				method: 'post',
				url: 'menu-validation/validate',
				data: { venueId },
			},
		})
	);

	return payload?.data;
};

/** Get a particular menu validation result */
export const getVenueMenuValidationResults = (
	venueId: string,
	runId: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GET_WORKFLOW_RUN_DETAILS({
			request: {
				method: 'get',
				url: `menu-validation/history/${venueId}/test/${runId}`,
			},
		})
	);

	return payload?.data;
};

/** Get the workflow run results for a venue */
export const getVenueWorkflowRuns = (venueId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_VENUE_WORKFLOW_RUNS({
			request: {
				method: 'get',
				url: `menu-validation/history/${venueId}`,
			},
		})
	);
	return payload?.data;
};

export default venueMenuValidationSlice.reducer;
