import MockAdapter from 'axios-mock-adapter/types';

import { IRefundImportValues } from './operations.types';

/** MOCKED DATA */
const refundFormSubmission: IRefundImportValues = {
	refunds: new File(['content'], 'refunds.cvs', { type: 'cvs' }),
};

const importRefunds = {
	response: [
		'row [1] process successfully',
		'row [2] - Unable to find order using TransactionId [BE1F4A57-8109-]',
		'row [3] - Unable to find order using TransactionId [DCC4D7B9-C9D8-]',
		'row [4] - Unable to find order using TransactionId [77265A7A-232D-]',
		'row [5] - Unable to find order using TransactionId [66C28C85-2706-]',
	],
};

/** MOCKED ENDPOINTS */

export const mockedOperationsEndpoints = (mockHttpClient: MockAdapter) => {
	// Mock import refund response
	mockHttpClient
		.onPost('zonal/import/refunds')
		.reply(200, mockOperations.endpoints.importRefunds.response);
};

const mockOperations = {
	data: {
		refundFormSubmission,
	},
	endpoints: { importRefunds },
};
export default mockOperations;
