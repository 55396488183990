import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import App from './app.component';
import { GlobalStyle } from './assets/styles/app';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
	// Initialise logrocket
	LogRocket.init('taaam2/orderpay-cms', {
		release: process.env.REACT_APP_VERSION,
		// Sanitise some network requests
		network: {
			requestSanitizer: (request) => {
				if (request.body) {
					// Set request body as empty on 'login', 'reset' and 'forgot'
					if (
						request.url.toLowerCase().indexOf('/login') !== -1 ||
						request.url.toLowerCase().indexOf('/reset') !== -1 ||
						request.url.toLowerCase().indexOf('/forgot') !== -1
					) {
						request.body = '';
					}

					// Parse body string
					const body = JSON.parse(request.body!);

					// Array of fields to sanitise
					const sanitisedFields = [
						'cccConfig',
						'cccLocationId',
						'email',
						'firstName',
						'lastName',
						'password_confirm',
						'password',
						'vatNo',
						'zonalApiKey',
						'zonalBundleIdentifier',
						'zonalEstateId',
						'zonalSiteId',
						'zonalUserAgent',
					];

					// Loop fields to sanitize
					for (const field of sanitisedFields) {
						// If field exists in request body
						if (body[field]) {
							// Set it to undefined
							body[field] = undefined;
						}
					}

					// Return the modified request
					return {
						...request,
						body: JSON.stringify(body),
					};
				}
				return request;
			},
			responseSanitizer: (response) => {
				if (response.body) {
					// Responses to clear
					if (
						// @ts-ignore
						response.url
							.toLowerCase()
							.indexOf('/transaction-report') !== -1 ||
						// @ts-ignore
						response.url
							.toLowerCase()
							.indexOf('/remittance-report') !== -1
					) {
						// Set response body
						response.body = '';
					}

					// Parse response body string
					const body = JSON.parse(response.body!);

					// Array of fields to sanitise
					const sanitisedFields = [
						'cccConfig',
						'cccLocationId',
						'customerEmail',
						'customerName',
						'email',
						'firstName',
						'lastName',
						'vatNo',
						'zonalApiKey',
						'zonalBundleIdentifier',
						'zonalEstateId',
						'zonalSiteId',
						'zonalUserAgent',
					];

					// Loop fields to sanitize
					for (const field of sanitisedFields) {
						// If field exists in request body
						if (body[field]) {
							// Set it to undefined
							body[field] = undefined;
						}
					}

					// Return modified response
					return {
						...response,
						body: JSON.stringify(body),
					};
				}
				return response;
			},
		},
	});

	// If we have sentry config
	if (process.env.REACT_APP_SENTRY_DSN) {
		// Initialise sentry
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			release: process.env.REACT_APP_VERSION,
		});

		// Get logrocket session URL
		LogRocket.getSessionURL((sessionURL) => {
			// Set sentry scope
			Sentry.configureScope((scope) => {
				// Add logrocket session URL to sentry extra data
				scope.setExtra('sessionURL', sessionURL);
			});
		});
	}
}

/** Render react app */
ReactDOM.render(
	<StrictMode>
		<GlobalStyle />
		<App />
	</StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
