import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import ValidationResultDetails from './validation-result-details.component';

import Button from 'components/button/button.component';
import Icon from 'components/icons/icon.component';
import TimeSince from 'components/time-since/time-since.component';
import { IWorkflowRunDetails } from 'modules/venue/venue-menu-validation.types';

interface IComponentProps {
	run: IWorkflowRunDetails;
	open: boolean;
	onViewDetails: () => void;
}
const ValidationResultItem: FunctionComponent<IComponentProps> = ({
	run,
	open,
	onViewDetails,
}) => {
	return (
		<>
			<tr>
				<td className="stretch">
					{run.validationTestDate && (
						<>
							{new Date(run.validationTestDate).toLocaleString()}{' '}
							(<TimeSince time={run.validationTestDate} />)
						</>
					)}
				</td>
				<td>{run.status}</td>
				<td>{run.validatedItems?.toLocaleString()}</td>
				<td>{run.warnings?.toLocaleString()}</td>
				<td>{run.errors?.toLocaleString()}</td>
				<td className="wide">
					<Button
						variant="secondary"
						onClick={onViewDetails}
						disabled={run.validationResults.length < 1}
					>
						<FormattedMessage
							id={
								open
									? 'menuValidation.validationResults.item.action.closeDetails'
									: 'menuValidation.validationResults.item.action.viewDetails'
							}
						/>
					</Button>
				</td>
				<td className="slim">
					{run.githubRunId && (
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`https://github.com/Checkfer-Limited/Backend-E2E-Tests/actions/runs/${run.githubRunId}`}
						>
							<Icon name="github" width={30} height={30} />
						</a>
					)}
				</td>
			</tr>
			{open && (
				<tr>
					{/* TODO: make this animate open */}
					<td colSpan={7} className="no-padding">
						<ValidationResultDetails
							results={run.validationResults}
						/>
					</td>
				</tr>
			)}
		</>
	);
};

export default ValidationResultItem;
