import React, {
	FunctionComponent,
	useEffect,
	useState,
	useRef,
	RefObject,
} from 'react';

import {
	StyledWrapper,
	StyledIcon,
	StyledToolTip,
} from './form-tooltip.styles';
import isOutOfViewport from './is-out-of-viewport.helper';

export interface IToolTip {
	title: string;
	description: string;
}

const FormToolTip: FunctionComponent<IToolTip> = ({ title, description }) => {
	const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false);
	const [position, setPosition] = useState<'left' | 'right'>('right');
	const toolTipRef = useRef() as RefObject<HTMLDivElement>;

	/* If open, listen for clicks or escape key press and close the tooltip */
	useEffect(() => {
		if (!isToolTipVisible) {
			return;
		}

		// TODO: Add logic to handle resize
		const {
			right: outOfBoundsRight,
			left: outOfBoundsLeft,
		} = isOutOfViewport(toolTipRef.current);

		if (outOfBoundsRight) {
			setPosition('left');
		}
		if (outOfBoundsLeft) {
			setPosition('right');
		}

		const closeOnClick = (): void => {
			setIsToolTipVisible(false);
		};
		window.addEventListener('click', closeOnClick);

		const closeOnEsc = (event: KeyboardEvent): void => {
			if (event.key === 'Escape') {
				setIsToolTipVisible(false);
			}
		};
		window.addEventListener('keydown', closeOnEsc);

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener('click', closeOnClick);
			window.removeEventListener('keydown', closeOnEsc);
		};
	}, [isToolTipVisible, toolTipRef]);

	return (
		<StyledWrapper onClick={() => setIsToolTipVisible(!isToolTipVisible)}>
			<StyledIcon
				name="info"
				height={16}
				width={16}
				colour="borders"
				visible={isToolTipVisible}
			/>
			{isToolTipVisible && (
				<StyledToolTip ref={toolTipRef} position={position}>
					<h5>{title}</h5>
					<p>{description}</p>
				</StyledToolTip>
			)}
		</StyledWrapper>
	);
};

export default FormToolTip;
