import { Formik, Field, Form, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string, ref } from 'yup';

import { deleteStaff, updateStaff, createStaff } from '../../staff.slice';
import { IStaff, IStaffFormValues } from '../../staff.types';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Input from 'components/form-inputs/input/input.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationString } from 'helpers/validation.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IStaffDetailsFormProps {
	history: History;
	staffId: string | undefined;
}

const StaffDetailsForm: FunctionComponent<IStaffDetailsFormProps> = ({
	history,
	staffId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active staff from store
	const activeStaffDetails: IStaff | undefined = useSelector(
		(state: RootState) => state.staff.activeStaffDetails
	);

	// Initial form values
	const initialValues: IStaffFormValues = {
		email: (staffId && activeStaffDetails?.email) || '',
		firstName: (staffId && activeStaffDetails?.firstName) || '',
		lastName: (staffId && activeStaffDetails?.lastName) || '',
		avatarUrl: (staffId && activeStaffDetails?.avatarUrl) || '',
		password: '',
		password_confirm: '',
	};

	// Interface for form errors
	interface IFormValidation {
		email?: string;
		firstName?: string;
		lastName?: string;
	}

	// Validation schema for update form
	const updateSchema = {
		email: genericValidationString({ fieldName: 'email' }).email(
			validationMessages.email('email')
		),
		firstName: genericValidationString({ fieldName: 'firstName' }),
		lastName: genericValidationString({ fieldName: 'lastName' }),
	};

	// Validation schema for create form
	const createSchema = {
		...updateSchema,
		password: string().required(validationMessages.required('password')),
		password_confirm: string()
			.required(validationMessages.required('password'))
			.oneOf(
				[ref('password'), null],
				intl.formatMessage({
					id: 'errors.forms.passwordConfirm.match',
				})
			),
	};

	// Validation schema
	const formValidationSchema = object<IFormValidation>().shape(
		staffId ? updateSchema : createSchema
	);

	// Handle form submission
	const handleSubmit = async (
		values: IStaffFormValues,
		{ setSubmitting }: FormikHelpers<IStaffFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Create / update
		const response = await (staffId
			? dispatch(updateStaff(staffId, values))
			: dispatch(createStaff(values)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: staffId
					? intl.formatMessage({
						id: 'staffForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'staffForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// @ts-ignore
		!staffId && history.push(`/staff/edit/${response?.id}/details`);
	};

	// Handle deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'staffForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'staffForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete action
		const response = staffId && (await dispatch(deleteStaff(staffId)));
		// Return if fail
		if (!response) {
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'staffForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to staff
		history.push('/staff');
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledFormFields>
						<StyledColumn>
							<h2>
								<FormattedMessage id="staffForm.headings.details" />
							</h2>
							<Field
								component={Input}
								name="firstName"
								label={intl.formatMessage({
									id: 'form.fields.firstName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.firstName.label',
								})}
								isPrivate={true}
							/>
							<Field
								component={Input}
								name="lastName"
								label={intl.formatMessage({
									id: 'form.fields.lastName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.lastName.label',
								})}
								isPrivate={true}
							/>
							<Field
								component={Input}
								name="email"
								type="email"
								label={intl.formatMessage({
									id: 'form.fields.email.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.email.label',
								})}
								isPrivate={true}
								autoComplete="new-password"
							/>
							{!staffId && (
								<>
									<h2>
										<FormattedMessage id="staffForm.headings.password" />
									</h2>
									<Field
										component={Input}
										name="password"
										type="password"
										label={intl.formatMessage({
											id: 'form.fields.password.label',
										})}
										placeholder={intl.formatMessage({
											id: 'form.fields.password.label',
										})}
										isPrivate={true}
										autoComplete="new-password"
									/>
									<Field
										component={Input}
										name="password_confirm"
										type="password"
										label={intl.formatMessage({
											id:
												'form.fields.passwordConfirm.label',
										})}
										placeholder={intl.formatMessage({
											id:
												'form.fields.passwordConfirm.label',
										})}
										isPrivate={true}
										autoComplete="new-password"
									/>
								</>
							)}
						</StyledColumn>
						{/* TODO: Unhide when image upload endpoint available */}
						{
							// @ts-ignore
							false && (
								<StyledColumn>
									<StyledFieldGroup>
										<StyledColumn>
											<Field
												component={ImageUpload}
												name="avatarUrl"
												label={intl.formatMessage({
													id:
														'form.fields.avatarUrl.label',
												})}
												placeholder={intl.formatMessage(
													{
														id:
															'form.fields.avatarUrl.label',
													}
												)}
												// TODO: Correct upload endpoint
												uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/user/images/avatar`}
											/>
										</StyledColumn>
									</StyledFieldGroup>
								</StyledColumn>
							)
						}
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link to="/staff">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{staffId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="staffForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							ariaLabel="submit-button"
							disabled={isSubmitting}
						>
							<FormattedMessage
								id={
									staffId
										? 'form.button.save'
										: 'staffForm.button.create'
								}
							/>
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default StaffDetailsForm;
