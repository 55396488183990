import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import { ISuggestedPairingListItem } from 'modules/menu/menu.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px 0 0;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-child(1) {
		margin-left: 20px;
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		display: flex;
		justify-content: center;
	}
`;

// Props for component
interface IComponentProps {
	item: ISuggestedPairingListItem;
	activeAccountId: string;
}

// Suggested pairing list item component
const SuggestedPairingListItem: FunctionComponent<IComponentProps> = ({
	item,
	activeAccountId,
}) => {
	return (
		<StyledListItem key={item.id} aria-label="suggested-pairing-list-item">
			<StyledColumn>{item.name}</StyledColumn>
			<StyledColumn>
				<Link
					to={`/menus/suggested-pairing/edit/${item.id}?activeAccount=${activeAccountId}`}
				>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledListItem>
	);
};

export default SuggestedPairingListItem;
