import { Store, Dispatch, AnyAction } from 'redux';

import { history } from '../routing/app-router.component';

import { REFRESH, processLogout } from 'modules/auth/auth.slice';

// Refresh token middleware
export default (store: Store) => (next: Dispatch) => async (
	action: AnyAction
) => {
	// Get access + refresh token expiry times from state
	const accessTokenExpiry = store.getState().auth.accessToken.expiry;
	const refreshTokenExpiry = store.getState().auth.refreshToken.expiry;

	// If refresh token has expired
	if (
		action.type !== 'auth/LOGOUT' &&
		refreshTokenExpiry !== null &&
		new Date().getTime() / 1000 >= refreshTokenExpiry
	) {
		// @ts-ignore // Call logout thunk
		await store.dispatch(processLogout());
		// Redirect user to login
		history.push('/');
		return false;
	}

	// If action is a request (and not refresh request) and we have an access token
	if (
		action.type !== 'auth/REFRESH' &&
		(((action || {}).payload || {}).request || {}).url &&
		accessTokenExpiry !== null
	) {
		// Check if access token is expired
		if (new Date().getTime() / 1000 >= accessTokenExpiry) {
			// If access token isn't currently refreshing
			if (!store.getState().auth.refreshToken.refreshing) {
				// Create auth/refresh request
				await store.dispatch(
					REFRESH({
						request: {
							method: 'post',
							url: 'auth/refresh',
						},
					})
				);
			}
		}
	}
	return next(action);
};
