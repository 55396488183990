import { Formik, Form, FormikHelpers, Field } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Radio from 'components/form-inputs/radio/radio.component';
import Switch from 'components/form-inputs/switch/switch.component';
import {
	convertToMinutes,
	convertToSeconds,
} from 'helpers/time-conversion.helpers';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueServiceTippingConfig,
	updateVenueServiceTippingConfig,
} from 'modules/venue/slices/venue.slice';
import {
	EPercentageIncrement,
	IVenueServiceTippingConfigFormValues,
	IVenueServiceTippingConfigSubmitValues,
} from 'modules/venue/venue.types';

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 0 0 25px;
	padding: 0 0 25px;
	border-bottom: 1px solid ${brand.body};

	&.flex-start {
		justify-content: flex-start;
		align-items: center;
	}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledLabel = styled.label<{ margin?: boolean }>`
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};

	${({ margin }) => margin && 'margin-bottom: 5px;'};
`;

const StyledShortInput = styled(Input)`
	display: inline-block;
	max-width: 60px;
	max-height: 32px;
	margin: 0 12px 0 0;

	input {
		height: 32px;
		line-height: 32px;
		padding: 0 8px;
		text-align: center;
	}
`;

const StyledRadioWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	border-bottom: 1px solid ${brand.body};
	margin: 0 0 25px;
`;

const StyledRadio = styled(Radio)`
	min-width: 145px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	activeService: string;
	handleDelete: Function;
	deleteServiceButtonText: string;
	formSubmission: boolean;
	setFormSubmission: Function;
	venueId: string;
}

const VenueServiceConfigTippingForm: FunctionComponent<IComponentProps> = ({
	handleDelete,
	deleteServiceButtonText,
	formSubmission,
	setFormSubmission,
	venueId,
	activeService,
}) => {
	const dispatch = useReduxDispatch();

	// Get active config from state
	const tipping = useSelector(
		(state: RootState) => state.venue.activeVenueServiceConfig?.tipping
	);

	useEffect(() => {
		const getData = async (id: string) => {
			await dispatch(getVenueServiceTippingConfig(id, activeService));
		};

		venueId && getData(venueId);
	}, [dispatch, venueId, activeService]);

	// Get inital values from state
	const initialValues: IVenueServiceTippingConfigFormValues = {
		percentageIncrementOne:
			tipping?.tippingConfig?.percentageIncrementOne?.toString() || '5',
		percentageIncrementTwo:
			tipping?.tippingConfig?.percentageIncrementTwo?.toString() || '10',
		percentageIncrementThree:
			tipping?.tippingConfig?.percentageIncrementThree?.toString() ||
			'15',
		defaultPercentageIncrement:
			tipping?.tippingConfig?.defaultPercentageIncrement || null,
		tippingEnabled: tipping?.tippingConfig?.tippingEnabled || false,
		tippingEnabledPaysheet:
			tipping?.tippingConfig?.tippingEnabledPaysheet || false,
		notificationTitle: tipping?.tippingConfig?.notificationTitle || '',
		notificationMessage: tipping?.tippingConfig?.notificationMessage || '',
		thankYouMessage: tipping?.tippingConfig?.thankYouMessage || '',
		visitExpiryTimeSeconds:
			(tipping?.visitExpiryTimeSeconds &&
				convertToMinutes(tipping.visitExpiryTimeSeconds).toString()) ||
			'',
		firstNotificationTimeSeconds:
			(tipping?.tippingConfig?.firstNotificationTimeSeconds &&
				convertToMinutes(
					tipping.tippingConfig.firstNotificationTimeSeconds
				).toString()) ||
			'',
		delayNotificationTimeSeconds:
			(tipping?.tippingConfig?.delayNotificationTimeSeconds &&
				convertToMinutes(
					tipping.tippingConfig?.delayNotificationTimeSeconds
				).toString()) ||
			'',
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenueServiceTippingConfigFormValues,
		{ setSubmitting }: FormikHelpers<IVenueServiceTippingConfigFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Update venue service tipping config
		const submitValues: IVenueServiceTippingConfigSubmitValues = {
			...values,
			percentageIncrementOne: parseFloat(values.percentageIncrementOne),
			percentageIncrementTwo: parseFloat(values.percentageIncrementTwo),
			percentageIncrementThree: parseFloat(
				values.percentageIncrementThree
			),
			visitExpiryTimeSeconds: convertToSeconds(
				Number(values.visitExpiryTimeSeconds)
			),
			firstNotificationTimeSeconds: convertToSeconds(
				Number(values.firstNotificationTimeSeconds)
			),
			delayNotificationTimeSeconds: convertToSeconds(
				Number(values.delayNotificationTimeSeconds)
			),
		};

		const response = await dispatch(
			updateVenueServiceTippingConfig(
				venueId,
				activeService,
				submitValues
			)
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting, values }) => (
				<Form>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="venueForm.services.tipping.title" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<StyledLabel margin>
								<FormattedMessage id="form.fields.tipAmounts.label" />
							</StyledLabel>
							<StyledFieldGroup className="flex-start">
								<Field
									component={StyledShortInput}
									name="percentageIncrementOne"
									placeholder="0"
									inputSymbol="%"
									inputSymbolPlacement="right"
								/>
								<Field
									component={StyledShortInput}
									name="percentageIncrementTwo"
									placeholder="0"
									inputSymbol="%"
									inputSymbolPlacement="right"
								/>
								<Field
									component={StyledShortInput}
									name="percentageIncrementThree"
									placeholder="0"
									inputSymbol="%"
									inputSymbolPlacement="right"
								/>
							</StyledFieldGroup>
							<StyledLabel margin>
								<FormattedMessage id="form.fields.defaultTip.label" />
							</StyledLabel>
							<StyledRadioWrapper>
								<Field
									component={StyledRadio}
									name="defaultPercentageIncrement"
									id="defaultPercentageIncrement-0"
									value={null}
									label={intl.formatMessage({
										id:
											'form.fields.defaultTipPercentage.label.none',
									})}
								/>
								<Field
									component={StyledRadio}
									name="defaultPercentageIncrement"
									id="defaultPercentageIncrement-1"
									value={
										EPercentageIncrement.percentageIncrementOne
									}
									label={intl.formatMessage(
										{
											id:
												'form.fields.defaultTipPercentage.label.percent',
										},
										{ value: values.percentageIncrementOne }
									)}
								/>
								<Field
									component={StyledRadio}
									name="defaultPercentageIncrement"
									id="defaultPercentageIncrement-2"
									value={
										EPercentageIncrement.percentageIncrementTwo
									}
									label={intl.formatMessage(
										{
											id:
												'form.fields.defaultTipPercentage.label.percent',
										},
										{ value: values.percentageIncrementTwo }
									)}
								/>
								<Field
									component={StyledRadio}
									name="defaultPercentageIncrement"
									id="defaultPercentageIncrement-3"
									value={
										EPercentageIncrement.percentageIncrementThree
									}
									label={intl.formatMessage(
										{
											id:
												'form.fields.defaultTipPercentage.label.percent',
										},
										{
											value:
												values.percentageIncrementThree,
										}
									)}
								/>
							</StyledRadioWrapper>
							<Field
								component={Switch}
								name="tippingEnabledPaysheet"
								label={intl.formatMessage({
									id:
										'form.fields.tippingEnabledPaysheet.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Switch}
								name="tippingEnabled"
								label={intl.formatMessage({
									id: 'form.fields.tippingEnabled.label',
								})}
								borderBottom
							/>
							<Field
								component={Input}
								name="notificationTitle"
								label={intl.formatMessage({
									id: 'form.fields.notificationTitle.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.notificationTitle.label',
								})}
								borderBottom
							/>
							<Field
								component={Input}
								name="notificationMessage"
								label={intl.formatMessage({
									id: 'form.fields.notificationMessage.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.notificationMessage.label',
								})}
								borderBottom
							/>
							<StyledLabel margin>
								<FormattedMessage id="form.fields.visitExpiryTimeSeconds.label" />
							</StyledLabel>
							<StyledFieldGroup className="flex-start">
								<Field
									component={StyledShortInput}
									name="visitExpiryTimeSeconds"
									placeholder={0}
								/>
								<StyledLabel>
									<FormattedMessage id="form.fields.minutes.label" />
								</StyledLabel>
							</StyledFieldGroup>
							<StyledLabel margin>
								<FormattedMessage id="form.fields.firstNotificationTimeSeconds.label" />
							</StyledLabel>
							<StyledFieldGroup className="flex-start">
								<Field
									component={StyledShortInput}
									name="firstNotificationTimeSeconds"
									placeholder={0}
								/>
								<StyledLabel>
									<FormattedMessage id="form.fields.minutesAfterVisit.label" />
								</StyledLabel>
							</StyledFieldGroup>
							<StyledLabel margin>
								<FormattedMessage id="form.fields.delayNotificationTimeSeconds.label" />
							</StyledLabel>
							<StyledFieldGroup className="flex-start">
								<Field
									component={StyledShortInput}
									name="delayNotificationTimeSeconds"
									placeholder={0}
								/>
								<StyledLabel>
									<FormattedMessage id="form.fields.minutesAfterFirstPrompt.label" />
								</StyledLabel>
							</StyledFieldGroup>
							<Field
								component={Input}
								name="thankYouMessage"
								label={intl.formatMessage({
									id: 'form.fields.thankYouMessage.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.thankYouMessage.label',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							<Button
								variant="secondary"
								onClick={() => handleDelete()}
								ariaLabel="delete-button"
							>
								{deleteServiceButtonText}
							</Button>
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</Form>
			)}
		</Formik>
	);
};

export default VenueServiceConfigTippingForm;
