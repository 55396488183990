import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import VenueServiceConfigCreateForm from './venue-service-config-create-form/venue-service-config-create-form.component';
import VenueServiceConfigEdit from './venue-service-config-edit/venue-service-config-edit.component';

import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueServiceGeneralConfig,
	resetVenueState,
} from 'modules/venue/slices/venue.slice';

interface IComponentProps {
	venueId: string;
	activeService: string;
}

// Venue service config component
const VenueServiceConfig: FunctionComponent<IComponentProps> = ({
	venueId,
	activeService,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	// Get active service general config from state
	const generalConfig = useSelector(
		(state: RootState) => state.venue.activeVenueServiceConfig?.general
	);

	useEffect(() => {
		// Get venue data by venue id
		const getVenueData = async (id: string) => {
			await dispatch(getVenueServiceGeneralConfig(id, activeService));
		};

		venueId && getVenueData(venueId);

		return () => {
			dispatch(resetVenueState());
		};
	}, [dispatch, venueId, activeService]);

	return generalConfig && Object.keys(generalConfig).length > 0 ? (
		<VenueServiceConfigEdit {...{ venueId, activeService }} />
	) : (
		<VenueServiceConfigCreateForm {...{ venueId, activeService }} />
	);
};

export default VenueServiceConfig;
