import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';

export interface IToolTipProps {
	position: 'left' | 'right';
}

export const StyledWrapper = styled.div`
	position: relative;
	cursor: pointer;
`;

export const StyledIcon = styled(Icon)<{ visible: boolean }>`
	cursor: pointer;

	svg {
		color: ${brand.borders};
	}

	&:hover svg {
		color: ${brand.primary};
	}

	${(props) => props.visible && `svg { color: ${brand.primary};}`}
`;

const triangleBeforeStyles = css`
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -5px;
		width: 0;
		height: 0;
		border-top: 11px solid transparent;
		border-bottom: 11px solid transparent;
		border-right: 11px solid ${brand.tooltipBackground};
	}
`;

const triangleAfterStyles = css`
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%) rotate(180deg);
		right: -5px;
		width: 0;
		height: 0;
		border-top: 11px solid transparent;
		border-bottom: 11px solid transparent;
		border-right: 11px solid ${brand.tooltipBackground};
	}
`;

export const StyledToolTip = styled.div<IToolTipProps>`
	position: absolute;
	${({ position }) => (position === 'left' ? 'right: 20px' : 'left: 20px')};
	top: 50%;
	transform: translateY(-50%);
	border-radius: 5px;
	background: ${brand.tooltipBackground};
	width: 205px;
	padding: 15px;
	z-index: 5;

	${({ position }) =>
		position === 'left' ? triangleAfterStyles : triangleBeforeStyles}

	h5 {
		margin: 0 0 5px;
		color: ${brand.white};
		font-size: ${fonts.sizes.small};
		font-weight: ${fonts.weights.semibold};
	}

	p {
		margin: 0;
		color: ${brand.white};
		font-size: ${fonts.sizes.small};
		font-weight: ${fonts.weights.regular};
		line-height: ${fonts.sizes.standard};
	}
`;
