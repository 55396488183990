import { FieldProps } from 'formik';
import { rgba } from 'polished';
import React, { FunctionComponent, ReactHTMLElement } from 'react';
// eslint-disable-next-line import/no-named-default
import { Theme } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { isArray } from 'util';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';
import { intl } from 'modules/core/i18n/i18n.config';

export interface IOption {
	label?: string;
	value?: string | boolean | number;
}

// Interface for component props
interface ISelectProps extends ReactHTMLElement<HTMLSelectElement> {
	label?: string;
	handleChangeEvent?: Function;
	hideInitialValue?: boolean;
	initialValue?: string;
	isClearable?: boolean;
	isDisabled?: boolean;
	isMulti?: boolean;
	placeholder?: string;
	value?: IOption | IOption[];
	formElementVariant?: 'header';
	size?: 'sm';
	testId?: string;
	className?: string;
	loadOptions?: Function;
}

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledAsyncSelect = styled(AsyncSelect)<ISelectProps>`
	width: 100%;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	font-size: ${fonts.sizes.standard};

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}

	[class$='control'] {
		min-height: ${({ size }) => (size === 'sm' ? '35px' : '40px')};
		border: none;
		border-radius: 5px;
		box-shadow: none;
	}

	.react-select__option--is-selected {
		font-weight: ${fonts.weights.medium};
	}

	.react-select__multi-value,
	.react-select__multi-value__label {
		border-radius: 5px;
		font-size: 100%;
	}

	.react-select__indicator {
		padding: 6px 8px;
	}

	.react-select__indicator-separator {
		display: none;
	}

	.react-select__indicators {
		width: 30px;
		svg {
			display: none;
		}
	}
`;

const StyledIcon = styled(Icon)`
	height: 100%;
	position: absolute;
	right: 10px;
	bottom: 0;
`;

/** Renders predictive search component */
const PredictiveSearch: FunctionComponent<FieldProps & ISelectProps> = ({
	field,
	form,
	label,
	isClearable,
	isDisabled,
	isMulti,
	placeholder,
	handleChangeEvent,
	formElementVariant,
	value,
	size,
	testId,
	className,
	loadOptions,
}) => {
	const { touched, errors } = form;
	const { name } = field;
	const isTouched = touched?.[`${field.name}`] || false;
	const hasErrors = errors?.[`${field.name}`] || false;

	return (
		<FormElement
			variant={formElementVariant}
			testId={testId}
			className={className}
		>
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledAsyncSelect
				{...name}
				size={size}
				classNamePrefix="react-select"
				className={isTouched && hasErrors && 'has-error'}
				isMulti={isMulti}
				isClearable={isClearable}
				isDisabled={isDisabled}
				noOptionsMessage={() =>
					intl.formatMessage({
						id: 'form.fields.predictiveSearch.noResults.default',
					})}
				aria-label={`predictive-search-${field.name}`}
				loadOptions={loadOptions}
				placeholder={
					placeholder ||
					intl.formatMessage({ id: 'form.search.placeholder' })
				}
				onChange={(option: IOption | IOption[]) => {
					// Update formik field
					form.setFieldValue(
						field.name,
						isArray(option) ? option : option?.value
					);

					// Set field as touched within timeout due to formik race condition
					setTimeout(
						() => form.setFieldTouched(field.name, true, true),
						1
					);

					// Run custom change event function
					handleChangeEvent &&
						handleChangeEvent({
							fieldValue: isArray(option)
								? option
								: option?.value,
							form,
						});
				}}
				theme={(theme: Theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: rgba(brand.primary, 0.25),
						primary75: rgba(brand.primary, 0.75),
						primary50: rgba(brand.primary, 0.5),
						primary: brand.primary,
					},
				})}
				value={value}
			/>
			<StyledIcon name="search" width={12} height={12} />
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</FormElement>
	);
};

export default PredictiveSearch;
