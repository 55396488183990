import React, { FunctionComponent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import VenueServiceConfigAvailabilityForm from './venue-service-config-availability/venue-service-config-availability-form.component';
import VenueServiceGeneralConfigForm from './venue-service-config-general/venue-service-config-general-form.component';
import VenueServiceConfigPosForm from './venue-service-config-pos/venue-service-config-pos-form.component';
import VenueServiceConfigServiceChargeForm from './venue-service-config-service-charge/venue-service-config-service-charge-form.component';
import VenueServiceConfigTippingForm from './venue-service-config-tipping/venue-service-config-tipping-form.component';

import { addAlert } from 'components/alert/alert.slice';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { deleteVenueServiceConfig } from 'modules/venue/slices/venue.slice';

const StyledTabList = styled(TabList)`
	margin: 0 0 35px;
`;

interface IParams {
	tertiaryTab?: string;
}

interface IComponentprops {
	venueId: string;
	activeService: string;
}

const VenueServiceConfigEdit: FunctionComponent<IComponentprops> = ({
	venueId,
	activeService,
}) => {
	const history = useHistory();
	const dispatch = useReduxDispatch();
	const { tertiaryTab }: IParams = useParams();
	const [activeTab, setActiveTab] = useState(tertiaryTab || 'table-service');

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	const handleDelete = async () => {
		// Confirm user wishes to delete the service config
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'venueForm.serviceConfig.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'venueForm.serviceConfig.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel do nothing
		if (!confirmDelete.value) {
			return;
		}

		// Delete venue service action
		venueId &&
			(await dispatch(deleteVenueServiceConfig(venueId, activeService)));

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.serviceConfig.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect to current service
		history.push(`/venues/edit/${venueId}/details/details`);
	};

	// Set delete service button intl string
	let serviceText = '';
	if (activeService === 'table-service') {
		serviceText = intl.formatMessage({
			id: 'venueForm.tabs.services.secondary.tableService',
		});
	} else if (activeService === 'order-ahead') {
		serviceText = intl.formatMessage({
			id: 'venueForm.tabs.services.secondary.orderAhead',
		});
	} else if (activeService === 'self-service') {
		serviceText = intl.formatMessage({
			id: 'venueForm.tabs.services.secondary.selfService',
		});
	}

	const deleteServiceButtonText = intl.formatMessage(
		{
			id: 'venueForm.headings.serviceConfig.button.delete',
		},
		{ service: serviceText }
	);

	return (
		<>
			<StyledTabList
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				navigateOnClick={true}
				history={history}
				parentUrl={`/venues/edit/${venueId}/services/${activeService}/`}
				tabStyle="button"
				tabs={[
					{
						id: 'general',
						title: intl.formatMessage({
							id: 'venueForm.tabs.services.tertiary.general',
						}),
					},
					{
						id: 'pos',
						title: intl.formatMessage({
							id: 'venueForm.tabs.services.tertiary.pos',
						}),
					},
					{
						id: 'availability',
						title: intl.formatMessage({
							id: 'venueForm.tabs.services.tertiary.availability',
						}),
					},
					{
						id: 'tipping',
						title: intl.formatMessage({
							id: 'venueForm.tabs.services.tertiary.tipping',
						}),
					},
					{
						id: 'service-charge',
						title: intl.formatMessage({
							id:
								'venueForm.tabs.services.tertiary.serviceCharge',
						}),
					},
					{
						id: 'feedback',
						title: intl.formatMessage({
							id: 'venueForm.tabs.services.tertiary.feedback',
						}),
						isHidden: true,
					},
				]}
			/>
			{venueId && activeTab === 'general' && (
				<VenueServiceGeneralConfigForm
					activeService={activeService}
					handleDelete={handleDelete}
					deleteServiceButtonText={deleteServiceButtonText}
					formSubmission={formSubmission}
					setFormSubmission={setFormSubmission}
					venueId={venueId}
				/>
			)}
			{venueId && activeTab === 'pos' && (
				<VenueServiceConfigPosForm
					activeService={activeService}
					handleDelete={handleDelete}
					deleteServiceButtonText={deleteServiceButtonText}
					formSubmission={formSubmission}
					setFormSubmission={setFormSubmission}
					venueId={venueId}
				/>
			)}
			{venueId && activeTab === 'availability' && (
				<VenueServiceConfigAvailabilityForm
					activeService={activeService}
					handleDelete={handleDelete}
					deleteServiceButtonText={deleteServiceButtonText}
					formSubmission={formSubmission}
					setFormSubmission={setFormSubmission}
					venueId={venueId}
				/>
			)}
			{venueId && activeTab === 'tipping' && (
				<VenueServiceConfigTippingForm
					activeService={activeService}
					handleDelete={handleDelete}
					deleteServiceButtonText={deleteServiceButtonText}
					formSubmission={formSubmission}
					setFormSubmission={setFormSubmission}
					venueId={venueId}
				/>
			)}
			{venueId && activeTab === 'service-charge' && (
				<VenueServiceConfigServiceChargeForm
					activeService={activeService}
					handleDelete={handleDelete}
					deleteServiceButtonText={deleteServiceButtonText}
					formSubmission={formSubmission}
					setFormSubmission={setFormSubmission}
					venueId={venueId}
				/>
			)}
			{venueId && activeTab === 'feedback' && (
				<div>feedback - under construction</div>
			)}
		</>
	);
};

export default VenueServiceConfigEdit;
