import get from 'lodash.get';

export interface IItemSelect {
	id: string;
	title: string;
	disabled: boolean;
	disabledMessage?: string;
}

export interface IItemSelectGroup {
	title: string;
	items: IItemSelect[];
}

/** TYPE GUARDS */
export const isIItemSelectGroup = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: any
): items is IItemSelectGroup[] => {
	const itemSelectGroupFields = ['title', 'items'];

	if (items.length === 0) {
		return false;
	}

	let isValid = true;
	itemSelectGroupFields.forEach((field) => {
		if (!get(items[0] as IItemSelectGroup, field)) {
			isValid = false;
		}
	});

	return isValid ? items : false;
};
