import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

interface ITooltipProps {
	arrow: 'top' | 'right' | 'bottom' | 'left';
	position?: {
		top?: string;
		right?: string;
		bottom?: string;
		left?: string;
	};
	testId?: string;
	text: string;
	title?: string;
	hidden?: boolean;
}

const StyledWrapper = styled.div`
	position: relative;

	.tooltip {
		opacity: 0;
		transition: all 0.2s ease-in;
	}

	&:hover .tooltip {
		opacity: 1;
	}
`;

const arrowStyling = (position: 'top' | 'right' | 'bottom' | 'left') => {
	let placement = '';
	let offset = '';
	let margin = '';
	let border = '';

	if (position === 'top') {
		placement = 'bottom';
		offset = 'left';
		margin = 'margin-left';
		border = 'border-bottom-color';
	}
	if (position === 'right') {
		placement = 'left';
		offset = 'top';
		margin = 'margin-top';
		border = 'border-left-color';
	}
	if (position === 'bottom') {
		placement = 'top';
		offset = 'left';
		margin = 'margin-left';
		border = 'border-top-color';
	}
	if (position === 'left') {
		placement = 'right';
		offset = 'top';
		margin = 'margin-top';
		border = 'border-right-color';
	}

	return css`
		${margin}: -4px;
		${border}: #101010;
		${placement}: 100%;
		${offset}: 50%;
	`;
};

const StyledDiv = styled.div<ITooltipProps>`
	z-index: 1;
	padding: 5px 10px;
	line-height: 1;
	border-radius: 2px;
	font-size: ${fonts.sizes.small};
	font-weight: ${fonts.weights.semibold};
	background: ${brand.black};
	color: ${brand.white};
	pointer-events: none;
	position: absolute;
	top: ${({ position }) => position?.top && position?.top};
	right: ${({ position }) => position?.right && position?.right};
	bottom: ${({ position }) => position?.bottom && position?.bottom};
	left: ${({ position }) => position?.left && position?.left};

	&:before {
		width: 0;
		height: 0;
		border: 4px solid transparent;
		pointer-events: none;
		content: '';
		position: absolute;
		${({ arrow }) => arrowStyling(arrow)}
	}

	${({ hidden }) => hidden && 'display: none;'}
`;

const Tooltip: FunctionComponent<ITooltipProps> = ({
	title,
	text,
	position,
	arrow,
	testId,
	children,
	hidden,
}) => {
	return (
		<StyledWrapper>
			<StyledDiv
				className="tooltip"
				{...{ text, position, arrow, hidden }}
				data-testid={testId}
			>
				{title && <h5>{title}</h5>}
				<span>{text}</span>
			</StyledDiv>
			{children}
		</StyledWrapper>
	);
};

export default Tooltip;
