import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	createPriceBand,
	updatePriceBand,
	deletePriceBand,
} from 'modules/brand/brand.slice';
import { IBrandPriceBandFormValues } from 'modules/brand/brand.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	brandId: string;
	priceBandId?: string;
}

const BrandPriceBandForm: FunctionComponent<IComponentProps> = ({
	brandId,
	priceBandId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const history = useHistory();

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active price band details
	const priceBand = useSelector(
		(state: RootState) => state.brand.activePriceBandDetails
	);

	// Set initial form values if editing a price band
	const initialValues: IBrandPriceBandFormValues = {
		name: (priceBandId && priceBand?.name) || '',
	};

	// Handle form submission
	const handleSubmit = async (
		values: IBrandPriceBandFormValues,
		{ setSubmitting }: FormikHelpers<IBrandPriceBandFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Create / update
		const response = await (priceBandId
			? dispatch(updatePriceBand(brandId, priceBandId, values))
			: dispatch(createPriceBand(brandId, values)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);

			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: priceBandId
					? intl.formatMessage({
						id: 'brandPriceBandForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'brandPriceBandForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// If a new price band was created, redirect back to price band list
		!priceBandId && history.push(`/brands/edit/${brandId}/price-bands`);
	};

	// Handle price band deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'brandPriceBandForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'brandPriceBandForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		if (!confirmDelete.value) {
			return;
		}

		// Delete price band
		const response =
			priceBandId &&
			(await dispatch(deletePriceBand(brandId, priceBandId)));

		if (!response) {
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandPriceBandForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to price band list for brand
		history.push(`/brands/edit/${brandId}/price-bands`);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="brandPriceBandForm.headings.details" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link to={`/brands/edit/${brandId}/price-bands`}>
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{priceBandId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="brandPriceBandForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage
								id={
									priceBandId
										? 'form.button.save'
										: 'brandPriceBandForm.button.create'
								}
							/>
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default BrandPriceBandForm;
