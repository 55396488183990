import { History } from 'history';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import BrandPriceBandForm from './brand-price-band-form/brand-price-band-form.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	getPriceBandDetails,
	resetBrandState,
} from 'modules/brand/brand.slice';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

const StyledHeader = styled.header`
	margin-bottom: 25px;
`;

interface IPageProps {
	history: History;
}

interface IPageParams {
	brandId: string;
	priceBandId?: string;
}

const BrandPriceBandFormPage: FunctionComponent<IPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	// Get brandId and priceBandId from URL path params
	const { brandId, priceBandId } = useParams<IPageParams>();

	// Get active price band
	const priceBand = useSelector(
		(state: RootState) => state.brand.activePriceBandDetails
	);

	useEffect(() => {
		const getData = async (
			currentBrandId: string,
			currentPriceBandId: string
		) => {
			dispatch(getPriceBandDetails(currentBrandId, currentPriceBandId));
		};

		// Only get price band details if we are in edit mode
		if (priceBandId) {
			getData(brandId, priceBandId);
		}

		// useEffect cleanup
		return () => {
			dispatch(resetBrandState());
		};
	}, [dispatch, brandId, priceBandId]);

	const pageTitle = priceBand?.name
		? priceBand?.name
		: 'brandPriceBandForm.create.title';

	const subtitle = priceBand
		? 'brandPriceBandForm.edit.title'
		: 'brandPriceBandForm.subtitle';

	return (
		<>
			<StyledHeader>
				<SectionHeading
					titleFormat={!priceBand}
					title={pageTitle}
					subtitle={subtitle}
				/>
			</StyledHeader>
			<FormWrapper>
				<BrandPriceBandForm
					brandId={brandId}
					priceBandId={priceBandId}
				/>
			</FormWrapper>
		</>
	);
};

export default withNav(BrandPriceBandFormPage);
