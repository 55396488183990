import { Field, Form, Formik, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string, number } from 'yup';

import BrandMarketingFooter from '../brand-marketing-footer/brand-marketing-footer.component';

import { addAlert } from 'components/alert/alert.slice';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	genericValidationNumber,
	genericValidationString,
} from 'helpers/validation.helper';
import {
	deleteBrand,
	getBrandMarketingOptin,
	putBrandMarketingOptin,
} from 'modules/brand/brand.slice';
import {
	IBrandMarketingOptin,
	IBrandMarketingOptinFormValues,
	IMarketingOptInAirshipConfig,
	isActeolMarketingConfig,
	isAirshipMarketingConfig,
	isEmailMarketingConfig,
} from 'modules/brand/brand.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledField = styled(Field)`
	text-transform: capitalize;
`;
const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

interface IComponentProps {
	brandId?: string;
	history: History;
}

const BrandMarketingSettingsForm: FunctionComponent<IComponentProps> = ({
	brandId,
	history,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	useEffect(() => {
		const getData = async (id: string | undefined) => {
			id && (await dispatch(getBrandMarketingOptin(id)));
		};
		getData(brandId);
	}, [brandId, dispatch]);

	const intl = useIntl();

	const { activeMarketingOptin } = useSelector(
		(state: RootState) => state.brand
	);

	// select options
	const IntegrationTypeOptions: IOption[] = [
		{
			label: 'None',
			value: 'none',
		},
		{
			label: 'Acteol',
			value: 'acteol',
		},
		{
			label: 'Airship',
			value: 'airship',
		},
	];

	const exportMethodOptions: IOption[] = [
		{
			label: 'csv',
			value: 'csv',
		},
		{
			label: 'json',
			value: 'json',
		},
	];

	const exportFrequencyOptions: IOption[] = [
		{
			label: 'Once a day',
			value: 'daily',
		},
		{
			label: 'Once a week',
			value: 'weekly',
		},
	];

	const initialValues: IBrandMarketingOptinFormValues = {
		isEnabled:
			brandId && typeof activeMarketingOptin?.isEnabled === 'boolean'
				? activeMarketingOptin?.isEnabled
				: false,
		privacyUrl: (brandId && activeMarketingOptin?.privacyUrl) || '',
		integrationType:
			(brandId && activeMarketingOptin?.integrationType) || 'none',
		integrationConfig: {
			instanceUrl: '',
			username: '',
			password: '',
			exportMethod: '',
			exportFrequency: '',
			exportEmail: '',
			sourceId: '',
			sourceName: 'OrderPay',
			brandCustomerGroups: 'OrderPay',
			accountAccessToken: '',
			groupCustomersByOrderFlow: false,
			accountId:
				brandId &&
				activeMarketingOptin?.integrationConfig &&
				isAirshipMarketingConfig(
					activeMarketingOptin?.integrationConfig
				) &&
				activeMarketingOptin?.integrationConfig?.accountId
					? activeMarketingOptin?.integrationConfig?.accountId.toString()
					: '',
			...activeMarketingOptin?.integrationConfig,
		},
	};

	// Handle form submission
	const handleSubmit = async (
		values: IBrandMarketingOptinFormValues,
		{ setSubmitting }: FormikHelpers<IBrandMarketingOptinFormValues>
	) => {
		if (!activeMarketingOptin) {
			fireDialog({
				title: intl.formatMessage({
					id: 'brandForm.dialogs.noActiveMarketingOptin.title',
				}),
				text: intl.formatMessage({
					id: 'brandForm.dialogs.noActiveMarketingOptin.text',
				}),
				icon: 'error',
			});
			return;
		}

		// Create submit values
		const submitValues: IBrandMarketingOptin = {
			id: activeMarketingOptin.id,
			target: activeMarketingOptin.target,
			isEnabled: values.isEnabled,
			privacyUrl: values.privacyUrl,
			integrationType: values.integrationType,
		};

		// Set integrationConfig values according to selected integration type
		if (values?.integrationConfig) {
			if (
				values.integrationType === 'acteol' &&
				isActeolMarketingConfig(values.integrationConfig)
			) {
				submitValues.integrationConfig = {
					instanceUrl: values.integrationConfig.instanceUrl,
					username: values.integrationConfig.username,
					password: values.integrationConfig.password,
				};
			} else if (
				values.integrationType === 'airship' &&
				isAirshipMarketingConfig(values.integrationConfig)
			) {
				submitValues.integrationConfig = {
					accountId: Number(values.integrationConfig.accountId),
					sourceId: values.integrationConfig.sourceId,
					sourceName: values.integrationConfig.sourceName,
					brandCustomerGroups:
						values.integrationConfig.brandCustomerGroups,
					accountAccessToken:
						values.integrationConfig.accountAccessToken,
					groupCustomersByOrderFlow:
						values.integrationConfig.groupCustomersByOrderFlow,
				};
			} else if (
				values.integrationType === 'none' &&
				isEmailMarketingConfig(values.integrationConfig)
			) {
				submitValues.integrationConfig = {
					exportMethod: values.integrationConfig.exportMethod,
					exportFrequency: values.integrationConfig.exportFrequency,
					exportEmail: values.integrationConfig.exportEmail,
				};
			}
		}

		// Set formik submission state to true
		setSubmitting(true);

		// Dispatch update brand data
		const response = await dispatch(putBrandMarketingOptin(submitValues));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	// Handle brand deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete brand
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete brand action
		brandId && (await dispatch(deleteBrand(brandId)));

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to brands page
		history.push('/brands');
	};

	const formValidationSchema = object<IBrandMarketingOptinFormValues>().shape(
		{
			privacyUrl: string().when('isEnabled', {
				is: true,
				then: genericValidationString({ fieldName: 'privacyUrl' }),
			}),
			integrationConfig: object().when('integrationType', {
				is: 'airship',
				then: object<IMarketingOptInAirshipConfig>({
					accountId: number().when('integrationType', {
						is: 'airship',
						then: genericValidationNumber({
							fieldName: 'airshipAccountId',
							min: 1,
							max: Infinity,
						}),
					}),
					brandCustomerGroups: string().required(
						validationMessages.genericRequired(
							'brandCustomerGroups'
						)
					),
					accountAccessToken: string().required(
						validationMessages.genericRequired('accountAccessToken')
					),
				}),
			}),
		}
	);

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
			validateOnChange={false}
		>
			{({ dirty, isSubmitting, values }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="brandForm.headings.marketing.settings" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								label={intl.formatMessage({
									id:
										'form.fields.marketingOptinEnabled.label',
								})}
								name="isEnabled"
							/>
							<Field
								component={Select}
								label={intl.formatMessage({
									id: 'form.fields.integrationType.label',
								})}
								name="integrationType"
								selectOptions={IntegrationTypeOptions}
								value={IntegrationTypeOptions.find(
									(option) =>
										option.value === values.integrationType
								)}
							/>
							<Field
								component={Input}
								name="privacyUrl"
								label={intl.formatMessage({
									id: 'form.fields.privacyUrl.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							{values.integrationType === 'acteol' && (
								<>
									<StyledField
										component={Input}
										name="integrationConfig.instanceUrl"
										label={intl.formatMessage(
											{
												id:
													'form.fields.instanceUrl.label',
											},
											{ instance: values.integrationType }
										)}
									/>
									<Field
										component={Input}
										name="integrationConfig.username"
										label={intl.formatMessage({
											id: 'form.fields.username.label',
										})}
									/>
									<Field
										component={Input}
										type="password"
										name="integrationConfig.password"
										label={intl.formatMessage({
											id: 'form.fields.password.label',
										})}
										value=""
									/>
								</>
							)}
							{values.integrationType === 'none' && (
								<>
									<Field
										component={Select}
										name="integrationConfig.exportMethod"
										label={intl.formatMessage({
											id:
												'form.fields.exportMethod.label',
										})}
										selectOptions={exportMethodOptions}
										value={exportMethodOptions.find(
											(option) =>
												isEmailMarketingConfig(
													values.integrationConfig
												) &&
												option.value ===
													values.integrationConfig
														?.exportMethod
										)}
									/>
									<Field
										component={Select}
										name="integrationConfig.exportFrequency"
										label={intl.formatMessage({
											id:
												'form.fields.exportFrequency.label',
										})}
										selectOptions={exportFrequencyOptions}
										value={exportFrequencyOptions.find(
											(option) =>
												isEmailMarketingConfig(
													values.integrationConfig
												) &&
												option.value ===
													values.integrationConfig
														?.exportFrequency
										)}
									/>
									<Field
										component={Input}
										name="integrationConfig.exportEmail"
										label={intl.formatMessage({
											id: 'form.fields.exportEmail.label',
										})}
									/>
								</>
							)}
							{values.integrationType === 'airship' && (
								<>
									<Field
										component={Input}
										name="integrationConfig.accountId"
										label={intl.formatMessage({
											id:
												'form.fields.airshipAccountId.label',
										})}
									/>
									<Field
										component={Input}
										name="integrationConfig.brandCustomerGroups"
										label={intl.formatMessage({
											id:
												'form.fields.brandCustomerGroups.label',
										})}
										toolTip={{
											title: intl.formatMessage({
												id:
													'marketingOptin.form.fields.brandCustomerGroups.toolTip.title',
											}),
											description: intl.formatMessage({
												id:
													'marketingOptin.form.fields.brandCustomerGroups.toolTip.description',
											}),
										}}
									/>
									<Field
										component={Input}
										name="integrationConfig.accountAccessToken"
										label={intl.formatMessage({
											id:
												'form.fields.accountAccessToken.label',
										})}
									/>
									<Field
										component={Switch}
										name="integrationConfig.groupCustomersByOrderFlow"
										label={intl.formatMessage({
											id:
												'form.fields.groupCustomers.label',
										})}
										toolTip={{
											title: intl.formatMessage({
												id:
													'marketingOptin.form.fields.groupCustomersByOrderFlow.toolTip.title',
											}),
											description: intl.formatMessage({
												id:
													'marketingOptin.form.fields.groupCustomersByOrderFlow.toolTip.description',
											}),
										}}
									/>
								</>
							)}
						</StyledColumn>
					</StyledFieldGroup>
					<BrandMarketingFooter
						disabled={
							isSubmitting ||
							(values.integrationType === 'airship' &&
								!values.integrationConfig?.accountAccessToken)
						}
						{...{ brandId, handleDelete }}
					/>
				</StyledForm>
			)}
		</Formik>
	);
};

export default BrandMarketingSettingsForm;
