import { createSlice, Dispatch } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';

import {
	ILoginCredentials,
	IResetPasswordParams,
	IAuthStore,
} from './auth.types';

// Create initial state for auth slice
export const initialAuthState: IAuthStore = {
	accessToken: {
		expiry: null,
	},
	eventsInProgress: 0,
	hasAuth: false,
	refreshToken: {
		expiry: null,
		refreshing: false,
	},
	user: {
		id: '',
		venueId: '',
	},
	activeAccountId: '',
};

const authSlice = createSlice({
	name: 'auth',
	initialState: initialAuthState,
	reducers: {
		LOGIN(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		LOGIN_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				hasAuth: true,
				accessToken: {
					...state.accessToken,
					expiry: action.payload.data.accessToken.expiry,
				},
				refreshToken: {
					...state.refreshToken,
					expiry: action.payload.data.refreshToken.expiry,
				},
				user: action.payload.data.user,
			};
		},
		LOGIN_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				hasAuth: false,
			};
		},
		LOGOUT(state) {
			return initialAuthState;
		},
		FORGOT_PASSWORD(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		FORGOT_PASSWORD_SUCCESS(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		FORGOT_PASSWORD_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_PASSWORD(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		RESET_PASSWORD_SUCCESS(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_PASSWORD_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REFRESH(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
				refreshToken: {
					...state.refreshToken,
					refreshing: true,
				},
			};
		},
		REFRESH_SUCCESS(state, action) {
			return {
				...state,
				hasAuth: true,
				eventsInProgress: state.eventsInProgress - 1,
				accessToken: {
					...state.accessToken,
					expiry: action.payload.data.accessToken.expiry,
				},
				refreshToken: {
					...state.refreshToken,
					expiry: action.payload.data.refreshToken.expiry,
					refreshing: false,
				},
			};
		},
		REFRESH_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				refreshToken: {
					...state.refreshToken,
					refreshing: false,
				},
			};
		},
		SET_ACTIVE_ACCOUNT(state, action) {
			return {
				...state,
				activeAccountId: action.payload.id,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAIL,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	REFRESH,
	REFRESH_SUCCESS,
	REFRESH_FAIL,
	SET_ACTIVE_ACCOUNT,
} = authSlice.actions;

/** Thunk to process login request */
export const processLogin = (credentials: ILoginCredentials) => async (
	dispatch: Dispatch
) => {
	// Create login request
	const { payload } = await dispatch(
		LOGIN({
			request: {
				method: 'post',
				url: 'auth/login',
				data: credentials,
			},
		})
	);

	// If request was successful
	if (
		payload?.status === 200 &&
		payload?.data.accessToken &&
		payload?.data.refreshToken
	) {
		// Identify user in log rocket
		// TODO: add name when available
		LogRocket.identify(payload.data.user.id, {
			email: credentials.email,
		});

		return true;
	}
	return false;
};

/** Thunk to process logout */
export const processLogout = () => async (dispatch: Dispatch) => {
	// Reset auth state
	await dispatch(LOGOUT());
};

/** Thunk to process forgot password request */
export const processForgotPassword = (email: string) => async (
	dispatch: Dispatch
) => {
	// Create forgot password request
	const { payload } = await dispatch(
		FORGOT_PASSWORD({
			request: {
				method: 'post',
				url: 'auth/forgot-password',
				data: {
					email,
				},
			},
		})
	);

	return payload?.status === 200;
};

/** Thunk to process reset password request */
export const processResetPassword = (params: IResetPasswordParams) => async (
	dispatch: Dispatch
) => {
	// Create reset password request
	const { payload } = await dispatch(
		RESET_PASSWORD({
			request: {
				method: 'post',
				url: 'auth/check-code',
				data: params,
			},
		})
	);

	return payload?.status === 200;
};

export const setActiveAccount = (accountId: string) => (dispatch: Dispatch) => {
	// Set the active account
	return dispatch(
		SET_ACTIVE_ACCOUNT({
			id: accountId,
		})
	);
};

export default authSlice.reducer;
