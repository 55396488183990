import { Field, Formik, Form, FormikProps } from 'formik';
import queryString from 'query-string';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

import { getBrandsList, resetBrandState } from './brand.slice';
import { IBrandItem } from './brand.types';
import BrandsList from './brands-list/brands-list.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Pagination from 'components/pagination/pagination.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getAccountsList } from 'modules/account/account.slice';
import { IAccount } from 'modules/account/account.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

// Interface for form values
interface ISearchFormValues {
	accountUUID: string;
	searchQuery: string;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.sub-actions {
		display: flex;
		align-items: center;

		form {
			display: flex;
			align-items: center;
			flex-grow: 1;
		}

		Button {
			margin: 0;
			flex-shrink: 0;
		}
	}
`;

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	max-width: 160px;

	input {
		height: 37px;
		line-height: 37px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledButton = styled(Button)`
	font-size: ${fonts.sizes.small};
	padding: 11px 18px;
`;

/** Renders brands page component */
const BrandsPage: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get history and location
	const history = useHistory();
	const location = useLocation();
	// Get query params
	const query: IQueryParams = queryString.parse(location.search);
	// Searchterm state
	const [searchTerm, setSearchTerm] = useState<string>(query.search || '');
	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
	// Sort column and direction for UI
	const [sortDirection, setSortDirection] = useState('');
	const [sortColumn, setSortColumn] = useState<keyof IBrandItem>('name');

	// Get brands from state
	const brands: IBrandItem[] = useSelector(
		(state: RootState) => state.brand.brands || []
	);

	// Get pagination from state
	const pagination = useSelector(
		(state: RootState) => state.brand.pagination
	);

	// Get accounts from store and prepare for select
	const accountOptions: IOption[] = useSelector(
		(state: RootState) => state.account.accounts
	).map((value: IAccount) => ({
		label: value.name,
		value: value.id,
	}));

	useEffect(() => {
		const getData = async () => {
			// Get accounts list
			await dispatch(getAccountsList());
		};
		getData();

		// useEffect cleanup
		return () => {
			dispatch(resetBrandState());
		};
	}, [dispatch]);

	// When page or filters are changed
	useEffect(() => {
		const getFilteredBrands = async () => {
			// Get pageSize from pagination object
			const { pageSize } = pagination;

			// Dispatch get brand list with filters
			await dispatch(
				getBrandsList({
					accountUUID: query.accountUUID,
					search: query.search,
					sortOrder: query.sortOrder,
					sort: query.sort,
					pageSize,
					pageNumber: parseFloat(query.pageNumber!) || 1,
				})
			);
		};
		getFilteredBrands();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dispatch,
		query.accountUUID,
		query.sortOrder,
		query.sort,
		query.search,
		query.pageNumber,
	]);

	// Handle searchterm change
	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	// Function to update sort query params
	const updateSort = (column: keyof IBrandItem) => {
		// sort order from query params
		const { sortOrder } = query;
		// variable to store sort direction
		let sortDir;

		if (sortOrder === 'ASC') {
			sortDir = 'DESC';
		} else if (sortOrder === 'DESC') {
			sortDir = '';
		} else {
			sortDir = 'ASC';
		}

		// TODO - Put this in useEffect too
		// Update sort direction in state
		setSortDirection(sortDir);
		setSortColumn(column);

		// Create list of filters
		const filters = {
			...query,
			sortOrder: sortDir,
			sort: column,
		};

		// Remove pagenumber from filters
		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	// Filter the brands list
	const filterBrands = ({ accountUUID = query.accountUUID }) => {
		// create menu filters
		const filters = query;

		// add filters if available
		accountUUID
			? (filters.accountUUID = accountUUID)
			: delete filters.accountUUID;

		// remove page number on search
		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	return (
		<>
			<StyledHeader>
				<SectionHeading
					title="brands.title"
					subtitle="brands.subtitle"
				/>
				<div className="sub-actions">
					<Formik
						initialValues={{
							search: query.search,
							accountUUID: query.accountUUID,
						}}
						onSubmit={() => {}}
					>
						{() => {
							return (
								<Form>
									<Field
										component={Select}
										isSearchable={true}
										size="sm"
										name="accountUUID"
										value={accountOptions.find(
											(account) =>
												account.value ===
												query.accountUUID
										)}
										placeholder={intl.formatMessage({
											id: 'form.fields.account.label',
										})}
										isClearable={true}
										selectOptions={accountOptions}
										formElementVariant="header"
										handleChangeEvent={async (
											value: ISelectChangeValue
										) =>
											filterBrands({
												accountUUID:
													value.fieldValue || '',
											})}
									/>
									<Field
										component={StyledInput}
										name="search"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={12}
										iconHeight={12}
										handleChangeEvent={(value: string) =>
											setSearchTerm(value)}
									/>
								</Form>
							);
						}}
					</Formik>
					<Link to="/brands/create">
						<StyledButton
							icon="plus"
							iconWidth={11}
							iconHeight={11}
						>
							<FormattedMessage id="brands.button.create" />
						</StyledButton>
					</Link>
				</div>
			</StyledHeader>
			<BrandsList
				brands={brands}
				setSort={updateSort}
				sortColumn={sortColumn}
				sortDirection={sortDirection}
			/>
			{pagination.pageCount > 1 && (
				<Pagination
					pageNumber={
						query.pageNumber ? parseFloat(query.pageNumber) : 1
					}
					pageCount={pagination.pageCount}
					setPageNumber={handlePagination}
				/>
			)}
		</>
	);
};

export default withNav(BrandsPage);
