import { History } from 'history';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import BrandFeedbackForm from './brand-feedback-form/brand-feedback-form.component';
import BrandMarketingForm from './brand-marketing-form/brand-marketing-form.component';
import BrandReceiptFooterConfigForm from './brand-receipt-footer-config-form/brand-receipt-footer-config-form.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import BrandDetailsForm from 'modules/brand/brand-form/brand-details-form/brand-details-form.component';
import BrandGiftingForm from 'modules/brand/brand-form/brand-gifting-form/brand-gifting-form.component';
import BrandPriceBandList from 'modules/brand/brand-form/brand-price-band-list/brand-price-band-list.component';
import {
	getBrand,
	setActiveBrand,
	resetBrandState,
} from 'modules/brand/brand.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import PaymentConfigForm from 'modules/payment/payment-config/payment-config.component';
import { EntityType } from 'modules/payment/payment.types';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

// Interface for component props
interface IComponentProps {
	history: History;
}

const BrandFormPage: FunctionComponent<IComponentProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get brand id and active tab from url params
	const { brandId, primaryTab, secondaryTab } = useParams<{
		brandId: string;
		primaryTab: string;
		secondaryTab: string;
	}>();

	// Get active brand name
	const brandName =
		useSelector((state: RootState) => state.brand.activeBrand?.name) ||
		intl.formatMessage({ id: 'brands.title' });

	useEffect(() => {
		// Get brand data by brand id
		const getData = async (id: string | undefined) => {
			// getBrand, or unset active brand
			id
				? await dispatch(getBrand(id))
				: dispatch(setActiveBrand(undefined));
		};
		getData(brandId);

		// useEffect cleanup
		return () => {
			dispatch(resetBrandState());
		};
	}, [brandId, dispatch]);

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!brandId}
					title={brandId ? brandName! : 'brandForm.create.title'}
					subtitle={
						brandId ? 'brandForm.edit.title' : 'brands.subtitle'
					}
				/>
			</header>
			{brandId ? (
				<>
					<TabList
						{...{ activeTab, setActiveTab }}
						navigateOnClick={true}
						history={history}
						parentUrl={`/brands/edit/${brandId}/`}
						tabs={[
							{
								id: 'details',
								title: intl.formatMessage({
									id: 'brandForm.tabs.details.primary',
								}),
							},
							{
								id: 'gifting',
								title: intl.formatMessage({
									id: 'brandForm.tabs.gifting.primary',
								}),
							},
							{
								id: 'payment-config',
								title: intl.formatMessage({
									id: 'brandForm.tabs.payment.primary',
								}),
							},
							{
								id: 'price-bands',
								title: intl.formatMessage({
									id: 'brandForm.tabs.priceBands.primary',
								}),
							},
							{
								id: 'feedback',
								title: intl.formatMessage({
									id: 'brandForm.tabs.feedback.primary',
								}),
							},
							{
								id: 'marketing',
								title: intl.formatMessage({
									id: 'brandForm.tabs.marketing.primary',
								}),
							},
							{
								id: 'receipt-footer',
								title: intl.formatMessage({
									id:
										'brandForm.tabs.receiptFooterConfig.primary',
								}),
							},
						]}
					/>
					<FormWrapper>
						{activeTab === 'details' && (
							<BrandDetailsForm {...{ brandId, history }} />
						)}
						{activeTab === 'gifting' && (
							<BrandGiftingForm {...{ brandId, history }} />
						)}
						{activeTab === 'payment-config' && (
							<PaymentConfigForm
								entityType={EntityType.brand}
								entityId={brandId}
							/>
						)}
						{activeTab === 'price-bands' && (
							<BrandPriceBandList {...{ brandId, history }} />
						)}
						{activeTab === 'feedback' && (
							<BrandFeedbackForm {...{ brandId, history }} />
						)}
						{activeTab === 'marketing' && (
							<BrandMarketingForm
								{...{ brandId, history, secondaryTab }}
							/>
						)}
						{activeTab === 'receipt-footer' && (
							<BrandReceiptFooterConfigForm
								{...{ brandId, history }}
							/>
						)}
					</FormWrapper>
				</>
			) : (
				<FormWrapper>
					<BrandDetailsForm {...{ history }} />
				</FormWrapper>
			)}
		</StyledContentWrapper>
	);
};

export default withNav(BrandFormPage);
