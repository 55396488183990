import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { IAlertStore, IAlertMessage } from './alert.types';

// Create initial state for alert slice
export const initialAlertState: IAlertStore = {
	alerts: [],
};

const alertSlice = createSlice({
	name: 'alert',
	initialState: initialAlertState,
	reducers: {
		ALERT_ADD(state, action) {
			return {
				...state,
				alerts: [...state.alerts, action.payload.alert],
			};
		},
		ALERT_REMOVE(state, action) {
			return {
				...state,
				alerts: state.alerts.filter(
					(alert: IAlertMessage) => alert.id !== action.payload.id
				),
			};
		},
		ALERT_CLEAR(state) {
			return {
				...state,
				alerts: [],
			};
		},
	},
});

// Destructure and export the plain action creators
export const { ALERT_ADD, ALERT_REMOVE, ALERT_CLEAR } = alertSlice.actions;

/** Thunk to process add alert request */
export const addAlert = (item: IAlertMessage) => async (dispatch: Dispatch) => {
	// Get uuid for alert
	const alert = {
		...item,
		id: await uuidv4(),
	};

	// Add alert
	await dispatch(
		ALERT_ADD({
			alert,
		})
	);

	// Wait 10 secs
	await setTimeout(async () => {
		// Remove alert
		await dispatch(ALERT_REMOVE(alert));
	}, 2000);
};

/** Thunk to process remove alert request */
export const removeAlert = (id: string) => (dispatch: Dispatch) => {
	return dispatch(
		ALERT_REMOVE({
			id,
		})
	);
};

/** Thunk to process clear alerts request */
export const clearAlerts = () => (dispatch: Dispatch) => {
	return dispatch(ALERT_CLEAR());
};

export default alertSlice.reducer;
