import { Formik, Field, Form, FormikHelpers } from 'formik';
import queryString from 'query-string';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useLocation } from 'react-router-dom';
import { AutoSizer, List } from 'react-virtualized';
import styled from 'styled-components';

import MenuCategoryListItem from './menu-category-list-item/menu-category-list-item.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import Input from 'components/form-inputs/input/input.component';
import SortIcons from 'components/sort-icons/sort-icons.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { getCategoriesList, patchMenuDetails } from 'modules/menu/menu.slice';
import { ICategory } from 'modules/menu/menu.types';

interface IFormValues {
	[index: string]: boolean;
}

// Interface for list item props
interface IListItemProps {
	key: string;
	index: number;
	isScrolling: boolean;
	style: Object;
}

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 0 0 20px;

	h2 {
		margin: 0;
	}
`;

const StyledInput = styled(Input)`
	margin: 0;
	width: 100%;
	max-width: 160px;

	input {
		height: 35px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledCheckbox = styled(Checkbox)`
	margin: 0 20px 0 0;
	flex-shrink: 0;
`;

const StyledFilterForm = styled(Form)`
	display: flex;
	align-items: center;
`;

const StyledSortIcons = styled(SortIcons)`
	margin: 0 10px 0 0;
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	padding: 0 15px;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	user-select: none;

	&.mod-sort {
		cursor: pointer;
	}

	:focus {
		outline: none;
	}

	&:first-child {
		min-width: 30px;
		width: 30px;
		margin-right: 15px;
	}

	&:nth-child(2),
	&:nth-child(3) {
		width: calc(50% - 80px);
		max-width: 300px;
	}
`;

const StyledList = styled.div`
	width: 100%;
	height: 550px;
	user-select: none;
	display: block;

	.ReactVirtualized__List:focus {
		outline: none;
	}
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	menuId: string;
}

const MenuCategoriesForm: FunctionComponent<IComponentProps> = ({ menuId }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const location = useLocation();

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Set use state for sort, search and show selected
	const [sortDirection, setSortDirection] = useState('');
	const [sortColumn, setSortColumn] = useState<keyof ICategory>('title');
	const [searchQuery, setSearchQuery] = useState('');
	const [showSelected, setShowSelected] = useState(false);

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get active account id from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active menu details from store
	const menuDetails = useSelector(
		(state: RootState) => state.menu.activeMenuDetails
	);

	// Get all categories from state
	let categories =
		useSelector((state: RootState) => state.menu.categories) || [];

	// If we have categories, filter and sort
	if (categories.length > 0) {
		categories = categories
			.filter(
				(category) =>
					(category.title &&
						category.title
							.toLowerCase()
							.includes(searchQuery.toLowerCase())) ||
					(category.reference &&
						category.reference
							.toLowerCase()
							.includes(searchQuery.toLowerCase()))
			)
			.sort((a, b) => {
				const sortA = a[sortColumn];
				const sortB = b[sortColumn];
				if (sortDirection === 'ASC') {
					return sortA! > sortB! ? 1 : -1;
				}
				if (sortDirection === 'DESC') {
					return sortA! > sortB! ? -1 : 1;
				}

				return 0;
			});
	}

	// Convert categories to initial values
	const currentMenuCategory = menuDetails?.categories?.reduce(
		(allCategories, category) => ({
			...allCategories,
			[category]: true,
		}),
		{}
	);

	// Initial form values
	const initialValues: IFormValues = currentMenuCategory!;

	useEffect(() => {
		// Get categories list
		const getData = async (id?: string) => {
			dispatch(getCategoriesList({ pageSize: -1 }));
		};

		getData(menuId);
	}, [dispatch, menuId]);

	// Handle form submission
	const handleSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Create array of empty categories
		const updatedCategories: string[] = [];

		// Filter values if they're set to true
		for (const key in values) {
			if (values[key]) {
				updatedCategories.push(key);
			}
		}

		// Send patch request
		const response = await dispatch(
			patchMenuDetails(menuId, {
				categories: updatedCategories,
			})
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'menuForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set for submission to true to remove routing prompt
		setFormSubmission(true);
	};

	const setSort = (column: keyof ICategory) => {
		let sort;
		if (sortDirection === 'ASC') {
			sort = 'DESC';
		} else if (sortDirection === 'DESC') {
			sort = '';
		} else {
			sort = 'ASC';
		}

		setSortDirection(sort);
		setSortColumn(column);
	};

	return (
		<>
			<StyledHeader>
				<h2>
					<FormattedMessage
						id="menuForm.headings.category"
						values={{ menu: menuDetails?.title }}
					/>
				</h2>
				<div>
					<Formik
						initialValues={{
							searchQuery: query.searchQuery,
							onlySelectedCategories: false,
						}}
						onSubmit={() => {}}
					>
						{() => {
							return (
								<StyledFilterForm>
									<Field
										component={StyledCheckbox}
										name="onlySelectedCategories"
										label={intl.formatMessage({
											id:
												'form.fields.onlySelectedCategories.label',
										})}
										handleChangeEvent={(value: boolean) => {
											setShowSelected(value);
										}}
									/>
									<Field
										component={StyledInput}
										name="searchQuery"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={12}
										iconHeight={12}
										handleChangeEvent={(value: string) =>
											setSearchQuery(value)}
									/>
								</StyledFilterForm>
							);
						}}
					</Formik>
				</div>
			</StyledHeader>
			<Formik
				enableReinitialize
				initialValues={initialValues!}
				onSubmit={handleSubmit}
			>
				{({ dirty, values, isSubmitting }) => {
					const visibleCategories =
						!showSelected && values
							? categories.sort((a, b) => {
								const aSelected = values[a.id] === true;
								const bSelected = values[b.id] === true;

								if (!aSelected && !bSelected) {
									return 0;
								}

								return bSelected && !aSelected ? 1 : -1;
							  })
							: categories.filter((category) =>
								values ? values[category.id] === true : true
							  );

					/** list items */
					const categoryItem = ({
						key,
						index,
						isScrolling,
						style,
					}: IListItemProps) => (
						<div style={style} key={key}>
							<MenuCategoryListItem
								item={visibleCategories[index]}
							/>
						</div>
					);

					return (
						<StyledForm>
							<Prompt
								when={dirty && !formSubmission}
								message=""
							/>
							<StyledHeadings>
								<StyledHeading />
								<StyledHeading
									className="mod-sort"
									onClick={() => setSort('title')}
									onKeyPress={() => setSort('title')}
									role="button"
									tabIndex={0}
								>
									<StyledSortIcons
										active={sortColumn === 'title'}
										sort={sortDirection}
									/>
									<FormattedMessage id="menuCategories.headings.title" />
								</StyledHeading>
								<StyledHeading
									className="mod-sort"
									onClick={() => setSort('reference')}
									onKeyPress={() => setSort('reference')}
									role="button"
									tabIndex={0}
								>
									<StyledSortIcons
										active={sortColumn === 'reference'}
										sort={sortDirection}
									/>
									<FormattedMessage id="menuCategories.headings.reference" />
								</StyledHeading>
							</StyledHeadings>
							<StyledList>
								{visibleCategories?.length ? (
									<AutoSizer>
										{({ height, width }) => (
											<List
												width={width || 400}
												height={550}
												rowCount={
													visibleCategories.length
												}
												rowHeight={61}
												rowRenderer={categoryItem}
											/>
										)}
									</AutoSizer>
								) : (
									// TODO - What if there are no categories?
									''
								)}
							</StyledList>
							<StyledActions>
								<div>
									<Link
										to={`/menus/menus?activeAccount=${activeAccountId}`}
									>
										<Button variant="secondary">
											<FormattedMessage id="form.button.back" />
										</Button>
									</Link>
								</div>
								<Button
									type="submit"
									disabled={isSubmitting}
									ariaLabel="submit-button"
								>
									<FormattedMessage id="form.button.save" />
								</Button>
							</StyledActions>
						</StyledForm>
					);
				}}
			</Formik>
		</>
	);
};

export default MenuCategoriesForm;
