import { createBrowserHistory } from 'history';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Router, Redirect } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {
	GuardToRoute,
	Next,
	GuardFunctionRouteProps,
	GuardFunction,
} from 'react-router-guards/dist/types';
import { LastLocationProvider } from 'react-router-last-location';

import { fireDialog } from '../dialog/dialog.service';
import { RootState } from '../state/root.reducer';
import NotFoundPage from './not-found.page';

import AccountFormPage from 'modules/account/account-form/account-form.page';
import AccountsPage from 'modules/account/accounts.page';
import AppUserDetailsPage from 'modules/app-users/app-user-details/app-user-details.page';
import AppUsersPage from 'modules/app-users/app-users.page';
import ForgotPasswordPage from 'modules/auth/forgot-password/forgot-password.page';
import LoginPage from 'modules/auth/login/login.page';
import ResetPasswordPage from 'modules/auth/reset-password/reset-password.page';
import BrandFormPage from 'modules/brand/brand-form/brand-form.page';
import BrandPriceBandFormPage from 'modules/brand/brand-price-band/brand-price-band-form.page';
import BrandsPage from 'modules/brand/brands.page';
import { intl } from 'modules/core/i18n/i18n.config';
import ImportPage from 'modules/import/import.page';
import CategoryFormPage from 'modules/menu/category/category-form/category-form.page';
import MenuUploadPage from 'modules/menu/menu-upload/menu-upload.page';
import MenuFormPage from 'modules/menu/menu/menu-form/menu-form.page';
import MenusPage from 'modules/menu/menus.page';
import ModifierGroupFormPage from 'modules/menu/modifier-group/modifier-group-form/modifier-group-form.page';
import ModifierItemFormPage from 'modules/menu/modifier-item/modifier-item-form/modifier-item-form.page';
import ProductFormPage from 'modules/menu/product/product-form/product-form.page';
import SuggestedPairingFormPage from 'modules/menu/suggested-pairing/suggested-pairing-form/suggested-pairing-form.page';
import OperationsPage from 'modules/operations/operations.page';
import OrderFormPage from 'modules/order/order-form/order-form.page';
import OrdersPage from 'modules/order/orders.page';
import ProfilePage from 'modules/profile/profile.page';
import ReportPage from 'modules/report/reports.page';
import StaffFormPage from 'modules/staff/staff-form/staff-form.page';
import StaffPage from 'modules/staff/staff.page';
import VenueFormPage from 'modules/venue/venue-form/venue-form.page';
import VenuesPage from 'modules/venue/venues.page';

// Create an export browser history
export const history = createBrowserHistory({
	// Overide default confirmation
	async getUserConfirmation(message, callback) {
		// Fire dialog
		const { value } = await fireDialog({
			title: intl.formatMessage({
				id: 'appRouter.navigation.confirm.title',
			}),
			text: intl.formatMessage({
				id: 'appRouter.navigation.confirm.text',
			}),
			showCancelButton: true,
		});
		// callback result from dialog
		callback(value);
	},
});

/** Router component containing all application routes */
const AppRouter: FunctionComponent = () => {
	const hasAuth: boolean = useSelector(
		(state: RootState) => state.auth.hasAuth
	);
	/** Route guard for routes requiring login */
	const requireLogin: GuardFunction = async (
		to: GuardToRoute,
		from: GuardFunctionRouteProps | null,
		next: Next
	) => {
		// If route requires auth
		if (to.meta.auth) {
			hasAuth ? next() : next.redirect('/');
		} else {
			next();
		}
	};

	/** Route guard for import route */
	const enableImport: GuardFunction = async (
		to: GuardToRoute,
		from: GuardFunctionRouteProps | null,
		next: Next
	) => {
		if (to.meta.import) {
			process.env.REACT_APP_ENABLE_IMPORT === 'true'
				? next()
				: next.redirect('/');
		} else {
			next();
		}
	};

	return (
		<Router history={history}>
			<LastLocationProvider>
				<GuardProvider
					guards={[requireLogin, enableImport]}
					error={NotFoundPage}
				>
					<Switch>
						<Route
							exact
							path="/"
							render={({ location }) =>
								hasAuth ? (
									<Redirect
										to={{
											pathname: '/accounts',
											state: { from: location },
										}}
									/>
								) : (
									<LoginPage />
								)}
						/>
						<Route
							exact
							path="/forgot"
							render={() => <ForgotPasswordPage />}
						/>
						<Route
							exact
							path="/reset"
							render={() => <ResetPasswordPage />}
						/>
						<GuardedRoute
							exact
							path={[
								'/import',
								'/import/:primaryTab(accounts|brands|venues)',
							]}
							render={() => <ImportPage {...{ history }} />}
							meta={{ auth: true, import: true }}
						/>
						<GuardedRoute
							exact
							path="/accounts"
							render={() => <AccountsPage />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/accounts/create',
								'/accounts/edit/:accountId/:primaryTab(details|payment-config)',
							]}
							render={() => <AccountFormPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/brands"
							render={() => <BrandsPage />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/brands/create',
								'/brands/edit/:brandId/:primaryTab(details|gifting|payment-config|price-bands|feedback|marketing|receipt-footer)',
								'/brands/edit/:brandId/:primaryTab(marketing)/:secondaryTab(settings|mapping)',
							]}
							render={() => <BrandFormPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/brands/:brandId/price-bands/create',
								'/brands/:brandId/price-bands/edit/:priceBandId',
							]}
							render={() => (
								<BrandPriceBandFormPage {...{ history }} />
							)}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/app-users"
							render={() => <AppUsersPage />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/app-users/view/:userId"
							render={() => <AppUserDetailsPage />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/staff"
							render={() => <StaffPage />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/staff/create',
								'/staff/edit/:staffId/:primaryTab',
							]}
							render={() => <StaffFormPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/venues"
							render={() => <VenuesPage />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/venues/create',
								'/venues/edit/:venueId/:primaryTab(gifting|printing|validation)',
								'/venues/edit/:venueId/:primaryTab(printing)/:secondaryTab(create)',
								'/venues/edit/:venueId/:primaryTab(printing)/:secondaryTab(edit)/:printerId',
								'/venues/edit/:venueId/:primaryTab(details)/:secondaryTab(details|config|payments|opening|publish|sync|beacons)',
								'/venues/edit/:venueId/:primaryTab(services)/:secondaryTab(table-service|order-ahead|delivery|self-service)/:tertiaryTab(general|pos|availability|tipping|service-charge|feedback)',
							]}
							render={() => <VenueFormPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/orders"
							render={() => <OrdersPage />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/orders/manageOrder/:orderId/:primaryTab(details|receipt|payments|refund|audits)"
							render={() => <OrderFormPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/menus/suggested-pairing/create',
								'/menus/suggested-pairing/edit/:pairingId',
							]}
							render={() => (
								<SuggestedPairingFormPage {...{ history }} />
							)}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/menus/:primaryTab"
							render={() => <MenusPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/menus/menus/create',
								'/menus/menus/edit/:menuId/:primaryTab(details|availability|categories)',
							]}
							render={() => <MenuFormPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={['/menus/menus/upload']}
							render={() => <MenuUploadPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/menus/category/create',
								'/menus/category/edit/:categoryId/:primaryTab',
							]}
							render={() => <CategoryFormPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/menus/product/create',
								'/menus/product/edit/:productId/:primaryTab(details|nutrition|modifier-groups|price-bands)',
							]}
							render={() => <ProductFormPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/menus/modifier-group/create',
								'/menus/modifier-group/edit/:modifierId/:primaryTab(details|items)',
							]}
							render={() => (
								<ModifierGroupFormPage {...{ history }} />
							)}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path={[
								'/menus/modifier-item/create',
								'/menus/modifier-item/edit/:modifierItemId/:primaryTab(details|price-bands|nutritional)',
							]}
							render={() => (
								<ModifierItemFormPage {...{ history }} />
							)}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/reports/:primaryTab(reports|transaction|remittance)"
							render={() => <ReportPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/operations/:primaryTab(refunds|beacons)"
							render={() => <OperationsPage {...{ history }} />}
							meta={{ auth: true }}
						/>
						<GuardedRoute
							exact
							path="/profile"
							render={() => <ProfilePage />}
							meta={{ auth: true }}
						/>
						<Route component={NotFoundPage} />
					</Switch>
				</GuardProvider>
			</LastLocationProvider>
		</Router>
	);
};

export default AppRouter;
