import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IOrderState, IOrderListFilters, IOrderRefund } from './order.types';

import { SET_LOADING_CONFIG } from 'components/loading/loading.slice';
import { intl } from 'modules/core/i18n/i18n.config';

// Create initial state for account slice
export const initialOrderState: IOrderState = {
	orders: [],
	eventsInProgress: 0,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
	audits: [],
};

const ordersSlice = createSlice({
	name: 'order',
	initialState: initialOrderState,
	reducers: {
		GET_ALL_ORDERS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ALL_ORDERS_SUCCESS(state, action) {
			return {
				...state,
				orders: action.payload.data.orders,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_ORDERS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDER_SUCCESS(state, action) {
			return {
				...state,
				activeOrder: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_PAYMENTS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDER_PAYMENTS_SUCCESS(state, action) {
			return {
				...state,
				activeOrderTransactions: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_PAYMENTS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_AUDITS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDER_AUDITS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				audits: action.payload.data,
			};
		},
		GET_ORDER_AUDITS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_ORDER_REFUND(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		POST_ORDER_REFUND_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_ORDER_REFUND_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_ORDER_STATE(state) {
			return {
				...initialOrderState,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_ALL_ORDERS,
	GET_ALL_ORDERS_SUCCESS,
	GET_ALL_ORDERS_FAIL,
	GET_ORDER,
	GET_ORDER_FAIL,
	GET_ORDER_SUCCESS,
	GET_ORDER_PAYMENTS,
	GET_ORDER_PAYMENTS_SUCCESS,
	GET_ORDER_PAYMENTS_FAIL,
	GET_ORDER_AUDITS,
	GET_ORDER_AUDITS_SUCCESS,
	GET_ORDER_AUDITS_FAIL,
	POST_ORDER_REFUND,
	POST_ORDER_REFUND_SUCCESS,
	POST_ORDER_REFUND_FAIL,
	RESET_ORDER_STATE,
} = ordersSlice.actions;

/** Get orders list */
export const getOrdersList = (filters?: IOrderListFilters) => async (
	dispatch: Dispatch
) => {
	try {
		// get orders list request
		const { payload } = await dispatch(
			GET_ALL_ORDERS({
				request: {
					method: 'get',
					url: 'order',
					params: filters,
				},
			})
		);

		return payload?.data;
	} catch (error) {
		return [];
	}
};

/** Get order */
export const getOrder = (orderId: string) => async (dispatch: Dispatch) => {
	// Create order request
	const { payload } = await dispatch(
		GET_ORDER({
			request: {
				method: 'get',
				url: `order/${orderId}`,
			},
		})
	);

	return payload?.data;
};

/** Get order payments */
export const getOrderPayments = (orderId: string) => async (
	dispatch: Dispatch
) => {
	// Create order payments request
	const { payload } = await dispatch(
		GET_ORDER_PAYMENTS({
			request: {
				method: 'get',
				url: `payments/order/${orderId}`,
			},
		})
	);

	return payload?.data;
};

export const getAuditLogs = (orderId: string) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GET_ORDER_AUDITS({
			request: {
				method: 'get',
				url: `audits/order/${orderId}`,
			},
		})
	);

	return payload?.data;
};

/** refund order amount */
export const refundOrder = (orderId: string, data: IOrderRefund) => async (
	dispatch: Dispatch
) => {
	try {
		// variable for request timeout
		const requestTimeout: number = 60000;
		// set loading config
		dispatch(
			SET_LOADING_CONFIG({
				data: {
					loadingMessage: intl.formatMessage({
						id: 'orderRefund.loading.message',
					}),
					loadingTimeout: requestTimeout,
				},
			})
		);

		// Create order refund request
		const { payload } = await dispatch(
			POST_ORDER_REFUND({
				request: {
					method: 'post',
					url: `payments/refund/order/${orderId}`,
					data,
					timeout: requestTimeout,
				},
			})
		);

		// Reset loading config
		dispatch(
			SET_LOADING_CONFIG({
				data: { loadingMessage: '', loadingTimeout: 0 },
			})
		);

		return payload?.status === 200;
	} catch {
		// Reset loading config
		dispatch(
			SET_LOADING_CONFIG({
				data: { loadingMessage: '', loadingTimeout: 0 },
			})
		);
		return false;
	}
};

/** Reset Order State */
export const resetOrderState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_ORDER_STATE());
};

export default ordersSlice.reducer;
