import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	EProductPairingSearchType,
	IMenuState,
	IMenuListFilters,
	IMenuDetailsFormValues,
	ICategoryPatch,
	IProductNutritionFormValues,
	IMenuPatch,
	ICategorySubmitValues,
	IProductDetailsSubmitValues,
	IProductPatch,
	IModifierGroupDetailsSubmitValues,
	IModifierItemPatch,
	IModifierItemSubmitValues,
	IModifierGroupPatch,
	IMenuOperatingTime,
	IMenuAvailabilityFormOperatingTimes,
	IMenuAvailabilitySubmitValues,
	IPairingGroupedSearchResult,
	IPairingSearchResult,
	IProductPairingSubmitValues,
	IPairingListFilters,
	ICheckoutUpsellConfig,
	ETableServiceType,
	IUpsellProduct,
	ICheckoutUpsellGroupedSearchResult,
	IMenuImportValues,
} from './menu.types';

import {
	IItemSelectGroup,
	IItemSelect,
} from 'components/form-inputs/multi-select/multi-select.types';
import { intl } from 'modules/core/i18n/i18n.config';

interface IState {
	auth: { activeAccountId: string };
}
// Create initial state
export const initialMenuState: IMenuState = {
	eventsInProgress: 0,
	preferences: {
		cuisines: [],
		dietary: [],
		allergens: [],
	},
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
};

const menuSlice = createSlice({
	name: 'menu',
	initialState: initialMenuState,
	reducers: {
		GET_MENU_PREFERENCES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MENU_PREFERENCES_SUCCESS(state, action) {
			return {
				...state,
				preferences: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MENU_PREFERENCES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MENUS_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MENUS_LIST_SUCCESS(state, action) {
			return {
				...state,
				menus: action.payload.data.data,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MENUS_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MENU_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MENU_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				activeMenuDetails: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MENU_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MENU_AVAILABILITY(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MENU_AVAILABILITY_SUCCESS(state, action) {
			return {
				...state,
				activeMenuAvailability: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MENU_AVAILABILITY_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_MENU(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_MENU_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_MENU_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MENU_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_MENU_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MENU_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_MENU_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PATCH_MENU_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_MENU_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MENU_AVAILABILITY(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_MENU_AVAILABILITY_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MENU_AVAILABILITY_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_MENU(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_MENU_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_MENU_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CATEGORIES_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_CATEGORIES_LIST_SUCCESS(state, action) {
			return {
				...state,
				categories: action.payload.data.data,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CATEGORIES_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CATEGORY_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_CATEGORY_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				activeCategoryDetails: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CATEGORY_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_CATEGORY(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_CATEGORY_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_CATEGORY_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_CATEGORY_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_CATEGORY_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_CATEGORY_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_CATEGORY_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PATCH_CATEGORY_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_CATEGORY_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_CATEGORY(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_CATEGORY_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_CATEGORY_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCTS_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PRODUCTS_LIST_SUCCESS(state, action) {
			return {
				...state,
				products: action.payload.data.data,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCTS_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCT_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PRODUCT_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				activeProductDetails: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCT_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCT_NUTRITION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PRODUCT_NUTRITION_SUCCESS(state, action) {
			return {
				...state,
				activeProductNutrition: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCT_NUTRITION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCT_PRICE_BANDS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PRODUCT_PRICE_BANDS_SUCCESS(state, action) {
			return {
				...state,
				activeProductPriceBands: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCT_PRICE_BANDS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PRODUCT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_PRODUCT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PRODUCT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRODUCT_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_PRODUCT_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRODUCT_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_PRODUCT_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PATCH_PRODUCT_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_PRODUCT_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRODUCT_NUTRITION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_PRODUCT_NUTRITION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRODUCT_NUTRITION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_PRODUCT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_PRODUCT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_PRODUCT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_GROUPS_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MODIFIER_GROUPS_LIST_SUCCESS(state, action) {
			return {
				...state,
				modifierGroups: action.payload.data.data,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_GROUPS_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_GROUP_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MODIFIER_GROUP_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				activeModifierGroupDetails: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_GROUP_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_NUTRITION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MODIFIER_NUTRITION_SUCCESS(state, action) {
			return {
				...state,
				activeModifierNutrition: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_NUTRITION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_MODIFIER_GROUP(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_MODIFIER_GROUP_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_MODIFIER_GROUP_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_NUTRITION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_MODIFIER_NUTRITION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_NUTRITION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_GROUP_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_MODIFIER_GROUP_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_GROUP_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_MODIFIER_GROUP_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PATCH_MODIFIER_GROUP_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_MODIFIER_GROUP_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_MODIFIER_GROUP(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_MODIFIER_GROUP_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_MODIFIER_GROUP_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_ITEMS_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MODIFIER_ITEMS_LIST_SUCCESS(state, action) {
			return {
				...state,
				modifierItems: action.payload.data.data,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_ITEMS_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_ITEM_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MODIFIER_ITEM_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				activeModifierItemDetails: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_ITEM_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_ITEM_PRICE_BANDS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MODIFIER_ITEM_PRICE_BANDS_SUCCESS(state, action) {
			return {
				...state,
				activeModifierItemPriceBands: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_MODIFIER_ITEM_PRICE_BANDS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_MODIFIER_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_MODIFIER_ITEM_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_MODIFIER_ITEM_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_ITEM_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_MODIFIER_ITEM_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_MODIFIER_ITEM_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_MODIFIER_ITEM_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PATCH_MODIFIER_ITEM_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PATCH_MODIFIER_ITEM_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_MODIFIER_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_MODIFIER_ITEM_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_MODIFIER_ITEM_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAIRING_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PAIRING_LIST_SUCCESS(state, action) {
			return {
				...state,
				productPairings: action.payload.data.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAIRING_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PAIRING_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_PAIRING_ITEM_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PAIRING_ITEM_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAIRING_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PAIRING_ITEM_SUCCESS(state, action) {
			return {
				...state,
				activeProductPairing: {
					...action.payload.data,
					baseSelectedProducts: action.payload.data?.baseSelectedProducts?.map(
						(item: IPairingSearchResult) => ({
							id: item.entitySku,
							title: item.name,
						})
					),
					pairingSelectedProducts: action.payload.data?.pairingSelectedProducts?.map(
						(item: IPairingSearchResult) => ({
							id: item.entitySku,
							title: item.name,
						})
					),
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAIRING_ITEM_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PAIRING_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_PAIRING_ITEM_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PAIRING_ITEM_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_PAIRING_ITEM(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_PAIRING_ITEM_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_PAIRING_ITEM_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAIRING_PRODUCT_SEARCH(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PAIRING_PRODUCT_SEARCH_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAIRING_PRODUCT_SEARCH_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CHECKOUT_UPSELL_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_CHECKOUT_UPSELL_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				activeCheckoutUpsellConfig: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CHECKOUT_UPSELL_CONFIG_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_CHECKOUT_UPSELL_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_CHECKOUT_UPSELL_CONFIG_SUCCESS(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_CHECKOUT_UPSELL_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CHECKOUT_UPSELL_PRODUCT_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_CHECKOUT_UPSELL_PRODUCT_LIST_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CHECKOUT_UPSELL_PRODUCT_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_MENU_STATE() {
			return {
				...initialMenuState,
			};
		},
		IMPORT_MENU(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		IMPORT_MENU_SUCCESS(state, action) {
			return {
				...state,
				importMenu: action.payload.data || [],
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		IMPORT_MENU_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_EXPORTED_MENU(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_EXPORTED_MENU_SUCCESS(state, action) {
			return {
				...state,
				exportedMenu: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_EXPORTED_MENU_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_MENU_PREFERENCES,
	GET_MENU_PREFERENCES_FAIL,
	GET_MENU_PREFERENCES_SUCCESS,
	GET_MENUS_LIST,
	GET_MENUS_LIST_SUCCESS,
	GET_MENUS_LIST_FAIL,
	GET_MENU_DETAILS,
	GET_MENU_DETAILS_SUCCESS,
	GET_MENU_DETAILS_FAIL,
	GET_MENU_AVAILABILITY,
	GET_MENU_AVAILABILITY_SUCCESS,
	GET_MENU_AVAILABILITY_FAIL,
	CREATE_MENU,
	CREATE_MENU_SUCCESS,
	CREATE_MENU_FAIL,
	UPDATE_MENU_DETAILS,
	UPDATE_MENU_DETAILS_SUCCESS,
	UPDATE_MENU_DETAILS_FAIL,
	PATCH_MENU_DETAILS,
	PATCH_MENU_DETAILS_SUCCESS,
	PATCH_MENU_DETAILS_FAIL,
	UPDATE_MENU_AVAILABILITY,
	UPDATE_MENU_AVAILABILITY_SUCCESS,
	UPDATE_MENU_AVAILABILITY_FAIL,
	DELETE_MENU,
	DELETE_MENU_SUCCESS,
	DELETE_MENU_FAIL,
	GET_CATEGORIES_LIST,
	GET_CATEGORIES_LIST_SUCCESS,
	GET_CATEGORIES_LIST_FAIL,
	GET_CATEGORY_DETAILS,
	GET_CATEGORY_DETAILS_SUCCESS,
	GET_CATEGORY_DETAILS_FAIL,
	CREATE_CATEGORY,
	CREATE_CATEGORY_SUCCESS,
	CREATE_CATEGORY_FAIL,
	UPDATE_CATEGORY_DETAILS,
	UPDATE_CATEGORY_DETAILS_SUCCESS,
	UPDATE_CATEGORY_DETAILS_FAIL,
	PATCH_CATEGORY_DETAILS,
	PATCH_CATEGORY_DETAILS_SUCCESS,
	PATCH_CATEGORY_DETAILS_FAIL,
	DELETE_CATEGORY,
	DELETE_CATEGORY_SUCCESS,
	DELETE_CATEGORY_FAIL,
	GET_PRODUCTS_LIST,
	GET_PRODUCTS_LIST_SUCCESS,
	GET_PRODUCTS_LIST_FAIL,
	GET_PRODUCT_DETAILS,
	GET_PRODUCT_DETAILS_SUCCESS,
	GET_PRODUCT_DETAILS_FAIL,
	GET_PRODUCT_NUTRITION,
	GET_PRODUCT_NUTRITION_SUCCESS,
	GET_PRODUCT_NUTRITION_FAIL,
	GET_PRODUCT_PRICE_BANDS,
	GET_PRODUCT_PRICE_BANDS_SUCCESS,
	GET_PRODUCT_PRICE_BANDS_FAIL,
	CREATE_PRODUCT,
	CREATE_PRODUCT_SUCCESS,
	CREATE_PRODUCT_FAIL,
	UPDATE_PRODUCT_DETAILS,
	UPDATE_PRODUCT_DETAILS_SUCCESS,
	UPDATE_PRODUCT_DETAILS_FAIL,
	PATCH_PRODUCT_DETAILS,
	PATCH_PRODUCT_DETAILS_FAIL,
	PATCH_PRODUCT_DETAILS_SUCCESS,
	UPDATE_PRODUCT_NUTRITION,
	UPDATE_PRODUCT_NUTRITION_SUCCESS,
	UPDATE_PRODUCT_NUTRITION_FAIL,
	DELETE_PRODUCT,
	DELETE_PRODUCT_SUCCESS,
	DELETE_PRODUCT_FAIL,
	GET_MODIFIER_NUTRITION,
	GET_MODIFIER_NUTRITION_SUCCESS,
	GET_MODIFIER_NUTRITION_FAIL,
	GET_MODIFIER_GROUPS_LIST,
	GET_MODIFIER_GROUPS_LIST_SUCCESS,
	GET_MODIFIER_GROUPS_LIST_FAIL,
	GET_MODIFIER_GROUP_DETAILS,
	GET_MODIFIER_GROUP_DETAILS_SUCCESS,
	GET_MODIFIER_GROUP_DETAILS_FAIL,
	CREATE_MODIFIER_GROUP,
	CREATE_MODIFIER_GROUP_SUCCESS,
	CREATE_MODIFIER_GROUP_FAIL,
	UPDATE_MODIFIER_GROUP_DETAILS,
	UPDATE_MODIFIER_NUTRITION,
	UPDATE_MODIFIER_NUTRITION_SUCCESS,
	UPDATE_MODIFIER_NUTRITION_FAIL,
	UPDATE_MODIFIER_GROUP_DETAILS_SUCCESS,
	UPDATE_MODIFIER_GROUP_DETAILS_FAIL,
	PATCH_MODIFIER_GROUP_DETAILS,
	PATCH_MODIFIER_GROUP_DETAILS_FAIL,
	PATCH_MODIFIER_GROUP_DETAILS_SUCCESS,
	DELETE_MODIFIER_GROUP,
	DELETE_MODIFIER_GROUP_SUCCESS,
	DELETE_MODIFIER_GROUP_FAIL,
	GET_MODIFIER_ITEMS_LIST,
	GET_MODIFIER_ITEMS_LIST_SUCCESS,
	GET_MODIFIER_ITEMS_LIST_FAIL,
	GET_MODIFIER_ITEM_DETAILS,
	GET_MODIFIER_ITEM_DETAILS_SUCCESS,
	GET_MODIFIER_ITEM_DETAILS_FAIL,
	GET_MODIFIER_ITEM_PRICE_BANDS,
	GET_MODIFIER_ITEM_PRICE_BANDS_SUCCESS,
	GET_MODIFIER_ITEM_PRICE_BANDS_FAIL,
	CREATE_MODIFIER_ITEM,
	CREATE_MODIFIER_ITEM_SUCCESS,
	CREATE_MODIFIER_ITEM_FAIL,
	UPDATE_MODIFIER_ITEM_DETAILS,
	UPDATE_MODIFIER_ITEM_DETAILS_SUCCESS,
	UPDATE_MODIFIER_ITEM_DETAILS_FAIL,
	PATCH_MODIFIER_ITEM_DETAILS,
	PATCH_MODIFIER_ITEM_DETAILS_SUCCESS,
	PATCH_MODIFIER_ITEM_DETAILS_FAIL,
	DELETE_MODIFIER_ITEM,
	DELETE_MODIFIER_ITEM_SUCCESS,
	DELETE_MODIFIER_ITEM_FAIL,
	CREATE_PAIRING_ITEM,
	CREATE_PAIRING_ITEM_FAIL,
	CREATE_PAIRING_ITEM_SUCCESS,
	GET_PAIRING_ITEM,
	GET_PAIRING_ITEM_FAIL,
	GET_PAIRING_ITEM_SUCCESS,
	UPDATE_PAIRING_ITEM,
	UPDATE_PAIRING_ITEM_FAIL,
	UPDATE_PAIRING_ITEM_SUCCESS,
	DELETE_PAIRING_ITEM,
	DELETE_PAIRING_ITEM_FAIL,
	DELETE_PAIRING_ITEM_SUCCESS,
	GET_PAIRING_LIST,
	GET_PAIRING_LIST_FAIL,
	GET_PAIRING_LIST_SUCCESS,
	GET_PAIRING_PRODUCT_SEARCH,
	GET_PAIRING_PRODUCT_SEARCH_FAIL,
	GET_PAIRING_PRODUCT_SEARCH_SUCCESS,
	RESET_MENU_STATE,
	GET_CHECKOUT_UPSELL_CONFIG,
	GET_CHECKOUT_UPSELL_CONFIG_SUCCESS,
	GET_CHECKOUT_UPSELL_CONFIG_FAIL,
	UPDATE_CHECKOUT_UPSELL_CONFIG,
	UPDATE_CHECKOUT_UPSELL_CONFIG_SUCCESS,
	UPDATE_CHECKOUT_UPSELL_CONFIG_FAIL,
	GET_CHECKOUT_UPSELL_PRODUCT_LIST,
	GET_CHECKOUT_UPSELL_PRODUCT_LIST_SUCCESS,
	GET_CHECKOUT_UPSELL_PRODUCT_LIST_FAIL,
	IMPORT_MENU,
	IMPORT_MENU_FAIL,
	IMPORT_MENU_SUCCESS,
	GET_EXPORTED_MENU,
	GET_EXPORTED_MENU_SUCCESS,
	GET_EXPORTED_MENU_FAIL,
} = menuSlice.actions;

/** Get menu preferences */
export const getMenuPreferences = () => async (dispatch: Dispatch) => {
	// Create request
	await dispatch(
		GET_MENU_PREFERENCES({
			request: {
				method: 'get',
				url: 'menu/preferences',
			},
		})
	);
};

// MENU
/** Get menu list */
export const getMenuList = (filters?: IMenuListFilters) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get menu list request
	const { payload } = await dispatch(
		GET_MENUS_LIST({
			request: {
				method: 'get',
				url: 'menu/menus',
				params: filters,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/** Get menu details */
export const getMenuDetails = (menuId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get menu details request
	const { payload } = await dispatch(
		GET_MENU_DETAILS({
			request: {
				method: 'get',
				url: `menu/menus/${menuId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/** Get menu availability */
export const getMenuAvailability = (menuId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get menu availability request
	const { payload } = await dispatch(
		GET_MENU_AVAILABILITY({
			request: {
				method: 'get',
				url: `menu/menus/${menuId}/availability`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/* Create menu */
export const createMenu = (data: IMenuDetailsFormValues) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// create menu request
	const { payload } = await dispatch(
		CREATE_MENU({
			request: {
				method: 'post',
				url: 'menu/menus',
				data,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/* Update menu */
export const updateMenuDetails = (
	menuId: string,
	data: IMenuDetailsFormValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update menu request
	const { payload } = await dispatch(
		UPDATE_MENU_DETAILS({
			request: {
				method: 'put',
				url: `menu/menus/${menuId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Patch menu */
export const patchMenuDetails = (menuId: string, data: IMenuPatch) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// update menu request
	const { payload } = await dispatch(
		PATCH_MENU_DETAILS({
			request: {
				method: 'patch',
				url: `menu/menus/${menuId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Update menu availability */
export const updateMenuAvailability = (
	menuId: string,
	data: IMenuAvailabilitySubmitValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update menu availability request
	const { payload } = await dispatch(
		UPDATE_MENU_AVAILABILITY({
			request: {
				method: 'put',
				url: `menu/menus/${menuId}/availability`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Delete menu */
export const deleteMenu = (menuId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// delete menu request
	const { payload } = await dispatch(
		DELETE_MENU({
			request: {
				method: 'delete',
				url: `menu/menus/${menuId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.status === 200;
};

// CATEGORY
/** Get categories list */
export const getCategoriesList = (filters?: IMenuListFilters) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get categories list request
	const { payload } = await dispatch(
		GET_CATEGORIES_LIST({
			request: {
				method: 'get',
				url: 'menu/categories',
				params: filters,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/** Get category details */
export const getCategoryDetails = (categoryId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get category details request
	const { payload } = await dispatch(
		GET_CATEGORY_DETAILS({
			request: {
				method: 'get',
				url: `menu/categories/${categoryId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/* Create category */
export const createCategory = (data: ICategorySubmitValues) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// create category request
	const { payload } = await dispatch(
		CREATE_CATEGORY({
			request: {
				method: 'post',
				url: 'menu/categories',
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Update category */
export const updateCategoryDetails = (
	categoryId: string,
	data: ICategorySubmitValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update category request
	const { payload } = await dispatch(
		UPDATE_CATEGORY_DETAILS({
			request: {
				method: 'put',
				url: `menu/categories/${categoryId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Patch category */
export const patchCategoryDetails = (
	categoryId: string,
	data: ICategoryPatch
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update category request
	const { payload } = await dispatch(
		PATCH_CATEGORY_DETAILS({
			request: {
				method: 'patch',
				url: `menu/categories/${categoryId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Delete category */
export const deleteCategory = (categoryId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// delete menu request
	const { payload } = await dispatch(
		DELETE_CATEGORY({
			request: {
				method: 'delete',
				url: `menu/categories/${categoryId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.status === 200;
};

// PRODUCTS

/** Get products list */
export const getProductsList = (filters?: IMenuListFilters) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get products list request
	const { payload } = await dispatch(
		GET_PRODUCTS_LIST({
			request: {
				method: 'get',
				url: 'menu/products',
				params: filters,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/** Get product details */
export const getProductDetails = (productId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get product details request
	const { payload } = await dispatch(
		GET_PRODUCT_DETAILS({
			request: {
				method: 'get',
				url: `menu/products/${productId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/** Get product nutritional information */
export const getProductNutrition = (productId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get product nutrition request
	const { payload } = await dispatch(
		GET_PRODUCT_NUTRITION({
			request: {
				method: 'get',
				url: `menu/products/${productId}/nutritional`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/* Get product price bands */
export const getProductPriceBands = (productId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get product nutrition request
	const { payload } = await dispatch(
		GET_PRODUCT_PRICE_BANDS({
			request: {
				method: 'get',
				url: `menu/products/${productId}/price-bands`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/* Create product */
export const createProduct = (data: IProductDetailsSubmitValues) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// create product request
	const { payload } = await dispatch(
		CREATE_PRODUCT({
			request: {
				method: 'post',
				url: 'menu/products',
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Update product */
export const updateProductDetails = (
	productId: string,
	data: IProductDetailsSubmitValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update product request
	const { payload } = await dispatch(
		UPDATE_PRODUCT_DETAILS({
			request: {
				method: 'put',
				url: `menu/products/${productId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Patch product */
export const patchProductDetails = (
	productId: string,
	data: IProductPatch
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update product request
	const { payload } = await dispatch(
		PATCH_PRODUCT_DETAILS({
			request: {
				method: 'patch',
				url: `menu/products/${productId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Update product nutritional information */
export const updateProductNutrition = (
	productId: string,
	data: IProductNutritionFormValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update product nutrition request
	const { payload } = await dispatch(
		UPDATE_PRODUCT_NUTRITION({
			request: {
				method: 'put',
				url: `menu/products/${productId}/nutritional`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Update modifier nutritional information */
export const updateModifierNutrition = (
	modifierItemId: string,
	data: IProductNutritionFormValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update product nutrition request
	const { payload } = await dispatch(
		UPDATE_MODIFIER_NUTRITION({
			request: {
				method: 'put',
				url: `menu/modifier-items/${modifierItemId}/nutritional`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/** Get modifier nutritional information */
export const getModifierNutrition = (modifierItemId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get product nutrition request
	const { payload } = await dispatch(
		GET_MODIFIER_NUTRITION({
			request: {
				method: 'get',
				url: `menu/modifier-items/${modifierItemId}/nutritional`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/* Delete product */
export const deleteProduct = (productId: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// delete menu request
	const { payload } = await dispatch(
		DELETE_PRODUCT({
			request: {
				method: 'delete',
				url: `menu/products/${productId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.status === 200;
};

// MODIFIER GROUP

/** Get modifier groups list */
export const getModifierGroupsList = (filters?: IMenuListFilters) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get modifier groups list request
	const { payload } = await dispatch(
		GET_MODIFIER_GROUPS_LIST({
			request: {
				method: 'get',
				url: 'menu/modifier-groups',
				headers: { 'account-id': getState().auth.activeAccountId },
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** Get modifier group details */
export const getModifierGroupDetails = (id: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get modifier group details request
	const { payload } = await dispatch(
		GET_MODIFIER_GROUP_DETAILS({
			request: {
				method: 'get',
				url: `menu/modifier-groups/${id}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/* Create modifier group */
export const createModifierGroup = (
	data: IModifierGroupDetailsSubmitValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// create modifier group request
	const { payload } = await dispatch(
		CREATE_MODIFIER_GROUP({
			request: {
				method: 'post',
				url: 'menu/modifier-groups',
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Update modifier group */
export const updateModifierGroupDetails = (
	id: string,
	data: IModifierGroupDetailsSubmitValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update modifier group request
	const { payload } = await dispatch(
		UPDATE_MODIFIER_GROUP_DETAILS({
			request: {
				method: 'put',
				url: `menu/modifier-groups/${id}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Patch modifier group */
export const patchModifierGroupDetails = (
	modifierGroupId: string,
	data: IModifierGroupPatch
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update modifier group request
	const { payload } = await dispatch(
		PATCH_MODIFIER_GROUP_DETAILS({
			request: {
				method: 'patch',
				url: `menu/modifier-groups/${modifierGroupId}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Delete modifier group */
export const deleteModifierGroup = (id: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// delete modifier group request
	const { payload } = await dispatch(
		DELETE_MODIFIER_GROUP({
			request: {
				method: 'delete',
				url: `menu/modifier-groups/${id}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.status === 200;
};

// MODIFIER ITEM

/** Get modifier items list */
export const getModifierItemsList = (filters?: IMenuListFilters) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get modifier items list request
	const { payload } = await dispatch(
		GET_MODIFIER_ITEMS_LIST({
			request: {
				method: 'get',
				url: 'menu/modifier-items',
				headers: { 'account-id': getState().auth.activeAccountId },
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** Get modifier item details */
export const getModifierItemDetails = (id: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get modifier item details request
	const { payload } = await dispatch(
		GET_MODIFIER_ITEM_DETAILS({
			request: {
				method: 'get',
				url: `menu/modifier-items/${id}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/** Get modifier item price bands */
export const getModifierItemPriceBands = (id: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// get modifier item details request
	const { payload } = await dispatch(
		GET_MODIFIER_ITEM_PRICE_BANDS({
			request: {
				method: 'get',
				url: `menu/modifier-items/${id}/price-bands`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.data;
};

/* Create modifier item */
export const createModifierItem = (data: IModifierItemSubmitValues) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// create modifier item request
	const { payload } = await dispatch(
		CREATE_MODIFIER_ITEM({
			request: {
				method: 'post',
				url: 'menu/modifier-items',
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Update modifier item */
export const updateModifierItemDetails = (
	id: string,
	data: IModifierItemSubmitValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	// update modifier item request
	const { payload } = await dispatch(
		UPDATE_MODIFIER_ITEM_DETAILS({
			request: {
				method: 'put',
				url: `menu/modifier-items/${id}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Patch modifierItem */
export const patchModifierItemDetails = (
	id: string,
	data: IModifierItemPatch
) => async (dispatch: Dispatch, getState: () => IState) => {
	// patch modifier item request
	const { payload } = await dispatch(
		PATCH_MODIFIER_ITEM_DETAILS({
			request: {
				method: 'patch',
				url: `menu/modifier-items/${id}`,
				headers: { 'account-id': getState().auth.activeAccountId },
				data,
			},
		})
	);

	return payload?.data;
};

/* Delete modifier item */
export const deleteModifierItem = (id: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// delete modifier item request
	const { payload } = await dispatch(
		DELETE_MODIFIER_ITEM({
			request: {
				method: 'delete',
				url: `menu/modifier-items/${id}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return payload?.status === 200;
};

// SUGGESTED PAIRING

/** Get suggested pairing list */
export const getSuggestedPairingList = (params?: IPairingListFilters) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	const { payload } = await dispatch(
		GET_PAIRING_LIST({
			request: {
				method: 'get',
				url: 'menu/suggested-pairing-lists',
				params,
				headers: {
					'account-id': getState().auth.activeAccountId,
				},
			},
		})
	);

	return payload?.data;
};

/* Create suggested pairing item */
export const createSuggestedPairingItem = (
	data: IProductPairingSubmitValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	const { payload } = await dispatch(
		CREATE_PAIRING_ITEM({
			request: {
				method: 'post',
				url: 'menu/suggested-pairing-lists',
				data,
				headers: {
					'account-id': getState().auth.activeAccountId,
				},
			},
		})
	);

	return payload?.data;
};

/** Get suggested pairing item */
export const getSuggestedPairingItem = (id: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	const { payload } = await dispatch(
		GET_PAIRING_ITEM({
			request: {
				method: 'get',
				url: `menu/suggested-pairing-lists/${id}`,
				headers: { 'account-id': getState().auth.activeAccountId },
			},
		})
	);

	return !!payload?.data;
};

/* Update suggested pairing item */
export const updateSuggestedPairingItem = (
	id: string,
	data: IProductPairingSubmitValues
) => async (dispatch: Dispatch, getState: () => IState) => {
	const { payload } = await dispatch(
		UPDATE_PAIRING_ITEM({
			request: {
				method: 'put',
				url: `menu/suggested-pairing-lists/${id}`,
				data,
				headers: {
					'account-id': getState().auth.activeAccountId,
				},
			},
		})
	);

	return payload?.data;
};

/* Delete suggested pairing item */
export const deleteSuggestedPairingItem = (id: string) => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	// delete modifier item request
	const { payload } = await dispatch(
		DELETE_PAIRING_ITEM({
			request: {
				method: 'delete',
				url: `menu/suggested-pairing-lists/${id}`,
				headers: {
					'account-id': getState().auth.activeAccountId,
				},
			},
		})
	);

	return payload?.status === 200;
};

/** Get products for pairing */
export const getPairingProductSearchResults = (
	search: string,
	type: EProductPairingSearchType,
	suggestedPairingListId?: string
) => async (dispatch: Dispatch, getState: () => IState) => {
	// get products list request
	const { payload } = await dispatch(
		GET_PAIRING_PRODUCT_SEARCH({
			request: {
				method: 'get',
				url: 'menu/suggested-pairing-product-categories',
				params: {
					search,
					type,
					suggestedPairingListId,
				},
				headers: {
					'account-id': getState().auth.activeAccountId,
				},
			},
		})
	);

	// Convert IPairingGroupedSearchResult[] to IItemSelectGroup[]
	return (
		payload?.data?.data?.map(
			(group: IPairingGroupedSearchResult): IItemSelectGroup => ({
				title: group.name,
				items: group.products.map(
					(item: IPairingSearchResult): IItemSelect => ({
						id: item.entitySku,
						title: item.name,
						disabled: item.disabled,
						disabledMessage: intl.formatMessage(
							{
								id:
									'form.fields.baseSelectedProducts.disabled.description',
							},
							{ listName: item.existingListName }
						),
					})
				),
			})
		) || []
	);
};

/** Get Checkout Upsell Config */
export const getCheckoutUpsellConfig = () => async (
	dispatch: Dispatch,
	getState: () => IState
) => {
	const { payload } = await dispatch(
		GET_CHECKOUT_UPSELL_CONFIG({
			request: {
				method: 'get',
				url: 'menu/checkout-upsell-config',
				params: {
					entity: 'account',
					id: getState().auth.activeAccountId,
				},
				headers: {
					'account-id': getState().auth.activeAccountId,
				},
			},
		})
	);

	return {
		...payload?.data,
		tableServiceTypes: payload?.data.tableServiceTypes.map(
			(value: ETableServiceType) => ({
				label: value.toString(),
				value: value.toString(),
			})
		),
		upsellProducts: payload?.data.upsellProducts.map(
			(product: IUpsellProduct) => ({
				id: product.entitySku,
				title: product.name,
				disabled: product.disabled || false,
			})
		),
	};
};

/** Update Checkout Upsell Config */
export const updateCheckoutUpsellConfig = (
	data: ICheckoutUpsellConfig
) => async (dispatch: Dispatch, getState: () => IState) => {
	const { payload } = await dispatch(
		UPDATE_CHECKOUT_UPSELL_CONFIG({
			request: {
				method: 'put',
				url: 'menu/checkout-upsell-config',
				data,
				headers: {
					'account-id': getState().auth.activeAccountId,
				},
			},
		})
	);

	return payload?.status === 200;
};

/* Search for products for upsell */
export const getCheckoutUpsellProductList = (
	search: string,
	checkoutUpsellConfigId: string
) => async (dispatch: Dispatch, getState: () => IState) => {
	// get checkout upsell list
	const { payload } = await dispatch(
		GET_CHECKOUT_UPSELL_PRODUCT_LIST({
			request: {
				method: 'get',
				url: 'menu/checkout-upsell-product-categories',
				params: {
					entity: 'account',
					id: getState().auth.activeAccountId,
					checkoutUpsellConfigId,
					search,
				},
				headers: {
					'account-id': getState().auth.activeAccountId,
				},
			},
		})
	);

	// Convert ICheckoutUpsellGroupedSearchResult[] to IItemSelectGroup[]
	return (
		payload?.data?.data?.map(
			(group: ICheckoutUpsellGroupedSearchResult): IItemSelectGroup => ({
				title: group.name,
				items: group.products.map(
					(item: IUpsellProduct): IItemSelect => ({
						id: item.entitySku,
						title: item.name,
						disabled: item.disabled || false,
					})
				),
			})
		) || []
	);
};

/** Reset Menu State */
export const resetMenuState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_MENU_STATE());
};

// Functions
/** Format menu availability opening times from API as form values */
export const formatMenuAvailabilityOpeningForForm = (
	data: IMenuOperatingTime[]
): IMenuAvailabilityFormOperatingTimes => {
	// Create default day
	const defaultDay = {
		open: false,
		openTime: '',
		closeTime: '',
	};

	// Create default form values
	const operatingTimes: IMenuAvailabilityFormOperatingTimes = {
		monday: defaultDay,
		tuesday: defaultDay,
		wednesday: defaultDay,
		thursday: defaultDay,
		friday: defaultDay,
		saturday: defaultDay,
		sunday: defaultDay,
	};

	// Array of days of the week
	const days = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	];

	// Loop through days in data
	for (const day of data) {
		// Add each day to form values
		operatingTimes[days[day.dayOfWeek]] = {
			open: true,
			openTime: day.openTime,
			closeTime: day.closeTime,
		};
	}

	return operatingTimes;
};

/** Format menu availability opening times from form values for API */
export const formatMenuAvailabilityOpeningForApi = (
	values: IMenuAvailabilityFormOperatingTimes
): IMenuOperatingTime[] => {
	// Array of days of the week
	const days = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	];

	return Object.entries(values)
		.filter((day) => day[1].open)
		.map((day) => {
			return {
				dayOfWeek: days.indexOf(day[0]),
				dayOfWeekName: day[0],
				openTime: day[1].openTime || '',
				closeTime: day[1].closeTime || '',
			};
		});
};

/** Thunk to import becaons */
export const importVenueMenu = (
	venueId: string,
	data: IMenuImportValues
) => async (dispatch: Dispatch) => {
	// Create form data
	const formData = new FormData();

	// Create form data from form values
	for (const [key, value] of Object.entries(data)) {
		formData.append(key, value);
	}

	// send form
	const { payload } = await dispatch(
		IMPORT_MENU({
			request: {
				method: 'post',
				url: `menu/import-export/${venueId}`,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		})
	);

	return payload?.status === 200;
};

/** Thunk to get an exported menu */
export const getExportedMenu = (venueId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_EXPORTED_MENU({
			request: {
				method: 'get',
				url: `menu/import-export/${venueId}`,
			},
		})
	);

	if (payload?.data && payload.data?.Location) {
		return payload.data.Location;
	}
	return null;
};

export default menuSlice.reducer;
