import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { toggleNav } from './nav.slice';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import structure from 'assets/styles/variables/structure';
import Icon from 'components/icons/icon.component';
import iconsList from 'components/icons/icons.config';
import LogoBlock from 'components/logo-block/logo-block.component';
import NavTooltip from 'components/nav-tooltip/nav-tooltip.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { processLogout } from 'modules/auth/auth.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

interface IStyledComponent {
	isNavOpen: boolean;
}

const StyledNav = styled.nav<IStyledComponent>`
	width: ${({ isNavOpen }) =>
		isNavOpen ? structure.nav_width_open : structure.nav_width_closed};
	transition: width 0.2s ease-in;
	height: 100vh;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;

	.nav-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.sub-nav {
		flex-grow: 1;
	}

	.sub-settings {
		margin-top: auto;
	}

	.background {
		width: 100%;
		height: 100%;
		border-radius: 0 10px 10px 0;
		overflow: hidden;
		background: ${brand.white};
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
`;

const StyledOpener = styled.div<IStyledComponent>`
	position: absolute;
	width: 10px;
	height: 100%;
	top: 0;
	right: 0;
	background: ${brand.neutral};
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px solid ${brand.body};
	cursor: pointer;

	svg {
		transform: rotate(${({ isNavOpen }) => (isNavOpen ? '180deg' : '0')});
	}

	&:hover {
		svg {
			color: ${brand.primary};
		}
	}
`;

const StyledListItemCss = css`
	padding: 20px;
	margin: 0 0 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	color: ${brand.black};
	transition: all 0.2s ease-in;

	&.mod-open-nav {
		padding-left: 25px;
	}

	&:hover,
	&.mod-active {
		background: ${brand.secondary};
		color: ${brand.primary};

		svg {
			color: ${brand.primary};
		}
	}
`;

const StyledLink = styled(NavLink)`
	${StyledListItemCss};
`;

const StyledA = styled.a`
	${StyledListItemCss};
`;

const StyledLinkNameVisibleCss = css`
	width: calc(100% - 50px);
	overflow: visible;
	opacity: 1;
	margin-left: 15px;
`;

const StyledLinkName = styled.div<IStyledComponent>`
	width: 0;
	overflow: hidden;
	opacity: 0;
	transition: all 0.2s ease-in;
	transition-property: width, overflow, opacity, margin;
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};

	${({ isNavOpen }) => isNavOpen && StyledLinkNameVisibleCss}
`;

const StyledIconContainer = styled.div<IStyledComponent>`
	cursor: pointer;
	border-top: 1px solid ${brand.borders};
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: ${({ isNavOpen }) => (isNavOpen ? '25px' : '0')};
	transition: padding 0.2s ease-in;

	&:hover {
		background: ${brand.secondary};
		color: ${brand.primary};

		svg {
			color: ${brand.primary};
		}
	}
`;

/** Renders navigation component */
const Nav: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get react router history
	const history = useHistory();
	// Get whole state object from store
	const isNavOpen = useSelector((state: RootState) => state.nav.open);

	interface ILinks {
		id: number;
		route: string;
		title: string;
		icon: keyof typeof iconsList;
		isHidden?: boolean;
	}

	// Links for top of nav bar
	const navLinks: ILinks[] = [
		{
			id: 1,
			route: 'accounts',
			title: intl.formatMessage({ id: 'navigation.item.accounts' }),
			icon: 'accounts',
		},
		{
			id: 2,
			route: 'brands',
			title: intl.formatMessage({ id: 'navigation.item.brands' }),
			icon: 'brands',
		},
		{
			id: 3,
			route: 'venues',
			title: intl.formatMessage({ id: 'navigation.item.venues' }),
			icon: 'venues',
		},
		{
			id: 4,
			route: 'orders',
			title: intl.formatMessage({ id: 'navigation.item.orders' }),
			icon: 'orders',
		},
		{
			id: 5,
			route: 'menus/menus',
			title: intl.formatMessage({ id: 'navigation.item.menus' }),
			icon: 'menu',
		},
	];

	// Add import link to nav
	process.env.REACT_APP_ENABLE_IMPORT === 'true' &&
		navLinks.push({
			id: 6,
			route: 'import',
			title: intl.formatMessage({ id: 'navigation.item.import' }),
			icon: 'data',
		});

	// Links for bottom of nav bar
	const settingsLinks: ILinks[] = [
		{
			id: 1,
			route: 'reports/reports',
			title: intl.formatMessage({ id: 'navigation.item.reports' }),
			icon: 'reports',
		},
		{
			id: 2,
			route: 'operations/refunds',
			title: intl.formatMessage({ id: 'navigation.item.operations' }),
			icon: 'operations',
		},
		{
			id: 3,
			route: 'staff',
			title: intl.formatMessage({ id: 'navigation.item.staff' }),
			icon: 'staff',
		},
		{
			id: 4,
			route: 'app-users',
			title: intl.formatMessage({ id: 'navigation.item.appUsers' }),
			icon: 'appUser',
		},
		{
			id: 5,
			route: 'profile',
			title: intl.formatMessage({ id: 'navigation.item.profile' }),
			icon: 'users',
		},
	];

	const isLocal = process.env.REACT_APP_ENVIRONMENT === 'local';

	return (
		<StyledNav isNavOpen={isNavOpen}>
			<div className="background" />
			<LogoBlock showTitle={isNavOpen} />
			<div className="nav-wrapper">
				<div className="sub-nav">
					{navLinks.map((item) =>
						!isLocal && item.isHidden ? (
							''
						) : (
							<NavTooltip
								key={item.id}
								text={item.title}
								arrow="left"
								hidden={isNavOpen}
								position={{ top: '21px', left: '60px' }}
							>
								<StyledLink
									to={`/${item.route}`}
									className={`${isNavOpen && 'mod-open-nav'}`}
									activeClassName="mod-active"
									title={item.title}
									aria-label={`${item.title.toLowerCase}-nav-link`}
								>
									<Icon
										name={item.icon}
										width={20}
										height={20}
									/>
									<StyledLinkName isNavOpen={isNavOpen}>
										{item.title}
									</StyledLinkName>
								</StyledLink>
							</NavTooltip>
						)
					)}
				</div>
				<div className="sub-settings">
					<NavTooltip
						text={`${intl.formatMessage({
							id: 'navigation.item.support',
						})}`}
						arrow="left"
						hidden={isNavOpen}
						position={{ top: '21px', left: '60px' }}
					>
						<StyledA
							target="_blank"
							href="https://support.orderpay.com/support/solutions/79000057353"
							className={`${isNavOpen && 'mod-open-nav'}`}
							aria-label="support-nav-link"
						>
							<Icon name="support" width={20} height={20} />
							<StyledLinkName isNavOpen={isNavOpen}>
								<FormattedMessage id="navigation.item.support" />
							</StyledLinkName>
						</StyledA>
					</NavTooltip>
					{settingsLinks.map((item) =>
						!isLocal && item.isHidden ? (
							''
						) : (
							<NavTooltip
								key={item.id}
								text={item.title}
								arrow="left"
								hidden={isNavOpen}
								position={{ top: '21px', left: '60px' }}
							>
								<StyledLink
									to={`/${item.route}`}
									className={`${isNavOpen && 'mod-open-nav'}`}
									activeClassName="mod-active"
									key={item.id}
									title={item.title}
									aria-label={`${item.title.toLowerCase}-nav-link`}
								>
									<Icon
										name={item.icon}
										width={20}
										height={20}
									/>
									<StyledLinkName isNavOpen={isNavOpen}>
										{item.title}
									</StyledLinkName>
								</StyledLink>
							</NavTooltip>
						)
					)}
					<NavTooltip
						text={`${intl.formatMessage({
							id: 'navigation.item.signOut',
						})}`}
						arrow="left"
						hidden={isNavOpen}
						position={{ top: '21px', left: '60px' }}
					>
						<StyledIconContainer
							isNavOpen={isNavOpen}
							data-testid="nav-logout"
							onClick={async () => {
								// process logout
								await dispatch(processLogout());
								// push route to login page
								history.push('/');
							}}
						>
							<Icon name="logout" width={20} height={20} />
							<StyledLinkName isNavOpen={isNavOpen}>
								<FormattedMessage id="navigation.item.signOut" />
							</StyledLinkName>
						</StyledIconContainer>
					</NavTooltip>
				</div>
				<StyledOpener
					isNavOpen={isNavOpen}
					onClick={() => dispatch(toggleNav())}
				>
					<Icon name="chevron" width={10} height={10} />
				</StyledOpener>
			</div>
		</StyledNav>
	);
};

export default Nav;
