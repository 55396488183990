import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { formatPrice } from 'helpers/price-band.helper';
import { IProductPriceBand } from 'modules/menu/menu.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-child(1) {
		min-width: 165px;
		width: calc(100% - 75px);
	}

	&:last-child {
		width: 75px;
		min-width: 75px;
		text-align: center;
		margin: 0;
	}
`;

// Props for component
interface IProductPriceBandListItemProps {
	item: IProductPriceBand;
}

const ProductPriceBandItem: FunctionComponent<IProductPriceBandListItemProps> = ({
	item,
}) => {
	return (
		<StyledListItem aria-label="product-price-band-item">
			<StyledColumn data-testid="product-price-band-name">
				{item.priceBandDetails.name}
			</StyledColumn>
			<StyledColumn>{formatPrice(item.price)}</StyledColumn>
		</StyledListItem>
	);
};

export default ProductPriceBandItem;
