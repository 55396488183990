import { rgba } from 'polished';

import brand from '../variables/brand';
import fonts from '../variables/fonts';

/** Global structure styles */
const globalStructure = `
html {
	font-family: ${fonts.family};
	font-size: ${fonts.sizes.base};

	body {
		line-height: ${fonts.line_height.base};
		font-weight: ${fonts.weights.default};
		background: ${brand.body};
		color: ${brand.text};
		font-size: ${fonts.sizes.standard};
		margin: 0;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		-o-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-family: ${fonts.family};

		input, select, textarea, button {
			font-family: ${fonts.family};
		}
	}

	* {
		box-sizing: border-box;
	}

	a {
		text-decoration: none;
	}
}

::selection {
	background: ${rgba(brand.primary, 0.75)};
}

::-moz-selection {
	background: ${rgba(brand.primary, 0.75)};
}
`;

export default globalStructure;
