import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IImportState } from './import.types';

import {
	IVenueDetailsFormValues,
	IVenuePaymentFormValues,
} from 'modules/venue/venue.types';

// Create initial state
export const initialImportState: IImportState = {
	eventsInProgress: 0,
};

const importSlice = createSlice({
	name: 'import',
	initialState: initialImportState,
	reducers: {
		UPDATE_VENUE_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PAYMENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_PAYMENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PAYMENT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ZONAL_VENUES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ZONAL_VENUES_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ZONAL_VENUES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PUBLISH(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_PUBLISH_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PUBLISH_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	UPDATE_VENUE_DETAILS,
	UPDATE_VENUE_DETAILS_FAIL,
	UPDATE_VENUE_DETAILS_SUCCESS,
	UPDATE_VENUE_CONFIG,
	UPDATE_VENUE_CONFIG_FAIL,
	UPDATE_VENUE_CONFIG_SUCCESS,
	UPDATE_VENUE_PAYMENT,
	UPDATE_VENUE_PAYMENT_FAIL,
	UPDATE_VENUE_PAYMENT_SUCCESS,
	GET_ZONAL_VENUES,
	GET_ZONAL_VENUES_FAIL,
	GET_ZONAL_VENUES_SUCCESS,
	UPDATE_VENUE_PUBLISH,
	UPDATE_VENUE_PUBLISH_FAIL,
	UPDATE_VENUE_PUBLISH_SUCCESS,
} = importSlice.actions;

/** Update venue config */
export const updateVenueConfig = (
	venueId: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	config: any
) => async (dispatch: Dispatch) => {
	// Create request
	await dispatch(
		UPDATE_VENUE_CONFIG({
			request: {
				method: 'patch',
				url: `venue/${venueId}/config`,
				data: config,
			},
		})
	);
};

/** Update venue payment */
export const updateVenuePayment = (
	venueId: string,
	values: IVenuePaymentFormValues
) => async (dispatch: Dispatch) => {
	// Create request
	await dispatch(
		UPDATE_VENUE_PAYMENT({
			request: {
				method: 'put',
				url: `venue/${venueId}/payment`,
				data: {
					...values,
					orderpayPercentageFee: Number(values.orderpayPercentageFee),
				},
			},
		})
	);
};

/** Update venue published */
export const updateVenuePublish = (
	venueId: string,
	isPublished: boolean
) => async (dispatch: Dispatch) => {
	// Create request
	await dispatch(
		UPDATE_VENUE_PUBLISH({
			request: {
				method: 'patch',
				url: `venue/${venueId}/is-published`,
				data: { isPublished },
			},
		})
	);
};

/** Update brandId for venue */
export const updateVenueBrand = (venueId: string, brandId: string) => async (
	dispatch: Dispatch
) => {
	// Create update request
	const response = await dispatch(
		UPDATE_VENUE_DETAILS({
			request: {
				method: 'patch',
				url: `venue/${venueId}/details`,
				data: { brandId },
			},
		})
	);

	return response;
};

/** Update name for venue */
export const updateVenueName = (venueId: string, name: string) => async (
	dispatch: Dispatch
) => {
	// Create update request
	const response = await dispatch(
		UPDATE_VENUE_DETAILS({
			request: {
				method: 'patch',
				url: `venue/${venueId}/details`,
				data: { name },
			},
		})
	);

	return response;
};

/** Update venue images */
export const updateVenueImages = (
	venueId: string,
	images: IVenueDetailsFormValues
) => async (dispatch: Dispatch) => {
	// Create update request
	const { payload } = await dispatch(
		UPDATE_VENUE_DETAILS({
			request: {
				method: 'patch',
				url: `venue/${venueId}/details`,
				data: images,
			},
		})
	);

	return payload;
};

/** Get all zonal venues */
export const getZonalVenues = () => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		GET_ZONAL_VENUES({
			request: {
				method: 'get',
				url: 'zonal/venues',
			},
		})
	);

	return payload?.data;
};

export default importSlice.reducer;
