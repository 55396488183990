import { Formik, Field, Form, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { object } from 'yup';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationString } from 'helpers/validation.helper';
import { getBrandsList } from 'modules/brand/brand.slice';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { createVenue } from 'modules/venue/slices/venue.slice';
import { IVenueCreateFormValues } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IVenueCreateFormProps {
	history: History;
}

const VenueCreateForm: FunctionComponent<IVenueCreateFormProps> = ({
	history,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	// Get brands from store and prepare for select
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	useEffect(() => {
		const getData = async () => {
			await dispatch(getBrandsList({ pageSize: 500 }));
		};
		getData();
	}, [dispatch]);

	// Initial form values
	const initialValues: IVenueCreateFormValues = {
		brandId: '',
		name: '',
	};

	// Interface for form errors
	interface IFormValidation extends IVenueCreateFormValues {}

	// Validation schema
	const formValidationSchema = object<IFormValidation>().shape({
		brandId: genericValidationString({ fieldName: 'brand' }),
		name: genericValidationString({ fieldName: 'venueName' }),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IVenueCreateFormValues,
		{ setSubmitting }: FormikHelpers<IVenueCreateFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Create / update
		const response = await dispatch(createVenue(values));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.created.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		history.push(`/venues/edit/${response?.id}/details/details`);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				<StyledForm>
					<StyledFormFields>
						<StyledColumn>
							<h2>
								<FormattedMessage id="venueForm.headings.create" />
							</h2>
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.venueName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.venueName.label',
								})}
							/>
							<div data-testid="brand-select">
								<Field
									component={Select}
									isSearchable={true}
									label={intl.formatMessage({
										id: 'form.fields.brand.label',
									})}
									name="brandId"
									selectOptions={brandOptions}
								/>
							</div>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="venueForm.button.create" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default VenueCreateForm;
