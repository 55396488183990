import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import icons from './icons.config';

import brand from 'assets/styles/variables/brand';

// Interface for component props
interface IIconProps {
	name?: keyof typeof icons;
	colour?: keyof typeof brand;
	height?: number;
	width?: number;
	className?: string;
}

const StyledDiv = styled.div<IIconProps>`
	height: ${({ height }) => height}px;
	width: ${({ width }) => width}px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	svg {
		color: ${({ colour }) => (colour ? brand[colour] : brand.black)};
		width: 100%;
		height: 100%;
	}
`;

/** Renders Icon component */
const Icon: FunctionComponent<IIconProps> = ({
	name = 'logo',
	colour = 'black',
	height = 20,
	width = 20,
	className,
}) => {
	const IconContent = icons[name];
	return (
		<StyledDiv
			className={className}
			colour={colour}
			height={height}
			width={width}
			data-testid="svg-icon-div"
		>
			<IconContent />
		</StyledDiv>
	);
};

export default Icon;
