import { FieldProps } from 'formik';
import { rgba } from 'polished';
import React, {
	FunctionComponent,
	InputHTMLAttributes,
	useEffect,
	useMemo,
} from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FormElement from 'components/form-element/form-element.component';
import { intl } from 'modules/core/i18n/i18n.config';

// Interface for component props
interface IComponentProps {
	label?: string;
	minDate?: Date;
	maxDate?: Date;
	handleChangeEvent?: Function;
	isDisabled?: boolean;
	minTime?: string;
	timeType?: 'from' | 'to';
}

const StyledFormElement = styled(FormElement)`
	margin: 0 15px 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 1;

	&:last-of-type {
		margin-right: 0;
	}

	div,
	input {
		flex-shrink: 1;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	input {
		width: 100%;
		height: 40px;
		line-height: 40px;
		min-width: 59px;
		padding: 0 12px;
		border: 1px solid ${brand.borders};
		border-radius: 5px;
		font-size: ${fonts.sizes.standard};
		color: ${brand.text};

		&::placeholder {
			font-size: ${fonts.sizes.standard};
			font-weight: ${fonts.weights.regular};
			color: ${brand.placeholder};
		}

		&:active,
		&:focus {
			padding: 0 12px;
			border: 1px solid ${brand.link};
			outline: none;
			box-shadow: none;
		}

		&.has-error {
			background: ${rgba(brand.validation_error, 0.05)};
			border: 1px solid ${brand.validation_error};
		}
	}

	svg {
		display: none;
	}
`;

const StyledLabel = styled.label`
	margin: 0 10px 0 0;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.medium};
`;

/** Renders TimePickerInput component */
const TimePickerInput: FunctionComponent<
	IComponentProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	field,
	form,
	label,
	handleChangeEvent,
	minDate,
	maxDate,
	isDisabled,
	timeType,
	minTime,
	className,
}) => {
	// Variable for field label
	let fieldLabel = label;

	// Function to format time as HH:MM
	const formatTime = (dateToFormat: Date) => {
		if (dateToFormat) {
			return [dateToFormat.getHours(), dateToFormat.getMinutes()]
				.map((num: number) => `0${num}`.slice(-2))
				.join(':');
		}
		return '';
	};

	const [currentTimeValue, startTime, endTime] = useMemo(() => {
		// Create new date;
		const current = new Date();
		const start = new Date();
		const end = new Date();

		// If field value, set time correctly
		if (field.value && field.value.includes(':')) {
			// Split current field value by colon
			const timeSplit = field.value.split(':');
			// Set date time by current field value
			current.setHours(Number(timeSplit[0]));
			current.setMinutes(Number(timeSplit[1]));
		} else {
			// default to 00:00
			current.setHours(0);
			current.setMinutes(0);
		}

		if (minTime) {
			// Split current field value by colon
			const timeSplit = minTime.split(':');
			// Set date time by current field value
			start.setHours(Number(timeSplit[0]));
			start.setMinutes(Number(timeSplit[1]));
		} else {
			// default to 00:00
			start.setHours(0);
			start.setMinutes(0);
		}

		// Set end date to start date + 1 day
		end.setDate(start.getDate() + 1);
		end.setHours(23);
		end.setMinutes(59);

		return [current, start, end];
	}, [minTime, field.value]);

	useEffect(() => {
		// If start time is bigger than current time and we have a field value
		if (
			startTime.toISOString() > currentTimeValue.toISOString() &&
			field.value
		) {
			// set date to start time
			currentTimeValue.setHours(startTime.getHours());
			currentTimeValue.setMinutes(startTime.getMinutes());
			// set field value correctly
			form.setFieldValue(field.name, formatTime(currentTimeValue));
		}
	}, [currentTimeValue, startTime, form, field.value, field.name]);

	// If no field label
	if (!fieldLabel && timeType) {
		// set to default label
		fieldLabel =
			timeType === 'from'
				? intl.formatMessage({ id: 'form.fields.openingTime.label' })
				: intl.formatMessage({ id: 'form.fields.closingTime.label' });
	}

	return (
		<StyledFormElement className={className}>
			{(timeType || fieldLabel) && (
				<StyledLabel htmlFor={`${field.name}-input`}>
					{fieldLabel}
				</StyledLabel>
			)}
			<DatePicker
				id={`${field.name}-input`}
				selected={field.value ? currentTimeValue : null}
				onChange={(day: Date) => {
					form.setFieldValue(field.name, formatTime(day));
					if (handleChangeEvent) {
						handleChangeEvent({
							fieldValue: formatTime(day),
							form,
						});
					}
				}}
				showTimeSelect
				showTimeSelectOnly
				timeFormat="HH:mm"
				timeIntervals={15}
				timeCaption={intl.formatMessage({
					id: 'form.fields.datePicker.time',
				})}
				dateFormat="HH:mm"
				minDate={minDate}
				maxDate={maxDate}
				disabled={isDisabled}
				showPopperArrow={false}
				placeholderText="00:00"
				minTime={startTime}
				maxTime={endTime}
			/>
		</StyledFormElement>
	);
};

export default TimePickerInput;
