import { Formik, Form, FormikHelpers, Field } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { array, object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import MultiSelect from 'components/form-inputs/multi-select/multi-select.component';
import {
	IItemSelect,
	IItemSelectGroup,
} from 'components/form-inputs/multi-select/multi-select.types';
import SuggestedPairingSearch from 'components/suggested-pairing-search/suggested-pairing-search.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	createSuggestedPairingItem,
	deleteSuggestedPairingItem,
	getPairingProductSearchResults,
	updateSuggestedPairingItem,
} from 'modules/menu/menu.slice';
import {
	EProductPairingSearchType,
	IProductPairingFormValues,
	IProductPairingSubmitValues,
} from 'modules/menu/menu.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

const StyledSearchTitle = styled.div`
	font-size: ${fonts.sizes.med};
`;

const SearchLabel: FunctionComponent<{ step: number }> = ({ step }) => (
	<StyledSearchTitle>
		<strong>
			<FormattedMessage
				id={`form.fields.suggestedPairingSearch.step${step}.step`}
			/>
		</strong>
		<FormattedMessage
			id={`form.fields.suggestedPairingSearch.step${step}.label`}
		/>
	</StyledSearchTitle>
);

interface IComponentProps {
	pairingId?: string;
	activeProductPairing?: IProductPairingFormValues;
}

const SuggestedPairingDetailsForm: FunctionComponent<IComponentProps> = ({
	pairingId,
	activeProductPairing,
}) => {
	const history = useHistory();
	const dispatch = useReduxDispatch();

	const [formSubmission, setFormSubmission] = useState<boolean>(false);
	const [baseSearchTerm, setBaseSearchTerm] = useState<string>('');
	const [baseSearchData, setBaseSearchData] = useState<
		IItemSelect[] | IItemSelectGroup[]
	>([]);
	const [pairingSearchTerm, setPairingSearchTerm] = useState<string>('');
	const [pairingSearchData, setPairingSearchData] = useState<
		IItemSelect[] | IItemSelectGroup[]
	>([]);

	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	/**
	 * TODO
	 * - pairing list
	 */

	const initialValues: IProductPairingFormValues = {
		name: (pairingId && activeProductPairing?.name) || '',
		inAppDescription:
			(pairingId && activeProductPairing?.inAppDescription) || '',
		baseSelectedProducts:
			(pairingId && activeProductPairing?.baseSelectedProducts) || [],
		pairingSelectedProducts:
			(pairingId && activeProductPairing?.pairingSelectedProducts) || [],
	};

	/** Form submission handler */
	const handleSubmit = async (
		values: IProductPairingFormValues,
		{ setSubmitting }: FormikHelpers<IProductPairingFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		const submitValues: IProductPairingSubmitValues = {
			...values,
			baseSelectedProducts: values.baseSelectedProducts.map(
				(item: IItemSelect) => ({
					entitySku: item.id,
					name: item.title,
					disabled: item.disabled,
				})
			),
			pairingSelectedProducts: values.pairingSelectedProducts.map(
				(item: IItemSelect) => ({
					entitySku: item.id,
					name: item.title,
					disabled: item.disabled,
				})
			),
		};

		const response = await dispatch(
			pairingId
				? updateSuggestedPairingItem(pairingId, submitValues)
				: createSuggestedPairingItem(submitValues)
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: pairingId
					? intl.formatMessage({
						id: 'suggestedPairing.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'suggestedPairing.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// If we're creating - go to edit newly created pairing item page
		!pairingId &&
			history.push(`/menus/suggested-pairing/edit/${response?.id}`);
	};

	// Handle pairing deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete pairing
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'suggestedPairing.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'suggestedPairing.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete pairing action
		pairingId && (await dispatch(deleteSuggestedPairingItem(pairingId)));

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'suggestedPairing.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to pairings list
		history.push(
			`/menus/suggested-pairing?activeAccount=${activeAccountId}`
		);
	};

	const formValidationSchema = object<IProductPairingFormValues>().shape({
		name: string().required(validationMessages.required('pairingListName')),
		inAppDescription: string().required(
			validationMessages.required('inAppDescription')
		),
		baseSelectedProducts: array().required(
			validationMessages.required('baseSelectedProducts')
		),
		pairingSelectedProducts: array().required(
			validationMessages.required('pairingSelectedProducts')
		),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="suggestedPairing.form.title" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<SuggestedPairingSearch
								testId="baseSearch"
								label={<SearchLabel step={1} />}
								handleSubmit={async (searchTerm: string) => {
									setBaseSearchTerm(searchTerm);
									setBaseSearchData(
										await dispatch(
											getPairingProductSearchResults(
												searchTerm,
												EProductPairingSearchType.Base,
												pairingId
											)
										)
									);
								}}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.pairingListName.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.pairingListName.placeholder',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledFormFields>
						<Field
							component={MultiSelect}
							isMulti
							name="baseSelectedProducts"
							labelSearch={intl.formatMessage(
								{
									id: `form.fields.baseSelectedProducts.labelSearch.${
										baseSearchTerm
											? 'hasSearchTerm'
											: 'noSearchTerm'
									}`,
								},
								{
									searchTerm: baseSearchTerm,
								}
							)}
							labelSelect={intl.formatMessage({
								id: 'form.fields.baseSelectedProducts.label',
							})}
							searchItems={baseSearchData}
							sortable={false}
							maxHeight={200}
						/>
					</StyledFormFields>
					<StyledFormFields>
						<StyledColumn>
							<SuggestedPairingSearch
								testId="pairingSearch"
								label={<SearchLabel step={2} />}
								handleSubmit={async (searchTerm: string) => {
									setPairingSearchTerm(searchTerm);
									setPairingSearchData(
										await dispatch(
											getPairingProductSearchResults(
												searchTerm,
												EProductPairingSearchType.Suggested
											)
										)
									);
								}}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="inAppDescription"
								label={intl.formatMessage({
									id: 'form.fields.inAppDescription.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.inAppDescription.placeholder',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledFormFields>
						<Field
							component={MultiSelect}
							isMulti
							name="pairingSelectedProducts"
							labelSearch={intl.formatMessage(
								{
									id: `form.fields.pairingSelectedProducts.labelSearch.${
										pairingSearchTerm
											? 'hasSearchTerm'
											: 'noSearchTerm'
									}`,
								},
								{
									searchTerm: pairingSearchTerm,
								}
							)}
							labelSelect={intl.formatMessage({
								id: 'form.fields.pairingSelectedProducts.label',
							})}
							searchItems={pairingSearchData}
							maxHeight={200}
						/>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link
								to={`/menus/suggested-pairing?activeAccount=${activeAccountId}`}
							>
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{pairingId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="suggestedPairing.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default SuggestedPairingDetailsForm;
