import { Formik, Field, Form, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams, Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import {
	updateAccount,
	createAccount,
	deleteAccount,
} from '../../account.slice';
import { IAccount, IAccountFormValues } from '../../account.types';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import DisplayValue from 'components/form-inputs/display-value/display-value.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Input from 'components/form-inputs/input/input.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationString } from 'helpers/validation.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledAccountForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledH2 = styled.h2`
	margin-top: 40px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	history: History;
}

// Account form page
const AccountDetailsForm: FunctionComponent<IComponentProps> = ({
	history,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get account id from url params
	const { accountId }: { accountId?: string } = useParams();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active account id from store
	const activeAccount: IAccount | undefined = useSelector(
		(state: RootState) => state.account.activeAccount
	);

	// Initial form values
	const initialValues: IAccountFormValues = {
		name: (accountId && activeAccount?.name) || '',
		vatNo: (accountId && activeAccount?.vatNo) || '',
		logoUrl: (accountId && activeAccount?.logoUrl) || '',
		zonalApiKey: (accountId && activeAccount?.zonalApiKey) || '',
		zonalBundleIdentifier:
			(accountId && activeAccount?.zonalBundleIdentifier) || '',
		zonalUserAgent: (accountId && activeAccount?.zonalUserAgent) || '',
		zonalEstateId: (accountId && activeAccount?.zonalEstateId) || '',
	};

	// Interface for form errors
	interface IFormValidation {
		name: string;
		zonalApiKey?: string;
		zonalBundleIdentifier?: string;
		zonalUserAgent?: string;
	}

	// Validation schema
	const formValidationSchema = object<IFormValidation>().shape(
		{
			name: genericValidationString({ fieldName: 'accountName' }),
			zonalApiKey: string().when(
				['zonalBundleIdentifier', 'zonalUserAgent', 'zonalEstateId'],
				{
					is: (
						zonalBundleIdentifier,
						zonalUserAgent,
						zonalEstateId
					) =>
						!!zonalBundleIdentifier ||
						!!zonalUserAgent ||
						!!zonalEstateId,
					then: genericValidationString({ fieldName: 'zonalApiKey' }),
				}
			),
			zonalBundleIdentifier: string().when(
				['zonalApiKey', 'zonalUserAgent', 'zonalEstateId'],
				{
					is: (zonalApiKey, zonalUserAgent, zonalEstateId) =>
						!!zonalApiKey || !!zonalUserAgent || !!zonalEstateId,
					then: genericValidationString({
						fieldName: 'zonalBundleIdentifier',
					}),
				}
			),
			zonalUserAgent: string().when(
				['zonalApiKey', 'zonalBundleIdentifier', 'zonalEstateId'],
				{
					is: (zonalApiKey, zonalBundleIdentifier, zonalEstateId) =>
						!!zonalApiKey ||
						!!zonalBundleIdentifier ||
						!!zonalEstateId,
					then: genericValidationString({
						fieldName: 'zonalUserAgent',
					}),
				}
			),
			zonalEstateId: string().when(
				['zonalApiKey', 'zonalBundleIdentifier', 'zonalUserAgent'],
				{
					is: (zonalApiKey, zonalBundleIdentifier, zonalUserAgent) =>
						!!zonalApiKey ||
						!!zonalBundleIdentifier ||
						!!zonalUserAgent,
					then: genericValidationString({
						fieldName: 'zonalEstateId',
					}),
				}
			),
		},
		[
			['zonalApiKey', 'zonalBundleIdentifier'],
			['zonalApiKey', 'zonalUserAgent'],
			['zonalApiKey', 'zonalEstateId'],
			['zonalBundleIdentifier', 'zonalUserAgent'],
			['zonalBundleIdentifier', 'zonalEstateId'],
			['zonalUserAgent', 'zonalEstateId'],
		]
	);

	// Handle form submission
	const handleSubmit = async (
		values: IAccountFormValues,
		{ setSubmitting }: FormikHelpers<IAccountFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Create / update account
		const response = await (accountId
			? dispatch(updateAccount(accountId, values))
			: dispatch(createAccount(values)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: accountId
					? intl.formatMessage({
						id: 'accountForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'accountForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// If we're creating - go to edit newly created account page
		!accountId && history.push(`/accounts/edit/${response?.id}/details`);
	};

	// Handle account deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete account
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'accountForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'accountForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete account action
		const response =
			accountId && (await dispatch(deleteAccount(accountId)));

		// Return on fail
		if (!response) {
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'accountForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to accounts
		history.push('/accounts');
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledAccountForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="accountForm.heading" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.accountName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.accountName.label',
								})}
							/>
							<DisplayValue
								label={intl.formatMessage({
									id: 'form.fields.accountId.label',
								})}
								value={accountId}
								copyValue={true}
							/>
							<Field
								component={Input}
								name="vatNo"
								label={intl.formatMessage({
									id: 'form.fields.vatNo.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.vatNo.label',
								})}
								isPrivate={true}
							/>
						</StyledColumn>
						<StyledColumn>
							<StyledFieldGroup>
								<StyledColumn>
									<Field
										component={ImageUpload}
										name="logoUrl"
										label={intl.formatMessage({
											id:
												'form.fields.accountLogoUrl.label',
										})}
										uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/account/images/logo`}
									/>
								</StyledColumn>
							</StyledFieldGroup>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledH2>
						<FormattedMessage id="brandForm.headings.posConfig" />
					</StyledH2>
					<StyledFieldGroup data-private>
						<StyledColumn>
							<Field
								component={Input}
								name="zonalApiKey"
								label={intl.formatMessage({
									id: 'form.fields.zonalApiKey.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.zonalApiKey.label',
								})}
							/>
							<Field
								component={Input}
								name="zonalUserAgent"
								label={intl.formatMessage({
									id: 'form.fields.zonalUserAgent.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.zonalUserAgent.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="zonalBundleIdentifier"
								label={intl.formatMessage({
									id:
										'form.fields.zonalBundleIdentifier.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.zonalBundleIdentifier.label',
								})}
							/>
							<Field
								component={Input}
								name="zonalEstateId"
								label={intl.formatMessage({
									id: 'form.fields.zonalEstateId.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.zonalEstateId.label',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/accounts">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{accountId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="accountForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							ariaLabel="submit-button"
							disabled={isSubmitting}
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledAccountForm>
			)}
		</Formik>
	);
};

export default AccountDetailsForm;
