import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import dayjs from 'helpers/dayjs.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { generateStripeLoginLink } from 'modules/payment/payment.slice';
import {
	IPaymentConfig,
	IStripeLoginUrl,
	IStripePaymentConfig,
} from 'modules/payment/payment.types';

const StyledH3 = styled.h3`
	margin: 0 0 4px 0;
`;

const StyledLink = styled.a`
	color: ${brand.primary};
	text-decoration: underline;
	display: block;
	margin-bottom: 16px;
	font-size: ${fonts.sizes.med};
	font-weight: bold;
`;

const StyledInfoGroup = styled.div`
	margin-bottom: 32px;
`;
const StyledInfo = styled.p`
	margin: 0 0 8px 0;

	&.mod-valid {
		color: ${brand.validation_success};
	}

	&.mod-invalid {
		color: ${brand.validation_error};
	}
`;

interface IComponentProps {
	paymentConfig: IPaymentConfig<IStripePaymentConfig>;
	stripeLoginLink?: IStripeLoginUrl;
}

const EditStripeMerchant: FunctionComponent<IComponentProps> = ({
	paymentConfig,
	stripeLoginLink,
}) => {
	const dispatch = useReduxDispatch();

	return (
		<>
			<StyledInfoGroup>
				<StyledInfo>
					<FormattedMessage id="form.fields.stripeAccountId.label" />:{' '}
					<strong>{paymentConfig.providerIdentifier}</strong>
				</StyledInfo>
				<StyledInfo
					className={`${
						paymentConfig.config.chargesEnabled
							? 'mod-valid'
							: 'mod-invalid'
					}`}
				>
					{paymentConfig.config.chargesEnabled ? (
						<FormattedMessage id="paymentConfigForm.info.chargesEnabled" />
					) : (
						<FormattedMessage id="paymentConfigForm.info.chargesDisabled" />
					)}
				</StyledInfo>
				<StyledInfo
					className={`${
						paymentConfig.config.payoutsEnabled
							? 'mod-valid'
							: 'mod-invalid'
					}`}
				>
					{paymentConfig.config.payoutsEnabled ? (
						<FormattedMessage id="paymentConfigForm.info.payoutsEnabled" />
					) : (
						<FormattedMessage id="paymentConfigForm.info.payoutsDisabled" />
					)}
				</StyledInfo>
			</StyledInfoGroup>
			<StyledInfoGroup>
				<StyledH3>
					<FormattedMessage id="paymentConfigForm.headings.stripeLoginLink" />
				</StyledH3>
				<StyledInfo>
					<FormattedMessage id="paymentConfigForm.info.stripeLoginLinkDescription" />{' '}
					{paymentConfig.entityType}.
				</StyledInfo>
				{!!stripeLoginLink && (
					<>
						<StyledInfo>
							<FormattedMessage id="paymentConfigForm.info.expiresAt" />
							:{' '}
							{dayjs(stripeLoginLink.expiresAt).format(
								'D MMM YYYY HH:mm'
							)}
						</StyledInfo>
						<StyledLink href={stripeLoginLink.url} target="_blank">
							<FormattedMessage id="paymentConfigForm.buttons.stripeLoginLink" />
						</StyledLink>
					</>
				)}
				<Button
					onClick={() =>
						dispatch(
							generateStripeLoginLink(
								paymentConfig.entityType,
								paymentConfig.entityId
							)
						)}
				>
					<FormattedMessage id="paymentConfigForm.buttons.generateStripeLoginLink" />
				</Button>
			</StyledInfoGroup>
		</>
	);
};

export default EditStripeMerchant;
