import MockAdapter from 'axios-mock-adapter/types';

import {
	IOrder,
	IOrderDetail,
	IOrderTransaction,
	IOrderRefund,
	EEntityType,
	IOrderAuditLog,
	ICustomerNotesItem,
} from './order.types';

/** MOCKED DATA */
export const orderDetail: IOrderDetail = {
	id: '9f4cc807-3bc3-4d6b-9ba1-5d25edeec63d',
	number: 'code0',
	status: 'completed',
	date: new Date().toString(),
	service: 'tableService',
	tableNumber: '1',
	customerName: 'John McClane',
	customerEmail: 'Yippeekiyay@gmail.com',
	accountName: 'Pizza Hut',
	brandName: 'Pizza Hut',
	venueName: 'North street',
};

export const customerNote: ICustomerNotesItem = {
	note: 'This is an example note',
	roundId: 1,
	addedAt: '2021-12-03T10:59:44.881Z',
};

export const orders: IOrder[] = [
	{
		id: '9f4cc807-3bc3-4d6b-9ba1-5d25edeec63d',
		orderNumber: 'code0',
		orderDate: new Date().toString(),
		customerName: 'Manish Soni',
		customerEmail: 'manish@studiographene.com',
		orderStatus: 'venueRejected',
		price: {
			units: 1665,
			currencyCode: 'GBP',
		},
	},
	{
		id: 'df8959f2-3cd6-4c97-a43b-b90f262402e4',
		orderNumber: '850932',
		orderDate: new Date().toString(),
		customerName: 'Manish Soni',
		customerEmail: 'manish@studiographene.com',
		orderStatus: 'pendingPickup',
		price: {
			units: 1665,
			currencyCode: 'GBP',
		},
	},
	{
		id: '3e0474eb-0f35-4ffa-9511-b0fee1b438ea',
		orderNumber: '903740',
		orderDate: new Date().toString(),
		customerName: 'Rich Mucha',
		customerEmail: 'rich@richappz.com',
		orderStatus: 'pendingPickup',
		price: {
			units: 1665,
			currencyCode: 'GBP',
		},
	},
	{
		id: 'b7e8d4d7-4275-459d-b106-e34cb49583ef',
		orderNumber: '324479',
		orderDate: new Date().toString(),
		customerName: 'Rich Mucha',
		customerEmail: 'rich@richappz.com',
		orderStatus: 'pendingPickup',
		price: {
			units: 3330,
			currencyCode: 'GBP',
		},
	},
];

export const orderTransactions: IOrderTransaction[] = [
	{
		id: 'ff21869a-2aef-440c-ab0e-199c9cds9',
		appUserId: 'accbed41-ef25-44d5-b6af-e449c30f6e01',
		orderId: 'b0ceec65-d281-41c2-8ca0-2bcb2dbe855f',
		cardId: null,
		total: '5450.00',
		status: 0,
		cccTransactionId: 'c2e94f1c-d460-414c-b9ad-5724aff60ee5',
		cccDeviceId: null,
		venueId: 'e6fb0780-af12-4c22-b728-0c061cc2d3fa',
		brandId: '1748dbf1-1c6e-4448-9b7d-5d11d010e7fe',
		accountId: null,
		source: '3C_WEBHOOK',
		isReversed: false,
		createdAt: '2020-07-16T15:58:38.209Z',
		updatedAt: '2020-07-16T15:58:38.209Z',
	},
	{
		id: 'ff21869a-2aef-440c-ab0e-320fcwesivsvm',
		appUserId: 'accbed41-ef25-44d5-b6af-e449c30f6e01',
		orderId: 'b0ceec65-d281-41c2-8ca0-2bcb2dbe855f',
		cardId: null,
		total: '5450.00',
		status: 0,
		cccTransactionId: 'c2e94f1c-d460-414c-b9ad-349vdkvdi',
		cccDeviceId: null,
		venueId: 'e6fb0780-af12-4c22-b728-0c061cc2d3fa',
		brandId: '1748dbf1-1c6e-4448-9b7d-5d11d010e7fe',
		accountId: null,
		source: '3C_WEBHOOK',
		isReversed: false,
		createdAt: '2020-07-16T15:58:38.209Z',
		updatedAt: '2020-07-16T15:58:38.209Z',
	},
	{
		id: 'ff21869a-2aef-440c-ab0e-asd3dssoxx',
		appUserId: 'accbed41-ef25-44d5-b6af-e449c30f6e01',
		orderId: 'b0ceec65-d281-41c2-8ca0-2bcb2dbe855f',
		cardId: null,
		total: '5450.00',
		status: 0,
		cccTransactionId: 'c2e94f1c-d460-414c-b9ad-5724aff60ee5',
		cccDeviceId: null,
		venueId: 'e6fb0780-af12-4c22-b728-0c061cc2d3fa',
		brandId: '1748dbf1-1c6e-4448-9b7d-5d11d010e7fe',
		accountId: null,
		source: '3C_WEBHOOK',
		isReversed: false,
		createdAt: '2020-07-16T15:58:38.209Z',
		updatedAt: '2020-07-16T15:58:38.209Z',
	},
	{
		id: 'ff21869a-2aef-440c-ab0e-ad3deodl',
		appUserId: 'accbed41-ef25-44d5-b6af-e449c30f6e01',
		orderId: 'b0ceec65-d281-41c2-8ca0-2bcb2dbe855f',
		cardId: null,
		total: '5450.00',
		status: 2,
		cccTransactionId: 'c2e94f1c-d460-414c-b9ad-5724aff60ee5',
		cccDeviceId: null,
		venueId: 'e6fb0780-af12-4c22-b728-0c061cc2d3fa',
		brandId: '1748dbf1-1c6e-4448-9b7d-5d11d010e7fe',
		accountId: null,
		source: '3C_WEBHOOK',
		isReversed: true,
		createdAt: '2020-07-16T15:58:38.209Z',
		updatedAt: '2020-07-16T15:58:38.209Z',
	},
	{
		id: 'ff21869a-2aef-440c-ab0e-asd3dascas',
		appUserId: 'accbed41-ef25-44d5-b6af-e449c30f6e01',
		orderId: 'b0ceec65-d281-41c2-8ca0-2bcb2dbe855f',
		cardId: null,
		total: '5450.00',
		status: 0,
		cccTransactionId: 'c2e94f1c-d460-414c-b9ad-5724aff60ee5',
		cccDeviceId: null,
		venueId: 'e6fb0780-af12-4c22-b728-0c061cc2d3fa',
		brandId: '1748dbf1-1c6e-4448-9b7d-5d11d010e7fe',
		accountId: null,
		source: '3C_WEBHOOK',
		isReversed: false,
		createdAt: '2020-07-16T15:58:38.209Z',
		updatedAt: '2020-07-16T15:58:38.209Z',
	},
];

const orderRefund: IOrderRefund = {
	amountInPence: 1000,
};

export const orderAudits: IOrderAuditLog[] = [
	{
		id: '1',
		dateCreated: new Date('2021-09-27T15:10:24.759Z'),
		entityType: EEntityType.basket,
		entityId: 'ddf8d14b-1095-413f-a362-5190745c4040',
		source: 'order-service',
		logType: 'VALIDATED',
		message: 'Basket valid',
		metadata: {
			paymentOption: 'payNow',
			basketTotal: 3.5,
			tip: {
				percent: null,
				amount: {
					currencyCode: 'GBP',
					units: 0,
				},
			},
			serviceCharge: {
				createdAt: '2021-09-27T15:10:20.379Z',
				basketId: 'ddf8d14b-1095-413f-a362-5190745c4040',
				amount: 1000,
				editable: true,
				name: 'Feathers Service Charge',
				id: '6407b481-7689-4cad-8968-94e7668ab5b8',
				type: 'amount',
				message: 'Just pay me',
				percent: null,
				updatedAt: '2021-09-27T15:10:20.379Z',
			},
			serviceCharge2nd: {
				createdAt: '2021-09-27T15:10:20.379Z',
				basketId: 'ddf8d14b-1095-413f-a362-5190745c4040',
				amount: 1000,
				editable: true,
				name: 'Feathers Service Charge',
				id: '6407b481-7689-4cad-8968-94e7668ab5b8',
				type: 'amount',
				message: 'Just pay me',
				percent: null,
				updatedAt: '2021-09-27T15:10:20.379Z',
			},
		},
	},
	{
		id: '2',
		dateCreated: new Date('2021-09-27T15:10:20.312Z'),
		entityType: EEntityType.basket,
		entityId: 'ddf8d14b-1095-413f-a362-5190745c4040',
		source: 'order-service',
		logType: 'UPDATED',
		message: 'Basket updated',
		metadata: {
			paymentOption: 'payNow',
			basketTotal: 3.5,
		},
	},
	{
		id: '3',
		dateCreated: new Date('2021-09-27T15:10:20.312Z'),
		entityType: EEntityType.basket,
		entityId: 'ddf8d14b-1095-413f-a362-5190745c4040',
		source: 'order-service',
		logType: 'UPDATED',
		message: 'Basket updated',
	},
	{
		id: '4',
		dateCreated: new Date('2021-09-27T14:40:01.996Z'),
		entityType: EEntityType.basket,
		entityId: 'ddf8d14b-1095-413f-a362-5190745c4040',
		source: 'order-service',
		logType: 'VALIDATED',
		message: 'Basket valid',
		metadata: {
			paymentOption: 'payNow',
			basketTotal: 3.5,
			tip: {
				amount: {
					currencyCode: 'GBP',
					units: 0,
				},
			},
			serviceCharge: {
				createdAt: '2021-09-27T14:39:56.686Z',
				basketId: 'ddf8d14b-1095-413f-a362-5190745c4040',
				amount: 1000,
				editable: true,
				name: 'Feathers Service Charge',
				id: 'a8f945a2-4d9d-48b6-83df-98e467f6ae1a',
				type: 'amount',
				message: 'Just pay me',
				percent: null,
				updatedAt: '2021-09-27T14:39:56.686Z',
			},
		},
	},
	{
		id: '5',
		dateCreated: new Date('2021-09-27T14:39:56.652Z'),
		entityType: EEntityType.basket,
		entityId: 'ddf8d14b-1095-413f-a362-5190745c4040',
		source: 'order-service',
		logType: 'CREATED',
		message: 'Basket created',
		metadata: {
			paymentOption: 'payNow',
			basketTotal: 3.5,
		},
	},
];

/** MOCKED ENDPOINTS CONFIG */

const getAllOrders = {
	response: {
		pagination: {
			pageSize: 20,
			pageNumber: 1,
			pageCount: 1,
		},
		orders,
	},
};

const getAudits = {
	response: orderAudits,
};

const getOrder = {
	response: orderDetail,
};

const getOrderTransaction = {
	response: orderTransactions,
};

// Regex for order endpoint
export const orderEndpointRegex = new RegExp(
	/order\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for transactions endpoint
export const orderTransactionEndpointRegex = new RegExp(
	/payments\/order\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for refund endpoint
export const orderRefundEndpointRegex = new RegExp(
	/payments\/refund\/order\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for order endpoint
export const orderAuditsEndpointRegex = new RegExp(
	/audits\/order\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

export const mockedOrderEndpoints = (mockHttpClient: MockAdapter) => {
	// Mock get order transaction response
	mockHttpClient
		.onGet(orderTransactionEndpointRegex)
		.reply(200, mockOrders.endpoints.getOrderTransaction.response);
	// Mock get order audits response
	mockHttpClient
		.onGet(orderAuditsEndpointRegex)
		.reply(200, mockOrders.endpoints.getAudits.response);
	// Mock getOrder response
	mockHttpClient
		.onGet(orderEndpointRegex)
		.reply(200, mockOrders.endpoints.getOrder.response);
	// Mock list orders response
	mockHttpClient
		.onGet('order')
		.reply(200, mockOrders.endpoints.getAllOrders.response);
	// Mock order refund response
	mockHttpClient.onPost(orderRefundEndpointRegex).reply(200);
};

const mockOrders = {
	data: {
		orders,
		orderDetail,
		orderTransactions,
		orderRefund,
		orderAudits,
	},
	endpoints: {
		getAllOrders,
		getOrder,
		getOrderTransaction,
		getAudits,
	},
};
export default mockOrders;
