import Uppy from '@uppy/core';
import { DashboardModal, useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import VenuePrinterForm from './venue-printer-form/venue-printer-form.component';
import VenuePrinterList from './venue-printer-list/venue-printer-list.component';

import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import ImageManagementModal from 'components/form-inputs/image-upload/image-management-modal/image-management-modal.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenuePrinter,
	getVenuePrintersList,
	getVenuePrinterImage,
	updateVenuePrinterImage,
} from 'modules/venue/slices/venue.slice';

const StyledActions = styled.div`
	padding: 0 0 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	Button:not(:last-child) {
		margin-right: 20px;
	}
`;

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

interface IVenueServicesProps {
	venueId: string;
	secondaryTab?: string;
	printerId?: string;
}

const VenuePrinting: FunctionComponent<IVenueServicesProps> = ({
	venueId,
	secondaryTab,
	printerId,
}) => {
	const dispatch = useReduxDispatch();
	const intl = useIntl();

	const { activeVenuePrinters, activeVenuePrinterImage } = useSelector(
		(state: RootState) => state.venue
	);

	const [isUppyOpen, setIsUppyOpen] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	// Create uppy
	const uppy = useUppy(() =>
		new Uppy({
			autoProceed: true,
			restrictions: { maxNumberOfFiles: 1 },
		})
			.use(XHRUpload, {
				endpoint: `${process.env.REACT_APP_API_BASE_URL}/venue/images/receipt-logo`,
				method: 'post',
				formData: true,
				fieldName: 'image',
				withCredentials: true,
			})
			.on('upload-success', async (file, response) => {
				// Get url from response
				const { url } = response.body;
				const updateLogoResponse = await dispatch(
					updateVenuePrinterImage(venueId, url)
				);
				// Close uppy
				setIsUppyOpen(false);
				// remove uppy class from body
				document.body.classList.remove('uppy-Dashboard-isFixed');
				// Reset upload
				uppy.reset();

				if (updateLogoResponse) {
					// Show success alert
					await dispatch(
						addAlert({
							title: intl.formatMessage({
								id: 'alerts.success.title',
							}),
							message: intl.formatMessage({
								id: 'venuePrinterLogo.alerts.updated.message',
							}),
							type: 'success',
						})
					);
				}

				await dispatch(getVenuePrinterImage(venueId));
			})
	);

	useEffect(() => {
		const getData = async (venue?: string, printer?: string) => {
			if (!venue) return;
			dispatch(getVenuePrintersList(venue));
			dispatch(getVenuePrinterImage(venue));

			printer && dispatch(getVenuePrinter(venue, printer));
		};

		getData(venueId, printerId);
	}, [venueId, printerId, dispatch]);

	const handleImageDelete = async () => {
		const deleteLogo = await dispatch(updateVenuePrinterImage(venueId, ''));

		if (deleteLogo) {
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venuePrinterLogo.alerts.updated.message',
				}),
				type: 'success',
			});

			await dispatch(getVenuePrinterImage(venueId));
		}
	};

	const handleImageOpen = () =>
		activeVenuePrinterImage ? setIsModalOpen(true) : setIsUppyOpen(true);

	return (
		<StyledWrapper>
			{secondaryTab ? (
				<VenuePrinterForm venueId={venueId} printerId={printerId} />
			) : (
				<>
					<StyledActions>
						<Button
							variant="secondary"
							onClick={() =>
								dispatch(getVenuePrintersList(venueId))}
						>
							<FormattedMessage id="venueForm.printing.button.refresh" />
						</Button>
						<Button
							variant="secondary"
							onClick={() => handleImageOpen()}
						>
							<FormattedMessage
								id={
									activeVenuePrinterImage
										? 'venueForm.printing.button.editLogo'
										: 'venueForm.printing.button.uploadLogo'
								}
							/>
						</Button>
						<Link to={`/venues/edit/${venueId}/printing/create`}>
							<Button>
								<FormattedMessage id="venueForm.printing.button.addPrinter" />
							</Button>
						</Link>
					</StyledActions>
					<VenuePrinterList
						venueId={venueId}
						printers={activeVenuePrinters}
					/>
					<ImageManagementModal
						isModalOpen={isModalOpen}
						toggleModal={setIsModalOpen}
						toggleUpload={setIsUppyOpen}
						deleteImage={handleImageDelete}
						fieldValue={activeVenuePrinterImage}
					/>

					<DashboardModal
						uppy={uppy}
						theme="light"
						open={isUppyOpen}
						onRequestClose={() => {
							setIsUppyOpen(false);
							uppy.reset();
							document.body.classList.remove(
								'uppy-Dashboard-isFixed'
							);
						}}
						closeModalOnClickOutside={true}
						proudlyDisplayPoweredByUppy={false}
					/>
				</>
			)}
		</StyledWrapper>
	);
};

export default VenuePrinting;
