import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { StyledTable } from './venue-menu-validation.styles';

import { IValidationResult } from 'modules/venue/venue-menu-validation.types';

const ValidationResultDetails: FunctionComponent<{
	results: IValidationResult[];
}> = ({ results }) => {
	return (
		<StyledTable style={{ margin: 0 }}>
			<thead>
				<tr>
					<th scope="col" className="stretch">
						<FormattedMessage id="menuValidation.validationDetails.item.header.productName" />
					</th>
					<th scope="col" className="stretch">
						<FormattedMessage id="menuValidation.validationDetails.item.header.productId" />
					</th>
					<th scope="col" className="stretch">
						<FormattedMessage id="menuValidation.validationDetails.item.header.modifierName" />
					</th>
					<th scope="col" className="stretch">
						<FormattedMessage id="menuValidation.validationDetails.item.header.modifierId" />
					</th>
					<th scope="col" className="stretch">
						<FormattedMessage id="menuValidation.validationDetails.item.header.message" />
					</th>
				</tr>
			</thead>
			<tbody>
				{results.map((result, index) => (
					<tr
						// eslint-disable-next-line react/no-array-index-key
						key={`${result.productId}-${result.modifierId}-${index}`}
					>
						<td className="stretch">{result.productName}</td>
						<td className="stretch">{result.productId}</td>
						<td className="stretch">{result.modifierName}</td>
						<td className="stretch">{result.modifierId}</td>
						<td className="stretch">{result.message}</td>
					</tr>
				))}
			</tbody>
		</StyledTable>
	);
};

export default ValidationResultDetails;
