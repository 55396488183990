import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IAccount } from '../../account.types';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';

const StyledAccountItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-of-type(1) {
		min-width: 0;
		margin-right: 0;
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		text-align: center;
		margin: 0;
	}

	&:nth-last-child(2) {
		width: 50px;
		min-width: 50px;
		text-align: center;
		margin: 0 10px 0 auto;
	}
`;

// Props for component
interface IAccountsListItemProps {
	item: IAccount;
}
// Accounts list item component
const AccountsListItem: FunctionComponent<IAccountsListItemProps> = ({
	item,
}) => {
	return (
		<StyledAccountItem key={item.id} aria-label="accounts-item">
			<StyledColumn />
			<StyledColumn data-testid="account-name">{item.name}</StyledColumn>
			<StyledColumn>
				<Link to={`/menus/menus?activeAccount=${item.id}`}>
					<Icon name="menu" colour="primary" />
				</Link>
			</StyledColumn>
			<StyledColumn>
				<Link to={`/accounts/edit/${item.id}/details`}>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledAccountItem>
	);
};

export default AccountsListItem;
