import { Field, Formik, Form } from 'formik';
import queryString from 'query-string';
import React, {
	FunctionComponent,
	useState,
	useEffect,
	useCallback,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

import { getAccountsList, resetAccountState } from './account.slice';
import { IAccount } from './account.types';
import AccountsList from './accounts-list/accounts-list.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Pagination from 'components/pagination/pagination.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	max-width: 160px;

	input {
		height: 35px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledButton = styled(Button)`
	font-size: ${fonts.sizes.small};
	padding: 11px 18px;
`;

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.sub-actions {
		display: flex;
		align-items: center;

		Button {
			margin: 0;
			flex-shrink: 0;
		}
	}
`;

/** Renders accounts page component */
const AccountsPage: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const location = useLocation();
	const history = useHistory();

	const [sortDirection, setSortDirection] = useState('');
	const [sortColumn, setSortColumn] = useState<keyof IAccount>('name');
	const [searchQuery, setSearchQuery] = useState('');
	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchQuery, 300);

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get pagination from state
	const pagination = useSelector(
		(state: RootState) => state.account.pagination
	);

	// Get accounts from state
	const accounts: IAccount[] = useSelector(
		(state: RootState) => state.account.accounts
	);

	useEffect(() => {
		const getFilteredAccounts = async () => {
			await dispatch(
				getAccountsList({
					search: query.search,
					sortOrder: query.sortOrder,
					sortBy: query.sort,
					pageSize: pagination.pageSize,
					pageNumber: parseFloat(query.pageNumber!) || 1,
				})
			);
		};

		getFilteredAccounts();

		// useEffect cleanup
		return () => {
			dispatch(resetAccountState());
		};
	}, [
		dispatch,
		pagination.pageSize,
		query.sortOrder,
		query.sort,
		query.search,
		query.pageNumber,
	]);

	// Handle searchterm change
	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	// Function to update sort query params
	const updateSort = (column: keyof IAccount) => {
		// sort order from query params
		const { sortOrder } = query;
		// variable to store sort direction
		let sortDir;

		if (sortOrder === 'ASC') {
			sortDir = 'DESC';
		} else if (sortOrder === 'DESC') {
			sortDir = '';
		} else {
			sortDir = 'ASC';
		}

		// TODO - Put this in useEffect too
		// Update sort direction in state
		setSortDirection(sortDir);
		setSortColumn(column);

		// Create list of filters
		const filters = {
			...query,
			sortOrder: sortDir,
			sort: column,
		};

		// Remove pagenumber from filters
		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	return (
		<>
			<StyledHeader>
				<SectionHeading
					title="accounts.title"
					subtitle="accounts.subtitle"
				/>
				<div className="sub-actions">
					<Formik initialValues={{ searchQuery }} onSubmit={() => {}}>
						{() => {
							return (
								<Form>
									<Field
										component={StyledInput}
										name="searchQuery"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={12}
										iconHeight={12}
										handleChangeEvent={(value: string) => {
											setSearchQuery(value);
										}}
									/>
								</Form>
							);
						}}
					</Formik>
					<Link to="/accounts/create">
						<StyledButton
							icon="plus"
							iconWidth={11}
							iconHeight={11}
						>
							<FormattedMessage id="accounts.button.create" />
						</StyledButton>
					</Link>
				</div>
			</StyledHeader>
			<AccountsList
				accounts={accounts}
				setSort={updateSort}
				sortColumn={sortColumn}
				sortDirection={sortDirection}
			/>
			{pagination?.pageCount > 1 && (
				<Pagination
					pageNumber={
						query.pageNumber ? parseFloat(query.pageNumber) : 1
					}
					pageCount={pagination.pageCount}
					setPageNumber={handlePagination}
				/>
			)}
		</>
	);
};

export default withNav(AccountsPage);
