import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import Icon from 'components/icons/icon.component';

interface ISortIconsProps {
	sort?: string;
	className?: string;
	active?: boolean;
}

const StyledSortIcons = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const StyledIcon = styled(Icon)`
	transform: rotate(180deg);
`;

/** Renders SortIcon component */
const SortIcons: FunctionComponent<ISortIconsProps> = ({
	className,
	sort,
	active,
}) => {
	const ascActive = sort === 'ASC';
	const descActive = sort === 'DESC';
	return (
		<StyledSortIcons className={className} data-testid="sort-icons">
			<Icon
				name="sortArrow"
				colour={ascActive && active ? 'text' : 'borders'}
				width={10}
				height={10}
			/>
			<StyledIcon
				name="sortArrow"
				colour={descActive && active ? 'text' : 'borders'}
				width={10}
				height={10}
			/>
		</StyledSortIcons>
	);
};

export default SortIcons;
