import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import OrderAuditLog from './order-audit-item/order-audit-item.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import { getAuditLogs } from 'modules/order/order.slice';
import { IOrderAuditLog } from 'modules/order/order.types';

interface IComponentProps {
	orderId: string;
}

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledButtons = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background: ${brand.borders};
		margin: 20px 0;
	}
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	text-align: right;
	width: 100%;
	max-width: 130px;
	font-weight: ${fonts.weights.medium};

	cursor: pointer;

	:focus {
		outline: none;
	}
`;

const StyledLogsList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	justify-content: space-between;
	user-select: none;
	padding: 0 0 0 15px;
`;

// order details form wrapper
const OrderAudits: FunctionComponent<IComponentProps> = ({ orderId }) => {
	// Get history
	const history = useHistory();

	const dispatch = useReduxDispatch();

	const { audits } = useSelector((state: RootState) => state.order);

	// dispatch to get order payment details
	useEffect(() => {
		const getData = async (id: string) => {
			await dispatch(getAuditLogs(id));
		};
		getData(orderId!);
	}, [dispatch, orderId]);

	return (
		<StyledWrapper>
			<StyledHeadings>
				<StyledHeading>
					<FormattedMessage id="orderAudits.heading.date" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="orderAudits.heading.logType" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="orderAudits.heading.source" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="orderAudits.heading.message" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="orderAudits.heading.metadata" />
				</StyledHeading>
			</StyledHeadings>

			<StyledLogsList aria-label="logs-list">
				{audits?.length > 0 ? (
					audits.map((item: IOrderAuditLog) => (
						<OrderAuditLog item={item} key={item.id} />
					))
				) : (
					<FormattedMessage id="orderAudits.noLogs" />
				)}
			</StyledLogsList>
			<StyledButtons>
				<Button
					variant="secondary"
					onClick={() => history.push('/orders')}
				>
					<FormattedMessage id="form.button.back" />
				</Button>
			</StyledButtons>
		</StyledWrapper>
	);
};

export default OrderAudits;
