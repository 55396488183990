import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IStaffState,
	IStaff,
	IStaffFormValues,
	IStaffFilters,
	IStaffRoles,
} from './staff.types';

// Create initial state for staff slice
export const initialStaffState: IStaffState = {
	staff: [],
	eventsInProgress: 0,
};

const staffSlice = createSlice({
	name: 'staff',
	initialState: initialStaffState,
	reducers: {
		GET_ALL_STAFF(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ALL_STAFF_SUCCESS(state, action) {
			return {
				...state,
				staff: action.payload.data.data,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_STAFF_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ACTIVE_STAFF(state, action) {
			return {
				...state,
				activeStaffDetails: action.payload,
			};
		},
		CREATE_STAFF(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_STAFF_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_STAFF_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_STAFF(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_STAFF_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_STAFF_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_STAFF(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_STAFF_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_STAFF_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_STAFF(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_STAFF_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_STAFF_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_STAFF_ROLES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_STAFF_ROLES_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_STAFF_ROLES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_STAFF_ROLES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_STAFF_ROLES_SUCCESS(state, action) {
			return {
				...state,
				activeStaffRoles: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_STAFF_ROLES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_STAFF_STATE() {
			return {
				...initialStaffState,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_ALL_STAFF,
	GET_ALL_STAFF_SUCCESS,
	GET_ALL_STAFF_FAIL,
	ACTIVE_STAFF,
	CREATE_STAFF,
	CREATE_STAFF_SUCCESS,
	CREATE_STAFF_FAIL,
	GET_STAFF,
	GET_STAFF_SUCCESS,
	GET_STAFF_FAIL,
	UPDATE_STAFF,
	UPDATE_STAFF_SUCCESS,
	UPDATE_STAFF_FAIL,
	DELETE_STAFF,
	DELETE_STAFF_SUCCESS,
	DELETE_STAFF_FAIL,
	UPDATE_STAFF_ROLES,
	UPDATE_STAFF_ROLES_SUCCESS,
	UPDATE_STAFF_ROLES_FAIL,
	GET_STAFF_ROLES,
	GET_STAFF_ROLES_SUCCESS,
	GET_STAFF_ROLES_FAIL,
	RESET_STAFF_STATE,
} = staffSlice.actions;

/** Get staff list */
export const getStaffList = (filters?: IStaffFilters) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_ALL_STAFF({
			request: {
				method: 'get',
				url: 'user',
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** Set active staff in state */
export const setActiveStaff = (staff: IStaff | undefined) => async (
	dispatch: Dispatch
) => {
	await dispatch(ACTIVE_STAFF(staff || undefined));
};

/** Create staff */
export const createStaff = (staff: IStaffFormValues) => async (
	dispatch: Dispatch
) => {
	// Create staff request
	const { payload } = await dispatch(
		CREATE_STAFF({
			request: {
				method: 'put',
				url: 'user',
				data: staff,
			},
		})
	);

	const success = payload.status === 200;

	// Update active staff
	success && (await dispatch(ACTIVE_STAFF(payload.data)));

	return payload.data;
};

/** Get staff by ID */
export const getStaff = (staffId: string) => async (dispatch: Dispatch) => {
	// Create staff user request
	const { payload } = await dispatch(
		GET_STAFF({
			request: {
				method: 'get',
				url: `user/${staffId}`,
			},
		})
	);

	// Update active staff
	payload?.data && (await dispatch(ACTIVE_STAFF(payload.data)));

	return payload?.data;
};

/** Update staff */
export const updateStaff = (staffId: string, staff: IStaffFormValues) => async (
	dispatch: Dispatch
) => {
	// Create update staff request
	const { payload } = await dispatch(
		UPDATE_STAFF({
			request: {
				method: 'put',
				url: `user/${staffId}`,
				data: staff,
			},
		})
	);

	const success = payload.status === 200;

	// Update active staff
	success && (await dispatch(ACTIVE_STAFF(payload.data)));

	return success;
};

/** Delete staff by ID */
export const deleteStaff = (staffId: string) => async (dispatch: Dispatch) => {
	// Create delete staff request
	const { payload } = await dispatch(
		DELETE_STAFF({
			request: {
				method: 'delete',
				url: `user/${staffId}`,
			},
		})
	);

	// Update active staff
	await dispatch(ACTIVE_STAFF(undefined));

	return payload.status === 200;
};

/** Get staff roles by ID */
export const getStaffRoles = (staffId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_STAFF_ROLES({
			request: {
				method: 'get',
				url: `user/${staffId}/access`,
			},
		})
	);

	return payload?.data;
};

/** Update staff */
export const updateStaffRoles = (staffId: string, data: IStaffRoles) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_STAFF_ROLES({
			request: {
				method: 'put',
				url: `user/${staffId}/access`,
				data,
			},
		})
	);

	return payload?.status === 200;
};

/** Reset Staff State */
export const resetStaffState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_STAFF_STATE());
};

export default staffSlice.reducer;
