import { FieldProps } from 'formik';
import { rgba } from 'polished';
import React, { FunctionComponent, ReactHTMLElement, useState } from 'react';
import { ActionMeta, Theme } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import { intl } from 'modules/core/i18n/i18n.config';

export interface IOption {
	label?: string;
	value?: string | boolean | number;
}

// Interface for component props
interface ISelectProps extends ReactHTMLElement<HTMLSelectElement> {
	label?: string;
	handleChangeEvent?: Function;
	hideInitialValue?: boolean;
	initialValue?: string;
	isDisabled?: boolean;
	placeholder?: string;
	value?: IOption | IOption[];
	formElementVariant?: 'header';
	size?: 'sm';
	testId?: string;
	className?: string;
}

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

// Create styled select component
const StyledSelect = styled(CreatableSelect)<ISelectProps>`
	width: 100%;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	font-size: ${fonts.sizes.standard};

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}

	[class$='control'] {
		min-height: ${({ size }) => (size === 'sm' ? '35px' : '40px')};
		border: none;
		border-radius: 5px;
		box-shadow: none;
	}

	.react-select__option--is-selected {
		font-weight: ${fonts.weights.medium};
	}

	.react-select__multi-value,
	.react-select__multi-value__label {
		border-radius: 5px;
		font-size: 100%;
	}

	.react-select__indicator {
		padding: 6px 8px;
	}
`;

/** Renders creatable Select component */
const CreateableSelect: FunctionComponent<FieldProps & ISelectProps> = ({
	field,
	form,
	label,
	isDisabled,
	placeholder,
	formElementVariant,
	size,
	testId,
	className,
}) => {
	const [inputValue, setInputValue] = useState<string>('');
	const { touched, errors } = form;
	const { name } = field;
	const isTouched = touched?.[`${field.name}`] || false;
	const hasErrors = errors?.[`${field.name}`] || false;

	const createOption = (option: string) => ({
		label: option,
		value: option,
	});

	// On change
	const handleChange = (value: string, actionMeta: ActionMeta<IOption>) => {
		// if we have a remove action
		if (actionMeta.action === 'remove-value') {
			// remove from values
			const newValues = field.value.filter(
				(values: IOption) =>
					values.value !== actionMeta?.removedValue?.value
			);
			// set field values
			form.setFieldValue(field.name, newValues);
		}
	};

	// On change set input value
	const handleInputChange = (val: string) => {
		setInputValue(val);
	};

	// handle key press events
	const handleKeyDown = (event: KeyboardEvent) => {
		// if no value, do nothing
		if (!inputValue) return;

		// switch for event type
		switch (event.key) {
		// If enter or tab
		case 'Enter':
		case 'Tab':
			// prevent default
			event.preventDefault();
			// If we already have the value, do nothing
			if (
				field.value.some(
					(vals: IOption) => vals.value === inputValue
				)
			) {
				return;
			}
			// set input value as empty
			setInputValue('');
			// set form value
			form.setFieldValue(field.name, [
				...field.value,
				createOption(inputValue),
			]);
		}
	};

	return (
		<FormElement
			variant={formElementVariant}
			testId={testId}
			className={className}
			isDisabled={isDisabled}
		>
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledSelect
				{...name}
				inputValue={inputValue}
				components={{ DropdownIndicator: null }}
				isClearable
				isMulti
				menuIsOpen={false}
				size={size}
				classNamePrefix="react-select"
				className={isTouched && hasErrors && 'has-error'}
				isDisabled={isDisabled}
				aria-label={`creatable-${field.name}`}
				placeholder={
					placeholder ||
					intl.formatMessage({ id: 'form.select.default' })
				}
				onChange={handleChange}
				onInputChange={handleInputChange}
				onKeyDown={handleKeyDown}
				theme={(theme: Theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: rgba(brand.primary, 0.25),
						primary75: rgba(brand.primary, 0.75),
						primary50: rgba(brand.primary, 0.5),
						primary: brand.primary,
					},
				})}
				value={field.value}
			/>

			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</FormElement>
	);
};

export default CreateableSelect;
