import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getRemittanceReport } from '../report.slice';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import DatePickerInput from 'components/form-inputs/date-picker/date-picker.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getAccountsList } from 'modules/account/account.slice';
import { IAccount } from 'modules/account/account.types';
import { getBrandsList, resetBrandState } from 'modules/brand/brand.slice';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenuesList,
	resetVenueState,
} from 'modules/venue/slices/venue.slice';
import { IVenueItem } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledSubtitle = styled.p`
	margin-bottom: 25px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	accountId?: string;
	brandId?: string;
	venueId?: string;
	history: History;
}

const ReportRemittance: FunctionComponent<IComponentProps> = ({
	accountId,
	brandId,
	venueId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	// Interface for filter options
	interface IFilterOptions {
		accountId?: string;
		brandId?: string;
		venueId?: string;
	}

	// Filters state (used when getting data to populate dropdowns)
	const [filters, setFilters] = useState<IFilterOptions>({
		accountId: '',
		brandId: '',
	});

	// Get data for select dropdowns
	useEffect(() => {
		const getData = async () => {
			// Get accounts list
			await dispatch(getAccountsList());
			// Get brand list with account filter
			await dispatch(
				getBrandsList({
					accountUUID: accountId || filters.accountId,
					pageSize: 200,
				})
			);
			// Get venue list with brand filter
			await dispatch(
				getVenuesList({ brandId: brandId || filters.brandId })
			);
		};

		getData();

		return () => {
			// Reset venue + brand states
			dispatch(resetBrandState());
			dispatch(resetVenueState());
		};
	}, [dispatch, accountId, brandId, filters]);

	// Get account options from state
	const accountOptions: IOption[] = useSelector(
		(state: RootState) => state.account.accounts
	).map((value: IAccount) => ({
		label: value.name,
		value: value.id,
	}));

	// Get brand options from state
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get venue options from state
	const venueOptions: IOption[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Interface for form values
	interface IFormValues {
		dateFrom: Date;
		dateTo: Date;
		reportType: string;
		accountId: string;
		brandId: string;
		venueId: string;
	}

	// Store for report type dropdown options
	const reportTypeOptions: IOption[] = [];

	// Set report type options
	if (accountId) {
		reportTypeOptions.push({ value: 'account', label: 'Account' });
		reportTypeOptions.push({ value: 'brand', label: 'Brand' });
		reportTypeOptions.push({ value: 'venue', label: 'Venue' });
	} else if (brandId) {
		reportTypeOptions.push({ value: 'brand', label: 'Brand' });
		reportTypeOptions.push({ value: 'venue', label: 'Venue' });
	} else if (venueId) {
		reportTypeOptions.push({ value: 'venue', label: 'Venue' });
	} else {
		reportTypeOptions.push({ value: 'account', label: 'Account' });
		reportTypeOptions.push({ value: 'brand', label: 'Brand' });
		reportTypeOptions.push({ value: 'venue', label: 'Venue' });
	}

	// Set default report type
	const setReportType = () => {
		if (accountId) {
			return 'brand';
		}
		if (brandId) {
			return 'venue';
		}
		if (venueId) {
			return 'venue';
		}
		return 'account';
	};

	// Initial form values
	const initialValues: IFormValues = {
		dateFrom: new Date(),
		dateTo: new Date(),
		reportType: setReportType(),
		accountId: '',
		brandId: '',
		venueId: '',
	};

	// Handle form submission
	const handleSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Create filters from route params (props), or form values
		const selectFilters: {
			accountId?: string;
			brandId?: string;
			venueId?: string;
		} = {
			accountId: accountId || values.accountId || '',
			brandId: brandId || values.brandId || '',
			venueId: venueId || values.venueId || '',
		};

		// Remove filters not for this report type
		if (values.reportType === 'account') {
			delete selectFilters.brandId;
			delete selectFilters.venueId;
		} else if (values.reportType === 'brand') {
			delete selectFilters.venueId;
		}

		// Request report from API
		await dispatch(
			getRemittanceReport(
				{
					dateFrom: values.dateFrom,
					dateTo: values.dateTo,
					...selectFilters,
				},
				values.reportType
			)
		);

		// Set formik submission state to false
		setSubmitting(false);
	};

	// Generate back link url
	const backLink = () => {
		if (accountId) {
			return '/accounts';
		}
		if (brandId) {
			return '/brands';
		}
		if (venueId) {
			return '/venues';
		}
		return '';
	};

	// Interface for select value change
	interface ISelectChangeValue {
		fieldValue: string;
		form: FormikProps<IFormValues>;
	}

	// Handle changing of report type
	const handleReportTypeChange = (value: ISelectChangeValue) => {
		if (value.fieldValue === 'account') {
			value.form.setFieldValue('brandId', '');
			value.form.setFieldValue('venueId', '');
		}
		if (value.fieldValue === 'brand') {
			value.form.setFieldValue('venueId', '');
		}
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{({ values, isSubmitting }) => (
				<StyledForm>
					<StyledSubtitle>
						<FormattedMessage id="reports.remittance.subtitle" />
					</StyledSubtitle>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={DatePickerInput}
								name="dateFrom"
								label={intl.formatMessage({
									id: 'form.fields.dateFrom.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.dateFrom.label',
								})}
								maxDate={values.dateTo}
							/>
							<Field
								component={DatePickerInput}
								name="dateTo"
								label={intl.formatMessage({
									id: 'form.fields.dateTo.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.dateTo.label',
								})}
								minDate={values.dateFrom}
								maxDate={new Date()}
							/>
							{!venueId && (
								<Field
									component={Select}
									label={intl.formatMessage({
										id: 'form.fields.reportType.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.reportType.label',
									})}
									handleChangeEvent={async (
										value: ISelectChangeValue
									) => handleReportTypeChange(value)}
									name="reportType"
									selectOptions={reportTypeOptions}
									value={reportTypeOptions.find(
										(type) =>
											type.value === values.reportType
									)}
								/>
							)}
							{!accountId &&
								!brandId &&
								!venueId &&
								values.reportType === 'account' && (
								<Field
									value={accountOptions.find(
										(opt) =>
											opt.value === values.accountId
									)}
									component={Select}
									isSearchable={true}
									label={intl.formatMessage({
										id: 'form.fields.account.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.account.label',
									})}
									handleChangeEvent={async (
										value: ISelectChangeValue
									) => {
										// Add accountId to filters
										setFilters({
											...filters,
											...{
												accountId:
														value.fieldValue || '',
											},
										});
									}}
									name="accountId"
									isClearable={true}
									selectOptions={accountOptions}
								/>
							)}
							{!brandId &&
								!venueId &&
								values.reportType !== 'account' && (
								<Field
									value={brandOptions.find(
										(opt) =>
											opt.value === values.brandId
									)}
									component={Select}
									isSearchable={true}
									label={intl.formatMessage({
										id: 'form.fields.brand.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.brand.label',
									})}
									handleChangeEvent={async (
										value: ISelectChangeValue
									) => {
										// Add accounbrandIdtId to filters
										setFilters({
											...filters,
											...{
												brandId:
														value.fieldValue || '',
											},
										});
									}}
									name="brandId"
									isClearable={true}
									selectOptions={brandOptions}
								/>
							)}
							{!venueId &&
								values.reportType === 'venue' &&
								values.brandId && (
								<Field
									value={venueOptions.find(
										(opt) =>
											opt.value === values.venueId
									)}
									component={Select}
									isSearchable={true}
									label={intl.formatMessage({
										id: 'form.fields.venue.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.venue.label',
									})}
									handleChangeEvent={async (
										value: ISelectChangeValue
									) =>
										setFilters({
											...filters,
											...{
												accountId:
														value.fieldValue || '',
											},
										})}
									name="venueId"
									isClearable={true}
									selectOptions={venueOptions}
								/>
							)}
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						{backLink() && (
							<Link to={backLink()} data-testid="back-link">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
						)}
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.download" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default ReportRemittance;
