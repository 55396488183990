import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
// Interface for component props
interface IFormElementProps {
	className?: string;
	variant?: 'header';
	testId?: string;
	isDisabled?: boolean;
	borderBottom?: boolean;
}

const headerVariantStyling = css`
	width: 185px;
	display: flex;
	align-items: center;
	margin: 0 10px 0 0;
`;

const disabledStyling = css`
	opacity: 0.3;
	pointer-events: none;
`;

const borderBottomStyling = css`
	padding: 0 0 25px;
	margin: 0 0 25px;
	border-bottom: 1px solid ${brand.body};
`;

// Create styled form element component
const StyledFormElement = styled.div<IFormElementProps>`
	margin: 0 0 20px;
	position: relative;
	${({ variant }) => variant === 'header' && headerVariantStyling}
	${({ isDisabled }) => isDisabled && disabledStyling}
	${({ borderBottom }) => borderBottom && borderBottomStyling}
`;

/** Renders form element component */
const FormElement: FunctionComponent<IFormElementProps> = ({
	children,
	className,
	variant,
	testId,
	isDisabled,
	borderBottom,
}) => {
	return (
		<StyledFormElement
			{...{ variant }}
			className={className}
			data-testid={testId}
			isDisabled={isDisabled}
			borderBottom={borderBottom}
		>
			{children}
		</StyledFormElement>
	);
};

export default FormElement;
