import React, {
	FunctionComponent,
	HTMLAttributes,
	useEffect,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';

interface IDisplayValueProps {
	label?: string;
	value?: string;
	link?: string;
	copyValue?: boolean;
}

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledDiv = styled.div<IDisplayValueProps>`
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-size: ${fonts.sizes.standard};
	display: flex;
	justify-content: space-between;
	align-items: center;

	a {
		color: ${brand.link};
		font-weight: ${fonts.weights.medium};
	}
`;

const StyledCopy = styled.div`
	cursor: pointer;
`;

const StyledSuccess = styled.div`
	position: absolute;
	top: 5px;
	width: 100%;
	margin-left: -50px;
	color: green;
`;

/** Renders text value component */
const DisplayValue: FunctionComponent<
	IDisplayValueProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
	const { className, label, value, link, copyValue } = props;

	const [copySuccess, setCopySuccess] = useState<boolean>(false);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(value!);
		setCopySuccess(true);
	};

	useEffect(() => {
		if (copySuccess === true) {
			setTimeout(() => {
				setCopySuccess(false);
			}, 1000);
		}
	}, [copySuccess]);

	return (
		<FormElement className={className}>
			{label && <StyledLabel>{label}</StyledLabel>}
			<StyledDiv {...props} data-testid="display-value">
				{link ? <a href={link}>{value}</a> : value}
				{copyValue && value && (
					<StyledCopy
						role="link"
						onClick={() => copyToClipboard()}
						onKeyPress={() => copyToClipboard()}
						tabIndex={0}
					>
						{copySuccess && (
							<StyledSuccess>
								<FormattedMessage id="displayValue.copy.success" />
							</StyledSuccess>
						)}
						<Icon
							name="duplicate"
							colour={copySuccess ? 'validation_success' : 'link'}
						/>
					</StyledCopy>
				)}
			</StyledDiv>
		</FormElement>
	);
};

export default DisplayValue;
