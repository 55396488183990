import { Field } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import DisplayValue from 'components/form-inputs/display-value/display-value.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getZonalSalesAreas,
	setZonalSalesAreas,
} from 'modules/venue/slices/venue.slice';
import {
	IVenueServicePosConfig,
	IVenueServicePosConfigFormValues,
	IZonalSalesArea,
} from 'modules/venue/venue.types';

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	&.flex-start {
		justify-content: flex-start;
	}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

interface IComponentProps {
	values: IVenueServicePosConfigFormValues;
	venueId: string;
	posConfig?: IVenueServicePosConfig;
}

// Create select dropdown for zonal sales areas
const zonalSalesAreaSelect = (
	values: IVenueServicePosConfigFormValues,
	activeZonalSalesAreas: IZonalSalesArea[] | undefined
) => {
	// If we have active zonal sales areas
	const salesAreasPresent =
		values.zonalSiteId &&
		activeZonalSalesAreas &&
		activeZonalSalesAreas.length;

	if (!salesAreasPresent) {
		return undefined;
	}

	// Format selected sales area for select value
	const zonalSalesAreaId = activeZonalSalesAreas!
		.map((salesArea: IZonalSalesArea) => ({
			label: salesArea.friendly,
			value: salesArea.id,
		}))
		.filter(
			(salesArea: IOption) =>
				salesArea.value?.toString() ===
				values.zonalSalesAreaId?.toString()
		)[0];

	return (
		<Field
			component={Select}
			label={intl.formatMessage({
				id: 'form.fields.zonalSalesAreaId.label',
			})}
			name="zonalSalesAreaId"
			selectOptions={activeZonalSalesAreas!.map(
				(salesArea: IZonalSalesArea) => ({
					label: salesArea.friendly,
					value: salesArea.id,
				})
			)}
			value={zonalSalesAreaId}
		/>
	);
};

const VenueServiceConfigPosZonalForm: FunctionComponent<IComponentProps> = ({
	values,
	posConfig,
	venueId,
}) => {
	const dispatch = useReduxDispatch();

	// Create state for zonalSiteId using default ID
	const [zonalSiteId, setZonalSiteId] = useState<string>(
		values.zonalSiteId?.toString() || ''
	);

	// Debounce zonalSiteId changes
	const [debouncedZonalSiteId] = useDebounce(zonalSiteId, 200);

	// Get zonal sales areas from store
	const activeZonalSalesAreas: IZonalSalesArea[] | undefined = useSelector(
		(state: RootState) => state?.venue?.activeZonalSalesAreas
	);

	useEffect(() => {
		// Handle zonal site id field changes
		const handleZonalSiteIdChange = async (value: string) => {
			// Get zonal sales areas for selected site id
			value
				? await dispatch(getZonalSalesAreas(venueId, value || ''))
				: dispatch(setZonalSalesAreas(undefined));
		};

		handleZonalSiteIdChange(
			debouncedZonalSiteId || posConfig?.zonalSiteId?.toString() || ''
		);
	}, [dispatch, venueId, posConfig, debouncedZonalSiteId]);

	return (
		<StyledFieldGroup>
			<StyledColumn>
				<Field
					component={Input}
					name="zonalSiteId"
					label={intl.formatMessage({
						id: 'form.fields.zonalSiteId.label',
					})}
					placeholder={intl.formatMessage({
						id: 'form.fields.zonalSiteId.label',
					})}
					handleChangeEvent={(value: string) => {
						setZonalSiteId(value);
					}}
					isPrivate={true}
					borderBottom
				/>
				{zonalSalesAreaSelect(values, activeZonalSalesAreas)}
			</StyledColumn>
			<StyledColumn>
				{!!values.zonalSiteId && (
					<Field
						component={DisplayValue}
						name="zonalSiteName"
						value={posConfig?.zonalSiteName}
						label={intl.formatMessage({
							id: 'form.fields.zonalSiteName.label',
						})}
						isPrivate={true}
					/>
				)}
			</StyledColumn>
		</StyledFieldGroup>
	);
};

export default VenueServiceConfigPosZonalForm;
