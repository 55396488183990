import brand from '../variables/brand';
import fonts from '../variables/fonts';

/** Global sweetalert styles */
const sweetAlert = `
	.swal-container {
		font-family: "Montserrat",arial,helvetica,sans-serif !important;
		background: black;

		button,
		input {
			font-family: "Montserrat",arial,helvetica,sans-serif;
		}

		.swal-popup {
			max-width: 790px;
			min-width: 590px;
			padding: 25px 20px;
			display: flex;
			border-radius: 10px;

			.swal-title {
				font-size: ${fonts.sizes.h1};
				font-weight: ${fonts.weights.semibold};
				color: ${brand.text};
				margin: 0 0 18px;
			}

			.swal-content {
				font-size: ${fonts.sizes.large};
				color: ${brand.text};
			}

			.swal2-actions {
				display: flex;
				padding: 20px 0 0;
				max-width: 300px;

				.swal-button-cancel,
				.swal-button-confirm {
					flex-grow: 1;
					outline: none;
					padding: 10px 22px;
					font-size: ${fonts.sizes.standard};
					box-shadow: 0px 0px 0px 1px ${brand.primary};
					border-radius: 10px;

					&:focus {
						box-shadow: none;
					}

					&:hover {
						background: ${brand.link_hover};
					}
				}

				.swal-button-confirm {
					color: ${brand.white};
					background: ${brand.primary};
				}

				.swal-button-cancel {
					color: ${brand.primary};
					background: ${brand.secondary};
					box-shadow: 0px 0px 0px 1px ${brand.secondary};

					&:hover {
						background: ${brand.link_hover_secondary};
						box-shadow: 0px 0px 0px 1px ${brand.secondary};
					}
				}
			}
		}
	}
`;

export default sweetAlert;
