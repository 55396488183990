import { lighten, rgba } from 'polished';

/** Brand colour variables */
const brand = {
	black: '#000000',
	opBlack: '#101010',
	white: '#ffffff',
	body: '#EDEEF2',
	neutral: '#F8F8FB',
	borders: '#D9D9D9',
	text: '#101010',
	placeholder: '#C9C9CC',
	get headings() {
		return this.text;
	},
	primary: '#4F80FF',
	secondary: rgba('#4F80FF', 0.1),
	get link() {
		return this.primary;
	},
	get link_hover() {
		return lighten(0.02, this.primary);
	},
	get link_hover_secondary() {
		return lighten(0.02, this.secondary);
	},
	get link_active() {
		return lighten(0.08, this.primary);
	},
	formDisabled: '#F2F2F2',
	validation_error: '#dc3545',
	validation_success: '#28a745',
	validation_warning: '#ffc107',
	tooltipBackground: '#6F7DA2',
};

export default brand;
