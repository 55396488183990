import { ReactComponent as accounts } from 'assets/svg/accounts.svg';
import { ReactComponent as appUser } from 'assets/svg/appUser.svg';
import { ReactComponent as brands } from 'assets/svg/brands.svg';
import { ReactComponent as camera } from 'assets/svg/camera.svg';
import { ReactComponent as checkbox } from 'assets/svg/checkbox.svg';
import { ReactComponent as chevron } from 'assets/svg/chevron.svg';
import { ReactComponent as close } from 'assets/svg/close.svg';
import { ReactComponent as deleteCross } from 'assets/svg/deleteCross.svg';
import { ReactComponent as drag } from 'assets/svg/drag.svg';
import { ReactComponent as dropdown } from 'assets/svg/dropdown.svg';
import { ReactComponent as duplicate } from 'assets/svg/duplicate.svg';
import { ReactComponent as edit } from 'assets/svg/edit.svg';
import { ReactComponent as github } from 'assets/svg/github.svg';
import { ReactComponent as home } from 'assets/svg/home.svg';
import { ReactComponent as data } from 'assets/svg/import.svg';
import { ReactComponent as info } from 'assets/svg/info.svg';
import { ReactComponent as logo } from 'assets/svg/logo.svg';
import { ReactComponent as logout } from 'assets/svg/logout.svg';
import { ReactComponent as menu } from 'assets/svg/menu.svg';
import { ReactComponent as more } from 'assets/svg/more.svg';
import { ReactComponent as operations } from 'assets/svg/operations.svg';
import { ReactComponent as orders } from 'assets/svg/orders.svg';
import { ReactComponent as picker } from 'assets/svg/picker.svg';
import { ReactComponent as plus } from 'assets/svg/plus.svg';
import { ReactComponent as reports } from 'assets/svg/reports.svg';
import { ReactComponent as search } from 'assets/svg/search.svg';
import { ReactComponent as settings } from 'assets/svg/settings.svg';
import { ReactComponent as sortArrow } from 'assets/svg/sortArrow.svg';
import { ReactComponent as staff } from 'assets/svg/staff.svg';
import { ReactComponent as support } from 'assets/svg/support.svg';
import { ReactComponent as upload } from 'assets/svg/upload.svg';
import { ReactComponent as users } from 'assets/svg/users.svg';
import { ReactComponent as venues } from 'assets/svg/venues.svg';

// Export all icons as default
export default {
	accounts,
	appUser,
	brands,
	camera,
	checkbox,
	chevron,
	close,
	data,
	deleteCross,
	drag,
	dropdown,
	duplicate,
	edit,
	home,
	info,
	logo,
	logout,
	menu,
	more,
	operations,
	orders,
	picker,
	plus,
	reports,
	search,
	settings,
	sortArrow,
	staff,
	support,
	upload,
	users,
	venues,
	github,
};
