import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import withNav from 'modules/navigation/with-nav.component';

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

interface IComponentProps {}

const ProfilePage: FunctionComponent<IComponentProps> = () => {
	return (
		<StyledWrapper>
			<h1>
				<FormattedMessage id="generic.page.underDevelopment" />
			</h1>
		</StyledWrapper>
	);
};

export default withNav(ProfilePage);
