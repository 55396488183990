import { Formik, Form, FormikHelpers, Field } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { number, object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import FieldError from 'components/field-error/field-error.component';
import Input from 'components/form-inputs/input/input.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TimePickerInput from 'components/form-inputs/time-picker/time-picker.component';
import {
	convertToMinutes,
	convertToSeconds,
} from 'helpers/time-conversion.helpers';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	genericValidationNumber,
	genericValidationString,
} from 'helpers/validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	formatVenueOpeningAsApi,
	formatVenueOpeningAsForm,
	getVenueServiceAvailabilityConfig,
	updateVenueServiceAvailabilityConfig,
} from 'modules/venue/slices/venue.slice';
import {
	IVenueOpeningFormValues,
	IvenueServiceAvailabilityConfig,
	IVenueServiceAvailabilityConfigFormValues,
	IVenueServiceDay,
} from 'modules/venue/venue.types';

interface IFieldGroupProps {
	borderBottom?: boolean;
}

const StyledFieldGroup = styled.div<IFieldGroupProps>`
	width: 100%;
	display: flex;
	justify-content: space-between;

	&.flex-start {
		justify-content: flex-start;
	}

	${({ borderBottom }) =>
		borderBottom &&
		css`
			border-bottom: 1px solid ${brand.body};
			padding-top: 5px;
			margin-bottom: 25px;
		`}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledColumnFull = styled.div`
	width: 100%;
	padding: 35px 0 50px;
`;

const StyledDay = styled.div`
	display: flex;
	flex-direction: column;
	padding: 13px 10px;
	border-radius: 10px;

	&:not(:last-child) {
		border-bottom: 1px solid ${brand.body};
	}

	div {
		text-align: center;
	}

	h3 {
		min-width: 35px;
		margin: 0 15px 0 0;
		font-weight: ${fonts.weights.regular};
	}
`;

interface IDayFieldsProps {
	limitMaxOrders: boolean;
}

const StyledDayFields = styled.div<IDayFieldsProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	> div {
		width: calc(100% / ${({ limitMaxOrders }) => (limitMaxOrders ? 6 : 5)});
	}
`;

const StyledDayFieldWrapper = styled.div`
	display: flex;
	align-items: center;

	> * {
		margin: 0;
	}

	&.mod-title,
	h3 {
		font-weight: ${fonts.weights.medium};
	}
`;

const StyledInput = styled(Input)`
	max-width: 65px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 1;

	&:last-of-type {
		margin-right: 0;
	}

	div,
	input {
		flex-shrink: 1;
	}

	label {
		margin: 0 10px 0 0;
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.medium};
	}

	input {
		min-width: 59px;
		padding: 0 12px;
	}
`;

const StyledTimePickerInput = styled(TimePickerInput)`
	max-width: 65px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	activeService: string;
	handleDelete: Function;
	deleteServiceButtonText: string;
	formSubmission: boolean;
	setFormSubmission: Function;
	venueId: string;
}

const VenueServiceConfigAvailabilityForm: FunctionComponent<IComponentProps> = ({
	handleDelete,
	deleteServiceButtonText,
	formSubmission,
	setFormSubmission,
	venueId,
	activeService,
}) => {
	const dispatch = useReduxDispatch();

	// Get active config from state
	const availabilityConfig = useSelector(
		(state: RootState) => state.venue.activeVenueServiceConfig?.availability
	);

	useEffect(() => {
		const getData = async (id: string) => {
			await dispatch(
				getVenueServiceAvailabilityConfig(id, activeService)
			);
		};

		venueId && getData(venueId);
	}, [dispatch, venueId, activeService]);

	// Default opening times field values
	const defaultOpening: IVenueServiceDay = {
		open: false,
		openingTime: '',
		closingTime: '',
		interval: '',
		maxOrdersPerSlot: '',
	};

	const defaultOpeningTimes: IVenueOpeningFormValues = {
		monday: defaultOpening,
		tuesday: defaultOpening,
		wednesday: defaultOpening,
		thursday: defaultOpening,
		friday: defaultOpening,
		saturday: defaultOpening,
		sunday: defaultOpening,
	};

	// Initial form values
	const initialValues: IVenueServiceAvailabilityConfigFormValues = {
		limitMaxOrders: availabilityConfig
			? !availabilityConfig.ignoreMaxOrders
			: false,
		prepTime: availabilityConfig?.prepTime.toString() || '0',
		openingTimes:
			(availabilityConfig?.openingTimes &&
				formatVenueOpeningAsForm(availabilityConfig?.openingTimes)) ||
			defaultOpeningTimes,
		orderAlertEnabled: availabilityConfig?.orderAlertEnabled || false,
		initialAlertSeconds:
			(availabilityConfig?.initialAlertSeconds &&
				convertToMinutes(
					availabilityConfig.initialAlertSeconds
				).toString()) ||
			'0',
		escalationAlertSeconds:
			(availabilityConfig?.escalationAlertSeconds &&
				convertToMinutes(
					availabilityConfig.escalationAlertSeconds
				).toString()) ||
			'0',
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenueServiceAvailabilityConfigFormValues,
		{
			setSubmitting,
		}: FormikHelpers<IVenueServiceAvailabilityConfigFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Update venue service config
		const response = await dispatch(
			updateVenueServiceAvailabilityConfig(venueId, activeService, {
				orderAlertEnabled: values.orderAlertEnabled,
				ignoreMaxOrders: !values.limitMaxOrders,
				openingTimes: formatVenueOpeningAsApi(values.openingTimes),
				prepTime: parseFloat(values.prepTime),
				initialAlertSeconds: convertToSeconds(
					parseFloat(values.initialAlertSeconds)
				),
				escalationAlertSeconds: convertToSeconds(
					parseFloat(values.escalationAlertSeconds)
				),
			})
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	const dayValidation = object().shape({
		openingTime: string().when('open', {
			is: (value) => !!value,
			then: genericValidationString({
				fieldName: 'openingTime',
			}).matches(
				/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
				intl.formatMessage({
					id: 'errors.forms.time.type',
				})
			),
		}),
		closingTime: string().when('open', {
			is: (value) => !!value,
			then: genericValidationString({
				fieldName: 'closingTime',
			}).matches(
				/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
				intl.formatMessage({
					id: 'errors.forms.time.type',
				})
			),
		}),
	});

	// Validation schema
	const formValidationSchema = object<
		IvenueServiceAvailabilityConfig
	>().shape({
		openingTimes: object<IVenueOpeningFormValues>().shape({
			monday: dayValidation,
			tuesday: dayValidation,
			wednesday: dayValidation,
			thursday: dayValidation,
			friday: dayValidation,
			saturday: dayValidation,
			sunday: dayValidation,
		}),
		initialAlertSeconds: number().when('orderAlertEnabled', {
			is: (value) => !!value,
			then: genericValidationNumber({
				fieldName: 'initialAlertSeconds',
				min: 0,
				max: 999,
			}),
		}),
		escalationAlertSeconds: number().when('orderAlertEnabled', {
			is: (value) => !!value,
			then: genericValidationNumber({
				fieldName: 'escalationAlertSeconds',
				min: 0,
				max: 999,
			}),
		}),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting, values, errors }) => (
				<Form>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="venueForm.services.availability.title" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								name="limitMaxOrders"
								label={intl.formatMessage({
									id: 'form.fields.timeslotMaxOrders.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.timeslotMaxOrders.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="prepTime"
								label={intl.formatMessage({
									id: 'form.fields.orderPrepTime.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.orderPrepTime.label',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledColumnFull>
						<StyledDay>
							<StyledDayFields
								limitMaxOrders={values.limitMaxOrders}
							>
								<StyledDayFieldWrapper className="mod-title">
									<FormattedMessage id="venueForm.services.availability.table.heading.day" />
								</StyledDayFieldWrapper>
								<StyledDayFieldWrapper className="mod-title">
									<FormattedMessage id="venueForm.services.availability.table.heading.availability" />
								</StyledDayFieldWrapper>
								<StyledDayFieldWrapper className="mod-title">
									<FormattedMessage id="venueForm.services.availability.table.heading.from" />
								</StyledDayFieldWrapper>
								<StyledDayFieldWrapper className="mod-title">
									<FormattedMessage id="venueForm.services.availability.table.heading.to" />
								</StyledDayFieldWrapper>
								<StyledDayFieldWrapper className="mod-title">
									<FormattedMessage id="venueForm.services.availability.table.heading.timeSlot" />
								</StyledDayFieldWrapper>
								{values.limitMaxOrders && (
									<StyledDayFieldWrapper className="mod-title">
										<FormattedMessage id="venueForm.services.availability.table.heading.ordersPerSlot" />
									</StyledDayFieldWrapper>
								)}
							</StyledDayFields>
						</StyledDay>
						{[
							'monday',
							'tuesday',
							'wednesday',
							'thursday',
							'friday',
							'saturday',
							'sunday',
						].map((day: string) => (
							<StyledDay key={day}>
								<StyledDayFields
									limitMaxOrders={values.limitMaxOrders}
								>
									<StyledDayFieldWrapper>
										<h3>
											{day.substring(0, 3).toUpperCase()}
										</h3>
									</StyledDayFieldWrapper>
									<StyledDayFieldWrapper>
										<Field
											component={Switch}
											name={`openingTimes.${day}.open`}
											onText={intl.formatMessage({
												id: 'form.fields.venueOpen.on',
											})}
											offText={intl.formatMessage({
												id: 'form.fields.venueOpen.off',
											})}
											showText={false}
										/>
									</StyledDayFieldWrapper>
									<StyledDayFieldWrapper>
										<Field
											component={StyledTimePickerInput}
											name={`openingTimes.${day}.openingTime`}
											isDisabled={
												!values.openingTimes[day].open
											}
										/>
									</StyledDayFieldWrapper>
									<StyledDayFieldWrapper>
										<Field
											component={StyledTimePickerInput}
											name={`openingTimes.${day}.closingTime`}
											isDisabled={
												!values.openingTimes[day].open
											}
										/>
									</StyledDayFieldWrapper>
									<StyledDayFieldWrapper>
										<Field
											component={StyledInput}
											name={`openingTimes.${day}.interval`}
											isDisabled={
												!values.openingTimes[day].open
											}
										/>
									</StyledDayFieldWrapper>
									{values.limitMaxOrders && (
										<StyledDayFieldWrapper>
											<Field
												component={StyledInput}
												name={`openingTimes.${day}.maxOrdersPerSlot`}
												isDisabled={
													!values.openingTimes[day]
														.open
												}
											/>
										</StyledDayFieldWrapper>
									)}
								</StyledDayFields>
								{errors.openingTimes &&
									errors.openingTimes[day] && (
									<FieldError
										ariaLabel={`openingTimes.${day}.open-error`}
									>
										<FormattedMessage id="form.fields.openingTimes.empty" />
									</FieldError>
								)}
							</StyledDay>
						))}
					</StyledColumnFull>
					<h2>
						<FormattedMessage id="venueForm.services.orderAlerts.title" />
					</h2>
					<StyledFieldGroup borderBottom={true}>
						<StyledColumn>
							<Field
								component={Switch}
								name="orderAlertEnabled"
								label={intl.formatMessage({
									id: 'form.fields.orderAlertsEnabled.label',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Input}
								name="initialAlertSeconds"
								label={intl.formatMessage({
									id: 'form.fields.initialAlertSeconds.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.initialAlertSeconds.label',
								})}
								type="number"
								isDisabled={!values.orderAlertEnabled}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="escalationAlertSeconds"
								label={intl.formatMessage({
									id:
										'form.fields.escalationAlertSeconds.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.escalationAlertSeconds.label',
								})}
								type="number"
								isDisabled={!values.orderAlertEnabled}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							<Button
								variant="secondary"
								onClick={() => handleDelete()}
								ariaLabel="delete-button"
							>
								{deleteServiceButtonText}
							</Button>
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</Form>
			)}
		</Formik>
	);
};

export default VenueServiceConfigAvailabilityForm;
