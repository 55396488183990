import currency from 'currency.js';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import queryString from 'query-string';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { array, object, string } from 'yup';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { hasPriceBands, formatPrice } from 'helpers/price-band.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setActiveAccount } from 'modules/auth/auth.slice';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getModifierItemDetails,
	resetMenuState,
	createModifierItem,
	deleteModifierItem,
	getMenuPreferences,
	getModifierGroupsList,
	patchModifierItemDetails,
} from 'modules/menu/menu.slice';
import {
	IModifierItemFormValues,
	IModifierItemSubmitValues,
} from 'modules/menu/menu.types';
import TextArea from 'components/form-inputs/text-area/text-area.component';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IFormValidation {
	title?: string;
	modifierGroups?: IOption[];
}

interface IComponentProps {
	modifierItemId?: string | undefined;
}

const ModifierItemDetailsForm: FunctionComponent<IComponentProps> = ({
	modifierItemId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const history = useHistory();
	// Get location hook
	const location = useLocation();

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active modifier item details from store
	const {
		activeModifierItemDetails: modifierDetails,
		modifierGroups,
		preferences,
	} = useSelector((state: RootState) => state.menu);

	useEffect(() => {
		// Get modifier item data by modifer item id
		const getData = async (id: string | undefined) => {
			// If account id in URL params has changed
			if (
				query.activeAccount &&
				query.activeAccount !== activeAccountId
			) {
				// Update active account id
				await dispatch(setActiveAccount(query.activeAccount));
			}

			// If we have an ID, get details
			!!id && (await dispatch(getModifierItemDetails(id)));
			await dispatch(getMenuPreferences());
			await dispatch(getModifierGroupsList({ pageSize: -1 }));
		};

		getData(modifierItemId);

		// useEffect cleanup
		return () => {
			dispatch(resetMenuState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifierItemId, dispatch, query.activeAccount]);

	// get allergens and dietary from preferences
	const { allergens, dietary } = preferences;

	// Map allergens to options
	const allergenOptions = allergens.map((option) => {
		return {
			value: option.id,
			label: option.title,
		};
	});

	// Map allergens to options
	const dietaryOptions = dietary.map((option) => {
		return {
			value: option.id,
			label: option.title,
		};
	});

	// map modifier groups to options
	const modifierGroupOptions = modifierGroups?.map((option) => {
		return {
			value: option.id,
			label: `${option.title} (${option.sku})`,
		};
	});

	// Create initial dietary values from Dietary options and current form values
	const initialDietaryValues: IOption[] = dietaryOptions.filter((option) => {
		return modifierDetails?.dietaryPreferences?.includes(option.value);
	});

	// Create initial allergen values from Dietary options and current form values
	const initialAllergenValues: IOption[] = allergenOptions.filter(
		(option) => {
			return modifierDetails?.allergens?.includes(option.value);
		}
	);

	const initialModifierGroups: IOption[] =
		modifierGroupOptions?.filter((option) => {
			return modifierDetails?.modifierGroups?.includes(option.value);
		}) || [];

	const initialDefaultModifierGroups: IOption[] =
		modifierGroupOptions?.filter((option) => {
			return modifierDetails?.defaultModifierGroups?.includes(
				option.value
			);
		}) || [];

	const initialWhiteListedModifierGroups: IOption[] =
		modifierGroupOptions?.filter((option) => {
			return modifierDetails?.whiteListedModifierGroups?.includes(
				option.value
			);
		}) || [];

	// convert modifier price to decimal
	const modifierPrice = !hasPriceBands(modifierDetails)
		? formatPrice(modifierDetails?.price, '')
		: undefined;

	// Initial form values
	const initialValues: IModifierItemFormValues = {
		title: (modifierItemId && modifierDetails?.title) || '',
		sku: (modifierItemId && modifierDetails?.sku) || '',
		subtitle: (modifierItemId && modifierDetails?.subtitle) || '',
		isActive: (modifierItemId && modifierDetails?.isActive) || false,
		imageUrl: (modifierItemId && modifierDetails?.imageUrl) || '',
		price: (modifierItemId && modifierPrice) || '0',
		overridesProductPrice:
			(modifierItemId && modifierDetails?.overridesProductPrice) || false,
		allergens: (modifierItemId && initialAllergenValues) || [],
		dietaryPreferences: (modifierItemId && initialDietaryValues) || [],
		ageRestricted:
			(modifierItemId && modifierDetails?.ageRestricted) || false,
		modifierGroups: (modifierItemId && initialModifierGroups) || [],
		defaultModifierGroups:
			(modifierItemId && initialDefaultModifierGroups) || [],
		whiteListedModifierGroups:
			(modifierItemId && initialWhiteListedModifierGroups) || [],
	};

	// Handle form submission
	const handleSubmit = async (
		values: IModifierItemFormValues,
		{ setSubmitting }: FormikHelpers<IModifierItemFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Map values to submit values
		const allergenValues = values.allergens?.map(
			(option: IOption) => option.value?.toString() || ''
		);
		const dietaryValues = values.dietaryPreferences?.map(
			(option: IOption) => option.value?.toString() || ''
		);
		const modifierGroupValues = values.modifierGroups?.map(
			(option: IOption) => option.value?.toString() || ''
		);
		const whitelistedModifierGroupsValues = values.whiteListedModifierGroups?.map(
			(option: IOption) => option.value?.toString() || ''
		);
		const defaultModifierGroupsValues = values.defaultModifierGroups?.map(
			(option: IOption) => option.value?.toString() || ''
		);

		// Create new form values
		const formValues: IModifierItemSubmitValues = {
			...values,
			price: !hasPriceBands(modifierDetails)
				? currency(values.price || 0).intValue
				: undefined,
			allergens: allergenValues || [],
			dietaryPreferences: dietaryValues || [],
			modifierGroups: modifierGroupValues || [],
			whiteListedModifierGroups: whitelistedModifierGroupsValues || [],
			defaultModifierGroups: defaultModifierGroupsValues || [],
		};

		// Create / update
		const response = await (modifierItemId
			? dispatch(patchModifierItemDetails(modifierItemId, formValues))
			: dispatch(createModifierItem(formValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: modifierItemId
					? intl.formatMessage({
							id: 'modifierItemForm.alerts.updated.message',
					  })
					: intl.formatMessage({
							id: 'modifierItemForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		!modifierItemId &&
			history.push(
				`/menus/modifier-item/edit/${response?.id}/details?activeAccount=${activeAccountId}`
			);
	};

	// Handle deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'modifierItemForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'modifierItemForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete action
		const response =
			modifierItemId &&
			(await dispatch(deleteModifierItem(modifierItemId)));

		// Return if fail
		if (!response) {
			return;
		}
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'modifierItemForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to modifier items
		history.push(`/menus/modifier-items?activeAccount=${activeAccountId}`);
	};

	const formValidationSchema = object<IFormValidation>().shape({
		title: string().required(validationMessages.required('name')),
		modifierGroups: array().required(
			validationMessages.required('modifierGroups')
		),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, values, isSubmitting }) => {
				const defaultModifierGroupOptions = values.modifierGroups;
				return (
					<StyledForm>
						<Prompt when={dirty && !formSubmission} message="" />
						<h2>
							<FormattedMessage id="modifierItemForm.headings.details" />
						</h2>
						<StyledFormFields>
							<StyledColumn>
								<Field
									component={Input}
									name="title"
									label={intl.formatMessage({
										id: 'form.fields.name.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.name.label',
									})}
									toolTip={{
										title: intl.formatMessage({
											id: 'form.fields.name.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.name.toolTip.description',
										}),
									}}
								/>
								<Field
									component={Switch}
									name="isActive"
									label={intl.formatMessage({
										id: 'form.fields.published.label',
									})}
									toolTip={{
										title: intl.formatMessage({
											id: 'form.fields.published.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.published.toolTip.description',
										}),
									}}
								/>
								<Field
									component={Input}
									name="sku"
									label={intl.formatMessage({
										id: 'form.fields.sku.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.sku.label',
									})}
									toolTip={{
										title: intl.formatMessage({
											id: 'form.fields.sku.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.sku.toolTip.description',
										}),
									}}
								/>
								<Field
									component={TextArea}
									name="subtitle"
									label={intl.formatMessage({
										id: 'form.fields.description.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.description.label',
									})}
								/>
								<Field
									component={Select}
									isMulti
									testId="modifierGroups-select"
									name="modifierGroups"
									selectOptions={modifierGroupOptions}
									label={intl.formatMessage({
										id: 'form.fields.modifierGroups.label',
									})}
									value={values.modifierGroups}
									toolTip={{
										title: intl.formatMessage({
											id:
												'form.fields.modifierGroups.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.modifierGroups.toolTip.description',
										}),
									}}
								/>
								<Field
									component={Select}
									isMulti
									name="defaultModifierGroups"
									selectOptions={defaultModifierGroupOptions}
									label={intl.formatMessage({
										id:
											'form.fields.defaultModifierGroups.label',
									})}
									value={values.defaultModifierGroups}
									toolTip={{
										title: intl.formatMessage({
											id:
												'form.fields.defaultModifierGroups.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.defaultModifierGroups.toolTip.description',
										}),
									}}
								/>
								{!hasPriceBands(modifierDetails) && (
									<Field
										component={Input}
										inputSymbol="£"
										name="price"
										label={intl.formatMessage({
											id: 'form.fields.price.label',
										})}
										placeholder={intl.formatMessage({
											id: 'form.fields.price.label',
										})}
										toolTip={{
											title: intl.formatMessage({
												id: 'form.fields.price.label',
											}),
											description: intl.formatMessage({
												id:
													'modifierItemForm.field.price.toolTip.description',
											}),
										}}
									/>
								)}
								<Field
									component={Switch}
									name="overridesProductPrice"
									label={intl.formatMessage({
										id: 'form.fields.priceOverride.label',
									})}
									toolTip={{
										title: intl.formatMessage({
											id:
												'form.fields.priceOverride.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.priceOverride.toolTip.description',
										}),
									}}
								/>
							</StyledColumn>
							<StyledColumn>
								<StyledFieldGroup>
									<StyledColumn>
										<Field
											component={ImageUpload}
											name="imageUrl"
											label={intl.formatMessage({
												id:
													'form.fields.modifierItemImage.label',
											})}
											uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/menu/images/modifier-item`}
											toolTip={{
												title: intl.formatMessage({
													id:
														'form.fields.modifierItemImage.label',
												}),
												description: intl.formatMessage(
													{
														id:
															'modifierItemForm.field.modifierItemImage.toolTip.description',
													}
												),
											}}
										/>
									</StyledColumn>
								</StyledFieldGroup>
								<Field
									component={Select}
									isMulti
									name="allergens"
									selectOptions={allergenOptions}
									label={intl.formatMessage({
										id: 'form.fields.allergens.label',
									})}
									value={values.allergens}
									toolTip={{
										title: intl.formatMessage({
											id: 'form.fields.allergens.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.allergens.toolTip.description',
										}),
									}}
								/>
								<Field
									component={Select}
									isMulti
									testId="dietary-preferences-select"
									name="dietaryPreferences"
									selectOptions={dietaryOptions}
									label={intl.formatMessage({
										id:
											'form.fields.dietaryPreferences.label',
									})}
									value={values.dietaryPreferences}
									toolTip={{
										title: intl.formatMessage({
											id:
												'form.fields.dietaryPreferences.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.dietaryPreferences.toolTip.description',
										}),
									}}
								/>
								<Field
									component={Switch}
									name="ageRestricted"
									label={intl.formatMessage({
										id: 'form.fields.ageRestricted.label',
									})}
									toolTip={{
										title: intl.formatMessage({
											id:
												'form.fields.ageRestricted.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.ageRestricted.toolTip.description',
										}),
									}}
								/>
								<Field
									component={Select}
									isMulti
									name="whiteListedModifierGroups"
									selectOptions={modifierGroupOptions}
									label={intl.formatMessage({
										id:
											'form.fields.whiteListedModifierGroups.label',
									})}
									value={values.whiteListedModifierGroups}
									toolTip={{
										title: intl.formatMessage({
											id:
												'form.fields.whiteListedModifierGroups.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierItemForm.field.whiteListedModifierGroups.toolTip.description',
										}),
									}}
								/>
							</StyledColumn>
						</StyledFormFields>
						<StyledActions>
							<div>
								<Link
									to={`/menus/modifier-items?activeAccount=${activeAccountId}`}
								>
									<Button variant="secondary">
										<FormattedMessage id="form.button.back" />
									</Button>
								</Link>
								{modifierItemId && (
									<Button
										variant="secondary"
										onClick={handleDelete}
										ariaLabel="delete-button"
									>
										<FormattedMessage id="modifierItemForm.button.delete" />
									</Button>
								)}
							</div>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage
									id={
										modifierItemId
											? 'form.button.save'
											: 'modifierItemForm.button.create'
									}
								/>
							</Button>
						</StyledActions>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export default ModifierItemDetailsForm;
