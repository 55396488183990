import { createSlice, Dispatch } from '@reduxjs/toolkit';
import get from 'lodash.get';

import {
	IVenueState,
	IVenueItem,
	IVenuesListFilters,
	IVenueCreateFormValues,
	IVenueDetailsFormValues,
	IVenueDetails,
	IVenueConfigFormValues,
	IVenuePaymentFormValues,
	IVenueConfig,
	IVenueOpeningFormValues,
	IVenueOpening,
	IZonalSalesArea,
	IVenueGiftingConfigFormValues,
	IVenueServiceGeneralConfig,
	IVenueServicePosConfig,
	IVenueServiceServiceChargeConfig,
	IvenueServiceAvailabilityConfig,
	IVenueServiceTippingConfigSubmitValues,
	IVenuePublishFormValues,
	IVenuePrinterSubmitValues,
} from '../venue.types';

import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { ICuisine } from 'modules/menu/menu.types';

// Create initial state
export const initialVenueState: IVenueState = {
	venues: [],
	eventsInProgress: 0,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
};

const venueSlice = createSlice({
	name: 'venue',
	initialState: initialVenueState,
	reducers: {
		SET_VENUES(state, action) {
			return {
				...state,
				venues: action.payload,
			};
		},
		GET_ALL_VENUES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ALL_VENUES_SUCCESS(state, action) {
			return {
				...state,
				venues: action.payload.data.venues,
				pagination: {
					...state.pagination,
					pageNumber: action.payload.data.pagination.pageNumber,
					pageCount: action.payload.data.pagination.pageCount,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_VENUES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_VENUE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_VENUE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_VENUE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_VENUE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_VENUE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_VENUE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ACTIVE_VENUE_DETAILS(state, action) {
			return {
				...state,
				activeVenueDetails: action.payload,
			};
		},
		GET_VENUE_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ACTIVE_VENUE_CONFIG(state, action) {
			return {
				...state,
				activeVenueConfig: action.payload,
			};
		},
		GET_VENUE_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ACTIVE_VENUE_PAYMENT(state, action) {
			return {
				...state,
				activeVenuePayment: action.payload,
			};
		},
		GET_VENUE_PAYMENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_PAYMENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_PAYMENT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PAYMENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_PAYMENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PAYMENT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ACTIVE_VENUE_OPENING(state, action) {
			return {
				...state,
				activeVenueOpening: action.payload,
			};
		},
		GET_VENUE_OPENING(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_OPENING_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_OPENING_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_OPENING(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_OPENING_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_OPENING_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ACTIVE_VENUE_PUBLISH(state, action) {
			return {
				...state,
				activeVenuePublish: action.payload,
			};
		},
		UPDATE_VENUE_PUBLISH(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_PUBLISH_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PUBLISH_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REFRESH_VENUE_MENU_CACHE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		REFRESH_VENUE_MENU_CACHE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REFRESH_VENUE_MENU_CACHE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_VENUE_SERVICE_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_VENUE_SERVICE_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_VENUE_SERVICE_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		// END REMOVAL
		CREATE_VENUE_SERVICE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_VENUE_SERVICE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_VENUE_SERVICE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SERVICE_GENERAL_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_SERVICE_GENERAL_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeVenueServiceConfig: {
					...state.activeVenueServiceConfig,
					general: action.payload.data,
				},
			};
		},
		GET_VENUE_SERVICE_GENERAL_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_GENERAL_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_SERVICE_GENERAL_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_GENERAL_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SERVICE_POS_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_SERVICE_POS_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeVenueServiceConfig: {
					...state.activeVenueServiceConfig,
					pos: action.payload.data,
				},
			};
		},
		GET_VENUE_SERVICE_POS_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_POS_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_SERVICE_POS_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_POS_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SERVICE_TIPPING_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_SERVICE_TIPPING_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeVenueServiceConfig: {
					...state.activeVenueServiceConfig,
					tipping: action.payload.data,
				},
			};
		},
		GET_VENUE_SERVICE_TIPPING_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_TIPPING_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_SERVICE_TIPPING_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_TIPPING_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SERVICE_SERVICE_CHARGE_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_SERVICE_SERVICE_CHARGE_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeVenueServiceConfig: {
					...state.activeVenueServiceConfig,
					serviceCharge: action.payload.data,
				},
			};
		},
		GET_VENUE_SERVICE_SERVICE_CHARGE_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_SERVICE_CHARGE_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_SERVICE_SERVICE_CHARGE_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_SERVICE_CHARGE_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SERVICE_AVAILABILITY_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_SERVICE_AVAILABILITY_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeVenueServiceConfig: {
					...state.activeVenueServiceConfig,
					availability: action.payload.data,
				},
			};
		},
		GET_VENUE_SERVICE_AVAILABILITY_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_AVAILABILITY_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_SERVICE_AVAILABILITY_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_SERVICE_AVAILABILITY_CONFIG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ACTIVE_ZONAL_SALES_AREAS(state, action) {
			return {
				...state,
				activeZonalSalesAreas: action.payload,
			};
		},
		GET_ZONAL_SALES_AREAS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ZONAL_SALES_AREAS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ZONAL_SALES_AREAS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SYNC_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_SYNC_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				activeVenueSync: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_SYNC_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ZONAL_VENUE_SYNC(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ZONAL_VENUE_SYNC_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ZONAL_VENUE_SYNC_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_GIFTING(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_GIFTING_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_GIFTING_SUCCESS(state, action) {
			return {
				...state,
				activeVenueGiftingConfig: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_GIFTING(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_GIFTING_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_GIFTING_SUCCESS(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_PRINTERS_LIST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_PRINTERS_LIST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_PRINTERS_LIST_SUCCESS(state, action) {
			return {
				...state,
				activeVenuePrinters: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_PRINTER_IMAGE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_PRINTER_IMAGE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_PRINTER_IMAGE_SUCCESS(state, action) {
			return {
				...state,
				activeVenuePrinterImage: action.payload.data.receiptLogoUrl,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PRINTER_IMAGE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_PRINTER_IMAGE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PRINTER_IMAGE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_PRINTER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_PRINTER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_PRINTER_SUCCESS(state, action) {
			return {
				...state,
				activeVenuePrinter: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_VENUE_PRINTER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_VENUE_PRINTER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_VENUE_PRINTER_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PRINTER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_VENUE_PRINTER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_VENUE_PRINTER_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_VENUE_PRINTER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_VENUE_PRINTER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_VENUE_PRINTER_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_VENUE_STATE() {
			return {
				...initialVenueState,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	SET_VENUES,
	GET_ALL_VENUES,
	GET_ALL_VENUES_SUCCESS,
	GET_ALL_VENUES_FAIL,
	CREATE_VENUE,
	CREATE_VENUE_FAIL,
	CREATE_VENUE_SUCCESS,
	DELETE_VENUE,
	DELETE_VENUE_FAIL,
	DELETE_VENUE_SUCCESS,
	ACTIVE_VENUE_DETAILS,
	GET_VENUE_DETAILS,
	GET_VENUE_DETAILS_FAIL,
	GET_VENUE_DETAILS_SUCCESS,
	UPDATE_VENUE_DETAILS,
	UPDATE_VENUE_DETAILS_FAIL,
	UPDATE_VENUE_DETAILS_SUCCESS,
	ACTIVE_VENUE_CONFIG,
	GET_VENUE_CONFIG,
	GET_VENUE_CONFIG_FAIL,
	GET_VENUE_CONFIG_SUCCESS,
	UPDATE_VENUE_CONFIG,
	UPDATE_VENUE_CONFIG_FAIL,
	UPDATE_VENUE_CONFIG_SUCCESS,
	ACTIVE_VENUE_PAYMENT,
	GET_VENUE_PAYMENT,
	GET_VENUE_PAYMENT_FAIL,
	GET_VENUE_PAYMENT_SUCCESS,
	UPDATE_VENUE_PAYMENT,
	UPDATE_VENUE_PAYMENT_FAIL,
	UPDATE_VENUE_PAYMENT_SUCCESS,
	ACTIVE_VENUE_OPENING,
	GET_VENUE_OPENING,
	GET_VENUE_OPENING_FAIL,
	GET_VENUE_OPENING_SUCCESS,
	UPDATE_VENUE_OPENING,
	UPDATE_VENUE_OPENING_FAIL,
	UPDATE_VENUE_OPENING_SUCCESS,
	ACTIVE_VENUE_PUBLISH,
	UPDATE_VENUE_PUBLISH,
	UPDATE_VENUE_PUBLISH_FAIL,
	UPDATE_VENUE_PUBLISH_SUCCESS,
	REFRESH_VENUE_MENU_CACHE,
	REFRESH_VENUE_MENU_CACHE_FAIL,
	REFRESH_VENUE_MENU_CACHE_SUCCESS,
	DELETE_VENUE_SERVICE_CONFIG,
	DELETE_VENUE_SERVICE_CONFIG_FAIL,
	DELETE_VENUE_SERVICE_CONFIG_SUCCESS,
	GET_VENUE_SERVICE_GENERAL_CONFIG,
	GET_VENUE_SERVICE_GENERAL_CONFIG_FAIL,
	GET_VENUE_SERVICE_GENERAL_CONFIG_SUCCESS,
	UPDATE_VENUE_SERVICE_GENERAL_CONFIG,
	UPDATE_VENUE_SERVICE_GENERAL_CONFIG_FAIL,
	UPDATE_VENUE_SERVICE_GENERAL_CONFIG_SUCCESS,
	GET_VENUE_SERVICE_POS_CONFIG,
	GET_VENUE_SERVICE_POS_CONFIG_FAIL,
	GET_VENUE_SERVICE_POS_CONFIG_SUCCESS,
	UPDATE_VENUE_SERVICE_POS_CONFIG,
	UPDATE_VENUE_SERVICE_POS_CONFIG_FAIL,
	UPDATE_VENUE_SERVICE_POS_CONFIG_SUCCESS,
	GET_VENUE_SERVICE_TIPPING_CONFIG,
	GET_VENUE_SERVICE_TIPPING_CONFIG_FAIL,
	GET_VENUE_SERVICE_TIPPING_CONFIG_SUCCESS,
	UPDATE_VENUE_SERVICE_TIPPING_CONFIG,
	UPDATE_VENUE_SERVICE_TIPPING_CONFIG_FAIL,
	UPDATE_VENUE_SERVICE_TIPPING_CONFIG_SUCCESS,
	GET_VENUE_SERVICE_SERVICE_CHARGE_CONFIG,
	GET_VENUE_SERVICE_SERVICE_CHARGE_CONFIG_FAIL,
	GET_VENUE_SERVICE_SERVICE_CHARGE_CONFIG_SUCCESS,
	UPDATE_VENUE_SERVICE_SERVICE_CHARGE_CONFIG,
	UPDATE_VENUE_SERVICE_SERVICE_CHARGE_CONFIG_FAIL,
	UPDATE_VENUE_SERVICE_SERVICE_CHARGE_CONFIG_SUCCESS,
	GET_VENUE_SERVICE_AVAILABILITY_CONFIG,
	GET_VENUE_SERVICE_AVAILABILITY_CONFIG_FAIL,
	GET_VENUE_SERVICE_AVAILABILITY_CONFIG_SUCCESS,
	UPDATE_VENUE_SERVICE_AVAILABILITY_CONFIG,
	UPDATE_VENUE_SERVICE_AVAILABILITY_CONFIG_FAIL,
	UPDATE_VENUE_SERVICE_AVAILABILITY_CONFIG_SUCCESS,
	ACTIVE_ZONAL_SALES_AREAS,
	GET_ZONAL_SALES_AREAS,
	GET_ZONAL_SALES_AREAS_FAIL,
	GET_ZONAL_SALES_AREAS_SUCCESS,
	GET_VENUE_SYNC_DETAILS,
	GET_VENUE_SYNC_DETAILS_SUCCESS,
	GET_VENUE_SYNC_DETAILS_FAIL,
	GET_ZONAL_VENUE_SYNC,
	GET_ZONAL_VENUE_SYNC_SUCCESS,
	GET_ZONAL_VENUE_SYNC_FAIL,
	GET_VENUE_GIFTING,
	GET_VENUE_GIFTING_FAIL,
	GET_VENUE_GIFTING_SUCCESS,
	UPDATE_VENUE_GIFTING,
	UPDATE_VENUE_GIFTING_FAIL,
	UPDATE_VENUE_GIFTING_SUCCESS,
	GET_VENUE_PRINTERS_LIST,
	GET_VENUE_PRINTERS_LIST_FAIL,
	GET_VENUE_PRINTERS_LIST_SUCCESS,
	GET_VENUE_PRINTER_IMAGE,
	GET_VENUE_PRINTER_IMAGE_FAIL,
	GET_VENUE_PRINTER_IMAGE_SUCCESS,
	UPDATE_VENUE_PRINTER_IMAGE,
	UPDATE_VENUE_PRINTER_IMAGE_FAIL,
	UPDATE_VENUE_PRINTER_IMAGE_SUCCESS,
	GET_VENUE_PRINTER,
	GET_VENUE_PRINTER_FAIL,
	GET_VENUE_PRINTER_SUCCESS,
	CREATE_VENUE_PRINTER,
	CREATE_VENUE_PRINTER_FAIL,
	CREATE_VENUE_PRINTER_SUCCESS,
	UPDATE_VENUE_PRINTER,
	UPDATE_VENUE_PRINTER_FAIL,
	UPDATE_VENUE_PRINTER_SUCCESS,
	DELETE_VENUE_PRINTER,
	DELETE_VENUE_PRINTER_FAIL,
	DELETE_VENUE_PRINTER_SUCCESS,
	RESET_VENUE_STATE,
	CREATE_VENUE_SERVICE,
	CREATE_VENUE_SERVICE_FAIL,
	CREATE_VENUE_SERVICE_SUCCESS,
} = venueSlice.actions;

/** Update venues in store */
export const setVenues = (venues: IVenueItem[]) => (dispatch: Dispatch) => {
	dispatch(SET_VENUES(venues));
};

/** Get venues list */
export const getVenuesList = (filters?: IVenuesListFilters) => async (
	dispatch: Dispatch
) => {
	try {
		// Create request
		const { payload } = await dispatch(
			GET_ALL_VENUES({
				request: {
					method: 'get',
					url: 'venue',
					params: filters,
				},
			})
		);

		return payload?.data;
	} catch (error) {
		return [];
	}
};

/** Create venue */
export const createVenue = (venue: IVenueCreateFormValues) => async (
	dispatch: Dispatch
) => {
	// Create venue request
	const { payload } = await dispatch(
		CREATE_VENUE({
			request: {
				method: 'post',
				url: 'venue',
				data: venue,
			},
		})
	);

	return payload?.data;
};

/** Delete venue by ID */
export const deleteVenue = (venueId: string) => async (dispatch: Dispatch) => {
	// Create delete venue request
	const { payload } = await dispatch(
		DELETE_VENUE({
			request: {
				method: 'delete',
				url: `venue/${venueId}`,
			},
		})
	);

	return payload.status === 200;
};

/** Get venue details by ID */
export const getVenueDetails = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create venue details request
	const { payload } = await dispatch(
		GET_VENUE_DETAILS({
			request: {
				method: 'get',
				url: `venue/${venueId}/details`,
			},
		})
	);

	await dispatch(
		ACTIVE_VENUE_DETAILS(formatVenueDetailsAsForm(payload?.data))
	);
};

/** Update venue details */
export const updateVenueDetails = (
	venueId: string,
	details: IVenueDetailsFormValues,
	cuisines: ICuisine[]
) => async (dispatch: Dispatch) => {
	// Create update request
	const { payload } = await dispatch(
		UPDATE_VENUE_DETAILS({
			request: {
				method: 'patch',
				url: `venue/${venueId}/details`,
				data: formatVenueDetailsAsAPI(details, cuisines),
			},
		})
	);

	return payload.status === 200;
};

/** Get venue config */
export const getVenueConfig = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_CONFIG({
			request: {
				method: 'get',
				url: `venue/${venueId}/config`,
			},
		})
	);

	if (payload?.data) {
		await dispatch(
			ACTIVE_VENUE_CONFIG(formatVenueConfigAsForm(payload?.data))
		);
		await dispatch(
			ACTIVE_VENUE_PUBLISH({
				isPublished: payload?.data?.isPublished,
				isPublishedForWeb: payload?.data?.isPublishedForWeb,
				isTestVenue: payload?.data?.isTestVenue,
			})
		);
	}
};

/** Update venue config */
export const updateVenueConfig = (
	venueId: string,
	config: IVenueConfigFormValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_CONFIG({
			request: {
				method: 'patch',
				url: `venue/${venueId}/config`,
				data: formatVenueConfigAsAPI(config),
			},
		})
	);

	return payload.status === 200;
};

/** Get venue payment */
export const getVenuePayment = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_PAYMENT({
			request: {
				method: 'get',
				url: `venue/${venueId}/payment`,
			},
		})
	);

	await dispatch(ACTIVE_VENUE_PAYMENT(payload?.data));
};

/** Update venue payment */
export const updateVenuePayment = (
	venueId: string,
	values: IVenuePaymentFormValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_PAYMENT({
			request: {
				method: 'put',
				url: `venue/${venueId}/payment`,
				data: {
					...values,
					orderpayPercentageFee: Number(values.orderpayPercentageFee),
				},
			},
		})
	);

	return payload.status === 200;
};

/** Get venue opening times */
export const getVenueOpening = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_OPENING({
			request: {
				method: 'get',
				url: `venue/${venueId}/opening-times`,
			},
		})
	);

	payload?.data &&
		(await dispatch(
			ACTIVE_VENUE_OPENING(formatVenueOpeningAsForm(payload?.data))
		));
};

/** Update venue opening times */
export const updateVenueOpening = (
	venueId: string,
	values: IVenueOpeningFormValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_OPENING({
			request: {
				method: 'put',
				url: `venue/${venueId}/opening-times`,
				data: formatVenueOpeningAsApi(values),
			},
		})
	);

	return payload.status === 200;
};

/** Update venue published */
export const updateVenuePublish = (
	venueId: string,
	values: IVenuePublishFormValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_PUBLISH({
			request: {
				method: 'patch',
				url: `venue/${venueId}/is-published`,
				data: {
					isPublished: values.isPublished,
					isPublishedForWeb: values.isPublishedForWeb,
					isTestVenue: values.isTestVenue,
				},
			},
		})
	);

	return payload.status === 200;
};

/** refresh venue menu cache */
export const refreshVenueMenuCache = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		REFRESH_VENUE_MENU_CACHE({
			request: {
				method: 'post',
				url: `venue/${venueId}/cache/menu/bust`,
			},
		})
	);

	return payload.status === 200;
};

/** delete venue service config */
export const deleteVenueServiceConfig = (
	venueId: string,
	activeService: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		DELETE_VENUE_SERVICE_CONFIG({
			request: {
				method: 'delete',
				url: `venue/${venueId}/services/${activeService}`,
			},
		})
	);

	return payload.status === 200;
};

/** Get venue service general config */
export const getVenueServiceGeneralConfig = (
	venueId: string,
	activeService: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_SERVICE_GENERAL_CONFIG({
			request: {
				method: 'get',
				url: `venue/${venueId}/venue-service/${activeService}/general`,
			},
		})
	);

	return payload?.data;
};

/** update venue service general config */
export const updateVenueServiceGeneralConfig = (
	venueId: string,
	activeService: string,
	data: IVenueServiceGeneralConfig
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_SERVICE_GENERAL_CONFIG({
			request: {
				method: 'patch',
				url: `venue/${venueId}/venue-service/${activeService}/general`,
				data,
			},
		})
	);

	return payload?.data;
};

/** Get venue service pos config */
export const getVenueServicePosConfig = (
	venueId: string,
	activeService: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_SERVICE_POS_CONFIG({
			request: {
				method: 'get',
				url: `venue/${venueId}/venue-service/${activeService}/pos`,
			},
		})
	);

	return payload?.data;
};

/** update venue service pos config */
export const updateVenueServicePosConfig = (
	venueId: string,
	activeService: string,
	data: Partial<IVenueServicePosConfig>
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_SERVICE_POS_CONFIG({
			request: {
				method: 'patch',
				url: `venue/${venueId}/venue-service/${activeService}/pos`,
				data,
			},
		})
	);

	return payload?.data;
};

/** Get venue service tipping config */
export const getVenueServiceTippingConfig = (
	venueId: string,
	activeService: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_SERVICE_TIPPING_CONFIG({
			request: {
				method: 'get',
				url: `venue/${venueId}/venue-service/${activeService}/tipping`,
			},
		})
	);

	return payload?.data;
};

/** update venue service tipping config */
export const updateVenueServiceTippingConfig = (
	venueId: string,
	activeService: string,
	data: IVenueServiceTippingConfigSubmitValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_SERVICE_TIPPING_CONFIG({
			request: {
				method: 'patch',
				url: `venue/${venueId}/venue-service/${activeService}/tipping`,
				data,
			},
		})
	);

	return payload?.data;
};

/** Get venue service charge config */
export const getVenueServiceServiceChargeConfig = (
	venueId: string,
	activeService: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_SERVICE_SERVICE_CHARGE_CONFIG({
			request: {
				method: 'get',
				url: `venue/${venueId}/venue-service/${activeService}/service-charge`,
			},
		})
	);

	return payload?.data;
};

/** update venue service charge config */
export const updateVenueServiceServiceChargeConfig = (
	venueId: string,
	activeService: string,
	data: IVenueServiceServiceChargeConfig
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_SERVICE_SERVICE_CHARGE_CONFIG({
			request: {
				method: 'patch',
				url: `venue/${venueId}/venue-service/${activeService}/service-charge`,
				data,
			},
		})
	);

	return payload?.data;
};

/** Get venue service availability config */
export const getVenueServiceAvailabilityConfig = (
	venueId: string,
	activeService: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_SERVICE_AVAILABILITY_CONFIG({
			request: {
				method: 'get',
				url: `venue/${venueId}/venue-service/${activeService}/availability`,
			},
		})
	);

	return payload?.data;
};

/** update venue service availability config */
export const updateVenueServiceAvailabilityConfig = (
	venueId: string,
	activeService: string,
	data: IvenueServiceAvailabilityConfig
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_SERVICE_AVAILABILITY_CONFIG({
			request: {
				method: 'patch',
				url: `venue/${venueId}/venue-service/${activeService}/availability`,
				data,
			},
		})
	);

	return payload?.data;
};

export const createVenueService = (
	venueId: string,
	activeService: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		CREATE_VENUE_SERVICE({
			request: {
				method: 'post',
				url: `venue/${venueId}/venue-service/${activeService}`,
			},
		})
	);

	return payload.status === 200;
};

/** Set zonal sales areas in store */
export const setZonalSalesAreas = (values: IZonalSalesArea[] | undefined) => (
	dispatch: Dispatch
) => {
	dispatch(ACTIVE_ZONAL_SALES_AREAS(values));
};

/** Get zonal sales areas */
export const getZonalSalesAreas = (venueId: string, siteId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_ZONAL_SALES_AREAS({
			request: {
				method: 'get',
				url: `zonal/${venueId}/${siteId}/sales-areas`,
			},
		})
	);

	// If no sales areas were returned
	if (siteId && payload?.data && !payload?.data.length) {
		// Fire error dialog
		fireDialog({
			title: intl.formatMessage({
				id: 'errors.responses.404.title',
			}),
			text: intl.formatMessage(
				{
					id: 'errors.forms.getZonalSalesAreas.empty',
				},
				{ siteId }
			),
			icon: 'error',
		});
	}

	payload?.data && (await dispatch(ACTIVE_ZONAL_SALES_AREAS(payload.data)));

	return payload?.data;
};

/** Get venue sync status */
export const getVenueSyncDetails = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_SYNC_DETAILS({
			request: {
				method: 'get',
				url: `venue/${venueId}/sync-status`,
			},
		})
	);

	return payload?.data;
};

/** Get venue gifting config */
export const getVenueGiftingConfig = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_GIFTING({
			request: {
				method: 'get',
				url: `venue/${venueId}/gifting-config`,
			},
		})
	);

	return payload?.data;
};

/** Update venue gifting config */
export const updateVenueGiftingConfig = (
	venueId: string,
	data: IVenueGiftingConfigFormValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_GIFTING({
			request: {
				method: 'put',
				url: `venue/${venueId}/gifting-config`,
				data,
			},
		})
	);

	return payload.status === 200;
};

/** Get venue printers list */
export const getVenuePrintersList = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_PRINTERS_LIST({
			request: {
				method: 'get',
				url: `venue/${venueId}/printer`,
			},
		})
	);

	return payload?.data;
};

/** Get venue printer image */
export const getVenuePrinterImage = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_PRINTER_IMAGE({
			request: {
				method: 'get',
				url: `venue/${venueId}/printing-config`,
			},
		})
	);

	return payload?.data;
};

/** update venue printer image */
export const updateVenuePrinterImage = (
	venueId: string,
	receiptLogoUrl: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_PRINTER_IMAGE({
			request: {
				method: 'put',
				url: `venue/${venueId}/printing-config`,
				data: { receiptLogoUrl },
			},
		})
	);

	return payload.status === 200;
};

/** Get venue printer */
export const getVenuePrinter = (venueId: string, printerId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_VENUE_PRINTER({
			request: {
				method: 'get',
				url: `venue/${venueId}/printer/${printerId}`,
			},
		})
	);

	return payload?.data;
};

/** create venue printer */
export const createVenuePrinter = (
	venueId: string,
	data: IVenuePrinterSubmitValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		CREATE_VENUE_PRINTER({
			request: {
				method: 'post',
				url: `venue/${venueId}/printer`,
				data,
			},
		})
	);

	return payload?.status === 201;
};

/** update venue printer */
export const updateVenuePrinter = (
	venueId: string,
	printerId: string,
	data: IVenuePrinterSubmitValues
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		UPDATE_VENUE_PRINTER({
			request: {
				method: 'patch',
				url: `venue/${venueId}/printer/${printerId}`,
				data,
			},
		})
	);

	return payload?.status === 200;
};

/** delete venue printer */
export const deleteVenuePrinter = (
	venueId: string,
	printerId: string
) => async (dispatch: Dispatch) => {
	// Create request
	const { payload } = await dispatch(
		DELETE_VENUE_PRINTER({
			request: {
				method: 'delete',
				url: `venue/${venueId}/printer/${printerId}`,
			},
		})
	);

	return payload?.data;
};

/** Trigger zonal sync for venue */
export const getZonalVenueSync = (venueId: string) => async (
	dispatch: Dispatch
) => {
	// Create request
	const { payload } = await dispatch(
		GET_ZONAL_VENUE_SYNC({
			request: {
				method: 'get',
				url: `zonal/${venueId}/sync`,
			},
		})
	);

	return payload?.data;
};

/** Reset Venue State */
export const resetVenueState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_VENUE_STATE());
};

/* FORMATTING FUNCTIONS */

/** Format selected cuisines for API */
export const formatVenueDetailsAsAPI = (
	details: IVenueDetailsFormValues,
	cuisines: ICuisine[]
) => {
	// Reassign details and remove accountId property
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { accountId, ...formattedDetails } = details;

	// Store for selected cuisines
	let selectedCuisines;
	// If we have cuisine form data
	if (formattedDetails?.cuisine) {
		// Get cuisine IDs from venue details
		selectedCuisines = Object.keys(formattedDetails.cuisine)
			// Remove falsey cuisines
			.filter((cuisineId: string) => {
				return !!get(formattedDetails, `cuisine[${cuisineId}]`);
			})
			// Get full cuisine data
			.map((cuisine: string) => ({
				id: cuisine,
				title: cuisines.find((item) => item.id === cuisine)?.title,
			}));
	}

	return {
		...formattedDetails,
		cuisine: (formattedDetails?.cuisine && selectedCuisines) || [],
	};
};

/** Format venue details from API as form values */
export const formatVenueDetailsAsForm = (
	data: IVenueDetails
): IVenueDetailsFormValues => {
	let selectedCuisines = {};
	let coverUrls = '';

	if (data?.cuisine) {
		for (const cuisine of data?.cuisine) {
			selectedCuisines = {
				...selectedCuisines,
				[cuisine.id]: true,
			};
		}
	}

	if (data?.coverUrls) {
		coverUrls = JSON.stringify(data?.coverUrls);
	}

	return {
		...data,
		cuisine: selectedCuisines,
		coverUrls,
	};
};

/** Format venue config from form for API */
export const formatVenueConfigAsAPI = (
	data: IVenueConfigFormValues
): IVenueConfig => ({
	...data,
	currency: {
		...data.currency,
		code: data.currency?.code?.value
			? data.currency.code.value.toString()
			: '',
		symbol: data.currency?.symbol?.value
			? data.currency.symbol.value.toString()
			: '',
	},
	gpsLocation: {
		...data.gpsLocation,
		lat: Number(data.gpsLocation?.lat),
		lng: Number(data.gpsLocation?.lng),
		radius: Number(data.gpsLocation?.radius),
	},
});

/** Format venue config from API as form values */
export const formatVenueConfigAsForm = (
	data: IVenueConfig
): IVenueConfigFormValues => ({
	...data,
	currency: {
		...data.currency,
		code: {
			label: data.currency.code,
			value: data.currency.code,
		},
		symbol: {
			label: data.currency.symbol,
			value: data.currency.symbol,
		},
	},
	gpsLocation: {
		...data.gpsLocation,
		lat: data.gpsLocation?.lat ? data.gpsLocation.lat.toString() : '',
		lng: data.gpsLocation?.lng ? data.gpsLocation.lng.toString() : '',
		radius: data.gpsLocation?.radius
			? data.gpsLocation.radius.toString()
			: '',
	},
	campaignEventName: data.campaignEventName,
});

/** Format venue opening times from API as form values */
export const formatVenueOpeningAsForm = (
	data: IVenueOpening[]
): IVenueOpeningFormValues => {
	// Create default day
	const defaultDay = {
		open: false,
		openingTime: '',
		closingTime: '',
		interval: '',
		maxOrdersPerSlot: '',
	};

	// Create default form values
	const formValues: IVenueOpeningFormValues = {
		monday: defaultDay,
		tuesday: defaultDay,
		wednesday: defaultDay,
		thursday: defaultDay,
		friday: defaultDay,
		saturday: defaultDay,
		sunday: defaultDay,
	};

	// Array of days of the week
	const days = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	];

	// Loop through days in data
	for (const day of data) {
		// Add each day to form values
		formValues[days[day.day]] = {
			open: true,
			openingTime: day.openingTime,
			closingTime: day.closingTime,
			interval: day?.interval?.toString(),
			maxOrdersPerSlot: day?.maxOrdersPerSlot?.toString(),
		};
	}

	return formValues;
};

/** Format venue opening times from form values for API */
export const formatVenueOpeningAsApi = (
	values: IVenueOpeningFormValues
): IVenueOpening[] => {
	// Array of days of the week
	const days = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	];

	return Object.entries(values)
		.filter((day) => day[1].open)
		.map((day) => {
			return {
				day: days.indexOf(day[0]),
				name: day[0],
				openingTime: day[1].openingTime || '',
				closingTime: day[1].closingTime || '',
				interval: (day[1].interval && parseFloat(day[1].interval)) || 0,
				maxOrdersPerSlot:
					(day[1].maxOrdersPerSlot &&
						parseFloat(day[1].maxOrdersPerSlot)) ||
					0,
			};
		});
};

export default venueSlice.reducer;
