import { Formik, Form, Field, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import DatePickerInput from 'components/form-inputs/date-picker/date-picker.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { getTransactionReport } from 'modules/report/report.slice';

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledSubtitle = styled.p`
	margin-bottom: 25px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	accountId?: string;
	brandId?: string;
	venueId?: string;
	history: History;
}

const ReportTransaction: FunctionComponent<IComponentProps> = ({
	accountId,
	brandId,
	venueId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	interface IFormValues {
		dateFrom: Date;
		dateTo: Date;
	}

	// Initial form values
	const initialValues: IFormValues = {
		dateFrom: new Date(),
		dateTo: new Date(),
	};

	// Handle form submission
	const handleSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		await dispatch(
			getTransactionReport({
				...values,
				accountId,
				brandId,
				venueId,
			})
		);

		// Set formik submission state to false
		setSubmitting(false);
	};

	// Generate back link url
	const backLink = () => {
		if (accountId) {
			return '/accounts';
		}
		if (brandId) {
			return '/brands';
		}
		if (venueId) {
			return '/venues';
		}
		return '';
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting }) => (
				<StyledForm>
					<StyledSubtitle>
						<FormattedMessage id="reports.transaction.subtitle" />
					</StyledSubtitle>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={DatePickerInput}
								name="dateFrom"
								label={intl.formatMessage({
									id: 'form.fields.dateFrom.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.dateFrom.label',
								})}
								maxDate={values.dateTo}
							/>
							<Field
								component={DatePickerInput}
								name="dateTo"
								label={intl.formatMessage({
									id: 'form.fields.dateTo.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.dateTo.label',
								})}
								minDate={values.dateFrom}
								maxDate={new Date()}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<Link to={backLink()} data-testid="back-link">
							<Button variant="secondary">
								<FormattedMessage id="form.button.back" />
							</Button>
						</Link>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.download" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default ReportTransaction;
