import dayjs from 'dayjs';
import queryString from 'query-string';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import DailyVolumeReportResults from './daily-volume-report-results/daily-volume-report-results.component';
import GiftAnalysisReportResults from './gift-analysis-report-results/gift-analysis-report-results.component';
import GiftExpiryReportResults from './gift-expiry-report-results/gift-expiry-report-results.component';
import GiftRedemptionReportResults from './gift-redemption-report-results/gift-redemption-report-results.component';
import GiftSalesReportResults from './gift-sales-report-results/gift-sales-report-results.component';
import TransactionReportResults from './transaction-report-results/transaction-report-results.component';

import { IQueryParams } from 'app.types';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Pagination from 'components/pagination/pagination.component';
import { RootState } from 'modules/core/state/root.reducer';

const StyledReportResults = styled.div`
	padding: 0 0 20px;
`;

const StyledReportDetails = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 15px;
`;

const StyledReportTitle = styled.h3`
	margin: 0;
	text-transform: capitalize;
	font-size: ${fonts.sizes.large};
`;

const StyledReportDate = styled.div`
	font-size: ${fonts.sizes.large};
	font-weight: ${fonts.weights.regular};
	margin: 0 20px 0 0;
`;

const StyledReportDateWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const StyledButton = styled(Button)`
	margin: 0 15px 0 0;
`;

const ReportResults: FunctionComponent = () => {
	// Get react hooks
	const history = useHistory();
	const location = useLocation();
	// Get query params
	const query: IQueryParams = queryString.parse(location.search);
	// Get currently paginated report from state
	const { activeReport, paginatedReport } = useSelector(
		(state: RootState) => state.report
	);

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};
	return (
		<StyledReportResults>
			<StyledReportDetails>
				<StyledReportTitle>
					{activeReport?.type.replace(/([A-Z])/g, ' $1').trim()}{' '}
					<FormattedMessage id="reports.results.reportTitle" />
				</StyledReportTitle>
				<StyledReportDateWrapper>
					<StyledReportDate>
						{dayjs(activeReport?.parameters.startDate).format(
							'D MMM YYYY'
						)}{' '}
						-{' '}
						{dayjs(activeReport?.parameters.endDate).format(
							'D MMM YYYY'
						)}
					</StyledReportDate>
					{activeReport?.urls?.json && (
						<StyledButton
							ariaLabel="download-json"
							onClick={() => {
								window.open(activeReport?.urls?.json, '_blank');
							}}
						>
							<FormattedMessage id="reports.downloads.button.json" />
						</StyledButton>
					)}
					{activeReport?.urls?.csv && (
						<StyledButton
							ariaLabel="download-csv"
							onClick={() => {
								window.open(activeReport?.urls?.csv, '_blank');
							}}
						>
							<FormattedMessage id="reports.downloads.button.csv" />
						</StyledButton>
					)}
					{activeReport?.urls?.excel && (
						<StyledButton
							ariaLabel="download-excel"
							onClick={() => {
								window.open(
									activeReport?.urls?.excel,
									'_blank'
								);
							}}
						>
							<FormattedMessage id="reports.downloads.button.excel" />
						</StyledButton>
					)}
				</StyledReportDateWrapper>
			</StyledReportDetails>
			{!!paginatedReport && paginatedReport?.data && (
				<>
					{activeReport?.type === 'transaction' && (
						<TransactionReportResults
							reportData={paginatedReport.data}
						/>
					)}
					{activeReport?.type === 'dailyVolume' && (
						<DailyVolumeReportResults
							reportData={paginatedReport.data}
						/>
					)}
					{activeReport?.type === 'giftAnalysis' && (
						<GiftAnalysisReportResults
							reportData={paginatedReport.data}
						/>
					)}
					{activeReport?.type === 'giftSales' && (
						<GiftSalesReportResults
							reportData={paginatedReport.data}
						/>
					)}
					{activeReport?.type === 'giftRedemptions' && (
						<GiftRedemptionReportResults
							reportData={paginatedReport.data}
						/>
					)}
					{activeReport?.type === 'expiredGifts' && (
						<GiftExpiryReportResults
							reportData={paginatedReport.data}
						/>
					)}
					{paginatedReport.pageCount > 1 && (
						<Pagination
							pageNumber={
								query.pageNumber
									? parseFloat(query.pageNumber)
									: 1
							}
							pageCount={paginatedReport.pageCount}
							setPageNumber={handlePagination}
						/>
					)}
				</>
			)}
		</StyledReportResults>
	);
};

export default ReportResults;
