import { FieldProps } from 'formik';
import get from 'lodash.get';
import { rgba } from 'polished';
import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import { intl } from 'modules/core/i18n/i18n.config';

// Interface for component props
interface IComponentProps {
	label?: string;
	minDate?: Date;
	maxDate?: Date;
	handleChangeEvent?: Function;
	showTimeSelect?: boolean;
}

const StyledFormElement = styled(FormElement)`
	.react-datepicker-wrapper {
		width: 100%;
	}

	input {
		width: 100%;
		height: 40px;
		line-height: 40px;
		padding: 0 14px;
		border: 1px solid ${brand.borders};
		border-radius: 5px;
		font-size: ${fonts.sizes.standard};
		color: ${brand.text};

		&::placeholder {
			font-size: ${fonts.sizes.standard};
			font-weight: ${fonts.weights.regular};
			color: ${brand.placeholder};
		}

		&:active,
		&:focus {
			padding: 0 14px;
			border: 1px solid ${brand.link};
			outline: none;
			box-shadow: none;
		}

		&.has-error {
			background: ${rgba(brand.validation_error, 0.05)};
			border: 1px solid ${brand.validation_error};
		}
	}

	svg {
		display: none;
	}
`;

const StyledLabel = styled.label`
	margin-bottom: 10px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

/** Renders DatePickerInput component */
const DatePickerInput: FunctionComponent<
	IComponentProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	field,
	form,
	label,
	handleChangeEvent,
	minDate,
	maxDate,
	showTimeSelect = true,
}) => {
	// Get touched and errors from form state
	const { touched, errors } = form;
	// Check if field is touched, or has errors
	const isTouched = get(touched, field.name) || false;
	const hasErrors = get(errors, field.name) || false;

	return (
		<StyledFormElement>
			{label && <StyledLabel>{label}</StyledLabel>}
			<DatePicker
				data-testid="date-picker"
				selected={field.value}
				onChange={(day: Date) => {
					form.setFieldValue(field.name, day);
					if (handleChangeEvent) {
						handleChangeEvent({
							fieldValue: day,
							form,
						});
					}
				}}
				showTimeSelect={showTimeSelect}
				timeFormat="HH:mm"
				timeIntervals={15}
				timeCaption={intl.formatMessage({
					id: 'form.fields.datePicker.time',
				})}
				dateFormat={
					showTimeSelect ? 'MMMM do, yyyy HH:mm' : 'MMMM do, yyyy'
				}
				minDate={minDate}
				maxDate={maxDate}
				showPopperArrow={false}
			/>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</StyledFormElement>
	);
};

export default DatePickerInput;
