import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { array, object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	createVenuePrinter,
	deleteVenuePrinter,
	updateVenuePrinter,
} from 'modules/venue/slices/venue.slice';
import {
	IPrinterModelOptions,
	IPrintCategoryOptions,
	IPrinterPurposeOptions,
	IVenuePrinterFormValues,
	IVenuePrinterSubmitValues,
} from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledSwitch = styled(Switch)`
	min-height: 69px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	venueId: string;
	printerId?: string | undefined;
}

const VenuePrinterForm: FunctionComponent<IComponentProps> = ({
	venueId,
	printerId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const history = useHistory();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active printer details from store
	const printerDetails = useSelector(
		(state: RootState) => state.venue.activeVenuePrinter
	);

	// Create form options
	const printerCategoryOptions: IPrintCategoryOptions[] = [
		{ label: 'all', value: -1 } as const,
		{ label: '0', value: 0 } as const,
		{ label: '1', value: 1 } as const,
		{ label: '2', value: 2 } as const,
		{ label: '3', value: 3 } as const,
		{ label: '4', value: 4 } as const,
		{ label: '5', value: 5 } as const,
		{ label: '6', value: 6 } as const,
		{ label: '7', value: 7 } as const,
		{ label: '8', value: 8 } as const,
		{ label: '9', value: 9 } as const,
	];

	const printerModelOptions: IPrinterModelOptions[] = [
		{ label: 'Epson', value: 'epson' } as const,
		{ label: 'Star', value: 'star' } as const,
		{ label: 'Epson Compatible', value: 'quickprinter' } as const,
	];

	const printerPurposeOptions: IPrinterPurposeOptions[] = [
		{ label: 'order', value: 'order' } as const,
		{ label: 'expediter', value: 'expediter' } as const,
	];

	// Format options to form values
	const initialCategoryOptions: IPrintCategoryOptions[] = useMemo(
		() =>
			printerCategoryOptions?.filter((option) => {
				return printerDetails?.categories?.includes(option.value);
			}) || [],

		[printerCategoryOptions, printerDetails]
	);

	const initialPrinterPurposeOptions: IPrinterPurposeOptions[] = useMemo(
		() =>
			printerPurposeOptions?.filter((option) => {
				return printerDetails?.purpose?.includes(option.value);
			}) || [],
		[printerPurposeOptions, printerDetails]
	);

	// Initial form values
	const initialValues: IVenuePrinterFormValues = {
		name: (printerId && printerDetails?.name) || '',
		printerId: (printerId && printerDetails?.printerId) || '',
		model: (printerId && printerDetails?.model) || 'epson',
		purpose: (printerId && initialPrinterPurposeOptions) || [],
		categories: (printerId && initialCategoryOptions) || [],
		active:
			printerId && typeof printerDetails?.active === 'boolean'
				? printerDetails?.active
				: true,
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenuePrinterFormValues,
		{ setSubmitting, setFieldValue }: FormikHelpers<IVenuePrinterFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		let printerName = values.name;

		// If name is empty, set it to printer name
		if (!values.name) {
			printerName = `${values.model} Printer`;
			setFieldValue('name', printerName);
		}

		// Format to submit values
		const formValues: IVenuePrinterSubmitValues = {
			...values,
			name: printerName,
			categories: values.categories.map((category) => category.value),
			purpose: values.purpose.map((pur) => pur.value),
		};

		// Create / update
		const response = await (printerId
			? dispatch(updateVenuePrinter(venueId, printerId, formValues))
			: dispatch(createVenuePrinter(venueId, formValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: printerId
					? intl.formatMessage({
						id: 'venuePrinterForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'venuePrinterForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		!printerId && history.push(`/venues/edit/${venueId}/printing`);
	};

	// Handle deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'venuePrinterForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'venuePrinterForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete action
		const response =
			printerId &&
			(await dispatch(deleteVenuePrinter(venueId, printerId)));

		// Return if fail
		if (!response) {
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venuePrinterForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to venue printers
		history.push(`/venues/edit/${venueId}/printing`);
	};

	// Form validation
	const formValidationSchema = object<IVenuePrinterFormValues>().shape({
		model: string().required(validationMessages.required('model')),
		printerId: string()
			.when('model', {
				is: (value) => value === 'epson',
				then: string().required(
					validationMessages.required('printerId')
				),
			})
			.when('model', {
				is: (value) => value === 'star',
				then: string().test(
					'is-mac-address',
					intl.formatMessage(
						{ id: 'errors.forms.validation.macAddress' },
						{
							field: intl.formatMessage({
								id: 'form.fields.printerId.label',
							}),
						}
					),
					(value) =>
						value.match(
							/^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/
						)
				),
			}),
		purpose: array().required(validationMessages.required('printerType')),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, values, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="venuePrinterForm.headings.details" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={StyledSwitch}
								name="active"
								label={intl.formatMessage({
									id: 'form.fields.printerSwitch.label',
								})}
							/>
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.printerName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.printerName.label',
								})}
							/>
							<Field
								component={Select}
								name="model"
								label={intl.formatMessage({
									id: 'form.fields.model.label',
								})}
								selectOptions={printerModelOptions}
								value={printerModelOptions.find(
									(option) => option.value === values.model
								)}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="printerId"
								label={intl.formatMessage({
									id: 'form.fields.printerId.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.printerId.label',
								})}
							/>
							<Field
								component={Select}
								isMulti
								name="purpose"
								label={intl.formatMessage({
									id: 'form.fields.printerType.label',
								})}
								selectOptions={printerPurposeOptions}
								value={values.purpose}
							/>
							<Field
								component={Select}
								isMulti
								name="categories"
								isDisabled={
									values.purpose &&
									!values.purpose.find(
										(purp) => purp.value === 'order'
									)
								}
								label={intl.formatMessage({
									id: 'form.fields.printerCategories.label',
								})}
								selectOptions={printerCategoryOptions}
								value={values.categories}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link to={`/venues/edit/${venueId}/printing`}>
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{printerId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="venuePrinterForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage
								id={
									printerId
										? 'form.button.save'
										: 'venuePrinterForm.button.create'
								}
							/>
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default VenuePrinterForm;
