import MockAdapter from 'axios-mock-adapter/types';

import {
	IBrandItem,
	IBrand,
	IBrandDetailsFormValues,
	IBrandCreateRequest,
	IBrandCreateResponse,
	IBrandUpdateRequest,
	IBrandUpdateResponse,
	IBrandGifting,
	IBrandGiftingFormValues,
	IBrandPriceBand,
	IBrandPriceBandFormValues,
	IBrandFeedbackFormValues,
	IBrandMarketingOptin,
	IBrandDetails,
	IBrandReceiptFooterFormValues,
} from './brand.types';

import mockPagination from 'components/pagination/pagination.mock';

/** MOCKED DATA */

const brandId: string = '87a0cb2d-2697-4291-82c0-1aa5514f2298';

const brandItem: IBrandItem = {
	id: 'f5168493-d9c9-40cd-820a-49ac7ce23876',
	accountId: 'd387dd41-0949-4d81-8aa0-8866e3403258',
	name: 'FIVE GUYS',
	logoUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
};

const brandsList: IBrandItem[] = [
	{
		id: 'f5168493-d9c9-40cd-820a-49ac7ce23876',
		accountId: 'd387dd41-0949-4d81-8aa0-8866e3403258',
		name: 'FIVE GUYS',
		logoUrl:
			'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
	},
	{
		id: '3115a138-ad0b-4388-838b-dfc76758137f',
		accountId: 'd387dd41-0949-4d81-8aa0-8866e3403258',
		name: 'PRET',
		logoUrl:
			'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/pret_logo.png',
	},
	{
		id: '33de8493-d9c9-40cd-820a-49ac7ce23876',
		accountId: 'd387dd41-0949-4d81-8aa0-8866e3403258',
		name: 'AAA Brand',
		logoUrl:
			'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
	},
	{
		id: '3dr58493-d9c9-40cd-820a-49ac7ce23876',
		accountId: 'd387dd41-0949-4d81-8aa0-8866e3403258',
		name: 'ZZZ Brand',
		logoUrl:
			'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
	},
];

const brand: IBrand = {
	id: 'f5168493-d9c9-40cd-820a-49ac7ce23876',
	name: 'FIVE GUYS',
	vatNo: 'vatnumb3r',
	description: 'some description',
	website: 'https://www.fiveguys.co.uk',
	logoUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
	coverUrls: [
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/covers/fiveguys_cover.jpg',
	],
	accountId: 'd387dd41-0949-4d81-8aa0-8866e3403258',
	createdAt: '2020-06-03T16:03:34.714Z',
	deletedAt: null,
	font: {
		id: 'bde90e59-f00f-4e09-82bf-9b81fcc1518f',
		fontFamily: 'arial',
		url:
			'https://orderpay.s3.eu-west-2.amazonaws.com/brands/fonts/Khand-SemiBold.ttf',
		h1: 40,
		h2: 24,
		h3: 18,
		text: 14,
		highlights: 10,
		smallText: 8,
		brandId: 'f5168493-d9c9-40cd-820a-49ac7ce23876',
	},
	colour: {
		id: '7c672a6a-964e-4ab4-adf2-9b471f322cfa',
		bgPrimary: '#e20019',
		bgSecondary: '#FFFFFF',
		lblPrimary: '#FFFFFF',
		lblSecondary: '#e20019',
		actPrimary: '#FFFFFF',
		actSecondary: '#e20019',
		actLblPrimary: '#e20019',
		actLblSecondary: '#FFFFFF',
		brandId: 'f5168493-d9c9-40cd-820a-49ac7ce23876',
	},
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	vatConfig: {
		standardRate: 20,
		reducedRate: 5,
		zeroRate: 0,
	},
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
	account: {
		id: '6432245b-33c2-42fa-abaf-d694034b8aa7',
		name: 'MAJOR Cheeto 5',
	},
	feedbackEnabled: true,
	feedbackEmail: 'test@orderpay.com',
};

const brandPriceBandList: IBrandPriceBand[] = [
	{
		id: '297c5acf-9384-4694-8233-df35405cdf32',
		name: 'Tier 1',
		subPriceBands: [],
	},
	{
		id: '53d0951c-887d-40a1-9127-6d1fe43b6e59',
		name: 'Tier 2',
		subPriceBands: [],
	},
	{
		id: 'e803b7a7-ed05-4d2a-9643-862abd7fc0ff',
		name: 'Tier 3',
		subPriceBands: [],
	},
	{
		id: '84f326d6-8d19-4dac-9021-da72d4d04e74',
		name: 'Tier 4',
		subPriceBands: [],
	},
	{
		id: 'e59e9796-accd-46fa-afd5-12bcd1293727',
		name: 'Tier 5',
		subPriceBands: [],
	},
];

const brandPriceBandId: string = '297c5acf-9384-4694-8233-df35405cdf32';

const brandPriceBand: IBrandPriceBand = {
	id: '297c5acf-9384-4694-8233-df35405cdf32',
	name: 'Tier 1',
	subPriceBands: [],
};

const brandMarketingOptin: IBrandMarketingOptin = {
	id: '297c5acf-9384-4694-8233-df35405cdf32',
	target: {
		entityType: 'brand',
		entityId: brandId,
	},
	isEnabled: true,
	privacyUrl: 'https://www.orderpay.com',
	integrationType: null,
	integrationConfig: {
		exportMethod: 'csv',
		exportFrequency: 'daily',
		exportEmail: 'exports@orderpay.com',
	},
};

/** MOCKED FORM VALUES */
const brandFormValues: IBrandDetailsFormValues = {
	name: 'FIVE GUYS',
	vatNo: 'vatnumb3r',
	logoUrl: '',
	coverUrls: [],
	description: 'some description',
	website: 'https://www.fiveguys.co.uk',
	bgPrimary: '#e20019',
	lblPrimary: '#FFFFFF',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	vatConfig: {
		standardRate: '20',
		reducedRate: '5',
		zeroRate: '0',
	},
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
};
const brandDetails: IBrandDetails = {
	name: 'FIVE GUYS',
	vatNo: 'vatnumb3r',
	logoUrl: '',
	coverUrls: [],
	description: 'some description',
	website: 'https://www.fiveguys.co.uk',
	fontFamily: 'arial',
	fontUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/brands/fonts/Khand-SemiBold.ttf',
	h1: '40',
	h2: '24',
	h3: '18',
	text: '14',
	highlights: '10',
	smallText: '8',
	bgPrimary: '#e20019',
	bgSecondary: '#FFFFFF',
	lblPrimary: '#FFFFFF',
	lblSecondary: '#e20019',
	actPrimary: '#FFFFFF',
	actSecondary: '#e20019',
	actLblPrimary: '#e20019',
	actLblSecondary: '#FFFFFF',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	vatConfig: {
		standardRate: '20',
		reducedRate: '5',
		zeroRate: '0',
	},
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
};

const brandFormSubmitValues: IBrandDetailsFormValues = {
	name: 'James Test Brand',
	vatNo: 'vatnumb3r',
	description: 'Something about pizza hut which is cool to say',
	website: 'https://www.pizzahut.co.uk',
	bgPrimary: '#44ff55',
	lblPrimary: '#445555',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	vatConfig: {
		standardRate: '20',
		reducedRate: '5',
		zeroRate: '0',
	},
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
};

const brandDetailsSubmit: IBrandDetails = {
	name: 'James Test Brand',
	vatNo: 'vatnumb3r',
	description: 'Something about pizza hut which is cool to say',
	website: 'https://www.pizzahut.co.uk',
	fontFamily: 'arial',
	fontUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/brands/fonts/Khand-SemiBold.ttf',
	h1: '22',
	h2: '18',
	h3: '16',
	text: '15',
	highlights: '14',
	smallText: '12',
	bgPrimary: '#44ff55',
	bgSecondary: '#44ee55',
	lblPrimary: '#445555',
	lblSecondary: '#442255',
	actPrimary: '#44cc55',
	actSecondary: '#44dd55',
	actLblPrimary: '#44ff44',
	actLblSecondary: '#55ff55',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	vatConfig: {
		standardRate: '20',
		reducedRate: '5',
		zeroRate: '0',
	},
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
};

const brandFormValuesNoImages: IBrandDetailsFormValues = {
	name: 'FIVE GUYS',
	vatNo: 'vatnumb3r',
	description: 'some description',
	website: 'https://www.fiveguys.co.uk',
	bgPrimary: '#e20019',
	lblPrimary: '#FFFFFF',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
};

const brandDetailsNoImages: IBrandDetails = {
	name: 'FIVE GUYS',
	vatNo: 'vatnumb3r',
	description: 'some description',
	website: 'https://www.fiveguys.co.uk',
	fontFamily: 'arial',
	fontUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/brands/fonts/Khand-SemiBold.ttf',
	h1: '40',
	h2: '24',
	h3: '18',
	text: '14',
	highlights: '10',
	smallText: '8',
	bgPrimary: '#e20019',
	bgSecondary: '#FFFFFF',
	lblPrimary: '#FFFFFF',
	lblSecondary: '#e20019',
	actPrimary: '#FFFFFF',
	actSecondary: '#e20019',
	actLblPrimary: '#e20019',
	actLblSecondary: '#FFFFFF',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
};

const brandGiftingFormValues: IBrandGiftingFormValues = {
	defaultImage: 'https://via.placeholder.com/50',
	tsAndCs: 'T&Cs to go here',
	maxAmountPence: '10000',
	validityPeriodMonths: '12',
	giftingEnabled: true,
};

const brandPriceBandFormValues: IBrandPriceBandFormValues = {
	name: 'Tier 1',
};

const brandGifting: IBrandGifting = {
	defaultImage: 'https://via.placeholder.com/50',
	tsAndCs: 'T&Cs to go here',
	maxAmountPence: 10000,
	validityPeriodMonths: 12,
	giftingEnabled: true,
};

const brandFeedbackFormValues: IBrandFeedbackFormValues = {
	feedbackEnabled: true,
	feedbackEmail: 'tested@orderpay.com',
};

/** MOCKED ENDPOINTS DATA */

const brandUpdateRequest: IBrandUpdateRequest = {
	name: 'James Test Brand',
	vatNo: 'vatnumb3r',
	description: 'Something about pizza hut which is cool to say',
	website: 'https://www.pizzahut.co.uk',
	fontFamily: '',
	fontUrl: '',
	h1: '0',
	h2: '0',
	h3: '0',
	text: '0',
	highlights: '0',
	smallText: '0',
	bgPrimary: '#44ff55',
	bgSecondary: '',
	lblPrimary: '#000',
	lblSecondary: '',
	actPrimary: '',
	actSecondary: '',
	actLblPrimary: '',
	actLblSecondary: '',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	vatConfig: {
		standardRate: 20,
		reducedRate: 5,
		zeroRate: 0,
	},
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
};

const brandUpdateResponse: IBrandUpdateResponse = {
	id: 'b17bb2ad-f52a-4b6c-9b3f-4074041d3dc2',
	accountId: 'd387dd41-0949-4d81-8aa0-8866e3403258',
	name: 'James Test Brand',
	vatNo: 'vatnumb3r',
	description: 'Something about pizza hut which is cool to say',
	website: 'https://www.pizzahut.co.uk',
	fontFamily: '',
	fontUrl: '',
	h1: 0,
	h2: 0,
	h3: 0,
	text: 0,
	highlights: 0,
	smallText: 0,
	bgPrimary: '#44ff55',
	bgSecondary: '',
	lblPrimary: '',
	lblSecondary: '',
	actPrimary: '',
	actSecondary: '',
	actLblPrimary: '',
	actLblSecondary: '',
	zonalApiKey: 'ekjfsajgahggg',
	zonalBundleIdentifier: 'osjgsogjoijds',
	zonalEstateId: 'osjgsogjoijds',
	zonalUserAgent: 'sidgjsgjapsj',
	vatConfig: {
		standardRate: 20,
		reducedRate: 5,
		zeroRate: 0,
	},
	hideMenuImages: true,
	hideVenueOpeningTimes: true,
	showDiscountMessage: false,
	campaignEventName: '',
	feedbackEnabled: true,
	feedbackEmail: 'tested@orderpay.com',
};

const brandCreateRequest: IBrandCreateRequest = {
	...brandUpdateRequest,
	accountUUID: 'd387dd41-0949-4d81-8aa0-8866e3403258',
};

const brandCreateResponse: IBrandCreateResponse = {
	...brandUpdateResponse,
};

const brandReceiptFooterConfig: IBrandReceiptFooterFormValues = {
	footerReceiptEnabled: false,
	footerReceiptMessage: '',
};

const brandReceiptFooterConfigUpdateValues: IBrandReceiptFooterFormValues = {
	footerReceiptEnabled: true,
	footerReceiptMessage: 'test',
};

/** MOCKED ENDPOINTS CONFIG */

const getAllBrands = {
	response: {
		brands: brandsList,
		pagination: mockPagination.data.pagination,
	},
};

const getBrand = {
	response: brand,
};

const createBrand = {
	request: brandCreateRequest,
	response: brandCreateResponse,
};

const updateBrand = {
	request: brandUpdateRequest,
	response: brandUpdateResponse,
};

const getBrandGifting = {
	response: brandGifting,
};

const updateBrandGifting = {
	request: brandGifting,
	response: brandGifting,
};

const getAllBrandPriceBands = {
	response: {
		data: brandPriceBandList,
	},
};

const getBrandPriceBandDetails = {
	response: brandPriceBand,
};

const createBrandPriceBand = {
	response: brandPriceBand,
};

const updateBrandPriceBand = {
	response: brandPriceBand,
};

const getReceiptFooterConfig = {
	response: brandReceiptFooterConfig,
};

const updateReceiptFooterConfig = {
	response: brandReceiptFooterConfigUpdateValues,
};

/** ENDPOINT REGEX */

// Regex for brand/uuid
export const brandEndpointRegex = new RegExp(
	/brand\/[\w]{8}(-[\w]{4}){3}-[\w]{12}$/
);

export const brandPriceBandEndpointRegex = new RegExp(
	/brand\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/price-bands$/
);

export const brandPriceBandDetailsEndpointRegex = new RegExp(
	/brand\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/price-bands\/[\w]{8}(-[\w]{4}){3}-[\w]{12}$/
);

export const brandReceiptConfigEndpointRegex = new RegExp(
	/brand\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/receipt-footer-config$/
);

/** MOCKED ENDPOINTS */

export const mockedBrandEndpoints = (mockHttpClient: MockAdapter) => {
	// Create brand
	mockHttpClient.onPost('brand').reply(200, brandCreateResponse);

	// Get all brands
	mockHttpClient
		.onGet('brand')
		.reply(200, mockBrand.endpoints.getAllBrands.response);

	// Get brand
	mockHttpClient
		.onGet(brandEndpointRegex)
		.reply(200, mockBrand.endpoints.getBrand.response);

	// Update brand
	mockHttpClient
		.onPut(brandEndpointRegex)
		.reply(200, mockBrand.endpoints.updateBrand.response);

	// Get brand price bands
	mockHttpClient
		.onGet(brandPriceBandEndpointRegex)
		.reply(200, mockBrand.endpoints.getAllBrandPriceBands.response);

	// Get brand price band details
	mockHttpClient
		.onGet(brandPriceBandDetailsEndpointRegex)
		.reply(200, mockBrand.endpoints.getBrandPriceBandDetails.response);

	// Create price band
	mockHttpClient
		.onPost(brandPriceBandEndpointRegex)
		.reply(200, mockBrand.endpoints.createBrandPriceBand.response);

	// Update price band
	mockHttpClient
		.onPut(brandPriceBandDetailsEndpointRegex)
		.reply(200, mockBrand.endpoints.updateBrandPriceBand.response);

	// Get receipt footer config
	mockHttpClient
		.onGet(brandReceiptConfigEndpointRegex)
		.reply(200, mockBrand.endpoints.getReceiptFooterConfig);

	// Update receipt footer config
	mockHttpClient
		.onPut(brandReceiptConfigEndpointRegex)
		.reply(200, brandReceiptFooterConfigUpdateValues);

	// Delete price band
	mockHttpClient.onDelete(brandPriceBandDetailsEndpointRegex).reply(204);

	// Mock delete brand response
	mockHttpClient.onDelete(brandEndpointRegex).reply(200);
};

const mockBrand = {
	data: {
		brandId,
		brand,
		brandItem,
		brandsList,
		brandFormValues,
		brandDetails,
		brandFormValuesNoImages,
		brandDetailsNoImages,
		brandFormSubmitValues,
		brandDetailsSubmit,
		brandGifting,
		brandGiftingFormValues,
		brandPriceBandList,
		brandPriceBand,
		brandPriceBandId,
		brandPriceBandFormValues,
		brandFeedbackFormValues,
		brandMarketingOptin,
		brandReceiptFooterConfig,
		brandReceiptFooterConfigUpdateValues,
	},
	endpoints: {
		getAllBrands,
		getBrand,
		createBrand,
		updateBrand,
		getBrandGifting,
		updateBrandGifting,
		getAllBrandPriceBands,
		getBrandPriceBandDetails,
		createBrandPriceBand,
		updateBrandPriceBand,
		getReceiptFooterConfig,
		updateReceiptFooterConfig,
	},
};
export default mockBrand;
