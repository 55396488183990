import { Field, Formik, Form, FormikProps } from 'formik';
import queryString from 'query-string';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

import { getVenuesList, resetVenueState } from './slices/venue.slice';
import { IVenuesListFilters } from './venue.types';
import VenuesList from './venues-list/venues-list.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Pagination from 'components/pagination/pagination.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getBrandsList } from 'modules/brand/brand.slice';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.sub-actions {
		display: flex;
		align-items: center;

		Button {
			margin: 0;
			flex-shrink: 0;
		}
	}
`;

const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
`;

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	max-width: 160px;

	input {
		height: 37px;
		line-height: 37px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;
const StyledButton = styled(Button)`
	font-size: ${fonts.sizes.small};
	padding: 11px 18px;
`;

// Interface for form values
interface ISearchFormValues {
	searchQuery: string;
	brand: string;
}

// Interface for brand change values
interface IBrandChangeValues {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

/** Renders venues page component */
const VenuesPage: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get history and location
	const history = useHistory();
	const location = useLocation();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Searchterm state
	const [searchTerm, setSearchTerm] = useState<string>(query.search || '');
	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	// Get brands from store and prepare for select
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		// Format brand as IOption
		label: value.name,
		value: value.id,
	}));

	// Get venues from state
	const { venues, pagination } = useSelector(
		(state: RootState) => state.venue
	);

	// On component load
	useEffect(() => {
		const getData = async () => {
			await dispatch(getBrandsList({ pageSize: 500 }));
		};

		getData();
		// useEffect cleanup
		return () => {
			dispatch(resetVenueState());
		};
	}, [dispatch]);

	// When page or filters are changed
	useEffect(() => {
		const getFilteredVenues = async () => {
			// Filters object
			const filters: IVenuesListFilters = {
				brandId: query.brandId,
				name: query.search,
				pageNumber: parseFloat(query.pageNumber!) || 1,
			};

			// Get filtered list of venues
			await dispatch(getVenuesList(filters));
		};
		getFilteredVenues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.search, query.pageNumber, query.brandId]);

	// Initial form values
	const initialValues: ISearchFormValues = {
		searchQuery: query.search || '',
		brand: query.brandId || '',
	};

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	// Handle search change
	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	return (
		<>
			<StyledHeader>
				<SectionHeading
					title="venues.title"
					subtitle="venues.subtitle"
				/>
				<div className="sub-actions">
					<Formik initialValues={initialValues} onSubmit={() => {}}>
						{() => {
							return (
								<StyledForm>
									<div data-testid="brand-select">
										<Field
											formElementVariant="header"
											component={Select}
											size="sm"
											value={brandOptions.find(
												(brandOpt) =>
													brandOpt.value ===
													query.brandId
											)}
											handleChangeEvent={async (
												value: IBrandChangeValues
											) =>
												// push location change with params
												history.push(
													`${
														location.pathname
													}?${queryString.stringify({
														brandId:
															value.fieldValue,
													})}`
												)}
											isSearchable={true}
											placeholder={intl.formatMessage({
												id: 'form.fields.brand.label',
											})}
											name="brand"
											isClearable={true}
											selectOptions={brandOptions}
										/>
									</div>
									<Field
										component={StyledInput}
										name="searchQuery"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={12}
										iconHeight={12}
										handleChangeEvent={(value: string) =>
											setSearchTerm(value)}
									/>
								</StyledForm>
							);
						}}
					</Formik>
					<Link to="/venues/create">
						<StyledButton
							icon="plus"
							iconWidth={11}
							iconHeight={11}
						>
							<FormattedMessage id="venues.button.create" />
						</StyledButton>
					</Link>
				</div>
			</StyledHeader>
			<VenuesList venues={venues} />
			{pagination && pagination.pageCount > 1 && (
				<Pagination
					pageNumber={
						query.pageNumber ? parseFloat(query.pageNumber) : 1
					}
					pageCount={pagination.pageCount}
					setPageNumber={handlePagination}
				/>
			)}
		</>
	);
};

export default withNav(VenuesPage);
