import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';

import { IPagination } from 'app.types';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Icon from 'components/icons/icon.component';

// Interface for component props
interface IComponentProps extends IPagination {
	setPageNumber: Function;
}

const StyledPagination = styled.div`
	margin: 35px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;

	> button {
		&:first-child {
			margin: 0 15px 0 0;
		}

		&:last-child {
			margin: 0 0 0 15px;
		}
	}
`;

const StyledButton = styled(Button)`
	padding: 10px 14px;
	min-width: 0;
	margin: 0 5px;
`;

const StyledEllipsis = styled.div`
	padding: 10px 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${fonts.sizes.large};
`;

const StyledIcon = styled(Icon)`
	transform: rotate(180deg);
`;

/** Renders Pagination component */
const Pagination: FunctionComponent<IComponentProps> = ({
	pageCount,
	pageNumber,
	setPageNumber,
}) => {
	// Function to generate page numbers
	const generatePageItems = (
		totalPages: number,
		currentPage: number,
		maxPageItems: number
	) => {
		// if total pages
		if (totalPages + 1 < maxPageItems) {
			// return array with all items, map keys to be key + 1 for correct number
			return [...new Array(totalPages).keys()].map((page) => page + 1);
		}

		// calculate items to the left
		const left = Math.max(
			1,
			Math.min(
				totalPages + 1 - maxPageItems,
				currentPage - Math.floor(maxPageItems / 2)
			)
		);

		// Variable for all items
		const items: (string | number)[] = new Array(maxPageItems);

		// loop through max pages and build array
		for (let i = 0; i < maxPageItems; i += 1) {
			items[i] = i + left;
		}

		// replace first and second item with min value and ellipsis
		if (items[0] > 1) {
			items[0] = 1;
			items[1] = 'prev-more';
		}
		// replace last and second from last item with max value and ellipsis
		if (items[items.length - 1] < totalPages) {
			items[items.length - 1] = totalPages;
			items[items.length - 2] = 'next-more';
		}
		return items;
	};

	// Creating pagination elements
	const pagination: ReactElement[] = generatePageItems(
		pageCount,
		pageNumber,
		7
	).map((item) => {
		// If number, render pagination button, else render ellipsis
		return typeof item === 'number' ? (
			<StyledButton
				key={item}
				disabled={item === pageNumber}
				onClick={() => setPageNumber(item)}
			>
				{item}
			</StyledButton>
		) : (
			<StyledEllipsis key={item}>...</StyledEllipsis>
		);
	});

	return (
		<StyledPagination data-testid="pagination">
			<StyledButton
				data-testid="back-button"
				disabled={pageNumber === 1}
				onClick={() => setPageNumber(pageNumber - 1)}
			>
				<StyledIcon
					name="chevron"
					colour={pageNumber === 1 ? 'placeholder' : 'white'}
					width={14}
					height={14}
				/>
			</StyledButton>
			{pagination}
			<StyledButton
				data-testid="next-button"
				disabled={pageNumber === pageCount}
				onClick={() => setPageNumber(pageNumber + 1)}
			>
				<Icon
					name="chevron"
					colour={pageNumber === pageCount ? 'placeholder' : 'white'}
					width={14}
					height={14}
				/>
			</StyledButton>
		</StyledPagination>
	);
};

export default Pagination;
