import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

// Interface for component props
interface IFieldErrorProps {
	ariaLabel?: string;
}

// Create styled field error component
const StyledFieldError = styled.div`
	padding: 0;
	line-height: ${fonts.line_height.large};
	font-weight: ${fonts.weights.regular};
	color: ${brand.validation_error};

	&:first-letter {
		text-transform: uppercase;
	}
`;

/** Renders field error component */
const FieldError: FunctionComponent<IFieldErrorProps> = ({
	ariaLabel,
	children,
}) => {
	return (
		<StyledFieldError aria-label={ariaLabel}>{children}</StyledFieldError>
	);
};

export default FieldError;
