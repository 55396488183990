import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IBeaconStore,
	IBeaconImportValues,
	IBeaconFilters,
} from './beacon.types';

// Create initial state for beacon slice
export const initialBeaconState: IBeaconStore = {
	eventsInProgress: 0,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
};

const BeaconSlice = createSlice({
	name: 'beacon',
	initialState: initialBeaconState,
	reducers: {
		GET_BEACONS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BEACONS_SUCCESS(state, action) {
			return {
				...state,
				activeBeacons: action.payload.data.beacons,
				pagination: action.payload.data.pagination,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BEACONS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SEARCH_BEACONS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		SEARCH_BEACONS_SUCCESS(state, action) {
			return {
				...state,
				searchedBeacons: action.payload.data.beacons,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SEARCH_BEACONS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_VENUE_BEACONS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PUT_VENUE_BEACONS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_VENUE_BEACONS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		IMPORT_BEACONS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		IMPORT_BEACONS_SUCCESS(state, action) {
			return {
				...state,
				importBeacons: action.payload.data || [],
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		IMPORT_BEACONS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_BEACONS,
	GET_BEACONS_SUCCESS,
	GET_BEACONS_FAIL,
	SEARCH_BEACONS,
	SEARCH_BEACONS_FAIL,
	SEARCH_BEACONS_SUCCESS,
	PUT_VENUE_BEACONS,
	PUT_VENUE_BEACONS_SUCCESS,
	PUT_VENUE_BEACONS_FAIL,
	IMPORT_BEACONS,
	IMPORT_BEACONS_SUCCESS,
	IMPORT_BEACONS_FAIL,
} = BeaconSlice.actions;

/** Thunk to get becaons */
export const getBeacons = (filters?: IBeaconFilters) => async (
	dispatch: Dispatch
) => {
	// get beacon list request
	const { payload } = await dispatch(
		GET_BEACONS({
			request: {
				method: 'get',
				url: 'beacons',
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** Thunk to search becaons */
export const searchBeacons = (filters?: IBeaconFilters) => async (
	dispatch: Dispatch
) => {
	// send form
	const { payload } = await dispatch(
		SEARCH_BEACONS({
			request: {
				method: 'get',
				url: 'beacons',
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** Thunk to attach beacons to venue */
export const putVenueBeacons = (venueId: string, data: string[]) => async (
	dispatch: Dispatch
) => {
	// send form
	const { payload } = await dispatch(
		PUT_VENUE_BEACONS({
			request: {
				method: 'put',
				url: `beacons/venue/${venueId}`,
				data,
			},
		})
	);

	return payload?.status === 200;
};

/** Thunk to import becaons */
export const importBeacons = (data: IBeaconImportValues) => async (
	dispatch: Dispatch
) => {
	// Create form data
	const formData = new FormData();

	// Create form data from form values
	for (const [key, value] of Object.entries(data)) {
		formData.append(key, value);
	}

	// send form
	const { payload } = await dispatch(
		IMPORT_BEACONS({
			request: {
				method: 'post',
				url: 'import/beacons/csv',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		})
	);

	return payload?.status === 200;
};

export default BeaconSlice.reducer;
