import currency from 'currency.js';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Prompt, Link } from 'react-router-dom';
import styled from 'styled-components';
import { object, boolean } from 'yup';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationNumber } from 'helpers/validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { refundOrder } from 'modules/order/order.slice';
import { IOrderRefund } from 'modules/order/order.types';

interface IComponentProps {
	orderId: string;
}

interface IFormValues {
	amountInPence: string;
	sendCustomerEmail: boolean;
}

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledInput = styled(Input)`
	input:before {
		content: '£';
		display: block;
	}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

// order refund component
const OrderRefund: FunctionComponent<IComponentProps> = ({ orderId }) => {
	// Get dispatch
	const dispatch = useReduxDispatch();

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Initial form values
	const initialValues: IFormValues = {
		amountInPence: '',
		sendCustomerEmail: true,
	};

	// Validation schema
	const validationSchema = object<IOrderRefund>().shape({
		amountInPence: genericValidationNumber({
			fieldName: 'refundAmount',
			min: 0,
			max: 999999,
		}),
		sendCustomerEmail: boolean(),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IFormValues,
		{ setSubmitting, resetForm }: FormikHelpers<IFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Convert refund amount to pence
		const refundCalc = currency(values.amountInPence).intValue;

		const response = await dispatch(
			refundOrder(orderId, {
				amountInPence: refundCalc,
				sendCustomerEmail: values.sendCustomerEmail,
			})
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'orderRefund.alerts.refundSuccess.message',
				}),
				type: 'success',
			})
		);

		resetForm();

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={StyledInput}
								name="amountInPence"
								inputSymbol="£"
								label={intl.formatMessage({
									id: 'form.fields.refundAmount.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.refundAmount.placeholder',
								})}
							/>
							<Field
								component={Switch}
								name="sendCustomerEmail"
								label={intl.formatMessage({
									id: 'form.fields.sendCustomerEmail.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.sendCustomerEmail.onText',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.sendCustomerEmail.offText',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link to="/orders">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default OrderRefund;
