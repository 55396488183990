import { FieldArray } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import ImageContainer from './image-container/image-container.component';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import Modal from 'components/modal/modal.component';

// Interface for component props
interface IComponentProps {
	isModalOpen: boolean;
	toggleModal: Function;
	toggleUpload: Function;
	deleteImage: Function;
	fieldValue?: string | string[];
	fieldName?: string;
	label?: string;
}

const StyledModalContent = styled.div`
	width: 100%;
	min-width: 740px;
	max-width: 740px;
`;

const StyledHeader = styled.header`
	margin: 0 0 25px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	h3 {
		margin: 0;
	}
`;

const StyledClose = styled.div`
	width: 20px;
	height: 20px;
	cursor: pointer;
`;

const StyledFieldWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const StyledImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	border-radius: 5px;
	z-index: 1;
`;

const StyledFieldItemWrapper = styled.div`
	width: calc(25% - 10px);
	margin: 0 5px 5px;
	height: 175px;
	background: ${brand.borders};
	border-radius: 5px;
`;

const StyledField = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
`;

const StyledUpload = styled.div`
	width: calc(25% - 10px);
	height: 175px;
	background: ${brand.neutral};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	cursor: pointer;
`;

/** Renders image management modal component */
const ImageManagementModal: FunctionComponent<IComponentProps> = ({
	fieldValue,
	fieldName,
	deleteImage,
	isModalOpen,
	toggleModal,
	toggleUpload,
	label,
}) => {
	const [imageContainerOpen, setImageContainerOpen] = useState(false);

	return (
		<Modal isOpen={isModalOpen} toggleModal={toggleModal}>
			<StyledModalContent data-testid="image-management-modal">
				<StyledHeader>
					<h3>{label}</h3>
					<StyledClose
						data-testid="image-management-modal-close"
						onClick={() => {
							toggleModal(false);
						}}
					>
						<Icon name="close" width={20} height={20} />
					</StyledClose>
				</StyledHeader>
				<StyledFieldWrapper>
					{Array.isArray(fieldValue) && fieldName ? (
						<FieldArray
							name={fieldName}
							render={(arrayHelpers) =>
								fieldValue && fieldValue.length > 0
									? fieldValue.map(
										(
											fieldItem: string,
											index: number
										) => (
											<StyledFieldItemWrapper
												key={fieldItem}
											>
												<StyledField
													data-testid="styled-image-field"
													onClick={() =>
														setImageContainerOpen(
															true
														)}
												>
													<StyledImage
														style={{
															backgroundImage: `url(${fieldItem})`,
														}}
													/>
												</StyledField>
												{imageContainerOpen && (
													<ImageContainer
														toggleContainer={
															setImageContainerOpen
														}
														deleteImage={() => {
															arrayHelpers.remove(
																index
															);
															setImageContainerOpen(
																false
															);
														}}
														imageUrl={fieldItem}
													/>
												)}
											</StyledFieldItemWrapper>
										)
									  )
									: ''}
						/>
					) : (
						!!fieldValue && (
							<StyledFieldItemWrapper>
								<StyledField
									onClick={() => setImageContainerOpen(true)}
								>
									<StyledImage
										style={{
											backgroundImage: `url(${fieldValue})`,
										}}
									/>
								</StyledField>
								{imageContainerOpen && (
									<ImageContainer
										toggleContainer={setImageContainerOpen}
										deleteImage={() => {
											deleteImage();
											setImageContainerOpen(false);
										}}
										imageUrl={fieldValue as string}
									/>
								)}
							</StyledFieldItemWrapper>
						)
					)}
					<StyledUpload
						data-testid="upload"
						onClick={() => {
							toggleUpload(true);
						}}
					>
						<Icon
							name="plus"
							colour="borders"
							height={25}
							width={25}
						/>
					</StyledUpload>
				</StyledFieldWrapper>
			</StyledModalContent>
		</Modal>
	);
};

export default ImageManagementModal;
