import { Formik, Form, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, mixed } from 'yup';

import { importVenueMenu } from '../../menu.slice';
import { IMenuImportValues } from '../../menu.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import FileUpload from 'components/form-inputs/file-upload/file-upload.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledH2 = styled.h2`
	line-height: 24px;
	margin: 0 0 25px;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledH3 = styled.h3`
	margin: 0 0 5px;
	line-height: 24px;
	font-size: ${fonts.sizes.med};
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button {
		margin-left: auto;
	}
`;

// Menu import form
const MenuImportForm = (props: { venueId: string }) => {
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);
	// Get dispatch from helper
	const dispatch = useReduxDispatch();

	// Login validation schema
	// Mixed is the yup type for file inputs
	const formValidationSchema = object<IMenuImportValues>().shape({
		menu: mixed().required(validationMessages.file('menuImport')),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IMenuImportValues,
		{ setSubmitting }: FormikHelpers<IMenuImportValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		if (values.menu) {
			const payload = await dispatch(
				importVenueMenu(props.venueId, values)
			);
			if (!payload) {
				await dispatch(
					// Add alert
					addAlert({
						title: intl.formatMessage({
							id: 'alerts.failed.title',
						}),
						message: intl.formatMessage({
							id: 'menus.import.alerts.failed.message',
						}),
						type: 'error',
					})
				);
				return;
			}

			await dispatch(
				// Add alert
				addAlert({
					title: intl.formatMessage({
						id: 'alerts.success.title',
					}),
					message: intl.formatMessage({
						id: 'menus.import.alerts.success.message',
					}),
					type: 'success',
				})
			);

			// Set form submission to true to remove routing prompt
			setFormSubmission(true);
		}

		// Set formik submission state to false
		setSubmitting(false);
	};

	// Set initial values, menu is of type file, so let's leave this as undefined
	const initialValues: IMenuImportValues = {
		menu: undefined,
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, setFieldValue, values, errors, isSubmitting }) => (
				<StyledForm encType="multipart/form-data">
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledH2>
						<FormattedMessage id="menus.import.importHeader" />
					</StyledH2>
					<StyledFieldGroup>
						<StyledColumn>
							<StyledH3>
								<FormattedMessage id="menus.import.importLabel" />
							</StyledH3>
							<FileUpload
								fileFormats=".csv"
								fieldName="menu"
								setFieldValue={setFieldValue}
								errors={errors.menu}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="menus.import.button.import" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default MenuImportForm;
