/* eslint-disable @typescript-eslint/indent */
import { Formik, Form, FieldArray, Field, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import PredictiveSearch from 'components/form-inputs/predictive-search/predictive-search.component';
import { IOption } from 'components/form-inputs/select/select.component';
import Icon from 'components/icons/icon.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	getBeacons,
	putVenueBeacons,
	searchBeacons,
} from 'modules/beacon/beacon.slice';
import { IBeacon, IVenueBeaconFormValues } from 'modules/beacon/beacon.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

interface IComponentProps {
	handleDelete: Function;
	venueId: string;
}

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledSelectWrap = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	max-width: 500px;
	margin-bottom: 15px;
`;

const StyledPredictiveSearch = styled(PredictiveSearch)`
	width: 100%;
`;

const StyledButton = styled(Button)`
	min-width: 120px;
	font-size: ${fonts.sizes.small};
`;

const StyledHeadings = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	padding: 0 14px 10px;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	user-select: none;

	&.mod-sort {
		cursor: pointer;
	}

	:focus {
		outline: none;
	}

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(25% - 110px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		padding-left: 0;
		text-align: center;
	}
`;

const StyledBeaconItem = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	background: ${brand.neutral};
	border-radius: 10px;
	padding: 14px;
	margin: 0 0 5px;

	> * {
		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4) {
			min-width: 165px;
			margin-right: 52px;
			width: calc(25% - 110px);
		}

		&:last-child {
			width: 30px;
			min-width: 30px;
			margin: 0 0 0 auto;
			padding-left: 0;
			text-align: center;
		}
	}
`;

const StyledRemove = styled.div`
	outline: none;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

const StyledEmpty = styled.div`
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
	margin: 15px 0;
`;

// Venue beacon form wrapper
const VenueBeacon: FunctionComponent<IComponentProps> = ({
	handleDelete,
	venueId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	const { activeBeacons, searchedBeacons } = useSelector(
		(state: RootState) => state.beacon
	);
	// dispatch to get venue sync details
	useEffect(() => {
		// Get current beacons for venue
		const getData = async (id: string) => {
			await dispatch(getBeacons({ venueId: id }));
		};
		venueId && getData(venueId);
	}, [dispatch, venueId]);

	// Initial form values
	const initialValues: IVenueBeaconFormValues = {
		beaconSearch: '',
		beacons:
			(venueId &&
				activeBeacons?.map((beacon) => {
					return {
						identifier: beacon.identifier,
						id: beacon.id,
						uuid: beacon.uuid,
						major: beacon.major.toString(),
						minor: beacon.minor.toString(),
					};
				})) ||
			[],
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenueBeaconFormValues,
		{ setSubmitting }: FormikHelpers<IVenueBeaconFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Map beacons to string array
		const beacons = values.beacons.map((beacon) => beacon.id);

		// Update venue beacons
		const response = dispatch(putVenueBeacons(venueId, beacons));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);
		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	const handleBeaconRemove = async (beaconRemoveFunction: Function) => {
		// Confirm user wishes to remove beacon
		const confirmRemove = await fireDialog({
			title: intl.formatMessage({
				id: 'venueBeaconForm.dialogs.confirmRemove.title',
			}),
			text: intl.formatMessage({
				id: 'venueBeaconForm.dialogs.confirmRemove.text',
			}),
			showCancelButton: true,
		});

		if (!confirmRemove.value) {
			return;
		}

		beaconRemoveFunction();
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, values, setFieldValue, isSubmitting }) => {
				// Get search beacons from store and prepare for select
				const beaconOptions: IOption[] =
					searchedBeacons
						?.filter(
							(beacon: IBeacon) =>
								!values.beacons.some(
									(valueBeacon) =>
										valueBeacon.id === beacon.id
								)
						)
						.map((value: IBeacon) => ({
							label: `${value.identifier} (${value.id})`,
							value: value.id,
						})) || [];

				// Function for react-select asnyc load options
				const beaconLoadOptions = async (inputValue: string) => {
					// Get beacon payload
					const beaconPayload = await dispatch(
						searchBeacons({ search: inputValue, pageSize: 100 })
					);

					// Return mapped venues filtered by
					return beaconPayload.beacons
						.filter(
							(beacon: IBeacon) =>
								!values.beacons.some(
									(valueBeacon) =>
										valueBeacon.id === beacon.id
								)
						)
						.map((value: IBeacon) => ({
							label: `${value.identifier} (${value.id})`,
							value: value.id,
						}));
				};

				return (
					<StyledForm noValidate>
						<Prompt when={dirty && !formSubmission} message="" />
						<FieldArray
							name="beacons"
							render={(arrayHelpers) => (
								<>
									<StyledSelectWrap>
										<Field
											isAsync
											loadOptions={beaconLoadOptions}
											size="sm"
											testId="beacon-select"
											formElementVariant="header"
											component={StyledPredictiveSearch}
											isSearchable={true}
											placeholder={intl.formatMessage({
												id: 'form.search.placeholder',
											})}
											name="beaconSearch"
											isClearable={true}
											value={
												beaconOptions.find(
													(beaconOpt) =>
														beaconOpt.value ===
														values.beaconSearch
												) || ''
											}
										/>
										<StyledButton
											onClick={() => {
												const selectedBeacon = searchedBeacons?.filter(
													(beacon) =>
														beacon.id ===
														values.beaconSearch
												)[0];

												// // if venue empty, do nothing
												if (
													!values.beaconSearch ||
													!selectedBeacon
												) {
													return;
												}

												// add venue for form array
												arrayHelpers.push({
													identifier:
														selectedBeacon.identifier,
													id: selectedBeacon.id,
													uuid: selectedBeacon.uuid,
													minor: selectedBeacon.minor,
													major: selectedBeacon.major,
												});

												// empty field
												setFieldValue(
													'beaconSearch',
													''
												);
											}}
											icon="plus"
											iconHeight={11}
											iconWidth={11}
											ariaLabel="venue-add-button"
											disabled={!values.beaconSearch}
										>
											<FormattedMessage id="venueBeacons.button.addBeacon" />
										</StyledButton>
									</StyledSelectWrap>
									{values.beacons &&
									values.beacons.length > 0 ? (
										<>
											<StyledHeadings>
												<StyledHeading>
													<FormattedMessage id="venueBeacons.list.heading.id" />
												</StyledHeading>
												<StyledHeading>
													<FormattedMessage id="venueBeacons.list.heading.uuid" />
												</StyledHeading>
												<StyledHeading>
													<FormattedMessage id="venueBeacons.list.heading.major" />
												</StyledHeading>
												<StyledHeading>
													<FormattedMessage id="venueBeacons.list.heading.minor" />
												</StyledHeading>
												<StyledHeading />
											</StyledHeadings>
											{values.beacons.map(
												(beacon, index) => (
													<StyledBeaconItem
														key={beacon.id}
													>
														<div>
															{beacon.identifier}
														</div>
														<div>{beacon.uuid}</div>
														<div>
															{beacon.major}
														</div>
														<div>
															{beacon.minor}
														</div>
														<StyledRemove
															onClick={() =>
																handleBeaconRemove(
																	() => {
																		arrayHelpers.remove(
																			index
																		);
																	}
																)}
															onKeyPress={() =>
																handleBeaconRemove(
																	() => {
																		arrayHelpers.remove(
																			index
																		);
																	}
																)}
															role="button"
															tabIndex={0}
														>
															<Icon
																name="deleteCross"
																width={15}
																height={15}
																colour="primary"
															/>
														</StyledRemove>
													</StyledBeaconItem>
												)
											)}
										</>
									) : (
										<StyledEmpty>
											<FormattedMessage id="venueBeacons.list.empty" />
										</StyledEmpty>
									)}
								</>
							)}
						/>
						<StyledActions>
							<div>
								<Link to="/venues">
									<Button variant="secondary">
										<FormattedMessage id="form.button.back" />
									</Button>
								</Link>
								{venueId && (
									<Button
										variant="secondary"
										onClick={() => {
											handleDelete(setFormSubmission);
										}}
										ariaLabel="delete-button"
									>
										<FormattedMessage id="venueForm.button.delete" />
									</Button>
								)}
							</div>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="form.button.save" />
							</Button>
						</StyledActions>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export default VenueBeacon;
