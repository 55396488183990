import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationString } from 'helpers/validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	updateVenueConfig,
	getVenueConfig,
} from 'modules/venue/slices/venue.slice';
import { IVenueConfigFormValues } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledH2 = styled.h2`
	margin-top: 40px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IVenueConfigFormProps {
	handleDelete: Function;
	venueId: string;
}

// Venue config form
const VenueConfigForm: FunctionComponent<IVenueConfigFormProps> = ({
	handleDelete,
	venueId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active venue config from store
	const activeVenueConfig: IVenueConfigFormValues | undefined = useSelector(
		(state: RootState) => state?.venue?.activeVenueConfig
	);

	useEffect(() => {
		// Get venue data by venue id
		const getVenueData = async (id: string) => {
			await dispatch(getVenueConfig(id));
		};
		getVenueData(venueId);
	}, [venueId, dispatch]);

	// Initial form values
	const initialValues: IVenueConfigFormValues = {
		campaignEventName:
			(venueId && activeVenueConfig?.campaignEventName) || '',
		socialDistancing:
			(venueId && activeVenueConfig?.socialDistancing) || false,
		askForAngelaEnabled:
			(venueId && activeVenueConfig?.askForAngelaEnabled) || false,
		numericTables: activeVenueConfig?.numericTables || false,
		currency: {
			code: (venueId && activeVenueConfig?.currency?.code) || null,
			symbol: (venueId && activeVenueConfig?.currency?.symbol) || null,
		},
		allergen: {
			url: (venueId && activeVenueConfig?.allergen?.url) || '',
		},
		alcohol: {
			url: (venueId && activeVenueConfig?.alcohol?.url) || '',
			active: (venueId && activeVenueConfig?.alcohol?.active) || false,
		},
		ageRestrictionIcons:
			(venueId && activeVenueConfig?.ageRestrictionIcons) || false,
		ageRestrictionAlert:
			(venueId && activeVenueConfig?.ageRestrictionAlert) || false,
		gpsLocation: {
			lat: (venueId && activeVenueConfig?.gpsLocation?.lat) || '',
			lng: (venueId && activeVenueConfig?.gpsLocation?.lng) || '',
			radius: (venueId && activeVenueConfig?.gpsLocation?.radius) || '',
		},
		showCalorificInformation:
			activeVenueConfig?.showCalorificInformation || false,
		showDiscountsAndPromoBanner:
			activeVenueConfig?.showDiscountsAndPromoBanner || false,
	};

	// Validation schema
	const formValidationSchema = object<IVenueConfigFormValues>().shape({
		orderAlertTime: string().when('orderAlertEnabled', {
			is: true,
			then: genericValidationString({
				fieldName: 'orderAlertTime',
			}),
		}),
		orderAlertEscalationTime: string().when('orderAlertEnabled', {
			is: true,
			then: genericValidationString({
				fieldName: 'orderAlertEscalationTime',
			}),
		}),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IVenueConfigFormValues,
		{ setSubmitting }: FormikHelpers<IVenueConfigFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Update venue
		const response = await dispatch(updateVenueConfig(venueId, values));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);
		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	const numericTableOptions: IOption[] = [
		{
			value: true,
			label: intl.formatMessage({
				id: 'form.fields.numericTables.numeric',
			}),
		},
		{
			value: false,
			label: intl.formatMessage({
				id: 'form.fields.numericTables.alphanumeric',
			}),
		},
	];

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledFieldGroup>
						<StyledColumn>
							<h2>
								<FormattedMessage id="venueForm.headings.config.currency" />
							</h2>
							<Field
								component={Select}
								name="currency.code"
								label={intl.formatMessage({
									id: 'form.fields.currency.code.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.currency.code.label',
								})}
								selectOptions={[{ value: 'GBP', label: 'GBP' }]}
								value={{ value: 'GBP', label: 'GBP' }}
							/>
							<Field
								component={Select}
								name="currency.symbol"
								label={intl.formatMessage({
									id: 'form.fields.currency.symbol.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.currency.symbol.label',
								})}
								selectOptions={[{ value: '£', label: '£' }]}
								value={{ value: '£', label: '£' }}
							/>
							<StyledH2>
								<FormattedMessage id="venueForm.headings.config.showCalorificInformation" />
							</StyledH2>
							<Field
								component={Switch}
								name="showCalorificInformation"
								label={intl.formatMessage({
									id:
										'form.fields.showCalorificInformation.switch.label',
								})}
								onText={intl.formatMessage({
									id: 'form.switch.onText.default',
								})}
								offText={intl.formatMessage({
									id: 'form.switch.offText.default',
								})}
							/>
							<StyledH2>
								<FormattedMessage id="venueForm.headings.config.showDiscountsAndPromoBanner" />
							</StyledH2>
							<Field
								component={Switch}
								name="showDiscountsAndPromoBanner"
								label={intl.formatMessage({
									id:
										'form.fields.showDiscountsAndPromoBanner.switch.label',
								})}
								onText={intl.formatMessage({
									id: 'form.switch.onText.default',
								})}
								offText={intl.formatMessage({
									id: 'form.switch.offText.default',
								})}
							/>
							<StyledH2>
								<FormattedMessage id="venueForm.headings.config.geolocation" />
							</StyledH2>
							<Field
								component={Input}
								name="gpsLocation.radius"
								label={intl.formatMessage({
									id: 'form.fields.gps.radius.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.gps.radius.label',
								})}
								type="number"
							/>
							<Field
								component={Input}
								name="gpsLocation.lat"
								label={intl.formatMessage({
									id: 'form.fields.gps.lat.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.gps.lat.label',
								})}
								type="number"
								step="any"
								min="-180"
								max="180"
							/>
							<Field
								component={Input}
								name="gpsLocation.lng"
								label={intl.formatMessage({
									id: 'form.fields.gps.lng.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.gps.lng.label',
								})}
								type="number"
								step="any"
								min="-180"
								max="180"
							/>
						</StyledColumn>
						<StyledColumn>
							<h2>
								<FormattedMessage id="venueForm.headings.config.features" />
							</h2>
							<Field
								component={Switch}
								name="socialDistancing"
								label={intl.formatMessage({
									id: 'form.fields.socialDistancing.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.socialDistancing.label',
								})}
								onText={intl.formatMessage({
									id: 'form.switch.onText.default',
								})}
								offText={intl.formatMessage({
									id: 'form.switch.offText.default',
								})}
							/>
							<Field
								component={Switch}
								name="askForAngelaEnabled"
								label={intl.formatMessage({
									id: 'form.fields.askForAngelaEnabled.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.askForAngelaEnabled.label',
								})}
								onText={intl.formatMessage({
									id: 'form.switch.onText.default',
								})}
								offText={intl.formatMessage({
									id: 'form.switch.offText.default',
								})}
								description={intl.formatMessage({
									id:
										'form.fields.askForAngelaEnabled.description',
								})}
							/>
							<Field
								component={Select}
								name="numericTables"
								label={intl.formatMessage({
									id: 'form.fields.numericTables.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.numericTables.label',
								})}
								selectOptions={numericTableOptions}
								value={numericTableOptions.find(
									(option) =>
										option.value === values.numericTables
								)}
							/>
							<Field
								component={Input}
								name="campaignEventName"
								label={intl.formatMessage({
									id: 'form.fields.campaignEventName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.campaignEventName.label',
								})}
							/>
							<StyledH2>
								<FormattedMessage id="venueForm.headings.config.allergens" />
							</StyledH2>
							<Field
								component={Input}
								name="allergen.url"
								label={intl.formatMessage({
									id: 'form.fields.allergens.url.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.allergens.url.label',
								})}
							/>
							<StyledH2>
								<FormattedMessage id="venueForm.headings.config.alcohol" />
							</StyledH2>
							<Field
								component={Switch}
								name="alcohol.active"
								label={intl.formatMessage({
									id: 'form.fields.alcohol.switch.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.alcohol.switch.label',
								})}
								onText={intl.formatMessage({
									id: 'form.switch.onText.default',
								})}
								offText={intl.formatMessage({
									id: 'form.switch.offText.default',
								})}
							/>
							<Field
								component={Input}
								name="alcohol.url"
								label={intl.formatMessage({
									id: 'form.fields.alcohol.url.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.alcohol.url.label',
								})}
							/>

							<Field
								component={Switch}
								name="ageRestrictionIcons"
								label={intl.formatMessage({
									id: 'form.fields.ageRestrictionIcons.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.alcohol.icon.label',
								})}
							/>

							<Field
								disabled={!values.ageRestrictionAlert}
								component={Switch}
								name="ageRestrictionAlert"
								label={intl.formatMessage({
									id: 'form.fields.ageRestrictionAlert.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.ageRestrictionAlert.label',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{venueId && (
								<Button
									variant="secondary"
									onClick={() => {
										handleDelete(setFormSubmission);
									}}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="venueForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default VenueConfigForm;
