export enum EntityType {
	venue = 'venue',
	brand = 'brand',
	account = 'account',
}

export enum PaymentProvider {
	stripe = 'stripe',
	ccc = 'ccc',
}

export enum PaymentProviderStatus {
	inactive,
	onboarding,
	active,
}

export interface IStripePaymentConfig {
	chargesEnabled: boolean;
	payoutsEnabled: boolean;
}

export interface ICCCPaymentConfig {
	orderpayPercentageFee: string | null;
	visa: boolean;
	amex: boolean;
	mastercard: boolean;
	applepay: boolean;
	googlepay: boolean;
	visa_applepay: boolean;
	amex_applepay: boolean;
	mastercard_applepay: boolean;
	visa_googlepay: boolean;
	amex_googlepay: boolean;
	mastercard_googlepay: boolean;
	threeDsTwoEnabled: boolean;
	giftingCCCLocationId?: string;
}

export interface IEditCCCMerchantFormValues extends ICCCPaymentConfig {
	cccLocationId: string;
}

export interface IPaymentConfig<T> {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	deletedAt?: Date;
	entityType: EntityType;
	entityId: string;
	config: T;
	active: boolean;
	provider: PaymentProvider;
	providerIdentifier: string;
	status: PaymentProviderStatus;
}

export interface IPaymentState {
	paymentConfig?: IPaymentConfig<ICCCPaymentConfig & IStripePaymentConfig>;
	eventsInProgress: number;
	stripeLoginUrl?: IStripeLoginUrl;
}

export interface ICreateMerchantFormValues {
	provider?: PaymentProvider;
	cccLocationId?: string;
}

export interface IStripeLoginUrl {
	url: string;
	expiresAt: Date;
}
