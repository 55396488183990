import { History } from 'history';
import queryString from 'query-string';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ModifierGroupDetailsForm from './modifier-group-details/modifier-group-details-form.component';
import ModifierGroupModifierItemsForm from './modifier-group-modifier-items/modifier-group-modifier-items-form.component';

import { IQueryParams } from 'app.types';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setActiveAccount } from 'modules/auth/auth.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	resetMenuState,
	getModifierGroupDetails,
} from 'modules/menu/menu.slice';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

interface IPageProps {
	history: History;
}

const ModifierGroupFormPage: FunctionComponent<IPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get location hook
	const location = useLocation();

	// Get menu id from url params
	const { modifierId, primaryTab } = useParams();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active modifier group
	const modifierGroup = useSelector(
		(state: RootState) => state.menu.activeModifierGroupDetails
	);

	useEffect(() => {
		// Get menu data by menu id
		const getData = async (id: string | undefined) => {
			// If account id in URL params has changed
			if (
				query.activeAccount &&
				query.activeAccount !== activeAccountId
			) {
				// Update active account id
				await dispatch(setActiveAccount(query.activeAccount));
			}

			// If we have an ID, get details
			!!id && dispatch(getModifierGroupDetails(id));
		};

		getData(modifierId);

		// useEffect cleanup
		return () => {
			dispatch(resetMenuState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifierId, dispatch, query.activeAccount]);

	// Set page title
	let pageTitle = modifierGroup?.title
		? modifierGroup?.title
		: 'modifierGroupForm.create.title';
	if (modifierGroup?.sku) {
		pageTitle += ` (${modifierGroup.sku})`;
	}

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!modifierGroup}
					title={pageTitle}
					subtitle={
						modifierGroup
							? 'modifierGroupForm.edit.title'
							: 'modifierGroupForm.subtitle'
					}
				/>
			</header>
			{!!modifierId && (
				<TabList
					navigateOnClick={true}
					history={history}
					parentUrl={`/menus/modifier-group/edit/${modifierId}/`}
					{...{
						activeTab,
						setActiveTab,
					}}
					tabs={[
						{
							id: 'details',
							title: intl.formatMessage({
								id: 'modifierGroupForm.tabs.details',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
						{
							id: 'items',
							title: intl.formatMessage({
								id: 'modifierGroupForm.tabs.items',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
					]}
				/>
			)}
			<FormWrapper>
				{activeTab === 'details' && (
					<ModifierGroupDetailsForm modifierId={modifierId} />
				)}
				{!!modifierId && activeTab === 'items' && (
					<ModifierGroupModifierItemsForm
						modifierGroupId={modifierId}
					/>
				)}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(ModifierGroupFormPage);
