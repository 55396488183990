import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	deleteVenue,
	getVenueGiftingConfig,
	updateVenueGiftingConfig,
} from 'modules/venue/slices/venue.slice';
import { IVenueGiftingConfigFormValues } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledP = styled.p`
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
	margin: 0 0 35px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	venueId: string;
}

// venue gifting form page
const VenueGiftingForm: FunctionComponent<IComponentProps> = ({ venueId }) => {
	// Get use hooks
	const dispatch = useReduxDispatch();
	const history = useHistory();

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active venue from store
	const { activeVenueGiftingConfig } = useSelector(
		(state: RootState) => state.venue
	);

	useEffect(() => {
		// Get venue gifting config
		const getData = async (id: string | undefined) => {
			id && (await dispatch(getVenueGiftingConfig(id)));
		};
		getData(venueId);
	}, [venueId, dispatch]);

	// Initial form values
	const initialValues: IVenueGiftingConfigFormValues = {
		defaultCardImage:
			(venueId && activeVenueGiftingConfig?.defaultCardImage) || '',
		giftingEnabled:
			(venueId && activeVenueGiftingConfig?.giftingEnabled) || false,
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenueGiftingConfigFormValues,
		{ setSubmitting }: FormikHelpers<IVenueGiftingConfigFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Disaptach update venue gifting config
		const response = await (venueId &&
			dispatch(updateVenueGiftingConfig(venueId, values)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	// Handle venue deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete venue
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'venueForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'venueForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});
		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}
		// Delete venue action
		const response = venueId && (await dispatch(deleteVenue(venueId)));

		// Return on fail
		if (!response) {
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		!!setFormSubmission && setFormSubmission(true);
		// Redirect user to venues
		history.push('/venues');
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="venueGiftingForm.headings.settings" />
					</h2>
					<StyledP>
						<FormattedMessage id="venueGiftingForm.copy.settings" />
					</StyledP>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								name="giftingEnabled"
								label={intl.formatMessage({
									id: 'form.fields.gifitingSwitch.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={ImageUpload}
								name="defaultCardImage"
								paddingTop="47%"
								label={intl.formatMessage({
									id: 'form.fields.giftCardImage.label',
								})}
								additionalData={{
									entity: 'venue',
									id: venueId,
								}}
								uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/gift-card/image`}
								isDisabled={!values.giftingEnabled}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<Link to="/venues">
							<Button variant="secondary">
								<FormattedMessage id="form.button.back" />
							</Button>
						</Link>
						{venueId && (
							<Button
								variant="secondary"
								onClick={() => handleDelete()}
								ariaLabel="delete-button"
							>
								<FormattedMessage id="venueForm.button.delete" />
							</Button>
						)}
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default VenueGiftingForm;
