/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import queryString from 'query-string';
import React, { useEffect, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { fireDialog } from '../../core/dialog/dialog.service';
import { getExportedMenu, getMenuList, resetMenuState } from '../menu.slice';
import { IMenuListFilters } from '../menu.types';
import MenuImportForm from './import-export-form/menu-import-form.component';

import { IQueryParams } from 'app.types';
import Button from 'components/button/button.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { IVenueItem } from 'modules/venue/venue.types';

// Interface for form values
interface ISearchFormValues {
	brand: string;
	venue: string;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

const StyledWrapper = styled.section`
	width: 100%;
	padding: 0 0 15px;
`;

const StyledActions = styled.div`
	padding: 0 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledForm = styled(Form)`
	display: flex;
`;

const StyledEndButtons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`;

const ImportExport: FunctionComponent = () => {
	// initialise hooks
	const dispatch = useReduxDispatch();
	const location = useLocation();
	const history = useHistory();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get active account
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Initial form values
	const initialValues: ISearchFormValues = {
		brand: query.brandId || '',
		venue: query.venueId || '',
	};

	// Get brand options from state
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get venue options from state
	const venueOption: IOption[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Handle export button form submission, collects S3 bucket URL
	// and displays a toast to download the file
	const handleDownload = async (
		values: any,
		{ setSubmitting }: FormikHelpers<any>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		if (query.venueId) {
			const fileUrl = await dispatch(getExportedMenu(query.venueId));

			if (fileUrl) {
				await fireDialog({
					title: intl.formatMessage({
						id: 'menus.export.alerts.success.message',
					}),
					html: `<a target="_blank" href="${fileUrl}">${intl.formatMessage(
						{ id: 'form.button.download' }
					)}</a>`,
					allowOutsideClick: true,
					allowEnterKey: true,
					allowEscapeKey: true,
					showConfirmButton: false,
					icon: 'success',
				});
			} else {
				await fireDialog({
					title: intl.formatMessage({
						id: 'menus.export.alerts.failed.title',
					}),
					text: intl.formatMessage({
						id: 'menus.export.alerts.failed.message',
					}),
					allowOutsideClick: true,
					allowEnterKey: true,
					allowEscapeKey: true,
					showConfirmButton: false,
					icon: 'error',
				});
			}
		}

		setSubmitting(false);
	};

	const handleBrandChangeEvent = async (value: ISelectChangeValue) =>
		filterMenus({
			brandId: value.fieldValue || '',
		});

	const handleVenueChangeEvent = async (value: ISelectChangeValue) =>
		filterMenus({
			venueId: value.fieldValue || '',
		});

	useEffect(() => {
		const getData = async () => {
			// Get filters from query params
			const menuFilters: IMenuListFilters = {
				brandId: query.brandId,
				venueId: query.venueId,
			};
			if (activeAccountId) {
				// Get menu list with filters
				await dispatch(getMenuList(menuFilters));
			} else {
				await dispatch(resetMenuState());
			}
		};

		getData();

		// useEffect cleanup
		return () => {
			dispatch(resetMenuState());
		};
	}, [dispatch, activeAccountId, query.brandId, query.venueId]);

	// Function to filter menu and change route
	const filterMenus = ({
		brandId = query.brandId,
		venueId = query.venueId,
	}) => {
		// get current filters
		const filters = { ...query };

		// add filters if available
		!!brandId && (filters.brandId = brandId);
		!!venueId && (filters.venueId = venueId);

		// remove page number on search
		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	return (
		<StyledWrapper>
			<StyledActions>
				<Formik
					initialValues={{ ...initialValues }}
					onSubmit={handleDownload}
				>
					{(values: ISearchFormValues, isSubmitting: boolean) => {
						return (
							<StyledForm>
								<Field
									size="sm"
									testId="brand-select"
									isDisabled={!activeAccountId}
									value={brandOptions.find(
										(brandOpt) =>
											brandOpt.value === query.brandId
									)}
									formElementVariant="header"
									component={Select}
									handleChangeEvent={handleBrandChangeEvent}
									isSearchable={true}
									placeholder={intl.formatMessage({
										id: 'form.fields.brandSelect.label',
									})}
									name="brand"
									isClearable={true}
									selectOptions={brandOptions}
								/>
								<Field
									size="sm"
									testId="venue-select"
									isDisabled={!activeAccountId}
									value={venueOption.find(
										(venue) => venue.value === query.venueId
									)}
									formElementVariant="header"
									component={Select}
									handleChangeEvent={handleVenueChangeEvent}
									isSearchable={true}
									placeholder={intl.formatMessage({
										id: 'form.fields.venueSelect.label',
									})}
									name="venue"
									isClearable={true}
									selectOptions={venueOption}
								/>
								<StyledEndButtons>
									{activeAccountId && query.venueId && (
										<Button
											icon="data"
											iconWidth={11}
											iconHeight={11}
											type="submit"
											disabled={isSubmitting}
											ariaLabel="submit-button"
										>
											<FormattedMessage id="menus.import.button.export" />
										</Button>
									)}
									;
								</StyledEndButtons>
							</StyledForm>
						);
					}}
				</Formik>
			</StyledActions>
			{activeAccountId && query.venueId ? (
				<MenuImportForm venueId={query.venueId} />
			) : (
				<FormattedMessage id="menus.import.noVenue" />
			)}
		</StyledWrapper>
	);
};

export default ImportExport;
