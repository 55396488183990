import styled from 'styled-components';

import fonts from 'assets/styles/variables/fonts';

export const StyledLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 5px;

	> div {
		margin-left: 10px;
	}
`;

export const StyledLabel = styled.label`
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;
