import { SweetAlertOptions } from 'sweetalert2';

/** Default dialog configuration */
const dialogConfig: SweetAlertOptions = {
	reverseButtons: false,
	customClass: {
		container: 'swal-container',
		popup: 'swal-popup',
		header: 'swal-header',
		title: 'swal-title',
		closeButton: 'swal-button-close',
		icon: 'swal-icon',
		image: 'swal-image',
		content: 'swal-content',
		input: 'swal-input',
		actions: 'swal-action-container',
		confirmButton: 'swal-button-confirm',
		cancelButton: 'swal-button-cancel',
		footer: 'swal-footer',
	},
};

export default dialogConfig;
