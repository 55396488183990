import { History } from 'history';
import queryString from 'query-string';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ProductDetailsForm from './product-details/product-details-form.component';
import ProductModifierGroupsForm from './product-modifier-groups/product-modifier-groups-form.component';
import ProductNutritionForm from './product-nutrition/product-nutrition-form.component';
import ProductPriceBandsList from './product-price-bands/product-price-bands-list.component';

import { IQueryParams } from 'app.types';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { hasPriceBands } from 'helpers/price-band.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setActiveAccount } from 'modules/auth/auth.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	resetMenuState,
	getProductDetails,
	getMenuPreferences,
} from 'modules/menu/menu.slice';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

interface IPageProps {
	history: History;
}

const ProductFormPage: FunctionComponent<IPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get location hook
	const location = useLocation();

	// Get product id from url params
	const { productId, primaryTab } = useParams<{
		productId: string;
		primaryTab: string;
	}>();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active product
	const product = useSelector(
		(state: RootState) => state.menu.activeProductDetails
	);

	useEffect(() => {
		// Get product data by product id
		const getData = async (id: string | undefined) => {
			// If account id in URL params has changed
			if (
				query.activeAccount &&
				query.activeAccount !== activeAccountId
			) {
				// Update active account id
				await dispatch(setActiveAccount(query.activeAccount));
			}

			// If we have an ID, get details
			!!id && dispatch(getProductDetails(id));
			await dispatch(getMenuPreferences());
		};

		getData(productId);

		// useEffect cleanup
		return () => {
			dispatch(resetMenuState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productId, dispatch, query.activeAccount]);

	// Set page title
	let pageTitle = product?.name ? product?.name : 'productForm.create.title';
	if (product?.sku) {
		pageTitle += ` (${product.sku})`;
	}

	const priceBandTab = hasPriceBands(product)
		? [
			{
				id: 'price-bands',
				title: intl.formatMessage({
					id: 'productForm.tabs.priceBands',
				}),
				queryString: `activeAccount=${activeAccountId}`,
			},
		  ]
		: [];

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!product}
					title={pageTitle}
					subtitle={
						product
							? 'productForm.edit.title'
							: 'productForm.subtitle'
					}
				/>
			</header>
			{!!productId && (
				<TabList
					navigateOnClick={true}
					history={history}
					parentUrl={`/menus/product/edit/${productId}/`}
					{...{
						activeTab,
						setActiveTab,
					}}
					tabs={[
						{
							id: 'details',
							title: intl.formatMessage({
								id: 'productForm.tabs.details',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
						// Only show the price band tab IF the active product has
						// price bands defined.
						...priceBandTab,
						{
							id: 'nutrition',
							title: intl.formatMessage({
								id: 'productForm.tabs.nutrition',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
						{
							id: 'modifier-groups',
							title: intl.formatMessage({
								id: 'productForm.tabs.modifierGroups',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
					]}
				/>
			)}
			<FormWrapper>
				{activeTab === 'details' && (
					<ProductDetailsForm productId={productId} />
				)}
				{activeTab === 'price-bands' && (
					<ProductPriceBandsList productId={productId} />
				)}
				{activeTab === 'nutrition' && productId && (
					<ProductNutritionForm productId={productId} />
				)}
				{activeTab === 'modifier-groups' && productId && (
					<ProductModifierGroupsForm productId={productId} />
				)}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(ProductFormPage);
