import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';

const StyledWrapper = styled.div`
	position: relative;
	cursor: pointer;
`;

/**
 * left | right
 * before | after
 */

const StyledToolTip = styled.div`
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 5px;
	width: 205px;
	padding: 15px;
	background: ${brand.tooltipBackground};
	&::after {
		content: '';
		display: block;
		height: 12px;
		width: 12px;
		background-color: inherit;
		border: inherit;
		position: absolute;
		left: -6px;
		top: calc(50% - 6px);
		clip-path: polygon(20% 0%, 100% 100%, 20% 100%);
		transform: rotate(45deg);
		border-radius: 0 0 0 2px;
	}
	h5 {
		margin: 0 0 5px;
		color: ${brand.white};
		font-size: ${fonts.sizes.small};
		font-weight: ${fonts.weights.semibold};
	}
	p {
		margin: 0 0 4px;
		color: ${brand.white};
		font-size: ${fonts.sizes.small};
		font-weight: ${fonts.weights.regular};
		line-height: ${fonts.sizes.standard};
	}
`;

const StyledInfoIcon = styled(Icon)<{ visible: boolean }>`
	cursor: pointer;
	svg {
		color: ${brand.borders};
	}
	&:hover svg {
		color: ${brand.primary};
	}
	${(props) => props.visible && `svg { color: ${brand.primary};}`}
`;

interface IComponentProps {
	title?: string;
	description?: string;
}

const Tooltip: FunctionComponent<IComponentProps> = ({
	title,
	description,
}) => {
	const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false);

	/* If open, listen for clicks or escape key press and close the tooltip */
	useEffect(() => {
		if (!isToolTipVisible) {
			return;
		}
		const closeOnClick = (): void => {
			setIsToolTipVisible(false);
		};
		window.addEventListener('click', closeOnClick);

		const closeOnEsc = (event: KeyboardEvent): void => {
			if (event.key === 'Escape') {
				setIsToolTipVisible(false);
			}
		};
		window.addEventListener('keydown', closeOnEsc);

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener('click', closeOnClick);
			window.removeEventListener('keydown', closeOnEsc);
		};
	}, [isToolTipVisible]);

	return (
		<StyledWrapper
			data-testid="tooltip"
			onClick={() => setIsToolTipVisible(!isToolTipVisible)}
		>
			<StyledInfoIcon
				name="info"
				width={18}
				height={18}
				visible={isToolTipVisible}
			/>
			{isToolTipVisible && (
				<StyledToolTip data-testid="tooltip-message">
					{title && <h5>{title}</h5>}
					{description && <p>{description}</p>}
				</StyledToolTip>
			)}
		</StyledWrapper>
	);
};

export default Tooltip;
