import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import { formatWithPriceBands } from 'helpers/price-band.helper';
import { IModifierItem } from 'modules/menu/menu.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px 0 0;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:first-child {
		min-width: 56px;
		width: 56px;
		padding: 0;
		margin: 0;

		img {
			margin: 0;
			width: 100%;
			height: auto;
		}
	}

	&:nth-child(2) {
		margin-left: 20px;
	}

	&:nth-child(4) {
		margin-left: -20px;
	}

	&:nth-child(1n + 2) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(25% - 110px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		display: flex;
		justify-content: center;
	}
`;

// Props for component
interface IComponentProps {
	item: IModifierItem;
	activeAccountId: string;
}
// modifier items list item component
const ModifierItemListItem: FunctionComponent<IComponentProps> = ({
	item,
	activeAccountId,
}) => {
	return (
		<StyledListItem key={item.id} aria-label="modifier-items-item">
			<StyledColumn>
				{!!item.imageUrl && (
					<img
						src={item.imageUrl!}
						alt={`${item.title} modifier item`}
					/>
				)}
			</StyledColumn>
			<StyledColumn>{item.title}</StyledColumn>
			<StyledColumn>{item.sku}</StyledColumn>
			<StyledColumn>{formatWithPriceBands(item)}</StyledColumn>
			<StyledColumn>
				<Link
					to={`/menus/modifier-item/edit/${item.id}/details?activeAccount=${activeAccountId}`}
				>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledListItem>
	);
};

export default ModifierItemListItem;
