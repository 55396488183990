import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IPaymentState,
	EntityType,
	ICCCPaymentConfig,
	PaymentProvider,
} from 'modules/payment/payment.types';

export const initialPaymentState: IPaymentState = {
	paymentConfig: undefined,
	eventsInProgress: 0,
};

const paymentSlice = createSlice({
	name: 'payment',
	initialState: initialPaymentState,
	reducers: {
		GET_PAYMENT_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PAYMENT_CONFIG_FAIL(state) {
			return {
				...state,
				paymentConfig: undefined,
				stripeLoginUrl: undefined,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PAYMENT_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				paymentConfig: action.payload.data,
				stripeLoginUrl: action.payload.data.loginLink,
			};
		},
		GENERATE_STRIPE_LINK(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GENERATE_STRIPE_LINK_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GENERATE_STRIPE_LINK_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				stripeLoginUrl: {
					url: action.payload.data.loginLink,
					expiresAt: action.payload.data.expiresAt,
				},
			};
		},
		UPDATE_PAYMENT_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_PAYMENT_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				paymentConfig: action.payload.data,
			};
		},
		UPDATE_PAYMENT_CONFIG_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PAYMENT_CONFIG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_PAYMENT_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				paymentConfig: action.payload.data,
				stripeLoginUrl: {
					url: action.payload.data.loginLink.url,
					expiresAt: action.payload.data.loginLink.expiresAt,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PAYMENT_CONFIG_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

export const {
	GET_PAYMENT_CONFIG,
	GET_PAYMENT_CONFIG_SUCCESS,
	GET_PAYMENT_CONFIG_FAIL,
	GENERATE_STRIPE_LINK,
	GENERATE_STRIPE_LINK_FAIL,
	GENERATE_STRIPE_LINK_SUCCESS,
	UPDATE_PAYMENT_CONFIG,
	UPDATE_PAYMENT_CONFIG_FAIL,
	UPDATE_PAYMENT_CONFIG_SUCCESS,
	CREATE_PAYMENT_CONFIG,
	CREATE_PAYMENT_CONFIG_FAIL,
	CREATE_PAYMENT_CONFIG_SUCCESS,
} = paymentSlice.actions;

export const getPaymentConfigForEntity = (
	entityType: EntityType,
	entityId: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GET_PAYMENT_CONFIG({
			request: {
				method: 'get',
				url: `/payments/${entityType}/${entityId}`,
			},
		})
	);

	return payload?.data;
};

export const generateStripeLoginLink = (
	entityType: EntityType,
	entityId: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GENERATE_STRIPE_LINK({
			request: {
				method: 'post',
				url: `/payments/stripe/${entityType}/${entityId}/edit-link`,
			},
		})
	);

	return payload?.data;
};

export const updateCCCMerchant = (
	entityType: EntityType,
	entityId: string,
	cccLocationId: string,
	config: ICCCPaymentConfig
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		UPDATE_PAYMENT_CONFIG({
			request: {
				method: 'put',
				url: `/payments/ccc/${entityType}/${entityId}`,
				data: {
					cccLocationId,
					config,
				},
			},
		})
	);

	return payload?.data;
};

export const createMerchant = (
	entityType: EntityType,
	entityId: string,
	provider: PaymentProvider,
	cccLocationId?: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		CREATE_PAYMENT_CONFIG({
			request: {
				method: 'post',
				url: `/payments/provider/${provider}/${entityType}/${entityId}`,
				data: {
					cccLocationId,
				},
			},
		})
	);

	return payload?.data;
};

export default paymentSlice.reducer;
