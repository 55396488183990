import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { IOrder } from 'modules/order/order.types';

// Props for component
interface IComponentprops {
	item: IOrder;
}

const StyledOrderItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 0 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	width: 100%;
	max-width: 110px;
	min-height: 56px;
	padding: 15px 0;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&.mod-capitalise {
		text-transform: capitalize;
	}

	&:nth-of-type(4),
	&:nth-of-type(5) {
		max-width: 270px;
	}

	&:nth-of-type(6),
	&:nth-of-type(7) {
		max-width: 130px;
	}

	&:last-child {
		min-width: 40px;
		max-width: 40px;
		justify-content: flex-end;
		padding: 0 15px 0 0;
	}
`;

// Orders list item component
const OrdersListItem: FunctionComponent<IComponentprops> = ({ item }) => {
	return (
		<StyledOrderItem key={item.id} aria-label="order-item">
			<StyledColumn>{item.id.split('-')[0]}</StyledColumn>
			<StyledColumn>{item.orderNumber}</StyledColumn>
			<StyledColumn>
				{intl.formatDate(item.orderDate, {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				})}{' '}
				{intl.formatTime(item.orderDate, {
					hour: 'numeric',
					minute: 'numeric',
					second: 'numeric',
					hour12: false,
				})}
			</StyledColumn>
			<StyledColumn data-private>{item.customerName}</StyledColumn>
			<StyledColumn data-private>{item.customerEmail}</StyledColumn>
			<StyledColumn className="mod-capitalise">
				{item.orderStatus.replace(/([A-Z])/g, ' $1').trim()}
			</StyledColumn>
			<StyledColumn>
				{intl.formatNumber(item.price.units / 100, {
					style: 'currency',
					currency: item.price.currencyCode,
				})}
			</StyledColumn>
			<StyledColumn>
				<Link to={`/orders/manageOrder/${item.id}/details`}>
					<Icon name="chevron" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledOrderItem>
	);
};

export default OrdersListItem;
