import { History } from 'history';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import AccountDetailsForm from 'modules/account/account-form/account-details-form/account-details-form.component';
import {
	getAccount,
	setActiveAccount,
	resetAccountState,
} from 'modules/account/account.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import PaymentConfigForm from 'modules/payment/payment-config/payment-config.component';
import { EntityType } from 'modules/payment/payment.types';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

// Interface for component props
interface IComponentProps {
	history: History;
}

const AccountFormPage: FunctionComponent<IComponentProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get account id and active tab from url params
	const { accountId, primaryTab } = useParams();

	// Get active account name
	const accountName =
		useSelector((state: RootState) => state.account.activeAccount?.name) ||
		intl.formatMessage({ id: 'accounts.title' });

	useEffect(() => {
		// Get account data by account id
		const getAccountData = async (id: string | undefined) => {
			// getAccount, or unset active account
			await (id
				? dispatch(getAccount(id))
				: dispatch(setActiveAccount(undefined)));
		};
		getAccountData(accountId);

		// Clean up use effect
		return () => {
			dispatch(resetAccountState());
		};
	}, [accountId, dispatch]);

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!accountId}
					title={accountId ? accountName : 'accountForm.create.title'}
					subtitle={
						accountId
							? 'accountForm.edit.title'
							: 'accounts.subtitle'
					}
				/>
			</header>
			{accountId ? (
				<>
					<TabList
						{...{ activeTab, setActiveTab }}
						navigateOnClick={true}
						history={history}
						parentUrl={`/accounts/edit/${accountId}/`}
						tabs={[
							{
								id: 'details',
								title: intl.formatMessage({
									id: 'accountForm.tabs.details.primary',
								}),
							},
							{
								id: 'payment-config',
								title: intl.formatMessage({
									id: 'accountForm.tabs.payment.primary',
								}),
							},
						]}
					/>
					<FormWrapper>
						{activeTab === 'details' && (
							<AccountDetailsForm {...{ accountId, history }} />
						)}
						{activeTab === 'payment-config' && (
							<PaymentConfigForm
								entityType={EntityType.account}
								entityId={accountId}
							/>
						)}
					</FormWrapper>
				</>
			) : (
				<FormWrapper>
					<AccountDetailsForm {...{ history }} />
				</FormWrapper>
			)}
		</StyledContentWrapper>
	);
};

export default withNav(AccountFormPage);
