import { Field, Formik, Form, FormikProps } from 'formik';
import queryString from 'query-string';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getVenuesList } from '../venue/slices/venue.slice';
import CategoryList from './category/category-list/category-list.component';
import CheckoutUpsellConfigForm from './checkout-upsell/checkout-upsell-form.component';
import ImportExport from './import-export/import-export.page';
import { resetMenuState } from './menu.slice';
import MenuList from './menu/menu-list/menu-list.component';
import ModifierGroupList from './modifier-group/modifer-group-list/modifier-group-list.component';
import ModifierItemList from './modifier-item/modifier-item-list/modifier-item-list.component';
import ProductList from './product/product-list/product-list.component';
import SuggestedPairingList from './suggested-pairing/suggested-pairing-list/suggested-pairing-list.component';

import { IQueryParams } from 'app.types';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getAccountsList } from 'modules/account/account.slice';
import { IAccount } from 'modules/account/account.types';
import { setActiveAccount } from 'modules/auth/auth.slice';
import { getBrandsList } from 'modules/brand/brand.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

// Interface for form values
interface ISearchFormValues {
	brand: string;
	venue: string;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.sub-actions {
		display: flex;
		width: 50%;
		align-items: flex-end;
		Button {
			margin: 0;
			flex-shrink: 0;
		}
	}
`;

const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
`;

const StyledSelect = styled(Select)`
	width: 100%;
	max-width: 380px;
	align-items: center;
	justify-content: flex-end;

	label {
		margin: 0 10px 0 0;
	}

	> div {
		max-width: 190px;
	}
`;

/** Renders menus page component */
const MenusPage: FunctionComponent = () => {
	// define variables
	const dispatch = useReduxDispatch();
	// Get history and location
	const history = useHistory();
	const location = useLocation();
	// Get active tabs from url params
	const { primaryTab } = useParams();
	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'menus');

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get account options from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Initial form values
	const initialValues: ISearchFormValues = {
		brand: query.brandId || '',
		venue: query.venueId || '',
	};

	// Get account options from state
	const accountOptions: IOption[] = useSelector(
		(state: RootState) => state.account.accounts
	).map((value: IAccount) => ({
		label: value.name,
		value: value.id,
	}));

	// Get initial accounts list and menu list
	useEffect(() => {
		const getData = async () => {
			// If account id in URL params has changed
			if (
				query.activeAccount &&
				query.activeAccount !== activeAccountId
			) {
				// Update active account id
				await dispatch(setActiveAccount(query.activeAccount));
			}

			// Get accounts list
			await dispatch(getAccountsList());
			// Only get data if we have active account
			if (activeAccountId) {
				// Get brand list with account filter
				await dispatch(
					getBrandsList({
						accountUUID: activeAccountId,
						pageSize: 200,
					})
				);
				// Get venue list with venue filter
				await dispatch(
					getVenuesList({
						accountId: activeAccountId,
						brandId: query.brandId,
					})
				);
			}
		};

		getData();

		// useEffect cleanup
		return () => {
			dispatch(resetMenuState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, location.search, activeAccountId, query.activeAccount]);

	// When account dropdown changes
	const handleAccountChange = (activeAccount: string) => {
		// Change route
		history.push(
			`${location.pathname}?${queryString.stringify({
				activeAccount,
			})}`
		);
	};

	return (
		<>
			<StyledHeader>
				<SectionHeading title="menus.title" subtitle="menus.subtitle" />
				<div className="sub-actions">
					<Formik initialValues={initialValues} onSubmit={() => {}}>
						{() => {
							return (
								<StyledForm>
									<Field
										size="sm"
										testId="account-select"
										formElementVariant="header"
										label={intl.formatMessage({
											id:
												'form.fields.activeAccount.label',
										})}
										component={StyledSelect}
										value={accountOptions.find(
											(account) =>
												account.value ===
												activeAccountId
										)}
										handleChangeEvent={async (
											value: ISelectChangeValue
										) =>
											handleAccountChange(
												value.fieldValue
											)}
										isSearchable={true}
										placeholder={intl.formatMessage({
											id:
												'form.fields.accountSelect.label',
										})}
										name="account"
										isClearable={true}
										selectOptions={accountOptions}
									/>
								</StyledForm>
							);
						}}
					</Formik>
				</div>
			</StyledHeader>
			<TabList
				{...{ activeTab, setActiveTab }}
				navigateOnClick={true}
				history={history}
				parentUrl="/menus/"
				tabs={[
					{
						id: 'menus',
						title: 'Menus',
						queryString: `activeAccount=${activeAccountId}`,
					},
					{
						id: 'categories',
						title: 'Categories',
						queryString: `activeAccount=${activeAccountId}`,
					},
					{
						id: 'products',
						title: 'Products',
						queryString: `activeAccount=${activeAccountId}`,
					},
					{
						id: 'modifier-groups',
						title: 'Modifier Groups',
						queryString: `activeAccount=${activeAccountId}`,
					},
					{
						id: 'modifier-items',
						title: 'Modifier Items',
						queryString: `activeAccount=${activeAccountId}`,
					},
					{
						id: 'suggested-pairing',
						title: 'Suggested Pairing',
						queryString: `activeAccount=${activeAccountId}`,
					},
					{
						id: 'checkout-upsell',
						title: 'Checkout Upsell',
						queryString: `activeAccount=${activeAccountId}`,
					},
					{
						id: 'import-export',
						title: 'Import / Export',
						queryString: `activeAccount=${activeAccountId}`,
					},
				]}
			/>
			<FormWrapper>
				{activeTab === 'menus' && <MenuList />}
				{activeTab === 'categories' && <CategoryList />}
				{activeTab === 'products' && <ProductList />}
				{activeTab === 'modifier-groups' && <ModifierGroupList />}
				{activeTab === 'modifier-items' && <ModifierItemList />}
				{activeTab === 'suggested-pairing' && <SuggestedPairingList />}
				{activeTab === 'checkout-upsell' && (
					<CheckoutUpsellConfigForm />
				)}
				{activeTab === 'import-export' && <ImportExport />}
			</FormWrapper>
		</>
	);
};

export default withNav(MenusPage);
