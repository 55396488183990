import { Channel } from 'pusher-js';
import { useContext, useEffect, useState } from 'react';

import { IVenueMenuValidationUpdateEvent } from '../venue-menu-validation.types';

import { PusherContext } from 'modules/core/pusher/pusher.context';

export default function useValidationFlowPusherEvent(venueId: string) {
	const { pusher } = useContext(PusherContext);

	const [channel, setChannel] = useState<Channel>();
	const [validationPusherEvent, setValidationPusherEvent] = useState<
		IVenueMenuValidationUpdateEvent
	>();

	useEffect(() => {
		if (!pusher) return () => {};
		const validationChannelSub = pusher.subscribe('Menu-Validation');
		setChannel(validationChannelSub);
		return () => {
			validationChannelSub.disconnect();
		};
	}, [setChannel, pusher]);

	useEffect(() => {
		if (!channel || !venueId) return () => {};
		channel.bind(
			'menu-validation-update',
			async (event: IVenueMenuValidationUpdateEvent) => {
				if (event && event.data.venueId === venueId) {
					setValidationPusherEvent(event);
				}
			}
		);
		return () => {
			channel.unbind_all();
		};
	}, [venueId, channel]);

	return validationPusherEvent;
}
