import get from 'lodash.get';

import { IPagination } from 'app.types';
/** MAIN TYPES */

export interface IBrandItem {
	id: string;
	accountId: string;
	name: string;
	logoUrl: string;
}

export interface IBrand {
	id: string;
	name: string;
	vatNo: string | null;
	description: string;
	website: string;
	logoUrl: string | null;
	coverUrls: string[] | null;
	accountId: string;
	createdAt: string;
	deletedAt: string | null;
	showDiscountMessage: boolean;
	campaignEventName: string;
	font: {
		id: string;
		fontFamily: string;
		url: string;
		h1: number;
		h2: number;
		h3: number;
		text: number;
		highlights: number;
		smallText: number;
		brandId: string;
	};
	colour: {
		id: string;
		bgPrimary: string;
		bgSecondary: string;
		lblPrimary: string;
		lblSecondary: string;
		actPrimary: string;
		actSecondary: string;
		actLblPrimary: string;
		actLblSecondary: string;
		brandId: string;
	};
	zonalApiKey?: string;
	zonalBundleIdentifier?: string;
	zonalEstateId?: string;
	zonalUserAgent?: string;
	vatConfig?: {
		standardRate?: number;
		reducedRate?: number;
		zeroRate?: number;
	};
	hideMenuImages: boolean;
	hideVenueOpeningTimes: boolean;
	account?: {
		id: string;
		name: string;
	};
	feedbackEnabled?: boolean;
	feedbackEmail?: string;
}

export interface IBrandGifting {
	defaultImage: string;
	tsAndCs: string;
	maxAmountPence: number;
	validityPeriodMonths: number;
	giftingEnabled: boolean;
}

enum ServiceType {
	clickAndCollect = 0,
	tableService = 1,
	delivery = 2,
	selfService = 3,
	customService = 4,
}

export interface IBrandPriceBandAvailability {
	dayOfWeek: number;
	startHour: number;
	endHour: number;
}

export interface IBrandSubPriceBand {
	id: string;
	name: string;
	serviceType: ServiceType;
	availability?: IBrandPriceBandAvailability[];
}

export interface IBrandPriceBand {
	id: string;
	name: string;
	subPriceBands: IBrandSubPriceBand[];
}

export interface IMarketingOptinActeolConfig {
	instanceUrl?: string;
	username?: string;
	password?: string;
}

export interface IMarketingOptInAirshipConfigFormValues {
	accountId: string | number;
	sourceId?: string;
	sourceName?: string;
	brandCustomerGroups?: string;
	accountAccessToken?: string;
	groupCustomersByOrderFlow?: boolean;
}
export interface IMarketingOptInAirshipConfig {
	accountId: string | number;
	sourceId?: string;
	sourceName?: string;
	brandCustomerGroups?: string;
	accountAccessToken?: string;
	groupCustomersByOrderFlow?: boolean;
}

export interface IAirshipVenue {
	id: string; // OrderPay id
	name: string; // OrderPay venue name
	airshipUnitId?: number; // Airship unit id
	groups?: string;
}

export interface IAirshipUnit {
	name: string;
	id: number;
}

export interface IAirshipUnitsMapping {
	venues: IAirshipVenue[];
	units: IAirshipUnit[];
}

export interface IMarketingOptinEmailConfig {
	exportMethod?: string;
	exportFrequency?: string;
	exportEmail?: string;
}
export interface IBrandMarketingOptin {
	id: string;
	target: {
		entityType: string;
		entityId: string;
	};
	isEnabled: boolean;
	privacyUrl: string;
	integrationType: string | null;
	integrationConfig?:
	| IMarketingOptinActeolConfig
	| IMarketingOptinEmailConfig
	| IMarketingOptInAirshipConfig;
	airshipVenues?: IAirshipVenue[];
	airshipUnits?: IAirshipUnit[];
}

export interface IBrandMarketingOptinFormValues {
	isEnabled: boolean;
	privacyUrl: string;
	integrationType: string | null;
	integrationConfig?: IMarketingOptinActeolConfig &
	IMarketingOptinEmailConfig &
	IMarketingOptInAirshipConfigFormValues;
}

export interface IBrandReceiptFooterFormValues {
	footerReceiptEnabled: boolean;
	footerReceiptMessage: string;
}

/** STATE TYPES */

export interface IBrandState {
	activeBrand?: IBrand;
	activeBrandGifting?: IBrandGifting;
	activePriceBandDetails?: IBrandPriceBand;
	activeMarketingOptin?: IBrandMarketingOptin;
	activeReceiptFooterConfig?: IBrandReceiptFooterFormValues;
	brands: IBrandItem[];
	priceBands: IBrandPriceBand[];
	eventsInProgress: number;
	pagination: IPagination;
}

/** FORM TYPES */

// brand object sent to backend to update brand details
export interface IBrandDetails {
	accountUUID?: string;
	name: string;
	vatNo: string;
	description: string;
	website: string;
	fontFamily: string;
	fontUrl: string;
	h1: string;
	h2: string;
	h3: string;
	text: string;
	highlights: string;
	showDiscountMessage: boolean;
	campaignEventName: string;
	smallText: string;
	bgPrimary: string;
	bgSecondary: string;
	lblPrimary: string;
	lblSecondary: string;
	actPrimary: string;
	actSecondary: string;
	actLblPrimary: string;
	actLblSecondary: string;
	logoUrl?: string;
	coverUrls?: string[];
	zonalApiKey?: string;
	zonalBundleIdentifier?: string;
	zonalEstateId?: string;
	zonalUserAgent?: string;
	vatConfig?: {
		standardRate?: string;
		reducedRate?: string;
		zeroRate?: string;
	};
	hideMenuImages: boolean;
	hideVenueOpeningTimes: boolean;
	account?: {
		id: string;
		name: string;
	};
	feedbackEnabled?: boolean;
	feedbackEmail?: string;
}

// bran details form values rendered on page
export interface IBrandDetailsFormValues {
	accountUUID?: string;
	name: string;
	vatNo: string;
	description: string;
	website: string;
	showDiscountMessage: boolean;
	campaignEventName: string;
	bgPrimary: string;
	lblPrimary: string;
	logoUrl?: string;
	coverUrls?: string[];
	zonalApiKey?: string;
	zonalBundleIdentifier?: string;
	zonalEstateId?: string;
	zonalUserAgent?: string;
	vatConfig?: {
		standardRate?: string;
		reducedRate?: string;
		zeroRate?: string;
	};
	hideMenuImages: boolean;
	hideVenueOpeningTimes: boolean;
	account?: {
		id: string;
		name: string;
	};
	feedbackEnabled?: boolean;
	feedbackEmail?: string;
	isBrandCreate?: boolean;
}

export interface IBrandGiftingFormValues {
	defaultImage: string;
	tsAndCs: string;
	maxAmountPence: string;
	validityPeriodMonths: string;
	giftingEnabled: boolean;
}

export interface IBrandPriceBandFormValues {
	name: string;
}

export interface IBrandFeedbackFormValues {
	feedbackEnabled: boolean;
	feedbackEmail: string;
}

/** ENDPOINT TYPES */

export interface IBrandListFilters {
	accountUUID?: string | string[];
	search?: string;
	ids?: string[];
	extended?: boolean;
	pageNumber?: number;
	pageSize?: number;
	sort?: string;
	sortOrder?: string;
}

export interface IBrandUpdateRequest {
	name: string;
	vatNo: string;
	description: string;
	website: string;
	fontFamily: string;
	fontUrl: string;
	h1: string;
	h2: string;
	h3: string;
	text: string;
	highlights: string;
	smallText: string;
	bgPrimary: string;
	bgSecondary: string;
	lblPrimary: string;
	lblSecondary: string;
	actPrimary: string;
	actSecondary: string;
	actLblPrimary: string;
	actLblSecondary: string;
	logoUrl?: string;
	coverUrls?: string[];
	zonalApiKey?: string;
	zonalBundleIdentifier?: string;
	zonalEstateId?: string;
	zonalUserAgent?: string;
	vatConfig?: {
		standardRate?: number;
		reducedRate?: number;
		zeroRate?: number;
	};
	hideMenuImages: boolean;
	hideVenueOpeningTimes: boolean;
	showDiscountMessage: boolean;
	campaignEventName: string;
	feedbackEmail?: string;
	feedbackEnabled?: boolean;
}

export interface IBrandUpdateResponse {
	id: string;
	accountId: string;
	name: string;
	vatNo: string | null;
	description: string;
	website: string;
	fontFamily: string;
	fontUrl: string;
	h1: number;
	h2: number;
	h3: number;
	text: number;
	highlights: number;
	smallText: number;
	bgPrimary: string;
	bgSecondary: string;
	lblPrimary: string;
	lblSecondary: string;
	actPrimary: string;
	actSecondary: string;
	actLblPrimary: string;
	actLblSecondary: string;
	logoUrl?: string;
	coverUrls?: string[];
	zonalApiKey?: string;
	zonalBundleIdentifier?: string;
	zonalEstateId?: string;
	zonalUserAgent?: string;
	vatConfig?: {
		standardRate?: number;
		reducedRate?: number;
		zeroRate?: number;
	};
	hideMenuImages: boolean;
	hideVenueOpeningTimes: boolean;
	showDiscountMessage: boolean;
	campaignEventName: string;
	feedbackEmail?: string;
	feedbackEnabled?: boolean;
}

export interface IBrandCreateRequest extends IBrandUpdateRequest {
	accountUUID: string;
}

export interface IBrandCreateResponse extends IBrandUpdateResponse {}

/** TYPE GUARDS */

export const isIBrand = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	brand: any
): brand is IBrand => {
	// @ts-ignore
	if (typeof (brand as IBrand).font.fontFamily === 'string') {
		return true;
	}
	return false;
};

export const isIBrandDetails = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	brand: any,
	create?: boolean
): brand is IBrandDetails => {
	const brandFormValuesFields = [
		'name',
		'description',
		'website',
		'bgPrimary',
		'lblPrimary',
	];

	if (create) {
		brandFormValuesFields.push('accountUUID');
	}

	let isValid = true;
	brandFormValuesFields.forEach((field) => {
		if (
			!get(brand as IBrandDetailsFormValues, field) ||
			get(brand as IBrandDetailsFormValues, field) === ''
		) {
			isValid = false;
		}
	});

	return isValid;
};

export const isActeolMarketingConfig = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	config: any
): config is IMarketingOptinActeolConfig => {
	return (config as IMarketingOptinActeolConfig).instanceUrl !== undefined;
};

export const isAirshipMarketingConfig = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	config: any
): config is IMarketingOptInAirshipConfig => {
	return (config as IMarketingOptInAirshipConfig).accountId !== undefined;
};

export const isEmailMarketingConfig = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	config: any
): config is IMarketingOptinEmailConfig => {
	return (config as IMarketingOptinEmailConfig).exportEmail !== undefined;
};
