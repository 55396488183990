// @ts-nocheck
/* eslint-disable */
import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { createAccount } from 'modules/account/account.slice';
import { isIAccountFormValues } from 'modules/account/account.types';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);

	h3 {
		margin: 0 0 5px 0;
		color: ${brand.validation_error};
	}
`;

const StyledErrors = styled.div`
	margin-bottom: 15px;
	font-weight: ${fonts.weights.medium};
	color: ${brand.validation_error};

	p {
		margin: 0 0 5px 0;
	}
`;

const StyledReady = styled.p`
	margin-bottom: 15px;
	font-weight: ${fonts.weights.medium};
	color: ${brand.validation_success};
`;

/** Import accounts component */
const ImportAccounts: FunctionComponent = () => {
	const [accountErrors, setAccountErrors] = useState<string[]>([]);
	const [accountsReady, setAccountsReady] = useState<boolean>(false);

	const dispatch = useReduxDispatch();

	const handleSubmitAccounts = async (
		values: any,
		{ setSubmitting }: FormikHelpers<any>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Reset account errors
		setAccountErrors([]);
		const errors: Array<string> = [];

		// Store for accounts
		let accounts;

		// Check accounts is valid JSON
		try {
			accounts = JSON.parse(values.accounts);
		} catch {
			errors.push('JSON is invalid, please correct and resubmit.');
			setAccountErrors(errors);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		// Check we have accounts
		if (accounts.length === 0) {
			errors.push('No accounts found in form values.');
			setAccountErrors(errors);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		const isInvalid = accounts
			.map((account: any) => {
				const accountIsValid = isIAccountFormValues(account);

				if (!accountIsValid) {
					errors.push(
						`An account (${account?.name}) was not in the valid format.`
					);
					setAccountErrors(errors);
				}

				return accountIsValid;
			})
			.includes(false);

		if (isInvalid) {
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		if (values.dry) {
			// Set formik submission state to false
			setSubmitting(false);
			setAccountsReady(true);
			return false;
		}

		// Import all accounts
		for (const account of accounts) {
			const response = await dispatch(createAccount(account));
			console.log('createAccount response: ', account, response);
		}

		// Set formik submission state to false
		setSubmitting(false);
	};

	return (
		<StyledFormFields>
			<StyledColumn>
				<h2>Accounts</h2>
				<Formik
					initialValues={{
						accounts: '',
						dry: true,
					}}
					onSubmit={handleSubmitAccounts}
				>
					{({ isSubmitting }) => (
						<Form>
							<Field
								component={TextArea}
								name="accounts"
								label="Accounts Data (JSON)"
								placeholder="Accounts Data (JSON)"
							/>
							<Field
								component={Checkbox}
								name="dry"
								label="Dry run"
							/>
							{accountsReady ? (
								<StyledReady>
									Accounts ready to import.
								</StyledReady>
							) : (
								''
							)}
							{accountErrors.length ? (
								<>
									<h3>
										Please correct the following errors:
									</h3>
									<StyledErrors>
										{accountErrors.map(
											(error: string, index) => (
												<p key={index}>{error}</p>
											)
										)}
									</StyledErrors>
								</>
							) : (
								''
							)}
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								Import Accounts
							</Button>
						</Form>
					)}
				</Formik>
			</StyledColumn>
		</StyledFormFields>
	);
};

export default ImportAccounts;
