import MockAdapter from 'axios-mock-adapter/types';
import { v4 as uuid } from 'uuid';

import {
	EntityType,
	ICCCPaymentConfig,
	IPaymentConfig,
	IStripePaymentConfig,
	PaymentProvider,
	PaymentProviderStatus,
} from './payment.types';

export const stripeMerchant: IPaymentConfig<IStripePaymentConfig> = {
	id: uuid(),
	entityId: uuid(),
	entityType: EntityType.brand,
	active: true,
	config: {
		chargesEnabled: false,
		payoutsEnabled: true,
	},
	createdAt: new Date(),
	provider: PaymentProvider.stripe,
	providerIdentifier: uuid(),
	status: PaymentProviderStatus.active,
	updatedAt: new Date(),
};

export const cccMerchant: IPaymentConfig<ICCCPaymentConfig> = {
	id: uuid(),
	entityId: uuid(),
	entityType: EntityType.brand,
	active: true,
	config: {
		mastercard: true,
		amex: true,
		amex_applepay: false,
		amex_googlepay: false,
		applepay: true,
		googlepay: true,
		mastercard_applepay: true,
		mastercard_googlepay: false,
		orderpayPercentageFee: null,
		threeDsTwoEnabled: true,
		visa: true,
		visa_applepay: true,
		visa_googlepay: true,
	},
	createdAt: new Date(),
	provider: PaymentProvider.ccc,
	providerIdentifier: uuid(),
	status: PaymentProviderStatus.active,
	updatedAt: new Date(),
};

export const stripeLoginLink = {
	loginLink: 'https://stripe-onboarding',
	expiresAt: new Date(),
};

const getPaymentConfig = {
	response: {
		...stripeMerchant,
		loginLink: stripeLoginLink,
	},
};

export const postStripeLoginLink = {
	response: stripeLoginLink,
};

export const updateCCCMerchant = {
	response: cccMerchant,
	request: {
		method: 'put',
		url: `/payments/ccc/venue/${uuid()}`,
		data: {
			cccLocationId: uuid(),
			config: {},
		},
	},
};

export const createMerchant = {
	response: {
		...stripeMerchant,
		loginLink: {
			url: 'https://stripe-onboarding',
			expiresAt: new Date(),
		},
	},
	request: {
		method: 'post',
		url: `/payments/provider/stripe/venue/${uuid()}`,
		data: {
			cccLocationId: undefined,
		},
	},
};

// leaving out accounts so create merchant form can be used with mocks
const getPaymentConfigRegex = /\/payments\/(brand|venue)\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/;

const getPaymentConfig404Regex = /\/payments\/account\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/;

const updateCCCMerchantRegex = /\/payments\/ccc\/(account|brand|venue)\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/;

const createStripeMerchantRegex = /\/payments\/provider\/stripe\/(account|brand|venue)\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/;
const createCCCMerchantRegex = /\/payments\/provider\/ccc\/(account|brand|venue)\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/;

const generateStripeLinkRegex = /\/payments\/stripe\/(account|brand|venue)\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/edit-link/;

export const mockedPaymentEndpoints = (mockHttpClient: MockAdapter) => {
	// create stripe login link
	mockHttpClient
		.onPost(generateStripeLinkRegex)
		.reply(200, mockPayment.endpoints.postStripeLoginLink.response);

	// Get payment config
	mockHttpClient
		.onGet(getPaymentConfigRegex)
		.reply(200, mockPayment.endpoints.getPaymentConfig.response);

	mockHttpClient.onPost(getPaymentConfig404Regex).reply(404, {});

	// Update CCC Merchant
	mockHttpClient
		.onPut(updateCCCMerchantRegex)
		.reply(200, mockPayment.endpoints.updateCCCMerchant.response);

	// create merchant
	mockHttpClient
		.onPost(createStripeMerchantRegex)
		.reply(200, mockPayment.endpoints.createMerchant.response);

	mockHttpClient.onPost(createCCCMerchantRegex).reply(200, cccMerchant);
};

const mockPayment = {
	data: {
		paymentConfig: stripeMerchant,
		stripeLoginLink,
		cccMerchant,
		stripeMerchant,
	},
	endpoints: {
		getPaymentConfig,
		postStripeLoginLink,
		updateCCCMerchant,
		createMerchant,
	},
};

export default mockPayment;
