import { IBrandCreateResponse, IBrandUpdateResponse, IBrand } from '../brand.types';

/** Convert IBrandCreateResponse | IBrandUpdateResponse to IBrand */
export default function formatShortBrand(brand: IBrandCreateResponse | IBrandUpdateResponse): IBrand {
	return {
		id: brand.id,
		name: brand.name,
		vatNo: brand.vatNo,
		description: brand.description,
		website: brand.website,
		logoUrl: brand.logoUrl || '',
		coverUrls: brand.coverUrls || [],
		accountId: brand.accountId,
		createdAt: 'string', // Missing on API
		deletedAt: 'string', // Missing on API
		font: {
			id: 'string', // Missing on API (What's this id for?)
			fontFamily: brand.fontFamily,
			url: brand.fontUrl,
			h1: brand.h1,
			h2: brand.h2,
			h3: brand.h3,
			text: brand.text,
			highlights: brand.highlights,
			smallText: brand.smallText,
			brandId: 'string', // Missing on API (why is this here?)
		},
		colour: {
			id: 'string', // Missing on API (What's this?!)
			bgPrimary: brand.bgPrimary,
			bgSecondary: brand.bgSecondary,
			lblPrimary: brand.lblPrimary,
			lblSecondary: brand.lblSecondary,
			actPrimary: brand.actPrimary,
			actSecondary: brand.actSecondary,
			actLblPrimary: brand.actLblPrimary,
			actLblSecondary: brand.actLblSecondary,
			brandId: 'string', // Missing on API (why is this here?)
		},
		vatConfig: brand.vatConfig,
		hideMenuImages: brand.hideMenuImages,
		hideVenueOpeningTimes: brand.hideVenueOpeningTimes,
		showDiscountMessage: brand.showDiscountMessage,
		campaignEventName: brand.campaignEventName,
		feedbackEmail: brand.feedbackEmail,
		feedbackEnabled: brand.feedbackEnabled,
	};
}
