import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { INavStore } from './nav.types';

// Create initial state for nav slice
export const initialNavState: INavStore = {
	open: true,
};

const navSlice = createSlice({
	name: 'nav',
	initialState: initialNavState,
	reducers: {
		TOGGLE_NAV(state) {
			return {
				open: !state.open,
			};
		},
	},
});

// Destructure and export the plain action creators
export const { TOGGLE_NAV } = navSlice.actions;

/** Thunk to toggle nav */
export const toggleNav = () => async (dispatch: Dispatch) => {
	// Add alert
	await dispatch(TOGGLE_NAV());
};

export default navSlice.reducer;
