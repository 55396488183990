import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

interface IComponentProps {
	orderId: string;
}

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledH2 = styled.h2`
	margin: 0 0 40px;
	font-size: ${fonts.sizes.large};
`;

const StyledOrderDetailsItem = styled.div`
	font-size: ${fonts.sizes.standard};
	margin: 0 0 20px;

	&.mod-capitalise {
		text-transform: capitalize;
	}
`;

const StyledSubtitle = styled.h4`
	font-size: ${fonts.sizes.med};
	margin: 0 0 10px;
	text-transform: capitalize;
`;

const StyledButtons = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background: ${brand.borders};
		margin: 20px 0;
	}
`;

// order details form wrapper
const OrderDetails: FunctionComponent<IComponentProps> = ({ orderId }) => {
	// Get history
	const history = useHistory();
	// Get order from store
	const order = useSelector((state: RootState) => state.order.activeOrder);

	return (
		<StyledWrapper>
			<StyledFieldGroup>
				<StyledColumn>
					<StyledH2>
						<FormattedMessage id="orderDetails.title.details" />
					</StyledH2>
					<StyledOrderDetailsItem>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.customerId" />
						</StyledSubtitle>
						{order?.number}
					</StyledOrderDetailsItem>
					<StyledOrderDetailsItem>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.id" />
						</StyledSubtitle>
						{order?.id}
					</StyledOrderDetailsItem>
					<StyledOrderDetailsItem>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.time" />
						</StyledSubtitle>
						{order?.date &&
							`${intl.formatDate(order?.date, {
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							})}
							${intl.formatTime(order?.date, {
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: false,
		})}`}
					</StyledOrderDetailsItem>

					<StyledOrderDetailsItem className="mod-capitalise">
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.status" />
						</StyledSubtitle>
						{order?.status &&
							order.status.replace(/([A-Z])/g, ' $1').trim()}
					</StyledOrderDetailsItem>

					<StyledOrderDetailsItem className="mod-capitalise">
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.service" />
						</StyledSubtitle>
						{order?.service &&
							order.service.replace(/([A-Z])/g, ' $1').trim()}
					</StyledOrderDetailsItem>

					<StyledOrderDetailsItem>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.tableNumber" />
						</StyledSubtitle>
						{order?.tableNumber}
					</StyledOrderDetailsItem>
				</StyledColumn>
				<StyledColumn>
					<StyledH2>
						<FormattedMessage id="orderDetails.title.customer" />
					</StyledH2>
					<StyledOrderDetailsItem data-private>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.customerName" />
						</StyledSubtitle>
						{order?.customerName}
					</StyledOrderDetailsItem>
					<StyledOrderDetailsItem data-private>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.customerEmail" />
						</StyledSubtitle>
						{order?.customerEmail}
					</StyledOrderDetailsItem>
					<StyledH2>
						<FormattedMessage id="orderDetails.title.venue" />
					</StyledH2>
					<StyledOrderDetailsItem>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.venueAccount" />
						</StyledSubtitle>
						{order?.accountName}
					</StyledOrderDetailsItem>
					<StyledOrderDetailsItem>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.venueBrand" />
						</StyledSubtitle>
						{order?.brandName}
					</StyledOrderDetailsItem>
					<StyledOrderDetailsItem>
						<StyledSubtitle>
							<FormattedMessage id="orderDetails.detailTitle.venueName" />
						</StyledSubtitle>
						{order?.venueName}
					</StyledOrderDetailsItem>
					{order?.customerNotes && order.customerNotes.length > 0 && (
						<StyledOrderDetailsItem>
							<StyledSubtitle>
								<FormattedMessage id="orderDetails.detailTitle.customerNotes" />
							</StyledSubtitle>
							{order.customerNotes.length === 1 ? (
								<p>{order.customerNotes[0].note}</p>
							) : (
								<ul>
									{order.customerNotes.map(
										({ note, roundId, addedAt }) => (
											<li key={roundId}>
												@{dayjs(addedAt).format('h:mm')}
												{' - '}
												{note}
											</li>
										)
									)}
								</ul>
							)}
						</StyledOrderDetailsItem>
					)}
				</StyledColumn>
			</StyledFieldGroup>
			<StyledButtons>
				<Button
					variant="secondary"
					onClick={() => history.push('/orders')}
				>
					<FormattedMessage id="form.button.back" />
				</Button>
			</StyledButtons>
		</StyledWrapper>
	);
};

export default OrderDetails;
