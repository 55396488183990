import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Input from 'components/form-inputs/input/input.component';
import Select from 'components/form-inputs/select/select.component';
import SortableMiniList from 'components/form-inputs/sortable-mini-list/sortable-mini-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	createMenu,
	updateMenuDetails,
	deleteMenu,
} from 'modules/menu/menu.slice';
import {
	IMenuDetailsFormValues,
	IServiceTypeOption,
	IMenuCategory,
} from 'modules/menu/menu.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	menuId?: string | undefined;
}

const MenuDetailsForm: FunctionComponent<IComponentProps> = ({ menuId }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const history = useHistory();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active menu details from store
	const menuDetails = useSelector(
		(state: RootState) => state.menu?.activeMenuDetails
	);

	// Create options for service type
	const serviceTypeOptions: IServiceTypeOption[] = [
		{ label: 'orderAhead', value: 0 },
		{ label: 'tableService', value: 1 },
	];

	// get initial service type from menu details
	const intialServiceType = serviceTypeOptions.filter((option) => {
		return menuDetails?.serviceTypes.includes(option.value);
	});

	// Cretae interface for form values
	interface IFormValues extends IMenuDetailsFormValues {
		serviceTypes: IServiceTypeOption[];
		categories: IMenuCategory[];
	}

	// Initial form values
	const initialValues: IFormValues = {
		title: (menuId && menuDetails?.title) || '',
		reference: (menuId && menuDetails?.reference) || '',
		subtitle: (menuId && menuDetails?.subtitle) || '',
		sortOrder: (menuId && menuDetails?.sortOrder) || 0,
		imageUrl: (menuId && menuDetails?.imageUrl) || '',
		serviceTypes: (menuId && intialServiceType) || [],
		categories: (menuId && menuDetails?.categoryDetails) || [],
	};

	// Handle form submission
	const handleSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Only send serviceType value and category Ids
		const formValues = {
			...values,
			serviceTypes: values.serviceTypes?.map((type) => type.value) || [],
			categories: values?.categories.map((category) => category.id),
		};
		// Create / update
		const response = await (menuId
			? dispatch(updateMenuDetails(menuId, formValues))
			: dispatch(createMenu(formValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: menuId
					? intl.formatMessage({
						id: 'menuForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'menuForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		!menuId &&
			history.push(
				`/menus/menus/edit/${response?.id}/details?activeAccount=${activeAccountId}`
			);
	};

	// Handle deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'menuForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'menuForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete action
		const response = menuId && (await dispatch(deleteMenu(menuId)));

		// Return if fail
		if (!response) {
			return;
		}
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'menuForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to menu
		history.push(`/menus/menus?activeAccount=${activeAccountId}`);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, values, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="menuForm.headings.details" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={Input}
								name="title"
								label={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
							/>
							<Field
								component={Input}
								name="reference"
								label={intl.formatMessage({
									id: 'form.fields.reference.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.reference.label',
								})}
							/>
							<Field
								component={Input}
								name="subtitle"
								label={intl.formatMessage({
									id: 'form.fields.subtitle.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.subtitle.label',
								})}
							/>
							<Field
								component={Input}
								type="number"
								name="sortOrder"
								label={intl.formatMessage({
									id: 'form.fields.sortOrder.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.sortOrder.label',
								})}
								step="1"
							/>
							<StyledFieldGroup>
								<StyledColumn>
									<Field
										component={ImageUpload}
										name="imageUrl"
										label={intl.formatMessage({
											id: 'form.fields.image.label',
										})}
										uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/menu/images/menu`}
									/>
								</StyledColumn>
							</StyledFieldGroup>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Select}
								isMulti
								name="serviceTypes"
								label={intl.formatMessage({
									id: 'form.fields.serviceTypes.label',
								})}
								selectOptions={serviceTypeOptions}
								value={values.serviceTypes}
							/>
							<Field
								component={SortableMiniList}
								isMulti
								name="categories"
								label={intl.formatMessage({
									id: 'form.fields.menuCategoriesOrder.label',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link
								to={`/menus/menus?activeAccount=${activeAccountId}`}
							>
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{menuId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="menuForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage
								id={
									menuId
										? 'form.button.save'
										: 'menuForm.button.create'
								}
							/>
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default MenuDetailsForm;
