import { Formik, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, mixed } from 'yup';

import { importRefunds } from '../operations.slice';
import { IRefundImportValues } from '../operations.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import FileUpload from 'components/form-inputs/file-upload/file-upload.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledH2 = styled.h2`
	line-height: 24px;
	margin: 0 0 25px;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledH3 = styled.h3`
	margin: 0 0 5px;
	line-height: 24px;
	font-size: ${fonts.sizes.med};
`;

const StyledUl = styled.ul`
	margin: 0;
	padding: 0 0 0 11px;
`;

const StyledLi = styled.li`
	margin: 0;
	padding: 0;
	font-size: ${fonts.sizes.small};
	line-height: 18px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button {
		margin-left: auto;
	}
`;

// Refund import form
const RefundImportForm: FunctionComponent = () => {
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);
	// Get dispatch from helper
	const dispatch = useReduxDispatch();
	// Get refund response from state
	const refunds = useSelector(
		(state: RootState) => state.operations.importRefunds
	);
	// Login validation schema
	const formValidationSchema = object<IRefundImportValues>().shape({
		refunds: mixed().required(validationMessages.file('refunds')),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IRefundImportValues,
		{ setSubmitting }: FormikHelpers<IRefundImportValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// dispatch import refunds
		await dispatch(importRefunds(values)).then(async (payload) => {
			if (!payload) return;

			await dispatch(
				// Add alert
				addAlert({
					title: intl.formatMessage({
						id: 'alerts.success.title',
					}),
					message: intl.formatMessage({
						id: 'operations.alerts.refund.success.message',
					}),
					type: 'success',
				})
			);

			// Set form submission to true to remove routing prompt
			setFormSubmission(true);
		});
		// Set formik submission state to false
		setSubmitting(false);
	};
	return (
		<>
			<Formik
				enableReinitialize
				initialValues={{}}
				validationSchema={formValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ dirty, setFieldValue, isSubmitting, errors }) => (
					<StyledForm encType="multipart/form-data">
						<Prompt when={dirty && !formSubmission} message="" />
						<StyledH2>
							<FormattedMessage id="operations.headings.zonalRefundImport" />
						</StyledH2>
						<StyledFieldGroup>
							<StyledColumn>
								<StyledH3>
									<FormattedMessage id="operations.refundImport.title.refund" />
								</StyledH3>
								<FileUpload
									fileFormats=".csv"
									fieldName="refunds"
									setFieldValue={setFieldValue}
									errors={errors.refunds}
								/>
							</StyledColumn>
							<StyledColumn>
								<StyledH3>
									<FormattedMessage id="operations.refundImport.title.results" />
								</StyledH3>
								{formSubmission && refunds.length > 0 ? (
									<StyledUl>
										{refunds.map((refund) => (
											<StyledLi key={refund}>
												{refund}
											</StyledLi>
										))}
									</StyledUl>
								) : (
									<p>
										<FormattedMessage id="operations.refundImport.results.empty" />
									</p>
								)}
							</StyledColumn>
						</StyledFieldGroup>
						<StyledActions>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="operations.buttons.processRefunds" />
							</Button>
						</StyledActions>
					</StyledForm>
				)}
			</Formik>
		</>
	);
};

export default RefundImportForm;
