import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/button/button.component';
import withNav from 'modules/navigation/with-nav.component';

const StyledWrapper = styled.section`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;

	h1 {
		font-size: 4em;
	}
`;

/** Renders 404 page  */
const NotFoundPage: FunctionComponent = () => {
	const history = useHistory();
	return (
		<StyledWrapper>
			<h1>
				<FormattedMessage id="errors.pages.404.title" />
			</h1>
			<h2>
				<FormattedMessage id="errors.pages.404.subtitle" />
			</h2>
			<Button onClick={() => history.goBack()}>
				<FormattedMessage id="errors.pages.404.button" />
			</Button>
		</StyledWrapper>
	);
};

export default withNav(NotFoundPage);
