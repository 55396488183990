import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Nav from './nav.component';

import structure from 'assets/styles/variables/structure';
import { RootState } from 'modules/core/state/root.reducer';

interface IStyledComponent {
	isNavOpen: boolean;
}

const StyledWrapper = styled.div`
	width: 100%;
	display: flex;
`;

const StyledMain = styled.main<IStyledComponent>`
	width: calc(
		100% -
			${({ isNavOpen }) =>
		isNavOpen
			? structure.nav_width_open
			: structure.nav_width_closed}
	);
	margin-left: ${({ isNavOpen }) =>
		isNavOpen ? structure.nav_width_open : structure.nav_width_closed};
	padding: 25px;
	transition: all 0.2s ease-in;
`;

/** Wraps provided component with navigation component */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withNav = (ComponentToWrap: ComponentType<any>) => (props: any) => {
	// Get whole state object from store
	const isNavOpen = useSelector((state: RootState) => state.nav.open);

	return (
		<StyledWrapper>
			<Nav />
			<StyledMain isNavOpen={isNavOpen}>
				<ComponentToWrap {...props} />
			</StyledMain>
		</StyledWrapper>
	);
};

export default withNav;
