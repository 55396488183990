import { History } from 'history';
import { rgba } from 'polished';
import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

// Type for tab list style
export type TTabListStyle = 'folder' | 'underlined' | 'button';

export interface ITab {
	id: string;
	title: string;
	defaultTab?: string;
	queryString?: string;
	isHidden?: boolean;
}

interface ITabListProps {
	activeTab: string;
	setActiveTab: Function;
	tabs: ITab[];
	tabStyle?: TTabListStyle;
	navigateOnClick?: boolean;
	history?: History;
	parentUrl?: string;
	className?: string;
}

const folderTabListStyle = css`
	background: ${brand.neutral};
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 0 0 20px;
	margin-bottom: -23px;
`;

const underlindedTabListStyle = css`
	border-bottom: 1px solid ${brand.neutral};
`;

const StyledTabList = styled.ul<ITabListProps>`
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	user-select: none;

	${({ tabStyle }) => tabStyle === 'underlined' && underlindedTabListStyle}

	${({ tabStyle }) =>
		(!tabStyle || tabStyle === 'folder') && folderTabListStyle}
`;

const folderTabStyle = css`
	padding: 20px 25px 11px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	margin: 0 5px 0 0;
	font-weight: ${fonts.weights.semibold};
	color: ${rgba(brand.text, 0.5)};

	&:hover,
	&.mod-active {
		background: ${brand.white};
		font-weight: ${fonts.weights.semibold};
	}
`;

const underlinedTabStyle = css`
	padding: 0 15px 12px 0;

	&:after {
		width: calc(100% - 15px);
		height: 3px;
		display: none;
		background: ${brand.primary};
		content: '';
		position: absolute;
		bottom: -1px;
		left: 0;
	}

	&:hover:after,
	&.mod-active:after {
		display: block;
	}
`;

const buttonTabStyle = css`
	color: ${rgba(brand.text, 0.5)};
	background: ${brand.neutral};
	margin: 0 7px 0 0;
	padding: 10px 15px;
	border-radius: 5px;
	font-size: ${fonts.sizes.standard};
	text-align: center;

	&:hover,
	&.mod-active {
		background: ${brand.primary};
		color: ${brand.white};
		border-color: transparent;

		&:after {
			display: none;
		}
	}
`;

// switch return for tab styling
const getTabStyles = (style?: TTabListStyle) => {
	switch (style) {
	case 'underlined':
		return underlinedTabStyle;
	case 'button':
		return buttonTabStyle;
	default:
		return folderTabStyle;
	}
};

const StyledTab = styled.li<ITabListProps>`
	min-width: 160px;
	margin: 0;
	font-weight: ${fonts.weights.regular};
	font-size: ${fonts.sizes.med};
	position: relative;
	cursor: pointer;

	&:hover,
	&.mod-active {
		color: ${brand.primary};
		font-weight: ${fonts.weights.medium};
	}

	${({ tabStyle }) => getTabStyles(tabStyle)}
`;

const TabList: FunctionComponent<ITabListProps> = (props) => {
	const {
		activeTab,
		setActiveTab,
		tabs,
		navigateOnClick,
		history,
		parentUrl,
	} = props;

	return (
		<StyledTabList {...props}>
			{tabs.map(
				(tab: ITab) =>
					!tab.isHidden && (
						<StyledTab
							{...props}
							key={tab.id}
							className={activeTab === tab.id ? 'mod-active' : ''}
							onClick={() => {
								if (navigateOnClick && history && parentUrl) {
									// Set default url
									let url = parentUrl + tab.id;
									// if we have a default tab, append to url
									!!tab.defaultTab &&
										(url += `/${tab.defaultTab}`);
									// If we have a query string
									!!tab.queryString &&
										(url += `?${tab.queryString}`);
									// push url via history
									history.push(url);
									return;
								}
								setActiveTab(tab.id);
							}}
							aria-label={`tab-${tab.id}`}
						>
							{tab.title}
						</StyledTab>
					)
			)}
		</StyledTabList>
	);
};

export default TabList;
