import { History } from 'history';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { setActiveStaff, getStaff, resetStaffState } from '../staff.slice';
import StaffDetailsForm from './staff-details/staff-details-form.component';
import StaffRolesForm from './staff-roles/staff-roles-form.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

interface IStaffFormPageProps {
	history: History;
}

const StaffFormPage: FunctionComponent<IStaffFormPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	// Get staff id from url params
	const {
		staffId,
		primaryTab,
	}: { staffId?: string; primaryTab?: string } = useParams();

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	// Get active staff member
	const staff = useSelector(
		(state: RootState) => state.staff.activeStaffDetails
	);

	useEffect(() => {
		// Get staff data by staff id
		const getStaffData = async (id: string | undefined) => {
			// getStaff, or unset active staff
			id
				? await dispatch(getStaff(id))
				: dispatch(setActiveStaff(undefined));
		};
		getStaffData(staffId);

		// useEffect cleanup
		return () => {
			dispatch(resetStaffState());
		};
	}, [staffId, dispatch]);

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!staff}
					title={
						staff
							? `${staff.firstName} ${staff.lastName}`
							: 'staffForm.create.title'
					}
					subtitle={staff ? 'staffForm.edit.title' : 'staff.subtitle'}
				/>
			</header>
			{staffId && (
				<TabList
					navigateOnClick={true}
					history={history}
					parentUrl={`/staff/edit/${staffId}/`}
					{...{ activeTab, setActiveTab }}
					tabs={[
						{
							id: 'details',
							title: intl.formatMessage({
								id: 'staffForm.tabs.details',
							}),
						},
						{
							id: 'roles',
							title: intl.formatMessage({
								id: 'staffForm.tabs.roles',
							}),
						},
					]}
				/>
			)}
			<FormWrapper>
				{activeTab === 'details' && (
					<StaffDetailsForm {...{ history, staffId }} />
				)}
				{activeTab === 'roles' && <StaffRolesForm {...{ staffId }} />}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(StaffFormPage);
