import { History } from 'history';
import queryString from 'query-string';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CategoryDetailsForm from './category-details/category-details-form.component';
import CategoryProductsForm from './category-products/category-products-form.component';

import { IQueryParams } from 'app.types';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setActiveAccount } from 'modules/auth/auth.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { resetMenuState, getCategoryDetails } from 'modules/menu/menu.slice';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

interface IPageProps {
	history: History;
}

const CategoryFormPage: FunctionComponent<IPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get location hook
	const location = useLocation();

	// Get category id from url params
	const { categoryId, primaryTab } = useParams();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active category
	const category = useSelector(
		(state: RootState) => state.menu.activeCategoryDetails
	);

	useEffect(() => {
		// Get category data by category id
		const getData = async (id: string | undefined) => {
			// If account id in URL params has changed
			if (
				query.activeAccount &&
				query.activeAccount !== activeAccountId
			) {
				// Update active account id
				await dispatch(setActiveAccount(query.activeAccount));
			}

			// If we have an ID, get details
			!!id && dispatch(getCategoryDetails(id));
		};

		getData(categoryId);

		// useEffect cleanup
		return () => {
			dispatch(resetMenuState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId, dispatch, query.activeAccount]);

	// Set page title
	let pageTitle = category?.title
		? category?.title
		: 'categoryForm.create.title';
	if (category?.reference) {
		pageTitle += ` (${category.reference})`;
	}

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!category}
					title={pageTitle}
					subtitle={
						category
							? 'categoryForm.edit.title'
							: 'categoryForm.subtitle'
					}
				/>
			</header>
			{!!categoryId && (
				<TabList
					navigateOnClick={true}
					history={history}
					parentUrl={`/menus/category/edit/${categoryId}/`}
					{...{
						activeTab,
						setActiveTab,
					}}
					tabs={[
						{
							id: 'details',
							title: intl.formatMessage({
								id: 'categoryForm.tabs.details',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
						{
							id: 'products',
							title: intl.formatMessage({
								id: 'categoryForm.tabs.products',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
					]}
				/>
			)}
			<FormWrapper>
				{activeTab === 'details' && (
					<CategoryDetailsForm categoryId={categoryId} />
				)}
				{activeTab === 'products' && (
					<CategoryProductsForm categoryId={categoryId!} />
				)}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(CategoryFormPage);
