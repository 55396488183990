import { rgba } from 'polished';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ReactModal, { ModalProps } from 'styled-react-modal';

import brand from 'assets/styles/variables/brand';

interface IModalProps extends ModalProps {
	toggleModal: Function;
	isExitable?: boolean;
}

interface IModalBackgroundProps {
	colour?: string;
	zIndex?: number;
}

export const modalBackground = styled.div<IModalBackgroundProps>`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${({ colour }) => colour || rgba(brand.black, 0.3)};
	align-content: center;
	align-items: center;
	justify-content: center;
	z-index: ${({ zIndex }) => zIndex || 8};
`;

const StyledModal = ReactModal.styled`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${brand.white};
	padding: 30px 20px;
	border-radius: 10px;
`;

/** Renders Modal component */
const Modal: FunctionComponent<IModalProps> = ({
	children,
	toggleModal,
	isOpen,
	allowScroll,
	afterOpen,
	afterClose,
	beforeOpen,
	beforeClose,
	isExitable = true,
}) => {
	const exitableProps = isExitable
		? {
			onBackgroundClick: () => toggleModal(),
			onEscapeKeydown: () => toggleModal(),
		  }
		: null;
	return (
		<StyledModal
			isOpen={isOpen}
			allowScroll={allowScroll}
			afterOpen={afterOpen}
			afterClose={afterClose}
			beforeOpen={beforeOpen}
			beforeClose={beforeClose}
			{...exitableProps}
		>
			{children}
		</StyledModal>
	);
};

export default Modal;
