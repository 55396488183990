import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getModifierNutrition,
	updateModifierNutrition,
} from 'modules/menu/menu.slice';
import { IProductNutritionFormValues } from 'modules/menu/menu.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	modifierItemId: string;
}

const ModifierItemNutritionalForm: FunctionComponent<IComponentProps> = ({
	modifierItemId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active product nutrition from store
	const modifierNutrition = useSelector(
		(state: RootState) => state.menu.activeModifierNutrition
	);

	useEffect(() => {
		// Get product data by product id
		const getData = async (id: string) => {
			// If we have an ID, get nutritional information
			await dispatch(getModifierNutrition(id));
		};

		getData(modifierItemId);
	}, [modifierItemId, dispatch]);

	// Create interface for form values
	interface IFormValues extends IProductNutritionFormValues {}

	// Initial form values
	const initialValues: IFormValues = {
		calories: modifierNutrition?.calories || '',
		fat: modifierNutrition?.fat || '',
		saturatedFats: modifierNutrition?.saturatedFats || '',
		sodium: modifierNutrition?.sodium || '',
		proteins: modifierNutrition?.proteins || '',
		carbohydrates: modifierNutrition?.carbohydrates || '',
		sugars: modifierNutrition?.sugars || '',
		fibre: modifierNutrition?.fibre || '',
	};

	// Handle form submission
	const handleSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Create new form values
		const formValues = {
			...values,
		};

		// Update request
		const response = await dispatch(
			updateModifierNutrition(modifierItemId, formValues)
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'productForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="productForm.headings.nutrition" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={Input}
								name="calories"
								label={intl.formatMessage({
									id: 'form.fields.calories.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.calories.label',
								})}
							/>
							<Field
								component={Input}
								name="fat"
								label={intl.formatMessage({
									id: 'form.fields.fat.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.fat.label',
								})}
							/>
							<Field
								component={Input}
								name="saturatedFats"
								label={intl.formatMessage({
									id: 'form.fields.saturatedFats.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.saturatedFats.label',
								})}
							/>
							<Field
								component={Input}
								name="sodium"
								label={intl.formatMessage({
									id: 'form.fields.sodium.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.sodium.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="proteins"
								label={intl.formatMessage({
									id: 'form.fields.proteins.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.proteins.label',
								})}
							/>
							<Field
								component={Input}
								name="carbohydrates"
								label={intl.formatMessage({
									id: 'form.fields.carbohydrates.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.carbohydrates.label',
								})}
							/>
							<Field
								component={Input}
								name="sugars"
								label={intl.formatMessage({
									id: 'form.fields.sugars.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.sugars.label',
								})}
							/>
							<Field
								component={Input}
								name="fibre"
								label={intl.formatMessage({
									id: 'form.fields.fibre.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.fibre.label',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<Link
							to={`/menus/products?activeAccount=${activeAccountId}`}
						>
							<Button variant="secondary">
								<FormattedMessage id="form.button.back" />
							</Button>
						</Link>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default ModifierItemNutritionalForm;
