/** MAIN TYPES */

import { IPagination } from 'app.types';
import { IItemSelect } from 'components/form-inputs/multi-select/multi-select.types';
import { IOption } from 'components/form-inputs/select/select.component';
import { ISortItem } from 'components/form-inputs/sortable-mini-list/sortable-mini-list.types';

export enum ESelectionTypes {
	single,
	multiple,
}

export enum EServiceType {
	orderAhead,
	tableService,
	delivery,
	selfService,
	customService,
}

export enum ETableServiceType {
	// OrderNowPayNow,
	// OrderNowPayLater,
	PayAndGo = 'PayAndGo',
	// JoinExistingTab,
	// OrderAndPay,
	// TabPickup,
}

export interface ISelectionTypeOption {
	label: keyof typeof ESelectionTypes;
	value: ESelectionTypes;
}

export interface IServiceTypeOption {
	label: keyof typeof EServiceType;
	value: EServiceType;
}

export interface ICuisine {
	id: string;
	title: string;
}

export interface IDietary {
	id: string;
	title: string;
}

export interface IAllergen {
	id: string;
	title: string;
	description?: string | null;
}

export interface IMenuCategory {
	id: string;
	title: string;
	reference: string;
	subtitle?: null | string;
	sortOrder?: number;
}

export interface IMenu {
	id: string;
	title: string;
	subtitle?: null | string;
	imageUrl?: string;
	isAvailable: boolean;
	isPublished: boolean;
	reference: null | string;
	parentId: null | string;
	sortOrder: null | number;
	categories?: string[];
	categoryDetails?: IMenuCategory[];
	serviceTypes: EServiceType[];
}

export interface ImenuItemDetails {
	id: string;
	name: string;
	sku: string;
}

export interface ICategory {
	id: string;
	title: string;
	reference: string;
	subtitle: string | null;
	imageUrl: string | null;
	sortOrder: number;
	isAvailable: boolean;
	isPublished: boolean;
	parentId: string;
	receiptPriority: number;
	products?: string[];
	productDetails?: ImenuItemDetails[];
	menu?: {
		id: string;
		title: string;
		sku: string;
	};
}

export enum ServiceType {
	clickAndCollect = 0,
	tableService = 1,
	delivery = 2,
	selfService = 3,
	customService = 4,
}

export interface IPriceBandAvailability {
	dayOfWeek: number;
	startHour: number;
	endHour: number;
}

export interface ISubPriceBand {
	id: string;
	name: string;
	serviceType: ServiceType;
	availability?: IPriceBandAvailability[];
}

export interface IPriceBand {
	id: string;
	name: string;
	subPriceBands: ISubPriceBand[];
}

export interface IProductPriceBand {
	id: string;
	applyVAT: boolean;
	price: number;
	priceBandDetails: {
		id: string;
		name: string;
	};
}

export interface IProduct {
	id: string;
	name: string;
	description: string | null;
	imageUrl: string | null;
	price?: number;
	priceBands?: IProductPriceBand[];
	isOutOfStock: boolean;
	isHidden: boolean;
	sortOrder: number;
	sku: string;
	modifierGroups: string[];
	modifierGroupDetails: { id: string; title: string; sku: string }[];
	allergens?: string[];
	dietaryPreferences?: string[];
	applyVAT: boolean;
	ageRestricted: boolean;
	categories: string[];
	categoryDetails: { id: string; title: string; reference: string }[];
	allergenDetails?: string[];
	dietaryPreferenceDetails?: string[];
}

export enum EModifierType {
	AddOn,
	Modifier,
}

export interface IModifierTypeOption {
	label: keyof typeof EModifierType;
	value: EModifierType;
}

export interface IModifierGroup {
	id: string;
	title: string;
	subtitle?: string;
	isActive?: boolean;
	sku: string | null;
	products: string[];
	productDetails: { id: string; name: string; sku: string | null }[];
	modifierType: EModifierType | number;
	selectionType: ESelectionTypes | number;
	minQuantity: number;
	maxQuantity: number;
	defaultQuantity?: number;
	priceInclusiveQuantity?: number;
	sortOrder?: number;
	modifierItems: string[];
	modifierItemDetails: {
		id: string;
		title: string;
		sku: string | null;
	}[];
}

export interface IModifierItemPriceBand extends IProductPriceBand {
	supplementPrice?: number;
}

export interface IModifierItem {
	id: string;
	title: string;
	sku: string | null;
	imageUrl: string | null;
	price?: number;
	priceBands?: IModifierItemPriceBand[];
	overridesProductPrice: boolean;
	subtitle: string | null;
	applyVAT: boolean;
	isActive: boolean;
	selectionType: ESelectionTypes;
	maxQuantity: number;
	minQuantity: number;
	defaultQuantity: number;
	priceInclusiveQuantity: number;
	sortOrder: number;
	supplementPrice: number;
	ageRestricted: boolean;
	preselected: boolean;
	defaultModifierGroups: string[];
	defaultModifierGroupDetails: ImenuItemDetails[];
	whiteListedModifierGroups: string[];
	whiteListedModifierGroupDetails: ImenuItemDetails[];
	modifierGroups: string[];
	modifierGroupDetails: ImenuItemDetails[];
	allergens: string[];
	allergenDetails: string[];
	dietaryPreferences?: string[];
	dietaryPreferenceDetails?: string[];
}

export interface IMenuListFilters {
	tagQuery?: string;
	accountId?: string;
	brandId?: string;
	venueId?: string;
	pageNumber?: number;
	pageSize?: number;
	pageCount?: number;
	sort?: string;
	sortOrder?: string;
	menuId?: string | string[] | null;
	parentId?: string | string[] | null;
	categoryId?: string | string[] | null;
	modifierGroupId?: string | string[] | null;
	search?: string;
}
export interface IMenuOperatingTime {
	dayOfWeek: number;
	dayOfWeekName: string;
	openTime: string;
	closeTime: string;
}

export interface IMenuOperatingDay {
	open: boolean;
	openTime: string;
	closeTime: string;
}

export interface IMenuAvailability {
	operatingTimes: IMenuOperatingTime[];
	isPublished: boolean;
	hideWhenUnavailable: boolean;
	venues: string[];
	venueDetails: {
		id: string;
		name: string;
	}[];
}

export interface IPairingListFilters {
	accountId?: string;
	pageNumber?: number;
	pageSize?: number;
	pageCount?: number;
	sort?: string;
	sortOrder?: string;
	search?: string;
}

export interface IUpsellProduct {
	entitySku: string;
	name: string;
	sortOrder?: number;
	disabled?: boolean;
}

/** PATCH TYPES */

export interface IMenuPatch {
	id?: string;
	title?: string;
	subtitle?: null | string;
	imageUrl?: string;
	isAvailable?: boolean;
	isPublished?: boolean;
	reference?: null | string;
	parentId?: null | string;
	sortOrder?: number;
	categories?: string[];
	serviceTypes?: EServiceType[];
}

export interface ICategoryPatch {
	title?: string;
	reference?: string;
	subtitle?: string;
	imageUrl?: string;
	sortOrder?: number;
	isAvailable?: boolean;
	isPublished?: boolean;
	parentId?: string;
	products?: string[];
	menu?: string;
}

export interface IProductPatch {
	id?: string;
	name?: string;
	description?: string | null;
	imageUrl?: string | null;
	price?: number;
	isOutOfStock?: boolean;
	isHidden?: boolean;
	sortOrder?: number;
	sku?: string;
	modifierGroups?: string[];
	allergens?: string[];
	dietaryPreferences?: string[];
}

export interface IModifierGroupPatch {
	title?: string;
	sku?: string;
	products?: string[];
	minQuantity?: number;
	maxQuantity?: number;
	selectionType?: number;
	modifierType?: number;
	modifierItems?: string[];
}

export interface IModifierItemPatch {
	title?: string;
	sku?: string;
	subtitle?: string;
	price?: number;
	priceInclusiveQuantity?: number;
	isActive?: boolean;
	applyVAT?: boolean;
	overridesProductPrice?: boolean;
	imageUrl?: string;
	allergens?: string[];
	dietaryPreferences?: string[];
	ageRestricted?: boolean;
	modifierGroups?: string[];
	defaultModifierGroups?: string[];
	whiteListedModifierGroups?: string[];
}

export interface ICheckoutUpsellConfig {
	id: string;
	isEnabled: boolean;
	automaticPopOverEnabled: boolean;
	upsellTitle: string;
	upsellMessage: string;
	tableServiceTypes: ETableServiceType[];
	upsellProducts: IUpsellProduct[];
}

/** FORM TYPES */

export interface IMenuDetailsFormValues {
	title: string;
	reference: string;
	subtitle: string;
	sortOrder: number;
	imageUrl: string;
	serviceTypes: EServiceType[] | IServiceTypeOption[];
	categories: IMenuCategory[] | string[];
}

export interface IMenuAvailabilityFormOperatingTimes {
	[day: string]: IMenuOperatingDay;
}
export interface IMenuAvailabilityFormValues {
	operatingTimes: IMenuAvailabilityFormOperatingTimes;
	isPublished: boolean;
	hideWhenUnavailable: boolean;
	venues: string[];
}

export interface IMenuAvailabilitySubmitValues {
	operatingTimes: IMenuOperatingTime[];
	isPublished: boolean;
	hideWhenUnavailable: boolean;
	venues: string[];
}

export interface ICategoryDetailsFormValues {
	title: string;
	reference: string;
	menu?: string;
	receiptPriority: number;
	products?: ISortItem[];
}

export interface ICategorySubmitValues {
	title: string;
	reference: string;
	receiptPriority: number;
	parentId?: string;
	products?: string[];
}

export interface IProductDetailsFormValues {
	name: string;
	description: string;
	imageUrl: string;
	price?: string;
	sku: string;
	modifierGroupDetails?: string[];
	allergens?: IOption[];
	dietaryPreferences?: IOption[];
	modifierGroups: ISortItem[];
	ageRestricted: boolean;
	categories: IOption[];
}

export interface IProductDetailsSubmitValues {
	name: string;
	description: string;
	imageUrl: string;
	price?: number;
	sku: string;
	modifiers?: string[];
	allergens?: string[];
	dietaryPreferences?: string[];
	modifierGroups: string[];
	ageRestricted: boolean;
	categories: string[];
}

export interface IProductNutritionFormValues {
	calories: string;
	fat: string;
	saturatedFats: string;
	sodium: string;
	proteins: string;
	carbohydrates: string;
	sugars: string;
	fibre: string;
}

export interface IModifierNutritionFormValues {
	calories: string;
	fat: string;
	saturatedFats: string;
	sodium: string;
	proteins: string;
	carbohydrates: string;
	sugars: string;
	fibre: string;
}

export interface IModifierGroupDetailsFormValues {
	title: string;
	sku: string;
	products: IOption[];
	minQuantity: string;
	maxQuantity: string;
	selectionType: number;
	modifierItems: ISortItem[];
	priceInclusiveQuantity: string;
}

export interface IModifierGroupDetailsSubmitValues {
	title: string;
	sku: string;
	products: string[];
	minQuantity: number;
	maxQuantity: number;
	selectionType: number;
	modifierItems: string[];
	priceInclusiveQuantity: number;
}

export interface IModifierItemFormValues {
	title: string;
	sku: string;
	subtitle: string;
	price?: string;
	isActive: boolean;
	overridesProductPrice: boolean;
	imageUrl: string;
	allergens: IOption[];
	dietaryPreferences?: IOption[];
	ageRestricted: boolean;
	modifierGroups: IOption[];
	defaultModifierGroups: IOption[];
	whiteListedModifierGroups: IOption[];
}

export interface IModifierItemSubmitValues {
	title: string;
	sku: string;
	subtitle: string;
	price?: number;
	isActive: boolean;
	overridesProductPrice: boolean;
	imageUrl: string;
	allergens: string[];
	dietaryPreferences?: string[];
	ageRestricted: boolean;
	modifierGroups: string[];
	defaultModifierGroups: string[];
	whiteListedModifierGroups: string[];
}

export interface ISuggestedPairingListItem {
	id: string;
	name: string;
	inAppDescription: string;
	createdAt: Date;
	updatedAt: Date | null;
}

export interface IPairingSearchResult {
	entitySku: string;
	name: string;
	disabled: boolean;
	existingListName?: string;
}
export interface IPairingGroupedSearchResult {
	name: string;
	products: IPairingSearchResult[];
}

export interface IProductPairingFormValues {
	name: string;
	inAppDescription: string;
	baseSelectedProducts: IItemSelect[];
	pairingSelectedProducts: IItemSelect[];
}
export interface IProductPairingSubmitValues {
	name: string;
	inAppDescription: string;
	baseSelectedProducts: IPairingSearchResult[];
	pairingSelectedProducts: IPairingSearchResult[];
}

export enum EProductPairingSearchType {
	Base = 'baseProduct',
	Suggested = 'suggestedProduct',
}

export interface ICheckoutUpsellConfigFormValues {
	isEnabled: boolean;
	automaticPopOverEnabled: boolean;
	upsellTitle: string;
	upsellMessage: string;
	tableServiceTypes: IOption[];
	upsellProducts: IItemSelect[];
}

export interface ICheckoutUpsellGroupedSearchResult {
	name: string;
	products: IUpsellProduct[];
}

/** ENDPOINT TYPES */

export interface IMenuPreferences {
	cuisines: ICuisine[];
	dietary: IDietary[];
	allergens: IAllergen[];
}

export interface IMenuUploadError {
	status: number;
	code: string;
	details: {
		sheetName: string;
		errors: {
			row: number;
			errors: {
				[column: string]: string[];
			};
		}[];
	};
}

/** STATE TYPES */

export interface IMenuState {
	eventsInProgress: number;
	preferences: IMenuPreferences;
	pagination: IPagination;
	menus?: IMenu[];
	activeMenuDetails?: IMenu;
	activeMenuAvailability?: IMenuAvailability;
	categories?: ICategory[];
	activeCategoryDetails?: ICategory;
	products?: IProduct[];
	activeProductDetails?: IProduct;
	activeProductNutrition?: IProductNutritionFormValues;
	activeModifierNutrition?: IModifierNutritionFormValues;
	activeProductPriceBands?: IProductPriceBand[];
	modifierGroups?: IModifierGroup[];
	activeModifierGroupDetails?: IModifierGroup;
	modifierItems?: IModifierItem[];
	activeModifierItemDetails?: IModifierItem;
	activeModifierItemPriceBands?: IModifierItemPriceBand[];
	productPairings?: ISuggestedPairingListItem[];
	activeProductPairing?: IProductPairingFormValues;
	activeCheckoutUpsellConfig?: ICheckoutUpsellConfig;
	importMenu?: IMenuImportValues;
}

export interface IMenuImportValues {
	menu?: File;
}
