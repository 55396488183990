import MockAdapter from 'axios-mock-adapter/types';

import {
	IMenuPreferences,
	IMenu,
	ICategory,
	IProduct,
	IModifierGroup,
	IMenuDetailsFormValues,
	IModifierItem,
	ICategoryPatch,
	IProductNutritionFormValues,
	IMenuAvailabilityFormValues,
	IMenuAvailability,
	IMenuPatch,
	ICategorySubmitValues,
	IProductDetailsSubmitValues,
	IProductPatch,
	IModifierGroupDetailsSubmitValues,
	IModifierItemSubmitValues,
	IModifierGroupPatch,
	IModifierNutritionFormValues,
	IMenuAvailabilitySubmitValues,
	IProductPriceBand,
	IModifierItemPriceBand,
	IPairingGroupedSearchResult,
	ISuggestedPairingListItem,
	IProductPairingSubmitValues,
	IProductPairingFormValues,
	ICheckoutUpsellConfigFormValues,
	ICheckoutUpsellConfig,
	ETableServiceType,
} from './menu.types';

/** MOCKED DATA */

const cuisines = [
	{
		id: '31908bd7-93ec-46ee-bcf5-64f30adb304b',
		title: 'British',
	},
	{
		id: '18093a54-85ca-4cb4-ac7c-f4c30dae18a8',
		title: 'Chinese',
	},
	{
		id: '3730624a-0962-4747-b0d5-c445b1150945',
		title: 'Japanese',
	},
	{
		id: '35a4c07b-ac5c-4c21-91c5-1b1763b37f6b',
		title: 'Italian',
	},
	{
		id: '30173d26-e8c6-43b2-be88-367c5ee6c637',
		title: 'Mexican',
	},
	{
		id: '024c181e-ecd4-4d8b-ae1b-62d217b1f514',
		title: 'Indian',
	},
	{
		id: 'd052a002-611e-4f34-afca-87d94a5c51a5',
		title: 'American',
	},
];

const dietary = [
	{
		id: '7242039f-effe-45f5-81a5-fbaf9755e5f5',
		title: 'Vegetarian',
	},
	{
		id: 'ba4b15d6-1b9c-40d4-a3c1-1528c4480dca',
		title: 'Vegan',
	},
	{
		id: '461a1bae-c2f9-491a-a1ec-cbf8e8268199',
		title: 'Halal',
	},
	{
		id: '605f748a-d1ce-418a-801e-48e5b08063dd',
		title: 'Gluten Free',
	},
	{
		id: '87d76ba1-4902-4838-b720-6f8253406d38',
		title: 'Organic',
	},
	{
		id: 'd59d5c73-b90a-4fc3-bef1-5a5ed8cc652a',
		title: 'Paleo',
	},
];

const allergens = [
	{
		id: '5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		title: 'Celery',
		description: null,
	},
	{
		id: '448850f8-55fe-4573-8342-18afdff2bfc5',
		title: 'Gluten & wheat',
		description: null,
	},
	{
		id: '4e547b13-d92f-4c63-8f5a-85da568773cf',
		title: 'Crustaceans',
		description: null,
	},
	{
		id: '9a2191e4-5bff-415d-bf4f-782e049a20ea',
		title: 'Eggs',
		description: null,
	},
	{
		id: '5938b193-aaad-44ea-be17-1803438f347a',
		title: 'Fish',
		description: null,
	},
	{
		id: 'f0037eec-ceff-4a66-a240-830b713478b3',
		title: 'Lupin',
		description: null,
	},
	{
		id: '5a8a259d-100e-4be2-9de6-bc6c5731b2bc',
		title: 'Milk',
		description: null,
	},
	{
		id: '2866c4e6-2cb8-435f-b960-41dad7e3fdac',
		title: 'Molluscs',
		description: null,
	},
	{
		id: '1c011a63-9851-41eb-ba33-ad3925f1eafa',
		title: 'Mustard',
		description: null,
	},
	{
		id: '212c307c-891a-4b21-add8-a9bc5b1fb071',
		title: 'Tree nuts',
		description: null,
	},
	{
		id: '5870374a-bce3-4dc2-bde8-748fcb3b8bb0',
		title: 'Peanuts',
		description: null,
	},
	{
		id: '7bd8642c-c1d9-4d5c-bb40-bb9dc2a323f8',
		title: 'Sesame',
		description: null,
	},
	{
		id: '471e554d-db61-4d13-aa38-cbd634b68367',
		title: 'Soya',
		description: null,
	},
	{
		id: 'b29c51ed-f0ba-43de-9c4a-a62c8e757c90',
		title: 'Sulphites',
		description: null,
	},
];

const menu: IMenu = {
	id: '22130872-6a62-4713-aa6f-00be954c38ae',
	title: 'Amazing Coffees',
	reference: 'amazing coffee drinks 2020',
	imageUrl: 'https://izoneapps-cdn.znl-stg01.com/uploads/2018/09/1724.png',
	categories: ['7d6b6345-8ab7-4b0e-ba33-5528a83d8829'],
	categoryDetails: [
		{
			id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
			title: 'Cold Drinks',
			reference: 'cold-drinks',
		},
	],
	serviceTypes: [0, 1],
	isAvailable: true,
	isPublished: true,
	subtitle: null,
	parentId: null,
	sortOrder: 1,
};

const menuList: IMenu[] = [
	{
		id: '6935e949-3645-4e44-ab09-bd01870f2085',
		title: 'Drinks',
		reference: 'Chrismas Drinks 2020',
		categories: ['7d6b6345-8ab7-4b0e-ba33-5528a83d8829'],
		categoryDetails: [
			{
				id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
				title: 'Cold Drinks',
				reference: 'cold-drinks',
			},
		],
		serviceTypes: [0, 1],
		isAvailable: true,
		isPublished: true,
		sortOrder: 1,
		subtitle: null,
		parentId: null,
	},
	{
		id: '216d976e-be15-4ed2-9eda-5b263cb918eb',
		title: 'Food',
		reference: 'Chrismas Food 2020',
		categories: ['7d6b6345-8ab7-4b0e-ba33-5528a83d8829'],
		categoryDetails: [
			{
				id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
				title: 'Cold Drinks',
				reference: 'cold-drinks',
			},
		],
		serviceTypes: [0],
		isAvailable: true,
		isPublished: true,
		sortOrder: 1,
		subtitle: null,
		parentId: null,
	},
	{
		id: '671f248a-bf06-4def-8f1c-b045018ba1ff',
		title: 'Food',
		reference: 'Chrismas Food 2020',
		categories: ['7d6b6345-8ab7-4b0e-ba33-5528a83d8829'],
		categoryDetails: [
			{
				id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
				title: 'Cold Drinks',
				reference: 'cold-drinks',
			},
		],
		serviceTypes: [0, 1],
		isAvailable: true,
		isPublished: true,
		sortOrder: 1,
		subtitle: null,
		parentId: null,
	},
	{
		id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		title: 'Food',
		reference: 'Chrismas Food 2020',
		categories: ['7d6b6345-8ab7-4b0e-ba33-5528a83d8829'],
		categoryDetails: [
			{
				id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
				title: 'Cold Drinks',
				reference: 'cold-drinks',
			},
		],
		serviceTypes: [0, 1],
		isAvailable: true,
		isPublished: true,
		sortOrder: 1,
		subtitle: null,
		parentId: null,
	},
];

const menuAvailability: IMenuAvailability = {
	operatingTimes: [
		{
			dayOfWeek: 0,
			dayOfWeekName: 'monday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 1,
			dayOfWeekName: 'tuesday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 2,
			dayOfWeekName: 'wednesday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 3,
			dayOfWeekName: 'thursday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 4,
			dayOfWeekName: 'friday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 5,
			dayOfWeekName: 'saturday',
			openTime: '09:00',
			closeTime: '15:00',
		},
	],
	isPublished: true,
	hideWhenUnavailable: false,
	venueDetails: [
		{
			id: 'c5044bea-93c0-4237-8acb-5311330ce50c',
			name: 'PRET Venue',
		},
		{
			id: '4219c4f0-3c7c-4c07-8a65-e0f4de93528a',
			name: 'FIVE GUYS Venue',
		},
	],
	venues: [
		'c5044bea-93c0-4237-8acb-5311330ce50c',
		'4219c4f0-3c7c-4c07-8a65-e0f4de93528a',
	],
};

const category: ICategory = {
	id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
	title: 'Cold Drinks',
	reference: 'cold drinks 2020',
	subtitle: null,
	imageUrl: null,
	sortOrder: 2,
	receiptPriority: 0,
	isAvailable: true,
	isPublished: true,
	products: [
		'd4555089-2160-4bbe-a6ed-1e3ecaf6f647',
		'd4555089-2160-4bbe-a6ed-ddsdsdssw929',
	],
	productDetails: [
		{
			id: 'd4555089-2160-4bbe-a6ed-1e3ecaf6f647',
			name: 'Espresso',
			sku: '',
		},
		{ id: 'd4555089-2160-4bbe-a6ed-ddsdsdssw929', name: 'Mocha', sku: '' },
	],
	menu: {
		id: '6935e949-3645-4e44-ab09-bd01870f2085',
		title: 'Drinks',
		sku: '',
	},
	parentId: '6935e949-3645-4e44-ab09-bd01870f2085',
};

const productPriceBands: IProductPriceBand[] = [
	{
		id: '62c80744-8c7c-452e-af11-dd4605b4180f',
		price: 529,
		applyVAT: true,
		priceBandDetails: {
			id: '87838661-54b0-4715-84bb-e627bff741b8',
			name: 'Tier 1',
		},
	},
	{
		id: '94b12e57-7acc-4408-92fc-a0344523bddc',
		price: 530,
		applyVAT: true,
		priceBandDetails: {
			id: '163515d2-af91-4ee0-874b-f5700d63dcfe',
			name: 'Tier 2',
		},
	},
	{
		id: '1e44017d-723c-4a52-9ddb-7192aa0ab6b0',
		price: 550,
		applyVAT: true,
		priceBandDetails: {
			id: '163515d2-af91-4ee0-874b-f5700d63dcfe',
			name: 'Tier 3',
		},
	},
];

const product: IProduct = {
	id: 'b80243c8-0354-4f7e-a46f-739c33c7d5d4',
	name: 'Poached Salmon',
	description: 'salmon that has been poached from a river',
	imageUrl: 'https://via.placeholder.com/50',
	priceBands: productPriceBands,
	isOutOfStock: false,
	isHidden: false,
	sortOrder: 2,
	sku: 'SALMON',
	allergens: [
		'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		'448850f8-55fe-4573-8342-18afdff2bfc5',
	],
	dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
	modifierGroupDetails: [
		{
			id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
			title: 'Portion',
			sku: 'Portion',
		},
		{
			id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
			title: 'Size',
			sku: 'Size',
		},
	],
	modifierGroups: [
		'001abb83-025b-4cdd-9aa8-43ab40745c6a',
		'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
	],
	applyVAT: false,
	ageRestricted: false,
	categoryDetails: [
		{
			id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
			title: 'Cold Drinks',
			reference: 'cold drinks 2020',
		},
		{
			id: 'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
			title: 'Coffees',
			reference: 'coffees 2020',
		},
	],
	categories: [
		'7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
		'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
	],
};

const productWithPriceBands = product;

const productWithoutPriceBands: IProduct = {
	id: 'b80243c8-0354-4f7e-a46f-739c33c7d5d4',
	name: 'Poached Salmon',
	description: 'salmon that has been poached from a river',
	imageUrl: 'https://via.placeholder.com/50',
	price: 529,
	isOutOfStock: false,
	isHidden: false,
	sortOrder: 2,
	sku: 'SALMON',
	allergens: [
		'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		'448850f8-55fe-4573-8342-18afdff2bfc5',
	],
	dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
	modifierGroupDetails: [
		{
			id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
			title: 'Portion',
			sku: 'Portion',
		},
		{
			id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
			title: 'Size',
			sku: 'Size',
		},
	],
	modifierGroups: [
		'001abb83-025b-4cdd-9aa8-43ab40745c6a',
		'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
	],
	applyVAT: false,
	ageRestricted: false,
	categoryDetails: [
		{
			id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
			title: 'Cold Drinks',
			reference: 'cold drinks 2020',
		},
		{
			id: 'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
			title: 'Coffees',
			reference: 'coffees 2020',
		},
	],
	categories: [
		'7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
		'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
	],
};

const productNutrition: IProductNutritionFormValues = {
	calories: '1',
	fat: '2',
	saturatedFats: '3',
	sodium: '4',
	proteins: '5',
	carbohydrates: '6',
	sugars: '7',
	fibre: '8',
};

const modifierNutrition: IModifierNutritionFormValues = {
	calories: '1',
	fat: '2',
	saturatedFats: '3',
	sodium: '4',
	proteins: '5',
	carbohydrates: '6',
	sugars: '7',
	fibre: '8',
};

const modifierItemPriceBands: IModifierItemPriceBand[] = [
	{
		id: '62c80744-8c7c-452e-af11-dd4605b4180f',
		price: 500,
		applyVAT: true,
		priceBandDetails: {
			id: '87838661-54b0-4715-84bb-e627bff741b8',
			name: 'Tier 1',
		},
	},
	{
		id: '94b12e57-7acc-4408-92fc-a0344523bddc',
		price: 520,
		applyVAT: true,
		priceBandDetails: {
			id: '163515d2-af91-4ee0-874b-f5700d63dcfe',
			name: 'Tier 2',
		},
	},
	{
		id: '1e44017d-723c-4a52-9ddb-7192aa0ab6b0',
		price: 550,
		applyVAT: true,
		priceBandDetails: {
			id: '163515d2-af91-4ee0-874b-f5700d63dcfe',
			name: 'Tier 3',
		},
	},
];

const modifierItemWithPriceBands: IModifierItem = {
	id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
	overridesProductPrice: false,
	sku: 'Chip portion quantity',
	title: 'Portion',
	imageUrl: 'https://via.placeholder.com/50',
	subtitle: 'Chip portion description',
	applyVAT: true,
	isActive: true,
	selectionType: 1,
	maxQuantity: 1,
	minQuantity: 1,
	defaultQuantity: 1,
	priceInclusiveQuantity: 1,
	sortOrder: 0,
	supplementPrice: 0,
	ageRestricted: false,
	preselected: false,
	priceBands: modifierItemPriceBands,
	modifierGroupDetails: [
		{
			id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
			name: 'BBQ Americano',
			sku: 'BBQ',
		},
		{
			id: '7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
			name: 'Chicken Supreme',
			sku: 'CHICKN',
		},
	],
	modifierGroups: [
		'272ee5e2-478f-4daa-a072-1c74fe10d223',
		'7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
	],
	defaultModifierGroupDetails: [
		{
			id: '7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
			name: 'Chicken Supreme',
			sku: 'CHICKN',
		},
	],
	defaultModifierGroups: ['7b16f8f6-9c31-4c35-8ba7-1a5dabef778c'],
	whiteListedModifierGroupDetails: [
		{
			id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
			name: 'BBQ Americano',
			sku: 'BBQ',
		},
	],
	whiteListedModifierGroups: ['272ee5e2-478f-4daa-a072-1c74fe10d223'],
	allergens: [
		'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		'448850f8-55fe-4573-8342-18afdff2bfc5',
	],
	dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
	allergenDetails: [],
	dietaryPreferenceDetails: [],
};

const modifierItemWithoutPriceBands: IModifierItem = {
	id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
	overridesProductPrice: false,
	sku: 'Chip portion quantity',
	title: 'Portion',
	imageUrl: 'https://via.placeholder.com/50',
	subtitle: 'Chip portion description',
	applyVAT: true,
	isActive: true,
	selectionType: 1,
	maxQuantity: 1,
	minQuantity: 1,
	defaultQuantity: 1,
	priceInclusiveQuantity: 1,
	sortOrder: 0,
	supplementPrice: 0,
	ageRestricted: false,
	preselected: false,
	price: 500,
	modifierGroupDetails: [
		{
			id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
			name: 'BBQ Americano',
			sku: 'BBQ',
		},
		{
			id: '7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
			name: 'Chicken Supreme',
			sku: 'CHICKN',
		},
	],
	modifierGroups: [
		'272ee5e2-478f-4daa-a072-1c74fe10d223',
		'7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
	],
	defaultModifierGroupDetails: [
		{
			id: '7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
			name: 'Chicken Supreme',
			sku: 'CHICKN',
		},
	],
	defaultModifierGroups: ['7b16f8f6-9c31-4c35-8ba7-1a5dabef778c'],
	whiteListedModifierGroupDetails: [
		{
			id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
			name: 'BBQ Americano',
			sku: 'BBQ',
		},
	],
	whiteListedModifierGroups: ['272ee5e2-478f-4daa-a072-1c74fe10d223'],
	allergens: [
		'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		'448850f8-55fe-4573-8342-18afdff2bfc5',
	],
	dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
	allergenDetails: [],
	dietaryPreferenceDetails: [],
};

const modifierItem: IModifierItem = modifierItemWithPriceBands;

const modifierGroup: IModifierGroup = {
	id: '45f089a7-3188-4dab-9813-38c3964c2921',
	title: '10 WING SCE',
	sku: null,
	products: ['fa70db8e-437f-4310-be15-5233cb565c62'],
	productDetails: [
		{
			id: 'fa70db8e-437f-4310-be15-5233cb565c62',
			name: '10 WINGS',
			sku: null,
		},
	],
	modifierType: 1,
	selectionType: 1,
	minQuantity: 1,
	maxQuantity: 1,
	modifierItems: [
		'98021d5f-2534-478c-9958-edf84ccd7278',
		'582bcb4a-6bd1-4087-b140-02c51a219bb1',
	],
	modifierItemDetails: [
		{
			id: '98021d5f-2534-478c-9958-edf84ccd7278',
			title: 'STICKY BBQ SAUCE',
			sku: null,
		},
		{
			id: '582bcb4a-6bd1-4087-b140-02c51a219bb1',
			title: 'PERI-PERI SAUCE',
			sku: null,
		},
	],
};
const categoriesList: ICategory[] = [
	{
		id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
		title: 'Cold Drinks',
		reference: 'cold drinks 2020',
		subtitle: null,
		imageUrl: null,
		sortOrder: 2,
		isAvailable: true,
		isPublished: true,
		receiptPriority: 0,
		products: ['b80243c8-0354-4f7e-a46f-739c33c7d5d4'],
		parentId: 'f4e9b529-7fde-4fcb-86a5-d5aefc4c2b65',
	},
	{
		id: 'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
		title: 'Coffees',
		reference: 'coffees 2020',
		subtitle: null,
		imageUrl: null,
		sortOrder: 0,
		receiptPriority: 0,
		isAvailable: true,
		isPublished: true,
		products: ['b80243c8-0354-4f7e-a46f-739c33c7d5d4'],
		parentId: 'f4e9b529-7fde-4fcb-86a5-d5aefc4c2b65',
	},
];

const productList: IProduct[] = [
	{
		id: 'b80243c8-0354-4f7e-a46f-739c33c7d5d4',
		name: 'Poached Salmon',
		description: 'salmon that has been poached from a river',
		imageUrl: 'https://via.placeholder.com/50',
		priceBands: [
			{
				id: '62c80744-8c7c-452e-af11-dd4605b4180f',
				price: 500,
				applyVAT: true,
				priceBandDetails: {
					id: '87838661-54b0-4715-84bb-e627bff741b8',
					name: 'Tier 1',
				},
			},
			{
				id: '94b12e57-7acc-4408-92fc-a0344523bddc',
				price: 520,
				applyVAT: true,
				priceBandDetails: {
					id: '163515d2-af91-4ee0-874b-f5700d63dcfe',
					name: 'Tier 2',
				},
			},
			{
				id: '1e44017d-723c-4a52-9ddb-7192aa0ab6b0',
				price: 550,
				applyVAT: true,
				priceBandDetails: {
					id: '163515d2-af91-4ee0-874b-f5700d63dcfe',
					name: 'Tier 3',
				},
			},
		],
		isOutOfStock: false,
		isHidden: false,
		sortOrder: 2,
		sku: 'SALMON',
		modifierGroupDetails: [
			{
				id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
				title: 'Portion',
				sku: 'Portion',
			},
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				title: 'Size',
				sku: 'Size',
			},
		],
		modifierGroups: [
			'001abb83-025b-4cdd-9aa8-43ab40745c6a',
			'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		],
		applyVAT: false,
		ageRestricted: false,
		categoryDetails: [
			{
				id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
				title: 'Cold Drinks',
				reference: 'cold drinks 2020',
			},
			{
				id: 'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
				title: 'Coffees',
				reference: 'coffees 2020',
			},
		],
		categories: [
			'7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
			'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
		],
	},
	{
		id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		name: 'Poached egg',
		description: 'an egg that has been poached from a hen',
		imageUrl: 'https://via.placeholder.com/50',
		price: 500,
		isOutOfStock: true,
		isHidden: true,
		sortOrder: 3,
		sku: 'EGG',
		modifierGroupDetails: [
			{
				id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
				title: 'Portion',
				sku: 'Portion',
			},
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				title: 'Size',
				sku: 'Size',
			},
		],
		modifierGroups: [
			'001abb83-025b-4cdd-9aa8-43ab40745c6a',
			'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		],
		applyVAT: false,
		ageRestricted: false,
		categoryDetails: [
			{
				id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
				title: 'Cold Drinks',
				reference: 'cold drinks 2020',
			},
			{
				id: 'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
				title: 'Coffees',
				reference: 'coffees 2020',
			},
		],
		categories: [
			'7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
			'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
		],
	},
	{
		id: 'cd69d770-8138-4c75-8ced-abc4dc147563',
		name: 'Fried egg',
		description: 'an egg that has been fried from a hen',
		imageUrl: 'https://via.placeholder.com/50',
		price: 200,
		isOutOfStock: true,
		isHidden: true,
		sortOrder: 3,
		sku: 'EGG',
		modifierGroupDetails: [
			{
				id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
				title: 'Portion',
				sku: 'Portion',
			},
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				title: 'Size',
				sku: 'Size',
			},
		],
		modifierGroups: [
			'001abb83-025b-4cdd-9aa8-43ab40745c6a',
			'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		],
		applyVAT: false,
		ageRestricted: false,
		categoryDetails: [
			{
				id: '7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
				title: 'Cold Drinks',
				reference: 'cold drinks 2020',
			},
			{
				id: 'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
				title: 'Coffees',
				reference: 'coffees 2020',
			},
		],
		categories: [
			'7d6b6345-8ab7-4b0e-ba33-5528a83d8829',
			'd9dfc61b-c5fd-454f-ac8c-6f9ea8cac82f',
		],
	},
];

const modifierGroups: IModifierGroup[] = [
	{
		id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
		title: 'BBQ Americano',
		subtitle: 'Customise your BBQ Americano',
		isActive: true,
		selectionType: 1,
		maxQuantity: 3,
		minQuantity: 0,
		defaultQuantity: 0,
		priceInclusiveQuantity: 1,
		sortOrder: 12,
		sku: 'BBQ',
		modifierType: 1,
		modifierItems: [
			'2a5b2f1a-70f7-4cca-9c14-378b3d7c788e',
			'f402eb15-f63f-4102-bbdc-cc74fe2526c3',
			'fe6d175f-a9f2-4507-80dc-f9ef08c444a4',
		],
		modifierItemDetails: [
			{
				id: '2a5b2f1a-70f7-4cca-9c14-378b3d7c788e',
				title: 'Bacon',
				sku: null,
			},
			{
				id: 'f402eb15-f63f-4102-bbdc-cc74fe2526c3',
				title: 'Chicken',
				sku: null,
			},
			{
				id: 'fe6d175f-a9f2-4507-80dc-f9ef08c444a4',
				title: 'Sweetcorn',
				sku: null,
			},
		],
		products: [
			'e1e246e4-cf09-4625-afe4-60fb46a6362c',
			'36ad95d9-8da1-4b7b-bc38-c934b103e0a4',
		],
		productDetails: [
			{
				id: 'e1e246e4-cf09-4625-afe4-60fb46a6362c',
				name: 'Half & Half',
				sku: null,
			},
			{
				id: '36ad95d9-8da1-4b7b-bc38-c934b103e0a4',
				name: 'BBQ Americano',
				sku: null,
			},
		],
	},
	{
		id: '7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
		title: 'Chicken Supreme',
		subtitle: 'Chicken Supreme',
		isActive: true,
		selectionType: 1,
		maxQuantity: 3,
		minQuantity: 0,
		defaultQuantity: 0,
		priceInclusiveQuantity: 3,
		sortOrder: 7,
		sku: 'CHICKN',
		modifierType: 1,
		modifierItems: [
			'6f07d976-7c4a-4edd-8249-48d606c91b51',
			'612288c6-7928-420d-82a6-c9bd3efe78d0',
			'106fb511-792c-4cb9-b8ab-5e978215199b',
		],
		modifierItemDetails: [
			{
				id: '6f07d976-7c4a-4edd-8249-48d606c91b51',
				title: 'Caramelisd Onion',
				sku: null,
			},
			{
				id: '612288c6-7928-420d-82a6-c9bd3efe78d0',
				title: 'Chicken',
				sku: null,
			},
			{
				id: '106fb511-792c-4cb9-b8ab-5e978215199b',
				title: 'Mushrooms',
				sku: null,
			},
		],
		products: [
			'553db868-ab7c-438f-b929-004803f7baae',
			'c2c56a72-b498-42c5-9c11-76040ef1024f',
		],
		productDetails: [
			{
				id: '553db868-ab7c-438f-b929-004803f7baae',
				name: 'Half & Half',
				sku: null,
			},
			{
				id: 'c2c56a72-b498-42c5-9c11-76040ef1024f',
				name: 'Chicken Supreme',
				sku: null,
			},
		],
	},
];

const modifierItems: IModifierItem[] = [
	{
		id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
		overridesProductPrice: false,
		sku: 'Chip portion quantity',
		title: 'Portion',
		imageUrl: 'https://via.placeholder.com/50',
		priceBands: [
			{
				id: '62c80744-8c7c-452e-af11-dd4605b4180f',
				price: 500,
				applyVAT: true,
				priceBandDetails: {
					id: '87838661-54b0-4715-84bb-e627bff741b8',
					name: 'Tier 1',
				},
			},
			{
				id: '94b12e57-7acc-4408-92fc-a0344523bddc',
				price: 550,
				applyVAT: true,
				priceBandDetails: {
					id: '163515d2-af91-4ee0-874b-f5700d63dcfe',
					name: 'Tier 2',
				},
			},
			{
				id: '1e44017d-723c-4a52-9ddb-7192aa0ab6b0',
				price: 600,
				applyVAT: true,
				priceBandDetails: {
					id: '163515d2-af91-4ee0-874b-f5700d63dcfe',
					name: 'Tier 3',
				},
			},
		],
		subtitle: 'Chip portion description',
		applyVAT: true,
		isActive: true,
		selectionType: 1,
		maxQuantity: 1,
		minQuantity: 1,
		defaultQuantity: 1,
		priceInclusiveQuantity: 3,
		sortOrder: 0,
		supplementPrice: 350,
		ageRestricted: false,
		preselected: false,
		modifierGroupDetails: [
			{
				id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
				name: 'Portion',
				sku: '',
			},
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				name: 'Size',
				sku: '',
			},
		],
		modifierGroups: [
			'001abb83-025b-4cdd-9aa8-43ab40745c6a',
			'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		],
		defaultModifierGroupDetails: [
			{
				id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
				name: 'Portion',
				sku: '',
			},
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				name: 'Size',
				sku: '',
			},
		],
		defaultModifierGroups: [
			'001abb83-025b-4cdd-9aa8-43ab40745c6a',
			'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		],
		whiteListedModifierGroupDetails: [
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				name: 'Size',
				sku: '',
			},
		],
		whiteListedModifierGroups: ['1aa5774b-bbe3-43b3-bf26-4f5c46d96768'],
		allergens: [
			'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
			'448850f8-55fe-4573-8342-18afdff2bfc5',
		],
		dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
		allergenDetails: [],
		dietaryPreferenceDetails: [],
	},
	{
		id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		overridesProductPrice: true,
		sku: 'Pizza size',
		title: 'Size',
		imageUrl: 'https://via.placeholder.com/50',
		price: 500,
		subtitle: 'Chip portion description',
		applyVAT: true,
		isActive: true,
		selectionType: 1,
		maxQuantity: 1,
		minQuantity: 1,
		defaultQuantity: 1,
		priceInclusiveQuantity: 3,
		sortOrder: 0,
		supplementPrice: 350,
		ageRestricted: false,
		preselected: false,
		modifierGroupDetails: [
			{
				id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
				name: 'Portion',
				sku: '',
			},
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				name: 'Size',
				sku: '',
			},
		],
		modifierGroups: [
			'001abb83-025b-4cdd-9aa8-43ab40745c6a',
			'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		],
		defaultModifierGroupDetails: [
			{
				id: '001abb83-025b-4cdd-9aa8-43ab40745c6a',
				name: 'Portion',
				sku: '',
			},
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				name: 'Size',
				sku: '',
			},
		],
		defaultModifierGroups: [
			'001abb83-025b-4cdd-9aa8-43ab40745c6a',
			'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
		],
		whiteListedModifierGroupDetails: [
			{
				id: '1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
				name: 'Size',
				sku: '',
			},
		],
		whiteListedModifierGroups: ['1aa5774b-bbe3-43b3-bf26-4f5c46d96768'],
		allergens: [
			'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
			'448850f8-55fe-4573-8342-18afdff2bfc5',
		],
		dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
		allergenDetails: [],
		dietaryPreferenceDetails: [],
	},
];

const checkoutUpsellConfig: ICheckoutUpsellConfig = {
	id: '56333451-1510-447f-982f-e0b4adbfcfbf',
	isEnabled: true,
	automaticPopOverEnabled: true,
	upsellTitle: '',
	upsellMessage: '',
	tableServiceTypes: [ETableServiceType.PayAndGo],
	upsellProducts: [
		{
			entitySku: 'Red Wine<>Montepulciano<>montepulciano',
			name: 'Montepulciano',
			sortOrder: 1,
		},
	],
};

/* MOCKED FORM DATA */
const menuDetailsFormValues: IMenuDetailsFormValues = {
	title: 'autumn 2020 cocktail menu',
	reference: 'autumn 2020',
	subtitle: 'Cocktail menu for the festive season!',
	sortOrder: 0,
	imageUrl: '',
	serviceTypes: [],
	categories: [],
};

const menuAvailabilityFormValues: IMenuAvailabilityFormValues = {
	operatingTimes: {
		monday: {
			open: true,
			openTime: '09:00',
			closeTime: '17:00',
		},
		tuesday: {
			open: true,
			openTime: '09:00',
			closeTime: '17:00',
		},
		wednesday: {
			open: true,
			openTime: '09:00',
			closeTime: '17:00',
		},
		thursday: {
			open: true,
			openTime: '09:00',
			closeTime: '17:00',
		},
		friday: {
			open: true,
			openTime: '09:00',
			closeTime: '17:00',
		},
		saturday: {
			open: true,
			openTime: '09:00',
			closeTime: '15:00',
		},
		sunday: {
			open: false,
			openTime: '',
			closeTime: '',
		},
	},
	isPublished: true,
	hideWhenUnavailable: false,
	venues: [
		'c5044bea-93c0-4237-8acb-5311330ce50c',
		'4219c4f0-3c7c-4c07-8a65-e0f4de93528a',
	],
};

const menuAvailabilityFormSubmitValues: IMenuAvailabilitySubmitValues = {
	operatingTimes: [
		{
			dayOfWeek: 0,
			dayOfWeekName: 'monday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 1,
			dayOfWeekName: 'tuesday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 2,
			dayOfWeekName: 'wednesday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 3,
			dayOfWeekName: 'thursday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 4,
			dayOfWeekName: 'friday',
			openTime: '09:00',
			closeTime: '17:00',
		},
		{
			dayOfWeek: 5,
			dayOfWeekName: 'saturday',
			openTime: '09:00',
			closeTime: '15:00',
		},
	],
	isPublished: true,
	hideWhenUnavailable: true,
	venues: [
		'c5044bea-93c0-4237-8acb-5311330ce50c',
		'4219c4f0-3c7c-4c07-8a65-e0f4de93528a',
	],
};

const menuCategoriesFormValues: IMenuPatch = {
	categories: ['7d6b6345-8ab7-4b0e-ba33-5528a83d8829'],
};

const productModifierGroupsFormValues: IProductPatch = {
	modifierGroups: [
		'001abb83-025b-4cdd-9aa8-43ab40745c6a',
		'1aa5774b-bbe3-43b3-bf26-4f5c46d96768',
	],
};

const categoryDetailsFormValues: ICategorySubmitValues = {
	title: 'autumn 2020 cocktail menu',
	reference: 'autumn 2020',
	receiptPriority: 0,
};

const categoryProductsFormValues: ICategoryPatch = {
	products: [
		'd4555089-2160-4bbe-a6ed-1e3ecaf6f647',
		'd4555089-2160-4bbe-a6ed-ddsdsdssw929',
	],
};

const modifierGroupModifierItemsFormValues: IModifierGroupPatch = {
	modifierItems: [
		'98021d5f-2534-478c-9958-edf84ccd7278',
		'582bcb4a-6bd1-4087-b140-02c51a219bb1',
	],
};

const productWithoutPriceBandsDetailsFormValues: IProductDetailsSubmitValues = {
	name: 'Poached Salmon',
	description: 'salmon that has been poached from a river',
	imageUrl: 'https://via.placeholder.com/50',
	sku: 'SALMON',
	price: 500,
	allergens: [
		'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		'448850f8-55fe-4573-8342-18afdff2bfc5',
	],
	dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
	ageRestricted: true,
	modifierGroups: [],
	categories: [],
};

const productWithPriceBandsDetailsFormValues: IProductDetailsSubmitValues = {
	name: 'Poached Salmon',
	description: 'salmon that has been poached from a river',
	imageUrl: 'https://via.placeholder.com/50',
	sku: 'SALMON',
	price: undefined,
	allergens: [
		'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		'448850f8-55fe-4573-8342-18afdff2bfc5',
	],
	dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
	ageRestricted: true,
	modifierGroups: [],
	categories: [],
};

const productNutritionFormValues: IProductNutritionFormValues = productNutrition;

const modifierGroupDetailsFormValues: IModifierGroupDetailsSubmitValues = {
	sku: 'Chip portion quantity',
	title: 'Portion',
	products: [],
	minQuantity: 1,
	maxQuantity: 2,
	selectionType: 1,
	modifierItems: [],
	priceInclusiveQuantity: 3,
};

const modifierItemWithPriceBandsDetailsFormValues: IModifierItemSubmitValues = {
	overridesProductPrice: false,
	sku: 'Chip portion quantity',
	subtitle: 'Elaborate description for the chip portion',
	title: 'Portion',
	imageUrl: 'https://via.placeholder.com/50',
	price: undefined,
	isActive: true,
	allergens: [
		'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		'448850f8-55fe-4573-8342-18afdff2bfc5',
	],
	dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
	ageRestricted: true,
	modifierGroups: [
		'272ee5e2-478f-4daa-a072-1c74fe10d223',
		'7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
	],
	defaultModifierGroups: ['7b16f8f6-9c31-4c35-8ba7-1a5dabef778c'],
	whiteListedModifierGroups: ['272ee5e2-478f-4daa-a072-1c74fe10d223'],
};

const modifierItemWithoutPriceBandsDetailsFormValues: IModifierItemSubmitValues = {
	overridesProductPrice: false,
	sku: 'Chip portion quantity',
	subtitle: 'Elaborate description for the chip portion',
	title: 'Portion',
	imageUrl: 'https://via.placeholder.com/50',
	price: 500,
	isActive: true,
	allergens: [
		'5373c2b8-acf7-4fdd-a67d-9fb4db5f9831',
		'448850f8-55fe-4573-8342-18afdff2bfc5',
	],
	dietaryPreferences: ['ba4b15d6-1b9c-40d4-a3c1-1528c4480dca'],
	ageRestricted: true,
	modifierGroups: [
		'272ee5e2-478f-4daa-a072-1c74fe10d223',
		'7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
	],
	defaultModifierGroups: ['7b16f8f6-9c31-4c35-8ba7-1a5dabef778c'],
	whiteListedModifierGroups: ['272ee5e2-478f-4daa-a072-1c74fe10d223'],
};

const mockSuggestedPairingList: ISuggestedPairingListItem[] = [
	{
		id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
		name: 'Burger pairings',
		inAppDescription: 'Goes well with burgers',
		createdAt: new Date(),
		updatedAt: null,
	},
	{
		id: '7b16f8f6-9c31-4c35-8ba7-1a5dabef778c',
		name: 'Pasta pairings',
		inAppDescription: 'Goes well with pasta',
		createdAt: new Date(),
		updatedAt: null,
	},
];

const mockPairingProductSearchResults: IPairingGroupedSearchResult[] = [
	{
		name: 'Crisps',
		products: [
			{
				entitySku: 'ready-salted',
				name: 'Ready Salted',
				disabled: false,
			},
			{
				entitySku: 'cheese-onion',
				name: 'Cheese & Onion',
				disabled: false,
			},
			{
				entitySku: 'salt-vinegar',
				name: 'Salt & Vinegar',
				disabled: false,
			},
		],
	},
];

const suggestedPairingFormValues: IProductPairingFormValues = {
	name: 'Burger pairings',
	inAppDescription: 'Goes great with burgers...',
	baseSelectedProducts: [
		{
			id: 'classic-burger',
			title: 'Classic burger',
			disabled: false,
		},
		{
			id: 'special-burger',
			title: 'Special burger',
			disabled: false,
		},
	],
	pairingSelectedProducts: [
		{
			id: 'fries',
			title: 'Fries',
			disabled: false,
		},
		{
			id: 'onion-rings',
			title: 'Onion rings',
			disabled: false,
		},
	],
};

const mockSuggestedPairingSubmitValues: IProductPairingSubmitValues = {
	name: 'Burger pairings',
	inAppDescription: 'Goes great with burgers...',
	baseSelectedProducts: [
		{
			entitySku: 'classic-burger',
			name: 'Classic burger',
			disabled: false,
		},
		{
			entitySku: 'special-burger',
			name: 'Special burger',
			disabled: false,
		},
	],
	pairingSelectedProducts: [
		{
			entitySku: 'fries',
			name: 'Fries',
			disabled: false,
		},
		{
			entitySku: 'onion-rings',
			name: 'Onion rings',
			disabled: false,
		},
	],
};

const checkoutUpsellConfigFormValues: ICheckoutUpsellConfigFormValues = {
	isEnabled: true,
	upsellTitle: 'test-title',
	automaticPopOverEnabled: true,
	upsellMessage: 'test-message',
	tableServiceTypes: [
		{
			label: 'PayAndGo',
			value: 'PayAndGo',
		},
	],
	upsellProducts: [
		{
			id: 'Red Wine<>Montepulciano<>montepulciano',
			title: 'Montepulciano',
			disabled: false,
		},
	],
};

const checkoutUpsellConfigSubmitValues: ICheckoutUpsellConfig = {
	id: '56333451-1510-447f-982f-e0b4adbfcfbf',
	isEnabled: true,
	automaticPopOverEnabled: true,
	upsellTitle: 'test-title',
	upsellMessage: 'test-message',
	tableServiceTypes: [ETableServiceType.PayAndGo],
	upsellProducts: [
		{
			entitySku: 'Red Wine<>Montepulciano<>montepulciano',
			name: 'Montepulciano',
			sortOrder: 1,
		},
	],
};

const checkoutUpsellSearchResults = {
	data: [
		{
			name: 'Burgers',
			products: [
				{
					entitySku: 'Burgers<>Cheese Burger<>123456789',
					name: 'Cheese Burger',
					disabled: false,
				},
				{
					entitySku: 'Burgers<>Vegan Burger<>99999999',
					name: 'Vegan Burger',
					disabled: false,
				},
			],
		},
	],
};

const getExportedMenuResponseData = {
	data: {
		ETag: '"2a13b4315bc762cfeeaba3803ec5713d"',
		Location:
			'https://orderpay.s3.eu-west-2.amazonaws.com/menus/menu-exports/c7694f17-1d53-4368-84a1-090027c151ff-menu-export-1649934822486.csv',
		key:
			'menus/menu-exports/c7694f17-1d53-4368-84a1-090027c151ff-menu-export-1649934822486.csv',
		Key:
			'menus/menu-exports/c7694f17-1d53-4368-84a1-090027c151ff-menu-export-1649934822486.csv',
		Bucket: 'orderpay',
	},
};

const getExportedMenuRequestData = {
	params: {
		venueId: 'test',
	},
};

const importVenueMenuRequestData = {
	params: {
		venueId: 'test',
		menu: undefined,
	},
};

/** MOCKED ENDPOINT DATA */

const getMenuPreferencesResponse: IMenuPreferences = {
	cuisines,
	dietary,
	allergens,
};

/** MOCKED ENDPOINT CONFIG */

const pagination = {
	pageSize: 20,
	pageNumber: 1,
	pageCount: 1,
};

const getMenuPreferences = {
	response: getMenuPreferencesResponse,
};

const getMenuDetails = {
	response: menu,
};

const getMenuAvailability = {
	response: menuAvailability,
};

const updateMenuAvailability = {
	request: menuAvailabilityFormSubmitValues,
};

const createMenu = {
	request: menuDetailsFormValues,
	response: { id: menu.id },
};

const getMenuList = {
	response: {
		data: menuList,
		pagination,
	},
};

const getCategoryDetails = {
	response: category,
};

const createCategory = {
	request: categoryDetailsFormValues,
	response: { id: category.id },
};

const getCategoriesList = {
	response: {
		data: categoriesList,
		pagination,
	},
};

const getProductList = {
	response: {
		data: productList,
		pagination,
	},
};

const getProductDetails = {
	response: product,
};

const getProductWithPriceBandsDetails = {
	response: productWithPriceBands,
};

const getProductWithoutPriceBandsDetails = {
	response: productWithoutPriceBands,
};

const getProductNutrition = {
	response: productNutrition,
};

const getProductPriceBands = {
	response: productPriceBands,
};

const updateProductNutrition = {
	request: productNutrition,
	response: { id: product.id },
};

const createProduct = {
	request: productWithoutPriceBandsDetailsFormValues,
	response: { id: productWithoutPriceBands.id },
};

const updateProductWithPriceBandsDetails = {
	request: productWithPriceBandsDetailsFormValues,
	response: { id: productWithPriceBands.id },
};

const updateProductWithoutPriceBandsDetails = {
	request: productWithoutPriceBandsDetailsFormValues,
	response: { id: productWithoutPriceBands.id },
};

const getModifierGroupsList = {
	response: {
		data: modifierGroups,
		pagination,
	},
};

const getModifierGroupDetails = {
	response: modifierGroup,
};

const getModifierNutrition = {
	response: modifierNutrition,
};

const updateModifierNutrition = {
	request: modifierNutrition,
	response: { id: modifierItem.id },
};

const createModifierGroup = {
	request: modifierGroupDetailsFormValues,
	response: { id: modifierGroup.id },
};

const getModifierItemsList = {
	response: {
		data: modifierItems,
		pagination,
	},
};

const getModifierItemDetails = {
	response: modifierItem,
};

const getModifierItemWithPriceBandsDetails = {
	response: modifierItemWithPriceBands,
};

const getModifierItemWithoutPriceBandsDetails = {
	response: modifierItemWithoutPriceBands,
};

const getModifierItemPriceBands = {
	response: modifierItemPriceBands,
};

const createModifierItem = {
	request: modifierItemWithoutPriceBandsDetailsFormValues,
	response: { id: modifierItemWithoutPriceBands.id },
};

const updateModifierItemWithPriceBands = {
	request: modifierItemWithPriceBandsDetailsFormValues,
	response: { id: modifierItemWithPriceBands.id },
};

const updateModifierItemWithoutPriceBands = {
	request: modifierItemWithoutPriceBandsDetailsFormValues,
	response: { id: modifierItemWithoutPriceBands.id },
};

const getPairingProductSearchResults = {
	request: 'searchTerm',
	response: mockPairingProductSearchResults,
};

const getSuggestedPairingList = {
	response: mockSuggestedPairingList,
};

const getSuggestedPairingItem = {
	request: mockSuggestedPairingList[0].id,
	response: mockSuggestedPairingSubmitValues,
};

const createSuggestedPairingItem = {
	request: mockSuggestedPairingSubmitValues,
	response: {
		...mockSuggestedPairingSubmitValues,
		id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
	},
};

const updateSuggestedPairingItem = {
	request: {
		...mockSuggestedPairingSubmitValues,
		id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
	},
	response: {
		...mockSuggestedPairingSubmitValues,
		id: '272ee5e2-478f-4daa-a072-1c74fe10d223',
	},
};

const getCheckoutUpsellConfig = {
	response: checkoutUpsellConfigSubmitValues,
};

const updateCheckoutUpsellConfig = {
	request: checkoutUpsellConfigSubmitValues,
	response: checkoutUpsellConfigSubmitValues,
};

const getCheckoutUpsellProductList = {
	request: {
		params: {
			entity: 'account',
			checkoutUpsellConfigId: '272ee5e2-478f-4daa-a072-1c74fe10d223',
			search: 'testsearch',
		},
	},
	response: checkoutUpsellSearchResults,
};

const getExportedMenu = {
	response: getExportedMenuResponseData,
	request: getExportedMenuRequestData,
};

const importVenueMenu = {
	request: importVenueMenuRequestData,
};

/** ENDPOINT REGEX */

// Regex for menu details endpoint
export const menuDetailsEndpointRegex = new RegExp(
	/menu\/menus\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/?$/
);

// Regex for menu availability endpoint
export const menuAvailabilityEndpointRegex = new RegExp(
	/menu\/menus\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/availability/
);

// Regex for category details endpoint
export const categoryDetailsEndpointRegex = new RegExp(
	/menu\/categories\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for product details endpoint
export const productDetailsEndpointRegex = new RegExp(
	/menu\/products\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/?$/
);

// Regex for product nutrition endpoint
export const productNutritionEndpointRegex = new RegExp(
	/menu\/products\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/nutritional/
);

// Regex for product nutrition endpoint
export const productPriceBandsEndpointRegex = new RegExp(
	/menu\/products\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/price-bands/
);

// Regex for modifier group details endpoint
export const modifierGroupDetailsEndpointRegex = new RegExp(
	/menu\/modifier-groups\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for modifier item details endpoint
export const modifierItemDetailsEndpointRegex = new RegExp(
	/menu\/modifier-items\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for modifier item nutrition endpoint
export const modifierItemNutritionEndpointRegex = new RegExp(
	/menu\/modifier-items\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/nutritional/
);

// Regex for modifier item price bands endpoint
export const modifierItemPriceBandsEndpointRegex = new RegExp(
	/menu\/modifier-items\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/price-bands/
);

// Regex for suggested pairing list
export const suggestedPairingListEndpointRegex = new RegExp(
	/menu\/suggested-pairing-lists\/?$/
);

// Regex for suggested pairing item
export const suggestedPairingItemEndpointRegex = new RegExp(
	/menu\/suggested-pairing-lists\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for pairing product search
export const pairingProductSearchEndpointRegex = new RegExp(
	/menu\/suggested-pairing-product-categories\/?$/
);

/** MOCKED ENDPOINTS */

export const mockedMenuEndpoints = (mockHttpClient: MockAdapter) => {
	// Get menu preferences
	mockHttpClient
		.onGet('menu/preferences')
		.reply(200, mockMenu.endpoints.getMenuPreferences.response);

	// Get menu details
	mockHttpClient
		.onGet(menuDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.getMenuDetails.response);

	// Get menu availability
	mockHttpClient
		.onGet(menuAvailabilityEndpointRegex)
		.reply(200, mockMenu.endpoints.getMenuAvailability.response);

	// Update menu availability
	mockHttpClient
		.onPut(menuAvailabilityEndpointRegex)
		.reply(200, mockMenu.endpoints.createMenu.response);

	// Get menus
	mockHttpClient
		.onGet('menu/menus')
		.reply(200, mockMenu.endpoints.getMenuList.response);

	// create menu
	mockHttpClient
		.onPost('menu/menus')
		.reply(200, mockMenu.endpoints.createMenu.response);

	// update menu details
	mockHttpClient
		.onPut(menuDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createMenu.response);

	// patch menu details
	mockHttpClient
		.onPatch(menuDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createMenu.response);

	// delete menu
	mockHttpClient
		.onDelete(menuDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createMenu.response);

	// Get category details
	mockHttpClient
		.onGet(categoryDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.getCategoryDetails.response);

	// Get categories
	mockHttpClient
		.onGet('menu/categories')
		.reply(200, mockMenu.endpoints.getCategoriesList.response);

	// create category
	mockHttpClient
		.onPost('menu/categories')
		.reply(200, mockMenu.endpoints.createCategory.response);

	// update category details
	mockHttpClient
		.onPut(categoryDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createCategory.response);

	// delete category
	mockHttpClient
		.onDelete(categoryDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createCategory.response);

	// Get product details
	mockHttpClient
		.onGet(productDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.getProductDetails.response);

	// Get product nutrition
	mockHttpClient
		.onGet(productNutritionEndpointRegex)
		.reply(200, mockMenu.endpoints.getProductNutrition.response);

	// Get product price bands
	mockHttpClient
		.onGet(productPriceBandsEndpointRegex)
		.reply(200, mockMenu.endpoints.getProductPriceBands.response);

	// Get products
	mockHttpClient
		.onGet('menu/products')
		.reply(200, mockMenu.endpoints.getProductList.response);

	// create product
	mockHttpClient
		.onPost('menu/products')
		.reply(200, mockMenu.endpoints.createProduct.response);

	// update product details
	mockHttpClient
		.onPatch(productDetailsEndpointRegex)
		.reply(
			200,
			mockMenu.endpoints.updateModifierItemWithoutPriceBands.response
		);

	// Update product nutrition
	mockHttpClient
		.onPut(productNutritionEndpointRegex)
		.reply(200, mockMenu.endpoints.updateProductNutrition.response);

	// delete product
	mockHttpClient
		.onDelete(productDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createProduct.response);

	// Get modifier group details
	mockHttpClient
		.onGet(modifierGroupDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.getModifierGroupDetails.response);
	// Get modifier groups
	mockHttpClient
		.onGet('menu/modifier-groups')
		.reply(200, mockMenu.endpoints.getModifierGroupsList.response);
	// create modifier group
	mockHttpClient
		.onPost('menu/modifier-groups')
		.reply(200, mockMenu.endpoints.createModifierGroup.response);

	// update modifer group details
	mockHttpClient
		.onPut(modifierGroupDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createModifierGroup.response);

	// delete modifier group
	mockHttpClient
		.onDelete(modifierGroupDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createModifierGroup.response);

	// Get modifier item price bands
	mockHttpClient
		.onGet(modifierItemPriceBandsEndpointRegex)
		.reply(200, mockMenu.endpoints.getModifierItemPriceBands.response);

	// Get modifer item details
	mockHttpClient
		.onGet(modifierItemDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.getModifierItemDetails.response);

	// Get modifier items
	mockHttpClient
		.onGet('menu/modifier-items')
		.reply(200, mockMenu.endpoints.getModifierItemsList.response);
	// create modifier item
	mockHttpClient
		.onPost('menu/modifier-items')
		.reply(200, mockMenu.endpoints.createModifierItem.response);

	// update modifer item details
	mockHttpClient
		.onPatch(modifierItemDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createModifierItem.response);

	// delete modifier item
	mockHttpClient
		.onDelete(modifierItemDetailsEndpointRegex)
		.reply(200, mockMenu.endpoints.createModifierItem.response);

	// get modifier nutrition
	mockHttpClient
		.onGet(modifierItemNutritionEndpointRegex)
		.reply(200, mockMenu.endpoints.getModifierNutrition.response);

	// update modifier nutrition
	mockHttpClient
		.onPut(modifierItemNutritionEndpointRegex)
		.reply(200, mockMenu.endpoints.updateModifierNutrition.response);

	// Suggested pairing
	mockHttpClient
		.onGet(suggestedPairingListEndpointRegex)
		.reply(200, mockMenu.endpoints.getSuggestedPairingList.response);
	mockHttpClient
		.onPost(suggestedPairingListEndpointRegex)
		.reply(200, mockMenu.endpoints.createSuggestedPairingItem.response);
	mockHttpClient
		.onGet(suggestedPairingItemEndpointRegex)
		.reply(200, mockMenu.endpoints.getSuggestedPairingItem.response);
	mockHttpClient
		.onPut(suggestedPairingItemEndpointRegex)
		.reply(200, mockMenu.endpoints.updateSuggestedPairingItem.response);
	mockHttpClient.onDelete(suggestedPairingItemEndpointRegex).reply(200);
	mockHttpClient
		.onGet(pairingProductSearchEndpointRegex)
		.reply(200, mockMenu.endpoints.getPairingProductSearchResults.response);
};

const mockMenu = {
	data: {
		cuisines,
		dietary,
		allergens,
		menu,
		menuList,
		productList,
		product,
		productWithPriceBands,
		productWithoutPriceBands,
		productWithPriceBandsDetailsFormValues,
		productWithoutPriceBandsDetailsFormValues,
		productModifierGroupsFormValues,
		productNutritionFormValues,
		category,
		categoriesList,
		modifierGroups,
		modifierGroup,
		menuDetailsFormValues,
		menuAvailabilityFormValues,
		menuCategoriesFormValues,
		categoryDetailsFormValues,
		modifierItem,
		modifierItemWithPriceBands,
		modifierItemWithoutPriceBands,
		modifierItems,
		modifierItemWithoutPriceBandsDetailsFormValues,
		modifierItemWithPriceBandsDetailsFormValues,
		modifierGroupDetailsFormValues,
		modifierGroupModifierItemsFormValues,
		categoryProductsFormValues,
		suggestedPairingFormValues,
		checkoutUpsellConfig,
		checkoutUpsellConfigFormValues,
		checkoutUpsellConfigSubmitValues,
	},
	endpoints: {
		getMenuPreferences,
		getMenuDetails,
		getMenuAvailability,
		updateMenuAvailability,
		getMenuList,
		createMenu,
		getProductList,
		getProductDetails,
		getProductWithPriceBandsDetails,
		getProductWithoutPriceBandsDetails,
		getProductNutrition,
		getProductPriceBands,
		updateProductNutrition,
		createProduct,
		updateProductWithPriceBandsDetails,
		updateProductWithoutPriceBandsDetails,
		getCategoriesList,
		getCategoryDetails,
		createCategory,
		getModifierGroupsList,
		getModifierGroupDetails,
		createModifierGroup,
		getModifierNutrition,
		getModifierItemsList,
		getModifierItemDetails,
		getModifierItemWithPriceBandsDetails,
		getModifierItemWithoutPriceBandsDetails,
		getModifierItemPriceBands,
		createModifierItem,
		updateModifierNutrition,
		updateModifierItemWithPriceBands,
		updateModifierItemWithoutPriceBands,
		getSuggestedPairingList,
		getSuggestedPairingItem,
		getPairingProductSearchResults,
		createSuggestedPairingItem,
		updateSuggestedPairingItem,
		getCheckoutUpsellConfig,
		updateCheckoutUpsellConfig,
		getCheckoutUpsellProductList,
		getExportedMenu,
		importVenueMenu,
	},
};
export default mockMenu;
