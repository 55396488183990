import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IAccountState,
	IAccount,
	IAccountFormValues,
	IAccountFilters,
} from './account.types';

// Create initial state for account slice
export const initialAccountState: IAccountState = {
	accounts: [],
	eventsInProgress: 0,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
};

const accountSlice = createSlice({
	name: 'account',
	initialState: initialAccountState,
	reducers: {
		GET_ALL_ACCOUNTS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ALL_ACCOUNTS_SUCCESS(state, action) {
			return {
				...state,
				accounts: action.payload.data.accounts,
				pagination: {
					...action.payload.data.pagination,
					pageSize: state.pagination.pageSize,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_ACCOUNTS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ACTIVE_ACCOUNT(state, action) {
			return {
				...state,
				activeAccount: action.payload,
			};
		},
		CREATE_ACCOUNT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_ACCOUNT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_ACCOUNT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ACCOUNT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ACCOUNT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ACCOUNT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_ACCOUNT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_ACCOUNT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_ACCOUNT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_ACCOUNT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_ACCOUNT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_ACCOUNT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_ACCOUNT_STATE() {
			return {
				...initialAccountState,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_ALL_ACCOUNTS,
	GET_ALL_ACCOUNTS_SUCCESS,
	GET_ALL_ACCOUNTS_FAIL,
	ACTIVE_ACCOUNT,
	CREATE_ACCOUNT,
	CREATE_ACCOUNT_SUCCESS,
	CREATE_ACCOUNT_FAIL,
	GET_ACCOUNT,
	GET_ACCOUNT_SUCCESS,
	GET_ACCOUNT_FAIL,
	UPDATE_ACCOUNT,
	UPDATE_ACCOUNT_SUCCESS,
	UPDATE_ACCOUNT_FAIL,
	DELETE_ACCOUNT,
	DELETE_ACCOUNT_SUCCESS,
	DELETE_ACCOUNT_FAIL,
	RESET_ACCOUNT_STATE,
} = accountSlice.actions;

/** Get accounts list */
export const getAccountsList = (filters?: IAccountFilters) => async (
	dispatch: Dispatch
) => {
	try {
		// get accounts list request
		const { payload } = await dispatch(
			GET_ALL_ACCOUNTS({
				request: {
					method: 'get',
					url: 'account',
					params: filters,
				},
			})
		);

		return payload?.data;
	} catch (error) {
		return [];
	}
};

/** Set active account in state */
export const setActiveAccount = (account: IAccount | undefined) => async (
	dispatch: Dispatch
) => {
	// Set active account in store
	await dispatch(ACTIVE_ACCOUNT(account));
};

/** Create account */
export const createAccount = (account: IAccountFormValues) => async (
	dispatch: Dispatch
) => {
	// Create accounts list request
	const { payload } = await dispatch(
		CREATE_ACCOUNT({
			request: {
				method: 'post',
				url: 'account/create',
				data: account,
			},
		})
	);

	// Update active account
	payload?.data && (await dispatch(ACTIVE_ACCOUNT(payload.data)));

	return payload?.data;
};

/** Get account by ID */
export const getAccount = (accountId: string) => async (dispatch: Dispatch) => {
	// get account request
	const { payload } = await dispatch(
		GET_ACCOUNT({
			request: {
				method: 'get',
				url: `account/${accountId}`,
			},
		})
	);

	// variable for request success
	const success = payload?.status === 200;

	// Update active account
	success && (await dispatch(ACTIVE_ACCOUNT(payload.data)));

	// return boolean for success
	return success;
};

/** Update account */
export const updateAccount = (
	accountId: string,
	account: IAccountFormValues
) => async (dispatch: Dispatch) => {
	// upate account request
	const { payload } = await dispatch(
		UPDATE_ACCOUNT({
			request: {
				method: 'post',
				url: `account/${accountId}`,
				data: account,
			},
		})
	);

	// variable for request success
	const success = payload?.status === 200;
	// Update active account
	success && (await dispatch(ACTIVE_ACCOUNT(payload.data)));
	// return boolean for success
	return success;
};

/** Delete account by ID */
export const deleteAccount = (accountId: string) => async (
	dispatch: Dispatch
) => {
	// delete account request
	const { payload } = await dispatch(
		DELETE_ACCOUNT({
			request: {
				method: 'delete',
				url: `account/${accountId}`,
			},
		})
	);

	// variable for request success
	const success = payload?.status === 200;
	// Update active account
	success && (await dispatch(ACTIVE_ACCOUNT(undefined)));
	// return boolean for success
	return success;
};

/** Reset Account State */
export const resetAccountState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_ACCOUNT_STATE());
};

export default accountSlice.reducer;
