import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import CreateMerchantForm from 'modules/payment/payment-config/create-merchant-form.component';
import EditCCCMerchantForm from 'modules/payment/payment-config/edit-ccc-merchant-form.component';
import EditStripeMerchant from 'modules/payment/payment-config/edit-stripe-merchant.component';
import { getPaymentConfigForEntity } from 'modules/payment/payment.slice';
import {
	EntityType,
	ICCCPaymentConfig,
	IPaymentConfig,
	IStripeLoginUrl,
	IStripePaymentConfig,
	PaymentProvider,
} from 'modules/payment/payment.types';

const StyledH2 = styled.h2`
	margin: 0 0 4px 0;
`;

interface IComponentProps {
	entityType: EntityType;
	entityId: string;
}

const PaymentConfigForm: FunctionComponent<IComponentProps> = ({
	entityType,
	entityId,
}) => {
	const dispatch = useReduxDispatch();

	const paymentConfig:
	| IPaymentConfig<ICCCPaymentConfig & IStripePaymentConfig>
	| undefined = useSelector(
		(state: RootState) => state?.payments?.paymentConfig
	);

	const stripeLoginLink: IStripeLoginUrl | undefined = useSelector(
		(state: RootState) => state?.payments?.stripeLoginUrl
	);

	const eventsInProgress: number = useSelector(
		(state: RootState) => state?.payments.eventsInProgress
	);

	useEffect(() => {
		const getPaymentConfig = async () => {
			await dispatch(getPaymentConfigForEntity(entityType, entityId));
		};
		getPaymentConfig();
	}, [entityId, entityType, dispatch]);

	return (
		<div>
			{!!paymentConfig && (
				<>
					<StyledH2>
						<FormattedMessage id="paymentConfigForm.headings.edit" />
					</StyledH2>
					{paymentConfig.provider === PaymentProvider.stripe && (
						<EditStripeMerchant
							paymentConfig={paymentConfig}
							stripeLoginLink={stripeLoginLink}
						/>
					)}
					{paymentConfig.provider === PaymentProvider.ccc && (
						<EditCCCMerchantForm paymentConfig={paymentConfig} />
					)}
				</>
			)}

			{!paymentConfig && !eventsInProgress && (
				<>
					<StyledH2>
						<FormattedMessage id="paymentConfigForm.headings.create" />
					</StyledH2>
					<CreateMerchantForm
						entityId={entityId}
						entityType={entityType}
					/>
				</>
			)}
		</div>
	);
};

export default PaymentConfigForm;
