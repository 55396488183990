import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IOperationsStore, IRefundImportValues } from './operations.types';

// Create initial state for operations slice
export const initialOperationsState: IOperationsStore = {
	eventsInProgress: 0,
	importRefunds: [],
};

const OperationsSlice = createSlice({
	name: 'operations',
	initialState: initialOperationsState,
	reducers: {
		IMPORT_REFUNDS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		IMPORT_REFUNDS_SUCCESS(state, action) {
			return {
				...state,
				importRefunds: action.payload.data || [],
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		IMPORT_REFUNDS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_OPERATIONS_STATE() {
			return initialOperationsState;
		},
	},
});

// Destructure and export the plain action creators
export const {
	IMPORT_REFUNDS,
	IMPORT_REFUNDS_SUCCESS,
	IMPORT_REFUNDS_FAIL,
	RESET_OPERATIONS_STATE,
} = OperationsSlice.actions;

/** Thunk to import refunds */
export const importRefunds = (data: IRefundImportValues) => async (
	dispatch: Dispatch
) => {
	// Create form data
	const formData = new FormData();
	formData.append('refunds', data.refunds!);

	// send form
	const { payload } = await dispatch(
		IMPORT_REFUNDS({
			request: {
				method: 'post',
				url: 'zonal/import/refunds',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			},
		})
	);

	return payload?.status === 200;
};

/** Reset Operations State */
export const resetOperationsState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_OPERATIONS_STATE());
};

export default OperationsSlice.reducer;
