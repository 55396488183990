import { FieldProps } from 'formik';
import get from 'lodash.get';
import { rgba } from 'polished';
import React, {
	FunctionComponent,
	ChangeEvent,
	InputHTMLAttributes,
} from 'react';
import styled, { css } from 'styled-components';

import FieldLabel from '../field-label/field-label.component';
import { IToolTip } from '../form-tooltip/form-tooltip.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';
import icons from 'components/icons/icons.config';

interface IInputProps {
	label?: string;
	icon?: keyof typeof icons;
	iconWidth?: number;
	iconHeight?: number;
	handleChangeEvent?: Function;
	hideError?: boolean;
	size?: 'sm' | 'lg';
	inputSymbol?: string;
	inputSymbolPlacement?: 'left' | 'right';
	isDisabled?: boolean;
	isPrivate?: boolean;
	autoComplete?: string;
	borderBottom?: boolean;
	toolTip?: IToolTip;
}

const sizeSmStyling = css`
	height: 35px;
	line-height: 35px;
`;

const sizeLgStyling = css`
	height: 48px;
	line-height: 48px;
	font-size: ${fonts.sizes.med};
`;

const StyledInputWrapper = styled.div`
	position: relative;
`;

const StyledSymbol = styled.div<IInputProps>`
	font-size: ${fonts.sizes.standard};
	position: absolute;
	top: 50%;
	transform: translateY(-53%);

	${({ inputSymbolPlacement }) =>
		inputSymbolPlacement === 'right' ? 'right: 5px;' : 'left: 10px;'};
`;

const StyledInput = styled.input<IInputProps>`
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-size: ${fonts.sizes.standard};
	padding: 0 14px;
	border: 1px solid ${brand.borders};
	border-radius: 5px;

	${({ inputSymbol, inputSymbolPlacement }) =>
		!!inputSymbol &&
		inputSymbolPlacement !== 'right' &&
		'padding-left: 25px'};

	${({ size }) => size === 'sm' && sizeSmStyling}
	${({ size }) => size === 'lg' && sizeLgStyling}

	&::placeholder {
		color: ${brand.placeholder};
	}

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
	}

	&[type='number'] {
		appearance: textfield;
	}

	&:focus {
		border-color: ${brand.link};
		outline: none;
	}

	&:disabled {
		background: ${brand.formDisabled};
	}
`;

const StyledIcon = styled(Icon)`
	height: 100%;
	position: absolute;
	right: 10px;
	bottom: 0;
`;

/** Renders Input component */
const Input: FunctionComponent<
	IInputProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	field,
	form,
	type = 'text',
	label,
	placeholder,
	icon,
	inputSymbol,
	iconWidth,
	iconHeight,
	className,
	handleChangeEvent,
	hideError,
	size,
	isDisabled,
	isPrivate,
	autoComplete,
	step,
	min,
	max,
	borderBottom,
	inputSymbolPlacement = 'left',
	toolTip,
}) => {
	const { touched, errors } = form;
	const isTouched = get(touched, field.name) || false;
	const hasErrors = get(errors, field.name) || false;

	// variable to set min/max props
	const minMaxProps = {
		min: (type === 'number' && ((!!min && min) || '0')) || undefined,
		max: (type === 'number' && max) || undefined,
	};

	return (
		<FormElement
			className={className}
			isDisabled={isDisabled}
			borderBottom={borderBottom}
		>
			{label && (
				<FieldLabel
					fieldName={field.name}
					label={label}
					toolTip={toolTip}
				/>
			)}
			<StyledInputWrapper>
				{!!inputSymbol && (
					<StyledSymbol inputSymbolPlacement={inputSymbolPlacement}>
						{inputSymbol}
					</StyledSymbol>
				)}
				<StyledInput
					{...field}
					{...minMaxProps}
					inputSymbol={inputSymbol}
					size={size}
					placeholder={placeholder}
					id={field.name}
					aria-label={`${field.name}-input`}
					className={isTouched && hasErrors ? 'has-error' : ''}
					type={type}
					step={step}
					data-testid="input"
					onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
						form.setFieldValue(
							field.name,
							changeEvent.target.value
						);
						if (handleChangeEvent) {
							handleChangeEvent(changeEvent.target.value);
						}
					}}
					disabled={isDisabled}
					data-private={isPrivate}
					autoComplete={autoComplete}
				/>
			</StyledInputWrapper>
			{icon && (
				<StyledIcon name={icon} width={iconWidth} height={iconHeight} />
			)}
			{!hideError && isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</FormElement>
	);
};

export default Input;
