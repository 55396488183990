import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import BrandPriceBandItem from './brand-price-band-item/brand-price-band-item.component';

import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getBrandPriceBands, resetBrandState } from 'modules/brand/brand.slice';
import { RootState } from 'modules/core/state/root.reducer';

const StyledWrapper = styled.section`
	width: 100%;
	padding: 0 0 15px;
`;

const StyledHeader = styled.h2`
	margin-bottom: 0;
`;

const StyledActions = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 20px;
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	user-select: none;

	:focus {
		outline: none;
	}

	&:nth-child(1) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(100% - 50px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		text-align: center;
		margin: 0 15px 0 0;
	}
`;

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

interface IComponentProps {
	brandId: string;
}

const BrandPriceBandList: FunctionComponent<IComponentProps> = ({
	brandId,
}) => {
	// initialise hooks
	const dispatch = useReduxDispatch();

	// Get brand price bands from state
	const brandPriceBands = useSelector(
		(state: RootState) => state.brand.priceBands
	);

	useEffect(() => {
		const getData = async () => {
			await dispatch(getBrandPriceBands(brandId));
		};

		getData();

		// useEffect cleanup
		return () => {
			resetBrandState();
		};
	}, [dispatch, brandId]);

	const EmptyMessage: FunctionComponent = () => {
		return (
			<h3 aria-label="price-bands-empty">
				<FormattedMessage id="brandPriceBands.empty" />
			</h3>
		);
	};

	const PriceBandList: FunctionComponent = () => (
		<>
			<StyledHeadings>
				<StyledHeading>
					<FormattedMessage id="brandPriceBands.heading.name" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="brandPriceBands.heading.edit" />
				</StyledHeading>
			</StyledHeadings>
			<StyledList aria-label="price-bands-list">
				{brandPriceBands.map((priceBand) => (
					<BrandPriceBandItem
						key={priceBand.id}
						brandId={brandId}
						item={priceBand}
					/>
				))}
			</StyledList>
		</>
	);

	return (
		<StyledWrapper>
			<StyledActions>
				<StyledHeader>
					<FormattedMessage id="brandPriceBands.title" />
				</StyledHeader>
				<Link to={`/brands/${brandId}/price-bands/create`}>
					<Button icon="plus" iconWidth={11} iconHeight={11}>
						<FormattedMessage id="brandPriceBands.button.create" />
					</Button>
				</Link>
			</StyledActions>
			{brandPriceBands?.length ? <PriceBandList /> : <EmptyMessage />}
		</StyledWrapper>
	);
};

export default BrandPriceBandList;
