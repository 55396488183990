import { History } from 'history';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import OrderAudits from './order-audits/order-audits.component';
import OrderDetails from './order-details/order-details.component';
import OrderPayments from './order-payments/order-payments.component';
import OrderReceipt from './order-receipt/order-receipt.component';
import OrderRefund from './order-refund/order-refund.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import { getOrder, resetOrderState } from 'modules/order/order.slice';

// Interface for component props
interface IPageProps {
	history: History;
}

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

/** Renders accounts page component */
const OrderFormPage: FunctionComponent<IPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get venue id and active tabs from url params
	const { orderId, primaryTab } = useParams();
	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	// Get active order number
	const orderNumber = useSelector(
		(state: RootState) => state.order.activeOrder?.number
	);

	// fun dispatch to get order by ID
	useEffect(() => {
		const getData = async (id: string) => {
			await dispatch(getOrder(id));
		};
		getData(orderId!);

		// useEffect cleanup
		return () => {
			dispatch(resetOrderState());
		};
	}, [dispatch, orderId]);

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!orderNumber}
					title={orderNumber ? `#${orderNumber}` : 'orderForm.title'}
					subtitle="orderForm.subtitle"
				/>
			</header>
			<TabList
				{...{ activeTab, setActiveTab }}
				navigateOnClick={true}
				history={history}
				parentUrl={`/orders/manageOrder/${orderId}/`}
				tabs={[
					{
						id: 'details',
						title: intl.formatMessage({
							id: 'orderForm.tabs.details.primary',
						}),
					},
					{
						id: 'receipt',
						title: intl.formatMessage({
							id: 'orderForm.tabs.receipt.primary',
						}),
					},
					{
						id: 'payments',
						title: intl.formatMessage({
							id: 'orderForm.tabs.payments.primary',
						}),
					},
					{
						id: 'refund',
						title: intl.formatMessage({
							id: 'orderForm.tabs.refund.primary',
						}),
					},
					{
						id: 'audits',
						title: intl.formatMessage({
							id: 'orderForm.tabs.audit.primary',
						}),
					},
				]}
			/>

			<FormWrapper>
				{!!orderId && activeTab === 'details' && (
					<OrderDetails orderId={orderId} />
				)}
				{!!orderId && activeTab === 'receipt' && <OrderReceipt />}
				{!!orderId && activeTab === 'payments' && (
					<OrderPayments orderId={orderId} />
				)}
				{!!orderId && activeTab === 'refund' && (
					<OrderRefund orderId={orderId} />
				)}
				{!!orderId && activeTab === 'audits' && (
					<OrderAudits orderId={orderId} />
				)}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(OrderFormPage);
