import { Formik, Form, Field } from 'formik';
import queryString from 'query-string';
import React, {
	useCallback,
	useEffect,
	FunctionComponent,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import { getSuggestedPairingList, resetMenuState } from '../../menu.slice';
import {
	IModifierItem,
	IPairingListFilters,
	ISuggestedPairingListItem,
} from '../../menu.types';
import SuggestedPairingListItem from './suggested-pairing-list-item/suggested-pairing-list-item.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Pagination from 'components/pagination/pagination.component';
import SortIcons from 'components/sort-icons/sort-icons.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledWrapper = styled.section`
	width: 100%;
	padding: 0 0 15px;
`;

const StyledActions = styled.div`
	padding: 0 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledForm = styled(Form)`
	display: flex;
	width: 50%;
`;

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	width: 100%;
	max-width: 160px;

	input {
		height: 35px;
		padding: 0 23px 0 14px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	justify-content: space-between;
	padding: 0 15px 0 0;
`;

const StyledSortIcons = styled(SortIcons)`
	margin: 0 10px 0 0;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	padding-left: 5px;
	user-select: none;

	&.mod-sort {
		cursor: pointer;
	}

	:focus {
		outline: none;
	}

	&:first-child {
		min-width: 50px;
		width: 50px;
		padding: 0;
		margin: 0;
	}

	&:nth-child(1n + 2) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(25% - 110px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		padding-left: 0;
		text-align: center;
	}
`;

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const SuggestedPairingList: FunctionComponent = () => {
	// initialise hooks
	const dispatch = useReduxDispatch();
	const location = useLocation();
	const history = useHistory();
	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	const [searchTerm, setSearchTerm] = useState<string>(query.search || '');

	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	interface ISearchFormValues {
		searchTerm: string;
	}

	// Initial form values
	const initialValues: ISearchFormValues = {
		searchTerm,
	};

	const { productPairings, pagination } = useSelector(
		(state: RootState) => state.menu
	);

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	// Function to update sort query params
	const updateSort = (column: keyof IModifierItem) => {
		// sort order from query params
		const { sortOrder } = query;
		// variable to store sort direction
		let sortDir;

		if (sortOrder === 'ASC') {
			sortDir = 'DESC';
		} else if (sortOrder === 'DESC') {
			sortDir = '';
		} else {
			sortDir = 'ASC';
		}

		// Create list of filters
		const filters = {
			...query,
			sortOrder: sortDir,
			sort: column,
		};

		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	useEffect(() => {
		const getData = async () => {
			// Get filters from query params
			const itemFilters: IPairingListFilters = {
				search: query.search,
				sortOrder: query.sortOrder,
				sort: query.sort,
				pageNumber: parseFloat(query.pageNumber!) || 1,
			};

			if (activeAccountId) {
				await dispatch(getSuggestedPairingList(itemFilters));
			} else {
				await dispatch(resetMenuState());
			}
		};

		getData();

		// useEffect cleanup
		return () => {
			resetMenuState();
		};
	}, [
		dispatch,
		activeAccountId,
		query.sortOrder,
		query.sort,
		query.pageNumber,
		query.search,
	]);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	// If no active account, tell the user to choose one
	const emptyMessage = activeAccountId ? (
		<FormattedMessage id="suggestedPairingList.empty" />
	) : (
		<FormattedMessage id="menus.list.noAccount" />
	);

	return (
		<StyledWrapper>
			<StyledActions>
				<Formik initialValues={initialValues} onSubmit={() => {}}>
					{() => {
						return (
							<StyledForm>
								<Field
									component={StyledInput}
									name="searchTerm"
									placeholder={intl.formatMessage({
										id: 'form.search.placeholder',
									})}
									icon="search"
									iconWidth={12}
									iconHeight={12}
									handleChangeEvent={(value: string) =>
										setSearchTerm(value)}
								/>
							</StyledForm>
						);
					}}
				</Formik>
				<div>
					{activeAccountId && (
						<Link
							to={`suggested-pairing/create?activeAccount=${activeAccountId}`}
						>
							<Button icon="plus" iconWidth={11} iconHeight={11}>
								<FormattedMessage id="suggestedPairingList.button.create" />
							</Button>
						</Link>
					)}
				</div>
			</StyledActions>
			<StyledHeadings>
				<StyledHeading
					className="mod-sort"
					onClick={() => updateSort('title')}
					onKeyPress={() => updateSort('title')}
					role="button"
					tabIndex={0}
				>
					<StyledSortIcons
						active={query.sort === 'title'}
						sort={query.sortOrder}
					/>
					<FormattedMessage id="suggestedPairingList.headings.name" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="suggestedPairingList.headings.edit" />
				</StyledHeading>
			</StyledHeadings>
			<StyledList aria-label="suggested-pairing-list">
				{productPairings && productPairings.length > 0
					? productPairings.map((item: ISuggestedPairingListItem) => (
						<SuggestedPairingListItem
							item={item}
							key={item.id}
							activeAccountId={activeAccountId}
						/>
					  ))
					: emptyMessage}
			</StyledList>
			{pagination && pagination.pageCount > 1 && (
				<Pagination
					pageNumber={
						query.pageNumber ? parseFloat(query.pageNumber) : 1
					}
					pageCount={pagination.pageCount}
					setPageNumber={handlePagination}
				/>
			)}
		</StyledWrapper>
	);
};

export default SuggestedPairingList;
