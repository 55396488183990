import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import { IModifierGroup } from 'modules/menu/menu.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 250px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-child(1) {
		margin-left: 20px;
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		display: flex;
		justify-content: center;
	}
`;

// Props for component
interface IComponentProps {
	item: IModifierGroup;
	activeAccountId: string;
}
// Modifier group list item component
const ModifierGroupListItem: FunctionComponent<IComponentProps> = ({
	item,
	activeAccountId,
}) => {
	return (
		<StyledListItem key={item.id} aria-label="modifier-group-item">
			<StyledColumn>{item.title}</StyledColumn>
			<StyledColumn>{item.sku}</StyledColumn>
			<StyledColumn>
				<Link
					to={`/menus/modifier-group/edit/${item.id}/details?activeAccount=${activeAccountId}`}
				>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledListItem>
	);
};

export default ModifierGroupListItem;
