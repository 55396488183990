import dayjs from 'dayjs';
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import queryString from 'query-string';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ReportResults from '../report-results/report-results.component';
import {
	getPaginatedReport,
	queueReport,
	resetReportState,
} from '../report.slice';
import {
	EReportTypes,
	IReportQueueFormValues,
	IReportQueueSubmitValues,
} from '../report.types';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import CreateableSelect from 'components/form-inputs/creatable-select/creatable-select.component';
import DatePickerInput from 'components/form-inputs/date-picker/date-picker.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import {
	addLoadingEvent,
	setLoadingConfig,
} from 'components/loading/loading.slice';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getAccountsList } from 'modules/account/account.slice';
import { IAccount } from 'modules/account/account.types';
import { getBrandsList, resetBrandState } from 'modules/brand/brand.slice';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenuesList,
	resetVenueState,
} from 'modules/venue/slices/venue.slice';
import { IVenueItem } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledSubtitle = styled.p`
	margin-bottom: 25px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledSelect = styled(Select)`
	text-transform: capitalize;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledCheckboxGroup = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;

	& > div {
		width: calc(33% - 30px);
	}
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

const ReportsForm: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const location = useLocation();

	const [accountFilters, setAccountFilters] = useState<string[]>([]);
	const [brandFilters, setBrandFilters] = useState<string[]>([]);

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get active report from state
	const { activeReport } = useSelector((state: RootState) => state.report);

	// Get data for select dropdowns
	useEffect(() => {
		const getData = async () => {
			// Get accounts list
			await dispatch(getAccountsList());
			// Get brand list with account filter
			await dispatch(
				getBrandsList({
					accountUUID: accountFilters,
					pageSize: 200,
				})
			);
			// Get venue list with brand filter
			await dispatch(getVenuesList({ brandId: brandFilters }));

			if (activeReport?.id) {
				await dispatch(
					getPaginatedReport(activeReport.id, {
						pageNumber: parseFloat(query.pageNumber!) || 1,
					})
				);
			}
		};

		getData();

		return () => {
			// Reset venue, brand and operations states
			dispatch(resetBrandState());
			dispatch(resetVenueState());
		};
	}, [
		dispatch,
		accountFilters,
		brandFilters,
		activeReport,
		query.pageNumber,
	]);

	// Create report type options from enum
	// map to enum[index] then filter undefined, then map to IOption
	const reportSelectOptions: IOption[] = Object.keys(EReportTypes)
		.map((type, index) => EReportTypes[index])
		.filter((v) => !!v)
		.map((type, index) => ({
			value: EReportTypes[index],
			label: EReportTypes[index].replace(/([A-Z])/g, ' $1').trim(),
		}));

	// Get account options from state
	const accountOptions: IOption[] = useSelector(
		(state: RootState) => state.account.accounts
	).map((value: IAccount) => ({
		label: value.name,
		value: value.id,
	}));

	// Get brand options from state
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get venue options from state
	const venueOptions: IOption[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Initial form values
	const initialValues: IReportQueueFormValues = {
		reportType: 'transaction',
		startDate: new Date(),
		endDate: new Date(),
		formats: {
			json: false,
			csv: false,
			excel: false,
		},
		emails: [],
		accountIds: [],
		brandIds: [],
		venueIds: [],
	};

	// Handle form submission
	const handleSubmit = async (
		values: IReportQueueFormValues,
		{ setSubmitting }: FormikHelpers<IReportQueueFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		const formats = Object.entries(values.formats)
			.filter(([key, value]) => !!value)
			.map(([key, value]) => key);

		const submitValues: IReportQueueSubmitValues = {
			startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
			endDate: dayjs(values.endDate).format('YYYY-MM-DD'),
			formats,
			emails: values.emails
				.filter((email) => !!email.value)
				.map((email) => email.value!.toString()),
			accountIds:
				values?.accountIds
					.filter((account: IOption) => !!account.value)
					.map((account: IOption) => account.value!.toString()) || [],
			brandIds:
				values?.brandIds
					.filter((brnd: IOption) => !!brnd.value)
					.map((brnd: IOption) => brnd.value!.toString()) || [],
			venueIds:
				values?.venueIds
					.filter((venue: IOption) => !!venue.value)
					.map((venue: IOption) => venue.value!.toString()) || [],
		};

		// Reset operations state to remove previous reports
		dispatch(resetReportState());
		// Set loading message
		dispatch(
			setLoadingConfig({
				loadingMessage: intl.formatMessage({
					id: 'reports.loading.message',
				}),
				loadingTimeout: 60000,
			})
		);
		// Dispatch general loading event
		dispatch(addLoadingEvent());
		// send queue report
		dispatch(queueReport(values.reportType, submitValues));
		// Set formik submission state to false
		setSubmitting(false);
	};
	// Interface for select value change
	interface ISelectChangeValue {
		fieldValue?: { label: string; value: string }[];
		form: FormikProps<IReportQueueFormValues>;
	}

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{({ values, isSubmitting }) => (
				<StyledForm>
					<StyledSubtitle>
						<FormattedMessage id="reports.general.subtitle" />
					</StyledSubtitle>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={StyledSelect}
								testId="reportType-select"
								name="reportType"
								label={intl.formatMessage({
									id: 'form.fields.reportType.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.reportType.label',
								})}
								selectOptions={reportSelectOptions}
								value={reportSelectOptions.find(
									(report) =>
										report.value === values.reportType
								)}
							/>
							<StyledSubtitle>
								<FormattedMessage id="form.fields.fileFormats.label" />
							</StyledSubtitle>
							<StyledCheckboxGroup>
								<Field
									component={Checkbox}
									name="formats.json"
									label={intl.formatMessage({
										id: 'form.fields.formats.json.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="formats.csv"
									label={intl.formatMessage({
										id: 'form.fields.formats.csv.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="formats.excel"
									label={intl.formatMessage({
										id: 'form.fields.formats.excel.label',
									})}
								/>
							</StyledCheckboxGroup>
							<Field
								component={CreateableSelect}
								label={intl.formatMessage({
									id: 'form.fields.emails.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.emails.placeHolder',
								})}
								name="emails"
								value={values.emails}
							/>
							<Field
								component={DatePickerInput}
								name="startDate"
								label={intl.formatMessage({
									id: 'form.fields.dateFrom.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.dateFrom.label',
								})}
								maxDate={values.endDate}
								showTimeSelect={false}
							/>
							<Field
								component={DatePickerInput}
								name="endDate"
								label={intl.formatMessage({
									id: 'form.fields.dateTo.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.dateTo.label',
								})}
								minDate={values.startDate}
								maxDate={new Date()}
								showTimeSelect={false}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Select}
								isSearchable={true}
								label={intl.formatMessage({
									id: 'form.fields.account.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.account.label',
								})}
								handleChangeEvent={async (
									value: ISelectChangeValue
								) => {
									// Add accountId to filters
									setAccountFilters(
										value?.fieldValue?.map(
											(acc) => acc.value
										) || []
									);
								}}
								name="accountIds"
								isClearable={true}
								isMulti={true}
								selectOptions={accountOptions}
							/>
							<Field
								component={Select}
								isSearchable={true}
								label={intl.formatMessage({
									id: 'form.fields.brand.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.brand.label',
								})}
								handleChangeEvent={async (
									value: ISelectChangeValue
								) => {
									// Add brandId to filters
									setBrandFilters(
										value?.fieldValue?.map(
											(bra) => bra.value
										) || []
									);
								}}
								name="brandIds"
								isClearable={true}
								isMulti={true}
								selectOptions={brandOptions}
							/>
							<Field
								component={Select}
								isSearchable={true}
								label={intl.formatMessage({
									id: 'form.fields.venue.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.venue.label',
								})}
								name="venueIds"
								isClearable={true}
								isMulti={true}
								selectOptions={venueOptions}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					{activeReport?.id && <ReportResults />}
					<StyledActions>
						<Button
							ariaLabel="remove-button"
							disabled={!activeReport?.id}
							onClick={() => dispatch(resetReportState())}
						>
							<FormattedMessage id="reports.button.resetReport" />
						</Button>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.generateReport" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default ReportsForm;
