import { History } from 'history';
import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import BeaconImportForm from './beacon-import-form/beacon-import-form.component';
import RefundImportForm from './refund-import-form/refund-import-form.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { intl } from 'modules/core/i18n/i18n.config';
import withNav from 'modules/navigation/with-nav.component';

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

// Interface for component props
interface IComponentProps {
	history: History;
}

/** Renders operations page component */
const OperationsPage: FunctionComponent<IComponentProps> = ({ history }) => {
	// Get primary tab from url params
	const { primaryTab } = useParams();

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'refunds');

	return (
		<>
			<StyledHeader>
				<SectionHeading
					title="operations.title"
					subtitle="operations.subtitle"
				/>
			</StyledHeader>
			<TabList
				{...{ activeTab, setActiveTab }}
				navigateOnClick={true}
				history={history}
				parentUrl="/operations/"
				tabs={[
					{
						id: 'refunds',
						title: intl.formatMessage({
							id: 'operations.tabs.refunds.primary',
						}),
					},
					{
						id: 'beacons',
						title: intl.formatMessage({
							id: 'operations.tabs.beacons.primary',
						}),
					},
				]}
			/>
			<FormWrapper>
				{activeTab === 'refunds' && <RefundImportForm />}
				{activeTab === 'beacons' && <BeaconImportForm />}
			</FormWrapper>
		</>
	);
};

export default withNav(OperationsPage);
