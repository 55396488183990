import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { isIVenueImport, IVenueImport } from '../import.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	getZonalVenues,
	updateVenueImages,
	updateVenuePayment,
	updateVenuePublish,
	updateVenueBrand,
	updateVenueConfig,
	updateVenueName,
} from 'modules/import/import.slice';

const StyledForm = styled(Form)`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);

	h3 {
		margin: 0 0 5px 0;
		color: ${brand.validation_error};
	}
`;

const StyledErrors = styled.div`
	margin-bottom: 15px;
	font-weight: ${fonts.weights.medium};
	color: ${brand.validation_error};

	p {
		margin: 0 0 5px 0;
	}
`;

const StyledReady = styled.p`
	margin-bottom: 15px;
	font-weight: ${fonts.weights.medium};
	color: ${brand.validation_success};
`;

const StyledImagesWrap = styled.div`
	padding: 25px;
	border-radius: 10px 10px;
	display: flex;
	flex-direction: column;
	background: ${brand.white};
`;

const StyledImagesListWrap = styled.div`
	display: flex;
	flex-wrap: wrap;

	img {
		margin: 0 1px 1px 0;
	}
`;

/** Import venues component */
const ImportVenues: FunctionComponent = () => {
	const [venueErrors, setVenueErrors] = useState<string[]>([]);
	const [venuesReady, setVenuesReady] = useState<boolean>(false);
	const [logos, setLogos] = useState<string[]>([]);
	const [covers, setCovers] = useState<string[]>([]);

	const dispatch = useReduxDispatch();

	interface IFormValues {
		data: string;
		dry: boolean;
		brand: boolean;
		venueName: boolean;
		image: boolean;
		config: boolean;
		payment: boolean;
		publish: boolean;
	}

	// eslint-disable-next-line consistent-return
	const handleSubmitVenues = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Reset venues errors
		setVenueErrors([]);
		const errors: Array<string> = [];

		// Store for venue data
		let venues;

		// Check venues is valid JSON
		try {
			venues = JSON.parse(values.data);
		} catch {
			errors.push('JSON is invalid, please correct and resubmit.');
			setVenueErrors(errors);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		// Check we have venues
		if (venues.length === 0) {
			errors.push('No data found in form values.');
			setVenueErrors(errors);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		const isInvalid = venues
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.map((item: any) => {
				const itemIsValid = isIVenueImport(item);

				if (!itemIsValid) {
					errors.push(
						`An item (house id: ${item?.stonegateHouseId}) was not in the valid format.`
					);
					setVenueErrors(errors);
				}

				return itemIsValid;
			})
			.includes(false);

		if (isInvalid) {
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		if (values.image) {
			// Add logos to logoArray (so we can check they load)
			const logoArray: string[] = [];
			for (const item of venues) {
				if (item.logoUrl) {
					logoArray.push(item.logoUrl);
				}
			}
			setLogos(logoArray);

			// Add covers to coverArray (so we can check they load)
			const coverArray: string[] = [];
			for (const item of venues) {
				if (item.coverUrls && item.coverUrls.length) {
					coverArray.push(...item.coverUrls);
				}
			}
			setCovers(coverArray);
		}

		// Get zonal venues from API
		const zonalVenues = await dispatch(getZonalVenues());

		// Check each venue from JSON exists in database
		venues = venues.filter((venue: IVenueImport) => {
			// @ts-ignore
			const venueExists = zonalVenues.some((zonalVenue) => {
				// eslint-disable-next-line eqeqeq
				return zonalVenue.venueRef == `${venue.stonegateHouseId}`;
			});

			if (!venueExists) {
				errors.push(
					`A venue (${venue.venueName} - ${venue.stonegateHouseId}) did not exist in the database and will not be imported.`
				);
			}
			return venueExists;
		});
		if (errors.length) {
			setVenueErrors(errors);
		}

		// Format venue image data for import
		const importData = venues.map((venue: IVenueImport) => {
			// Match API venue to JSON venue
			// @ts-ignore
			const venueFromAPI = zonalVenues.filter((zonalVenue) => {
				// eslint-disable-next-line eqeqeq
				return zonalVenue.venueRef == `${venue.stonegateHouseId}`;
			})[0];

			return {
				venueId: venueFromAPI.orderPayId,
				logoUrl: venue.logoUrl,
				brandId: venue.brandId,
				venueName: venue.venueName,
				config: {
					allergenUrl: venue.allergenUrl,
				},
			};
		});
		if (!importData.length) {
			errors.push('No venues were selected for import.');
			setVenueErrors(errors);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		if (values.dry) {
			setVenuesReady(true);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		// Create import API requests
		for (const item of importData) {
			if (values.brand) {
				// Match venue to brand
				// eslint-disable-next-line no-await-in-loop
				const brandResponse = await dispatch(
					updateVenueBrand(item.venueId, item.brandId)
				);
				// eslint-disable-next-line no-console
				console.log('updateVenueBrand response: ', item, brandResponse);
			}

			if (values.venueName) {
				// Set pretty name for venue
				// eslint-disable-next-line no-await-in-loop
				const nameResponse = await dispatch(
					updateVenueName(item.venueId, item.venueName)
				);
				// eslint-disable-next-line no-console
				console.log('updateVenueName response: ', item, nameResponse);
			}

			if (values.image) {
				// Import venue images
				// eslint-disable-next-line no-await-in-loop
				const imageResponse = await dispatch(
					updateVenueImages(item.venueId, {
						logoUrl: item.logoUrl,
					})
				);
				// eslint-disable-next-line no-console
				console.log('updateVenueImage response: ', item, imageResponse);
			}

			if (values.config) {
				// eslint-disable-next-line no-await-in-loop
				const configResponse = await dispatch(
					updateVenueConfig(item.venueId, {
						allergen: {
							url: item.config.allergenUrl,
						},
					})
				);
				// eslint-disable-next-line no-console
				console.log(
					'updateVenueConfig response: ',
					item,
					configResponse
				);
			}

			if (values.payment) {
				// Set venue payment config
				// eslint-disable-next-line no-await-in-loop
				const paymentResponse = await dispatch(
					updateVenuePayment(item.venueId, {
						cccLocationId: '001006',
						orderpayPercentageFee: '1.95',
						visa: true,
						amex: true,
						mastercard: true,
						applepay: true,
						googlepay: false,
						visa_applepay: true,
						amex_applepay: true,
						mastercard_applepay: true,
						visa_googlepay: false,
						amex_googlepay: false,
						mastercard_googlepay: false,
						threeDsTwoEnabled: false,
					})
				);
				// eslint-disable-next-line no-console
				console.log(
					'updateVenuePayment response: ',
					item,
					paymentResponse
				);
			}

			if (values.publish) {
				// Publish venue
				// eslint-disable-next-line no-await-in-loop
				const publishedResponse = await dispatch(
					updateVenuePublish(item.venueId, true)
				);
				// eslint-disable-next-line no-console
				console.log(
					'updateVenuePublish response: ',
					item,
					publishedResponse
				);
			}
		}
		// Set formik submission state to false
		setSubmitting(false);

		return true;
	};

	return (
		<>
			<Formik
				initialValues={{
					data: '',
					dry: true,
					brand: false,
					venueName: false,
					image: false,
					config: false,
					payment: false,
					publish: false,
				}}
				onSubmit={handleSubmitVenues}
			>
				{({ isSubmitting }) => (
					<StyledForm>
						<StyledColumn>
							<h2>Venues</h2>
							<Field
								component={TextArea}
								name="data"
								label="Venue Data (JSON)"
								placeholder="Venue Data (JSON)"
							/>
							<Field
								component={Checkbox}
								name="dry"
								label="Dry run"
							/>
							{venuesReady ? (
								<StyledReady>
									Venues ready to import.
								</StyledReady>
							) : (
								''
							)}
							{venueErrors.length ? (
								<>
									<h3>
										Please correct the following errors:
									</h3>
									<StyledErrors>
										{venueErrors.map(
											(error: string, index) => (
												// eslint-disable-next-line react/no-array-index-key
												<p key={index}>{error}</p>
											)
										)}
									</StyledErrors>
								</>
							) : (
								''
							)}
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								Import Venue Data
							</Button>
						</StyledColumn>
						<StyledColumn>
							<h2>Import configuration</h2>
							<Field
								component={Checkbox}
								name="brand"
								label="Brand Matching"
							/>
							<Field
								component={Checkbox}
								name="venueName"
								label="Pretty name"
							/>
							<Field
								component={Checkbox}
								name="image"
								label="Image Matching"
							/>
							<Field
								component={Checkbox}
								name="config"
								label="Venue Config"
							/>
							<Field
								component={Checkbox}
								name="payment"
								label="Venue Payment Config"
							/>
							<Field
								component={Checkbox}
								name="publish"
								label="Publish Venue"
							/>
						</StyledColumn>
					</StyledForm>
				)}
			</Formik>
			<br />
			{logos.length || covers.length ? (
				<StyledImagesWrap>
					<h2>Images to import</h2>
					{logos.length ? <h3>Logos:</h3> : ''}
					{logos.length ? (
						<StyledImagesListWrap>
							{logos.map((logoUrl: string, index) => (
								<img
									src={logoUrl}
									alt=""
									// eslint-disable-next-line react/no-array-index-key
									key={index}
									width="50"
								/>
							))}
						</StyledImagesListWrap>
					) : (
						''
					)}
					<br />
					<br />
					{covers.length ? <h3>Covers:</h3> : ''}
					{covers.length ? (
						<StyledImagesListWrap>
							{covers.map((coverUrl: string, index) => (
								<img
									src={coverUrl}
									alt=""
									// eslint-disable-next-line react/no-array-index-key
									key={index}
									width="50"
								/>
							))}
						</StyledImagesListWrap>
					) : (
						''
					)}
				</StyledImagesWrap>
			) : (
				''
			)}
		</>
	);
};

export default ImportVenues;
