import { Form } from 'formik';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';

export const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

export const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;
