import { FieldProps } from 'formik';
import get from 'lodash.get';
import { rgba } from 'polished';
import React, {
	FunctionComponent,
	ChangeEvent,
	InputHTMLAttributes,
} from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';

interface IColourPickerProps {
	label?: string;
	handleChangeEvent?: Function;
}

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledInput = styled.input`
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding: 0 14px 0 43px;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	font-size: ${fonts.sizes.standard};

	&:focus {
		border-color: ${brand.link};
		outline: none;
	}

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}
`;

interface IStyledColourProps {
	colour: string;
}

const StyledWrapper = styled.div`
	position: relative;
`;

const StyledColour = styled.div<IStyledColourProps>`
	width: 30px;
	height: 30px;
	border: 1px solid ${brand.borders};
	border-radius: 6px;
	background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
		linear-gradient(-45deg, #ccc 25%, transparent 25%),
		linear-gradient(45deg, transparent 75%, #ccc 75%),
		linear-gradient(-45deg, transparent 75%, #ccc 75%);
	background-size: 10px 10px;
	background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
	${(props) => props.colour && `background: ${props.colour};`}
	content: '';
	position: absolute;
	bottom: 5px;
	left: 5px;
`;

/** Renders colour picker component */
const ColourPicker: FunctionComponent<
	IColourPickerProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({ field, form, label, placeholder, className, handleChangeEvent }) => {
	const { touched, errors } = form;
	const isTouched = get(touched, field.name) || false;
	const hasErrors = get(errors, field.name) || false;

	return (
		<FormElement className={className}>
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledWrapper>
				<StyledColour colour={field.value} />
				<StyledInput
					{...field}
					placeholder={placeholder}
					id={field.name}
					aria-label={`${field.name}-input`}
					className={isTouched && hasErrors ? 'has-error' : ''}
					type="text"
					data-testid="colour-picker"
					onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
						form.setFieldValue(
							field.name,
							changeEvent.target.value
						);
						if (handleChangeEvent) {
							handleChangeEvent(changeEvent.target.value);
						}
					}}
				/>
			</StyledWrapper>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</FormElement>
	);
};

export default ColourPicker;
