import { Field, FieldProps, FormikValues } from 'formik';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import SortableMiniList from '../sortable-mini-list/sortable-mini-list.component';
import {
	IItemSelect,
	IItemSelectGroup,
	isIItemSelectGroup,
} from './multi-select.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';
import ToolTip from 'components/tooltip/tooltip.component';

const StyledFormElement = styled(FormElement)`
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledWrapper = styled.div`
	width: 100%;
	height: 200px;
	overflow: auto;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
`;

const StyledLabel = styled.label`
	margin-bottom: 10px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledToggle = styled(Button)`
	width: 100%;
	padding: 14px 20px 14px 48px;
	border-bottom: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	position: relative;
	user-select: none;
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.light};
	color: ${brand.text};

	&:before {
		width: 14px;
		height: 14px;
		border-radius: 3px;
		border: 2px solid ${brand.borders};
		position: absolute;
		content: '';
		left: 20px;
	}

	&.mod-selected {
		background: rgba(0, 0, 0, 0.025);

		&:before {
			background: ${brand.primary};
			border-color: transparent;
		}
	}

	&:hover {
		background: rgba(0, 0, 0, 0.05);
	}
`;

const StyledGroup = styled.div`
	width: 100%;

	.sub-title {
		padding: 10px 20px 10px 20px;
		font-weight: ${fonts.weights.semibold};
	}
`;

interface IStyledItemProps {
	disabled?: boolean;
}
const StyledItem = styled.div<IStyledItemProps>`
	padding: 10px 20px 10px 48px;
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
	user-select: none;
	gap: 7px;

	&:before {
		width: 14px;
		height: 14px;
		border-radius: 3px;
		border: 2px solid ${brand.borders};
		position: absolute;
		content: '';
		left: 20px;
	}

	&.mod-selected {
		background: rgba(0, 0, 0, 0.025);

		&:before {
			background: ${brand.primary};
			border-color: transparent;
		}
	}

	&:not([disabled]):hover {
		background: rgba(0, 0, 0, 0.05);
	}

	&[disabled] {
		color: ${brand.placeholder};
		cursor: not-allowed;
		&:before {
			background: ${brand.formDisabled};
			border-color: ${brand.formDisabled};
		}
	}
`;

const StyledIcon = styled(Icon)`
	position: absolute;
	user-select: none;
	content: '';
	left: 20px;
`;

/* Renders a selectable item for the left hand side list */
const SelectableItem: FunctionComponent<{
	item: IItemSelect;
	selected: boolean;
	handleClick: () => void;
}> = ({ item, selected, handleClick }) => {
	return (
		<StyledItem
			disabled={item.disabled}
			className={selected ? 'mod-selected' : ''}
			key={item.id}
			onClick={() => handleClick()}
			data-testid={`multi-select-option[${item.id}]`}
		>
			{selected && (
				<StyledIcon
					name="checkbox"
					colour="white"
					width={18}
					height={18}
				/>
			)}

			<span>{item.title}</span>

			{item.disabled && item.disabledMessage && (
				<ToolTip
					title={item.title}
					description={item.disabledMessage}
				/>
			)}
		</StyledItem>
	);
};

interface IComponentProps {
	searchItems: IItemSelect[] | IItemSelectGroup[];
	labelSelect?: string;
	labelSearch?: string;
	sortable?: boolean;
	maxHeight?: number;
}

const MultiSelect: FunctionComponent<IComponentProps & FieldProps> = ({
	field,
	form,
	searchItems,
	labelSelect,
	labelSearch,
	sortable = true,
	maxHeight,
}) => {
	// Create local component state
	const [isGrouped, setIsGrouped] = useState<boolean>(false);
	const [items, setItems] = useState<IItemSelect[]>([]);
	const [groupedItems, setGroupedItems] = useState<IItemSelectGroup[]>([]);
	const [selectedItemsFromSearch, setSelectedItemsFromSearch] = useState<
		IItemSelect[]
	>([]);
	const [
		selectedItemsNotFromSearch,
		setSelectedItemsNotFromSearch,
	] = useState<IItemSelect[]>([]);

	/** When search items change */
	useEffect(() => {
		// If items are grouped
		if (isIItemSelectGroup(searchItems)) {
			// Set isGrouped in component state
			setIsGrouped(true);
			// Store grouped items
			setGroupedItems(searchItems);
			// Get items from groups
			setItems(
				searchItems.map((group: IItemSelectGroup) => group.items).flat()
			);

			return;
		}
		setIsGrouped(false);
		setItems(searchItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchItems]);

	/** When field value or items change */
	useEffect(() => {
		// Store selected items from search in state
		setSelectedItemsFromSearch(
			items.filter((item: IItemSelect) =>
				field.value.some(
					(selected: IItemSelect) => selected.id === item.id
				)
			)
		);
		// Store selected items *not* from search in state
		setSelectedItemsNotFromSearch(
			field.value.filter(
				(item: IItemSelect) =>
					!items.some(
						(selected: IItemSelect) => selected.id === item.id
					)
			)
		);
	}, [field.value, items]);

	/** Method for getting the selectable items. Callback rather than memo so it only runs when needed */
	const getSelectableItems = useCallback((): IItemSelect[] => {
		return items.filter(
			(item: IItemSelect) =>
				!item.disabled &&
				!field.value.some(
					(selected: IItemSelect) => selected.id === item.id
				)
		);
	}, [items, field]);

	/** Whether all items are selected */
	const allItemsSelected = useMemo<boolean>(() => {
		return (
			selectedItemsFromSearch.length ===
			items.filter((item) => !item.disabled).length
		);
	}, [selectedItemsFromSearch, items]);

	/** Add/remove item from baseSelected form values */
	const handleItemClick = (item: IItemSelect) => {
		if (item.disabled) {
			return;
		}

		form.setFieldTouched(field.name);

		if (
			field.value.some((selected: IItemSelect) => selected.id === item.id)
		) {
			form.setFieldValue(
				field.name,
				field.value.filter(
					(selected: IItemSelect) => selected.id !== item.id
				)
			);
			return;
		}

		form.setFieldValue(field.name, [...field.value, item]);
	};

	/** Determine if item is present in form values */
	const isItemSelected = (item: IItemSelect, values: FormikValues) =>
		!!field.value.find((selected: IItemSelect) => selected.id === item.id);

	/** Toggle search items selected/clear */
	const handleToggleAll = () => {
		form.setFieldTouched(field.name);

		// If all items are already selected
		if (allItemsSelected) {
			// Clear items from selection
			form.setFieldValue(field.name, selectedItemsNotFromSearch);
			return;
		}

		const selectableItems = getSelectableItems();

		form.setFieldValue(field.name, [...field.value, ...selectableItems]);
	};

	return (
		<StyledFormElement>
			<StyledColumn data-testid="multi-select-search">
				{labelSearch && (
					<StyledLabel htmlFor={field.name}>
						{labelSearch}
					</StyledLabel>
				)}
				<StyledWrapper>
					{items.length > 0 && (
						<StyledToggle
							variant="text"
							onClick={() => handleToggleAll()}
							className={allItemsSelected ? 'mod-selected' : ''}
							data-testid="multi-select-toggleAll"
						>
							{selectedItemsFromSearch.length ===
								items.length && (
								<StyledIcon
									name="checkbox"
									colour="white"
									width={18}
									height={18}
								/>
							)}
							{allItemsSelected ? (
								<FormattedMessage id="components.multiSelect.clear" />
							) : (
								<FormattedMessage id="components.multiSelect.select" />
							)}{' '}
							<FormattedMessage id="components.multiSelect.all" />
						</StyledToggle>
					)}
					{isGrouped ? (
						<>
							{groupedItems.map((group: IItemSelectGroup) => (
								<StyledGroup key={group.title}>
									<div className="sub-title">
										{group.title}
									</div>
									{group.items.map((item: IItemSelect) => (
										<SelectableItem
											selected={isItemSelected(
												item,
												form.values
											)}
											key={item.id}
											item={item}
											handleClick={() => {
												handleItemClick(item);
											}}
										/>
									))}
								</StyledGroup>
							))}
						</>
					) : (
						<div>
							{items.map((item: IItemSelect) => (
								<SelectableItem
									selected={isItemSelected(item, form.values)}
									key={item.id}
									item={item}
									handleClick={() => {
										handleItemClick(item);
									}}
								/>
							))}
						</div>
					)}
				</StyledWrapper>
			</StyledColumn>
			<StyledColumn data-testid="multi-select-select">
				{labelSelect && (
					<StyledLabel htmlFor={field.name}>
						{labelSelect}
					</StyledLabel>
				)}
				<Field
					component={SortableMiniList}
					sortable={sortable}
					name={field.name}
					removable={true}
					clearable={true}
					maxHeight={maxHeight}
				/>
			</StyledColumn>
		</StyledFormElement>
	);
};

export default MultiSelect;
