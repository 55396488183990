/* eslint-disable @typescript-eslint/indent */
import { Formik, Field, Form, FieldArray, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';

import {
	IAccountRoleFormValue,
	IBrandRoleFormValue,
	IStaffRoles,
	IStaffRolesFormValues,
	IVenueRoleFormValue,
} from '../../staff.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import PredictiveSearch from 'components/form-inputs/predictive-search/predictive-search.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Icon from 'components/icons/icon.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getAccountsList } from 'modules/account/account.slice';
import { IAccount } from 'modules/account/account.types';
import { getBrandsList } from 'modules/brand/brand.slice';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { getStaffRoles, updateStaffRoles } from 'modules/staff/staff.slice';
import { getVenuesList } from 'modules/venue/slices/venue.slice';
import { IVenueItem } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 0 45px;
`;

const StyledHeader = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledSelectWrap = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 80%;
`;

const StyledTitle = styled.div`
	h2 {
		margin: 0;
	}
`;

const StyledSubtitle = styled.p`
	margin: 10px 0 15px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledButton = styled(Button)`
	min-width: 120px;
	font-size: ${fonts.sizes.small};
`;

const StyledHeadings = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	padding: 0 14px 10px;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	user-select: none;

	&.mod-sort {
		cursor: pointer;
	}

	:focus {
		outline: none;
	}

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(25% - 110px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		padding-left: 0;
		text-align: center;
	}
`;

const StyledRoleItem = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	background: ${brand.neutral};
	border-radius: 10px;
	padding: 14px;
	margin: 0 0 5px;

	> * {
		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			min-width: 165px;
			margin-right: 52px;
			width: calc(25% - 110px);
		}

		&:last-child {
			width: 30px;
			min-width: 30px;
			margin: 0 0 0 auto;
			padding-left: 0;
			text-align: center;
		}
	}
`;

const StyledCheckbox = styled(Checkbox)`
	margin: 0;
`;

const StyledRemove = styled.div`
	outline: none;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IStaffRolesFormProps {
	staffId: string | undefined;
}

const StaffRolesForm: FunctionComponent<IStaffRolesFormProps> = ({
	staffId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active staff from store
	const activeStaffRoles: IStaffRoles | undefined = useSelector(
		(state: RootState) => state.staff.activeStaffRoles
	);

	// On component load
	useEffect(() => {
		const getData = async () => {
			staffId && (await dispatch(getStaffRoles(staffId)));
			// TODO: Correct page size to -1 when available
			await dispatch(getAccountsList());
			await dispatch(getBrandsList({ pageSize: 100 }));
		};

		getData();
	}, [dispatch, staffId]);

	// Get accounts from store and prepare for select
	const accountOptions: IOption[] = useSelector(
		(state: RootState) => state.account.accounts
	).map((value: IAccount) => ({
		label: value.name,
		value: value.id,
	}));

	// Get brand from store and prepare for select
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get venue from store and prepare for select
	const venueOptionsFromStore: IOption[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Cache the venues so the list will not get modified on search
	// look for venueLoadOptions below, where getVenuesList is executed and, thus, modifies the state
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const venueOptions = useMemo(() => venueOptionsFromStore, []);

	// Format account access for form
	const formattedAccountAccess: IAccountRoleFormValue[] =
		activeStaffRoles?.accounts?.map((accountAccess) => ({
			accountId: accountAccess.accountId,
			roles: {
				cmsAccess: accountAccess.roles.includes('CMS_ACCESS'),
				oplAccess: accountAccess.roles.includes('OP_ACCESS'),
			},
		})) || [];

	// Format brand access for form
	const formattedBrandAccess: IBrandRoleFormValue[] =
		activeStaffRoles?.brands?.map((brandAccess) => ({
			brandId: brandAccess.brandId,
			roles: {
				cmsAccess: brandAccess.roles.includes('CMS_ACCESS'),
				oplAccess: brandAccess.roles.includes('OP_ACCESS'),
			},
		})) || [];

	// Format venue access for form
	const formattedVenueAccess: IVenueRoleFormValue[] =
		activeStaffRoles?.venues?.map((venueAccess) => ({
			venueId: venueAccess.venueId,
			roles: {
				cmsAccess: venueAccess.roles.includes('CMS_ACCESS'),
				oplAccess: venueAccess.roles.includes('OP_ACCESS'),
			},
		})) || [];

	// Initial form values
	const initialValues: IStaffRolesFormValues = {
		selectValues: {
			account: '',
			brand: '',
			venue: '',
		},
		accounts: (staffId && formattedAccountAccess) || [],
		brands: (staffId && formattedBrandAccess) || [],
		venues: (staffId && formattedVenueAccess) || [],
	};

	// Handle form submission
	const handleSubmit = async (
		values: IStaffRolesFormValues,
		{ setSubmitting }: FormikHelpers<IStaffRolesFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Create variable for form values
		const formValues: IStaffRoles = {
			accounts: [],
			brands: [],
			venues: [],
		};

		// map accounts to corect submission type
		formValues.accounts = values.accounts?.map((accValues) => {
			const roles = [];
			accValues?.roles?.oplAccess && roles.push('OP_ACCESS');
			accValues?.roles?.cmsAccess && roles.push('CMS_ACCESS');

			return {
				accountId: accValues.accountId,
				roles,
			};
		});

		// map brands to corect submission type
		formValues.brands = values.brands?.map((brandValues) => {
			const roles = [];
			brandValues?.roles?.oplAccess && roles.push('OP_ACCESS');
			brandValues?.roles?.cmsAccess && roles.push('CMS_ACCESS');

			return {
				brandId: brandValues.brandId,
				roles,
			};
		});

		// map venues to corect submission type
		formValues.venues = values.venues?.map((venueValues) => {
			const roles = [];
			venueValues?.roles?.oplAccess && roles.push('OP_ACCESS');
			venueValues?.roles?.cmsAccess && roles.push('CMS_ACCESS');

			return {
				venueId: venueValues.venueId,
				roles,
			};
		});

		// Dispatch update redux thunk
		const response =
			staffId && (await dispatch(updateStaffRoles(staffId, formValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'staffForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, setFieldValue, isSubmitting }) => {
				// Function for react-select asnyc load options
				const venueLoadOptions = async (inputValue: string) => {
					// Get venue payload
					const venuesPayload = await dispatch(
						getVenuesList({ name: inputValue, pageSize: 100 })
					);

					// Return mapped venues
					return venuesPayload.venues
						.filter(
							(venue: IVenueItem) =>
								!values?.venues?.some(
									(valueVenue) =>
										valueVenue.venueId === venue.id
								)
						)
						.map((value: IVenueItem) => ({
							label: value.name,
							value: value.id,
						}));
				};

				return (
					<StyledForm>
						<Prompt when={dirty && !formSubmission} message="" />
						<StyledFormFields>
							<FieldArray
								name="accounts"
								render={(arrayHelpers) => (
									<>
										<StyledHeader>
											<StyledTitle>
												<h2>
													<FormattedMessage id="staffRoles.headings.accounts" />
												</h2>
												{(!values?.accounts ||
													values?.accounts?.length <
														1) && (
													<StyledSubtitle>
														<FormattedMessage id="staffRoles.accounts.roles.empty" />
													</StyledSubtitle>
												)}
											</StyledTitle>
											<StyledSelectWrap>
												<Field
													size="sm"
													testId="account-select"
													formElementVariant="header"
													defaultOptions={[]}
													component={Select}
													isSearchable={true}
													placeholder={intl.formatMessage(
														{
															id:
																'form.fields.accountSelect.label',
														}
													)}
													name="selectValues.account"
													isClearable={true}
													selectOptions={accountOptions.filter(
														(option) =>
															!values?.accounts?.some(
																(
																	accountValue
																) =>
																	accountValue.accountId ===
																	option.value
															)
													)}
													value={
														accountOptions.find(
															(accOpt) =>
																accOpt.value ===
																values
																	.selectValues
																	.account
														) || ''
													}
												/>
												<StyledButton
													onClick={() => {
														// If no accounts, do nothing
														if (
															!values.selectValues
																.account
														) {
															return;
														}
														// Push account to form array
														arrayHelpers.push({
															accountId:
																values
																	.selectValues
																	.account,
															roles: {
																cmsAccess: false,
																oplAccess: false,
															},
														});
														// empty field value
														setFieldValue(
															'selectValues.account',
															''
														);
													}}
													icon="plus"
													iconHeight={11}
													iconWidth={11}
													ariaLabel="account-add-button"
													disabled={
														!values.selectValues
															.account
													}
												>
													<FormattedMessage id="staffRoles.button.account.add" />
												</StyledButton>
											</StyledSelectWrap>
										</StyledHeader>
										{values.accounts &&
											values.accounts.length > 0 && (
												<>
													<StyledHeadings>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.accountName" />
														</StyledHeading>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.cmsAccess" />
														</StyledHeading>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.oplAccess" />
														</StyledHeading>
														<StyledHeading />
													</StyledHeadings>
													{values.accounts.map(
														(
															accountItem,
															index
														) => (
															<StyledRoleItem
																key={
																	accountItem.accountId
																}
															>
																<div>
																	{accountOptions &&
																		accountOptions.filter(
																			(
																				accountOpt
																			) =>
																				values?.accounts &&
																				accountOpt.value ===
																					accountItem.accountId
																		)[0]
																			?.label}
																</div>
																<Field
																	component={
																		StyledCheckbox
																	}
																	name={`accounts.${index}.roles.cmsAccess`}
																	uncheckedColour="white"
																	border={
																		true
																	}
																/>
																<Field
																	component={
																		StyledCheckbox
																	}
																	name={`accounts.${index}.roles.oplAccess`}
																	uncheckedColour="white"
																	border={
																		true
																	}
																/>
																<StyledRemove
																	onClick={() =>
																		arrayHelpers.remove(
																			index
																		)
																	}
																	onKeyPress={() =>
																		arrayHelpers.remove(
																			index
																		)
																	}
																	role="button"
																	tabIndex={0}
																>
																	<Icon
																		name="deleteCross"
																		width={
																			15
																		}
																		height={
																			15
																		}
																		colour="primary"
																	/>
																</StyledRemove>
															</StyledRoleItem>
														)
													)}
												</>
											)}
									</>
								)}
							/>
						</StyledFormFields>
						<StyledFormFields>
							<FieldArray
								name="brands"
								render={(arrayHelpers) => (
									<>
										<StyledHeader>
											<StyledTitle>
												<h2>
													<FormattedMessage id="staffRoles.headings.brands" />
												</h2>
												{(!values?.brands ||
													values?.brands?.length <
														1) && (
													<StyledSubtitle>
														<FormattedMessage id="staffRoles.brands.roles.empty" />
													</StyledSubtitle>
												)}
											</StyledTitle>
											<StyledSelectWrap>
												<Field
													size="sm"
													testId="brand-select"
													formElementVariant="header"
													component={Select}
													isSearchable={true}
													placeholder={intl.formatMessage(
														{
															id:
																'form.fields.brandSelect.label',
														}
													)}
													name="selectValues.brand"
													isClearable={true}
													selectOptions={brandOptions.filter(
														(option) =>
															!values?.brands?.some(
																(brandValue) =>
																	brandValue.brandId ===
																	option.value
															)
													)}
													value={
														brandOptions.find(
															(brandOpt) =>
																brandOpt.value ===
																values
																	.selectValues
																	.brand
														) || ''
													}
												/>
												<StyledButton
													onClick={() => {
														// if brand empty, do nothing
														if (
															!values.selectValues
																.brand
														) {
															return;
														}
														// push brand to form array
														arrayHelpers.push({
															brandId:
																values
																	.selectValues
																	.brand,
															roles: {
																cmsAccess: false,
																oplAccess: false,
															},
														});
														// empty field
														setFieldValue(
															'selectValues.brand',
															''
														);
													}}
													icon="plus"
													iconHeight={11}
													iconWidth={11}
													ariaLabel="brand-add-button"
													disabled={
														!values.selectValues
															.brand
													}
												>
													<FormattedMessage id="staffRoles.button.brand.add" />
												</StyledButton>
											</StyledSelectWrap>
										</StyledHeader>
										{values.brands &&
											values.brands.length > 0 && (
												<>
													<StyledHeadings>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.brandName" />
														</StyledHeading>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.cmsAccess" />
														</StyledHeading>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.oplAccess" />
														</StyledHeading>
														<StyledHeading />
													</StyledHeadings>
													{values.brands.map(
														(brandItem, index) => (
															<StyledRoleItem
																key={
																	brandItem.brandId
																}
															>
																<div>
																	{brandOptions &&
																		brandOptions.filter(
																			(
																				brandOpt
																			) =>
																				values?.brands &&
																				brandOpt.value ===
																					brandItem.brandId
																		)[0]
																			?.label}
																</div>
																<Field
																	component={
																		StyledCheckbox
																	}
																	name={`brands.${index}.roles.cmsAccess`}
																	uncheckedColour="white"
																	border={
																		true
																	}
																/>
																<Field
																	component={
																		StyledCheckbox
																	}
																	name={`brands.${index}.roles.oplAccess`}
																	uncheckedColour="white"
																	border={
																		true
																	}
																/>
																<StyledRemove
																	onClick={() =>
																		arrayHelpers.remove(
																			index
																		)
																	}
																	onKeyPress={() =>
																		arrayHelpers.remove(
																			index
																		)
																	}
																	role="button"
																	tabIndex={0}
																>
																	<Icon
																		name="deleteCross"
																		width={
																			15
																		}
																		height={
																			15
																		}
																		colour="primary"
																	/>
																</StyledRemove>
															</StyledRoleItem>
														)
													)}
												</>
											)}
									</>
								)}
							/>
						</StyledFormFields>
						<StyledFormFields>
							<FieldArray
								name="venues"
								render={(arrayHelpers) => (
									<>
										<StyledHeader>
											<StyledTitle>
												<h2>
													<FormattedMessage id="staffRoles.headings.venues" />
												</h2>
												{(!values?.venues ||
													values?.venues?.length <
														1) && (
													<StyledSubtitle>
														<FormattedMessage id="staffRoles.venues.roles.empty" />
													</StyledSubtitle>
												)}
											</StyledTitle>
											<StyledSelectWrap>
												<Field
													loadOptions={
														venueLoadOptions
													}
													size="sm"
													testId="venue-select"
													formElementVariant="header"
													component={PredictiveSearch}
													isSearchable={true}
													placeholder={intl.formatMessage(
														{
															id:
																'form.fields.venueSearch.label',
														}
													)}
													name="selectValues.venue"
													isClearable={true}
													value={
														venueOptions.find(
															(venueOpt) =>
																venueOpt.value ===
																values
																	.selectValues
																	.venue
														) || ''
													}
												/>
												<StyledButton
													onClick={() => {
														// if venue empty, do nothing
														if (
															!values.selectValues
																.venue
														) {
															return;
														}
														// add venue for form array
														arrayHelpers.push({
															venueId:
																values
																	.selectValues
																	.venue,
															roles: {
																cmsAccess: false,
																oplAccess: false,
															},
														});
														// clear field value
														setFieldValue(
															'selectValues.venue',
															''
														);
													}}
													icon="plus"
													iconHeight={11}
													iconWidth={11}
													ariaLabel="venue-add-button"
													disabled={
														!values.selectValues
															.venue
													}
												>
													<FormattedMessage id="staffRoles.button.venue.add" />
												</StyledButton>
											</StyledSelectWrap>
										</StyledHeader>
										{values.venues &&
											values.venues.length > 0 && (
												<>
													<StyledHeadings>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.venueName" />
														</StyledHeading>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.cmsAccess" />
														</StyledHeading>
														<StyledHeading>
															<FormattedMessage id="staffRoles.list.heading.oplAccess" />
														</StyledHeading>
														<StyledHeading />
													</StyledHeadings>
													{values.venues.map(
														(venueItem, index) => (
															<StyledRoleItem
																key={
																	venueItem.venueId
																}
															>
																<div>
																	{venueOptions &&
																		venueOptions.filter(
																			(
																				venueOpt
																			) =>
																				values?.venues &&
																				venueOpt.value ===
																					venueItem.venueId
																		)[0]
																			?.label}
																</div>
																<Field
																	component={
																		StyledCheckbox
																	}
																	name={`venues.${index}.roles.cmsAccess`}
																	uncheckedColour="white"
																	border={
																		true
																	}
																/>
																<Field
																	component={
																		StyledCheckbox
																	}
																	name={`venues.${index}.roles.oplAccess`}
																	uncheckedColour="white"
																	border={
																		true
																	}
																/>
																<StyledRemove
																	onClick={() =>
																		arrayHelpers.remove(
																			index
																		)
																	}
																	onKeyPress={() =>
																		arrayHelpers.remove(
																			index
																		)
																	}
																	role="button"
																	tabIndex={0}
																>
																	<Icon
																		name="deleteCross"
																		width={
																			15
																		}
																		height={
																			15
																		}
																		colour="primary"
																	/>
																</StyledRemove>
															</StyledRoleItem>
														)
													)}
												</>
											)}
									</>
								)}
							/>
						</StyledFormFields>
						<StyledActions>
							<div>
								<Link to="/staff">
									<Button variant="secondary">
										<FormattedMessage id="form.button.back" />
									</Button>
								</Link>
							</div>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="form.button.save" />
							</Button>
						</StyledActions>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export default StaffRolesForm;
