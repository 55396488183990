import { Formik, Form, Field, FormikProps } from 'formik';
import queryString from 'query-string';
import React, {
	useCallback,
	useEffect,
	FunctionComponent,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import { getMenuList, resetMenuState } from '../../menu.slice';
import { IMenuListFilters, IMenu } from '../../menu.types';
import MenuListItem from './menu-list-item/menu-list-item.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Pagination from 'components/pagination/pagination.component';
import SortIcons from 'components/sort-icons/sort-icons.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { IVenueItem } from 'modules/venue/venue.types';

// Interface for form values
interface ISearchFormValues {
	brand: string;
	venue: string;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

const StyledWrapper = styled.section`
	width: 100%;
	padding: 0 0 15px;
`;

const StyledActions = styled.div`
	padding: 0 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledForm = styled(Form)`
	display: flex;
`;

const StyledEndButtons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`;

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	width: 100%;
	max-width: 160px;

	input {
		height: 35px;
		padding: 0 23px 0 14px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	justify-content: space-between;
	padding: 0 15px;
`;

const StyledSortIcons = styled(SortIcons)`
	margin: 0 10px 0 0;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	padding-left: 5px;
	user-select: none;

	&.mod-sort {
		cursor: pointer;
	}

	:focus {
		outline: none;
	}

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(5) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(25% - 110px);
	}

	&:nth-child(4) {
		min-width: 220px;
		width: calc(25% - 110px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		padding-left: 0;
		text-align: center;
	}
`;

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const MenuList: FunctionComponent = () => {
	// initialise hooks
	const dispatch = useReduxDispatch();
	const location = useLocation();
	const history = useHistory();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	const [searchTerm, setSearchTerm] = useState<string>(query.search || '');

	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	// Get active account
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get venue options from state
	const menus = useSelector((state: RootState) => state.menu.menus || []);
	const pagination = useSelector((state: RootState) => state.menu.pagination);

	// Initial form values
	const initialValues: ISearchFormValues = {
		brand: query.brandId || '',
		venue: query.venueId || '',
	};

	// Get brand options from state
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get venue options from state
	const venueOption: IOption[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		label: value.name,
		value: value.id,
	}));

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	useEffect(() => {
		const getData = async () => {
			// Get filters from query params
			const menuFilters: IMenuListFilters = {
				brandId: query.brandId,
				venueId: query.venueId,
				search: query.search,
				sortOrder: query.sortOrder,
				sort: query.sort,
				pageNumber: parseFloat(query.pageNumber!) || 1,
			};
			if (activeAccountId) {
				// Get menu list with filters
				await dispatch(getMenuList(menuFilters));
			} else {
				await dispatch(resetMenuState());
			}
		};

		getData();

		// useEffect cleanup
		return () => {
			resetMenuState();
		};
	}, [
		dispatch,
		activeAccountId,
		query.search,
		query.brandId,
		query.venueId,
		query.sortOrder,
		query.sort,
		query.pageNumber,
	]);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	// Function to update sort query params
	const updateSort = (column: keyof IMenu) => {
		// sort order from query params
		const { sortOrder } = query;
		// variable to store sort direction
		let sortDir;

		if (sortOrder === 'ASC') {
			sortDir = 'DESC';
		} else if (sortOrder === 'DESC') {
			sortDir = '';
		} else {
			sortDir = 'ASC';
		}

		// Create list of filters
		const filters = {
			...query,
			sortOrder: sortDir,
			sort: column,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	// Function to filter menu and change route
	const filterMenus = ({
		brandId = query.brandId,
		venueId = query.venueId,
	}) => {
		// get current filters
		const filters = query;

		// add filters if available
		!!brandId && (filters.brandId = brandId);
		!!venueId && (filters.venueId = venueId);

		// remove page number on search
		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	// If no active account, tell the user to choose one
	const emptyMessage = activeAccountId ? (
		<FormattedMessage id="menuList.empty" />
	) : (
		<FormattedMessage id="menus.list.noAccount" />
	);

	return (
		<StyledWrapper>
			<StyledActions>
				<Formik
					initialValues={{ ...initialValues, searchTerm }}
					onSubmit={() => {}}
				>
					{() => {
						return (
							<StyledForm>
								<Field
									component={StyledInput}
									name="searchTerm"
									placeholder={intl.formatMessage({
										id: 'form.search.placeholder',
									})}
									icon="search"
									iconWidth={12}
									iconHeight={12}
									handleChangeEvent={(value: string) =>
										setSearchTerm(value)}
								/>
								<Field
									size="sm"
									testId="brand-select"
									isDisabled={!activeAccountId}
									value={brandOptions.find(
										(brandOpt) =>
											brandOpt.value === query.brandId
									)}
									formElementVariant="header"
									component={Select}
									handleChangeEvent={async (
										value: ISelectChangeValue
									) =>
										filterMenus({
											brandId: value.fieldValue || '',
										})}
									isSearchable={true}
									placeholder={intl.formatMessage({
										id: 'form.fields.brandSelect.label',
									})}
									name="brand"
									isClearable={true}
									selectOptions={brandOptions}
								/>
								<Field
									size="sm"
									testId="venue-select"
									isDisabled={!activeAccountId}
									value={venueOption.find(
										(venue) => venue.value === query.venueId
									)}
									formElementVariant="header"
									component={Select}
									handleChangeEvent={async (
										value: ISelectChangeValue
									) =>
										filterMenus({
											venueId: value.fieldValue || '',
										})}
									isSearchable={true}
									placeholder={intl.formatMessage({
										id: 'form.fields.venueSelect.label',
									})}
									name="venue"
									isClearable={true}
									selectOptions={venueOption}
								/>
							</StyledForm>
						);
					}}
				</Formik>
				{activeAccountId && (
					<StyledEndButtons>
						<Link
							to={`menus/create?activeAccount=${activeAccountId}`}
						>
							<Button icon="plus" iconWidth={11} iconHeight={11}>
								<FormattedMessage id="menuList.button.create" />
							</Button>
						</Link>
						<Link
							to={`menus/upload?activeAccount=${activeAccountId}`}
						>
							<Button icon="plus" iconWidth={11} iconHeight={11}>
								<FormattedMessage id="menuList.button.upload" />
							</Button>
						</Link>
					</StyledEndButtons>
				)}
			</StyledActions>
			<StyledHeadings>
				<StyledHeading
					className="mod-sort"
					onClick={() => updateSort('title')}
					onKeyPress={() => updateSort('title')}
					role="button"
					tabIndex={0}
				>
					<StyledSortIcons
						active={query.sort === 'title'}
						sort={query.sortOrder}
					/>
					<FormattedMessage id="menuList.headings.display" />
				</StyledHeading>
				<StyledHeading
					className="mod-sort"
					onClick={() => updateSort('reference')}
					onKeyPress={() => updateSort('reference')}
					role="button"
					tabIndex={0}
				>
					<StyledSortIcons
						active={query.sort === 'reference'}
						sort={query.sortOrder}
					/>
					<FormattedMessage id="menuList.headings.reference" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="menuList.headings.sortOrder" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="menuList.headings.serviceTypes" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="menuList.headings.published" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="menuList.headings.edit" />
				</StyledHeading>
			</StyledHeadings>
			<StyledList aria-label="venues-list">
				{menus?.length > 0
					? menus.map((item) => (
						<MenuListItem
							item={item}
							key={item.id}
							activeAccountId={activeAccountId}
						/>
					  ))
					: emptyMessage}
			</StyledList>
			{pagination && pagination.pageCount > 1 && (
				<Pagination
					pageNumber={
						query.pageNumber ? parseFloat(query.pageNumber) : 1
					}
					pageCount={pagination.pageCount}
					setPageNumber={handlePagination}
				/>
			)}
		</StyledWrapper>
	);
};

export default MenuList;
