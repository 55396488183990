import Uppy from '@uppy/core';
import { DashboardModal, useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { FieldProps } from 'formik';
import get from 'lodash.get';
import { rgba } from 'polished';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import FieldLabel from '../field-label/field-label.component';
import { IToolTip } from '../form-tooltip/form-tooltip.component';
import ImageManagementModal from './image-management-modal/image-management-modal.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';

// Interface for component props
interface IComponentProps {
	label?: string;
	fileLimit: number;
	uploadEndpoint: string;
	emptyMessage?: string;
	additionalData?: Record<string, unknown>;
	paddingTop?: string;
	isDisabled?: boolean;
	toolTip?: IToolTip;
}

interface IStyledComponentProps {
	isEmptyMessage: boolean;
	paddingTop?: string;
}

const StyledFormElement = styled(FormElement)`
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
`;

const StyledImgWrapper = styled.div<IStyledComponentProps>`
	background: ${brand.borders};
	width: 100%;
	padding-top: ${({ paddingTop }) => paddingTop || '100%'};
	position: relative;
	cursor: pointer;
	z-index: 2;
	border-radius: 20px;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		background: ${({ isEmptyMessage }) =>
		isEmptyMessage ? brand.body : rgba(brand.black, 0.4)};
		z-index: 1;
		border-radius: 20px;
	}
`;

const StyledCount = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	border-radius: 50%;
	background: ${brand.validation_error};
	color: ${brand.white};
	z-index: 1;
`;

const StyledImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	z-index: 0;
	border-radius: 20px;
`;

const StyledText = styled.div<IStyledComponentProps>`
	position: absolute;
	left: 12px;
	bottom: 12px;
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.small};
	font-weight: ${fonts.weights.medium};
	color: ${({ isEmptyMessage }) =>
		isEmptyMessage ? brand.black : brand.white};
	z-index: 2;
`;

const StyledEmptyMessage = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	font-size: ${fonts.sizes.large};
	font-weight: ${fonts.weights.light};
	color: ${rgba(brand.black, 0.4)};
	margin: 0 10%;
	z-index: 2;
`;

const StyledIcon = styled(Icon)`
	margin-right: 6.5px;
`;

/** Renders image upload component */
const ImageUpload: FunctionComponent<IComponentProps & FieldProps> = ({
	field,
	form,
	label,
	fileLimit = 1,
	meta,
	uploadEndpoint,
	emptyMessage,
	additionalData,
	paddingTop,
	isDisabled,
	toolTip,
}) => {
	const { touched, errors } = form;
	const isTouched = get(touched, field.name) || false;
	const hasErrors = get(errors, field.name) || false;
	const [isUppyOpen, setIsUppyOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Create uppy
	const uppy = useUppy(() =>
		new Uppy({
			restrictions: { maxNumberOfFiles: fileLimit },
			autoProceed: true,
			meta: additionalData,
		})
			.use(XHRUpload, {
				endpoint: uploadEndpoint,
				method: 'post',
				formData: true,
				fieldName: 'image',
				withCredentials: true,
			})
			.on('upload-success', (file, response) => {
				// Get url from response
				const { url } = response.body;
				// If field is array, add to array and set field value
				if (Array.isArray(field.value)) {
					const fieldValues = [...field.value];
					fieldValues.push(url);
					form.setFieldValue(field.name, fieldValues);
				} else {
					// Set field value
					form.setFieldValue(field.name, url);
				}
				// Close uppy
				setIsUppyOpen(false);
				// remove cuppy class from body
				document.body.classList.remove('uppy-Dashboard-isFixed');
			})
	);

	// if no file, show uppy else show image modal
	const handleImageClick = () => {
		!field.value || field.value.length < 1
			? setIsUppyOpen(true)
			: setIsModalOpen(true);
	};

	// set image to display based on if array
	const displayImage = Array.isArray(field.value)
		? field.value[0]
		: field.value;

	const isEmptyAndHasMessage = !!emptyMessage && !displayImage;

	return (
		<StyledFormElement isDisabled={isDisabled}>
			{label && (
				<FieldLabel
					fieldName={field.name}
					label={label}
					toolTip={toolTip}
					labelOnClick={handleImageClick}
				/>
			)}
			<StyledImgWrapper
				onClick={() => !isDisabled && handleImageClick()}
				data-testid="image-upload"
				isEmptyMessage={isEmptyAndHasMessage}
				paddingTop={paddingTop}
			>
				{isEmptyAndHasMessage && (
					<StyledEmptyMessage>{emptyMessage}</StyledEmptyMessage>
				)}
				{Array.isArray(field.value) && field.value.length > 0 && (
					<StyledCount>{field.value.length}</StyledCount>
				)}
				<StyledImage
					style={{ backgroundImage: `url(${displayImage})` }}
				/>
				<StyledText isEmptyMessage={isEmptyAndHasMessage}>
					<StyledIcon
						name="camera"
						width={20}
						height={15}
						colour={isEmptyAndHasMessage ? 'black' : 'white'}
					/>
					<FormattedMessage id="imageUpload.text" />
				</StyledText>
			</StyledImgWrapper>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
			<ImageManagementModal
				isModalOpen={isModalOpen}
				toggleModal={setIsModalOpen}
				toggleUpload={setIsUppyOpen}
				deleteImage={() => form.setFieldValue(field.name, '')}
				fieldValue={field.value}
				fieldName={field.name}
				label={label}
			/>
			<DashboardModal
				uppy={uppy}
				theme="light"
				open={isUppyOpen}
				onRequestClose={() => {
					setIsUppyOpen(false);
					document.body.classList.remove('uppy-Dashboard-isFixed');
				}}
				closeModalOnClickOutside={true}
				proudlyDisplayPoweredByUppy={false}
			/>
		</StyledFormElement>
	);
};

export default ImageUpload;
