import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import { IMenu, EServiceType } from 'modules/menu/menu.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&.mod-capitalise {
		text-transform: capitalize;
	}

	&:first-child {
		margin-left: 26px;
	}

	&:nth-child(2) {
		margin-right: 31px !important;
	}

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(5) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(25% - 110px);
	}

	&:nth-child(4) {
		min-width: 220px;
		width: calc(25% - 110px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		padding-left: 0;
		text-align: center;
	}
`;

// Props for component
interface IComponentProps {
	item: IMenu;
	activeAccountId: string;
}
// menu list item component
const MenuListItem: FunctionComponent<IComponentProps> = ({
	item,
	activeAccountId,
}) => {
	// Get list of service types via the enum and add space before capital letters
	const serviceTypes = item.serviceTypes?.map((type) =>
		EServiceType[type].replace(/([A-Z])/g, ' $1').trim()
	);

	return (
		<StyledListItem key={item.id} aria-label="menu-item">
			<StyledColumn>{item.title}</StyledColumn>
			<StyledColumn>{item.reference}</StyledColumn>
			<StyledColumn>{item.sortOrder}</StyledColumn>
			<StyledColumn className="mod-capitalise">
				{serviceTypes?.toString().replace(/,/g, ', ')}
			</StyledColumn>
			<StyledColumn>
				{item.isPublished ? (
					<FormattedMessage id="menuListItem.content.published" />
				) : (
					'-'
				)}
			</StyledColumn>
			<StyledColumn>
				<Link
					to={`/menus/menus/edit/${item.id}/details?activeAccount=${activeAccountId}`}
				>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledListItem>
	);
};

export default MenuListItem;
