import { configureStore } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import axiosMiddleware from 'redux-axios-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import httpClient, { httpMiddlewareConfig } from '../http/http.config';
import refreshMiddleware from './refresh.middleware';
import rootReducer from './root.reducer';

import { IOrder } from 'modules/order/order.types';
import { IStaff } from 'modules/staff/staff.types';

// Configure redux-persist
const persistConfig = {
	key: 'root',
	storage,
};

// Persist root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure redux store using redux toolkit
const store = configureStore({
	reducer: persistedReducer,
	middleware: [
		thunk,
		refreshMiddleware,
		axiosMiddleware(httpClient, httpMiddlewareConfig),
		LogRocket.reduxMiddleware({
			// Sanitise redux state
			stateSanitizer: (state) => ({
				...state,
				account: {
					...state.account,
					activeAccount: {
						...state.account.activeAccount,
						cccConfig: undefined,
						vatNo: undefined,
						zonalApiKey: undefined,
						zonalBundleIdentifier: undefined,
						zonalEstateId: undefined,
						zonalUserAgent: undefined,
					},
				},
				brand: {
					...state.brand,
					activeBrand: {
						...state.brand.activeBrand,
						cccConfig: undefined,
						vatNo: undefined,
						zonalApiKey: undefined,
						zonalBundleIdentifier: undefined,
						zonalEstateId: undefined,
						zonalUserAgent: undefined,
					},
				},
				venue: {
					...state.venue,
					activeVenueDetails: {
						...state.venue.activeVenueDetails,
						vatNo: undefined,
					},
					activeVenuePayment: {
						...state.venue.activeVenuePayment,
						cccLocationId: undefined,
					},
					activeVenueServiceConfig: {
						...state.venue.activeVenueServiceConfig,
						zonalSalesAreaId: undefined,
						zonalSiteId: undefined,
						zonalSiteName: undefined,
					},
				},
				order: {
					...state.order,
					activeOrder: {
						...state.order.activeOrder,
						customerEmail: undefined,
						customerName: undefined,
					},
					orders: state.order.orders?.map((order: IOrder) => ({
						...order,
						customerEmail: undefined,
						customerName: undefined,
					})),
				},
				staff: {
					...state.staff,
					activeStaffDetails: {
						...state.staff.activeStaffDetails,
						email: undefined,
						firstName: undefined,
						lastName: undefined,
					},
					staff: state.staff.staff?.map((staffMember: IStaff) => ({
						...staffMember,
						email: undefined,
						firstName: undefined,
						lastName: undefined,
					})),
				},
			}),
			// Sanitise redux actions
			actionSanitizer: (action) => {
				// Get action payload
				let { payload } = action;

				// Remove all API requests & responses from logging
				if (
					action.type.toLowerCase().indexOf('_SUCCESS') !== -1 ||
					action.type.toLowerCase().indexOf('CREATE_') !== -1 ||
					action.type.toLowerCase().indexOf('UPDATE_') !== -1
				) {
					payload = undefined;
				}

				// Return the modified action
				return { ...action, payload };
			},
		}),
	],
});

// Persist store
export const persistor = persistStore(store);

// If we're in development mode
if (process.env.NODE_ENV === 'development' && module.hot) {
	// Hot reload the root reducer
	module.hot.accept('./root.reducer', () => {
		// eslint-disable-next-line global-require
		const newRootReducer = require('./root.reducer').default;
		store.replaceReducer(newRootReducer);
	});
}

// Create a type for dispatches
export type AppDispatch = typeof store.dispatch;

export default store;
