import currency from 'currency.js';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { number, object } from 'yup';

import {
	getBrandGiftingConfig,
	updateBrandGiftingConfig,
	deleteBrand,
} from '../../brand.slice';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Input from 'components/form-inputs/input/input.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationNumber } from 'helpers/validation.helper';
import {
	IBrandGifting,
	IBrandGiftingFormValues,
} from 'modules/brand/brand.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledP = styled.p`
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
	margin: 0 0 35px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	brandId?: string;
	history: History;
}

// Brand gifting form page
const BrandGiftingForm: FunctionComponent<IComponentProps> = ({
	brandId,
	history,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active brand from store
	const activeBrandGifting = useSelector(
		(state: RootState) => state.brand.activeBrandGifting
	);

	useEffect(() => {
		// Get brand gifting config
		const getData = async (id: string | undefined) => {
			id && (await dispatch(getBrandGiftingConfig(id)));
		};
		getData(brandId);
	}, [brandId, dispatch]);

	// Initial form values
	const initialValues: IBrandGiftingFormValues = {
		defaultImage: (brandId && activeBrandGifting?.defaultImage) || '',
		tsAndCs: (brandId && activeBrandGifting?.tsAndCs) || '',
		maxAmountPence:
			(brandId &&
				activeBrandGifting?.maxAmountPence &&
				(activeBrandGifting?.maxAmountPence / 100).toString()) ||
			'',
		validityPeriodMonths:
			(brandId &&
				activeBrandGifting?.validityPeriodMonths &&
				activeBrandGifting?.validityPeriodMonths.toString()) ||
			'12',
		giftingEnabled:
			(brandId && activeBrandGifting?.giftingEnabled) || false,
	};

	// Handle form submission
	const handleSubmit = async (
		values: IBrandGiftingFormValues,
		{ setSubmitting }: FormikHelpers<IBrandGiftingFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Get form values and format max amount in penct
		const formValues: IBrandGifting = {
			...values,
			validityPeriodMonths: Number(values.validityPeriodMonths),
			maxAmountPence: currency(values.maxAmountPence).intValue,
		};

		// Disaptach updatee brand gifting config
		const response = await (brandId &&
			dispatch(updateBrandGiftingConfig(brandId, formValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	// Handle brand deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete brand
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete brand action
		brandId && (await dispatch(deleteBrand(brandId)));

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to brands page
		history.push('/brands');
	};

	//  form validation schema
	const formValidationSchema = object<IBrandGiftingFormValues>().shape({
		maxAmountPence: number().when(['giftingEnabled'], {
			is: (giftingEnabled) => !!giftingEnabled,
			then: genericValidationNumber({
				fieldName: 'maxAmountPence',
				min: 1,
				max: 999,
			}),
		}),
		validityPeriodMonths: number().when(['giftingEnabled'], {
			is: (giftingEnabled) => !!giftingEnabled,
			then: genericValidationNumber({
				fieldName: 'validityPeriodMonths',
				min: 1,
				max: 999,
			}),
		}),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="brandGiftingForm.headings.settings" />
					</h2>
					<StyledP>
						<FormattedMessage id="brandGiftingForm.copy.settings" />
					</StyledP>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								name="giftingEnabled"
								label={intl.formatMessage({
									id: 'form.fields.gifitingSwitch.label',
								})}
							/>
							<Field
								component={Input}
								type="number"
								min="1"
								step="1"
								name="validityPeriodMonths"
								label={intl.formatMessage({
									id:
										'form.fields.validityPeriodMonths.label',
								})}
								isDisabled={!values.giftingEnabled}
							/>
							<Field
								component={Input}
								inputSymbol="£"
								type="number"
								step="10"
								name="maxAmountPence"
								label={intl.formatMessage({
									id: 'form.fields.maxAmountPence.label',
								})}
								isDisabled={!values.giftingEnabled}
							/>
							<Field
								component={TextArea}
								name="tsAndCs"
								label={intl.formatMessage({
									id: 'form.fields.tsAndCs.label',
								})}
								isDisabled={!values.giftingEnabled}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={ImageUpload}
								name="defaultImage"
								paddingTop="47%"
								label={intl.formatMessage({
									id: 'form.fields.giftCardImage.label',
								})}
								additionalData={{
									entity: 'brand',
									id: brandId,
								}}
								uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/gift-card/image`}
								isDisabled={!values.giftingEnabled}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/brands">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{brandId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="brandForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default BrandGiftingForm;
