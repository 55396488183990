import Uppy from '@uppy/core';
import { DragDrop, useUppy } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { History } from 'history';
import queryString from 'query-string';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { IMenuUploadError } from '../menu.types';
import MenuUploadError from './menu-upload-error.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Switch from 'components/form-inputs/switch/switch.component';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import {
	addLoadingEvent,
	resetLoadingStates,
	setLoadingConfig,
} from 'components/loading/loading.slice';
import SectionHeading from 'components/section-heading/section-heading.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setActiveAccount } from 'modules/auth/auth.slice';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;
interface IFormValues {
	overwrite: boolean;
}

interface IPageProps {
	history: History;
}

const MenuUploadPage: FunctionComponent = () => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get location hook
	const location = useLocation();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	const [uploadError, setUploadError] = useState<IMenuUploadError | null>(
		null
	);
	const [filename, setFilename] = useState('Upload Spreadsheet');

	const initialValues: IFormValues = {
		overwrite: false,
	};

	// Create uppy
	const uppy = useUppy(() =>
		new Uppy({
			autoProceed: false,
			restrictions: {
				maxNumberOfFiles: 1,
				allowedFileTypes: [
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				],
			},
		})
			.use(XHRUpload, {
				endpoint: `${process.env.REACT_APP_API_BASE_URL}/menu/upload`,
				method: 'post',
				formData: true,
				fieldName: 'image',
				withCredentials: true,
				headers: {
					'account-id': activeAccountId,
				},
				timeout: 300000,
			})
			.on('upload-success', async () => {
				dispatch(
					addAlert({
						title: intl.formatMessage({
							id: 'alerts.success.title',
						}),
						message: intl.formatMessage({
							id: 'menuForm.alerts.uploaded.message',
						}),
						type: 'success',
					})
				);

				// Clear selected file
				uppy.reset();
				setFilename('Upload Spreadsheet');
				setUploadError(null);

				// Reset loading state
				await dispatch(resetLoadingStates());
			})
			.on('upload-error', async (file, err, response) => {
				await dispatch(
					addAlert({
						title: intl.formatMessage({
							id: 'alerts.failed.title',
						}),
						message: intl.formatMessage({
							id: 'menuForm.alerts.uploadFailed.message',
						}),
						type: 'error',
					})
				);

				// If valid error response, display it
				if (response && response.status === 400) {
					setUploadError(response.body as IMenuUploadError);
				} else {
					await fireDialog({
						title: intl.formatMessage({
							id: 'errors.exception.title',
						}),
						text: err.message,
						allowOutsideClick: false,
						allowEnterKey: false,
						allowEscapeKey: false,
						showConfirmButton: false,
					});
				}

				// Clear selected file
				uppy.reset();
				setFilename('Upload Spreadsheet');

				await dispatch(resetLoadingStates());
			})
			.on('file-added', (file) => {
				setFilename(file.name);
			})
	);

	useEffect(() => {
		if (query.activeAccount && query.activeAccount !== activeAccountId) {
			// Update active account id
			dispatch(setActiveAccount(query.activeAccount));
		}
	}, [dispatch, query.activeAccount, activeAccountId]);

	useEffect(() => {
		if (uppy) {
			uppy.getPlugin('XHRUpload')?.setOptions({
				headers: {
					'account-id': activeAccountId,
				},
			});
		}
	}, [uppy, activeAccountId]);

	const handleSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		setSubmitting(true);

		// Fire dialog if no file is selected
		if (uppy.getFiles().length === 0) {
			await fireDialog({
				title: intl.formatMessage({
					id: 'menuForm.upload.dialogs.noFile.title',
				}),
				text: intl.formatMessage({
					id: 'menuForm.upload.dialogs.noFile.text',
				}),
			});
			return;
		}

		// Set uppy upload rest method and start upload
		uppy.getPlugin('XHRUpload')?.setOptions({
			endpoint: `${process.env.REACT_APP_API_BASE_URL}/menu/upload/${
				values.overwrite ? 'upsert' : 'insert'
			}`,
		});
		uppy.upload();

		// Set upload message and trigger loading state
		await dispatch(
			setLoadingConfig({
				loadingMessage: intl.formatMessage({
					id: 'menuForm.upload.loading.message',
				}),
				loadingTimeout: 300000,
			})
		);
		await dispatch(addLoadingEvent());

		setSubmitting(false);
	};

	return (
		<FormWrapper>
			<StyledContentWrapper>
				<header>
					<SectionHeading
						title="menuForm.upload.title"
						subtitle="menuForm.subtitle"
					/>
				</header>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={handleSubmit}
				>
					{({ isSubmitting }) => (
						<StyledForm>
							<StyledFormFields>
								<StyledColumn>
									<Field
										component={Switch}
										name="overwrite"
										label={intl.formatMessage({
											id: 'form.fields.overwrite.label',
										})}
										placeholder={intl.formatMessage({
											id: 'form.fields.overwrite.label',
										})}
									/>
								</StyledColumn>
								<StyledColumn>
									<DragDrop
										uppy={uppy}
										locale={{
											strings: {
												dropHereOr:
													'Drop here or %{browse}',
												browse: 'click to browse',
											},
										}}
									/>
									{filename}
								</StyledColumn>
							</StyledFormFields>
							{uploadError && (
								<MenuUploadError uploadError={uploadError} />
							)}
							<StyledActions>
								<div>
									<Link
										to={`/menus/menus?activeAccount=${activeAccountId}`}
									>
										<Button variant="secondary">
											<FormattedMessage id="form.button.back" />
										</Button>
									</Link>
								</div>
								<Button
									type="submit"
									disabled={isSubmitting}
									ariaLabel="upload-button"
								>
									<FormattedMessage id="menuForm.button.upload" />
								</Button>
							</StyledActions>
						</StyledForm>
					)}
				</Formik>
			</StyledContentWrapper>
		</FormWrapper>
	);
};

export default withNav(MenuUploadPage);
