import { History } from 'history';
import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ImportAccounts from './accounts/import-accounts.component';
import ImportBrands from './brands/import-brands.component';
import ImportVenues from './venues/import-venues.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import TabList from 'components/tab-list/tab-list.component';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;

		h1,
		h3 {
			margin: 0;
		}
	}
`;

interface IComponentProps {
	history: History;
}

/** Import page component */
const ImportPage: FunctionComponent<IComponentProps> = ({ history }) => {
	// Get active tab from url params
	const { primaryTab } = useParams();

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'accounts');

	return (
		<StyledContentWrapper>
			<header>
				<h3>Manage Data</h3>
				<h1>Import</h1>
			</header>
			<TabList
				{...{ activeTab, setActiveTab }}
				navigateOnClick={true}
				history={history}
				parentUrl="/import/"
				tabs={[
					{
						id: 'accounts',
						title: 'Accounts',
					},
					{
						id: 'brands',
						title: 'Brands',
					},
					{
						id: 'venues',
						title: 'Venues',
					},
				]}
			/>
			<FormWrapper>
				{activeTab === 'accounts' && <ImportAccounts />}
				{activeTab === 'brands' && <ImportBrands />}
				{activeTab === 'venues' && <ImportVenues />}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(ImportPage);
