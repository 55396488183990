import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import * as yup from 'yup';

import {
	EntityType,
	ICreateMerchantFormValues,
	PaymentProvider,
} from '../payment.types';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationString } from 'helpers/validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { createMerchant } from 'modules/payment/payment.slice';

interface IComponentProps {
	entityId: string;
	entityType: EntityType;
}

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const CreateMerchantForm: FunctionComponent<IComponentProps> = ({
	entityId,
	entityType,
}) => {
	const dispatch = useReduxDispatch();
	const initialValues: ICreateMerchantFormValues = {
		provider: undefined,
		cccLocationId: '',
	};

	const formValidationSchema = yup.object({
		provider: genericValidationString({
			fieldName: 'paymentProvider',
		}).required(),

		cccLocationId: yup.string().when('provider', {
			is: (provider) => provider === PaymentProvider.ccc,
			then: yup.string().required(
				intl.formatMessage({
					id: 'errors.forms.validation.cccLocationId',
				})
			),
			otherwise: yup.string().optional(),
		}),
	});

	const handleSubmit = async (
		values: ICreateMerchantFormValues,
		{ setSubmitting }: FormikHelpers<ICreateMerchantFormValues>
	) => {
		setSubmitting(true);

		const provider = values.provider as PaymentProvider;
		const response = await dispatch(
			createMerchant(entityType, entityId, provider, values.cccLocationId)
		);

		if (!response) {
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'paymentConfigForm.alerts.created.message',
				}),
				type: 'success',
			})
		);

		setSubmitting(false);
	};

	const paymentProviderOptions: IOption[] = [
		{
			value: PaymentProvider.stripe,
			label: intl.formatMessage({
				id: 'form.fields.paymentProvider.values.stripe',
			}),
		},
		{
			value: PaymentProvider.ccc,
			label: intl.formatMessage({
				id: 'form.fields.paymentProvider.values.ccc',
			}),
		},
	];

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting, values, isValid }) => (
				<Form>
					<StyledColumn>
						<div data-testid="payment-provider">
							<Field
								component={Select}
								name="provider"
								selectOptions={paymentProviderOptions}
								label={intl.formatMessage({
									id: 'form.fields.paymentProvider.label',
								})}
							/>
						</div>
						{values.provider === PaymentProvider.ccc && (
							<Field
								component={Input}
								name="cccLocationId"
								label={intl.formatMessage({
									id: 'form.fields.cccLocationId.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.cccLocationId.label',
								})}
							/>
						)}
					</StyledColumn>
					<StyledActions>
						<Button
							type="submit"
							disabled={isSubmitting || !isValid}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="paymentConfigForm.buttons.create" />
						</Button>
					</StyledActions>
				</Form>
			)}
		</Formik>
	);
};

export default CreateMerchantForm;
