import { Field, Form, Formik, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string, boolean } from 'yup';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	deleteBrand,
	getBrand,
	updateBrandFeedback,
} from 'modules/brand/brand.slice';
import { IBrandFeedbackFormValues } from 'modules/brand/brand.types';
import formatFullBrand from 'modules/brand/helpers/format-full-brand.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	brandId?: string;
	history: History;
}

const BrandFeedbackForm: FunctionComponent<IComponentProps> = ({
	brandId,
	history,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	const activeBrand = useSelector(
		(state: RootState) =>
			state.brand.activeBrand && formatFullBrand(state.brand.activeBrand)
	);

	useEffect(() => {
		// Get brand details (contains feedback config)
		const getData = async (id: string | undefined) => {
			id && (await dispatch(getBrand(id)));
		};
		getData(brandId);
	}, [brandId, dispatch]);

	const intl = useIntl();

	const initialValues: IBrandFeedbackFormValues = {
		feedbackEnabled: (brandId && activeBrand?.feedbackEnabled) || false,
		feedbackEmail: (brandId && activeBrand?.feedbackEmail) || '',
	};

	// Handle form submission
	const handleSubmit = async (
		values: IBrandFeedbackFormValues,
		{ setSubmitting }: FormikHelpers<IBrandFeedbackFormValues>
	) => {
		if (!activeBrand) {
			fireDialog({
				title: intl.formatMessage({
					id: 'brandForm.dialogs.noActiveBrand.title',
				}),
				text: intl.formatMessage({
					id: 'brandForm.dialogs.noActiveBrand.text',
				}),
				icon: 'error',
			});
			return;
		}

		// Set formik submission state to true
		setSubmitting(true);

		// Dispatch update brand
		const response = await (brandId &&
			dispatch(updateBrandFeedback(brandId, values)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	// Handle brand deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete brand
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete brand action
		brandId && (await dispatch(deleteBrand(brandId)));

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to brands page
		history.push('/brands');
	};

	const formValidationSchema = object<IBrandFeedbackFormValues>().shape({
		feedbackEmail: string().email(validationMessages.email('email')),
		feedbackEnabled: boolean(),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="brandForm.headings.feedback" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								label={intl.formatMessage({
									id: 'form.fields.feedbackEnabled.label',
								})}
								name="feedbackEnabled"
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								label={intl.formatMessage({
									id: 'form.fields.feedbackEmail.label',
								})}
								name="feedbackEmail"
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/brands">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{brandId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="brandForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default BrandFeedbackForm;
