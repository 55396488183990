import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import SuggestedPairingDetailsForm from './suggested-pairing-details/suggested-pairing-details-form.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { getSuggestedPairingItem } from 'modules/menu/menu.slice';
import withNav from 'modules/navigation/with-nav.component';

const StyledHeader = styled.header`
	margin-bottom: 25px;
`;

interface IPageProps {
	history: History;
}

const SuggestedPairingFormPage: FunctionComponent<IPageProps> = ({
	history,
}) => {
	const dispatch = useReduxDispatch();

	const { pairingId }: { pairingId?: string } = useParams();

	const activeProductPairing = useSelector(
		(state: RootState) => state.menu.activeProductPairing
	);

	/** Get data for the form */
	const getFormData = async (id: string) => {
		await dispatch(getSuggestedPairingItem(id));
	};

	// On component load
	useEffect(() => {
		pairingId && getFormData(pairingId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<StyledHeader>
				<SectionHeading
					title={
						pairingId && activeProductPairing
							? activeProductPairing.name
							: intl.formatMessage({
								id: 'suggestedPairing.create.title',
							  })
					}
					subtitle={intl.formatMessage({
						id: 'suggestedPairing.subtitle',
					})}
					titleFormat={false}
					subtitleFormat={false}
				/>
			</StyledHeader>
			<FormWrapper>
				<SuggestedPairingDetailsForm
					pairingId={pairingId}
					activeProductPairing={activeProductPairing}
				/>
			</FormWrapper>
		</>
	);
};

export default withNav(SuggestedPairingFormPage);
