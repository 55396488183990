import MockAdapter from 'axios-mock-adapter/types';

import {
	IPaginatedReports,
	IReport,
	IReportData,
	IReportQueueSubmitValues,
} from './report.types';

/** MOCKED DATA */
const reportQueueRequest: IReportQueueSubmitValues = {
	startDate: '2019-12-31',
	endDate: '2020-12-31',
	formats: ['csv', 'excel', 'json'],
	emails: ['nick.hope@checkfer.com'],
};

const report: IReport = {
	id: '0553d991-8231-4b86-a50a-72c1a4fdb9cc',
	createdAt: '2020-12-17 12:04:11',
	updatedAt: '2020-12-17 12:04:11',
	generatedAt: null,
	urls: {
		csv: 'https://www.google.com',
		excel: 'https://www.google.com',
		json: 'https://www.google.com',
	},
	formats: ['csv', 'excel', 'json'],
	userId: 'aa1c45d7-d0e7-4aa1-b984-da2aaa5ea764',
	type: 'transaction',
	parameters: {
		startDate: '2019-12-11',
		endDate: '2020-12-31',
	},
	status: 0,
	emails: ['nick.hope@checkfer.com'],
};

const paginatedReportData: IReportData = {
	date: '12/01/2021',
	time: '11:01:20',
	type: 'Payment',
	card: 'AMEX',
	merchantId: '001006',
	account: 'OrderPay Clients',
	brand: 'Chopstix',
	venue: 'Chopstix OrderPay Office',
	site: 'Chopstix OrderPay Office',
	identifier: '5DB8E487-4428-489B-9167-94849F55930F',
	gross: 14.5,
	fee: 0.58,
	net: 13.92,
	grossTransactionVisaMastercard: 1,
	grossTransactionAmex: 1,
	grossTransactionCorportate: 1,
	grossTransactionOther: 0,
	grossTransactionSubtotal: 3,
	feesTransactionVisaMastercard: 1,
	feesTransactionAmex: 1,
	feesTransactionCorportate: 1,
	feesTransactionOther: 0,
	feesTransactionSubtotal: 3,
	netTransactionVisaMastercard: 1,
	netTransactionAmex: 1,
	netTransactionCorportate: 1,
	netTransactionOther: 0,
	netTransactionSubtotal: 3,
	grossTipsAmex: 1,
	grossTipsVisaMastercard: 1,
	grossTipsCorportate: 1,
	grossTipsOther: 0,
	grossTipsSubtotal: 3,
	feesTipsVisaMastercard: 1,
	feesTipsAmex: 1,
	feesTipsCorportate: 1,
	feesTipsOther: 0,
	feesTipsSubtotal: 3,
	netTipsVisaMastercard: 1,
	netTipsAmex: 1,
	netTipsCorportate: 1,
	netTipsOther: 0,
	netTipsSubtotal: 3,
	grossGiftsAmex: 1,
	grossGiftsVisaMastercard: 1,
	grossGiftsCorportate: 1,
	grossGiftsOther: 0,
	grossGiftsSubtotal: 3,
	feesGiftsVisaMastercard: 1,
	feesGiftsAmex: 1,
	feesGiftsCorportate: 1,
	feesGiftsOther: 0,
	feesGiftsSubtotal: 3,
	netGiftsVisaMastercard: 1,
	netGiftsAmex: 1,
	netGiftsCorportate: 1,
	netGiftsOther: 0,
	netGiftsSubtotal: 3,
	code: 'OPG78Y9Y99',
	created: '01/12/2020',
	expires: '01/01/2021',
	redeemed: '03/12/2020',
	amount: 50,
	amountRedeemed: 20,
	amountExpired: 30,
	amountBalance: 20,
};

const paginatedReport: IPaginatedReports = {
	pageCount: 1,
	pageSize: 1,
	pageNumber: 1,
	data: [paginatedReportData],
};

const getTransactionReportResponse = `"id","appUserId","orderId","cardId","total","status","cccTransactionId","cccDeviceId","venueId","brandId","accountId","source","createdAt","updatedAt","card","brandName","accountName","venueName","cardBrand"
"c46f4b86-9d8a-49ec-85fe-b7b3621a0e7c","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","cfa31cc4-491e-40e0-a9b6-2fdd0f574b61","225abb40-ac8e-4e4a-813e-64b1c51798e8","-415.00",2,"C00791A3-C183-4EE0-B1ED-8A16FCBDDE7D","2273d2ca-df66-434a-986c-5efb7b506df2","d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830","ZONAL_IMPORT","2020-07-03T12:42:28.484Z","2020-07-03T12:42:28.484Z",,"Stonegate staging",,"Test Site 9000003","amex"
"8af4b49e-3578-4fd2-bf76-bd19faeed4d0","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","cfa31cc4-491e-40e0-a9b6-2fdd0f574b61","225abb40-ac8e-4e4a-813e-64b1c51798e8","-415.00",2,"AEA7817E-5B85-4F94-9583-71133B5792E0","2273d2ca-df66-434a-986c-5efb7b506df2","d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830","ZONAL_IMPORT","2020-07-03T12:41:57.315Z","2020-07-03T12:41:57.315Z",,"Stonegate staging",,"Test Site 9000003","amex"
"523c290f-5ef0-487d-a563-e1680bb8e1ab","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","cfa31cc4-491e-40e0-a9b6-2fdd0f574b61","225abb40-ac8e-4e4a-813e-64b1c51798e8","415.00",0,"BAB6D2E3-C8CB-415B-AA42-AD56B32A8591","2273d2ca-df66-434a-986c-5efb7b506df2","d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830","PAYMENT_SERVICE","2020-07-03T12:40:04.301Z","2020-07-03T12:40:04.301Z",,"Stonegate staging",,"Test Site 9000003","amex"
"d1738ac7-6718-4e50-b67f-3d72bb8ae2ab","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","e472550c-d3a7-4821-ba75-a6f1402873e4","225abb40-ac8e-4e4a-813e-64b1c51798e8","-305.00",2,"A306D349-E0AE-4AA7-8F21-DCD725DF7492","28f32145-62ca-4a40-b7fb-be562ab152c9","d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830","ZONAL_IMPORT","2020-07-03T12:26:00.462Z","2020-07-03T12:26:00.462Z",,"Stonegate staging",,"Test Site 9000003","amex"
"160039ac-f35f-4781-a853-e1ab0aa6522e","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","e472550c-d3a7-4821-ba75-a6f1402873e4","225abb40-ac8e-4e4a-813e-64b1c51798e8","-305.00",2,"B516B0D2-3B50-46E9-AA4F-CC3F74EEF092","28f32145-62ca-4a40-b7fb-be562ab152c9","d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830","ZONAL_IMPORT","2020-07-03T12:07:11.500Z","2020-07-03T12:07:11.500Z",,"Stonegate staging",,"Test Site 9000003","amex"
"d808eac1-9a02-4b17-9212-d344a110e0f4","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","e472550c-d3a7-4821-ba75-a6f1402873e4","225abb40-ac8e-4e4a-813e-64b1c51798e8","-305.00",2,"779E7B03-93D2-4EFA-9F32-3FF1BCC35A3C","28f32145-62ca-4a40-b7fb-be562ab152c9","d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830","ZONAL_IMPORT","2020-07-03T11:56:58.039Z","2020-07-03T11:56:58.039Z",,"Stonegate staging",,"Test Site 9000003","amex"
"fba0e813-4f8b-4b3c-8925-5e4f356fdb9c","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","e472550c-d3a7-4821-ba75-a6f1402873e4","225abb40-ac8e-4e4a-813e-64b1c51798e8","-305.00",2,"1FB425E1-8C35-4B22-8C21-497C3218B90C","28f32145-62ca-4a40-b7fb-be562ab152c9","d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830","ZONAL_IMPORT","2020-07-03T11:46:38.548Z","2020-07-03T11:46:38.548Z",,"Stonegate staging",,"Test Site 9000003","amex"
"b05fb920-8fe4-45ca-bc0c-310fc1f69de0","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","a7bf37e7-e28b-430f-a533-5d6024eba67e",,"405.00",0,"FED9CADA-B4D1-439E-AB1E-2EBCBAE7A45C",,"f7145f36-73dc-4fe8-bc30-47744f777b86","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-30T09:55:37.063Z","2020-06-30T09:55:37.063Z",,"Stonegate staging",,"Test Site 9000002",
"087f4fca-ffbe-4c3d-afb6-fc7a7c16f5f1","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","e472550c-d3a7-4821-ba75-a6f1402873e4",,"305.00",0,"09B79FBB-1FB3-463C-ABDC-0C856D5328C6",,"d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-29T15:36:59.021Z","2020-06-29T15:36:59.021Z",,"Stonegate staging",,"Test Site 9000003",
"e3d64735-7b0a-4ef3-a46e-60affb11ab28","d878895b-2dc5-47a8-b8ec-469e055bac41","f07375b1-9210-457b-9c5d-bc17a0936d48",,"3.20",1,"3D48A251-FC7D-4A32-A9BF-93FA764E3009",,"d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T19:53:14.071Z","2020-06-26T19:53:14.071Z",,"Stonegate staging",,"Test Site 9000003",
"fd728855-f695-47c6-a82a-717ef9f476ca","d878895b-2dc5-47a8-b8ec-469e055bac41","78c4b1fb-06d2-4b68-a10e-03242062044b",,"4.05",1,"D0FD9995-AA91-452D-AB05-5BF8AA8F0960",,"f7145f36-73dc-4fe8-bc30-47744f777b86","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T19:45:46.154Z","2020-06-26T19:45:46.154Z",,"Stonegate staging",,"Test Site 9000002",
"cc16a735-18ba-4d66-a709-e03ca83b0282","d878895b-2dc5-47a8-b8ec-469e055bac41","23ebd866-829b-41a0-ba8e-148441339ea8",,"3.05",1,"63A52BF8-63A4-4911-B892-6C56A74182CE",,"d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T19:42:22.792Z","2020-06-26T19:42:22.792Z",,"Stonegate staging",,"Test Site 9000003",
"aadea42a-6fe7-4b4d-b535-0a26d3c39875","d878895b-2dc5-47a8-b8ec-469e055bac41","c5624424-b47f-4185-92a8-cb8b51a717c7",,"4.05",1,"A6619F2A-5AB7-4CBE-9C2D-2D5574639041",,"f7145f36-73dc-4fe8-bc30-47744f777b86","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T19:38:44.427Z","2020-06-26T19:38:44.427Z",,"Stonegate staging",,"Test Site 9000002",
"5a5ee4bc-d5ce-480e-8cc6-c2526e6db2b9","d878895b-2dc5-47a8-b8ec-469e055bac41","1a7719c4-b2d7-4f6e-a4e3-fbd42b00e851",,"4.90",1,"9ACEBF55-9C36-4044-8DF0-0E871A79C023",,"f7145f36-73dc-4fe8-bc30-47744f777b86","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T19:25:27.426Z","2020-06-26T19:25:27.426Z",,"Stonegate staging",,"Test Site 9000002",
"cf88318c-5197-4e07-97ba-5e33bbd71bc3","d878895b-2dc5-47a8-b8ec-469e055bac41","b6624d46-c33f-48f5-93ec-cace6f6f6f62",,"3.05",1,"2E547C54-7813-4B83-BB09-615C5CA6FC25",,"d0591933-2873-4aa4-8c70-8b5197794105","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T19:01:53.782Z","2020-06-26T19:01:53.782Z",,"Stonegate staging",,"Test Site 9000003",
"b68f5b3f-60bd-4b12-9b79-ea22fff68bea","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","af36db00-e491-454b-a756-f429bd22ca14",,"4.15",1,"7D3A5DA1-CCF5-4E14-BCF3-D16531262F84",,"f7145f36-73dc-4fe8-bc30-47744f777b86","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T14:56:30.823Z","2020-06-26T14:56:30.823Z",,"Stonegate staging",,"Test Site 9000002",
"32e6c6db-0537-4dad-a2c4-a26e6aff0565","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","a48a74b5-4055-4d66-9b6b-a2f42df514b5",,"24.89",1,"3B36CDC2-81B2-4173-A9E6-DF0A6B10F543",,"f7145f36-73dc-4fe8-bc30-47744f777b86","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T14:53:10.775Z","2020-06-26T14:53:10.775Z",,"Stonegate staging",,"Test Site 9000002",
"8f713c73-adb3-4404-b94f-d28194138dc1","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","07798c71-e80e-4ebe-80b7-7525e5c5362d",,"4.90",1,"81577336-BC84-4669-9D6E-C8385DC60807",,"f7145f36-73dc-4fe8-bc30-47744f777b86","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T14:53:08.614Z","2020-06-26T14:53:08.614Z",,"Stonegate staging",,"Test Site 9000002",
"8c3a3552-728b-4ee5-9c9c-4a678cb869de","2e2ddd8d-3b6f-44ec-b04d-54c2eee45abd","fa4fc0c7-576e-4f9b-bd77-2a906037263b","225abb40-ac8e-4e4a-813e-64b1c51798e8","4.90",1,"6CF341C5-9388-401E-BBA7-30F786641811",,"f7145f36-73dc-4fe8-bc30-47744f777b86","36a0cb2d-2697-4291-82c0-1aa5514f2236","412d5c6d-a6bc-4d1d-acab-c47a68eb4830",,"2020-06-26T13:42:23.813Z","2020-06-26T13:42:23.813Z",,"Stonegate staging",,"Test Site 9000002","amex"
"90d3b13f-aabf-4619-90f3-bdb309fc2ebc","b32b79ca-4fff-400e-82fc-48409034ed01","693dbc6d-406b-4397-944d-d81be4ca7088","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"9AE1C843-3D1F-46BF-B32F-519B4F3B35EE",,,,,,"2020-06-10T16:06:15.711Z","2020-06-10T16:06:15.711Z",,,,,"amex"
"3e3892e9-f8d4-4e50-9e5c-e96574641ea0","b32b79ca-4fff-400e-82fc-48409034ed01","49decf64-1a8e-4616-b6ab-42a35fbfeecd","2a440e56-c2a7-4b65-a5fe-59fa8f884671","2.70",0,"F597E4E3-F81D-404F-915C-CEBF3B4D61DD",,,,,,"2020-06-10T15:12:43.518Z","2020-06-10T15:12:43.518Z",,,,,"amex"
"5290145b-8581-4a9c-9ada-55c0057b6830","b32b79ca-4fff-400e-82fc-48409034ed01","fa28fecd-4cb7-469c-8d14-114702a32cfe","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"79497B04-0F4A-4BE6-94E0-8F3BC37DDFD1",,,,,,"2020-06-10T15:09:12.984Z","2020-06-10T15:09:12.984Z",,,,,"amex"
"3f164212-ce8b-4d3c-8cf7-063697fe2e0f","b32b79ca-4fff-400e-82fc-48409034ed01","489dbf13-b68e-4b56-a82b-2dffcf0771ae","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"FA635A85-DAA8-4191-8516-D6058BF79A58",,,,,,"2020-06-10T15:05:37.184Z","2020-06-10T15:05:37.184Z",,,,,"amex"
"c2cc955d-177a-4018-a817-d78b07e0eaaa","b32b79ca-4fff-400e-82fc-48409034ed01","373b108b-21a3-47c0-8efc-3e2018af1598","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"98251762-1739-47E9-8A41-C5CAA87E8D2D",,,,,,"2020-06-10T15:00:24.786Z","2020-06-10T15:00:24.786Z",,,,,"amex"
"9d272968-14ef-4f70-8a27-43b2056583bb","b32b79ca-4fff-400e-82fc-48409034ed01","92fe1e28-89de-4108-b2e0-287af8f351d1","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"65E55AFB-A4FF-4E95-8A31-E691AF2839F7",,,,,,"2020-06-10T14:57:31.004Z","2020-06-10T14:57:31.004Z",,,,,"amex"
"6132cb18-104a-454d-a34c-8ba5be186bba","b32b79ca-4fff-400e-82fc-48409034ed01","bbe7ec2d-2a3a-4d19-85cb-7703a7cfeb44","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"29F7FE64-73F2-4FAE-821E-051DE1E0409D",,,,,,"2020-06-10T14:52:08.768Z","2020-06-10T14:52:08.768Z",,,,,"amex"
"6a2a37ff-530d-4162-aca8-e69fa036b4f5","b32b79ca-4fff-400e-82fc-48409034ed01","9def0ab3-0118-41cd-b7ec-8d0c23f3e087","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"F3423F9C-CAA5-4F6A-8646-32BAB280BFBD",,,,,,"2020-06-10T14:51:54.820Z","2020-06-10T14:51:54.820Z",,,,,"amex"
"857faee6-6fb1-4f2c-a0c8-752193751955","b32b79ca-4fff-400e-82fc-48409034ed01","14ed8c3c-fd50-4dd3-a5d3-4bc061706cb5","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"4A613806-601A-4635-B825-9517B266D035",,,,,,"2020-06-10T14:51:40.951Z","2020-06-10T14:51:40.951Z",,,,,"amex"
"feadf014-19eb-47ab-8875-88bad90a6215","b32b79ca-4fff-400e-82fc-48409034ed01","619df0ce-1baa-49dd-8e98-03836e4f8376","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"9BF91180-A584-48BD-94A8-F5084A6E4CCC",,,,,,"2020-06-10T14:51:39.569Z","2020-06-10T14:51:39.569Z",,,,,"amex"
"6c750ff4-b351-4150-aeb4-0611736de7e0","b32b79ca-4fff-400e-82fc-48409034ed01","e142f6a5-0d61-41c4-85b7-09ebd921332b","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.35",0,"41CE8599-0BBC-4A3F-9C42-E8DC7B231C24",,,,,,"2020-06-10T14:30:20.652Z","2020-06-10T14:30:20.652Z",,,,,"amex"
"e13d0c7f-3ed8-4a03-afe7-855cd9bb6186","b32b79ca-4fff-400e-82fc-48409034ed01","bff02646-1388-4efc-ae66-43a685cf121f","2a440e56-c2a7-4b65-a5fe-59fa8f884671","6.30",0,"581FC2F3-7390-4BF1-B31C-81D7A4BAE4EB",,,,,,"2020-06-10T13:56:11.819Z","2020-06-10T13:56:11.819Z",,,,,"amex"
"cd6ad2b2-da13-4d8e-a2bd-4856bb57579a","b32b79ca-4fff-400e-82fc-48409034ed01","1b7226cc-b130-474c-8f33-154322de7ed3","2a440e56-c2a7-4b65-a5fe-59fa8f884671","6.30",0,"CF7CDF7D-631D-40E8-8F0C-0B8C62CA5F01",,,,,,"2020-06-10T13:53:18.910Z","2020-06-10T13:53:18.910Z",,,,,"amex"
"51307291-a173-4ffb-8845-05f04e7d274a","b32b79ca-4fff-400e-82fc-48409034ed01","70b897c9-d695-4957-8770-88a67aab0104","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"E58DC11E-8EEE-4B66-B17C-4E425B2BD051",,,,,,"2020-06-10T03:37:44.367Z","2020-06-10T03:37:44.367Z",,,,,"amex"
"ca10a7b0-98c2-4c2a-944d-3289366c9861","b32b79ca-4fff-400e-82fc-48409034ed01","b78c0de4-90d7-449f-87a0-f6289a5d2972","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"EF2C5EFC-36D9-4206-A613-E36BB8586317",,,,,,"2020-06-10T03:23:20.687Z","2020-06-10T03:23:20.687Z",,,,,"amex"
"c6e96162-9a3c-4555-8b33-4ca1fd41f8fd","b32b79ca-4fff-400e-82fc-48409034ed01","cac53ffc-6567-466b-a37a-5ef7a0a2987f","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"2AF155A4-D0EF-4D88-BD52-7BD3C3160A8C",,,,,,"2020-06-09T18:10:46.873Z","2020-06-09T18:10:46.873Z",,,,,"amex"
"4434f839-d143-4127-9a9c-506c2816de67","b32b79ca-4fff-400e-82fc-48409034ed01","7812ab88-d26b-4dc6-8e0b-bb059fd443de","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"A1C0A1E9-B5FE-4B33-B5CE-03DB7DE4E214",,,,,,"2020-06-09T18:02:20.160Z","2020-06-09T18:02:20.160Z",,,,,"amex"
"24a72127-2de2-4cd2-b9f8-41fb0c5dc09d","b32b79ca-4fff-400e-82fc-48409034ed01","d7fb7fec-5e0d-4156-b2ce-d2c605950f6b","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"9F8B4B30-1A2C-49A2-9F55-F60C865B8B8D",,,,,,"2020-06-09T17:58:56.732Z","2020-06-09T17:58:56.732Z",,,,,"amex"
"4bfd57a3-ad61-4d56-9fab-06627718a952","b32b79ca-4fff-400e-82fc-48409034ed01","4a289899-91d8-49c1-a996-568fa5525162","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"E37C902E-B319-4002-9134-28DBCB79E1CC",,,,,,"2020-06-09T17:53:01.291Z","2020-06-09T17:53:01.291Z",,,,,"amex"
"385ca854-79b6-4abf-a92c-3934f0e10060","b32b79ca-4fff-400e-82fc-48409034ed01","f61054b6-cd86-4d93-b3fe-d8cb5bbd0ab3","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"E72C9E22-E08C-42D7-B0A3-F1896CF5C6F7",,,,,,"2020-06-09T17:51:40.336Z","2020-06-09T17:51:40.336Z",,,,,"amex"
"7637fa68-5078-476d-bfda-ccf16f996f6f","b32b79ca-4fff-400e-82fc-48409034ed01","862f1039-fcc4-4443-91db-e25121b319b6","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"EFB922BE-ACDC-4386-8FB2-1C4F10130A77",,,,,,"2020-06-09T17:47:55.280Z","2020-06-09T17:47:55.280Z",,,,,"amex"
"3aca71f1-04ec-47ee-8e33-ce7bf4cf513e","b32b79ca-4fff-400e-82fc-48409034ed01","1183102c-b715-4098-8f59-6f9e0b41a9ac","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"5C433284-01A8-4506-9104-363ECAFE518C",,,,,,"2020-06-09T17:46:01.791Z","2020-06-09T17:46:01.791Z",,,,,"amex"
"d7e07cc3-2902-4bf6-b642-93b6f7d496c0","b32b79ca-4fff-400e-82fc-48409034ed01","048a16d0-8c77-4263-ba16-16f60dba592d","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"7C140509-57BB-4683-9D6A-9513A185958D",,,,,,"2020-06-09T17:44:41.174Z","2020-06-09T17:44:41.174Z",,,,,"amex"
"aaf0b988-fc00-47e3-9e7a-e442dc812a71","b32b79ca-4fff-400e-82fc-48409034ed01","83e2fc84-a0c0-4806-ab84-1f12c9671062","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"3C6E5851-E758-41CF-99B1-6F8EAABDCEAB",,,,,,"2020-06-09T17:40:50.315Z","2020-06-09T17:40:50.315Z",,,,,"amex"
"384d9c4b-38d5-4602-a643-a4c724f7676d","b32b79ca-4fff-400e-82fc-48409034ed01","9796179f-65d1-4b3a-94df-bef2efc1cd04","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"4F55FF43-F3C5-48C0-B318-0AF3421F1D9B",,,,,,"2020-06-09T17:40:14.034Z","2020-06-09T17:40:14.034Z",,,,,"amex"
"3cd82f8c-6acb-4a14-a8ee-1b40147fb027","b32b79ca-4fff-400e-82fc-48409034ed01","2f5b48ff-c918-478c-941b-54871fa5dc46","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"F3F9C914-871C-4DC0-AF7C-D7FC17872A1B",,,,,,"2020-06-09T17:37:20.170Z","2020-06-09T17:37:20.170Z",,,,,"amex"
"a2ebc28d-373c-4525-887f-4dea35c138b8","b32b79ca-4fff-400e-82fc-48409034ed01","5ad428fb-540e-4a50-a56c-9fce766553a5","2a440e56-c2a7-4b65-a5fe-59fa8f884671","1.66",0,"D4343A9A-99FE-41FF-BB4E-81185A9F435A",,,,,,"2020-06-09T17:33:50.811Z","2020-06-09T17:33:50.811Z",,,,,"amex"`;

/** MOCKED ENDPOINT CONFIG */

const getTransactionReport = {
	response: getTransactionReportResponse,
};

const getRemittanceReport = {
	response: '',
};

const getPaginatedReport = {
	response: paginatedReport,
};

const queueReport = {
	request: reportQueueRequest,
	response: report,
};
/** ENDPOINT REGEX */

// Regex for transaction report
export const transactionEndpointRegex = new RegExp(
	/payments\/transaction-report.*/
);

// Regex for remittance report
export const remittanceEndpointRegex = new RegExp(
	/payments\/remittance-report.*/
);

// Regex to queue report
export const queueReportEndpointRegex = new RegExp(
	/reports\/?(transaction|dailyVolume|giftSales|giftRedemptions|expiredGifts|giftAnalysis)/
);

// Regex for get report
export const getReportEndpointRegex = new RegExp(
	/reports\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for get paginated report
export const getPaginatedReportEndpointRegex = new RegExp(
	/reports\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/paginated/
);

/** MOCKED ENDPOINTS */

export const mockedReportEndpoints = (mockHttpClient: MockAdapter) => {
	// Get transaction report
	mockHttpClient
		.onGet(transactionEndpointRegex)
		.reply(200, getTransactionReport.response, {
			'content-type': 'text-csv',
		});

	// Get remittance report
	mockHttpClient
		.onGet(remittanceEndpointRegex)
		.reply(200, getRemittanceReport.response, {
			'content-type': 'text-csv',
		});

	// Queue report
	mockHttpClient
		.onGet(queueReportEndpointRegex)
		.reply(200, queueReport.response);

	// Get report
	mockHttpClient
		.onGet(getReportEndpointRegex)
		.reply(200, queueReport.response);

	// Get paginated report
	mockHttpClient
		.onGet(getPaginatedReportEndpointRegex)
		.reply(200, getPaginatedReport.response);
};

const mockReport = {
	data: { reportQueueRequest, report, paginatedReport, paginatedReportData },
	endpoints: {
		getTransactionReport,
		getPaginatedReport,
		getRemittanceReport,
		queueReport,
	},
};
export default mockReport;
