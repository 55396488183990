import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import { IBrandPriceBand } from 'modules/brand/brand.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-child(1) {
		min-width: 165px;
		width: calc(100% - 50px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		text-align: center;
		margin: 0;
	}
`;

// Props for component
interface IBrandPriceBandListItemProps {
	brandId: string;
	item: IBrandPriceBand;
}

// Brands list item component
const BrandPriceBandItem: FunctionComponent<IBrandPriceBandListItemProps> = ({
	brandId,
	item,
}) => {
	return (
		<StyledListItem aria-label="brand-price-band-item">
			<StyledColumn data-testid="brand-name">{item.name}</StyledColumn>
			<StyledColumn>
				<Link to={`/brands/${brandId}/price-bands/edit/${item.id}`}>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledListItem>
	);
};

export default BrandPriceBandItem;
