import { Field, Formik, Form, FormikProps } from 'formik';
import queryString from 'query-string';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import StaffList from './staff-list/staff-list.component';
import { IStaffFilters } from './staff.types';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getAccountsList } from 'modules/account/account.slice';
import { IAccount } from 'modules/account/account.types';
import { getBrandsList } from 'modules/brand/brand.slice';
import { IBrandItem, IBrandListFilters } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import { getVenuesList } from 'modules/venue/slices/venue.slice';
import { IVenueItem, IVenuesListFilters } from 'modules/venue/venue.types';

interface ISearchFormValues {
	searchTerm: string;
	brands: string;
	venues: string;
	accounts: string;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.sub-actions {
		display: flex;
		width: 50%;
		align-items: center;
		justify-content: flex-end;

		Button {
			margin: 0;
			flex-shrink: 0;
		}
	}
`;

const StyledForm = styled(Form)`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-end;
	padding: 0 10px 0 0;
`;

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	width: 100%;
	max-width: 160px;

	input {
		padding: 0 23px 0 14px;
		height: 35px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledLink = styled(Link)`
	flex-shrink: 0;
`;

const StyledButton = styled(Button)`
	font-size: ${fonts.sizes.small};
	flex-grow: 1;
	padding: 11px 18px;
`;

/** Renders staff page component */
const StaffPage: FunctionComponent = () => {
	// get hooks
	const dispatch = useReduxDispatch();
	const location = useLocation();
	const history = useHistory();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	const [searchTerm, setSearchTerm] = useState<string>(query.search || '');
	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	// Get account options from state
	const accountOptions: IOption[] = useSelector(
		(state: RootState) => state.account.accounts
	).map((value: IAccount) => ({
		label: value.name,
		value: value.id,
	}));

	// Get brand options from state
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get venue options from state
	const venueOptions: IOption[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Initial form values
	const initialValues: ISearchFormValues = {
		searchTerm,
		venues: query.venues || '',
		brands: query.brands || '',
		accounts: query.accounts || '',
	};

	// Function to filter staff list and change route
	const filterStaffList = ({
		brands = query.brands,
		venues = query.venues,
		accounts = query.accounts,
	}) => {
		// create menu filters
		const filters: IStaffFilters = {};

		// add filters if available
		!!brands && (filters.brands = brands);
		!!venues && (filters.venues = venues);
		!!accounts && (filters.accounts = accounts);

		// remove page number & search term on search
		delete filters.pageNumber;
		delete filters.search;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	// useEffect for handling search
	useEffect(() => {
		const getData = async () => {
			// Get filters from query params
			// TODO: page size when accounts list is paginated
			// const accountFilters: IStaffFilters = {
			// 	// pageSize: -1,
			// };

			// TODO: correct page size when endpoint accepts -1
			const brandFilters: IBrandListFilters = {
				accountUUID: query.accounts,
				pageSize: 100,
			};

			// TODO: correct page size when endpoint accepts -1
			const venueFilters: IVenuesListFilters = {
				accountId: query.accounts,
				brandId: query.brands,
				pageSize: 100,
			};

			await dispatch(getAccountsList());
			await dispatch(getBrandsList(brandFilters));
			await dispatch(getVenuesList(venueFilters));
		};

		getData();
	}, [dispatch, query.accounts, query.brands]);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	return (
		<>
			<StyledHeader>
				<SectionHeading title="staff.title" subtitle="staff.subtitle" />
				<div className="sub-actions">
					<Formik initialValues={initialValues} onSubmit={() => {}}>
						{({ values }) => {
							return (
								<StyledForm>
									<Field
										size="sm"
										testId="account-select"
										value={accountOptions.find(
											(accountOpt) =>
												accountOpt.value ===
												values.accounts
										)}
										formElementVariant="header"
										component={Select}
										handleChangeEvent={async (
											value: ISelectChangeValue
										) =>
											filterStaffList({
												accounts:
													value.fieldValue || '',
											})}
										isSearchable={true}
										placeholder={intl.formatMessage({
											id:
												'form.fields.accountSelect.label',
										})}
										name="accounts"
										isClearable={true}
										selectOptions={accountOptions}
									/>
									<Field
										size="sm"
										testId="brand-select"
										value={brandOptions.find(
											(brandOpt) =>
												brandOpt.value === values.brands
										)}
										formElementVariant="header"
										component={Select}
										handleChangeEvent={async (
											value: ISelectChangeValue
										) =>
											filterStaffList({
												brands: value.fieldValue || '',
											})}
										isSearchable={true}
										placeholder={intl.formatMessage({
											id: 'form.fields.brandSelect.label',
										})}
										name="brands"
										isClearable={true}
										selectOptions={brandOptions}
									/>
									<Field
										size="sm"
										testId="venue-select"
										value={venueOptions.find(
											(venueOpts) =>
												venueOpts.value ===
												values.accounts
										)}
										formElementVariant="header"
										component={Select}
										handleChangeEvent={async (
											value: ISelectChangeValue
										) =>
											filterStaffList({
												venues: value.fieldValue || '',
											})}
										isSearchable={true}
										placeholder={intl.formatMessage({
											id: 'form.fields.venueSelect.label',
										})}
										name="venues"
										isClearable={true}
										selectOptions={venueOptions}
									/>
									<Field
										component={StyledInput}
										name="searchTerm"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={12}
										iconHeight={12}
										handleChangeEvent={(value: string) =>
											setSearchTerm(value)}
									/>
								</StyledForm>
							);
						}}
					</Formik>
					<StyledLink to="/staff/create">
						<StyledButton
							icon="plus"
							iconWidth={11}
							iconHeight={11}
						>
							<FormattedMessage id="staff.button.create" />
						</StyledButton>
					</StyledLink>
				</div>
			</StyledHeader>
			<StaffList />
		</>
	);
};

export default withNav(StaffPage);
