import React, {
	FunctionComponent,
	useCallback,
	useState,
	useEffect,
	useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ValidationResults from './validation-results.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import TimeSince from 'components/time-since/time-since.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import useValidationFlowPusherEvent from 'modules/venue/hooks/use-validation-flow-pusher-event.hook';
import useValidationRuns from 'modules/venue/hooks/use-validation-runs.hooks';
import { startWorkflowRun } from 'modules/venue/slices/venue-menu-validation.slice';

const StyledStartButton = styled.div`
	margin: 20px 0;
	display: flex;
	gap: 15px;
	align-items: center;
`;

const StyledButton = styled(Button)`
	&:disabled {
		pointer-events: none;
	}
`;

const StyledInProgressMsg = styled.span`
	font-size: ${fonts.sizes.base};
	color: ${brand.validation_error};
`;

interface IComponentProps {
	venueId: string;
}

const VenueMenuValidation: FunctionComponent<IComponentProps> = ({
	venueId,
}) => {
	const dispatch = useReduxDispatch();

	// Id of the run that the current user has triggered
	const [currentRun, setCurrentRun] = useState<string>();

	// Most recent pusher event for validation results
	const validationPusherEvent = useValidationFlowPusherEvent(venueId);

	const { eventsInProgress } = useSelector(
		(state: RootState) => state.venueMenuValidation
	);

	// Gets the previous runs for the venue
	// Will trigger if venueId is changed
	// Can also be triggered by calling getRunsManually
	const {
		loading: previousRunsLoading,
		validationRuns,
		getValidationRuns,
	} = useValidationRuns(venueId);

	// Handle clicking on the "Start" button
	const handleClick = useCallback(async () => {
		const runDetails = await dispatch(startWorkflowRun(venueId));
		setCurrentRun(runDetails.id);
	}, [setCurrentRun, venueId, dispatch]);

	// Handle what happens when a pusher event is recieved
	useEffect(() => {
		if (
			!validationPusherEvent ||
			validationPusherEvent.data.venueId !== venueId
		)
			return;
		getValidationRuns();
	}, [validationPusherEvent, currentRun, venueId, getValidationRuns]);

	// Has a run been started in the last 20 minutes?
	const validationInProgress = useMemo(() => {
		return (
			validationRuns &&
			validationRuns[0]?.status === 'working' &&
			new Date(validationRuns[0]?.validationTestDate).getTime() >
				Date.now() - 20 * 60 * 1000
		);
	}, [validationRuns]);

	const buttonDisabled = Boolean(
		previousRunsLoading || eventsInProgress > 0 || validationInProgress
	);

	return (
		<>
			<h2>
				<FormattedMessage id="menuValidation.title" />
			</h2>

			<p>
				<FormattedMessage id="menuValidation.message.info" />
			</p>
			<p>
				<FormattedMessage id="menuValidation.message.time" />
			</p>

			<StyledStartButton>
				<StyledButton onClick={handleClick} disabled={buttonDisabled}>
					<FormattedMessage id="menuValidation.action.start" />
				</StyledButton>
				{validationInProgress && (
					<StyledInProgressMsg>
						<FormattedMessage id="menuValidation.message.inProgress" />
						{validationRuns && validationRuns[0] && (
							<>
								{' - '}
								Started{' '}
								<TimeSince
									time={validationRuns[0].validationTestDate}
								/>
							</>
						)}
					</StyledInProgressMsg>
				)}
			</StyledStartButton>

			<ValidationResults runs={validationRuns} />
		</>
	);
};

export default VenueMenuValidation;
