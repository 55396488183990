import currency from 'currency.js';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { number, object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Radio from 'components/form-inputs/radio/radio.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationNumber } from 'helpers/validation.helper';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueServiceServiceChargeConfig,
	updateVenueServiceServiceChargeConfig,
} from 'modules/venue/slices/venue.slice';
import {
	EServiceChargeType,
	IVenueServiceServiceChargeConfigFormValues,
} from 'modules/venue/venue.types';

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	&.flex-start {
		justify-content: flex-start;
	}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledRadioWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	border-bottom: 1px solid ${brand.body};
	margin: 0 0 25px;
`;

const StyledRadio = styled(Radio)`
	min-width: 145px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	activeService: string;
	handleDelete: Function;
	deleteServiceButtonText: string;
	formSubmission: boolean;
	setFormSubmission: Function;
	venueId: string;
}

const VenueServiceConfigServiceChargeForm: FunctionComponent<IComponentProps> = ({
	handleDelete,
	deleteServiceButtonText,
	formSubmission,
	setFormSubmission,
	venueId,
	activeService,
}) => {
	const dispatch = useReduxDispatch();

	// Get active service charge config from state
	const serviceChargeConfig = useSelector(
		(state: RootState) =>
			state.venue.activeVenueServiceConfig?.serviceCharge
	);

	useEffect(() => {
		const getData = async (id: string) => {
			await dispatch(
				getVenueServiceServiceChargeConfig(id, activeService)
			);
		};

		venueId && getData(venueId);
	}, [dispatch, venueId, activeService]);

	// Set initial service charge value (either percentage or pounds)
	const initialValue =
		serviceChargeConfig?.type === EServiceChargeType.amount
			? currency(serviceChargeConfig?.value, {
				fromCents: true,
				precision: 2,
			  })
				.toJSON()
				.toFixed(2)
			: Number(serviceChargeConfig?.value || 0);

	const initialValues: IVenueServiceServiceChargeConfigFormValues = {
		enabled: serviceChargeConfig?.enabled || false,
		name: serviceChargeConfig?.name || '',
		type: serviceChargeConfig?.type || EServiceChargeType.percentage,
		value: initialValue.toString() || '12.50',
		editable: serviceChargeConfig?.editable || false,
		appMessage: serviceChargeConfig?.appMessage || '',
		emailMessage: serviceChargeConfig?.emailMessage || '',
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenueServiceServiceChargeConfigFormValues,
		{
			setSubmitting,
		}: FormikHelpers<IVenueServiceServiceChargeConfigFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// get value for service charge
		let serviceChargeValue = Number(values.value);

		// If service charge type is amount, convert to pence
		if (values.type === EServiceChargeType.amount) {
			serviceChargeValue = currency(serviceChargeValue).intValue;
		}

		// Update venue service config
		const response = await dispatch(
			updateVenueServiceServiceChargeConfig(venueId, activeService, {
				...values,
				value: serviceChargeValue,
			})
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	// Validation schema
	const formValidationSchema = object<
		IVenueServiceServiceChargeConfigFormValues
	>().shape({
		appMessage: string().when('enabled', {
			is: (value) => !!value,
			then: string().required(validationMessages.required('appMessage')),
		}),
		value: number()
			.test(
				'is-two-decimal-place',
				intl.formatMessage(
					{ id: 'errors.forms.validation.maxDecimalPlaces' },
					{
						field: intl.formatMessage({
							id: 'form.fields.serviceChargeValue.label',
						}),
						places: 2,
					}
				),
				(value) => value?.toString().match(/^\d{1,3}(\.?\d{0,2})?$/)
			)
			.test(
				'is-multiple-of-0.5',
				intl.formatMessage(
					{ id: 'errors.forms.validation.multipleOf' },
					{
						field: intl.formatMessage({
							id: 'form.fields.serviceChargeValue.label',
						}),
						multiple: 0.5,
					}
				),
				(value) => value % 0.5 === 0
			)
			.when('type', {
				is: (value) => value === EServiceChargeType.amount,
				then: genericValidationNumber({
					fieldName: 'serviceChargeValue',
					min: 0,
					max: 999,
				}),
			})
			.when('type', {
				is: (value) => value === EServiceChargeType.percentage,
				then: genericValidationNumber({
					fieldName: 'serviceChargeValue',
					min: 0,
					max: 100,
				}),
			}),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting, values }) => (
				<Form>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="venueForm.services.serviceCharge.title" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								name="enabled"
								label={intl.formatMessage({
									id: 'form.fields.serviceCharge.label',
								})}
								borderBottom
							/>
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.serviceChargeName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.serviceChargeName.label',
								})}
								borderBottom
							/>
							<StyledLabel>
								<FormattedMessage id="form.fields.serviceChargeType.label" />
							</StyledLabel>
							<StyledRadioWrapper>
								<Field
									component={StyledRadio}
									name="type"
									id="type-amount"
									value={EServiceChargeType.amount}
									label={intl.formatMessage({
										id: 'form.fields.fixedAmount.label',
									})}
								/>
								<Field
									component={StyledRadio}
									name="type"
									id="type-percentage"
									value={EServiceChargeType.percentage}
									label={intl.formatMessage({
										id: 'form.fields.percentage.label',
									})}
								/>
							</StyledRadioWrapper>
							<Field
								inputSymbol={`${
									values.type === 'amount' ? '£' : '%'
								}`}
								component={Input}
								name="value"
								label={intl.formatMessage({
									id: 'form.fields.serviceChargeValue.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.serviceChargeValue.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<StyledLabel>
								<FormattedMessage id="form.fields.serviceChargeEditable.label" />
							</StyledLabel>
							<StyledRadioWrapper>
								<Field
									component={StyledRadio}
									name="editable"
									id="editable-0"
									value={true}
									label={intl.formatMessage({
										id: 'form.fields.editable.label.yes',
									})}
								/>
								<Field
									component={StyledRadio}
									name="editable"
									id="editable-1"
									value={false}
									label={intl.formatMessage({
										id: 'form.fields.editable.label.no',
									})}
								/>
							</StyledRadioWrapper>
							<Field
								component={TextArea}
								name="appMessage"
								label={intl.formatMessage({
									id: 'form.fields.appMessage.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.appMessage.placeholder',
								})}
								borderBottom
							/>
							<Field
								component={TextArea}
								name="emailMessage"
								label={intl.formatMessage({
									id: 'form.fields.emailMessage.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.emailMessage.placeholder',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							<Button
								variant="secondary"
								onClick={() => handleDelete()}
								ariaLabel="delete-button"
							>
								{deleteServiceButtonText}
							</Button>
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</Form>
			)}
		</Formik>
	);
};

export default VenueServiceConfigServiceChargeForm;
