import Pusher, { Channel } from 'pusher-js';
import React, { createContext, FunctionComponent } from 'react';

interface IPusherContext {
	pusher?: Pusher;
	userChannel?: Channel;
}
export const PusherContext = createContext<IPusherContext>({
	pusher: undefined,
	userChannel: undefined,
});

// Seperate interface to ensure loosen coupling
interface IComponentProps extends IPusherContext {}

/**
 * Provides access to pusher instance elsewhere in the application using the context API
 */
const PusherContextProvider: FunctionComponent<IComponentProps> = ({
	children,
	pusher,
	userChannel,
}) => {
	return (
		<PusherContext.Provider value={{ pusher, userChannel }}>
			{children}
		</PusherContext.Provider>
	);
};

export default PusherContextProvider;
