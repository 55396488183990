import { createIntlCache, createIntl } from 'react-intl';

import localeEN from './locale-en.json';

// Define locale config
const i18nConfig = {
	locale: 'en',
	messages: localeEN,
};

// Create a cache for react-intl plugin
const cache = createIntlCache();

// Create instance of react-intl + export it
export const intl = createIntl(i18nConfig, cache);

export default i18nConfig;
