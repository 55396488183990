import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Button from 'components/button/button.component';

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

export interface IBrandMarketingFooterProps {
	brandId?: string;
	handleDelete: () => {};
	disabled: boolean;
}

const BrandMarketingFooter: FunctionComponent<IBrandMarketingFooterProps> = ({
	brandId,
	handleDelete,
	disabled,
}) => {
	return (
		<StyledActions>
			{brandId && (
				<Button
					variant="outline"
					onClick={handleDelete}
					ariaLabel="delete-button"
				>
					<FormattedMessage id="brandForm.button.delete" />
				</Button>
			)}

			<div>
				<Link to="/brands">
					<Button variant="outline">
						<FormattedMessage id="form.button.back" />
					</Button>
				</Link>
				<Button
					type="submit"
					disabled={disabled}
					ariaLabel="submit-button"
				>
					<FormattedMessage id="brandForm.button.save" />
				</Button>
			</div>
		</StyledActions>
	);
};

export default BrandMarketingFooter;
