import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';

// Interface for component props
interface ILogoBlockProps {
	variant?: string;
	showTitle?: boolean;
}

const loginStyling = css`
	width: 356px;
	padding: 30px 22px;
	color: ${brand.black};
	font-size: ${fonts.sizes.h1};
	font-weight: ${fonts.weights.semibold};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	svg {
		width: 40px;
		height: 40px;
		margin-right: 20px;
	}

	.sub-title {
		display: block;
	}
`;

const titleVisibleCss = css`
	font-size: ${fonts.sizes.h2};
	width: 100%;
	max-width: 150px;
	overflow: visible;
	opacity: 1;
	margin-left: 15px;
`;

const navStyling = (showTitle: boolean) => css`
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: ${fonts.weights.semibold};
	background: ${brand.black};
	color: ${brand.white};
	border-radius: 0 10px 0 0;
	overflow: hidden;

	.sub-title {
		width: 0;
		overflow: hidden;
		opacity: 0;
		transition: all 0.2s ease-in;
		font-size: 0;

		${showTitle && titleVisibleCss};
	}

	svg {
		width: 31px;
		height: 31px;
	}
`;

// Create styled logo block component
const StyledLogoBlock = styled.div<ILogoBlockProps>`
	${({ variant, showTitle }) =>
		variant === 'login' ? loginStyling : navStyling(showTitle!)}
`;

/** Renders logo block component */
const LogoBlock: FunctionComponent<ILogoBlockProps> = ({
	variant,
	showTitle = false,
}) => {
	return (
		<StyledLogoBlock variant={variant} showTitle={showTitle}>
			<Logo />
			<div className="sub-title">
				<FormattedMessage id="app.title" />
			</div>
		</StyledLogoBlock>
	);
};

export default LogoBlock;
