import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import Input from 'components/form-inputs/input/input.component';
import Radio from 'components/form-inputs/radio/radio.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationString } from 'helpers/validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueServiceGeneralConfig,
	updateVenueServiceGeneralConfig,
} from 'modules/venue/slices/venue.slice';
import {
	IVenueServiceGeneralConfig,
	PaymentOption,
} from 'modules/venue/venue.types';

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	&.flex-start {
		justify-content: flex-start;
	}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledRadioWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	border-bottom: 1px solid ${brand.body};
	margin: 0 0 20px;
`;

const StyledRadio = styled(Radio)`
	min-width: 145px;
`;

const StyledCheckbox = styled(Checkbox)`
	min-width: 90px;
	margin-right: 40px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	activeService: string;
	handleDelete: Function;
	deleteServiceButtonText: string;
	formSubmission: boolean;
	setFormSubmission: Function;
	venueId: string;
}

const VenueServiceGeneralConfigForm: FunctionComponent<IComponentProps> = ({
	activeService,
	handleDelete,
	deleteServiceButtonText,
	formSubmission,
	setFormSubmission,
	venueId,
}) => {
	// get hooks
	const dispatch = useReduxDispatch();

	// Get active service general config from state
	const generalConfig = useSelector(
		(state: RootState) => state.venue.activeVenueServiceConfig?.general
	);

	useEffect(() => {
		const getData = async (id: string) => {
			await dispatch(getVenueServiceGeneralConfig(id, activeService));
		};

		venueId && getData(venueId);
	}, [dispatch, venueId, activeService]);

	// Get inital values from state
	const initialValues: IVenueServiceGeneralConfig = {
		serviceStatus: {
			enabled: generalConfig?.serviceStatus?.enabled || false,
			message: generalConfig?.serviceStatus?.message || '',
		},
		tableServiceType: generalConfig?.tableServiceType || null,
		orderInstructions: generalConfig?.orderInstructions || '',
		acceptsVouchers: generalConfig?.acceptsVouchers || false,
		tableServiceFeatures: {
			orderAndPay:
				generalConfig?.tableServiceFeatures.orderAndPay ?? false,
			payAndGo: generalConfig?.tableServiceFeatures.payAndGo ?? false,
		},
		orderNotesEnabled: generalConfig?.orderNotesEnabled || false,
		itemNotesEnabled: generalConfig?.itemNotesEnabled || false,
		onplEnabled: generalConfig?.onplEnabled || false,
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenueServiceGeneralConfig,
		{ setSubmitting }: FormikHelpers<IVenueServiceGeneralConfig>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Update venue service general config
		const response = await dispatch(
			updateVenueServiceGeneralConfig(venueId, activeService, values)
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	// Validation schema
	const formValidationSchema = object<IVenueServiceGeneralConfig>().shape({
		serviceStatus: object().shape({
			message: string().when('enabled', {
				is: true,
				then: genericValidationString({
					fieldName: 'serviceStatus.message',
				}),
			}),
		}),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting, values, setFieldValue }) => (
				<Form>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="venueForm.services.general.title" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								name="serviceStatus.enabled"
								label={intl.formatMessage({
									id: 'form.fields.serviceStatus.label',
								})}
								borderBottom
							/>
							<Field
								component={Input}
								name="serviceStatus.message"
								label={intl.formatMessage({
									id:
										'form.fields.serviceStatus.message.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.serviceStatus.message.label',
								})}
								borderBottom
							/>
							<Field
								component={Switch}
								name="acceptsVouchers"
								label={intl.formatMessage({
									id: 'form.fields.acceptsVouchers.label',
								})}
								borderBottom
							/>
							<Field
								component={Switch}
								name="orderNotesEnabled"
								label={intl.formatMessage({
									id: 'form.fields.orderNotesEnabled.label',
								})}
							/>
							<Field
								component={Switch}
								name="itemNotesEnabled"
								label={intl.formatMessage({
									id: 'form.fields.itemNotesEnabled.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							{activeService === 'table-service' && (
								<>
									<StyledLabel>
										<FormattedMessage id="form.fields.serviceType.label" />
									</StyledLabel>
									<StyledRadioWrapper>
										<Field
											component={StyledRadio}
											name="tableServiceType"
											id="tableServiceType-0"
											value={PaymentOption.payNow}
											label={intl.formatMessage({
												id: 'form.fields.payNow.label',
											})}
											handleChangeEvent={() => {
												setFieldValue(
													'tableServiceFeatures.orderAndPay',
													false
												);
												setFieldValue(
													'onplEnabled',
													false
												);
											}}
										/>
										<Field
											component={Radio}
											name="tableServiceType"
											id="tableServiceType-1"
											value={PaymentOption.payLater}
											label={intl.formatMessage({
												id:
													'form.fields.payLater.label',
											})}
											handleChangeEvent={() => {
												setFieldValue(
													'tableServiceFeatures.orderAndPay',
													false
												);
											}}
										/>
									</StyledRadioWrapper>
								</>
							)}
							{activeService === 'table-service' && (
								<>
									<StyledLabel>
										<FormattedMessage id="form.fields.tableServiceFeatures.label" />
									</StyledLabel>
									<StyledRadioWrapper>
										<Field
											component={StyledCheckbox}
											name="tableServiceFeatures.orderAndPay"
											id="tableServiceFeatures-OrderAndPay"
											isDisabled={
												values.tableServiceType !==
												PaymentOption.payNow
											}
											label={intl.formatMessage({
												id:
													'form.fields.orderAndPay.label',
											})}
											value={
												values.tableServiceType ===
													PaymentOption.payNow ||
												values.tableServiceFeatures
													.orderAndPay
											}
										/>
										<Field
											component={StyledCheckbox}
											name="tableServiceFeatures.payAndGo"
											id="tableServiceFeatures-PayAndGo"
											label={intl.formatMessage({
												id:
													'form.fields.payAndGo.label',
											})}
											value={
												values.tableServiceFeatures
													.payAndGo
											}
										/>
										<Field
											component={StyledCheckbox}
											name="onplEnabled"
											id="tableServiceFeatures-OpenBill"
											isDisabled={
												values.tableServiceType ===
												PaymentOption.payNow
											}
											label={intl.formatMessage({
												id:
													'form.fields.openBill.label',
											})}
											value={values.onplEnabled}
										/>
									</StyledRadioWrapper>
								</>
							)}
							<Field
								component={TextArea}
								name="orderInstructions"
								label={intl.formatMessage({
									id: 'form.fields.orderInstructions.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.orderInstructions.placeholder',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							<Button
								variant="secondary"
								onClick={() => handleDelete()}
								ariaLabel="delete-button"
							>
								{deleteServiceButtonText}
							</Button>
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</Form>
			)}
		</Formik>
	);
};

export default VenueServiceGeneralConfigForm;
