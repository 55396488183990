import { History } from 'history';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import VenueServiceConfig from './venue-service-config/venue-service-config.component';

import TabList from 'components/tab-list/tab-list.component';
import { intl } from 'modules/core/i18n/i18n.config';

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyleTabList = styled(TabList)`
	margin: 2px 0 20px;
`;

interface IVenueServicesProps {
	history: History;
	venueId: string;
	secondaryTab?: string;
}

// Venue services form wrapper
const VenueServices: FunctionComponent<IVenueServicesProps> = ({
	history,
	venueId,
	secondaryTab,
}) => {
	// Tab state
	const [activeTab, setActiveTab] = useState(secondaryTab || 'table-service');

	return (
		<StyledWrapper>
			<StyleTabList
				{...{
					activeTab,
					setActiveTab,
				}}
				navigateOnClick={true}
				history={history}
				parentUrl={`/venues/edit/${venueId}/services/`}
				tabStyle="underlined"
				tabs={[
					{
						defaultTab: 'general',
						id: 'table-service',
						title: intl.formatMessage({
							id:
								'venueForm.tabs.services.secondary.tableService',
						}),
					},
					{
						defaultTab: 'general',
						id: 'order-ahead',
						title: intl.formatMessage({
							id: 'venueForm.tabs.services.secondary.orderAhead',
						}),
					},
					{
						defaultTab: 'general',
						id: 'self-service',
						title: intl.formatMessage({
							id: 'venueForm.tabs.services.secondary.selfService',
						}),
					},
				]}
			/>
			<VenueServiceConfig activeService={activeTab} {...{ venueId }} />
		</StyledWrapper>
	);
};

export default VenueServices;
