import { IPagination } from 'app.types';
import { IOption } from 'components/form-inputs/select/select.component';

/** MAIN TYPES */
// Enum for report formats
export enum EReportFormats {
	csv,
	excel,
	json,
}

// Enum for report types
export enum EReportTypes {
	transaction,
	dailyVolume,
	giftSales,
	giftRedemptions,
	expiredGifts,
	giftAnalysis,
}

// Enum for report status
export enum EReportStatus {
	pending,
	processing,
	ready,
	failed,
}
// Interface for report urls
export interface IReportUrls {
	csv: string;
	excel: string;
	json: string;
}

// Interface for report
export interface IReport {
	id: string;
	createdAt: string;
	updatedAt: string;
	generatedAt: string | null;
	urls: null | IReportUrls;
	formats: string[];
	userId: string;
	type: keyof typeof EReportTypes;
	parameters: {
		startDate: string;
		endDate: string;
		accountIds?: string[];
		brandIds?: string[];
		venueIds?: string[];
	};
	status: EReportStatus;
	emails: string[];
}

// Interface for report paginated data
export interface IReportData {
	date?: string;
	time?: string;
	type?: string;
	card?: string;
	merchantId?: string;
	account?: string;
	brand?: string;
	venue?: string;
	site?: string;
	identifier?: string;
	gross?: number;
	fee?: number;
	net?: number;
	grossTransactionVisaMastercard?: number;
	grossTransactionAmex?: number;
	grossTransactionCorportate?: number;
	grossTransactionOther?: number;
	grossTransactionSubtotal?: number;
	feesTransactionVisaMastercard?: number;
	feesTransactionAmex?: number;
	feesTransactionCorportate?: number;
	feesTransactionOther?: number;
	feesTransactionSubtotal?: number;
	netTransactionVisaMastercard?: number;
	netTransactionAmex?: number;
	netTransactionCorportate?: number;
	netTransactionOther?: number;
	netTransactionSubtotal?: number;
	grossTipsAmex?: number;
	grossTipsVisaMastercard?: number;
	grossTipsCorportate?: number;
	grossTipsOther?: number;
	grossTipsSubtotal?: number;
	feesTipsVisaMastercard?: number;
	feesTipsAmex?: number;
	feesTipsCorportate?: number;
	feesTipsOther?: number;
	feesTipsSubtotal?: number;
	netTipsVisaMastercard?: number;
	netTipsAmex?: number;
	netTipsCorportate?: number;
	netTipsOther?: number;
	netTipsSubtotal?: number;
	grossGiftsAmex?: number;
	grossGiftsVisaMastercard?: number;
	grossGiftsCorportate?: number;
	grossGiftsOther?: number;
	grossGiftsSubtotal?: number;
	feesGiftsVisaMastercard?: number;
	feesGiftsAmex?: number;
	feesGiftsCorportate?: number;
	feesGiftsOther?: number;
	feesGiftsSubtotal?: number;
	netGiftsVisaMastercard?: number;
	netGiftsAmex?: number;
	netGiftsCorportate?: number;
	netGiftsOther?: number;
	netGiftsSubtotal?: number;
	code?: string;
	created?: string;
	expires?: string;
	redeemed?: string;
	amount?: number;
	amountRedeemed?: number;
	amountExpired?: number;
	amountBalance?: number;
}

// Type for report data
export type TReportData = keyof IReportData;

// Interface for paginated reports
export interface IPaginatedReports extends IPagination {
	data?: IReportData[];
}

/** FORM TYPES */

// Interface for report queue form values
export interface IReportQueueFormValues {
	reportType: keyof typeof EReportTypes;
	startDate: Date;
	endDate: Date;
	formats: {
		json: boolean;
		csv: boolean;
		excel: boolean;
	};
	emails: IOption[];
	accountIds: IOption[];
	brandIds: IOption[];
	venueIds: IOption[];
}

// Interface for report queue submit values
export interface IReportQueueSubmitValues {
	startDate: string;
	endDate: string;
	formats: string[];
	emails: string[];
	accountIds?: string[];
	brandIds?: string[];
	venueIds?: string[];
}

/** ENDPOINT TYPES */
export interface IReportFilters {
	dateFrom?: Date;
	dateTo?: Date;
	accountId?: string;
	brandId?: string;
	venueId?: string;
	pageNumber?: number;
	pageSize?: number;
}

/** STATE TYPES */
export interface IReportState {
	eventsInProgress: number;
	queuedReport?: IReport;
	activeReport?: IReport;
	paginatedReport?: IPaginatedReports;
}
