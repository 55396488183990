import { History } from 'history';
import queryString from 'query-string';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MenuAvailabilityForm from './menu-availability/menu-availability-form.component';
import MenuCategoriesForm from './menu-categories/menu-categories-form.component';
import MenuDetailsForm from './menu-details/menu-details-form.component';

import { IQueryParams } from 'app.types';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setActiveAccount } from 'modules/auth/auth.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { resetMenuState, getMenuDetails } from 'modules/menu/menu.slice';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

interface IPageProps {
	history: History;
}

const MenuFormPage: FunctionComponent<IPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get location hook
	const location = useLocation();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get menu id from url params
	const { menuId, primaryTab } = useParams();

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active menu
	const menu = useSelector(
		(state: RootState) => state.menu.activeMenuDetails
	);

	useEffect(() => {
		// Get menu data by menu id
		const getData = async (id: string | undefined) => {
			// If account id in URL params has changed
			if (
				query.activeAccount &&
				query.activeAccount !== activeAccountId
			) {
				// Update active account id
				await dispatch(setActiveAccount(query.activeAccount));
			}

			// If we have an ID, get details
			!!id && dispatch(getMenuDetails(id));
		};

		getData(menuId);

		// useEffect cleanup
		return () => {
			dispatch(resetMenuState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuId, dispatch, query.activeAccount]);

	// Set page title
	let pageTitle = menu?.title ? menu?.title : 'menuForm.create.title';
	if (menu?.reference) {
		pageTitle += ` (${menu.reference})`;
	}

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!menu}
					title={pageTitle}
					subtitle={
						menu ? 'menuForm.edit.title' : 'menuForm.subtitle'
					}
				/>
			</header>
			{!!menuId && (
				<TabList
					navigateOnClick={true}
					history={history}
					parentUrl={`/menus/menus/edit/${menuId}/`}
					{...{
						activeTab,
						setActiveTab,
					}}
					tabs={[
						{
							id: 'details',
							title: intl.formatMessage({
								id: 'menuForm.tabs.details',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
						{
							id: 'availability',
							title: intl.formatMessage({
								id: 'menuForm.tabs.availability',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
						{
							id: 'categories',
							title: intl.formatMessage({
								id: 'menuForm.tabs.categories',
							}),
							queryString: `activeAccount=${activeAccountId}`,
						},
					]}
				/>
			)}
			<FormWrapper>
				{activeTab === 'details' && <MenuDetailsForm menuId={menuId} />}
				{activeTab === 'availability' && menuId && (
					<MenuAvailabilityForm menuId={menuId} />
				)}
				{activeTab === 'categories' && menuId && (
					<MenuCategoriesForm menuId={menuId} />
				)}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(MenuFormPage);
