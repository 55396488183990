import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { IAccount } from '../account.types';
import AccountsListItem from './accounts-list-item/accounts-list-item.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import SortIcons from 'components/sort-icons/sort-icons.component';

interface IAccountsList {
	accounts: IAccount[];
	setSort: Function;
	sortColumn: keyof IAccount;
	sortDirection: string;
}

const StyledWrapper = styled.section`
	width: 100%;
	padding: 15px;
	border-radius: 10px;
	background: ${brand.white};

	h3 {
		margin: 0;
	}
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	cursor: pointer;

	:focus {
		outline: none;
	}

	&:nth-of-type(1) {
		min-width: 0;
		margin-right: 0;
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		text-align: center;
		margin: 0 15px 0 0;
	}

	&:nth-last-child(2) {
		width: 50px;
		min-width: 50px;
		text-align: center;
		margin: 0 20px 0 auto;
	}
`;

const StyledAccountsList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const StyledSortIcons = styled(SortIcons)`
	margin: 0 10px 0 0;
`;

/** Renders accounts list component */
const AccountsList: FunctionComponent<IAccountsList> = ({
	accounts,
	sortColumn,
	sortDirection,
	setSort,
}) => {
	/** Empty message component */
	const EmptyMessage: FunctionComponent = () => {
		return (
			<h3 aria-label="accounts-empty">
				<FormattedMessage id="accountsList.empty" />
			</h3>
		);
	};

	return (
		<StyledWrapper>
			{accounts.length ? (
				<>
					<StyledHeadings>
						<StyledHeading />
						<StyledHeading
							onClick={() => setSort('name')}
							onKeyPress={() => setSort('name')}
							role="button"
							tabIndex={0}
						>
							<StyledSortIcons
								active={sortColumn === 'name'}
								sort={sortDirection}
							/>
							<FormattedMessage id="accountsList.headings.name" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="accountsList.headings.menu" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="accountsList.headings.edit" />
						</StyledHeading>
					</StyledHeadings>
					<StyledAccountsList aria-label="accounts-list">
						{accounts.map((item) => (
							<AccountsListItem item={item} key={item.id} />
						))}
					</StyledAccountsList>
				</>
			) : (
				<EmptyMessage />
			)}
		</StyledWrapper>
	);
};

export default AccountsList;
