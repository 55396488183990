import { Field, Formik, Form } from 'formik';
import queryString from 'query-string';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import AppUsersList from './app-users-list/app-users-list.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Input from 'components/form-inputs/input/input.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

interface ISearchFormValues {
	searchTerm: string;
}

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.sub-actions {
		display: flex;
		width: 50%;
		align-items: center;
		justify-content: flex-end;

		Button {
			margin: 0;
			flex-shrink: 0;
		}
	}
`;

const StyledForm = styled(Form)`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-end;
	padding: 0 10px 0 0;
`;

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	width: 100%;
	max-width: 160px;

	input {
		padding: 0 23px 0 14px;
		height: 35px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

/** Renders app users page component */
const AppUsersPage: FunctionComponent = () => {
	// get hooks
	const location = useLocation();
	const history = useHistory();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	const [searchTerm, setSearchTerm] = useState<string>(query.search || '');
	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	const { totalAppUsers } = useSelector((state: RootState) => state.appUsers);

	// Initial form values
	const initialValues: ISearchFormValues = {
		searchTerm,
	};

	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	return (
		<>
			<StyledHeader>
				<SectionHeading
					title="appUsers.title"
					subtitle="appUsers.subtitle"
					extraTitleInfo={intl.formatMessage(
						{ id: 'appUsers.extraTitleInfo' },
						{
							total: intl.formatNumber(totalAppUsers || 0),
						}
					)}
				/>
				<div className="sub-actions">
					<Formik initialValues={initialValues} onSubmit={() => {}}>
						{() => {
							return (
								<StyledForm>
									<Field
										component={StyledInput}
										name="searchTerm"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={12}
										iconHeight={12}
										handleChangeEvent={(value: string) =>
											setSearchTerm(value)}
									/>
								</StyledForm>
							);
						}}
					</Formik>
				</div>
			</StyledHeader>
			<AppUsersList />
		</>
	);
};

export default withNav(AppUsersPage);
