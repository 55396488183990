import { Field, Form, Formik, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';

import BrandMarketingFooter from '../brand-marketing-footer/brand-marketing-footer.component';

import { addAlert } from 'components/alert/alert.slice';
import FormToolTip from 'components/form-inputs/form-tooltip/form-tooltip.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	deleteBrand,
	getAirshipVenues,
	getBrandMarketingOptin,
	updateAirshipVenues,
} from 'modules/brand/brand.slice';
import { IAirshipUnit, IAirshipVenue } from 'modules/brand/brand.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;

	h2 {
		margin: 16px 0 33px;
	}
`;

const StyledFormHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	gap: 50px;
	width: 100%;
	padding: 0 0 16px;

	& > div {
		flex: 1 1 100%;
		font-weight: 500;
	}
`;

const StyledHeaderItem = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const StyledFormRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	gap: 50px;
	width: 100%;
	border-top: 1px solid rgba(226, 226, 226, 0.5);
	padding: 15px 0;

	& > div {
		flex: 1 1 100%;
	}
`;

const StyledInput = styled(Input)`
	margin: 0;
`;

const StyledSelect = styled(Select)`
	margin: 0;
`;

const airshipUnitsToSelectOptions = (units?: IAirshipUnit[]): IOption[] => {
	if (!units) {
		return [];
	}

	return units.map((unit) => ({
		label: unit.name,
		value: unit.id,
	}));
};

export interface IBrandMarketingMappingFormProps {
	brandId?: string;
	history: History;
}

const BrandMarketingMappingForm: FunctionComponent<IBrandMarketingMappingFormProps> = ({
	brandId,
	history,
}) => {
	const intl = useIntl();

	const dispatch = useReduxDispatch();

	const { activeMarketingOptin } = useSelector(
		(state: RootState) => state.brand
	);

	useEffect(() => {
		const getBrandMarketingData = async (id?: string) => {
			id && (await dispatch(getBrandMarketingOptin(id)));
		};
		getBrandMarketingData(brandId);
	}, [brandId, dispatch]);

	useEffect(() => {
		const getBrandMappingData = async (id?: string) => {
			id && (await dispatch(getAirshipVenues(id)));
		};
		getBrandMappingData(brandId);
	}, [brandId, dispatch]);

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);
	const unitOptions = airshipUnitsToSelectOptions(
		activeMarketingOptin?.airshipUnits
	);

	const handleSubmit = async (
		values: IAirshipVenue[],
		{ setSubmitting }: FormikHelpers<IAirshipVenue[]>
	) => {
		if (!activeMarketingOptin) {
			fireDialog({
				title: intl.formatMessage({
					id: 'brandForm.dialogs.noActiveMarketingOptin.title',
				}),
				text: intl.formatMessage({
					id: 'brandForm.dialogs.noActiveMarketingOptin.text',
				}),
				icon: 'error',
			});
			return;
		}

		// Set formik submission state to true
		setSubmitting(true);

		// Dispatch update mapping data
		const response = await dispatch(updateAirshipVenues(values, brandId));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	// Handle brand deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete brand
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete brand action
		brandId && (await dispatch(deleteBrand(brandId)));

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to brands page
		history.push('/brands');
	};

	if (!activeMarketingOptin?.airshipVenues) return null;

	return (
		<Formik
			initialValues={activeMarketingOptin?.airshipVenues}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ dirty, isSubmitting, values }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />

					<h2>
						<FormattedMessage id="brandForm.headings.marketing.mapping" />
					</h2>
					<StyledFormHeader>
						<StyledHeaderItem>
							<FormattedMessage id="brandForm.headings.marketing.mapping.venue" />
						</StyledHeaderItem>
						<StyledHeaderItem>
							<FormattedMessage id="brandForm.headings.marketing.mapping.airshipUnit" />
						</StyledHeaderItem>
						<StyledHeaderItem>
							<FormattedMessage id="brandForm.headings.marketing.mapping.customerGroup" />
							<FormToolTip
								title={intl.formatMessage({
									id:
										'marketingOptin.form.fields.customerGroupName.toolTip.title',
								})}
								description={intl.formatMessage({
									id:
										'marketingOptin.form.fields.customerGroupName.toolTip.description',
								})}
							/>
						</StyledHeaderItem>
					</StyledFormHeader>
					{values.map((value, i) => (
						<StyledFormRow key={value.id}>
							<Field
								component={StyledInput}
								name={`[${i}].name`}
							/>
							<Field
								component={StyledSelect}
								name={`[${i}].airshipUnitId`}
								selectOptions={unitOptions}
								value={unitOptions.find(
									(unitOpt) =>
										unitOpt.value ===
										values[i].airshipUnitId
								)}
							/>
							<Field
								component={StyledInput}
								name={`[${i}].groups`}
								placeholder={intl.formatMessage({
									id:
										'form.fields.customerGroupName.placeholder',
								})}
							/>
						</StyledFormRow>
					))}
					<BrandMarketingFooter
						disabled={isSubmitting}
						{...{ brandId, handleDelete }}
					/>
				</StyledForm>
			)}
		</Formik>
	);
};

export default BrandMarketingMappingForm;
