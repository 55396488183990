import { number, string } from 'yup';

import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';

// Interface for string validation
interface IStringValidation {
	fieldName: string;
	websiteValidation?: boolean;
	hexValidation?: boolean;
}

// Interface for number validation
interface INumberValidation {
	fieldName: string;
	min: number;
	max: number;
}

// Function to create default string validation
export const genericValidationString = ({
	fieldName,
	websiteValidation = false,
	hexValidation = false,
}: IStringValidation) => {
	// Create schema with required, min & max
	let schema = string()
		.required(validationMessages.required(fieldName))
		.min(1, validationMessages.minLength(fieldName))
		.max(255, validationMessages.maxLength(fieldName, 255));

	// Conditionally set website validation
	if (websiteValidation) {
		schema = schema.matches(
			// eslint-disable-next-line max-len
			/^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
			validationMessages.url(fieldName)
		);
	}

	// Conditionally set colour hex validation
	if (hexValidation) {
		schema = schema.matches(
			/^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
			validationMessages.hexValue(fieldName)
		);
	}

	return schema;
};

// Function to create default number validation
export const genericValidationNumber = ({
	fieldName,
	min,
	max,
}: INumberValidation) => {
	// Create schema with required, min, max and check if it's a number
	return number()
		.typeError(validationMessages.number(fieldName))
		.required(validationMessages.number(fieldName))
		.min(min, validationMessages.minNumber(fieldName, min))
		.max(max, validationMessages.maxNumber(fieldName, max));
};
