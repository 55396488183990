import MockAdapter from 'axios-mock-adapter/types';

import {
	EPercentageIncrement,
	EPosOption,
	EServiceChargeType,
	IActiveVenueServiceConfig,
	ICreateVenueRequest,
	IVenueConfig,
	IVenueConfigFormValues,
	IVenueDetails,
	IVenueDetailsFormValues,
	IVenueGiftingConfig,
	IVenueGiftingConfigFormValues,
	IVenueItem,
	IVenueOpening,
	IVenueOpeningFormValues,
	IVenuePaymentFormValues,
	IVenuePrinter,
	IVenuePrinterConfig,
	IVenuePrinterListItem,
	IVenuePrinterSubmitValues,
	IvenueServiceAvailabilityConfig,
	IVenueServiceGeneralConfig,
	IVenueServicePosConfig,
	IVenueServiceServiceChargeConfig,
	IVenueServiceTippingConfig,
	IVenueSyncValues,
	PaymentOption,
} from './venue.types';

import mockPagination from 'components/pagination/pagination.mock';

/** MOCKED DATA */

const venueId: string = 'c5044bea-93c0-4237-8acb-5311330ce50c';

const venue: IVenueItem = {
	id: 'c5044bea-93c0-4237-8acb-5311330ce50c',
	name: 'PRET Venue',
	account: 'TBC',
	brand: {
		id: '3115a138-ad0b-4388-838b-dfc76758137f',
		name: 'PRET',
	},
	config: {
		isPublished: true,
		isPublishedForWeb: true,
		isTestVenue: true,
	},
	address: {
		city: 'Milton Keynes',
	},
};

const venuesList: IVenueItem[] = [
	{
		id: 'c5044bea-93c0-4237-8acb-5311330ce50c',
		name: 'PRET Venue',
		account: 'TBC',
		brand: {
			id: '3115a138-ad0b-4388-838b-dfc76758137f',
			name: 'PRET',
		},
		config: {
			isPublished: true,
			isPublishedForWeb: true,
			isTestVenue: true,
		},
		address: {
			city: 'Milton Keynes',
		},
	},
	{
		id: '4219c4f0-3c7c-4c07-8a65-e0f4de93528a',
		name: 'FIVE GUYS Venue',
		account: 'TBC',
		brand: {
			id: 'f5168493-d9c9-40cd-820a-49ac7ce23876',
			name: 'FIVE GUYS',
		},
		config: {
			isPublished: false,
			isPublishedForWeb: false,
			isTestVenue: false,
		},
		address: {
			city: 'Milton Keynes',
		},
	},
];

const venueOpeningTimes: IVenueOpening[] = [
	{
		day: 0,
		name: 'monday',
		openingTime: '09:00',
		closingTime: '17:00',
		interval: 5,
		maxOrdersPerSlot: 5,
	},
	{
		day: 1,
		name: 'tuesday',
		openingTime: '09:00',
		closingTime: '17:00',
		interval: 5,
		maxOrdersPerSlot: 5,
	},
	{
		day: 2,
		name: 'wednesday',
		openingTime: '09:00',
		closingTime: '17:00',
		interval: 5,
		maxOrdersPerSlot: 5,
	},
	{
		day: 3,
		name: 'thursday',
		openingTime: '09:00',
		closingTime: '17:00',
		interval: 5,
		maxOrdersPerSlot: 5,
	},
	{
		day: 4,
		name: 'friday',
		openingTime: '09:00',
		closingTime: '17:00',
		interval: 5,
		maxOrdersPerSlot: 5,
	},
	{
		day: 5,
		name: 'saturday',
		openingTime: '09:00',
		closingTime: '15:00',
		interval: 5,
		maxOrdersPerSlot: 5,
	},
];

const venueServiceGeneralConfig: IVenueServiceGeneralConfig = {
	serviceStatus: {
		enabled: true,
		message: 'this service is currently unavailable',
	},
	tableServiceType: PaymentOption.payNow,
	orderInstructions: 'Please make sure you have place the correct order',
	acceptsVouchers: true,
	tableServiceFeatures: {
		orderAndPay: true,
		payAndGo: false,
	},
	orderNotesEnabled: false,
	itemNotesEnabled: false,
	onplEnabled: false,
};

const venueServicePosConfig: IVenueServicePosConfig = {
	zonalSalesAreaId: 1,
	zonalSiteId: 1,
	pos: EPosOption.Zonal,
};

const venueServiceTippingConfig: IVenueServiceTippingConfig = {
	visitExpiryTimeSeconds: 600,
	tippingConfig: {
		percentageIncrementOne: 10,
		percentageIncrementTwo: 12.5,
		percentageIncrementThree: 15,
		defaultPercentageIncrement: EPercentageIncrement.percentageIncrementTwo,
		tippingEnabled: true,
		tippingEnabledPaysheet: false,
		notificationTitle: 'Like our servicve?',
		notificationMessage: 'Let our wait staff know by leaving a tip!',
		firstNotificationTimeSeconds: 700,
		delayNotificationTimeSeconds: 800,
		thankYouMessage: 'Thanks for the tip!',
	},
};

const venueServiceServiceChargeConfig: IVenueServiceServiceChargeConfig = {
	enabled: true,
	name: 'Service charge',
	type: EServiceChargeType.percentage,
	value: 12.5,
	editable: true,
	appMessage: 'This covers the cost of any and all expenses',
	emailMessage: 'thanks!',
};

const venueServiceAvailabilityConfig: IvenueServiceAvailabilityConfig = {
	ignoreMaxOrders: true,
	prepTime: 900,
	openingTimes: venueOpeningTimes,
	orderAlertEnabled: false,
	initialAlertSeconds: 900,
	escalationAlertSeconds: 900,
};

// Mock active venue service config
const activeVenueServiceConfig: IActiveVenueServiceConfig = {
	general: venueServiceGeneralConfig,
	pos: venueServicePosConfig,
	tipping: venueServiceTippingConfig,
	serviceCharge: venueServiceServiceChargeConfig,
	availability: venueServiceAvailabilityConfig,
};

/** MOCKED FORM DATA */

const venueDetailsFormValues: IVenueDetailsFormValues = {
	name: 'PRET',
	vatNo: 'vatnumb3r',
	accountId: 'c5044bea-93c0-4237-8acb-5311330ce50a',
	brandId: '3115a138-ad0b-4388-838b-dfc76758137f',
	coverUrls: '',
	logoUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
	description:
		'College friends Julian Metcalfe and Sinclair Beecham were sick of processed food and impersonal service so they opened the very first Pret A Manger in 75B Victoria Street. It promised simple, delicious food served by friendly, motivated staff.',
	address: {
		line1: '65 Long Acre',
		line2: 'Covent Garden',
		city: 'London',
		postcode: 'WC2E 9JD',
		country: 'United Kingdom',
	},
	contact: {
		website: 'https://pret.co.uk',
		email: '',
		phone: '+44 20 7932 5324',
		name: '',
	},
	cuisine: {
		'31908bd7-93ec-46ee-bcf5-64f30adb304b': true,
		'18093a54-85ca-4cb4-ac7c-f4c30dae18a8': true,
	},
	useLogoBackground: false,
	logoTintColor: '',
};

const venueConfigFormValues: IVenueConfigFormValues = {
	socialDistancing: true,
	askForAngelaEnabled: false,
	ageRestrictionAlert: false,
	ageRestrictionIcons: false,
	numericTables: true,
	currency: {
		code: { label: 'GBP', value: 'GBP' },
		symbol: { label: '£', value: '£' },
	},
	allergen: {
		url: 'https://www.allergen.url',
	},
	alcohol: {
		url: 'https://www.alcohol.url',
		active: true,
	},
	gpsLocation: {
		lng: '9',
		lat: '3',
		radius: '10',
	},
	campaignEventName: '',
	showCalorificInformation: true,
	showDiscountsAndPromoBanner: true,
};

const venuePaymentFormValues: IVenuePaymentFormValues = {
	cccLocationId: '359dc69e-db43-4ab7-8a1a-9f1b1b4bdfe2',
	orderpayPercentageFee: '35',
	visa: true,
	amex: false,
	mastercard: false,
	applepay: false,
	googlepay: true,
	visa_applepay: true,
	amex_applepay: true,
	mastercard_applepay: true,
	visa_googlepay: true,
	amex_googlepay: true,
	mastercard_googlepay: true,
	threeDsTwoEnabled: true,
};

const venueOpeningFormValues: IVenueOpeningFormValues = {
	monday: {
		open: true,
		openingTime: '09:00',
		closingTime: '17:00',
		interval: '5',
		maxOrdersPerSlot: '5',
	},
	tuesday: {
		open: true,
		openingTime: '09:00',
		closingTime: '17:00',
		interval: '5',
		maxOrdersPerSlot: '5',
	},
	wednesday: {
		open: true,
		openingTime: '09:00',
		closingTime: '17:00',
		interval: '5',
		maxOrdersPerSlot: '5',
	},
	thursday: {
		open: true,
		openingTime: '09:00',
		closingTime: '17:00',
		interval: '5',
		maxOrdersPerSlot: '5',
	},
	friday: {
		open: true,
		openingTime: '09:00',
		closingTime: '17:00',
		interval: '5',
		maxOrdersPerSlot: '5',
	},
	saturday: {
		open: true,
		openingTime: '09:00',
		closingTime: '15:00',
		interval: '5',
		maxOrdersPerSlot: '5',
	},
	sunday: {
		open: false,
		openingTime: '',
		closingTime: '',
		interval: '',
		maxOrdersPerSlot: '',
	},
};

const venuePrinterSubmitValues: IVenuePrinterSubmitValues = {
	name: 'printer 1',
	printerId: 'PRNT122',
	model: 'epson',
	purpose: ['expediter'],
	categories: [0, 1, 2],
	active: true,
};

/** MOCKED ENDPOINT DATA */

const createVenueRequest: ICreateVenueRequest = {
	name: venue.name,
	brandId: venue.brand.id,
};

const getVenueDetailsResponse: IVenueDetails = {
	id: 'c5044bea-93c0-4237-8acb-5311330ce50c',
	name: 'PRET',
	vatNo: 'vatnumb3r',
	accountId: 'c5044bea-93c0-4237-8acb-5311330ce50a',
	brandId: '3115a138-ad0b-4388-838b-dfc76758137f',
	logoUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
	coverUrls: [
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/covers/fiveguys_cover.jpg',
	],
	description:
		'College friends Julian Metcalfe and Sinclair Beecham were sick of processed food and impersonal service so they opened the very first Pret A Manger in 75B Victoria Street. It promised simple, delicious food served by friendly, motivated staff.',
	address: {
		line1: '65 Long Acre',
		line2: 'Covent Garden',
		city: 'London',
		postcode: 'WC2E 9JD',
		country: 'United Kingdom',
	},
	contact: {
		website: 'https://pret.co.uk',
		email: null,
		phone: '+44 20 7932 5324',
		name: null,
	},
	cuisine: [
		{
			id: '31908bd7-93ec-46ee-bcf5-64f30adb304b',
			title: 'British',
		},
		{
			id: '18093a54-85ca-4cb4-ac7c-f4c30dae18a8',
			title: 'Chinese',
		},
	],
	logoTintColor: '',
	useLogoBackground: false,
};

const updateVenueDetailsRequest = {
	name: 'PRET',
	vatNo: 'vatnumb3r',
	description:
		'College friends Julian Metcalfe and Sinclair Beecham were sick of processed food and impersonal service so they opened the very first Pret A Manger in 75B Victoria Street. It promised simple, delicious food served by friendly, motivated staff.',
	address: {
		line1: '65 Long Acre',
		line2: 'Covent Garden',
		city: 'London',
		postcode: 'WC2E 9JD',
		country: 'United Kingdom',
	},
	contact: {
		website: 'https://pret.co.uk',
		email: '',
		phone: '+44 20 7932 5324',
		name: '',
	},
	cuisine: [
		{
			id: '31908bd7-93ec-46ee-bcf5-64f30adb304b',
			title: 'British',
		},
		{
			id: '18093a54-85ca-4cb4-ac7c-f4c30dae18a8',
			title: 'Chinese',
		},
	],
	brandId: '3115a138-ad0b-4388-838b-dfc76758137f',
	logoUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/logos/fiveguys_logo_update.png',
	coverUrls: '',
	useLogoBackground: false,
	logoTintColor: '',
};

const updateVenueDetailsResponse: IVenueDetails = getVenueDetailsResponse;

const getVenueConfigResponse: IVenueConfig = {
	socialDistancing: true,
	askForAngelaEnabled: true,
	numericTables: true,
	currency: {
		code: 'GBP',
		symbol: '£',
	},
	allergen: {
		url: null,
	},
	alcohol: {
		url: null,
		active: true,
	},
	gpsLocation: {
		lat: null,
		lng: null,
		radius: null,
	},
	campaignEventName: '',
	showCalorificInformation: true,
	showDiscountsAndPromoBanner: true,
};

const updateVenueConfigRequest = {
	socialDistancing: true,
	askForAngelaEnabled: false,
	numericTables: true,
	currency: {
		code: 'GBP',
		symbol: '£',
	},
	allergen: {
		url: 'https://www.allergen.url',
	},
	alcohol: {
		url: 'https://www.alcohol.url',
		active: true,
	},
	gpsLocation: {
		lng: 9,
		lat: 3,
		radius: 10,
	},
	campaignEventName: '',
	showDiscountsAndPromoBanner: true,
	showCalorificInformation: true,
	ageRestrictionIcons: false,
	ageRestrictionAlert: false,
};

const updateVenueConfigResponse = updateVenueConfigRequest;

const getVenuePaymentResponse = {
	venueId: 'c45ef3e0-95f8-4db0-96eb-c7a5aa97fa3e',
	cccLocationId: 'ada69fa0-ac70-48ce-8710-10231706851a',
	orderpayPercentageFee: 35,
	visa: true,
	amex: true,
	mastercard: false,
	applepay: false,
	googlepay: false,
	visa_applepay: true,
	amex_applepay: true,
	mastercard_applepay: true,
	visa_googlepay: true,
	amex_googlepay: true,
	mastercard_googlepay: true,
	threeDsTwoEnabled: true,
	createdAt: '2020-06-05T09:22:01.379Z',
	updatedAt: '2020-06-05T09:22:01.379Z',
};

const updateVenuePaymentRequest = {
	cccLocationId: '359dc69e-db43-4ab7-8a1a-9f1b1b4bdfe2',
	orderpayPercentageFee: 35,
	visa: true,
	amex: false,
	mastercard: false,
	applepay: false,
	googlepay: true,
	visa_applepay: true,
	amex_applepay: true,
	mastercard_applepay: true,
	visa_googlepay: true,
	amex_googlepay: true,
	mastercard_googlepay: true,
	threeDsTwoEnabled: true,
};

const updateVenuePaymentResponse = {
	venueId: '359dc69e-db43-4ab7-8a1a-9f1b1b4bdfe2',
	cccLocationId: '359dc69e-db43-4ab7-8a1a-9f1b1b4bdfe2',
	visa: true,
	amex: false,
	mastercard: false,
	applepay: false,
	googlepay: true,
	visa_applepay: true,
	amex_applepay: true,
	mastercard_applepay: true,
	visa_googlepay: true,
	amex_googlepay: true,
	mastercard_googlepay: true,
	orderpayPercentageFee: '35.00',
	createdAt: '2020-06-10T16:06:30.524Z',
	updatedAt: '2020-06-10T16:06:30.524Z',
	threeDsTwoEnabled: true,
};

const updateVenueOpeningTimesRequest: IVenueOpening[] = venueOpeningTimes;

const updateVenueOpeningTimesResponse: IVenueOpening[] = updateVenueOpeningTimesRequest;

const getZonalVenuesResponse = [
	{
		zonalSiteId: 14,
		zonalId: '1_14',
		orderPayId: '969892e5-79ab-4127-a9f2-e64783a7b544',
		zonalSalesAreaId: '32',
		title: 'Forth Spinningfields',
		venueRef: null,
		brandOrderPayId: '87a0cb2d-2697-4291-82c0-1aa5514f2298',
		syncStartedAt: null,
		lastSyncStartedAt: '2020-06-22T10:28:00.705Z',
		lastSyncFinishedAt: '2020-06-22T10:28:00.760Z',
	},
	{
		zonalSiteId: 13,
		zonalId: '1_13',
		orderPayId: '5d553fce-4e2f-43fb-a854-7aec2213ed22',
		zonalSalesAreaId: '28',
		title: 'Forth Edinburgh City',
		venueRef: null,
		brandOrderPayId: '87a0cb2d-2697-4291-82c0-1aa5514f2298',
		syncStartedAt: null,
		lastSyncStartedAt: '2020-06-22T10:28:00.917Z',
		lastSyncFinishedAt: '2020-06-22T10:28:00.946Z',
	},
];

const getZonalSalesAreasResponse = [
	{
		friendly: 'Mobile Ordering',
		id: 1,
		name: 'Mobile Ordering',
	},
	{
		friendly: 'Table Service',
		id: 2,
		name: 'Table Service',
	},
];

const getVenueSyncDetailsResponse: IVenueSyncValues = {
	zonalSyncStatus:
		'Menu [Desserts] [1799] Cannot be downloaded from Zonal (Error).. Menu [Lunch] [1772] Cannot be downloaded from Zonal (Error).',
	syncStartedAt: null,
	lastSyncStartedAt: '2020-06-22T10:28:00.917Z',
	lastSyncFinishedAt: '2020-06-22T10:28:00.946Z',
};

const getVenueSyncDetailsCurrentSync: IVenueSyncValues = {
	zonalSyncStatus: 'Success',
	syncStartedAt: '2020-06-22T10:28:00.917Z',
	lastSyncStartedAt: '2020-06-22T10:28:00.917Z',
	lastSyncFinishedAt: '2020-06-22T10:28:00.946Z',
};

const getVenueGiftingConfigResponse: IVenueGiftingConfig = {
	venueId,
	giftingEnabled: true,
	defaultCardImage: 'https://via.placeholder.com/50',
};

const updateVenueGiftingConfigRequest: IVenueGiftingConfigFormValues = {
	giftingEnabled: false,
	defaultCardImage: 'https://via.placeholder.com/50',
};

const getVenuePrintersListResponse: IVenuePrinterListItem[] = [
	{
		name: 'printer 1',
		id: 'c5044bea-93c0-4237-8acb-5311330ce333',
		model: 'epson',
		lastPolled: null,
	},
	{
		name: 'printer 2',
		id: 'c5044bea-93c0-4237-3d3d-5311330ce333',
		model: 'star',
		lastPolled: new Date(),
	},
];

const getVenuePrinterImageResponse: IVenuePrinterConfig = {
	venueId,
	receiptLogoUrl:
		'https://orderpay.s3.eu-west-2.amazonaws.com/appdemo/covers/fiveguys_cover.jpg',
};

const getVenuePrinterResponse: IVenuePrinter = {
	name: 'printer 1',
	id: 'c5044bea-93c0-4237-8acb-5311330ce333',
	printerId: 'c5044bea',
	venueId: 'c5044bea-93c0-4237-8acb-5311330ce50c',
	model: 'epson',
	purpose: ['order'],
	categories: [0, 1, 2],
	active: true,
};

/** MOCKED ENDPOINT CONFIG */

const getAllVenues = {
	response: {
		filters: {},
		pagination: mockPagination.data.pagination,
		venues: venuesList,
	},
};

const createVenue = {
	request: createVenueRequest,
	response: {
		id: venueId,
	},
};

const getVenueDetails = {
	response: getVenueDetailsResponse,
};

const updateVenueDetails = {
	request: updateVenueDetailsRequest,
	response: updateVenueDetailsResponse,
};

const getVenueConfig = {
	response: getVenueConfigResponse,
};

const updateVenueConfig = {
	request: updateVenueConfigRequest,
	response: updateVenueConfigResponse,
};

const getVenuePayment = {
	response: getVenuePaymentResponse,
};

const updateVenuePayment = {
	request: updateVenuePaymentRequest,
	response: updateVenuePaymentResponse,
};

const getVenueOpeningTimes = {
	response: venueOpeningTimes,
};

const updateVenueOpeningTimes = {
	request: updateVenueOpeningTimesRequest,
	response: updateVenueOpeningTimesResponse,
};

const getZonalVenues = {
	response: getZonalVenuesResponse,
};

const getVenueServiceGeneralConfig = {
	response: venueServiceGeneralConfig,
};

const updateVenueServiceGeneralConfig = {
	request: venueServiceGeneralConfig,
	response: venueServiceGeneralConfig,
};

const getVenueServicePosConfig = {
	response: venueServicePosConfig,
};

const updateVenueServicePosConfig = {
	request: venueServicePosConfig,
	response: venueServicePosConfig,
};

const getVenueServiceTippingConfig = {
	response: venueServiceTippingConfig,
};

const updateVenueServiceTippingConfig = {
	request: {
		...venueServiceTippingConfig.tippingConfig,
		visitExpiryTimeSeconds:
			venueServiceTippingConfig.visitExpiryTimeSeconds,
	},
	response: venueServiceTippingConfig,
};

const getVenueServiceServiceChargeConfig = {
	response: venueServiceServiceChargeConfig,
};

const updateVenueServiceServiceChargeConfig = {
	request: venueServiceServiceChargeConfig,
	response: venueServiceServiceChargeConfig,
};

const getVenueServiceAvailabilityConfig = {
	response: venueServiceAvailabilityConfig,
};

const updateVenueServiceAvailabilityConfig = {
	request: venueServiceAvailabilityConfig,
	response: venueServiceAvailabilityConfig,
};

const getZonalSalesAreas = {
	response: getZonalSalesAreasResponse,
};

const getVenueSyncDetails = {
	response: getVenueSyncDetailsResponse,
};

const getVenueGiftingConfig = {
	response: getVenueGiftingConfigResponse,
};

const updateVenueGiftingConfig = {
	request: updateVenueGiftingConfigRequest,
	response: updateVenueGiftingConfigRequest,
};

const getVenuePrintersList = {
	response: getVenuePrintersListResponse,
};

const getVenuePrinterImage = {
	response: getVenuePrinterImageResponse,
};

const getVenuePrinter = {
	response: getVenuePrinterResponse,
};

/** ENDPOINT REGEX */

// Regex for delete venue
export const venueEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

// Regex for venue details
export const venueDetailsEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/details/
);

// Regex for venue config
export const venueConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/config/
);

// Regex for venue payment
export const venuePaymentEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/payment/
);

// Regex for venue opening
export const venueOpeningEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/opening-times/
);

// Regex for venue published
export const venuePublishedEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/is-published/
);

// Regex for venue service config
export const venueServiceConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/services\/.*/
);

// Regex for venue service general config
export const venueServiceGeneralConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/venue-service\/.*\/general/
);

// Regex for venue service pos config
export const venueServicePosConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/venue-service\/.*\/pos/
);

// Regex for venue service tipping config
export const venueServiceTippingConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/venue-service\/.*\/tipping/
);

// Regex for venue service service charge config
export const venueServiceServiceChargeConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/venue-service\/.*\/service-charge/
);

// Regex for venue service availability config
export const venueServiceAvailabilityConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/venue-service\/.*\/availability/
);

// Regex for zonal sales areas
export const zonalSalesAreasEndpointRegex = new RegExp(
	/zonal\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/.*\/sales-areas/
);

// Regex for venue sync
export const venueSyncDetailsEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/sync-status/
);

// Regex for venue sync
export const zonalVenueSyncEndpointRegex = new RegExp(
	/zonal\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/sync/
);

// Regex for venue gifting config
export const venueGiftingConfigEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/gifting-config/
);

// Regex for venue printers list
export const venueGetPrintersListEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/printer/
);

export const venueGetPrinterEndpointRegex = new RegExp(
	/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/printer\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);

/** MOCKED ENDPOINTS */

export const mockedVenueEndpoints = (mockHttpClient: MockAdapter) => {
	// Get all venues
	mockHttpClient
		.onGet('venue')
		.reply(200, mockVenue.endpoints.getAllVenues.response);

	// Create venue
	mockHttpClient
		.onPost('venue')
		.reply(200, mockVenue.endpoints.createVenue.response);

	// Get venue details
	mockHttpClient
		.onGet(venueDetailsEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenueDetails.response);

	// Update venue details
	mockHttpClient
		.onPatch(venueDetailsEndpointRegex)
		.reply(200, mockVenue.endpoints.updateVenueDetails.response);

	// Get venue config
	mockHttpClient
		.onGet(venueConfigEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenueConfig.response);

	// Update venue config
	mockHttpClient
		.onPatch(venueDetailsEndpointRegex)
		.reply(200, mockVenue.endpoints.updateVenueConfig.response);

	// Get venue payment
	mockHttpClient
		.onGet(venuePaymentEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenuePayment.response);

	// Update venue payment
	mockHttpClient
		.onPut(venuePaymentEndpointRegex)
		.reply(200, mockVenue.endpoints.updateVenuePayment.response);

	// Get venue opening
	mockHttpClient
		.onGet(venueOpeningEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenueOpeningTimes.response);

	// Update venue opening
	mockHttpClient
		.onPut(venueOpeningEndpointRegex)
		.reply(200, mockVenue.endpoints.updateVenueOpeningTimes.response);

	// Update venue published
	mockHttpClient
		.onPut(venuePublishedEndpointRegex)
		.reply(200, mockVenue.endpoints.updateVenueConfig.response);

	// Get venue service general config
	mockHttpClient
		.onGet(venueServiceGeneralConfigEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenueServiceGeneralConfig.response);

	// update venue service general config
	mockHttpClient
		.onPatch(venueServiceGeneralConfigEndpointRegex)
		.reply(
			200,
			mockVenue.endpoints.updateVenueServiceGeneralConfig.response
		);

	// Get venue service pos config
	mockHttpClient
		.onGet(venueServicePosConfigEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenueServicePosConfig.response);

	// update venue service pos config
	mockHttpClient
		.onPatch(venueServicePosConfigEndpointRegex)
		.reply(200, mockVenue.endpoints.updateVenueServicePosConfig.response);

	// Get venue service tipping config
	mockHttpClient
		.onGet(venueServiceTippingConfigEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenueServiceTippingConfig.response);

	// update venue service tipping config
	mockHttpClient
		.onPatch(venueServiceTippingConfigEndpointRegex)
		.reply(
			200,
			mockVenue.endpoints.updateVenueServiceTippingConfig.response
		);

	// Get venue service service charge config
	mockHttpClient
		.onGet(venueServiceServiceChargeConfigEndpointRegex)
		.reply(
			200,
			mockVenue.endpoints.getVenueServiceServiceChargeConfig.response
		);

	// update venue service service charge config
	mockHttpClient
		.onPatch(venueServiceServiceChargeConfigEndpointRegex)
		.reply(
			200,
			mockVenue.endpoints.updateVenueServiceServiceChargeConfig.response
		);

	// Get venue service availability config
	mockHttpClient
		.onGet(venueServiceAvailabilityConfigEndpointRegex)
		.reply(
			200,
			mockVenue.endpoints.getVenueServiceAvailabilityConfig.response
		);

	// update venue service availability config
	mockHttpClient
		.onPatch(venueServiceAvailabilityConfigEndpointRegex)
		.reply(
			200,
			mockVenue.endpoints.updateVenueServiceAvailabilityConfig.response
		);

	// Get zonal sales areas
	mockHttpClient
		.onGet(zonalSalesAreasEndpointRegex)
		.reply(200, mockVenue.endpoints.getZonalSalesAreas.response);

	// Get venue sync
	mockHttpClient
		.onGet(venueSyncDetailsEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenueSyncDetails.response);

	// Get venue sync
	mockHttpClient.onGet(zonalVenueSyncEndpointRegex).reply(200);

	// Get venue gifting config
	mockHttpClient
		.onGet(venueGiftingConfigEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenueGiftingConfig.response);

	// Get venue printer
	mockHttpClient
		.onGet(venueGetPrinterEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenuePrinter.response);

	// Get venue get printers
	mockHttpClient
		.onGet(venueGetPrintersListEndpointRegex)
		.reply(200, mockVenue.endpoints.getVenuePrintersList.response);

	// put venue gifting config
	mockHttpClient.onPut(venueGiftingConfigEndpointRegex).reply(200);

	// delete venue
	mockHttpClient.onDelete(venueEndpointRegex).reply(200);
};

const mockVenue = {
	data: {
		venueId,
		venue,
		venuesList,
		venueDetailsFormValues,
		venueConfigFormValues,
		venuePaymentFormValues,
		venueServiceGeneralConfig,
		venueOpeningFormValues,
		getVenueSyncDetailsCurrentSync,
		activeVenueServiceConfig,
		venueServiceAvailabilityConfig,
		venueServicePosConfig,
		venueServiceServiceChargeConfig,
		venueServiceTippingConfig,
		venuePrinter: getVenuePrinterResponse,
		venuePrinterSubmitValues,
	},
	endpoints: {
		getAllVenues,
		createVenue,
		getVenueDetails,
		updateVenueDetails,
		getVenueConfig,
		updateVenueConfig,
		getVenuePayment,
		updateVenuePayment,
		getVenueOpeningTimes,
		updateVenueOpeningTimes,
		getZonalVenues,
		getVenuePrintersList,
		getVenuePrinterImage,
		getVenuePrinter,
		getVenueServiceGeneralConfig,
		updateVenueServiceGeneralConfig,
		getZonalSalesAreas,
		getVenueSyncDetails,
		getVenueGiftingConfig,
		updateVenueGiftingConfig,
		getVenueServicePosConfig,
		updateVenueServicePosConfig,
		getVenueServiceTippingConfig,
		updateVenueServiceTippingConfig,
		getVenueServiceServiceChargeConfig,
		updateVenueServiceServiceChargeConfig,
		getVenueServiceAvailabilityConfig,
		updateVenueServiceAvailabilityConfig,
	},
};
export default mockVenue;
