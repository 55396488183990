import 'react-datepicker/dist/react-datepicker.css';
import { createGlobalStyle } from 'styled-components';

import datePicker from './global/date-picker';
import fontFaces from './global/font-faces';
import globalFonts from './global/fonts';
import globalStructure from './global/structure';
import sweetAlert from './global/sweetAlert';

/** Global styles */
export const GlobalStyle = createGlobalStyle`
	${fontFaces}
	${globalFonts}
	${globalStructure}
	${sweetAlert}
	${datePicker}
`;
