import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import CheckList from 'components/form-inputs/checklist/checklist.component';
import { ICheckListItem } from 'components/form-inputs/checklist/checklist.types';
import Switch from 'components/form-inputs/switch/switch.component';
import TimePickerInput from 'components/form-inputs/time-picker/time-picker.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	updateMenuAvailability,
	getMenuAvailability,
	formatMenuAvailabilityOpeningForForm,
	formatMenuAvailabilityOpeningForApi,
} from 'modules/menu/menu.slice';
import {
	IMenuAvailabilityFormValues,
	IMenuAvailabilitySubmitValues,
} from 'modules/menu/menu.types';
import { getVenuesList } from 'modules/venue/slices/venue.slice';
import { IVenueItem } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledDay = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5px 10px;
	border-radius: 10px;
	background: ${brand.neutral};

	&:nth-child(odd) {
		background: transparent;
	}

	div {
		text-align: center;
	}

	h3 {
		min-width: 35px;
		margin: 0 15px 0 0;
		font-weight: ${fonts.weights.regular};
	}
`;

const StyledDayFields = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledSwitchWrap = styled.div`
	width: 100px;
	margin-right: 15px;
	flex-shrink: 0;

	div,
	label {
		margin: 0;
	}
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	menuId: string;
}

const MenuAvailabilityForm: FunctionComponent<IComponentProps> = ({
	menuId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active menu availability from store
	const menuAvailability = useSelector(
		(state: RootState) => state.menu.activeMenuAvailability
	);

	// Get venues list from state
	const venuesList: ICheckListItem[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		id: value.id,
		title: value.name,
	}));

	// Get active account id from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get initial form values and venues list
	useEffect(() => {
		const getData = async () => {
			// Get venue list
			await dispatch(getVenuesList({ accountId: activeAccountId }));

			// Get menu availability
			await dispatch(getMenuAvailability(menuId));
		};

		getData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, menuId]);

	// Initial form values
	const initialValues: IMenuAvailabilityFormValues = {
		operatingTimes:
			(menuAvailability?.operatingTimes &&
				formatMenuAvailabilityOpeningForForm(
					menuAvailability?.operatingTimes!
				)) ||
			{},
		isPublished: menuAvailability?.isPublished || false,
		hideWhenUnavailable: menuAvailability?.hideWhenUnavailable || false,
		venues: menuAvailability?.venues || [],
	};

	// Handle form submission
	const handleSubmit = async (
		values: IMenuAvailabilityFormValues,
		{ setSubmitting }: FormikHelpers<IMenuAvailabilityFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		const formValues: IMenuAvailabilitySubmitValues = {
			...values,
			operatingTimes: formatMenuAvailabilityOpeningForApi(
				values.operatingTimes
			),
		};
		// Update menu availability
		const response = await dispatch(
			updateMenuAvailability(menuId, formValues)
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'menuForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, values, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="menuForm.headings.availability" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={CheckList}
								name="venues"
								label={intl.formatMessage({
									id: 'form.fields.menuVenues.label',
								})}
								items={venuesList}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Switch}
								name="isPublished"
								label={intl.formatMessage({
									id: 'form.fields.publishMenu.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.publish.on',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.publish.off',
								})}
							/>
							<Field
								component={Switch}
								name="hideWhenUnavailable"
								label={intl.formatMessage({
									id: 'form.fields.hideWhenUnavailable.label',
								})}
								onText={intl.formatMessage({
									id:
										'form.fields.hideWhenUnavailable.onText',
								})}
								offText={intl.formatMessage({
									id:
										'form.fields.hideWhenUnavailable.offText',
								})}
							/>
							{[
								'monday',
								'tuesday',
								'wednesday',
								'thursday',
								'friday',
								'saturday',
								'sunday',
							].map((day) => (
								<StyledDay key={day}>
									<StyledDayFields>
										<h3>
											{day.substring(0, 3).toUpperCase()}
										</h3>
										<StyledSwitchWrap>
											<Field
												component={Switch}
												name={`operatingTimes.${day}.open`}
												onText={intl.formatMessage({
													id:
														'form.fields.menuOpen.on',
												})}
												offText={intl.formatMessage({
													id:
														'form.fields.menuOpen.off',
												})}
											/>
										</StyledSwitchWrap>
										<Field
											component={TimePickerInput}
											name={`operatingTimes.${day}.openTime`}
											hideError={true}
											isDisabled={
												!values.operatingTimes[day]
													?.open || false
											}
											timeType="from"
										/>
										<Field
											component={TimePickerInput}
											name={`operatingTimes.${day}.closeTime`}
											hideError={true}
											isDisabled={
												!values.operatingTimes[day]
													?.open || false
											}
											timeType="to"
										/>
									</StyledDayFields>
								</StyledDay>
							))}
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<Link
							to={`/menus/menus?activeAccount=${activeAccountId}`}
						>
							<Button variant="secondary">
								<FormattedMessage id="form.button.back" />
							</Button>
						</Link>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default MenuAvailabilityForm;
