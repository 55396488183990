import LogRocket from 'logrocket';
import { Component } from 'react';

import { fireDialog } from '../dialog/dialog.service';
import { intl } from '../i18n/i18n.config';

// Create typing for component props
interface IErrorBoundaryProps {}

// Create typing for component state
interface IErrorBoundaryState {
	error: string | null;
}

/** ErrorBoundary component */
class ErrorBoundary extends Component<
	IErrorBoundaryProps,
	IErrorBoundaryState
> {
	/** Creates an instance of ErrorBoundary. */
	constructor(props: IErrorBoundaryProps) {
		super(props);

		// Set default state
		this.state = {
			error: null,
		};
	}

	/** Set component state from error */
	static getDerivedStateFromError(error: Error) {
		return {
			error: error.message,
		};
	}

	/** If an error is thrown - it will be caught here */
	componentDidCatch(error: Error | null, errorInfo: object) {
		// Log error to logrocket
		error && LogRocket.captureException(error, { extra: { ...errorInfo } });
	}

	/** Render component */
	render() {
		// If there is an error
		if (this.state.error) {
			// Show error message in alert popup
			return fireDialog({
				title: intl.formatMessage({ id: 'errors.exception.title' }),
				text: this.state.error,
				allowOutsideClick: false,
				allowEnterKey: false,
				allowEscapeKey: false,
				showConfirmButton: false,
			});
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
