import { rgba } from 'polished';
import React, { FunctionComponent } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';

interface IComponentProps {
	fieldName: string;
	label?: string;
	hideError?: boolean;
	setFieldValue: Function;
	errors?: string;
	fileFormats: string;
}

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledFileUpload = styled.div`
	background: ${brand.white};
	cursor: pointer;
	user-select: none;
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-size: ${fonts.sizes.standard};
	padding: 0 14px;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	position: relative;

	&::placeholder {
		color: ${brand.placeholder};
	}

	&:focus {
		border-color: ${brand.link};
		outline: none;
	}

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}
`;

const StyledIcon = styled(Icon)`
	position: absolute;
	top: 10px;
	right: 10px;
`;

/** Renders FileUpload component */
const FileUpload: FunctionComponent<IComponentProps> = ({
	fileFormats,
	label,
	fieldName,
	errors,
	setFieldValue,
	hideError,
}) => {
	const hasErrors = !!errors;
	return (
		<FormElement>
			{label && <StyledLabel htmlFor={fieldName}>{label}</StyledLabel>}
			<Dropzone
				accept={fileFormats}
				onDrop={(acceptedFiles) => {
					setFieldValue(fieldName, acceptedFiles[0]);
				}}
			>
				{({
					getRootProps,
					getInputProps,
					fileRejections,
					acceptedFiles,
				}) => (
					<section>
						<StyledFileUpload
							{...getRootProps()}
							className={`${
								fileRejections.length > 0 || hasErrors
									? 'has-error'
									: ''
							}`}
						>
							<input
								{...getInputProps()}
								data-testid="file-upload"
							/>
							{acceptedFiles[0]
								? acceptedFiles[0].name
								: 'File upload'}
							<StyledIcon name="upload" width={20} height={20} />
						</StyledFileUpload>
						{!hideError && hasErrors && (
							<FieldError ariaLabel={`${fieldName}-error`}>
								{errors}
							</FieldError>
						)}
					</section>
				)}
			</Dropzone>
		</FormElement>
	);
};

export default FileUpload;
