import brand from './brand';

/** Font variables */
const fonts = {
	family: '"Montserrat", arial, helvetica, sans-serif',
	sizes: {
		base: '14px',
		standard: `${12 / 14}rem`,
		small: `${11 / 14}rem`,
		med: `${14 / 14}rem`,
		large: `${18 / 14}rem`,
		h1: `${25 / 14}rem`,
		h2: `${18 / 14}rem`,
		get h3() {
			return this.med;
		},
		get h4() {
			return this.med;
		},
		get h5() {
			return this.standard;
		},
		get h6() {
			return this.standard;
		},
	},
	weights: {
		light: 300,
		regular: 400,
		medium: 500,
		semibold: 600,
		get default() {
			return this.light;
		},
	},
	headings: {
		get family() {
			return fonts.family;
		},
		get weight() {
			return fonts.weights.medium;
		},
		color: brand.headings,
		line_height: 1.2,
	},
	line_height: {
		base: 1.5,
		med: 1.7,
		large: 2,
	},
};

export default fonts;
