import { IBrand, IBrandDetails } from '../brand.types';


/** Convert IBrand to IBrandDetails */
export default function formatFullBrand(brand: IBrand): IBrandDetails {
	return {
		name: brand.name,
		vatNo: brand.vatNo || '',
		description: brand.description,
		website: brand.website,
		logoUrl: brand.logoUrl?.toString(),
		coverUrls: brand.coverUrls || [],
		fontFamily: brand.font?.fontFamily,
		fontUrl: brand.font?.url,
		h1: brand.font?.h1.toString(),
		h2: brand.font?.h2.toString(),
		h3: brand.font?.h3.toString(),
		text: brand.font?.text.toString(),
		highlights: brand.font?.highlights.toString(),
		smallText: brand.font?.smallText.toString(),
		bgPrimary: brand.colour?.bgPrimary,
		bgSecondary: brand.colour?.bgSecondary,
		lblPrimary: brand.colour?.lblPrimary,
		lblSecondary: brand.colour?.lblSecondary,
		actPrimary: brand.colour?.actPrimary,
		actSecondary: brand.colour?.actSecondary,
		actLblPrimary: brand.colour?.actLblPrimary,
		actLblSecondary: brand.colour?.actLblSecondary,
		zonalApiKey: brand.zonalApiKey,
		zonalBundleIdentifier: brand.zonalBundleIdentifier,
		zonalEstateId: brand.zonalEstateId,
		zonalUserAgent: brand.zonalUserAgent,
		vatConfig: {
			standardRate:
                ((brand.vatConfig?.standardRate ||
                    brand.vatConfig?.standardRate === 0) &&
                    (Number(brand.vatConfig?.standardRate) * 1).toString()) ||
                '',
			reducedRate:
                ((brand.vatConfig?.reducedRate ||
                    brand.vatConfig?.reducedRate === 0) &&
                    (Number(brand.vatConfig?.reducedRate) * 1).toString()) ||
                '',
			zeroRate:
                ((brand.vatConfig?.zeroRate || brand.vatConfig?.zeroRate === 0) &&
                    (Number(brand.vatConfig?.zeroRate) * 1).toString()) ||
                '',
		},
		hideMenuImages: brand.hideMenuImages,
		hideVenueOpeningTimes: brand.hideVenueOpeningTimes,
		showDiscountMessage: brand.showDiscountMessage,
		campaignEventName: brand.campaignEventName,
		account: brand.account,
		feedbackEmail: brand.feedbackEmail,
		feedbackEnabled: brand.feedbackEnabled,

	};
}