import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
	deleteAppUser,
	getAppUser,
	resetAppUsersState,
} from '../app-users.slice';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import DisplayValue from 'components/form-inputs/display-value/display-value.component';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import dayjs from 'helpers/dayjs.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

const StyledFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const AppUserDetailsPage: FunctionComponent = () => {
	// Get hooks
	const dispatch = useReduxDispatch();
	const history = useHistory();

	// Get user id from url params
	const { userId }: { userId?: string } = useParams();

	// Get active app user
	const appUser = useSelector(
		(state: RootState) => state.appUsers.activeUserDetails
	);

	useEffect(() => {
		// Get user data by id
		const getDetails = async (id: string | undefined) => {
			id && (await dispatch(getAppUser(id)));
		};

		getDetails(userId);

		// useEffect cleanup
		return () => {
			dispatch(resetAppUsersState());
		};
	}, [userId, dispatch]);

	// Handle deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'appUserDetails.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'appUserDetails.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete action
		const response = userId && (await dispatch(deleteAppUser(userId)));
		// Return if fail
		if (!response) {
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'appUserDetails.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Redirect user to app-users
		history.push('/app-users');
	};

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					title={`${appUser?.firstName} ${appUser?.lastName}`}
					titleFormat={false}
					subtitle="appUserDetails.title"
				/>
			</header>
			<FormWrapper>
				<h2>
					<FormattedMessage id="appUserDetails.headings.details" />
				</h2>
				<StyledFields>
					<StyledColumn>
						<DisplayValue
							label={intl.formatMessage({
								id: 'form.fields.firstName.label',
							})}
							value={appUser?.firstName}
						/>
						<DisplayValue
							label={intl.formatMessage({
								id: 'form.fields.lastName.label',
							})}
							value={appUser?.lastName}
						/>
						<DisplayValue
							label={intl.formatMessage({
								id: 'form.fields.email.label',
							})}
							value={appUser?.email}
						/>
					</StyledColumn>
					<StyledColumn>
						<DisplayValue
							label={intl.formatMessage({
								id: 'form.fields.userId.label',
							})}
							value={appUser?.id}
						/>
						<DisplayValue
							label={intl.formatMessage({
								id: 'form.fields.createdAt.label',
							})}
							value={dayjs(appUser?.createdAt).format(
								'DD-MM-YYYY HH:mm:ss'
							)}
						/>
						<DisplayValue
							label={intl.formatMessage({
								id: 'form.fields.phone.label',
							})}
							value={appUser?.phone}
						/>
					</StyledColumn>
				</StyledFields>
				<StyledActions>
					<Link to="/app-users">
						<Button variant="secondary">
							<FormattedMessage id="form.button.back" />
						</Button>
					</Link>
					{userId && (
						<Button
							variant="secondary"
							onClick={handleDelete}
							ariaLabel="delete-button"
						>
							<FormattedMessage id="appUserDetails.button.delete" />
						</Button>
					)}
				</StyledActions>
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(AppUserDetailsPage);
