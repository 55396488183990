import { Formik, Form, Field, FormikHelpers } from 'formik';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { object } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import Input from 'components/form-inputs/input/input.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationString } from 'helpers/validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { updateCCCMerchant } from 'modules/payment/payment.slice';
import {
	IPaymentConfig,
	ICCCPaymentConfig,
	IEditCCCMerchantFormValues,
} from 'modules/payment/payment.types';

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledSubtitle = styled.p`
	margin-bottom: 15px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledCheckboxGroup = styled.div`
	margin-bottom: -20px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;

	& > div {
		width: calc(50% - 20px);
	}
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	paymentConfig: IPaymentConfig<ICCCPaymentConfig>;
}

const EditCCCMerchantForm: FunctionComponent<IComponentProps> = ({
	paymentConfig,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	const initialValues: IEditCCCMerchantFormValues = {
		cccLocationId: paymentConfig?.providerIdentifier,
		giftingCCCLocationId: paymentConfig?.config?.giftingCCCLocationId,
		amex: paymentConfig?.config?.amex,
		amex_applepay: paymentConfig?.config?.amex_applepay,
		amex_googlepay: paymentConfig?.config?.amex_googlepay,
		applepay: paymentConfig?.config?.applepay,
		googlepay: paymentConfig?.config?.googlepay,
		mastercard: paymentConfig?.config?.mastercard,
		mastercard_applepay: paymentConfig?.config?.mastercard_applepay,
		mastercard_googlepay: paymentConfig?.config?.mastercard_googlepay,
		orderpayPercentageFee: '1',
		threeDsTwoEnabled: paymentConfig?.config?.threeDsTwoEnabled,
		visa: paymentConfig?.config?.visa,
		visa_applepay: paymentConfig?.config?.visa_applepay,
		visa_googlepay: paymentConfig?.config?.visa_googlepay,
	};

	const formValidationSchema = object<ICCCPaymentConfig>().shape({
		cccLocationId: genericValidationString({ fieldName: 'cccLocationId' }),
	});

	const handleSubmit = async (
		values: IEditCCCMerchantFormValues,
		{ setSubmitting }: FormikHelpers<IEditCCCMerchantFormValues>
	) => {
		setSubmitting(true);

		const response = await dispatch(
			updateCCCMerchant(
				paymentConfig.entityType,
				paymentConfig.entityId,
				values.cccLocationId,
				{
					...values,
				}
			)
		);

		if (!response) {
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'paymentConfigForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		setSubmitting(false);
	};
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				<Form>
					<p>
						<FormattedMessage id="paymentConfigForm.headings.editingCCCMerchant" />
					</p>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Input}
								name="cccLocationId"
								label={intl.formatMessage({
									id: 'form.fields.cccLocationId.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.cccLocationId.label',
								})}
								isPrivate={true}
							/>
							<Field
								component={Input}
								name="giftingCCCLocationId"
								label={intl.formatMessage({
									id:
										'form.fields.giftingCCCLocationId.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.giftingCCCLocationId.label',
								})}
								isPrivate={true}
							/>
							<Field
								component={Switch}
								name="threeDsTwoEnabled"
								label={intl.formatMessage({
									id: 'form.fields.threeDsTwoEnabled.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.threeDsTwoEnabled.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<StyledSubtitle>
								<FormattedMessage id="form.fields.cards.label" />
							</StyledSubtitle>
							<StyledCheckboxGroup>
								<Field
									component={Checkbox}
									name="visa"
									label={intl.formatMessage({
										id: 'form.fields.cards.visa.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="amex"
									label={intl.formatMessage({
										id: 'form.fields.cards.amex.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="mastercard"
									label={intl.formatMessage({
										id:
											'form.fields.cards.mastercard.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="applepay"
									label={intl.formatMessage({
										id: 'form.fields.cards.applepay.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="googlepay"
									label={intl.formatMessage({
										id: 'form.fields.cards.googlepay.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="visa_applepay"
									label={intl.formatMessage({
										id:
											'form.fields.cards.visa_applepay.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="amex_applepay"
									label={intl.formatMessage({
										id:
											'form.fields.cards.amex_applepay.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="mastercard_applepay"
									label={intl.formatMessage({
										id:
											'form.fields.cards.mastercard_applepay.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="visa_googlepay"
									label={intl.formatMessage({
										id:
											'form.fields.cards.visa_googlepay.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="amex_googlepay"
									label={intl.formatMessage({
										id:
											'form.fields.cards.amex_googlepay.label',
									})}
								/>
								<Field
									component={Checkbox}
									name="mastercard_googlepay"
									label={intl.formatMessage({
										id:
											'form.fields.cards.mastercard_googlepay.label',
									})}
								/>
							</StyledCheckboxGroup>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</Form>
			)}
		</Formik>
	);
};

export default EditCCCMerchantForm;
