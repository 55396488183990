import { useFormikContext } from 'formik';
import { meetsContrastGuidelines } from 'polished';
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { IBrandDetailsFormValues } from 'modules/brand/brand.types';

const StyleBrandColourExample = styled.div`
	width: 100%;
	height: 52px;
	margin-bottom: 25px;
	div:first-child {
		height: 100%;
		text-align: center;
		line-height: 52px;
		border-radius: 5px;
		border: 1px solid ${brand.borders};
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
	}
`;
// takes bgColour props and checks if contract guidelines are met.
// wrapped within try-catch incase entered value is not a valid colour code.
const useAccessibleButtonColour = () => {
	const formikProps = useFormikContext<IBrandDetailsFormValues>();
	useEffect(() => {
		const bgColour = formikProps.values.bgPrimary;
		try {
			const textColour = meetsContrastGuidelines(bgColour, '#000').AA
				? '#000'
				: '#FFF';
			formikProps.setFieldValue('lblPrimary', textColour);
			// eslint-disable-next-line no-empty
		} catch (error) {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formikProps.values.bgPrimary]);
};

const BrandColourExample: FunctionComponent = () => {
	useAccessibleButtonColour();
	const { values } = useFormikContext<IBrandDetailsFormValues>();
	return (
		<StyleBrandColourExample>
			<div
				data-testid="brand-example-colour"
				style={{
					backgroundColor: values.bgPrimary,
					color: values.lblPrimary,
				}}
			>
				Example of text on background colour
			</div>
		</StyleBrandColourExample>
	);
};

export default BrandColourExample;
