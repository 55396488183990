import { Field } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getBrandPriceBands } from 'modules/brand/brand.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	IVenueServicePosConfig,
	IVenueServicePosConfigFormValues,
} from 'modules/venue/venue.types';

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	margin-top: 30px;

	&.flex-start {
		justify-content: flex-start;
	}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

interface IComponentProps {
	values: IVenueServicePosConfigFormValues;
	posConfig?: IVenueServicePosConfig;
	venueId: string;
}

const VenueServiceConfigPosComtrexForm: FunctionComponent<IComponentProps> = ({
	values,
	posConfig,
	venueId,
}) => {
	const dispatch = useReduxDispatch();

	const brandId =
		useSelector(
			(state: RootState) => state.venue?.activeVenueDetails?.brandId
		) || '';
	const priceBands =
		useSelector((state: RootState) => state.brand?.priceBands) || [];

	useEffect(() => {
		dispatch(getBrandPriceBands(brandId));
	}, [brandId, dispatch]);

	const priceBandOptions = priceBands.map((priceBand) => ({
		label: priceBand.name,
		value: priceBand.id,
	})) as IOption[];

	return (
		<>
			<StyledFieldGroup>
				<StyledColumn>
					<Field
						component={Input}
						name="comtrexIpAddress"
						label={intl.formatMessage({
							id: 'form.fields.comtrexIpAddress.label',
						})}
						placeholder={intl.formatMessage({
							id: 'form.fields.comtrexIpAddress.label',
						})}
					/>
					<Field
						component={Input}
						name="comtrexPortNumber"
						label={intl.formatMessage({
							id: 'form.fields.comtrexPortNumber.label',
						})}
						placeholder={intl.formatMessage({
							id: 'form.fields.comtrexPortNumber.label',
						})}
					/>
				</StyledColumn>
				<StyledColumn>
					<Field
						component={Input}
						name="comtrexEndpoint"
						label={intl.formatMessage({
							id: 'form.fields.comtrexEndpoint.label',
						})}
						placeholder={intl.formatMessage({
							id: 'form.fields.comtrexEndpoint.label',
						})}
					/>
					<Field
						component={Input}
						name="comtrexVendorName"
						label={intl.formatMessage({
							id: 'form.fields.comtrexVendorName.label',
						})}
						placeholder={intl.formatMessage({
							id: 'form.fields.comtrexVendorName.label',
						})}
					/>
				</StyledColumn>
			</StyledFieldGroup>
			<StyledFieldGroup>
				<StyledColumn>
					<Field
						component={Select}
						name="comtrexPriceBand"
						label={intl.formatMessage({
							id: 'form.fields.comtrexPriceBand.label',
						})}
						value={priceBandOptions.find((option) => {
							return option.value === posConfig?.priceBandId;
						})}
						testId="price-band-select"
						selectOptions={priceBandOptions}
					/>
				</StyledColumn>
			</StyledFieldGroup>
		</>
	);
};

export default VenueServiceConfigPosComtrexForm;
