import { History } from 'history';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getVenueDetails } from '../slices/venue.slice';
import VenueCreateForm from './venue-create/venue-create-form.component';
import VenueDetails from './venue-details/venue-details.component';
import VenueGiftingForm from './venue-gifting/venue-gifting-form.component';
import VenueMenuValidation from './venue-menu-validation/venue-menu-validation.component';
import VenuePrinting from './venue-printing/venue-printing.component';
import VenueServices from './venue-services/venue-services.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

// Interface for component props
interface IVenueFormPageProps {
	history: History;
}

const VenueFormPage: FunctionComponent<IVenueFormPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	// Get venue id and active tabs from url params
	const { venueId, primaryTab, secondaryTab, printerId } = useParams();
	// Get active venue name
	const activeVenueDetails = useSelector(
		(state: RootState) => state.venue.activeVenueDetails
	);

	const venueName =
		activeVenueDetails?.name || intl.formatMessage({ id: 'venues.title' });

	useEffect(() => {
		// Get venue data by venue id
		const getVenueData = async (id: string) => {
			await dispatch(getVenueDetails(id));
		};

		!!venueId && getVenueData(venueId);
	}, [venueId, dispatch]);

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!venueId}
					title={venueId ? venueName : 'venueForm.create.title'}
					subtitle={
						venueId ? 'venueForm.edit.title' : 'venueForm.subtitle'
					}
				/>
			</header>
			{venueId ? (
				<TabList
					{...{ activeTab, setActiveTab }}
					navigateOnClick={true}
					history={history}
					parentUrl={`/venues/edit/${venueId}/`}
					tabs={[
						{
							defaultTab: 'details',
							id: 'details',
							title: intl.formatMessage({
								id: 'venueForm.tabs.details.primary',
							}),
						},
						{
							defaultTab: 'table-service/general',
							id: 'services',
							title: intl.formatMessage({
								id: 'venueForm.tabs.services.primary',
							}),
						},
						{
							id: 'gifting',
							title: intl.formatMessage({
								id: 'venueForm.tabs.gifting.primary',
							}),
							isHidden: !activeVenueDetails?.giftingEnabled,
						},
						{
							id: 'printing',
							title: intl.formatMessage({
								id: 'venueForm.tabs.printing.primary',
							}),
						},
						{
							id: 'validation',
							title: intl.formatMessage({
								id: 'venueForm.tabs.validation.primary',
							}),
						},
					]}
				/>
			) : (
				<FormWrapper>
					<VenueCreateForm {...{ history }} />
				</FormWrapper>
			)}
			<FormWrapper>
				{venueId && activeTab === 'details' && (
					<VenueDetails {...{ history, venueId, secondaryTab }} />
				)}
				{venueId && activeTab === 'services' && (
					<VenueServices {...{ history, venueId, secondaryTab }} />
				)}
				{venueId && activeTab === 'gifting' && (
					<VenueGiftingForm venueId={venueId} />
				)}
				{venueId && activeTab === 'printing' && (
					<VenuePrinting
						venueId={venueId}
						secondaryTab={secondaryTab}
						printerId={printerId}
					/>
				)}
				{venueId && activeTab === 'validation' && (
					<VenueMenuValidation venueId={venueId} />
				)}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(VenueFormPage);
