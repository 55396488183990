import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import SortableMiniList from 'components/form-inputs/sortable-mini-list/sortable-mini-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	createModifierGroup,
	patchModifierGroupDetails,
	deleteModifierGroup,
	getProductsList,
} from 'modules/menu/menu.slice';
import {
	IModifierGroupDetailsFormValues,
	ISelectionTypeOption,
	IModifierGroupDetailsSubmitValues,
	ESelectionTypes,
} from 'modules/menu/menu.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IFormValidation {
	title?: string;
}
interface IComponentProps {
	modifierId?: string | undefined;
}

const ModifierGroupDetailsForm: FunctionComponent<IComponentProps> = ({
	modifierId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const history = useHistory();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active modifier group details from store
	const modifierGroupDetails = useSelector(
		(state: RootState) => state.menu.activeModifierGroupDetails
	);

	// Create options for selection type
	const selectionTypeOptions: ISelectionTypeOption[] = [
		{ label: 'single', value: 0 },
		{ label: 'multiple', value: 1 },
	];

	useEffect(() => {
		const getData = async () => {
			await dispatch(getProductsList({ pageSize: -1 }));
		};

		getData();
	}, [dispatch]);

	// Get product options from state
	const productOptions =
		useSelector((state: RootState) => state.menu.products)?.map(
			(option) => ({
				value: option.id,
				label: `${option.name} ${option.sku ? `(${option.sku})` : ''} ${
					option.price
						? `${intl.formatNumber(option.price / 100, {
							style: 'currency',
							currency: 'GBP',
						  })}`
						: ''
				}`,
			})
		) || [];

	// Set initial product options
	const initialProductOptions: IOption[] =
		productOptions?.filter((option) => {
			return modifierGroupDetails?.products?.includes(option.value);
		}) || [];

	// Initial form values
	const initialValues: IModifierGroupDetailsFormValues = {
		title: (modifierId && modifierGroupDetails?.title) || '',
		sku: (modifierId && modifierGroupDetails?.sku) || '',
		products: (modifierId && initialProductOptions) || [],
		priceInclusiveQuantity:
			(modifierId &&
				modifierGroupDetails?.priceInclusiveQuantity?.toString()) ||
			'0',
		minQuantity:
			(modifierId && modifierGroupDetails?.minQuantity?.toString()) || '',
		maxQuantity:
			(modifierId && modifierGroupDetails?.maxQuantity?.toString()) || '',
		selectionType:
			(modifierId && modifierGroupDetails?.selectionType) ||
			selectionTypeOptions[0].value,
		modifierItems:
			(modifierId &&
				modifierGroupDetails?.modifierItemDetails?.map((item) => ({
					id: item.id,
					title: `${item.title}${item.sku ? `(${item.sku})` : ''}`,
				}))) ||
			[],
	};

	// Handle form submission
	const handleSubmit = async (
		values: IModifierGroupDetailsFormValues,
		{ setSubmitting }: FormikHelpers<IModifierGroupDetailsFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		const formValues: IModifierGroupDetailsSubmitValues = {
			...values,
			products:
				values.products?.map(
					(option: IOption) => option.value?.toString() || ''
				) || [],
			priceInclusiveQuantity: parseFloat(values.priceInclusiveQuantity),
			minQuantity: parseFloat(values.minQuantity),
			maxQuantity: parseFloat(values.maxQuantity),
			modifierItems: values.modifierItems?.map((item) => item.id),
		};

		// Create / update
		const response = await (modifierId
			? dispatch(patchModifierGroupDetails(modifierId, formValues))
			: dispatch(createModifierGroup(formValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: modifierId
					? intl.formatMessage({
						id: 'modifierGroupForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'modifierGroupForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		!modifierId &&
			history.push(
				`/menus/modifier-group/edit/${response?.id}/details?activeAccount=${activeAccountId}`
			);
	};

	// Handle deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'modifierGroupForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'modifierGroupForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete action
		const response =
			modifierId && (await dispatch(deleteModifierGroup(modifierId)));

		// Return if fail
		if (!response) {
			return;
		}
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'modifierGroupForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to modifier groups
		history.push(`/menus/modifier-groups?activeAccount=${activeAccountId}`);
	};

	const formValidationSchema = object<IFormValidation>().shape({
		title: string().required(validationMessages.required('name')),
		minQuantity: string().when(
			['selectionType', 'maxQuantity'],
			(selectionType: ESelectionTypes, maxQuantity: string) => {
				return string().test({
					test: (minQuantity: string) => {
						return (
							selectionType === 0 ||
							(selectionType === 1 &&
								Number(minQuantity) <= Number(maxQuantity))
						);
					},
					message: intl.formatMessage({
						id: 'errors.forms.validation.minAboveMax',
					}),
				});
			}
		),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, values, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="modifierGroupForm.headings.details" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={Input}
								name="title"
								label={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
								toolTip={{
									title: intl.formatMessage({
										id: 'form.fields.name.label',
									}),
									description: intl.formatMessage({
										id:
											'modifierGroupDetails.form.field.name.toolTip.description',
									}),
								}}
							/>
							<Field
								component={Input}
								name="sku"
								label={intl.formatMessage({
									id: 'form.fields.reference.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.reference.label',
								})}
								toolTip={{
									title: intl.formatMessage({
										id: 'form.fields.reference.label',
									}),
									description: intl.formatMessage({
										id:
											'modifierGroupDetails.form.field.reference.toolTip.description',
									}),
								}}
							/>
							<Field
								component={Select}
								isMulti
								name="products"
								label={intl.formatMessage({
									id:
										'form.fields.productsModifierGroup.label',
								})}
								selectOptions={productOptions}
								value={values.products}
								toolTip={{
									title: intl.formatMessage({
										id:
											'form.fields.productsModifierGroup.label',
									}),
									description: intl.formatMessage({
										id:
											'modifierGroupDetails.form.field.productsModifierGroup.toolTip.description',
									}),
								}}
							/>
							<Field
								component={Select}
								selectOptions={selectionTypeOptions}
								name="selectionType"
								testId="selectionType-select"
								label={intl.formatMessage({
									id: 'form.fields.selectionType.label',
								})}
								value={selectionTypeOptions.filter(
									(option) =>
										option.value === values.selectionType
								)}
								toolTip={{
									title: intl.formatMessage({
										id: 'form.fields.selectionType.label',
									}),
									description: intl.formatMessage({
										id:
											'modifierGroupDetails.form.field.selectionType.toolTip.description',
									}),
								}}
							/>
							<Field
								component={Input}
								name="minQuantity"
								label={intl.formatMessage({
									id: 'form.fields.minQuantity.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.minQuantity.label',
								})}
								type="number"
								step="1"
								toolTip={{
									title: intl.formatMessage({
										id: 'form.fields.minQuantity.label',
									}),
									description: intl.formatMessage({
										id:
											'modifierGroupDetails.form.field.minQuantity.toolTip.description',
									}),
								}}
							/>
							{values.selectionType ===
								ESelectionTypes.multiple && (
								<Field
									component={Input}
									name="maxQuantity"
									label={intl.formatMessage({
										id: 'form.fields.maxQuantity.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.maxQuantity.label',
									})}
									type="number"
									step="1"
									toolTip={{
										title: intl.formatMessage({
											id: 'form.fields.maxQuantity.label',
										}),
										description: intl.formatMessage({
											id:
												'modifierGroupDetails.form.field.maxQuantity.toolTip.description',
										}),
									}}
								/>
							)}
							<Field
								component={Input}
								name="priceInclusiveQuantity"
								label={intl.formatMessage({
									id:
										'form.fields.priceInclusiveQuantity.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.priceInclusiveQuantity.label',
								})}
								toolTip={{
									title: intl.formatMessage({
										id:
											'form.fields.priceInclusiveQuantity.label',
									}),
									description: intl.formatMessage({
										id:
											'modifierGroupDetails.form.field.priceInclusiveQuantity.toolTip.description',
									}),
								}}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={SortableMiniList}
								name="modifierItems"
								label={intl.formatMessage({
									id:
										'form.fields.modifierItemsGroupOrder.label',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link
								to={`/menus/modifier-groups?activeAccount=${activeAccountId}`}
							>
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{modifierId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="modifierGroupForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage
								id={
									modifierId
										? 'form.button.save'
										: 'modifierGroupForm.button.create'
								}
							/>
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default ModifierGroupDetailsForm;
