import { Formik, Form, Field, FormikProps } from 'formik';
import queryString from 'query-string';
import React, {
	useCallback,
	useEffect,
	FunctionComponent,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import {
	getProductsList,
	getCategoriesList,
	resetMenuState,
} from '../../menu.slice';
import { IMenuListFilters, IProduct, ICategory } from '../../menu.types';
import ProductListItem from './product-list-item/product-list-item.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Pagination from 'components/pagination/pagination.component';
import SortIcons from 'components/sort-icons/sort-icons.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { IVenueItem } from 'modules/venue/venue.types';

interface ISearchFormValues {
	searchTerm: string;
	brand: string;
	venue: string;
	category: string;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

const StyledWrapper = styled.section`
	width: 100%;
	padding: 0 0 15px;
`;

const StyledActions = styled.div`
	padding: 0 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledForm = styled(Form)`
	display: flex;
	width: 50%;
`;

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	width: 100%;
	max-width: 160px;

	input {
		height: 35px;
		padding: 0 23px 0 14px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	justify-content: space-between;
	padding: 0 15px 0 0;
`;

const StyledSortIcons = styled(SortIcons)`
	margin: 0 10px 0 0;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	padding-left: 5px;
	user-select: none;

	&.mod-sort {
		cursor: pointer;
	}

	:focus {
		outline: none;
	}

	&:first-child {
		min-width: 50px;
		width: 50px;
		padding: 0;
		margin: 0;
	}

	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4) {
		min-width: 250px;
		max-width: 250px;
	}

	&:nth-child(4) {
		margin-left: 20px;
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		padding-left: 0;
		text-align: center;
	}
`;

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const ProductList: FunctionComponent = () => {
	// initialise hooks
	const dispatch = useReduxDispatch();
	const location = useLocation();
	const history = useHistory();
	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get products from state
	const products =
		useSelector((state: RootState) => state.menu.products) || [];

	const [searchTerm, setSearchTerm] = useState<string>(query.search || '');

	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Initial form values
	const initialValues: ISearchFormValues = {
		searchTerm,
		brand: query.brandId || '',
		venue: query.venueId || '',
		category: query.categoryId || '',
	};

	// Get brand options from state
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get venue options from state
	const venueOption: IOption[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get categories from store and prepare for select
	const categoryOptions: IOption[] =
		useSelector((state: RootState) => state.menu.categories)?.map(
			(value: ICategory) => ({
				value: value.id,
				label: `${value.title} ${
					value.reference ? `(${value.reference})` : ''
				}`,
			})
		) || [];

	const pagination = useSelector((state: RootState) => state.menu.pagination);

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.search = search) : delete filters.search;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.search]
	);

	useEffect(() => {
		const getData = async () => {
			// Get filters from query params
			const categoryFilters: IMenuListFilters = {
				brandId: query.brandId,
				venueId: query.venueId,
				pageSize: -1,
			};

			const productFilters: IMenuListFilters = {
				brandId: query.brandId,
				venueId: query.venueId,
				search: query.search,
				sortOrder: query.sortOrder,
				sort: query.sort,
				categoryId: query.categoryId,
				pageNumber: parseFloat(query.pageNumber!) || 1,
			};

			if (activeAccountId) {
				await dispatch(getCategoriesList(categoryFilters));

				await dispatch(getProductsList(productFilters));
			} else {
				resetMenuState();
			}
		};

		getData();
	}, [
		dispatch,
		activeAccountId,
		query.brandId,
		query.categoryId,
		query.venueId,
		query.search,
		query.sortOrder,
		query.sort,
		query.menuId,
		query.pageNumber,
	]);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	// Function to update sort query params
	const updateSort = (column: keyof IProduct) => {
		// sort order from query params
		const { sortOrder } = query;
		// variable to store sort direction
		let sortDir;

		if (sortOrder === 'ASC') {
			sortDir = 'DESC';
		} else if (sortOrder === 'DESC') {
			sortDir = '';
		} else {
			sortDir = 'ASC';
		}

		// Create list of filters
		const filters = {
			...query,
			sortOrder: sortDir,
			sort: column,
		};

		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	// Function to filter menu and change route
	const filterProducts = ({
		brandId = query.brandId,
		venueId = query.venueId,
		categoryId = query.categoryId,
	}) => {
		// get current filters
		const filters = query;

		// add filters if available
		!!brandId && (filters.brandId = brandId);
		!!venueId && (filters.venueId = venueId);
		!!categoryId && (filters.categoryId = categoryId);

		// remove page number on search
		delete filters.pageNumber;

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	// If no active account, tell the user to choose one
	const emptyMessage = activeAccountId ? (
		<FormattedMessage id="productList.empty" />
	) : (
		<FormattedMessage id="menus.list.noAccount" />
	);

	return (
		<StyledWrapper>
			<StyledActions>
				<Formik initialValues={initialValues} onSubmit={() => {}}>
					{({ values }) => {
						return (
							<StyledForm>
								<Field
									component={StyledInput}
									name="searchTerm"
									placeholder={intl.formatMessage({
										id: 'form.search.placeholder',
									})}
									icon="search"
									iconWidth={12}
									iconHeight={12}
									handleChangeEvent={(value: string) =>
										setSearchTerm(value)}
								/>
								<Field
									size="sm"
									testId="brand-select"
									isDisabled={!activeAccountId}
									value={brandOptions.find(
										(brandOpt) =>
											brandOpt.value === query.brandId
									)}
									formElementVariant="header"
									component={Select}
									handleChangeEvent={async (
										value: ISelectChangeValue
									) =>
										filterProducts({
											brandId: value.fieldValue || '',
										})}
									isSearchable={true}
									placeholder={intl.formatMessage({
										id: 'form.fields.brandSelect.label',
									})}
									name="brand"
									isClearable={true}
									selectOptions={brandOptions}
								/>
								<Field
									size="sm"
									testId="venue-select"
									isDisabled={!activeAccountId}
									value={venueOption.find(
										(venue) => venue.value === query.venueId
									)}
									formElementVariant="header"
									component={Select}
									handleChangeEvent={async (
										value: ISelectChangeValue
									) =>
										filterProducts({
											venueId: value.fieldValue || '',
										})}
									isSearchable={true}
									placeholder={intl.formatMessage({
										id: 'form.fields.venueSelect.label',
									})}
									name="venue"
									isClearable={true}
									selectOptions={venueOption}
								/>
								<Field
									component={Select}
									isDisabled={!activeAccountId}
									selectOptions={categoryOptions}
									name="category"
									placeholder={intl.formatMessage({
										id: 'form.fields.categorySelect.label',
									})}
									size="sm"
									formElementVariant="header"
									handleChangeEvent={async (
										value: ISelectChangeValue
									) =>
										filterProducts({
											categoryId: value.fieldValue || '',
										})}
									value={categoryOptions.find(
										(category) =>
											category.value === query.categoryId
									)}
								/>
							</StyledForm>
						);
					}}
				</Formik>
				<div>
					<Link
						to={`product/create?activeAccount=${activeAccountId}`}
					>
						<Button icon="plus" iconWidth={11} iconHeight={11}>
							<FormattedMessage id="productList.button.create" />
						</Button>
					</Link>
				</div>
			</StyledActions>
			<StyledHeadings>
				<StyledHeading />
				<StyledHeading
					className="mod-sort"
					onClick={() => updateSort('name')}
					onKeyPress={() => updateSort('name')}
					role="button"
					tabIndex={0}
				>
					<StyledSortIcons
						active={query.sort === 'name'}
						sort={query.sortOrder}
					/>
					<FormattedMessage id="productList.headings.name" />
				</StyledHeading>
				<StyledHeading
					className="mod-sort"
					onClick={() => updateSort('sku')}
					onKeyPress={() => updateSort('sku')}
					role="button"
					tabIndex={0}
				>
					<StyledSortIcons
						active={query.sort === 'sku'}
						sort={query.sortOrder}
					/>
					<FormattedMessage id="productList.headings.sku" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="productList.headings.price" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="productList.headings.edit" />
				</StyledHeading>
			</StyledHeadings>
			<StyledList aria-label="product-list">
				{products?.length > 0
					? products.map((item) => (
						<ProductListItem
							item={item}
							key={item.id}
							activeAccountId={activeAccountId}
						/>
					  ))
					: emptyMessage}
			</StyledList>
			{pagination && pagination.pageCount > 1 && (
				<Pagination
					pageNumber={
						query.pageNumber ? parseFloat(query.pageNumber) : 1
					}
					pageCount={pagination.pageCount}
					setPageNumber={handlePagination}
				/>
			)}
		</StyledWrapper>
	);
};

export default ProductList;
