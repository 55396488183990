import { History } from 'history';
import queryString from 'query-string';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ModifierItemDetailsForm from './modifier-item-details/modifier-item-details-form.component';
import ModifierItemNutritionalForm from './modifier-item-nutrition/modifier-nutrition-form.component';
import ModifierItemPriceBandsList from './modifier-item-price-bands/modifier-item-price-bands-list.component';

import { IQueryParams } from 'app.types';
import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { hasPriceBands } from 'helpers/price-band.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setActiveAccount } from 'modules/auth/auth.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getModifierItemDetails,
	resetMenuState,
	getMenuPreferences,
	getModifierGroupsList,
} from 'modules/menu/menu.slice';
import withNav from 'modules/navigation/with-nav.component';

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

interface IPageProps {
	history: History;
}

const ModifierItemFormPage: FunctionComponent<IPageProps> = ({ history }) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get location hook
	const location = useLocation();

	// Get modifierItemId id from url params
	const { modifierItemId, primaryTab } = useParams<{
		modifierItemId: string;
		primaryTab: string;
	}>();

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'details');

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active modifier item details from store
	const activeModifierItemDetails = useSelector(
		(state: RootState) => state.menu.activeModifierItemDetails
	);

	useEffect(() => {
		// Get modifier item data by modifer item id
		const getData = async (id: string | undefined) => {
			// If account id in URL params has changed
			if (
				query.activeAccount &&
				query.activeAccount !== activeAccountId
			) {
				// Update active account id
				await dispatch(setActiveAccount(query.activeAccount));
			}

			// If we have an ID, get details
			!!id && (await dispatch(getModifierItemDetails(id)));
			await dispatch(getMenuPreferences());
			await dispatch(getModifierGroupsList({ pageSize: -1 }));
		};

		getData(modifierItemId);

		// useEffect cleanup
		return () => {
			dispatch(resetMenuState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifierItemId, dispatch, query.activeAccount]);

	// Set page title
	let pageTitle = activeModifierItemDetails?.title
		? activeModifierItemDetails?.title
		: 'modifierItemForm.create.title';

	if (activeModifierItemDetails?.sku) {
		pageTitle += ` (${activeModifierItemDetails.sku})`;
	}

	const tabs = [
		{
			id: 'details',
			title: intl.formatMessage({
				id: 'modifierItemForm.tabs.details',
			}),
			queryString: `activeAccount=${activeAccountId}`,
		},
		{
			id: 'nutritional',
			title: intl.formatMessage({
				id: 'modifierItemForm.tabs.nutritional',
			}),
			queryString: `activeAccount=${activeAccountId}`,
		},
	];

	if (hasPriceBands(activeModifierItemDetails)) {
		tabs.push({
			id: 'price-bands',
			title: intl.formatMessage({
				id: 'modifierItemForm.tabs.priceBands',
			}),
			queryString: `activeAccount=${activeAccountId}`,
		});
	}

	return (
		<StyledContentWrapper>
			<header>
				<SectionHeading
					titleFormat={!modifierItemId}
					title={pageTitle}
					subtitle={
						modifierItemId
							? 'modifierItemForm.edit.title'
							: 'modifierItemForm.subtitle'
					}
				/>
			</header>
			{!!modifierItemId && (
				<TabList
					navigateOnClick={true}
					history={history}
					parentUrl={`/menus/modifier-item/edit/${modifierItemId}/`}
					{...{
						activeTab,
						setActiveTab,
					}}
					tabs={tabs}
				/>
			)}
			<FormWrapper>
				{activeTab === 'details' && (
					<ModifierItemDetailsForm modifierItemId={modifierItemId} />
				)}
				{activeTab === 'price-bands' && (
					<ModifierItemPriceBandsList
						modifierItemId={modifierItemId}
					/>
				)}
				{activeTab === 'nutritional' && (
					<ModifierItemNutritionalForm
						modifierItemId={modifierItemId}
					/>
				)}
			</FormWrapper>
		</StyledContentWrapper>
	);
};

export default withNav(ModifierItemFormPage);
