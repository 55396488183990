import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IBrandState,
	IBrandItem,
	IBrand,
	IBrandListFilters,
	IBrandGifting,
	IBrandPriceBandFormValues,
	IBrandFeedbackFormValues,
	IBrandMarketingOptin,
	IBrandDetails,
	IAirshipVenue,
	IBrandReceiptFooterFormValues,
} from './brand.types';
import formatShortBrand from './helpers/format-short-brand.helper';

// Create initial state
export const initialBrandState: IBrandState = {
	brands: [],
	priceBands: [],
	eventsInProgress: 0,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
};

const brandSlice = createSlice({
	name: 'brand',
	initialState: initialBrandState,
	reducers: {
		ACTIVE_BRAND(state, action) {
			return {
				...state,
				activeBrand: action.payload,
			};
		},
		CREATE_BRAND(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_BRAND_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_BRAND_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_BRAND(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_BRAND_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_BRAND_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_BRANDS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ALL_BRANDS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_BRANDS_SUCCESS(state, action) {
			return {
				...state,
				brands: action.payload.data.brands,
				eventsInProgress: state.eventsInProgress - 1,
				pagination: {
					...state.pagination,
					pageNumber: action.payload.data.pagination.pageNumber,
					pageCount: action.payload.data.pagination.pageCount,
				},
			};
		},
		GET_BRAND(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BRAND_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BRAND_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_BRANDS(state, action) {
			return {
				...state,
				brands: action.payload,
			};
		},
		UPDATE_BRAND(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_BRAND_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_BRAND_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BRAND_GIFTING(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BRAND_GIFTING_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BRAND_GIFTING_SUCCESS(state, action) {
			return {
				...state,
				activeBrandGifting: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_BRAND_GIFTING(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_BRAND_GIFTING_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_BRAND_GIFTING_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_BRAND_PRICE_BANDS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ALL_BRAND_PRICE_BANDS_SUCCESS(state, action) {
			return {
				...state,
				priceBands: action.payload.data.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_BRAND_PRICE_BANDS_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRICE_BAND_DETAILS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PRICE_BAND_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				activePriceBandDetails: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRICE_BAND_DETAILS_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PRICE_BAND(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_PRICE_BAND_SUCCESS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PRICE_BAND_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRICE_BAND(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_PRICE_BAND_SUCCESS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PRICE_BAND_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_PRICE_BAND(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_PRICE_BAND_SUCCESS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_PRICE_BAND_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_BRAND_FEEDBACK(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_BRAND_FEEDBACK_SUCCESS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_BRAND_FEEDBACK_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BRAND_MARKETING_OPTIN(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BRAND_MARKETING_OPTIN_SUCCESS(state, action) {
			return {
				...state,
				activeMarketingOptin: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BRAND_MARKETING_OPTIN_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_BRAND_MARKETING_OPTIN(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PUT_BRAND_MARKETING_OPTIN_SUCCESS(state, action) {
			return {
				...state,
				activeMarketingOptin: {
					...action.payload.data,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_BRAND_MARKETING_OPTIN_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_BRAND_STATE() {
			return {
				...initialBrandState,
			};
		},
		GET_AIRSHIP_VENUES(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_AIRSHIP_VENUES_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_AIRSHIP_VENUES_SUCCESS(state, action) {
			if (state.activeMarketingOptin) {
				return {
					...state,
					activeMarketingOptin: {
						...state.activeMarketingOptin,
						airshipVenues: action.payload.data.venues,
						airshipUnits: action.payload.data.units,
					},
					eventsInProgress: state.eventsInProgress - 1,
				};
			}

			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_AIRSHIP_VENUES(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_AIRSHIP_VENUES_SUCCESS(state, action) {
			if (state.activeMarketingOptin) {
				return {
					...state,
					activeMarketingOptin: {
						...state.activeMarketingOptin,
						airshipVenues: action.payload.data.venues,
						airshipUnits: action.payload.data.units,
					},
					eventsInProgress: state.eventsInProgress - 1,
				};
			}

			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_AIRSHIP_VENUES_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BRAND_RECEIPT_FOOTER_CONFIG(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BRAND_RECEIPT_FOOTER_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				activeReceiptFooterConfig: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BRAND_RECEIPT_FOOTER_CONFIG_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_BRAND_RECEIPT_FOOTER_CONFIG(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		PUT_BRAND_RECEIPT_FOOTER_CONFIG_SUCCESS(state, action) {
			return {
				...state,
				activeReceiptFooterConfig: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		PUT_BRAND_RECEIPT_FOOTER_CONFIG_FAIL(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	ACTIVE_BRAND,
	CREATE_BRAND,
	CREATE_BRAND_FAIL,
	CREATE_BRAND_SUCCESS,
	DELETE_BRAND,
	DELETE_BRAND_FAIL,
	DELETE_BRAND_SUCCESS,
	GET_ALL_BRANDS,
	GET_ALL_BRANDS_FAIL,
	GET_ALL_BRANDS_SUCCESS,
	GET_ALL_BRAND_PRICE_BANDS,
	GET_ALL_BRAND_PRICE_BANDS_FAIL,
	GET_ALL_BRAND_PRICE_BANDS_SUCCESS,
	GET_BRAND,
	GET_BRAND_FAIL,
	GET_BRAND_SUCCESS,
	SET_BRANDS,
	UPDATE_BRAND,
	UPDATE_BRAND_FAIL,
	UPDATE_BRAND_SUCCESS,
	GET_BRAND_GIFTING,
	GET_BRAND_GIFTING_FAIL,
	GET_BRAND_GIFTING_SUCCESS,
	GET_PRICE_BAND_DETAILS,
	GET_PRICE_BAND_DETAILS_FAIL,
	GET_PRICE_BAND_DETAILS_SUCCESS,
	CREATE_PRICE_BAND,
	CREATE_PRICE_BAND_FAIL,
	CREATE_PRICE_BAND_SUCCESS,
	UPDATE_PRICE_BAND,
	UPDATE_PRICE_BAND_FAIL,
	UPDATE_PRICE_BAND_SUCCESS,
	DELETE_PRICE_BAND,
	DELETE_PRICE_BAND_FAIL,
	DELETE_PRICE_BAND_SUCCESS,
	UPDATE_BRAND_GIFTING,
	UPDATE_BRAND_GIFTING_FAIL,
	UPDATE_BRAND_GIFTING_SUCCESS,
	UPDATE_BRAND_FEEDBACK,
	UPDATE_BRAND_FEEDBACK_SUCCESS,
	UPDATE_BRAND_FEEDBACK_FAIL,
	GET_BRAND_MARKETING_OPTIN,
	GET_BRAND_MARKETING_OPTIN_FAIL,
	GET_BRAND_MARKETING_OPTIN_SUCCESS,
	PUT_BRAND_MARKETING_OPTIN,
	PUT_BRAND_MARKETING_OPTIN_FAIL,
	PUT_BRAND_MARKETING_OPTIN_SUCCESS,
	RESET_BRAND_STATE,
	GET_AIRSHIP_VENUES,
	GET_AIRSHIP_VENUES_SUCCESS,
	GET_AIRSHIP_VENUES_FAIL,
	UPDATE_AIRSHIP_VENUES,
	UPDATE_AIRSHIP_VENUES_FAIL,
	UPDATE_AIRSHIP_VENUES_SUCCESS,
	GET_BRAND_RECEIPT_FOOTER_CONFIG,
	GET_BRAND_RECEIPT_FOOTER_CONFIG_FAIL,
	GET_BRAND_RECEIPT_FOOTER_CONFIG_SUCCESS,
	PUT_BRAND_RECEIPT_FOOTER_CONFIG,
	PUT_BRAND_RECEIPT_FOOTER_CONFIG_FAIL,
	PUT_BRAND_RECEIPT_FOOTER_CONFIG_SUCCESS,
} = brandSlice.actions;

/** Update brands in store */
export const setBrands = (brands: IBrandItem[]) => (dispatch: Dispatch) => {
	dispatch(SET_BRANDS(brands));
};

/** Get brands list */
export const getBrandsList = (filters?: IBrandListFilters) => async (
	dispatch: Dispatch
) => {
	// Create brands list request
	const { payload } = await dispatch(
		GET_ALL_BRANDS({
			request: {
				method: 'get',
				url: 'brand',
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** Set active brand in state */
export const setActiveBrand = (brand: IBrand | undefined) => async (
	dispatch: Dispatch
) => {
	// Set active brand in store
	await dispatch(ACTIVE_BRAND(brand));
};

/** Create brand */
export const createBrand = (brand: IBrandDetails) => async (
	dispatch: Dispatch
) => {
	// Create brand request
	const { payload } = await dispatch(
		CREATE_BRAND({
			request: {
				method: 'post',
				url: 'brand',
				data: brand,
			},
		})
	);

	const success = payload.status === 200;
	if (success) {
		// Format response as IBrand
		const formattedBrand = payload.data && formatShortBrand(payload.data);

		// Update active brand
		await dispatch(ACTIVE_BRAND(formattedBrand));

		return formattedBrand;
	}

	return success;
};

/** Get brand by ID */
export const getBrand = (brandId: string) => async (dispatch: Dispatch) => {
	// Get brand request
	const { payload } = await dispatch(
		GET_BRAND({
			request: {
				method: 'get',
				url: `brand/${brandId}`,
			},
		})
	);

	// Update active brand
	payload?.data && (await dispatch(ACTIVE_BRAND(payload.data)));

	return payload?.data;
};

/** Update brand */
export const updateBrand = (brandId: string, brand: IBrandDetails) => async (
	dispatch: Dispatch
) => {
	// Update brand request
	const { payload } = await dispatch(
		UPDATE_BRAND({
			request: {
				method: 'patch',
				url: `brand/${brandId}`,
				data: brand,
			},
		})
	);

	const success = payload.status === 200;
	if (success) {
		// Format response as IBrand
		const formattedBrand = formatShortBrand(payload.data);

		// Update active brand
		await dispatch(ACTIVE_BRAND(formattedBrand));

		return formattedBrand;
	}

	return success;
};

/** Delete brand by ID */
export const deleteBrand = (brandId: string) => async (dispatch: Dispatch) => {
	// Delete brand list request
	const { payload } = await dispatch(
		DELETE_BRAND({
			request: {
				method: 'delete',
				url: `brand/${brandId}`,
			},
		})
	);

	const success = payload.status === 200;

	if (success) {
		// Update active brand
		await dispatch(ACTIVE_BRAND(undefined));
	}
	return success;
};

/** Update brand feedback */
export const updateBrandFeedback = (
	brandId: string,
	brandFeedback: IBrandFeedbackFormValues
) => async (dispatch: Dispatch) => {
	// Update brand request
	const { payload } = await dispatch(
		UPDATE_BRAND_FEEDBACK({
			request: {
				method: 'patch',
				url: `brand/${brandId}`,
				data: brandFeedback,
			},
		})
	);

	return payload.status === 200;
};

/** Get brand gifting */
export const getBrandGiftingConfig = (brandId: string) => async (
	dispatch: Dispatch
) => {
	// Get brand request
	const { payload } = await dispatch(
		GET_BRAND_GIFTING({
			request: {
				method: 'get',
				url: `brand/${brandId}/gifting-config`,
			},
		})
	);

	return payload?.data;
};

/** Update brand gifting */
export const updateBrandGiftingConfig = (
	brandId: string,
	data: IBrandGifting
) => async (dispatch: Dispatch) => {
	// Get brand request
	const { payload } = await dispatch(
		UPDATE_BRAND_GIFTING({
			request: {
				method: 'put',
				url: `brand/${brandId}/gifting-config`,
				data,
			},
		})
	);

	return payload?.status === 200;
};

/** Get price bands for brand */
export const getBrandPriceBands = (brandId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_ALL_BRAND_PRICE_BANDS({
			request: {
				method: 'get',
				url: `brand/${brandId}/price-bands`,
			},
		})
	);

	return payload?.data?.data;
};

/** Get price band details */
export const getPriceBandDetails = (
	brandId: string,
	priceBandId: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		GET_PRICE_BAND_DETAILS({
			request: {
				method: 'get',
				url: `brand/${brandId}/price-bands/${priceBandId}`,
			},
		})
	);

	return payload?.data;
};

/** Create price band */
export const createPriceBand = (
	brandId: string,
	priceBand: IBrandPriceBandFormValues
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		CREATE_PRICE_BAND({
			request: {
				method: 'post',
				url: `brand/${brandId}/price-bands`,
				data: priceBand,
			},
		})
	);

	return payload?.status === 200;
};

/** Update price band */
export const updatePriceBand = (
	brandId: string,
	priceBandId: string,
	priceBand: IBrandPriceBandFormValues
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		UPDATE_PRICE_BAND({
			request: {
				method: 'put',
				url: `brand/${brandId}/price-bands/${priceBandId}`,
				data: priceBand,
			},
		})
	);

	return payload?.status === 200;
};

/** Delete price band */
export const deletePriceBand = (brandId: string, priceBandId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		DELETE_PRICE_BAND({
			request: {
				method: 'delete',
				url: `brand/${brandId}/price-bands/${priceBandId}`,
			},
		})
	);

	return payload?.status === 204;
};

/** Get brand marketing optin details */
export const getBrandMarketingOptin = (brandId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_BRAND_MARKETING_OPTIN({
			request: {
				method: 'get',
				url: 'marketing/config',
				params: { entity: 'brand', id: brandId },
			},
		})
	);

	return payload?.data;
};

/** put brand marketing optin details */
export const putBrandMarketingOptin = (data: IBrandMarketingOptin) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		PUT_BRAND_MARKETING_OPTIN({
			request: {
				method: 'put',
				url: 'marketing/config',
				data,
			},
		})
	);

	return payload?.status === 200;
};

/** Get airship venues for mapping */
export const getAirshipVenues = (entityId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_AIRSHIP_VENUES({
			request: {
				method: 'get',
				url: `marketing/config/mapping/${entityId}`,
			},
		})
	);

	return payload?.status === 200;
};

export const getBrandReceiptFooterConfig = (brandId: string) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_BRAND_RECEIPT_FOOTER_CONFIG({
			request: {
				method: 'get',
				url: `brand/${brandId}/receipt-footer-config`,
			},
		})
	);

	return payload?.status === 200;
};

export const updateAirshipVenues = (
	data: IAirshipVenue[],
	entityId?: string
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		UPDATE_AIRSHIP_VENUES({
			request: {
				method: 'put',
				url: `marketing/config/mapping/${entityId}`,
				data: {
					venues: data,
				},
			},
		})
	);

	return payload.status === 200;
};

export const updateBrandReceiptFooterConfig = (
	brandId: string,
	data: IBrandReceiptFooterFormValues
) => async (dispatch: Dispatch) => {
	const { payload } = await dispatch(
		PUT_BRAND_RECEIPT_FOOTER_CONFIG({
			request: {
				method: 'put',
				url: `brand/${brandId}/receipt-footer-config`,
				data,
			},
		})
	);

	return payload.status === 200;
};

/** Reset Brand State */
export const resetBrandState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_BRAND_STATE());
};

export default brandSlice.reducer;
