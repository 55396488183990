import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import dayjs from 'helpers/dayjs.helper';
import { IAppUser } from 'modules/app-users/app-users.types';

const StyledStaffItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 150px;
	min-height: 56px;
	padding: 15px 0 15px 5px;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-of-type(1) {
		width: 300px;
	}

	&:nth-of-type(2) {
		width: 250px;
	}

	&:nth-of-type(3) {
		width: 230px;
	}
`;

const StyledIconColumn = styled.div`
	width: 30px;
	min-width: 30px;
	height: 56px;
	margin: 0 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
`;

// Props for component
interface IAppUsersListItemProps {
	item: IAppUser;
}
// Staff list item component
const AppUsersListItem: FunctionComponent<IAppUsersListItemProps> = ({
	item,
}) => {
	return (
		<StyledStaffItem key={item.id} aria-label="app-user-item">
			<StyledColumn data-private>{item.email}</StyledColumn>
			<StyledColumn data-private>{item.firstName}</StyledColumn>
			<StyledColumn data-private>{item.lastName}</StyledColumn>
			<StyledColumn data-private>{item.phone}</StyledColumn>
			<StyledColumn data-private>
				{dayjs(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
			</StyledColumn>
			<StyledIconColumn>
				<Link to={`/app-users/view/${item.id}`}>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledIconColumn>
		</StyledStaffItem>
	);
};

export default AppUsersListItem;
