/** MAIN TYPES */
import { IPagination } from 'app.types';
import { IOption } from 'components/form-inputs/select/select.component';
import { ICuisine } from 'modules/menu/menu.types';

export enum EPosOption {
	None = '',
	Zonal = 'zonal',
	LineTen = 'lineTen',
	Comtrex = 'comtrex',
}

export enum PaymentOption {
	payNow = 'payNow',
	payLater = 'payLater',
}

export enum EServiceChargeType {
	amount = 'amount',
	percentage = 'percentage',
}

export enum EPercentageIncrement {
	percentageIncrementOne = 'percentageIncrementOne',
	percentageIncrementTwo = 'percentageIncrementTwo',
	percentageIncrementThree = 'percentageIncrementThree',
}
export interface IVenueItem {
	id: string;
	name: string;
	account: string;
	brand: {
		id: string;
		name: string;
	};
	config: {
		isPublished: boolean;
		isPublishedForWeb: boolean;
		isTestVenue: boolean;
	};
	address: {
		city?: string;
	};
}

export interface IVenueDetails {
	id: string;
	accountId: string;
	brandId: string;
	name?: string | null;
	vatNo?: string | null;
	description?: string | null;
	logoUrl: string | null;
	coverUrls: string[];
	address?: {
		line1?: string | null;
		line2?: string | null;
		city?: string | null;
		postcode?: string | null;
		country?: string | null;
	};
	contact?: {
		website?: string | null;
		email?: string | null;
		phone?: string | null;
		name?: string | null;
	};
	cuisine?: ICuisine[];
	useLogoBackground: boolean;
	logoTintColor: string | null;
}

export interface IVenueConfig {
	askForAngelaEnabled: boolean;
	socialDistancing: boolean;
	numericTables: boolean;
	campaignEventName: string;
	currency: {
		code: string;
		symbol: string;
	};
	allergen?: {
		url: string | null;
	};
	alcohol?: {
		url: string | null;
		active: boolean;
	};
	gpsLocation?: {
		lat: number | null;
		lng: number | null;
		radius: number | null;
	};
	isPublished?: boolean;
	showCalorificInformation: boolean;
	showDiscountsAndPromoBanner: boolean;
	ageRestrictionIcons?: boolean;
	ageRestrictionAlert?: boolean;
}

export interface IVenueOpening {
	day: number;
	name: string;
	openingTime: string;
	closingTime: string;
	interval: number;
	maxOrdersPerSlot: number;
}

export interface IVenueTippingConfig {
	tippingEnabled: boolean;
	percentageIncrementOne: string;
	percentageIncrementTwo: string;
	percentageIncrementThree: string;
	firstNotificationTimeSeconds: string;
	delayNotificationTimeSeconds: string;
	notificationTitle: string;
	notificationMessage: string;
	thankYouMessage: string;
}

export interface IVenueTippingConfigApi {
	tippingEnabled: boolean;
	percentageIncrementOne: number;
	percentageIncrementTwo: number;
	percentageIncrementThree: number;
	firstNotificationTimeSeconds: number;
	delayNotificationTimeSeconds: number;
	notificationTitle: string;
	notificationMessage: string;
	thankYouMessage: string;
}

export interface IVenueGiftingConfig {
	venueId: string;
	defaultCardImage: string;
	giftingEnabled: boolean;
}

export interface IVenueServiceGeneralConfig {
	serviceStatus: {
		enabled: boolean;
		message: string;
	};
	tableServiceType?: PaymentOption | null;
	orderInstructions: string;
	acceptsVouchers: boolean;
	tableServiceFeatures: {
		orderAndPay: boolean;
		payAndGo: boolean;
	};
	orderNotesEnabled?: boolean;
	itemNotesEnabled?: boolean;
	onplEnabled?: boolean;
}

export interface IVenueServicePosConfig {
	pos?: EPosOption;
	zonalSiteId?: number | null;
	zonalSiteName?: string;
	zonalSalesAreaId?: number | null;
	ipAddress?: string;
	portNumber?: number;
	endpoint?: string;
	priceBandId?: string;
	vendorName?: string;
}

export interface IVenueServicePosConfigUpdate {
	zonalSiteId?: number | null;
	zonalSiteName?: string;
	zonalSalesAreaId?: number | null;
	comtrexConfig?: {
		ipAddress: string;
		portNumber: number;
		endpoint: string;
		priceBandId?: string;
		vendorName: string;
	};
}

export interface IVenueServiceTippingConfig {
	visitExpiryTimeSeconds: number;
	tippingConfig: {
		percentageIncrementOne: number;
		percentageIncrementTwo: number;
		percentageIncrementThree: number;
		defaultPercentageIncrement: EPercentageIncrement;
		firstNotificationTimeSeconds: number;
		delayNotificationTimeSeconds: number;
		notificationTitle: string;
		notificationMessage: string;
		thankYouMessage: string;
		tippingEnabled: boolean;
		tippingEnabledPaysheet: boolean;
	};
}

export interface IVenueServiceServiceChargeConfig {
	enabled: boolean;
	name: string;
	type: EServiceChargeType;
	value: number;
	editable: boolean;
	appMessage: string;
	emailMessage: string;
}

export interface IvenueServiceAvailabilityConfig {
	ignoreMaxOrders: boolean;
	prepTime: number;
	openingTimes: IVenueOpening[];
	orderAlertEnabled: boolean;
	initialAlertSeconds: number;
	escalationAlertSeconds: number;
}

export interface IActiveVenueServiceConfig {
	general?: IVenueServiceGeneralConfig;
	pos?: IVenueServicePosConfig;
	tipping?: IVenueServiceTippingConfig;
	serviceCharge?: IVenueServiceServiceChargeConfig;
	availability?: IvenueServiceAvailabilityConfig;
}

export type PrintPriority = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export type PrinterPurpose = 'order' | 'expediter';

export type PrinterModel = 'epson' | 'star' | 'quickprinter';

export interface IVenuePrinterListItem {
	id: string;
	name: string;
	model: PrinterModel;
	lastPolled: Date | null;
}

export interface IPrintCategoryOptions {
	label: string;
	value: PrintPriority;
}

export interface IPrinterModelOptions {
	label: string;
	value: PrinterModel;
}

export interface IPrinterPurposeOptions {
	label: string;
	value: PrinterPurpose;
}

export interface IVenuePrinter {
	name: string;
	id: string;
	printerId: string;
	venueId: string;
	model: PrinterModel;
	purpose: PrinterPurpose[];
	categories: PrintPriority[];
	active: boolean;
}

export interface IVenuePrinterConfig {
	venueId: string;
	receiptLogoUrl: string;
}

/** FORM TYPES */

export interface IVenueCreateFormValues {
	name: string;
	brandId: string;
}

export interface IVenueDetailsFormValues {
	accountId?: string;
	brandId?: string;
	name?: string | null;
	vatNo?: string | null;
	description?: string | null;
	address?: {
		line1?: string | null;
		line2?: string | null;
		city?: string | null;
		postcode?: string | null;
		country?: string | null;
	};
	contact?: {
		website?: string | null;
		email?: string | null;
		phone?: string | null;
		name?: string | null;
	};
	logoUrl?: string | null;
	coverUrls?: string | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cuisine?: any;
	useLogoBackground?: boolean;
	logoTintColor?: string | null;
	giftingEnabled?: boolean;
}

export interface IVenueConfigFormValues {
	socialDistancing: boolean;
	askForAngelaEnabled: boolean;
	numericTables: boolean;
	currency?: {
		code: IOption | null;
		symbol: IOption | null;
	};
	allergen?: {
		url: string | null;
	};
	alcohol?: {
		url: string | null;
		active: boolean;
	};
	gpsLocation?: {
		lat: string | null;
		lng: string | null;
		radius: string | null;
	};
	isPublished?: boolean;
	campaignEventName: string;
	showCalorificInformation: boolean;
	showDiscountsAndPromoBanner: boolean;
	ageRestrictionIcons?: boolean;
	ageRestrictionAlert?: boolean;
}

export interface IVenuePaymentFormValues {
	cccLocationId: string;
	orderpayPercentageFee: string | null;
	visa: boolean;
	amex: boolean;
	mastercard: boolean;
	applepay: boolean;
	googlepay: boolean;
	visa_applepay: boolean;
	amex_applepay: boolean;
	mastercard_applepay: boolean;
	visa_googlepay: boolean;
	amex_googlepay: boolean;
	mastercard_googlepay: boolean;
	threeDsTwoEnabled: boolean;
}

export interface IVenueOpeningDay {
	open: boolean;
	openingTime?: string;
	closingTime?: string;
	interval?: string;
	maxOrdersPerSlot?: string;
}

export interface IVenueOpeningFormValues {
	[day: string]: IVenueOpeningDay;
}

export interface IVenuePublishFormValues {
	isPublished: boolean;
	isPublishedForWeb: boolean;
	isTestVenue: boolean;
}

export interface IVenueServiceDay {
	open: boolean;
	openingTime?: string;
	closingTime?: string;
	interval?: string;
	maxOrdersPerSlot?: string;
}

export interface IServiceChargeConfig {
	enabled: boolean;
	name: string;
	type: EServiceChargeType;
	value: number | string;
	editable: boolean;
	appMessage?: string;
	emailMessage?: string;
}

export interface IVenueSyncValues {
	zonalSyncStatus: string;
	syncStartedAt: string | null;
	lastSyncStartedAt: string;
	lastSyncFinishedAt: string;
}

export interface IVenueGiftingConfigFormValues {
	defaultCardImage: string;
	giftingEnabled: boolean;
}

export interface IVenueServicePosConfigFormValues {
	zonalSiteId: string;
	zonalSalesAreaId: string;
	comtrexIpAddress: string;
	comtrexPortNumber: string;
	comtrexEndpoint: string;
	comtrexPriceBand: string;
	comtrexVendorName: string;
}

export interface IVenueServiceTippingConfigFormValues {
	percentageIncrementOne: string;
	percentageIncrementTwo: string;
	percentageIncrementThree: string;
	defaultPercentageIncrement?: EPercentageIncrement | null;
	tippingEnabled: boolean;
	tippingEnabledPaysheet: boolean;
	notificationTitle: string;
	notificationMessage: string;
	thankYouMessage: string;
	firstNotificationTimeSeconds: string;
	delayNotificationTimeSeconds: string;
	visitExpiryTimeSeconds: string;
}

export interface IVenueServiceTippingConfigSubmitValues {
	percentageIncrementOne: number;
	percentageIncrementTwo: number;
	percentageIncrementThree: number;
	defaultPercentageIncrement?: EPercentageIncrement | null;
	tippingEnabled: boolean;
	tippingEnabledPaysheet: boolean;
	notificationTitle: string;
	notificationMessage: string;
	thankYouMessage: string;
	firstNotificationTimeSeconds: number;
	delayNotificationTimeSeconds: number;
	visitExpiryTimeSeconds: number;
}

export interface IVenueServiceServiceChargeConfigFormValues {
	enabled: boolean;
	name: string;
	type: EServiceChargeType;
	value: string;
	editable: boolean;
	appMessage: string;
	emailMessage: string;
}

export interface IVenueServiceAvailabilityConfigFormValues {
	limitMaxOrders: boolean;
	prepTime: string;
	openingTimes: IVenueOpeningFormValues;
	orderAlertEnabled: boolean;
	initialAlertSeconds: string;
	escalationAlertSeconds: string;
}

export interface IVenuePrinterFormValues {
	name: string;
	printerId: string;
	model: PrinterModel;
	purpose: IPrinterPurposeOptions[];
	categories: IPrintCategoryOptions[];
	active: boolean;
}

export interface IVenuePrinterSubmitValues {
	name: string;
	printerId: string;
	model: PrinterModel;
	purpose: PrinterPurpose[];
	categories: PrintPriority[];
	active: boolean;
}

/** ENDPOINT TYPES */

export interface IVenuesListFilters {
	accountId?: string;
	brandId?: string | string[];
	pageNumber?: number;
	pageSize?: number;
	name?: string;
}

export interface ICreateVenueRequest {
	name: string;
	brandId: string;
}

export interface IZonalSalesArea {
	friendly: string;
	id: number;
	name: string;
}

/** STATE TYPES */

export interface IVenueState {
	venues: IVenueItem[];
	eventsInProgress: number;
	pagination: IPagination;
	activeVenueDetails?: IVenueDetailsFormValues;
	activeVenueConfig?: IVenueConfigFormValues;
	activeVenuePayment?: IVenuePaymentFormValues;
	activeVenueOpening?: IVenueOpeningFormValues;
	activeVenuePublish?: IVenuePublishFormValues;
	activeVenueServiceConfig?: IActiveVenueServiceConfig;
	activeVenueSync?: IVenueSyncValues;
	activeZonalSalesAreas?: IZonalSalesArea[];
	activeVenueGiftingConfig?: IVenueGiftingConfig;
	activeVenuePrinters?: IVenuePrinterListItem[];
	activeVenuePrinter?: IVenuePrinter;
	activeVenuePrinterImage?: string;
}
