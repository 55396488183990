import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import fonts from 'assets/styles/variables/fonts';

const StyledHeading = styled.div`
	display: flex;
	flex-direction: column;

	h1,
	h3 {
		margin: 0;
	}
`;

const StyledExtraData = styled.span`
	font-size: ${fonts.sizes.h1};
	font-weight: ${fonts.weights.light};
`;

interface ISectionHeadingProps {
	title: string;
	subtitle: string;
	extraTitleInfo?: string;
	titleFormat?: boolean;
	subtitleFormat?: boolean;
}

const SectionHeading: FunctionComponent<ISectionHeadingProps> = ({
	title,
	subtitle,
	extraTitleInfo,
	titleFormat = true,
	subtitleFormat = true,
}) => {
	const extraTitleContent = (
		<StyledExtraData> | {extraTitleInfo}</StyledExtraData>
	);
	return (
		<StyledHeading>
			<h3>
				{subtitleFormat ? <FormattedMessage id={subtitle} /> : subtitle}
			</h3>
			<h1>
				{titleFormat ? <FormattedMessage id={title} /> : title}
				{extraTitleInfo && extraTitleContent}
			</h1>
		</StyledHeading>
	);
};

export default SectionHeading;
