import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Button from 'components/button/button.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { createVenueService } from 'modules/venue/slices/venue.slice';

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	venueId: string;
	activeService: string;
}

// Venue service config service create form
const VenueServiceConfigCreateForm: FunctionComponent<IComponentProps> = ({
	venueId,
	activeService,
}) => {
	// Get use hooks
	const dispatch = useReduxDispatch();
	const history = useHistory();

	const handleSetupService = async () => {
		// Create service on venue
		const response = await dispatch(
			createVenueService(venueId, activeService)
		);

		// If error, do nothing
		if (!response) {
			return;
		}

		// refresh page
		history.go(0);
	};

	return (
		<>
			<h2>
				<FormattedMessage id="venueForm.headings.serviceConfig.serviceNotFound" />
			</h2>
			<StyledActions>
				<Link to="/venues">
					<Button variant="secondary">
						<FormattedMessage id="form.button.back" />
					</Button>
				</Link>
				<div>
					<Button variant="primary" onClick={handleSetupService}>
						<FormattedMessage id="form.button.configureServiceType" />
					</Button>
				</div>
			</StyledActions>
		</>
	);
};

export default VenueServiceConfigCreateForm;
