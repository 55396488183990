import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueSyncDetails,
	getZonalVenueSync,
} from 'modules/venue/slices/venue.slice';

interface IComponentProps {
	handleDelete: Function;
	venueId: string;
}

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledH2 = styled.h2`
	line-height: 24px;
	margin: 0 0 20px;
	font-size: ${fonts.sizes.large};
`;

const StyledVenueSyncItem = styled.div`
	font-size: ${fonts.sizes.standard};
	margin: 0 0 30px;

	&.mod-capitalise {
		text-transform: capitalize;
	}
`;

const StyledSubtitle = styled.h4`
	font-size: ${fonts.sizes.med};
	line-height: 24px;
	margin: 0 0 15px;
	text-transform: capitalize;
`;

const StyledErrors = styled.div`
	color: ${brand.text};
`;

const StyledSyncSuccess = styled.div`
	&.mod-success {
		color: ${brand.validation_success};
	}

	&.mod-error {
		color: ${brand.validation_error};
	}
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

// Venue sync form wrapper
const VenueSync: FunctionComponent<IComponentProps> = ({
	handleDelete,
	venueId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Get venue sync status from store
	const syncStatus = useSelector(
		(state: RootState) => state.venue.activeVenueSync!
	);
	// dispatch to get venue sync details
	useEffect(() => {
		const getData = async (id: string) => {
			await dispatch(getVenueSyncDetails(id));
		};
		getData(venueId);
	}, [dispatch, venueId]);

	const lastSyncSuccess = syncStatus?.zonalSyncStatus === 'Success';
	const currentSyncInProgress = !!syncStatus?.syncStartedAt;

	return (
		<StyledWrapper>
			<StyledFieldGroup>
				<StyledColumn>
					<StyledH2>
						<FormattedMessage id="venueSync.title.lastSync" />
					</StyledH2>
					<StyledVenueSyncItem>
						<StyledSubtitle>
							<FormattedMessage id="venueSync.detailsTitle.lastSyncStatus" />
						</StyledSubtitle>
						<StyledSyncSuccess
							className={`${
								lastSyncSuccess ? 'mod-success' : 'mod-error'
							}`}
						>
							{lastSyncSuccess && (
								<FormattedMessage id="venueSync.lastStatus.success" />
							)}
							{!lastSyncSuccess && (
								<>
									<FormattedMessage id="venueSync.lastStatus.error" />
									:
									<StyledErrors>
										{syncStatus?.zonalSyncStatus ? (
											syncStatus?.zonalSyncStatus
										) : (
											<FormattedMessage id="content.notAvailable" />
										)}
									</StyledErrors>
								</>
							)}
						</StyledSyncSuccess>
					</StyledVenueSyncItem>
					<StyledVenueSyncItem>
						<StyledSubtitle>
							<FormattedMessage id="venueSync.detailsTitle.lastSyncStarted" />
						</StyledSubtitle>
						{syncStatus?.lastSyncStartedAt ? (
							moment(syncStatus?.lastSyncStartedAt).format(
								'Do MMMM YYYY HH:mm:ss'
							)
						) : (
							<FormattedMessage id="content.notAvailable" />
						)}
					</StyledVenueSyncItem>
					<StyledVenueSyncItem>
						<StyledSubtitle>
							<FormattedMessage id="venueSync.detailsTitle.lastSyncFinished" />
						</StyledSubtitle>
						{syncStatus?.lastSyncFinishedAt ? (
							moment(syncStatus?.lastSyncFinishedAt).format(
								'Do MMMM YYYY HH:mm:ss'
							)
						) : (
							<FormattedMessage id="content.notAvailable" />
						)}
					</StyledVenueSyncItem>
				</StyledColumn>
				{currentSyncInProgress && (
					<StyledColumn>
						<StyledH2>
							<FormattedMessage id="venueSync.title.currentSync" />
						</StyledH2>
						<StyledVenueSyncItem>
							<StyledSubtitle>
								<FormattedMessage id="venueSync.detailsTitle.currentSyncStatus" />
							</StyledSubtitle>
							<StyledSyncSuccess className="mod-success">
								<FormattedMessage id="venueSync.currentSync.running" />
							</StyledSyncSuccess>
						</StyledVenueSyncItem>
						<StyledVenueSyncItem>
							<StyledSubtitle>
								<FormattedMessage id="venueSync.detailsTitle.currentSyncStarted" />
							</StyledSubtitle>
							{syncStatus?.syncStartedAt ? (
								moment(syncStatus?.syncStartedAt).format(
									'Do MMMM YYYY HH:mm:ss'
								)
							) : (
								<FormattedMessage id="content.notAvailable" />
							)}
						</StyledVenueSyncItem>
					</StyledColumn>
				)}
			</StyledFieldGroup>
			<StyledActions>
				<div>
					<Link to="/venues">
						<Button variant="secondary">
							<FormattedMessage id="form.button.back" />
						</Button>
					</Link>
					{venueId && (
						<Button
							variant="secondary"
							onClick={() => handleDelete()}
							ariaLabel="delete-button"
						>
							<FormattedMessage id="venueForm.button.delete" />
						</Button>
					)}
				</div>
				{!currentSyncInProgress && (
					<Button
						onClick={async () => {
							await dispatch(getZonalVenueSync(venueId));
							await dispatch(getVenueSyncDetails(venueId));
						}}
					>
						<FormattedMessage id="venueSync.button.startSync" />
					</Button>
				)}
			</StyledActions>
		</StyledWrapper>
	);
};

export default VenueSync;
