import MockAdapter from 'axios-mock-adapter/types';

import {
	IStaff,
	IStaffFormValues,
	IStaffRoles,
	IStaffRolesFormValues,
} from './staff.types';

import mockPagination from 'components/pagination/pagination.mock';
import mockAccounts from 'modules/account/account.mock';
import mockAuth from 'modules/auth/auth.mock';
import mockBrand from 'modules/brand/brand.mock';
import mockVenue from 'modules/venue/venue.mock';

/** MOCKED DATA */

const staffId: string = '6432245b-33c2-42fa-abaf-d694034b8aa7';

const staffList: IStaff[] = [
	{
		createdAt: '2020-05-15T14:32:28.625Z',
		deletedAt: null,
		email: 'bob.compton@checkfer.com',
		firstName: 'Bob',
		id: '6432245b-33c2-42fa-abaf-d694034b8aa7',
		lastName: 'Compton',
		avatarUrl: null,
	},
	{
		createdAt: '2020-05-15T14:32:28.625Z',
		deletedAt: null,
		email: 'andrew.adams@checkfer.com',
		firstName: 'Andrew',
		id: '64vfv55b-33c2-42fa-abaf-d694034b8aa7',
		lastName: 'Adam',
		avatarUrl: null,
	},
	{
		createdAt: '2020-05-15T14:32:28.625Z',
		deletedAt: null,
		email: 'zues.zelous@checkfer.com',
		firstName: 'Zues',
		id: '4fed245b-33c2-42fa-abaf-d694034b8aa7',
		lastName: 'Zelous',
		avatarUrl: null,
	},
	{
		createdAt: '2020-05-15T14:32:28.625Z',
		deletedAt: null,
		email: 'fellow.human@checkfer.com',
		firstName: 'Fellow',
		id: '1432245b-33c2-42fa-abaf-d694034b8aa7',
		lastName: 'Human',
		avatarUrl: null,
	},
];

const staffRoles: IStaffRoles = {
	accounts: [
		{
			accountId: mockAccounts.data.accountId,
			roles: ['OP_ACCESS', 'CMS_ACCESS'],
		},
	],
	brands: [
		{
			brandId: mockBrand.data.brand.id,
			roles: ['OP_ACCESS', 'CMS_ACCESS'],
		},
	],
	venues: [
		{
			venueId: mockVenue.data.venueId,
			roles: ['OP_ACCESS', 'CMS_ACCESS'],
		},
	],
};

/** MOCKED FORM VALUES */

const staffDetailsFormValues: IStaffFormValues = {
	email: staffList[0].email,
	firstName: staffList[0].firstName,
	lastName: staffList[0].lastName,
};

const staffRolesFormValues: IStaffRolesFormValues = {
	selectValues: {
		account: '',
		brand: '',
		venue: '',
	},
	accounts: [
		{
			accountId: mockAccounts.data.accountId,
			roles: { cmsAccess: true, oplAccess: true },
		},
	],
	brands: [
		{
			brandId: mockBrand.data.brand.id,
			roles: { cmsAccess: true, oplAccess: true },
		},
	],
	venues: [
		{
			venueId: mockVenue.data.venueId,
			roles: { cmsAccess: true, oplAccess: true },
		},
	],
};

/** MOCKED ENDPOINT CONFIG */

const getAllStaff = {
	response: {
		pagination: mockPagination.data.pagination,
		data: staffList,
	},
};

const getStaff = {
	response: staffList[0],
};

const createStaff = {
	request: {
		...staffDetailsFormValues,
		password: mockAuth.endpoints.createResetPassword.request.password,
		password_confirm:
			mockAuth.endpoints.createResetPassword.request.password,
		avatarUrl: '',
	},
};

const updateStaff = {
	request: {
		id: staffId,
		...staffDetailsFormValues,
	},
	response: {
		id: staffId,
		...staffDetailsFormValues,
	},
};

const updateStaffRoles = {
	request: {
		id: staffId,
		staffRoles,
	},
};

const getStaffRoles = {
	response: staffRoles,
};

/** ENDPOINT REGEX */

// Regex for user/uuid
export const staffEndpointRegex = new RegExp(
	/user\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/
);
// Regex for user/uuid/roles
export const staffRolesEndpointRegex = new RegExp(
	/user\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/access/
);

/** MOCKED ENDPOINTS */

export const mockedStaffEndpoints = (mockHttpClient: MockAdapter) => {
	// Mock list staff response
	mockHttpClient
		.onGet('user')
		.reply(200, mockStaff.endpoints.getAllStaff.response);

	// Mock update staff response
	mockHttpClient
		.onPost(staffEndpointRegex)
		.reply(200, mockStaff.endpoints.updateStaff.response);

	// Mock create/update staff response
	mockHttpClient
		.onPost(/user\/\w+/)
		.reply(200, mockStaff.endpoints.updateStaff.response);

	// Mock delete staff response
	mockHttpClient.onDelete(/user\/\w+/).reply(200);

	// Mock get staff roles response
	mockHttpClient
		.onGet(staffRolesEndpointRegex)
		.reply(200, mockStaff.endpoints.getStaffRoles.response);

	// Mock create/update staff response
	mockHttpClient.onPut(staffRolesEndpointRegex).reply(200);

	// Mock get staff response
	mockHttpClient
		.onGet(staffEndpointRegex)
		.reply(200, mockStaff.endpoints.getStaff.response);
};

const mockStaff = {
	data: {
		staffId,
		roles: staffRoles,
		staffRolesFormValues,
	},
	endpoints: {
		createStaff,
		getAllStaff,
		getStaff,
		updateStaff,
		updateStaffRoles,
		getStaffRoles,
	},
};
export default mockStaff;
