import { Formik, Form, FormikHelpers, Field } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, mixed } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import FileUpload from 'components/form-inputs/file-upload/file-upload.component';
import Input from 'components/form-inputs/input/input.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { importBeacons } from 'modules/beacon/beacon.slice';
import { IBeaconImportValues } from 'modules/beacon/beacon.types';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledH2 = styled.h2`
	line-height: 24px;
	margin: 0 0 25px;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledH3 = styled.h3`
	margin: 0 0 5px;
	line-height: 24px;
	font-size: ${fonts.sizes.med};
`;

const StyledBeaconResponse = styled.div`
	margin: 5px 0 0;
	h4 {
		margin: 0;
		font-size: ${fonts.sizes.small};
		line-height: 18px;
	}
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button {
		margin-left: auto;
	}
`;

// Beacon import form
const BeaconImportForm: FunctionComponent = () => {
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);
	// Get dispatch from helper
	const dispatch = useReduxDispatch();
	// Get beacon response from state
	const beaconResults = useSelector(
		(state: RootState) => state.beacon.importBeacons
	);

	// Login validation schema
	const formValidationSchema = object<IBeaconImportValues>().shape({
		beacons: mixed().required(validationMessages.file('beacons')),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IBeaconImportValues,
		{ setSubmitting }: FormikHelpers<IBeaconImportValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// dispatch import beacons
		await dispatch(importBeacons(values)).then(async (payload) => {
			if (!payload) return;

			await dispatch(
				// Add alert
				addAlert({
					title: intl.formatMessage({
						id: 'alerts.success.title',
					}),
					message: intl.formatMessage({
						id: 'operations.alerts.beacons.success.message',
					}),
					type: 'success',
				})
			);

			// Set form submission to true to remove routing prompt
			setFormSubmission(true);
		});

		// Set formik submission state to false
		setSubmitting(false);
	};
	return (
		<>
			<Formik
				enableReinitialize
				initialValues={{}}
				validationSchema={formValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ dirty, setFieldValue, values, errors, isSubmitting }) => (
					<StyledForm encType="multipart/form-data">
						<Prompt when={dirty && !formSubmission} message="" />
						<StyledH2>
							<FormattedMessage id="operations.headings.beaconImport" />
						</StyledH2>
						<StyledFieldGroup>
							<StyledColumn>
								<StyledH3>
									<FormattedMessage id="operations.beaconImport.title.beacon" />
								</StyledH3>
								<FileUpload
									fileFormats=".csv"
									fieldName="beacons"
									setFieldValue={setFieldValue}
									errors={errors.beacons}
								/>
								<Field
									component={Input}
									name="uuidKey"
									label={intl.formatMessage({
										id: 'form.fields.uuidKey.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.uuidKey.label',
									})}
								/>
								<Field
									component={Input}
									name="majorKey"
									label={intl.formatMessage({
										id: 'form.fields.majorKey.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.majorKey.label',
									})}
								/>
								<Field
									component={Input}
									name="minorKey"
									label={intl.formatMessage({
										id: 'form.fields.minorKey.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.minorKey.label',
									})}
								/>
								<Field
									component={Input}
									name="locationIdKey"
									label={intl.formatMessage({
										id: 'form.fields.locationIdKey.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.locationIdKey.label',
									})}
								/>
								<Field
									component={Input}
									name="identifierKey"
									label={intl.formatMessage({
										id: 'form.fields.identifierKey.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.identifierKey.label',
									})}
								/>
							</StyledColumn>
							<StyledColumn>
								<StyledH3>
									<FormattedMessage id="operations.beaconImport.title.results" />
								</StyledH3>
								{formSubmission ? (
									<StyledBeaconResponse>
										<h4>
											<FormattedMessage id="operations.beaconImport.results.validated" />
											: {beaconResults?.validated}
										</h4>
										<h4>
											<FormattedMessage id="operations.beaconImport.results.syncing" />
											: {beaconResults?.syncing}
										</h4>
									</StyledBeaconResponse>
								) : (
									<p>
										<FormattedMessage id="operations.beaconImport.results.empty" />
									</p>
								)}
							</StyledColumn>
						</StyledFieldGroup>
						<StyledActions>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="operations.buttons.processBeacons" />
							</Button>
						</StyledActions>
					</StyledForm>
				)}
			</Formik>
		</>
	);
};

export default BeaconImportForm;
