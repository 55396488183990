import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { IVenueItem } from '../venue.types';
import VenuesListItem from './venues-list-item/venues-list-item.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

interface IVenuesList {
	venues: IVenueItem[];
}

const StyledWrapper = styled.section`
	width: 100%;
	padding: 15px;
	border-radius: 10px;
	background: ${brand.white};

	h3 {
		margin: 0;
	}
`;

const StyledHeadings = styled.div`
	padding: 0 10px;
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	padding-left: 5px;
	user-select: none;

	:focus {
		outline: none;
	}

	&:nth-child(1n) {
		width: calc(25% - 120px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		text-align: center;
		margin: 0 10px 0 0;
	}

	&:nth-last-child(2) {
		width: 50px;
		min-width: 50px;
		text-align: center;
		margin: 0 20px 0 auto;
	}
`;

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

/** Renders venues list component */
const VenuesList: FunctionComponent<IVenuesList> = ({ venues }) => {
	/** Empty message component */
	const EmptyMessage: FunctionComponent = () => {
		return (
			<h3 aria-label="venues-empty">
				<FormattedMessage id="venuesList.empty" />
			</h3>
		);
	};

	return (
		<StyledWrapper>
			{venues.length ? (
				<>
					<StyledHeadings>
						<StyledHeading>
							<FormattedMessage id="venuesList.headings.name" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="venuesList.headings.brandName" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="venuesList.headings.city" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="venuesList.headings.published.app" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="venuesList.headings.published.web" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="venuesList.headings.menu" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="venuesList.headings.edit" />
						</StyledHeading>
					</StyledHeadings>
					<StyledList aria-label="venues-list">
						{venues.map((item) => (
							<VenuesListItem item={item} key={item.id} />
						))}
					</StyledList>
				</>
			) : (
				<EmptyMessage />
			)}
		</StyledWrapper>
	);
};

export default VenuesList;
