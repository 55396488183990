import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';

import VenueServiceConfigPosComtrexForm from './venue-service-config-pos-comtrex-form.components';
import VenueServiceConfigPosZonalForm from './venue-service-config-pos-zonal-form.components';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Select from 'components/form-inputs/select/select.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	getVenueServicePosConfig,
	updateVenueServicePosConfig,
} from 'modules/venue/slices/venue.slice';
import {
	EPosOption,
	IVenueServicePosConfigFormValues,
	IVenueServicePosConfigUpdate,
} from 'modules/venue/venue.types';

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	margin: 0 0 25px;
	padding: 0 0 25px;
	border-bottom: 1px solid ${brand.body};

	&.flex-start {
		justify-content: flex-start;
	}
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	activeService: string;
	handleDelete: Function;
	deleteServiceButtonText: string;
	formSubmission: boolean;
	setFormSubmission: Function;
	venueId: string;
}

interface ISelectChangeValue {
	fieldValue: EPosOption;
}

const VenueServiceConfigPosForm: FunctionComponent<IComponentProps> = ({
	activeService,
	handleDelete,
	deleteServiceButtonText,
	formSubmission,
	setFormSubmission,
	venueId,
}) => {
	const dispatch = useReduxDispatch();

	const posOptions = [
		{ label: 'None', value: EPosOption.None },
		{ label: 'Zonal', value: EPosOption.Zonal },
		{ label: 'Comtrex', value: EPosOption.Comtrex },
	];

	useEffect(() => {
		const getData = async (id: string) => {
			await dispatch(getVenueServicePosConfig(id, activeService));
		};

		venueId && getData(venueId);
	}, [dispatch, venueId, activeService]);

	// Get active service pos config from state
	const posConfig = useSelector(
		(state: RootState) => state.venue.activeVenueServiceConfig?.pos
	);

	const [venuePos, setVenuePos] = useState<EPosOption>(
		posConfig?.pos ?? EPosOption.None
	);
	const posConfigOption = venuePos ?? posConfig?.pos;
	useEffect(() => {
		if (posConfig?.pos) {
			setVenuePos(posConfig.pos);
		}
	}, [posConfig]);

	// Handle form submission
	const handleSubmit = async (
		values: IVenueServicePosConfigFormValues,
		{ setSubmitting }: FormikHelpers<IVenueServicePosConfigFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		const body: IVenueServicePosConfigUpdate = {};
		if (venuePos === EPosOption.Zonal) {
			body.zonalSiteId = parseFloat(values.zonalSiteId);
			body.zonalSalesAreaId = parseFloat(values.zonalSalesAreaId);
		} else if (venuePos === EPosOption.Comtrex) {
			body.comtrexConfig = {
				ipAddress: values.comtrexIpAddress,
				portNumber: parseFloat(values.comtrexPortNumber),
				endpoint: values.comtrexEndpoint,
				priceBandId: values.comtrexPriceBand,
				vendorName: values.comtrexVendorName,
			};
		}

		// Update venue service pos config
		const response = await dispatch(
			updateVenueServicePosConfig(venueId, activeService, body)
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	const initialValues: IVenueServicePosConfigFormValues = {
		zonalSiteId: posConfig?.zonalSiteId?.toString() || '',
		zonalSalesAreaId: posConfig?.zonalSalesAreaId?.toString() || '',
		comtrexIpAddress: posConfig?.ipAddress || '',
		comtrexPortNumber: posConfig?.portNumber?.toString() || '',
		comtrexEndpoint: posConfig?.endpoint || '',
		comtrexPriceBand: posConfig?.priceBandId || '',
		comtrexVendorName: posConfig?.vendorName || '',
	};

	const selectedPosValue = posOptions.find(
		({ value }) => value === posConfigOption
	);

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting, values }) => (
				<Form>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="venueForm.services.pos.title" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								size="sm"
								testId="epos-select"
								formElementVariant="header"
								component={Select}
								handleChangeEvent={(
									value: ISelectChangeValue
								) => {
									setVenuePos(value.fieldValue);
								}}
								placeholder={intl.formatMessage({
									id: 'form.fields.eposSelect.label',
								})}
								value={selectedPosValue}
								name="venueServicePos"
								selectOptions={posOptions}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					{selectedPosValue?.value === EPosOption.Zonal && (
						<VenueServiceConfigPosZonalForm
							venueId={venueId}
							values={values}
							posConfig={posConfig}
						/>
					)}

					{selectedPosValue?.value === EPosOption.Comtrex && (
						<VenueServiceConfigPosComtrexForm
							venueId={venueId}
							values={values}
							posConfig={posConfig}
						/>
					)}
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							<Button
								variant="secondary"
								onClick={() => handleDelete()}
								ariaLabel="delete-button"
							>
								{deleteServiceButtonText}
							</Button>
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</Form>
			)}
		</Formik>
	);
};

export default VenueServiceConfigPosForm;
