import axios, { AxiosError, AxiosResponse } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { Store } from 'redux';

import { handleApiError } from '../error/error.service';
import { history } from '../routing/app-router.component';

import { mockedAccountEndpoints } from 'modules/account/account.mock';
import { mockedAuthEndpoints } from 'modules/auth/auth.mock';
import { processLogout } from 'modules/auth/auth.slice';
import { mockedBeaconEndpoints } from 'modules/beacon/beacon.mock';
import { mockedBrandEndpoints } from 'modules/brand/brand.mock';
import { mockedMenuEndpoints } from 'modules/menu/menu.mock';
import { mockedOperationsEndpoints } from 'modules/operations/operations.mock';
import { mockedOrderEndpoints } from 'modules/order/order.mock';
import { mockedPaymentEndpoints } from 'modules/payment/payment.mock';
import { mockedReportEndpoints } from 'modules/report/report.mock';
import { mockedStaffEndpoints } from 'modules/staff/staff.mock';
import { mockedVenueEndpoints } from 'modules/venue/venue.mock';

/** Create and configure axios instance */
const httpClient = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	timeout: parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!),
	withCredentials: true,
});

/** Create a mocked axios client */
export const mockHttpClient = new MockAdapter(httpClient, {
	// Only mock matched/configured routes
	onNoMatch: 'passthrough',
});

/** Mocked endpoints */
const mockedEndpoints = () => {
	// Payment mocks
	mockedPaymentEndpoints(mockHttpClient);
	// Auth mocks
	mockedAuthEndpoints(mockHttpClient);
	// Account mocks
	mockedAccountEndpoints(mockHttpClient);
	// Staff mocks
	mockedStaffEndpoints(mockHttpClient);
	// Brand mocks
	mockedBrandEndpoints(mockHttpClient);
	// Beacon mocks
	mockedBeaconEndpoints(mockHttpClient);
	// Venue mocks
	mockedVenueEndpoints(mockHttpClient);
	// Menu mocks
	mockedMenuEndpoints(mockHttpClient);
	// Order mocks
	mockedOrderEndpoints(mockHttpClient);
	// Report mocks
	mockedReportEndpoints(mockHttpClient);
	// Operations mocks
	mockedOperationsEndpoints(mockHttpClient);
};
// If api mocking is enabled - mock api
process.env.REACT_APP_API_MOCKING === 'true' && mockedEndpoints();

// Axios redux middleware config
export const httpMiddlewareConfig = {
	interceptors: {
		response: [
			{
				async error({ getState, dispatch }: Store, error: AxiosError) {
					// If, error was a 401
					if (error?.response?.status === 401) {
						// If access token refreshing
						if (getState().auth.refreshToken.refreshing) {
							// Retry the request 3 times
							for (let i = 0; i < 3; i++) {
								// eslint-disable-next-line no-await-in-loop
								const retry = await retryAxiosRequest(error);

								if (retry.status === 200) {
									return retry;
								}
							}
						}

						// If user logged in
						if (getState().auth?.user?.id) {
							// Call logout thunk
							// @ts-ignore
							await dispatch(processLogout());
							// Redirect user to login
							history.push('/');
						}
					}

					return handleApiError(error);
				},
			},
		],
	},
};

// Retry a failed axios request after timeout/3
export const retryAxiosRequest = async (
	error: AxiosError
): Promise<AxiosResponse> => {
	// Divide the axios timeout by 3
	const retryWaitTime = parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!) / 3;

	return new Promise((resolve) =>
		setTimeout(() => {
			// Find out the endpoint which was called and retry
			resolve(axios(error.config));
		}, retryWaitTime)
	);
};

// Export auth API as object
export default httpClient;
