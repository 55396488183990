import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import { IVenueItem } from 'modules/venue/venue.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-child(1n) {
		width: calc(25% - 120px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		text-align: center;
		margin: 0;
	}

	&:nth-last-child(2) {
		width: 50px;
		min-width: 50px;
		text-align: center;
		margin: 0 10px 0 auto;
	}
`;

// Props for component
interface IVenuesListItemProps {
	item: IVenueItem;
}
// Venues list item component
const VenuesListItem: FunctionComponent<IVenuesListItemProps> = ({ item }) => {
	return (
		<StyledListItem key={item.id} aria-label="venues-item">
			<StyledColumn>{item.name}</StyledColumn>
			<StyledColumn>{item.brand.name}</StyledColumn>
			<StyledColumn>{item.address.city}</StyledColumn>
			<StyledColumn>
				<FormattedMessage
					id={`venuesListItem.content.published.${
						item.config.isPublished ? 'on' : 'off'
					}`}
				/>
			</StyledColumn>
			<StyledColumn>
				<FormattedMessage
					id={`venuesListItem.content.published.${
						item.config.isPublishedForWeb ? 'on' : 'off'
					}`}
				/>
			</StyledColumn>
			<StyledColumn>
				<Link
					to={`/menus/menus?activeAccount=${item.account}&brandId=${item.brand.id}&venueId=${item.id}`}
				>
					<Icon name="menu" colour="primary" />
				</Link>
			</StyledColumn>
			<StyledColumn>
				<Link to={`/venues/edit/${item.id}/details/details`}>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledListItem>
	);
};

export default VenuesListItem;
