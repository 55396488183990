import { Field, Formik, Form, FormikProps } from 'formik';
import queryString from 'query-string';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

import { getOrdersList, resetOrderState } from './order.slice';
import { IOrderListFilters } from './order.types';
import OrdersList from './orders-list/orders-list.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Pagination from 'components/pagination/pagination.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getAccountsList } from 'modules/account/account.slice';
import { IAccount } from 'modules/account/account.types';
import { getBrandsList } from 'modules/brand/brand.slice';
import { IBrandItem } from 'modules/brand/brand.types';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import withNav from 'modules/navigation/with-nav.component';
import { getVenuesList } from 'modules/venue/slices/venue.slice';
import { IVenueItem } from 'modules/venue/venue.types';

// Interface for form values
interface ISearchFormValues {
	search: string;
	account: string;
	brand: string;
	venue: string;
}

interface ISelectChangeValue {
	fieldValue: string;
	form: FormikProps<ISearchFormValues>;
}

const StyledInput = styled(Input)`
	margin: 0 10px 0 0;
	max-width: 160px;

	input {
		height: 37px;
		line-height: 37px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.sub-actions {
		display: flex;
		align-items: center;

		Button {
			margin: 0;
			flex-shrink: 0;
		}
	}
`;

const StyledForm = styled(Form)`
	display: flex;
	align-items: center;
`;

/** Renders order page component */
const OrdersPage: FunctionComponent = () => {
	// define variables
	const dispatch = useReduxDispatch();
	// Get history and location
	const history = useHistory();
	const location = useLocation();
	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Searchterm state
	const [searchTerm, setSearchTerm] = useState<string>(query.query || '');
	// Debounce searchTerm changes
	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	// Initial form values
	const initialValues: ISearchFormValues = {
		search: query.query || '',
		account: query.accountIds || '',
		brand: query.brandIds || '',
		venue: query.venueIds || '',
	};

	// Get orders and pagination from state
	const { orders, pagination } = useSelector(
		(state: RootState) => state.order
	);

	// Get account options from state
	const accountOptions: IOption[] = useSelector(
		(state: RootState) => state.account.accounts
	).map((value: IAccount) => ({
		label: value.name,
		value: value.id,
	}));

	// Get brand options from state
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get venue options from state
	const venueOption: IOption[] = useSelector(
		(state: RootState) => state.venue.venues
	).map((value: IVenueItem) => ({
		label: value.name,
		value: value.id,
	}));

	// Get initial accounts list and orders list
	useEffect(() => {
		const getData = async () => {
			// Get accounts list
			await dispatch(getAccountsList());

			// create order filters
			const filters: IOrderListFilters = {
				accountIds: query.accountIds,
				brandIds: query.brandIds,
				venueIds: query.venueIds,
				query: query.query,
				pageNumber: parseFloat(query.pageNumber!) || 1,
			};

			// Get brand list with account filter
			await dispatch(
				getBrandsList({ accountUUID: query.accountIds, pageSize: 200 })
			);
			// Get venue list with venue filter
			await dispatch(getVenuesList({ brandId: query.brandIds }));

			if (query.venueIds && query.accountIds && query.brandIds) {
				// Get order filters list
				await dispatch(getOrdersList(filters));
			}
		};

		getData();

		// useEffect cleanup
		return () => {
			dispatch(resetOrderState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		query.query,
		query.accountIds,
		query.brandIds,
		query.venueIds,
		query.pageNumber,
	]);

	// Function to filter orders and change route
	const filterOrders = ({
		accountIds = query.accountIds,
		brandIds = query.brandIds,
		venueIds = query.venueIds,
	}) => {
		// create order filters
		const filters: IOrderListFilters = {};

		// add filters if available
		!!accountIds && (filters.accountIds = accountIds);
		!!brandIds && (filters.brandIds = brandIds);
		!!venueIds && (filters.venueIds = venueIds);

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	const handlePagination = (pageNumber: number = 1) => {
		// Create list of filters
		const filters = {
			...query,
			pageNumber,
		};

		// change route
		history.push(`${location.pathname}?${queryString.stringify(filters)}`);
	};

	// Handle search change
	const handleSearch = useCallback(
		(search: string, currentParams: IQueryParams) => {
			// Create list of filters
			const filters = currentParams;

			// remove page number on search
			delete filters.pageNumber;

			search ? (filters.query = search) : delete filters.query;

			// change route
			history.push(
				`${location.pathname}?${queryString.stringify(filters)}`
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query.query]
	);

	// useEffect for handling search
	useEffect(() => {
		handleSearch(debouncedSearchTerm, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, debouncedSearchTerm]);

	return (
		<>
			<StyledHeader>
				<SectionHeading
					title="orders.title"
					subtitle="orders.subtitle"
				/>
				<div className="sub-actions">
					<Formik initialValues={initialValues} onSubmit={() => {}}>
						{() => {
							return (
								<StyledForm>
									<div data-testid="account-select">
										<Field
											size="sm"
											formElementVariant="header"
											component={Select}
											value={accountOptions.find(
												(account) =>
													account.value ===
													query.accountIds
											)}
											handleChangeEvent={async (
												value: ISelectChangeValue
											) =>
												filterOrders({
													accountIds:
														value.fieldValue || '',
												})}
											isSearchable={true}
											placeholder={intl.formatMessage({
												id: 'form.fields.account.label',
											})}
											name="account"
											isClearable={true}
											selectOptions={accountOptions}
										/>
									</div>
									<div data-testid="brand-select">
										<Field
											size="sm"
											isDisabled={!query.accountIds}
											value={brandOptions.find(
												(brandOpt) =>
													brandOpt.value ===
													query.brandIds
											)}
											formElementVariant="header"
											component={Select}
											handleChangeEvent={async (
												value: ISelectChangeValue
											) =>
												filterOrders({
													brandIds:
														value.fieldValue || '',
												})}
											isSearchable={true}
											placeholder={intl.formatMessage({
												id: 'form.fields.brand.label',
											})}
											name="brand"
											isClearable={true}
											selectOptions={brandOptions}
										/>
									</div>
									<div data-testid="venue-select">
										<Field
											size="sm"
											isDisabled={
												!query.brandIds ||
												!query.accountIds
											}
											value={venueOption.find(
												(venue) =>
													venue.value ===
													query.venueIds
											)}
											formElementVariant="header"
											component={Select}
											handleChangeEvent={async (
												value: ISelectChangeValue
											) =>
												filterOrders({
													venueIds:
														value.fieldValue || '',
												})}
											isSearchable={true}
											placeholder={intl.formatMessage({
												id: 'form.fields.venue.label',
											})}
											name="venue"
											isClearable={true}
											selectOptions={venueOption}
										/>
									</div>
									<Field
										component={StyledInput}
										name="search"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={12}
										iconHeight={12}
										handleChangeEvent={(value: string) =>
											setSearchTerm(value)}
									/>
								</StyledForm>
							);
						}}
					</Formik>
				</div>
			</StyledHeader>
			<OrdersList orders={orders} />
			{pagination && pagination.pageCount > 1 && (
				<Pagination
					pageNumber={
						query.pageNumber ? parseFloat(query.pageNumber) : 1
					}
					pageCount={pagination.pageCount}
					setPageNumber={handlePagination}
				/>
			)}
		</>
	);
};

export default withNav(OrdersPage);
