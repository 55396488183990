import { FieldProps } from 'formik';
import get from 'lodash.get';
import React, {
	FunctionComponent,
	InputHTMLAttributes,
	ChangeEvent,
} from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';

// Interface for component props
interface ICheckboxProps {
	label?: string;
	handleChangeEvent?: Function;
	uncheckedColour?: keyof typeof brand;
	border?: boolean;
	checked?: boolean;
	isDisabled?: boolean;
}

const StyledFormElement = styled(FormElement)`
	margin-bottom: 20px;

	& > div {
		padding: 0;
	}
`;

const StyledWrapper = styled.div`
	display: flex;
`;

const StyledLabel = styled.label`
	display: block;
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.regular};
	line-height: 18px;
	user-select: none;
`;

// Create styled Checkbox component
const StyledCheckbox = styled.label<ICheckboxProps>`
	width: 18px;
	height: 18px;
	margin-right: 10px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ uncheckedColour }) =>
		uncheckedColour ? brand[uncheckedColour] : brand.body};
	position: relative;
	overflow: hidden;
	cursor: pointer;
	user-select: none;

	${({ border, checked }) =>
		border &&
		!checked &&
		css`
			border: 1px solid ${brand.borders};
		`};

	input {
		width: 0;
		height: 0;
		opacity: 0;
		cursor: pointer;
		position: absolute;
	}
`;

const StyledChecked = styled.div`
	width: 100%;
	height: 100%;
	background: ${brand.primary};
	display: flex;
	align-items: center;
	justify-content: center;
`;

/** Renders Checkbox component */
const Checkbox: FunctionComponent<
	ICheckboxProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	field,
	form,
	label,
	handleChangeEvent,
	className,
	uncheckedColour,
	border,
	isDisabled,
}) => {
	const { touched, errors } = form;
	const isTouched = get(touched, field.name) || false;
	const hasErrors = get(errors, field.name) || false;

	return (
		<StyledFormElement className={className} isDisabled={isDisabled}>
			<StyledWrapper>
				<StyledCheckbox
					htmlFor={field.name}
					uncheckedColour={uncheckedColour}
					checked={field.value}
					border={border}
				>
					<input
						{...field}
						type="checkbox"
						checked={field.value}
						id={field.name}
						aria-label={`${field.name}-checkbox`}
						data-testid="checkbox"
						disabled={isDisabled}
						onChange={(
							changeEvent: ChangeEvent<HTMLInputElement>
						) => {
							form.setFieldValue(
								field.name,
								changeEvent.target.checked
							);
							if (handleChangeEvent) {
								handleChangeEvent(changeEvent.target.checked);
							}
						}}
					/>
					{field.value === true && (
						<StyledChecked>
							<Icon
								name="checkbox"
								colour="white"
								width={18}
								height={18}
							/>
						</StyledChecked>
					)}
				</StyledCheckbox>
				{label && (
					<StyledLabel htmlFor={field.name}>{label}</StyledLabel>
				)}
			</StyledWrapper>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</StyledFormElement>
	);
};

export default Checkbox;
