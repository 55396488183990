import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { IOrder } from '../order.types';
import OrdersListItem from './orders-list-item/orders-list-item.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

interface IOrdersList {
	orders: IOrder[];
}

const StyledWrapper = styled.section`
	width: 100%;
	padding: 15px;
	border-radius: 10px;
	background: ${brand.white};

	h3 {
		margin: 0;
	}
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	justify-content: space-between;
	user-select: none;
	padding: 0 0 0 15px;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	text-align: right;
	width: 100%;
	max-width: 110px;
	cursor: pointer;

	:focus {
		outline: none;
	}

	&:nth-of-type(4),
	&:nth-of-type(5) {
		max-width: 270px;
	}

	&:nth-of-type(6),
	&:nth-of-type(7) {
		max-width: 130px;
	}

	&:last-child {
		min-width: 40px;
		max-width: 40px;
	}
`;

const StyledOrdersList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

/** Renders orders list component */
const OrdersList: FunctionComponent<IOrdersList> = ({ orders }) => {
	/** Empty message component */
	const EmptyMessage: FunctionComponent = () => {
		return (
			<h3 aria-label="orders-empty">
				<FormattedMessage id="ordersList.empty" />
			</h3>
		);
	};

	return (
		<StyledWrapper>
			{orders.length ? (
				<>
					<StyledHeadings>
						<StyledHeading>
							<FormattedMessage id="ordersList.heading.id" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="ordersList.heading.collectionId" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="ordersList.heading.date" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="ordersList.heading.name" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="ordersList.heading.email" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="ordersList.heading.status" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="ordersList.heading.price" />
						</StyledHeading>
						<StyledHeading>
							<FormattedMessage id="ordersList.heading.more" />
						</StyledHeading>
					</StyledHeadings>
					<StyledOrdersList aria-label="accounts-list">
						{orders.map((item) => (
							<OrdersListItem item={item} key={item.id} />
						))}
					</StyledOrdersList>
				</>
			) : (
				<EmptyMessage />
			)}
		</StyledWrapper>
	);
};

export default OrdersList;
