import { FieldProps } from 'formik';
import React, {
	FunctionComponent,
	InputHTMLAttributes,
	ChangeEvent,
} from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';

// Interface for component props
interface IComponentProps {
	label?: string;
	handleChangeEvent?: Function;
	checkedColour?: keyof typeof brand;
	checked?: boolean;
	disabled?: boolean;
}

const StyledFormElement = styled(FormElement)`
	margin-bottom: 20px;

	& > div {
		padding: 0;
		align-items: center;
	}
`;

const StyledWrapper = styled.div`
	display: flex;
	position: relative;
	padding-right: 65px;

	&:active label:before {
		transform: scale(0.8);
		transition: 300ms ease-out;
	}
`;

const StyledLabel = styled.label`
	width: 100%;
	display: block;
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.medium};
	line-height: 22px;
	user-select: none;
`;

const StyledRadio = styled.label<IComponentProps>`
	position: relative;
	padding-left: 37px;
	height: 26px;

	&:before {
		width: 26px;
		height: 26px;
		margin-right: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 3px solid ${brand.primary};
		border-radius: 50%;
		box-sizing: border-box;
		overflow: hidden;
		cursor: pointer;
		user-select: none;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
	}

	&:after {
		${({ checked, checkedColour }) =>
		checked &&
			css`
				content: '';
				display: block;
				background: ${brand.primary};
				width: 16px;
				height: 16px;
				border-radius: 50%;
				position: absolute;
				left: 5px;
				top: 5px;
			`};
	}

	input {
		width: 0;
		height: 0;
		opacity: 0;
		cursor: pointer;
		position: absolute;
	}
`;

/** Renders radio button component */
const Radio: FunctionComponent<
	IComponentProps & FieldProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	field,
	form,
	label,
	handleChangeEvent,
	className,
	checkedColour,
	id,
	disabled,
	value,
}) => {
	const { touched, errors } = form;
	const isTouched = touched?.[`${field.name}`] || false;
	const hasErrors = errors?.[`${field.name}`] || false;

	return (
		<StyledFormElement className={className} isDisabled={disabled}>
			<StyledWrapper>
				<StyledRadio
					htmlFor={id}
					checkedColour={checkedColour}
					disabled={disabled}
					checked={field.value === value}
				>
					<input
						{...field}
						type="radio"
						value={value}
						id={id}
						disabled={disabled}
						aria-label={`${field.name}-radio`}
						data-testid="radio"
						onChange={(
							changeEvent: ChangeEvent<HTMLInputElement>
						) => {
							form.setFieldValue(field.name, value);
							if (handleChangeEvent) {
								handleChangeEvent();
							}
						}}
					/>
				</StyledRadio>
				{label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
			</StyledWrapper>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</StyledFormElement>
	);
};

export default Radio;
