import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import SortableMiniList from 'components/form-inputs/sortable-mini-list/sortable-mini-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	createCategory,
	deleteCategory,
	getMenuList,
	patchCategoryDetails,
} from 'modules/menu/menu.slice';
import { ICategoryDetailsFormValues } from 'modules/menu/menu.types';
import { IPrintCategoryOptions } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	categoryId?: string | undefined;
}

const CategoryDetailsForm: FunctionComponent<IComponentProps> = ({
	categoryId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const history = useHistory();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active category details from store
	const categoryDetails = useSelector(
		(state: RootState) => state.menu.activeCategoryDetails
	);

	// Get menu list and map to option values
	const menuOptions: IOption[] =
		useSelector((state: RootState) => state.menu.menus)?.map((menu) => {
			return {
				value: menu.id,
				label: menu.title,
			};
		}) || [];

	// Create form options
	const receiptPriorityOptions: IPrintCategoryOptions[] = [
		{ label: '0', value: 0 } as const,
		{ label: '1', value: 1 } as const,
		{ label: '2', value: 2 } as const,
		{ label: '3', value: 3 } as const,
		{ label: '4', value: 4 } as const,
		{ label: '5', value: 5 } as const,
		{ label: '6', value: 6 } as const,
		{ label: '7', value: 7 } as const,
		{ label: '8', value: 8 } as const,
		{ label: '9', value: 9 } as const,
	];

	useEffect(() => {
		const getData = async () => {
			await dispatch(getMenuList({ pageSize: -1 }));
		};

		getData();
	}, [dispatch]);

	// Initial form values
	const initialValues: ICategoryDetailsFormValues = {
		title: (categoryId && categoryDetails?.title) || '',
		reference: (categoryId && categoryDetails?.reference) || '',
		menu: (categoryId && categoryDetails?.menu?.id) || '',
		receiptPriority: (categoryId && categoryDetails?.receiptPriority) || 0,
		products:
			(categoryId &&
				categoryDetails?.productDetails?.map((product) => ({
					id: product.id,
					title: `${product.name}${
						product.sku ? `(${product.sku})` : ''
					}`,
				}))) ||
			[],
	};

	// Handle form submission
	const handleSubmit = async (
		values: ICategoryDetailsFormValues,
		{ setSubmitting }: FormikHelpers<ICategoryDetailsFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		const formValues = {
			title: values.title,
			reference: values.reference,
			parentId: values.menu || '',
			receiptPriority: values.receiptPriority,
			products: values.products?.map((product) => product.id) || [],
		};

		// Create / update
		const response = await (categoryId
			? dispatch(patchCategoryDetails(categoryId, formValues))
			: dispatch(createCategory(formValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: categoryId
					? intl.formatMessage({
						id: 'categoryForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'categoryForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		!categoryId &&
			history.push(
				`/menus/category/edit/${response?.id}/details?activeAccount=${activeAccountId}`
			);
	};

	// Handle deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'categoryForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'categoryForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete action
		const response =
			categoryId && (await dispatch(deleteCategory(categoryId)));

		// Return if fail
		if (!response) {
			return;
		}
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'categoryForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to categories
		history.push(`/menus/categories?activeAccount=${activeAccountId}`);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, values, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="categoryForm.headings.details" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={Input}
								name="title"
								label={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
							/>
							<Field
								component={Input}
								name="reference"
								label={intl.formatMessage({
									id: 'form.fields.reference.label',
								})}
							/>
							<Field
								component={Select}
								name="menu"
								selectOptions={menuOptions}
								label={intl.formatMessage({
									id: 'form.fields.menuCategory.label',
								})}
								value={menuOptions.filter(
									(option) => option.value === values.menu
								)}
							/>
							<Field
								component={Select}
								name="receiptPriority"
								selectOptions={receiptPriorityOptions}
								label={intl.formatMessage({
									id: 'form.fields.receiptPriority.label',
								})}
								value={receiptPriorityOptions.filter(
									(option) =>
										option.value === values.receiptPriority
								)}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={SortableMiniList}
								name="products"
								label={intl.formatMessage({
									id:
										'form.fields.categoryProductsOrder.label',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link
								to={`/menus/categories?activeAccount=${activeAccountId}`}
							>
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{categoryId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="categoryForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage
								id={
									categoryId
										? 'form.button.save'
										: 'categoryForm.button.create'
								}
							/>
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default CategoryDetailsForm;
