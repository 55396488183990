import currency from 'currency.js';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import SortableMiniList from 'components/form-inputs/sortable-mini-list/sortable-mini-list.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { formatPrice, hasPriceBands } from 'helpers/price-band.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	createProduct,
	deleteProduct,
	getCategoriesList,
	patchProductDetails,
} from 'modules/menu/menu.slice';
import {
	IProductDetailsFormValues,
	IProductDetailsSubmitValues,
} from 'modules/menu/menu.types';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	productId?: string | undefined;
}
// Interface for form errors
interface IFormValidation {
	name?: string;
}

const ProductDetailsForm: FunctionComponent<IComponentProps> = ({
	productId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const history = useHistory();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		const getData = async () => {
			await dispatch(getCategoriesList({ pageSize: -1 }));
		};

		getData();
	}, [dispatch]);

	// Get active product details from store
	const productDetails = useSelector(
		(state: RootState) => state.menu.activeProductDetails
	);

	// get allergens and dietary from state
	const { allergens, dietary } = useSelector(
		(state: RootState) => state?.menu?.preferences
	);

	// Get categories lsit from store
	const categories = useSelector((state: RootState) => state.menu.categories);

	// Map allergens to options
	const allergenOptions = allergens.map((option) => {
		return {
			value: option.id,
			label: option.title,
		};
	});

	// Map allergens to options
	const dietaryOptions = dietary.map((option) => {
		return {
			value: option.id,
			label: option.title,
		};
	});

	// map category to options
	const categoryOptions = categories?.map((option) => {
		return {
			value: option.id,
			label: `${option.title} (${option.reference})`,
		};
	});

	// Create initial dietary values from Dietary options and current form values
	const initialDietaryValues: IOption[] = dietaryOptions.filter((option) => {
		return productDetails?.dietaryPreferences?.includes(option.value);
	});

	// Create initial allergen values from Dietary options and current form values
	const initialAllergenValues: IOption[] = allergenOptions.filter(
		(option) => {
			return productDetails?.allergens?.includes(option.value);
		}
	);

	// Create initial category option values from categories list and current form values
	const initialCategoryOptions: IOption[] =
		categoryOptions?.filter((option) => {
			return productDetails?.categories?.includes(option.value);
		}) || [];

	// convert product price to decimal
	const productPrice = !hasPriceBands(productDetails)
		? formatPrice(productDetails?.price, '')
		: undefined;

	// Initial form values
	const initialValues: IProductDetailsFormValues = {
		name: (productId && productDetails?.name) || '',
		price: (productId && productPrice) || '0',
		sku: (productId && productDetails?.sku) || '',
		description: (productId && productDetails?.description) || '',
		imageUrl: (productId && productDetails?.imageUrl) || '',
		allergens: (productId && initialAllergenValues) || [],
		dietaryPreferences: (productId && initialDietaryValues) || [],
		ageRestricted: (productId && productDetails?.ageRestricted) || false,
		modifierGroups:
			(productId &&
				productDetails?.modifierGroupDetails?.map((modifierGroup) => ({
					id: modifierGroup.id,
					title: `${modifierGroup.title} (${modifierGroup.sku})`,
				}))) ||
			[],
		categories: (productId && initialCategoryOptions) || [],
	};

	// Handle form submission
	const handleSubmit = async (
		values: IProductDetailsFormValues,
		{ setSubmitting }: FormikHelpers<IProductDetailsFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Convert options to array of strings
		const allergenValues = values.allergens?.map(
			(option: IOption) => option.value?.toString() || ''
		);
		const dietaryValues = values.dietaryPreferences?.map(
			(option: IOption) => option.value?.toString() || ''
		);
		const categoryValues = values.categories?.map(
			(option: IOption) => option.value?.toString() || ''
		);
		const modifierGroupsValues = values.modifierGroups?.map(
			(option: { id: string; title: string }) => option.id || ''
		);

		// Create new form values
		const formValues: IProductDetailsSubmitValues = {
			...values,
			allergens: allergenValues || [],
			dietaryPreferences: dietaryValues || [],
			categories: categoryValues || [],
			modifierGroups: modifierGroupsValues || [],
			price: !hasPriceBands(productDetails)
				? currency(values.price || 0).intValue
				: undefined,
		};

		// Create / update
		const response = await (productId
			? dispatch(patchProductDetails(productId, formValues))
			: dispatch(createProduct(formValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: productId
					? intl.formatMessage({
						id: 'productForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'productForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		!productId &&
			history.push(
				`/menus/product/edit/${response?.id}/details?activeAccount=${activeAccountId}`
			);
	};

	// Handle deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'productForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'productForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete action
		const response =
			productId && (await dispatch(deleteProduct(productId)));

		// Return if fail
		if (!response) {
			return;
		}
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'productForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to product
		history.push(`/menus/products?activeAccount=${activeAccountId}`);
	};

	const formValidationSchema = object<IFormValidation>().shape({
		name: string().required(validationMessages.required('name')),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, values, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="productForm.headings.details" />
					</h2>
					<StyledFormFields>
						<StyledColumn>
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.name.label',
								})}
							/>
							<Field
								component={Select}
								isMulti
								name="categories"
								testId="categories-select"
								selectOptions={categoryOptions}
								label={intl.formatMessage({
									id: 'form.fields.productCategories.label',
								})}
								value={values.categories}
							/>
							<Field
								component={Input}
								name="sku"
								label={intl.formatMessage({
									id: 'form.fields.sku.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.sku.label',
								})}
							/>
							<Field
								component={TextArea}
								name="description"
								label={intl.formatMessage({
									id: 'form.fields.description.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.description.label',
								})}
							/>
							<StyledFieldGroup>
								<StyledColumn>
									<Field
										component={ImageUpload}
										name="imageUrl"
										label={intl.formatMessage({
											id: 'form.fields.image.label',
										})}
										uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/menu/images/product`}
									/>
								</StyledColumn>
							</StyledFieldGroup>
						</StyledColumn>
						<StyledColumn>
							{!hasPriceBands(productDetails) && (
								<Field
									component={Input}
									inputSymbol="£"
									name="price"
									label={intl.formatMessage({
										id: 'form.fields.price.label',
									})}
									placeholder={intl.formatMessage({
										id: 'form.fields.price.label',
									})}
								/>
							)}
							<div data-testid="allergens-select">
								<Field
									component={Select}
									isMulti
									name="allergens"
									selectOptions={allergenOptions}
									label={intl.formatMessage({
										id: 'form.fields.allergens.label',
									})}
									value={values.allergens}
								/>
							</div>
							<Field
								component={Select}
								isMulti
								testId="dietary-preferences-select"
								name="dietaryPreferences"
								selectOptions={dietaryOptions}
								label={intl.formatMessage({
									id: 'form.fields.dietaryPreferences.label',
								})}
								value={values.dietaryPreferences}
							/>
							<Field
								component={Switch}
								name="ageRestricted"
								label={intl.formatMessage({
									id: 'form.fields.ageRestriction.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.ageRestriction.on',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.ageRestriction.off',
								})}
							/>
							<Field
								component={SortableMiniList}
								name="modifierGroups"
								label={intl.formatMessage({
									id: 'form.fields.modifierGroupOrder.label',
								})}
							/>
						</StyledColumn>
					</StyledFormFields>
					<StyledActions>
						<div>
							<Link
								to={`/menus/products?activeAccount=${activeAccountId}`}
							>
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{productId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="productForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage
								id={
									productId
										? 'form.button.save'
										: 'productForm.button.create'
								}
							/>
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default ProductDetailsForm;
