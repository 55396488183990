import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';
import dayjs from 'helpers/dayjs.helper';
import { IVenuePrinterListItem } from 'modules/venue/venue.types';

const StyledWrapper = styled.section`
	width: 100%;
	padding: 0 15px 15px;
	border-radius: 10px;
	background: ${brand.white};

	h3 {
		margin: 0;
	}
`;

const StyledHeadings = styled.div`
	padding: 0 10px;
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
`;

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	padding-left: 5px;
	user-select: none;
	font-weight: ${fonts.weights.medium};

	:focus {
		outline: none;
	}

	&:nth-child(1n) {
		width: calc(25% - 120px);
	}
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:nth-child(1n) {
		width: calc(25% - 120px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		text-align: center;
		margin: 0;
	}

	&:nth-last-child(2) {
		margin: 0 auto 0 0;
	}
`;

interface IComponentProps {
	venueId: string;
	printers?: IVenuePrinterListItem[];
}

const VenuePrinterList: FunctionComponent<IComponentProps> = ({
	venueId,
	printers,
}) => {
	const intl = useIntl();

	if (!printers || printers?.length === 0) {
		return (
			<StyledWrapper>
				<h3 aria-label="venues-empty">
					<FormattedMessage id="venuePrinterList.empty" />
				</h3>
			</StyledWrapper>
		);
	}

	const lastPolledText = (printer: IVenuePrinterListItem) => {
		if (printer.model === 'quickprinter') {
			return intl.formatMessage({
				id: 'venuesPrintersList.column.localPrinter',
			});
		}

		if (printer.lastPolled) {
			return dayjs(printer.lastPolled).format('DD-MM-YYYY HH:mm:ss');
		}
		return intl.formatMessage({
			id: 'venuesPrintersList.column.neverPolled',
		});
	};

	return (
		<StyledWrapper>
			<StyledHeadings>
				<StyledHeading>
					<FormattedMessage id="venuesPrintersList.headings.printer" />
				</StyledHeading>
				<StyledHeading>
					<FormattedMessage id="venuesPrintersList.headings.polled" />
				</StyledHeading>
			</StyledHeadings>
			<StyledList aria-label="venues-list">
				{printers.map((printer) => (
					<StyledListItem key={printer.id}>
						<StyledColumn>{printer.name}</StyledColumn>
						<StyledColumn>{lastPolledText(printer)}</StyledColumn>
						<StyledColumn>
							<Link
								to={`/venues/edit/${venueId}/printing/edit/${printer.id}`}
							>
								<Icon name="chevron" colour="black" />
							</Link>
						</StyledColumn>
					</StyledListItem>
				))}
			</StyledList>
		</StyledWrapper>
	);
};

export default VenuePrinterList;
