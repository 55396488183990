import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, {
	useCallback,
	useEffect,
	FunctionComponent,
	useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import {
	getCheckoutUpsellConfig,
	getCheckoutUpsellProductList,
	updateCheckoutUpsellConfig,
} from '../menu.slice';
import {
	ETableServiceType,
	ICheckoutUpsellConfigFormValues,
	ICheckoutUpsellConfig,
} from '../menu.types';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Input from 'components/form-inputs/input/input.component';
import MultiSelect from 'components/form-inputs/multi-select/multi-select.component';
import {
	IItemSelect,
	IItemSelectGroup,
} from 'components/form-inputs/multi-select/multi-select.types';
import Select from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';
import SuggestedPairingSearch from 'components/suggested-pairing-search/suggested-pairing-search.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: flex-end;

	button:first-child {
		margin-right: 20px;
	}
`;

const StyledLabel = styled.div`
	line-height: 1.7;
	font-size: 1rem;
	font-weight: 400;
`;

const CheckoutUpsellConfigForm: FunctionComponent = () => {
	// initialise hooks
	const dispatch = useReduxDispatch();
	const intl = useIntl();

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [searchData, setSearchData] = useState<
		IItemSelect[] | IItemSelectGroup[]
	>([]);

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	const { activeAccountId } = useSelector((state: RootState) => state.auth);
	const { activeCheckoutUpsellConfig } = useSelector(
		(state: RootState) => state.menu
	);

	const tableServiceTypeOptions = Object.values(ETableServiceType).map(
		(key) => ({
			label: key,
			value: key,
		})
	);

	const getData = useCallback(async () => {
		activeAccountId && (await dispatch(getCheckoutUpsellConfig()));
	}, [activeAccountId, dispatch]);
	useEffect(() => {
		// Get checkout upsell config

		getData();
	}, [getData]);

	// initial form values
	const initialValues: ICheckoutUpsellConfigFormValues = {
		isEnabled: activeCheckoutUpsellConfig?.isEnabled || false,
		upsellTitle: activeCheckoutUpsellConfig?.upsellTitle || '',
		automaticPopOverEnabled:
			activeCheckoutUpsellConfig?.automaticPopOverEnabled || false,
		upsellMessage: activeCheckoutUpsellConfig?.upsellMessage || '',
		tableServiceTypes: activeCheckoutUpsellConfig?.tableServiceTypes.map(
			(value) => ({
				label: value.toString(),
				value: value.toString(),
			})
		) || [
			{
				label: 'PayAndGo',
				value: 'PayAndGo',
			},
		],
		upsellProducts:
			activeCheckoutUpsellConfig?.upsellProducts.map((product) => ({
				id: product.entitySku,
				title: product.name,
				disabled: product.disabled || false,
			})) || [],
	};

	const handleSubmit = async (
		values: ICheckoutUpsellConfigFormValues,
		{ setSubmitting }: FormikHelpers<ICheckoutUpsellConfigFormValues>
	) => {
		if (!activeAccountId || !activeCheckoutUpsellConfig?.id) {
			fireDialog({
				title: intl.formatMessage({
					id: 'upsellForm.dialogs.noActiveAccount.text',
				}),
				text: intl.formatMessage({
					id: 'upsellForm.dialogs.noActiveAccount.text',
				}),
				icon: 'error',
			});
			return;
		}

		// Set formik submission state to true
		setSubmitting(true);

		const submitValues: ICheckoutUpsellConfig = {
			...values,
			id: activeCheckoutUpsellConfig?.id,
			tableServiceTypes: values.tableServiceTypes.map(
				(option) => option.value
			) as ETableServiceType[],
			upsellProducts: values.upsellProducts.map((selected, index) => ({
				entitySku: selected.id,
				name: selected.title,
				sortOrder: index + 1,
			})),
		};

		// Dispatch update checkout upsell config
		const response = await (activeAccountId &&
			dispatch(updateCheckoutUpsellConfig(submitValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'upsellForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Get updated config
		getData();

		// Clear search
		setSearchTerm('');
		setSearchData([]);
	};

	const handleSearch = async (search: string) => {
		setSearchTerm(search);
		if (!activeCheckoutUpsellConfig?.id) {
			fireDialog({
				title: intl.formatMessage({
					id: 'upsellForm.dialogs.noActiveAccount.title',
				}),
				text: intl.formatMessage({
					id: 'upsellForm.dialogs.noActiveAccount.text',
				}),
				icon: 'error',
			});
			return;
		}
		setSearchData(
			await dispatch(
				getCheckoutUpsellProductList(
					search,
					activeCheckoutUpsellConfig?.id
				)
			)
		);
	};

	const formValidationSchema = object<
		ICheckoutUpsellConfigFormValues
	>().shape({
		upsellTitle: string().when(['isEnabled'], {
			is: (upsellEnabled) => !!upsellEnabled,
			then: string().required(validationMessages.required('upsellTitle')),
		}),
	});

	return activeAccountId ? (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="menu.headings.checkoutUpsellConfig" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								label={intl.formatMessage({
									id: 'form.fields.upsellEnabled.label',
								})}
								name="isEnabled"
							/>
							<Field
								component={Input}
								label={intl.formatMessage({
									id: 'form.fields.upsellTitle.label',
								})}
								name="upsellTitle"
							/>
							<SuggestedPairingSearch
								label={
									<StyledLabel>
										{' '}
										{intl.formatMessage({
											id:
												'form.fields.suggestedPairingSearch.placeholder',
										})}{' '}
									</StyledLabel>
								}
								handleSubmit={handleSearch}
								testId="upsellProductSearch"
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Switch}
								label={intl.formatMessage({
									id: 'form.fields.upsellPopoutEnabled.label',
								})}
								name="automaticPopOverEnabled"
							/>
							<Field
								component={Input}
								label={intl.formatMessage({
									id: 'form.fields.upsellMessage.label',
								})}
								name="upsellMessage"
							/>
							<Field
								component={Select}
								isMulti
								name="tableServiceTypes"
								label={intl.formatMessage({
									id: 'form.fields.serviceTypes.label',
								})}
								selectOptions={tableServiceTypeOptions}
								value={values.tableServiceTypes}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<Field
						component={MultiSelect}
						isMulti
						name="upsellProducts"
						labelSearch={intl.formatMessage(
							{
								id: `form.fields.baseSelectedProducts.labelSearch.${
									searchTerm
										? 'hasSearchTerm'
										: 'noSearchTerm'
								}`,
							},
							{
								searchTerm,
							}
						)}
						labelSelect={intl.formatMessage({
							id: 'form.fields.upsellList.label',
						})}
						searchItems={searchData}
						sortable={true}
						maxHeight={200}
					/>
					<StyledActions>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	) : (
		<div>
			<h2>
				<FormattedMessage id="menu.headings.checkoutUpsellConfig" />
			</h2>
			<FormattedMessage id="upsellForm.dialogs.noActiveAccount.text" />
		</div>
	);
};

export default CheckoutUpsellConfigForm;
