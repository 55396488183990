import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';

const StyledFormWrapper = styled.main`
	padding: 25px;
	border-radius: 10px;
	background: ${brand.white};
`;

interface IComponentProps {
	className?: string;
}

/** Renders form wrapper component */
const FormWrapper: FunctionComponent<IComponentProps> = ({
	children,
	className,
}) => {
	return (
		<StyledFormWrapper className={className}>{children}</StyledFormWrapper>
	);
};

export default FormWrapper;
