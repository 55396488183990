import { History } from 'history';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { addAlert } from 'components/alert/alert.slice';
import TabList from 'components/tab-list/tab-list.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import PaymentConfigForm from 'modules/payment/payment-config/payment-config.component';
import { EntityType } from 'modules/payment/payment.types';
import { deleteVenue } from 'modules/venue/slices/venue.slice';
import VenueBeaconsForm from 'modules/venue/venue-form/venue-details/venue-beacons-form/venue-beacons-form.component';
import VenueConfigForm from 'modules/venue/venue-form/venue-details/venue-config-form/venue-config-form.component';
import VenueDetailsForm from 'modules/venue/venue-form/venue-details/venue-details-form/venue-details-form.component';
import VenueOpeningForm from 'modules/venue/venue-form/venue-details/venue-opening-form/venue-opening-form.component';
import VenuePublishForm from 'modules/venue/venue-form/venue-details/venue-publish-form/venue-publish-form.component';
import VenueSync from 'modules/venue/venue-form/venue-details/venue-sync/venue-sync.component';

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledTabList = styled(TabList)`
	margin: 2px 0 20px;
`;

interface IVenueDetailsProps {
	history: History;
	venueId: string;
	secondaryTab?: string;
}

// Venue details form wrapper
const VenueDetails: FunctionComponent<IVenueDetailsProps> = ({
	history,
	venueId,
	secondaryTab,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	// Tab state
	const [activeTab, setActiveTab] = useState(secondaryTab || 'details');

	// Handle venue deletion
	const handleDelete = async (setFormSubmission?: Function) => {
		// Confirm user wishes to delete venue
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'venueForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'venueForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});
		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}
		// Delete venue action
		const response = venueId && (await dispatch(deleteVenue(venueId)));

		// Return on fail
		if (!response) {
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		!!setFormSubmission && setFormSubmission(true);
		// Redirect user to venues
		history.push('/venues');
	};

	return (
		<StyledWrapper>
			<StyledTabList
				{...{
					activeTab,
					setActiveTab,
				}}
				navigateOnClick={true}
				history={history}
				parentUrl={`/venues/edit/${venueId}/details/`}
				tabStyle="underlined"
				tabs={[
					{
						id: 'details',
						title: intl.formatMessage({
							id: 'venueForm.tabs.details.secondary.venueDetails',
						}),
					},
					{
						id: 'config',
						title: intl.formatMessage({
							id: 'venueForm.tabs.details.secondary.venueConfig',
						}),
					},
					{
						id: 'payments',
						title: intl.formatMessage({
							id: 'venueForm.tabs.details.secondary.payments',
						}),
					},
					{
						id: 'opening',
						title: intl.formatMessage({
							id: 'venueForm.tabs.details.secondary.openingTimes',
						}),
					},
					{
						id: 'publish',
						title: intl.formatMessage({
							id: 'venueForm.tabs.details.secondary.publish',
						}),
					},
					{
						id: 'sync',
						title: intl.formatMessage({
							id: 'venueForm.tabs.details.secondary.sync',
						}),
					},
					{
						id: 'beacons',
						title: intl.formatMessage({
							id: 'venueForm.tabs.details.secondary.beacons',
						}),
					},
				]}
			/>
			{activeTab === 'details' && (
				<VenueDetailsForm
					{...{
						handleDelete,
						venueId,
					}}
				/>
			)}
			{activeTab === 'config' && (
				<VenueConfigForm
					{...{
						handleDelete,
						venueId,
					}}
				/>
			)}
			{activeTab === 'payments' && (
				<PaymentConfigForm
					entityType={EntityType.venue}
					entityId={venueId}
				/>
			)}
			{activeTab === 'opening' && (
				<VenueOpeningForm
					{...{
						handleDelete,
						venueId,
					}}
				/>
			)}
			{activeTab === 'publish' && (
				<VenuePublishForm
					{...{
						handleDelete,
						venueId,
					}}
				/>
			)}
			{activeTab === 'sync' && (
				<VenueSync
					{...{
						handleDelete,
						venueId,
					}}
				/>
			)}
			{activeTab === 'beacons' && (
				<VenueBeaconsForm
					{...{
						handleDelete,
						venueId,
					}}
				/>
			)}
		</StyledWrapper>
	);
};

export default VenueDetails;
