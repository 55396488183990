import React, { FunctionComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { ISortItem } from '../sortable-mini-list.types';

import Button from 'components/button/button.component';
import Icon from 'components/icons/icon.component';

interface IComponentProps {
	item: ISortItem;
	index: number;
	sortable: boolean;
	removeItem?: Function;
}

interface IWrapperProps {
	sortable: boolean;
}

const StyledListItem = styled.div<IWrapperProps>`
	padding: 10px 20px;

	${({ sortable }) => !sortable && 'pointer-events: none;'}

	&:hover {
		background: rgba(0, 0, 0, 0.05);
	}
`;

const StyledWrapper = styled.div<IWrapperProps>`
	position: relative;
	${({ sortable }) => sortable && 'padding-left: 20px;'}
`;

const StyledIcon = styled(Icon)`
	position: absolute;
	top: 4px;
	left: 0;
`;

const StyledRemove = styled(Button)`
	position: absolute;
	top: 2px;
	right: 0;
	pointer-events: auto;
`;

const SortableMiniListItem: FunctionComponent<IComponentProps> = ({
	item,
	index,
	sortable,
	removeItem,
}) => {
	return (
		<Draggable draggableId={item.id} index={index}>
			{(provided) => (
				<StyledListItem
					data-testid="sortable-mini-list-item"
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					sortable={sortable}
				>
					<StyledWrapper sortable={sortable}>
						{sortable && (
							<StyledIcon name="drag" width={10} height={10} />
						)}
						{item.title}&nbsp;
						{item.reference ? `(${item.reference})` : ''}
						{item.sku ? `(${item.sku})` : ''}
						{removeItem && (
							<StyledRemove
								icon="close"
								variant="icon"
								iconWidth={14}
								iconHeight={14}
								onClick={() => removeItem(item)}
							/>
						)}
					</StyledWrapper>
				</StyledListItem>
			)}
		</Draggable>
	);
};

export default SortableMiniListItem;
