import { IPagination } from 'app.types';

/** MOCKED DATA */

const pagination: IPagination = {
	pageCount: 1,
	pageNumber: 1,
	pageSize: 20,
};

const mockPagination = {
	data: {
		pagination,
	},
};
export default mockPagination;
