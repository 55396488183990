import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IStoreVersionState } from './store-version.types';

import { LOGOUT } from 'modules/auth/auth.slice';

// Create initial state for app slice
export const initialStoreVersionState: IStoreVersionState = {};

const storeVersionSlice = createSlice({
	name: 'storeVersion',
	initialState: initialStoreVersionState,
	reducers: {
		UPDATE_VERSION_NUMBER(state, action) {
			return {
				...state,
				versionNumber: action.payload.versionNumber,
			};
		},
	},
});

// Destructure and export the plain action creators
export const { UPDATE_VERSION_NUMBER } = storeVersionSlice.actions;

/** Thunk to update redux version number */
export const updateVersionNumber = (versionNumber: string) => async (
	dispatch: Dispatch
) => {
	// Invalidate store using logout
	await dispatch(LOGOUT());

	// update local storage version number
	await dispatch(
		UPDATE_VERSION_NUMBER({
			versionNumber,
		})
	);
};

export default storeVersionSlice.reducer;
