import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IMenuUploadError } from '../menu.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { intl } from 'modules/core/i18n/i18n.config';

interface IComponentProps {
	uploadError: IMenuUploadError;
}

const StyledList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;
	font-size: ${fonts.sizes.h4};
	flex-direction: row;
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:first-child {
		margin-left: 26px;
	}
`;

const MenuUploadError: FunctionComponent<IComponentProps> = ({
	uploadError,
}) => {
	return (
		<>
			<h3>
				{intl.formatMessage({
					id: 'menuUpload.error.title',
				})}
				<br />
				{intl.formatMessage({
					id: 'menuUpload.error.sheet',
				})}
				{`: ${uploadError.details.sheetName}`}
			</h3>
			<StyledList>
				{uploadError.details.errors.map((value) => (
					<StyledListItem key={`row${value.row}`}>
						<StyledColumn>
							{intl.formatMessage({
								id: 'menuUpload.error.row',
							})}{' '}
							{value.row}
						</StyledColumn>
						<StyledColumn>
							<StyledList>
								{Object.entries(value.errors).map(
									([columnName, columnErrors]) => (
										<li key={columnName}>
											{intl.formatMessage({
												id: 'menuUpload.error.column',
											})}{' '}
											{columnName}:{' '}
											{columnErrors.join(
												` ${intl.formatMessage({
													id: 'menuUpload.error.or',
												})} `
											)}
										</li>
									)
								)}
							</StyledList>
						</StyledColumn>
					</StyledListItem>
				))}
			</StyledList>
		</>
	);
};

export default MenuUploadError;
