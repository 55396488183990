import { useContext, useEffect } from 'react';

import { getReport, getPaginatedReport } from '../report.slice';
import { IReport } from '../report.types';

import {
	setLoadingConfig,
	removeLoadingEvent,
} from 'components/loading/loading.slice';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { PusherContext } from 'modules/core/pusher/pusher.context';

export default function useReportPusherEvent() {
	const { userChannel } = useContext(PusherContext);
	const dispatch = useReduxDispatch();

	useEffect(() => {
		if (!userChannel) return () => {};

		const reportEventCallback = ({
			data: { report },
		}: {
			data: { report: IReport };
		}) => {
			// If report status is not ready
			if (report.status !== 2) {
				return;
			}
			// Get report by ID
			dispatch(getReport(report.id));
			// Get paginated results to display report data
			dispatch(getPaginatedReport(report.id, { pageSize: 25 }));
			// Reset loading config
			dispatch(
				setLoadingConfig({
					loadingMessage: '',
					loadingTimeout: 0,
				})
			);
			// Remove loading event
			dispatch(removeLoadingEvent());
		};

		userChannel.bind('report-event', reportEventCallback);

		return () => {
			userChannel.unbind('report-event', reportEventCallback);
		};
	}, [dispatch, userChannel]);
}
