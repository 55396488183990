import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

// Interface for component props
interface ILoginWrapperProps {}

// Create styled login wrapper component
const StyledLoginWrapper = styled.main`
	width: 100%;
	min-height: 100vh;
	padding-bottom: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
`;

/** Renders login wrapper component */
const LoginWrapper: FunctionComponent<ILoginWrapperProps> = ({ children }) => {
	return <StyledLoginWrapper>{children}</StyledLoginWrapper>;
};

export default LoginWrapper;
