import { Field, Formik, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import { object, string } from 'yup';

import {
	StyledActions,
	StyledColumn,
	StyledFieldGroup,
	StyledForm,
} from './brand-receipt-footer-form.styles';

import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { stripTags } from 'helpers/strip-tags.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	deleteBrand,
	getBrandReceiptFooterConfig,
	updateBrandReceiptFooterConfig,
} from 'modules/brand/brand.slice';
import { IBrandReceiptFooterFormValues } from 'modules/brand/brand.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { RootState } from 'modules/core/state/root.reducer';

interface IComponentProps {
	brandId?: string;
	history: History;
}

const BrandReceiptFooterConfigForm: FunctionComponent<IComponentProps> = ({
	brandId,
	history,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	const activeReceiptFooterConfig = useSelector(
		(state: RootState) => state.brand.activeReceiptFooterConfig
	);

	useEffect(() => {
		// Get brand receipt footer config
		const getData = async (id: string | undefined) => {
			id && (await dispatch(getBrandReceiptFooterConfig(id)));
		};
		getData(brandId);
	}, [brandId, dispatch]);

	const intl = useIntl();

	const initialValues: IBrandReceiptFooterFormValues = {
		footerReceiptEnabled:
			(brandId && activeReceiptFooterConfig?.footerReceiptEnabled) ||
			false,
		footerReceiptMessage:
			(brandId && activeReceiptFooterConfig?.footerReceiptMessage) || '',
	};

	// Handle form submission
	const handleSubmit = async (
		values: IBrandReceiptFooterFormValues,
		{ setSubmitting }: FormikHelpers<IBrandReceiptFooterFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		const submitValues: IBrandReceiptFooterFormValues = {
			...values,
			footerReceiptMessage: stripTags(values.footerReceiptMessage),
		};

		// Dispatch update brand
		const response = await (brandId &&
			dispatch(updateBrandReceiptFooterConfig(brandId, submitValues)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	// Handle brand deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete brand
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete brand action
		brandId && (await dispatch(deleteBrand(brandId)));

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to brands page
		history.push('/brands');
	};

	const formValidationSchema = object<IBrandReceiptFooterFormValues>().shape({
		footerReceiptMessage: string().when('footerReceiptEnabled', {
			is: true,
			then: string().required(
				validationMessages.required('footerReceiptMessage')
			),
		}),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="brandForm.headings.receiptFooterConfig" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								label={intl.formatMessage({
									id:
										'form.fields.footerReceiptEnabled.label',
								})}
								name="footerReceiptEnabled"
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={TextArea}
								label={intl.formatMessage({
									id:
										'form.fields.footerReceiptMessage.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.footerReceiptMessage.placeholder',
								})}
								name="footerReceiptMessage"
								isDisabled={!values.footerReceiptEnabled}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/brands">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{brandId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="brandForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default BrandReceiptFooterConfigForm;
