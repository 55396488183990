import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';
import { ICategory } from 'modules/menu/menu.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding: 15px 0 15px 5px;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:first-child {
		margin-left: 20px;
	}

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(4) {
		min-width: 165px;
		margin-right: 52px;
		width: calc(25% - 110px);
	}

	&:nth-child(3) {
		min-width: 220px;
		width: calc(25% - 110px);
	}

	&:last-child {
		width: 30px;
		min-width: 30px;
		margin: 0 0 0 auto;
		display: flex;
		justify-content: center;
	}
`;

// Props for component
interface IComponentProps {
	item: ICategory;
	activeAccountId: string;
}
// categories list item component
const CategoryListItem: FunctionComponent<IComponentProps> = ({
	item,
	activeAccountId,
}) => {
	return (
		<StyledListItem key={item.id} aria-label="categories-item">
			<StyledColumn>{item.title}</StyledColumn>
			<StyledColumn>{item.reference}</StyledColumn>
			<StyledColumn>{item.menu?.title}</StyledColumn>
			<StyledColumn>{item.products?.length || 0}</StyledColumn>
			<StyledColumn>
				<Link
					to={`/menus/category/edit/${item.id}/details?activeAccount=${activeAccountId}`}
				>
					<Icon name="edit" colour="primary" />
				</Link>
			</StyledColumn>
		</StyledListItem>
	);
};

export default CategoryListItem;
