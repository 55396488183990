import Pusher, { Channel } from 'pusher-js';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../state/root.reducer';
import PusherContextProvider from './pusher.context';

/** Renders pusher component */
const PusherComponent: FunctionComponent = ({ children }) => {
	const [pusher, setPusher] = useState<Pusher>();
	const [userChannel, setUserChannel] = useState<Channel>();
	const { user } = useSelector((state: RootState) => state.auth);

	// Set the pusher instance
	useEffect(() => {
		// @ts-ignore
		Pusher.Runtime.createXHR = () => {
			// create xhr
			const xhr = new XMLHttpRequest();
			// Set with creds
			xhr.withCredentials = true;
			return xhr;
		};
		// Create new pusher
		const pusherInstance = new Pusher(
			process.env.REACT_APP_PUSHER_KEY || '',
			{
				cluster: 'eu',
				authEndpoint: `${process.env.REACT_APP_API_BASE_URL}/pusher/auth`,
			}
		);
		setPusher(pusherInstance);

		return () => {
			pusherInstance.unbind_all();
		};
	}, [setPusher]);

	// Set the user channel
	useEffect(() => {
		if (!pusher) return () => {};
		const connectedCallback = () => {
			// If no user Id, do nothing
			if (!user?.id) {
				return;
			}
			// subscribe to user channel
			const channel = pusher.subscribe(`private-user-${user.id}`);
			setUserChannel(channel);
		};
		pusher.connection.bind('connected', connectedCallback);
		return () => {
			pusher.connection.unbind('connected', connectedCallback);
			setUserChannel(undefined);
		};
	}, [pusher, user, user.id]);

	return (
		<PusherContextProvider userChannel={userChannel} pusher={pusher}>
			<div data-testid="pusher-component" />
			{children}
		</PusherContextProvider>
	);
};

export default PusherComponent;
