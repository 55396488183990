import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IAppUsersFilters, IAppUsersState } from './app-users.types';

// Create initial state for app users slice
export const initialAppUsersState: IAppUsersState = {
	eventsInProgress: 0,
};

const appUsersSlice = createSlice({
	name: 'appUsers',
	initialState: initialAppUsersState,
	reducers: {
		GET_ALL_APP_USERS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ALL_APP_USERS_SUCCESS(state, action) {
			return {
				...state,
				appUsers: action.payload.data.data,
				pagination: action.payload.data.pagination,
				totalAppUsers: action.payload.data.totalCount,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ALL_APP_USERS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_APP_USER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_APP_USER_SUCCESS(state, action) {
			return {
				...state,
				activeUserDetails: action.payload.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_APP_USER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_APP_USER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_APP_USER_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_APP_USER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_APP_USERS_STATE() {
			return {
				...initialAppUsersState,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_ALL_APP_USERS,
	GET_ALL_APP_USERS_FAIL,
	GET_ALL_APP_USERS_SUCCESS,
	GET_APP_USER,
	GET_APP_USER_FAIL,
	GET_APP_USER_SUCCESS,
	DELETE_APP_USER,
	DELETE_APP_USER_FAIL,
	DELETE_APP_USER_SUCCESS,
	RESET_APP_USERS_STATE,
} = appUsersSlice.actions;

/** Get app users list */
export const getAppUsersList = (filters: IAppUsersFilters) => async (
	dispatch: Dispatch
) => {
	const { payload } = await dispatch(
		GET_ALL_APP_USERS({
			request: {
				method: 'get',
				url: 'app-users',
				params: filters,
			},
		})
	);

	return payload?.data;
};

/** Get app user by ID */
export const getAppUser = (userId: string) => async (dispatch: Dispatch) => {
	// Create app user request
	const { payload } = await dispatch(
		GET_APP_USER({
			request: {
				method: 'get',
				url: `app-users/${userId}`,
			},
		})
	);

	return payload?.data;
};

/** Delete app user by ID */
export const deleteAppUser = (userId: string) => async (dispatch: Dispatch) => {
	// Delete app user request
	const { payload } = await dispatch(
		DELETE_APP_USER({
			request: {
				method: 'delete',
				url: `app-users/${userId}`,
			},
		})
	);

	return payload.status === 200;
};

/** Reset app users state */
export const resetAppUsersState = () => async (dispatch: Dispatch) => {
	// reset state
	await dispatch(RESET_APP_USERS_STATE());
};

export default appUsersSlice.reducer;
