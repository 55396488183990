import { Formik, Field, Form, FormikHelpers } from 'formik';
import queryString from 'query-string';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt, useLocation } from 'react-router-dom';
import { AutoSizer, List } from 'react-virtualized';
import styled from 'styled-components';

import CategoryProductListItem from './category-product-list-item/category-product-list-item.component';

import { IQueryParams } from 'app.types';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import Input from 'components/form-inputs/input/input.component';
import SortIcons from 'components/sort-icons/sort-icons.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { getProductsList, patchCategoryDetails } from 'modules/menu/menu.slice';
import { IProduct } from 'modules/menu/menu.types';

interface IFormValues {
	[index: string]: boolean;
}

// Interface for list item props
interface IListItemProps {
	key: string;
	index: number;
	isScrolling: boolean;
	style: Object;
}

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 0 0 20px;

	h2 {
		margin: 0;
	}
`;

const StyledInput = styled(Input)`
	margin: 0;
	width: 100%;
	max-width: 160px;

	input {
		height: 35px;
		font-size: ${fonts.sizes.small};
		background: ${brand.neutral};
	}
`;

const StyledCheckbox = styled(Checkbox)`
	margin: 0 20px 0 0;
`;

const StyledFilterForm = styled(Form)`
	display: flex;
	align-items: center;
`;

const StyledSortIcons = styled(SortIcons)`
	margin: 0 10px 0 0;
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	padding: 0 15px;
`;

const StyledHeading = styled.div`
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0 45px 0 0;
	user-select: none;

	&.mod-sort {
		cursor: pointer;
	}

	:focus {
		outline: none;
	}

	&:first-child {
		min-width: 30px;
		width: 30px;
		margin-right: 15px;
	}

	&:nth-child(2) {
		min-width: 50px;
		width: 50px;
		padding: 0;
		margin: 0;

		img {
			margin: 0;
		}
	}

	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(5) {
		width: calc(50% - 80px);
		max-width: 300px;
	}

	&:nth-child(5) {
		margin-left: 20px;
	}
`;

const StyledProductsList = styled.div`
	width: 100%;
	height: 550px;
	user-select: none;
	display: block;

	.ReactVirtualized__List:focus {
		outline: none;
	}
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	categoryId: string;
}

const CategoryProductsForm: FunctionComponent<IComponentProps> = ({
	categoryId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	const location = useLocation();

	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Set use state for sort, search and show selected
	const [sortDirection, setSortDirection] = useState('');
	const [sortColumn, setSortColumn] = useState<keyof IProduct>('name');
	const [searchQuery, setSearchQuery] = useState('');
	const [showSelected, setShowSelected] = useState(false);

	// Get query params
	const query: IQueryParams = queryString.parse(location.search);

	// Get active account ID from state
	const { activeAccountId } = useSelector((state: RootState) => state.auth);

	// Get active category details from store
	const categoryDetails = useSelector(
		(state: RootState) => state.menu.activeCategoryDetails
	);

	// Get all products from state
	let products = useSelector((state: RootState) => state.menu.products) || [];

	// If we have products, filter and sort
	if (products.length > 0) {
		products = products
			.filter(
				(product) =>
					(product.name &&
						product.name
							.toLowerCase()
							.includes(searchQuery.toLowerCase())) ||
					(product.sku &&
						product.sku
							.toLowerCase()
							.includes(searchQuery.toLowerCase()))
			)
			.sort((a, b) => {
				const sortA = a[sortColumn];
				const sortB = b[sortColumn];
				if (sortDirection === 'ASC') {
					return sortA! > sortB! ? 1 : -1;
				}
				if (sortDirection === 'DESC') {
					return sortA! > sortB! ? -1 : 1;
				}

				return 0;
			});
	}
	// Convert products to initial values
	const currentCategoryProduct = categoryDetails?.products?.reduce(
		(allProducts, product) => ({
			...allProducts,
			[product]: true,
		}),
		{}
	);

	// Initial form values
	const initialValues: IFormValues = currentCategoryProduct!;

	useEffect(() => {
		// Get products list
		const getData = async (id?: string) => {
			dispatch(getProductsList({ pageSize: -1 }));
		};

		getData(categoryId);
	}, [dispatch, categoryId]);

	// Handle form submission
	const handleSubmit = async (
		values: IFormValues,
		{ setSubmitting }: FormikHelpers<IFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Create array of empty products
		const updatedProducts: string[] = [];

		// Filter values if they're set to true
		for (const key in values) {
			if (values[key]) {
				updatedProducts.push(key);
			}
		}

		// Send patch request
		const response = await dispatch(
			patchCategoryDetails(categoryId, { products: updatedProducts })
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'categoryForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set for submission to true to remove routing prompt
		setFormSubmission(true);
	};

	const setSort = (column: keyof IProduct) => {
		let sort;
		if (sortDirection === 'ASC') {
			sort = 'DESC';
		} else if (sortDirection === 'DESC') {
			sort = '';
		} else {
			sort = 'ASC';
		}

		setSortDirection(sort);
		setSortColumn(column);
	};

	return (
		<>
			<StyledHeader>
				<h2>
					<FormattedMessage
						id="categoryForm.headings.product"
						values={{ category: categoryDetails?.title }}
					/>
				</h2>
				<div>
					<Formik
						initialValues={{
							searchQuery: query.searchQuery,
							onlySelectedProducts: false,
						}}
						onSubmit={() => {}}
					>
						{() => {
							return (
								<StyledFilterForm>
									<Field
										component={StyledCheckbox}
										name="onlySelectedProducts"
										label={intl.formatMessage({
											id:
												'form.fields.onlySelectedCategoryProducts.label',
										})}
										handleChangeEvent={(value: boolean) => {
											setShowSelected(value);
										}}
									/>
									<Field
										component={StyledInput}
										name="searchQuery"
										placeholder={intl.formatMessage({
											id: 'form.search.placeholder',
										})}
										icon="search"
										iconWidth={12}
										iconHeight={12}
										handleChangeEvent={(value: string) =>
											setSearchQuery(value)}
									/>
								</StyledFilterForm>
							);
						}}
					</Formik>
				</div>
			</StyledHeader>
			<Formik
				enableReinitialize
				initialValues={initialValues!}
				onSubmit={handleSubmit}
			>
				{({ dirty, values, isSubmitting }) => {
					const visibleProducts =
						!showSelected && values
							? products.sort((a, b) => {
								const aSelected = values[a.id] === true;
								const bSelected = values[b.id] === true;

								if (!aSelected && !bSelected) {
									return 0;
								}

								return bSelected && !aSelected ? 1 : -1;
							  })
							: products.filter((product) =>
								values ? values[product.id] === true : true
							  );

					/** list items */
					const productItem = ({
						key,
						index,
						style,
					}: IListItemProps) => (
						<div key={key} style={style}>
							<CategoryProductListItem
								item={visibleProducts[index]}
							/>
						</div>
					);
					return (
						<StyledForm>
							<Prompt
								when={dirty && !formSubmission}
								message=""
							/>
							<StyledHeadings>
								<StyledHeading />
								<StyledHeading />
								<StyledHeading
									className="mod-sort"
									onClick={() => setSort('name')}
									onKeyPress={() => setSort('name')}
									role="button"
									tabIndex={0}
								>
									<StyledSortIcons
										active={sortColumn === 'name'}
										sort={sortDirection}
									/>
									<FormattedMessage id="categoryProducts.headings.name" />
								</StyledHeading>
								<StyledHeading
									className="mod-sort"
									onClick={() => setSort('sku')}
									onKeyPress={() => setSort('sku')}
									role="button"
									tabIndex={0}
								>
									<StyledSortIcons
										active={sortColumn === 'sku'}
										sort={sortDirection}
									/>
									<FormattedMessage id="categoryProducts.headings.sku" />
								</StyledHeading>
								<StyledHeading>
									<FormattedMessage id="categoryProducts.headings.price" />
								</StyledHeading>
							</StyledHeadings>
							<StyledProductsList>
								{visibleProducts?.length > 0 ? (
									<AutoSizer>
										{({ height, width }) => (
											<List
												width={width || 400}
												height={height || 550}
												rowCount={
													visibleProducts.length
												}
												rowHeight={60}
												rowRenderer={productItem}
											/>
										)}
									</AutoSizer>
								) : (
									// TODO - What if the list is empty?
									''
								)}
							</StyledProductsList>
							<StyledActions>
								<div>
									<Link
										to={`/menus/categories?activeAccount=${activeAccountId}`}
									>
										<Button variant="secondary">
											<FormattedMessage id="form.button.back" />
										</Button>
									</Link>
								</div>
								<Button
									type="submit"
									disabled={isSubmitting}
									ariaLabel="submit-button"
								>
									<FormattedMessage
										id={
											categoryId
												? 'form.button.save'
												: 'categoryForm.button.create'
										}
									/>
								</Button>
							</StyledActions>
						</StyledForm>
					);
				}}
			</Formik>
		</>
	);
};

export default CategoryProductsForm;
