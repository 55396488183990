import MockAdapter from 'axios-mock-adapter/types';

import { IBeacon, IBeaconImportValues } from './beacon.types';

import mockPagination from 'components/pagination/pagination.mock';

/** MOCKED DATA */
const beacon: IBeacon = {
	id: '2485adb5-ee4d-4643-8a7f-7b0543bd439d',
	uuid: '09e3216b-0538-4b80-a064-c73bb9ca154e',
	major: 1,
	minor: 2,
	type: 'threshold',
	venueId: '84bea6b3-1ed1-4997-afae-9bef2fb2731e',
	createdAt: '2020-06-06T14:44:09.947Z',
	pingedAt: '2020-06-06T14:44:09.947Z',
	identifier: 'Door',
};

const beacons: IBeacon[] = [
	{
		id: '2485adb5-ee4d-4643-8a7f-7b0543bd439d',
		uuid: '09e3216b-0538-4b80-a064-c73bb9ca154e',
		major: 1,
		minor: 2,
		type: 'threshold',
		venueId: '84bea6b3-1ed1-4997-afae-9bef2fb2731e',
		createdAt: '2020-06-06T14:44:09.947Z',
		pingedAt: '2020-06-06T14:44:09.947Z',
		identifier: 'Door',
	},
	{
		id: 'b21b3e09-91be-4eef-aa6c-89e6fd65ced6',
		uuid: '09e3216b-0538-4b80-a064-c73bb9ca154e',
		major: 10,
		minor: 2,
		type: 'threshold',
		venueId: '84bea6b3-1ed1-4997-afae-9bef2fb2731e',
		createdAt: '2020-06-07T13:23:18.997Z',
		pingedAt: '2020-06-07T13:23:18.997Z',
		identifier: 'Door 2',
	},
	{
		id: 'a3035628-b3b9-48b9-a338-aaf5a4432f51',
		uuid: '09e3216b-0538-4b80-a064-c73bb9ca154e',
		major: 1,
		minor: 12,
		type: 'threshold',
		venueId: '4219c4f0-3c7c-4c07-8a65-e0f4de93528a',
		createdAt: '2020-06-06T14:43:39.392Z',
		pingedAt: '2020-06-06T14:43:39.392Z',
		identifier: 'Door',
	},
];

const importBeaconParams: IBeaconImportValues = {
	beacons: new File(['content'], 'beacons.cvs', { type: 'cvs' }),
};

const venuBeaconSubmitValues: string[] = ['beacon1', 'beacon2'];

/** MOCKED ENDPOINTS CONFIG */
const getBeacons = {
	response: {
		beacons,
		pagination: mockPagination.data.pagination,
	},
};

const putVenueBeacons = {
	request: venuBeaconSubmitValues,
	response: {
		added: ['beacon1', 'beacon2'],
		removed: [],
	},
};

const importBeacons = {
	response: {
		validated: 12,
		syncing: 12,
	},
};

/** MOCKED ENDPOINTS */

// Regex for put venue beacons
export const putVenueBeaconsEndpointRegex = new RegExp(
	/beacons\/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\\/
);

export const mockedBeaconEndpoints = (mockHttpClient: MockAdapter) => {
	// Mock get beacons response
	mockHttpClient
		.onGet('beacons')
		.reply(200, mockBeacon.endpoints.importBeacons.response);

	// Mock put venue beacons response
	mockHttpClient
		.onPut(putVenueBeaconsEndpointRegex)
		.reply(200, mockBeacon.endpoints.putVenueBeacons.response);

	// Mock import beacons response
	mockHttpClient
		.onPost('imports/beacons/csv')
		.reply(200, mockBeacon.endpoints.importBeacons.response);
};

const mockBeacon = {
	data: {
		beacon,
		beacons,
		importBeaconParams,
		venuBeaconSubmitValues,
	},
	endpoints: {
		getBeacons,
		putVenueBeacons,
		importBeacons,
	},
};
export default mockBeacon;
