// @ts-nocheck
/* eslint-disable */
import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { createBrand } from 'modules/brand/brand.slice';
import { isIBrandDetails } from 'modules/brand/brand.types';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';

const StyledFormFields = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);

	h3 {
		margin: 0 0 5px 0;
		color: ${brand.validation_error};
	}
`;

const StyledErrors = styled.div`
	margin-bottom: 15px;
	font-weight: ${fonts.weights.medium};
	color: ${brand.validation_error};

	p {
		margin: 0 0 5px 0;
	}
`;

const StyledReady = styled.p`
	margin-bottom: 15px;
	font-weight: ${fonts.weights.medium};
	color: ${brand.validation_success};
`;

const StyledImagesWrap = styled.div`
	padding: 25px;
	border-radius: 10px 10px;
	display: flex;
	flex-direction: column;
	background: ${brand.white};
`;

const StyledImagesListWrap = styled.div`
	display: flex;
`;

/** Import brands component */
const ImportBrands: FunctionComponent = () => {
	const [brandErrors, setBrandErrors] = useState<string[]>([]);
	const [brandsReady, setBrandsReady] = useState<boolean>(false);

	const [logos, setLogos] = useState<string[]>([]);
	const [covers, setCovers] = useState<string[]>([]);

	const dispatch = useReduxDispatch();

	const handleSubmitBrands = async (
		values: any,
		{ setSubmitting }: FormikHelpers<any>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Reset brand errors
		setBrandErrors([]);
		const errors: Array<string> = [];

		// Store for brands
		let brands;

		// Check brands is valid JSON
		try {
			brands = JSON.parse(values.brands);
		} catch {
			errors.push('JSON is invalid, please correct and resubmit.');
			setBrandErrors(errors);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		// Check we have brands
		if (brands.length === 0) {
			errors.push('No brands found in form values.');
			setBrandErrors(errors);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		const isInvalid = brands
			.map((brand: any) => {
				const brandIsValid = isIBrandDetailsFormValues(brand, true);

				if (!brandIsValid) {
					errors.push(
						`A brand (${brand?.name}) was not in the valid format.`
					);
					setBrandErrors(errors);
				}

				return brandIsValid;
			})
			.includes(false);

		if (isInvalid) {
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		// Add logos to logoArray (so we can check they load)
		const logoArray: string[] = [];
		for (const item of brands) {
			if (item.logoUrl) {
				logoArray.push(item.logoUrl);
			}
		}
		setLogos(logoArray);

		// Add covers to coverArray (so we can check they load)
		const coverArray: string[] = [];
		for (const item of brands) {
			if (item.coverUrls && item.coverUrls.length) {
				coverArray.push(...item.coverUrls);
			}
		}
		setCovers(coverArray);

		if (values.dry) {
			setBrandsReady(true);
			// Set formik submission state to false
			setSubmitting(false);
			return false;
		}

		// Import all brands
		for (const item of brands) {
			const response = await dispatch(createBrand(item));
			console.log('createBrand response: ', item, response);
		}

		// Set formik submission state to false
		setSubmitting(false);
	};

	return (
		<>
			<StyledFormFields>
				<StyledColumn>
					<h2>Brands</h2>
					<Formik
						initialValues={{
							brands: '',
							dry: true,
						}}
						onSubmit={handleSubmitBrands}
					>
						{({ isSubmitting }) => (
							<Form>
								<Field
									component={TextArea}
									name="brands"
									label="Brands Data (JSON)"
									placeholder="Brands Data (JSON)"
								/>
								<Field
									component={Checkbox}
									name="dry"
									label="Dry run"
								/>
								{brandsReady ? (
									<StyledReady>
										Brands ready to import.
									</StyledReady>
								) : (
									''
								)}
								{brandErrors.length ? (
									<>
										<h3>
											Please correct the following errors:
										</h3>
										<StyledErrors>
											{brandErrors.map(
												(error: string, index) => (
													<p key={index}>{error}</p>
												)
											)}
										</StyledErrors>
									</>
								) : (
									''
								)}
								<Button
									type="submit"
									dissabled={isSubmitting}
									ariaLabel="submit-button"
								>
									Import Brands
								</Button>
							</Form>
						)}
					</Formik>
				</StyledColumn>
			</StyledFormFields>
			<br />
			{logos.length || covers.length ? (
				<StyledImagesWrap>
					<h2>Images to import</h2>
					{logos.length ? <h3>Logos:</h3> : ''}
					{logos.length ? (
						<StyledImagesListWrap>
							{logos.map((logoUrl: string, index) => (
								<img
									src={logoUrl}
									alt=""
									key={index}
									width="50"
								/>
							))}
						</StyledImagesListWrap>
					) : (
						''
					)}
					<br />
					<br />
					{covers.length ? <h3>Covers:</h3> : ''}
					{covers.length ? (
						<StyledImagesListWrap>
							{covers.map((coverUrl: string, index) => (
								<img
									src={coverUrl}
									alt=""
									key={index}
									width="50"
								/>
							))}
						</StyledImagesListWrap>
					) : (
						''
					)}
				</StyledImagesWrap>
			) : (
				''
			)}
		</>
	);
};

export default ImportBrands;
