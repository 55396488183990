import get from 'lodash.get';

/** MAIN TYPES */

// Interface for venue import
export interface IVenueImport {
	stonegateHouseId: string;
	brandName?: string;
	brandId: string;
	zonalVenueName?: string;
	venueName?: string;
	allergenUrl?: string;
	logoUrl: string;
	coverUrls?: string[];
}

/** ENDPOINT TYPES */

/** STATE TYPES */

export interface IImportState {
	eventsInProgress: number;
}

/** TYPE GUARDS */

// Type guard for venue import
export const isIVenueImport = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	venueImage: any
): venueImage is IVenueImport => {
	const venueImageFields = ['stonegateHouseId', 'brandId', 'logoUrl'];

	let isValid = true;
	venueImageFields.forEach((field) => {
		if (
			!get(venueImage as IVenueImport, field) ||
			get(venueImage as IVenueImport, field) === ''
		) {
			isValid = false;
		}
	});

	return isValid;
};
