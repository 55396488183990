import React, { FunctionComponent, ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';
import icons from 'components/icons/icons.config';

// Interface for component props
interface IButtonProps {
	ariaLabel?: string;
	size?: 'md' | 'lg';
	variant?: 'primary' | 'secondary' | 'icon' | 'text' | 'outline';
	icon?: keyof typeof icons;
	iconWidth?: number;
	iconHeight?: number;
	iconColour?: keyof typeof brand;
}

const outlineStyling = css<IButtonProps>`
	min-width: 115px;
	background: none;
	border: 1px solid ${brand.primary};
	box-shadow: none;
	border-radius: 5px;

	&:hover,
	&:active {
		background: none;
	}
`;

const iconStyling = css<IButtonProps>`
	width: ${({ iconWidth }) => iconWidth};
	min-width: 0;
	height: ${({ iconHeight }) => iconHeight};
	background: none;
	border: none;
	padding: 0;
	box-shadow: none;
	border-radius: 0;

	&:hover,
	&:active {
		background: none;
	}
`;

const textStyling = css<IButtonProps>`
	min-width: 0;
	background: none;
	border: none;
	padding: 0;
	box-shadow: none;
	border-radius: 0;

	&:hover,
	&:active {
		background: none;
	}
`;

const disabledStyling = css`
	background: ${brand.neutral};
	color: ${brand.placeholder};
	box-shadow: 0px 0px 0px 1px ${brand.neutral};

	&:hover {
		background: ${brand.neutral};
	}
`;

const buttonWithIconStyling = css`
	display: flex;
	align-items: center;
`;

// Create styled button component
const StyledButton = styled.button<
	IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>`
	min-width: 115px;
	line-height: 1;
	padding: ${({ size }) => (size === 'md' ? '12px' : '17px 20px')};
	border: none;
	box-shadow: 0px 0px 0px 1px
		${({ variant }) => (variant === 'primary' ? brand.primary : brand.secondary)};
	border-radius: 5px;
	cursor: pointer;
	background: ${({ variant }) =>
		variant === 'primary' ? brand.link : brand.secondary};
	color: ${({ variant }) =>
		variant === 'primary' ? brand.white : brand.primary};
	font-size: ${({ size }) => (size === 'md' ? fonts.sizes.med : fonts.sizes.med)};
	font-weight: ${fonts.weights.semibold};

	&:hover {
		background: ${({ variant }) =>
		variant === 'primary'
			? brand.link_hover
			: brand.link_hover_secondary};
	}

	&:active {
		background: ${brand.link_active};
		border-color: transparent;
		box-shadow: none;
	}

	&:focus {
		outline: none;
	}

	${({ disabled }) => (disabled === true ? disabledStyling : null)}
	${({ icon }) => (icon ? buttonWithIconStyling : null)}

	${({ variant }) => (variant === 'icon' ? iconStyling : null)}
	${({ variant }) => (variant === 'text' ? textStyling : null)}
	${({ variant }) => (variant === 'outline' ? outlineStyling : null)}
`;

const StyledIcon = styled(Icon)`
	margin-right: 10px;
`;

/** Renders button component */
const Button: FunctionComponent<
	IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
	const {
		ariaLabel,
		icon,
		iconHeight,
		iconWidth,
		children,
		disabled,
	} = props;

	const iconColour = !props.variant ? 'white' : props.iconColour;
	const size = !props.size ? 'md' : props.size;
	const type = !props.type ? 'button' : props.type;
	const variant = !props.variant ? 'primary' : props.variant;

	return (
		<StyledButton
			{...props}
			aria-label={ariaLabel}
			variant={variant}
			type={type}
			size={size}
			disabled={disabled}
		>
			{icon && (
				<StyledIcon
					name={icon}
					width={iconWidth}
					height={iconHeight}
					colour={disabled ? 'placeholder' : iconColour}
				/>
			)}
			{children}
		</StyledButton>
	);
};

export default Button;
