import { Formik, Form, Field, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import FieldError from 'components/field-error/field-error.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TimePickerInput from 'components/form-inputs/time-picker/time-picker.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { genericValidationString } from 'helpers/validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	updateVenueOpening,
	getVenueOpening,
} from 'modules/venue/slices/venue.slice';
import { IVenueOpeningFormValues } from 'modules/venue/venue.types';

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledDay = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5px 10px;
	border-radius: 10px;
	background: ${brand.neutral};

	&:nth-child(odd) {
		background: transparent;
	}

	div {
		text-align: center;
	}

	h3 {
		min-width: 35px;
		margin: 0 15px 0 0;
		font-weight: ${fonts.weights.regular};
	}
`;

const StyledDayFields = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledSwitchWrap = styled.div`
	width: 100px;
	margin-right: 15px;
	flex-shrink: 0;

	div {
		margin: 0;
	}
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	handleDelete: Function;
	venueId: string;
}

// Venue opening times form
const VenueOpeningForm: FunctionComponent<IComponentProps> = ({
	handleDelete,
	venueId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active venue opening times from store
	const activeVenueOpening: IVenueOpeningFormValues | undefined = useSelector(
		(state: RootState) => state?.venue?.activeVenueOpening
	);

	useEffect(() => {
		// Get venue data by venue id
		const getVenueData = async (id: string) => {
			await dispatch(getVenueOpening(id));
		};
		getVenueData(venueId);
	}, [venueId, dispatch]);

	// Default opening times field values
	const defaultOpening = {
		open: false,
		openingTime: '',
		closingTime: '',
	};

	// Initial form values
	const initialValues: IVenueOpeningFormValues = {
		monday: activeVenueOpening?.monday || defaultOpening,
		tuesday: activeVenueOpening?.tuesday || defaultOpening,
		wednesday: activeVenueOpening?.wednesday || defaultOpening,
		thursday: activeVenueOpening?.thursday || defaultOpening,
		friday: activeVenueOpening?.friday || defaultOpening,
		saturday: activeVenueOpening?.saturday || defaultOpening,
		sunday: activeVenueOpening?.sunday || defaultOpening,
	};

	// Day validation schema
	const dayValidation = object().shape({
		openingTime: string().when('open', {
			is: (value) => !!value,
			then: genericValidationString({ fieldName: 'openingTime' }).matches(
				/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
				intl.formatMessage({
					id: 'errors.forms.time.type',
				})
			),
		}),
		closingTime: string().when('open', {
			is: (value) => !!value,
			then: genericValidationString({ fieldName: 'closingTime' }).matches(
				/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
				intl.formatMessage({
					id: 'errors.forms.time.type',
				})
			),
		}),
	});

	// Validation schema
	const formValidationSchema = object<IVenueOpeningFormValues>().shape({
		monday: dayValidation,
		tuesday: dayValidation,
		wednesday: dayValidation,
		thursday: dayValidation,
		friday: dayValidation,
		saturday: dayValidation,
		sunday: dayValidation,
	});

	// Handle form submission
	const handleSubmit = async (
		values: IVenueOpeningFormValues,
		{ setSubmitting }: FormikHelpers<IVenueOpeningFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Update venue
		const response = await dispatch(updateVenueOpening(venueId, values));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);
		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ errors, dirty, values, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledFieldGroup>
						<StyledColumn>
							<h2>
								<FormattedMessage id="venueForm.headings.opening" />
							</h2>
							{[
								'monday',
								'tuesday',
								'wednesday',
								'thursday',
								'friday',
								'saturday',
								'sunday',
							].map((day) => (
								<StyledDay key={day}>
									<StyledDayFields>
										<h3>
											{day.substring(0, 3).toUpperCase()}
										</h3>
										<StyledSwitchWrap>
											<Field
												component={Switch}
												name={`${day}.open`}
												onText={intl.formatMessage({
													id:
														'form.fields.venueOpen.on',
												})}
												offText={intl.formatMessage({
													id:
														'form.fields.venueOpen.off',
												})}
											/>
										</StyledSwitchWrap>
										<Field
											component={TimePickerInput}
											name={`${day}.openingTime`}
											isDisabled={!values[day].open}
											timeType="from"
										/>
										<Field
											component={TimePickerInput}
											name={`${day}.closingTime`}
											isDisabled={!values[day].open}
											timeType="to"
										/>
									</StyledDayFields>
									{!!errors[day] && !!values[`${day}`].open && (
										<FieldError
											ariaLabel={`${day}.open-error`}
										>
											<FormattedMessage id="form.fields.venueTimes.empty" />
										</FieldError>
									)}
								</StyledDay>
							))}
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{venueId && (
								<Button
									variant="secondary"
									onClick={() => {
										handleDelete(setFormSubmission);
									}}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="venueForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default VenueOpeningForm;
