import { rgba } from 'polished';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { removeAlert } from './alert.slice';
import { IAlertMessage } from './alert.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import structure from 'assets/styles/variables/structure';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';

interface IStyledComponent {
	isNavOpen?: boolean;
}
const StyledAlerts = styled.div`
	width: 100%;
	display: flex;
	padding: 10px 10px 0;
	flex-flow: column;
	align-items: flex-end;
	position: fixed;
	top: 0;
	z-index: 1;
`;

const StyledAlertContainer = styled.div<IStyledComponent>`
	width: calc(
		100% -
			${({ isNavOpen }) =>
		isNavOpen
			? structure.nav_width_open
			: structure.nav_width_closed}
	);
	transition: width 0.2s ease-in;
`;

const errorAlertStyling = css`
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
`;

const successAlertStyling = css`
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
`;

const defaultAlertStyling = css`
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
`;

const StyledAlert = styled.div<IAlertMessage>`
	width: 100%;
	padding: 0.75rem 1.25rem;
	margin-bottom: 5px;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	box-shadow: 0 5px 15px ${rgba(brand.black, 0.1)};

	${({ type }) => {
		if (type === 'error') {
			return errorAlertStyling;
		}
		if (type === 'success') {
			return successAlertStyling;
		}
		return defaultAlertStyling;
	}}

	.alert-content {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h4 {
			min-width: 75px;
			margin: 0;
			line-height: 1;
			color: inherit;
		}
		p {
			margin: 0;
			line-height: 1;
			font-size: ${fonts.sizes.med};
			font-weight: ${fonts.weights.regular};
		}
	}

	.button-close {
		padding: 0;
		line-height: 1;
		border: none;
		cursor: pointer;
		color: inherit;
		background: transparent;
		font-size: ${fonts.sizes.large};

		&:focus {
			outline: 0;
		}
	}
`;

/** Renders Alert message */
const AlertMessage: FunctionComponent<IAlertMessage> = ({
	title,
	message,
	type,
	id,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	return (
		<StyledAlert type={type}>
			<div className="alert-content">
				<h4>{title}</h4>
				<p>{message}</p>
			</div>
			<button
				type="button"
				className="button-close"
				aria-label="Close"
				onClick={() => {
					dispatch(removeAlert(id!));
				}}
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</StyledAlert>
	);
};

/** Renders Alert component */
const Alert: FunctionComponent = () => {
	const alerts: IAlertMessage[] = useSelector(
		(state: RootState) => state.alert.alerts
	);

	// Get whole state object from store
	const isNavOpen = useSelector((state: RootState) => state.nav.open);

	return (
		<StyledAlerts>
			<StyledAlertContainer isNavOpen={isNavOpen}>
				{alerts.map((alert: IAlertMessage, index) => (
					<AlertMessage
						key={alert.id}
						id={alert.id}
						title={alert.title}
						type={alert.type}
						message={alert.message}
					/>
				))}
			</StyledAlertContainer>
		</StyledAlerts>
	);
};

export default Alert;
