import { History } from 'history';
import React, { FunctionComponent, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import BrandMarketingMappingForm from '../brand-marketing-mapping-form/brand-marketing-mapping-form.component';
import BrandMarketingSettingsForm from '../brand-marketing-settings-form/brand-marketing-settings-form.component';

import TabList from 'components/tab-list/tab-list.component';
import { isAirshipMarketingConfig } from 'modules/brand/brand.types';
import { RootState } from 'modules/core/state/root.reducer';

interface IComponentProps {
	brandId?: string;
	history: History;
	secondaryTab: string;
}

const StyledTabList = styled(TabList)`
	margin: 2px 0 20px;
`;

const StyledContentWrapper = styled.div`
	header {
		margin-bottom: 25px;
	}
`;

const BrandMarketingForm: FunctionComponent<IComponentProps> = ({
	history,
	brandId,
	secondaryTab,
}) => {
	const [activeTab, setActiveTab] = useState(secondaryTab || 'settings');

	const intl = useIntl();

	const { activeMarketingOptin } = useSelector(
		(state: RootState) => state.brand
	);

	const { integrationConfig, integrationType } = { ...activeMarketingOptin };

	const showMappingTab = useMemo(() => {
		if (
			integrationType &&
			integrationConfig &&
			isAirshipMarketingConfig(integrationConfig) &&
			integrationType === 'airship' &&
			integrationConfig?.accountId &&
			integrationConfig?.accountAccessToken
		) {
			return true;
		}

		return false;
	}, [integrationConfig, integrationType]);

	return (
		<StyledContentWrapper>
			<StyledTabList
				{...{
					activeTab,
					setActiveTab,
				}}
				navigateOnClick={true}
				history={history}
				parentUrl={`/brands/edit/${brandId}/marketing/`}
				tabStyle="underlined"
				tabs={[
					{
						id: 'settings',
						title: intl.formatMessage({
							id: 'brandForm.tabs.marketing.secondary.settings',
						}),
					},
					{
						id: 'mapping',
						title: intl.formatMessage({
							id: 'brandForm.tabs.marketing.secondary.mapping',
						}),
						isHidden: !showMappingTab,
					},
				]}
			/>

			{activeTab === 'settings' && (
				<BrandMarketingSettingsForm {...{ history, brandId }} />
			)}

			{activeTab === 'mapping' && (
				<BrandMarketingMappingForm {...{ history, brandId }} />
			)}
		</StyledContentWrapper>
	);
};

export default BrandMarketingForm;
