import { IPagination } from 'app.types';

/** MAIN TYPES */
export enum EOrderStatus {
	pendingPaymentValidation,
	paymentFailed,
	pendingPickup,
	venueRejected,
	completed,
}

export enum EServiceType {
	orderAhead,
	tableService,
}

export enum EEntityType {
	basket = 'BASKET',
}

export interface IOrder {
	id: string;
	orderNumber: string;
	orderDate: string;
	customerName: string;
	customerEmail: string;
	orderStatus: keyof typeof EOrderStatus;
	price: {
		units: number;
		currencyCode: string;
	};
}

export interface ICustomerNotesItem {
	roundId: number;
	note: string;
	addedAt: string;
}

export interface IOrderDetail {
	id: string;
	number: string;
	status: keyof typeof EOrderStatus;
	date: string;
	service: keyof typeof EServiceType;
	tableNumber: string;
	customerName: string;
	customerEmail: string;
	accountName: string;
	brandName: string;
	venueName: string;
	customerNotes?: ICustomerNotesItem[];
}

export interface IOrderTransaction {
	id: string;
	appUserId: string;
	orderId: string;
	cardId: string | null;
	total: string;
	status: number;
	cccTransactionId: string;
	cccDeviceId: string | null;
	venueId: string;
	brandId: string;
	accountId: string | null;
	source: string;
	isReversed: boolean;
	createdAt: string;
	updatedAt: string;
}
export interface IOrderRefund {
	amountInPence: number;
	originalCCCTransactionId?: string;
	sendCustomerEmail?: boolean;
}

export interface IOrderAuditLog {
	id: string;
	dateCreated: Date;
	entityId: string;
	entityType: EEntityType;
	source: string;
	logType: string;
	message: string;
	metadata?: object;
}

/** STATE TYPES */
export interface IOrderState {
	activeOrder?: IOrderDetail;
	activeOrderTransactions?: IOrderTransaction[];
	orders: IOrder[];
	eventsInProgress: number;
	pagination: IPagination;
	audits: IOrderAuditLog[];
}

export interface IOrderAuditState {
	audits: IOrderAuditLog[];
	eventsInProgress: number;
}

/** ENDPOINT TYPES */

export interface IOrderListFilters {
	query?: string;
	accountIds?: string;
	brandIds?: string;
	venueIds?: string;
	pageNumber?: number;
	pageSize?: number;
}
