import { FieldProps } from 'formik';
import get from 'lodash.get';
import { rgba } from 'polished';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import SortableMiniListItem from './sortable-mini-list-item/sortable-mini-list-item.component';
import { ISortItem } from './sortable-mini-list.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';

// Props for list items component
interface IListItemsProps {
	items: ISortItem[];
	sortable: boolean;
	removeItem?: Function;
}

// Create memoised component for mini list items
const ListItems = React.memo<IListItemsProps>(
	({ items, sortable, removeItem }) => (
		<div>
			{items.map((item: ISortItem, index: number) => (
				<SortableMiniListItem
					item={item}
					index={index}
					key={item.id}
					sortable={sortable}
					removeItem={removeItem}
				/>
			))}
		</div>
	)
);

const StyledFormElement = styled(FormElement)`
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
`;

const StyledLabel = styled.label`
	margin-bottom: 5px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledHeader = styled.header`
	width: 100%;
	padding: 14px 20px;
	border-bottom: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;
	user-select: none;
`;

const StyledClearButton = styled(Button)`
	font-size: ${fonts.sizes.standard};
`;

const StyledMiniList = styled.div<IComponentProps>`
	width: 100%;
	min-height: 200px;
	max-height: 100vh;
	overflow: auto;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	user-select: none;

	${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}
`;

// Interface for main component props
interface IComponentProps {
	label?: string;
	sortable?: boolean;
	removable?: boolean;
	clearable?: boolean;
	maxHeight?: number;
}

const SortableMiniList: FunctionComponent<IComponentProps & FieldProps> = ({
	field,
	form,
	label,
	sortable = true,
	removable,
	clearable = false,
	maxHeight,
}) => {
	const { touched, errors } = form;
	const isTouched = get(touched, field.name) || false;
	const hasErrors = get(errors, field.name) || false;

	// Interface for list order state
	interface IListOrder {
		items: ISortItem[];
	}

	// Store items in state
	const [listOrder, setListOrder] = useState<IListOrder>({
		items: field.value,
	});

	useEffect(() => {
		setListOrder({
			items: field.value,
		});
	}, [field.value]);

	/** Reorder list items */
	const reorder = (
		list: ISortItem[],
		startIndex: number,
		endIndex: number
	) => {
		// Create an array from the list of items
		const result = Array.from(list);
		// Remove moved item from old index
		const [removed] = result.splice(startIndex, 1);
		// Put moved item at new index
		result.splice(endIndex, 0, removed);

		return result;
	};

	/** Handle drag end */
	function onDragEnd(result: DropResult) {
		// If dropped out of bounds
		if (!result.destination) {
			return;
		}

		// If dropped in original spot
		if (result.destination.index === result.source.index) {
			return;
		}

		// Reorder list
		const itemsOrder = reorder(
			listOrder.items,
			result.source.index,
			result.destination.index
		);

		// Update state
		setListOrder({
			items: itemsOrder,
		});

		form.setFieldTouched(field.name);

		// Set field value
		form.setFieldValue(field.name, itemsOrder);
	}

	/** Handle removing item from list */
	const onRemoveItem = (itemToRemove: ISortItem) => {
		form.setFieldTouched(field.name);

		form.setFieldValue(
			field.name,
			field.value.filter((item: ISortItem) => item.id !== itemToRemove.id)
		);
	};

	/** Handle clearing all items from list */
	const handleClearAll = () => {
		form.setFieldTouched(field.name);
		form.setFieldValue(field.name, []);
	};

	return (
		<StyledFormElement>
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledMiniList
				data-testid="sortable-mini-list"
				className={isTouched && hasErrors ? 'has-error' : ''}
				maxHeight={maxHeight}
			>
				{clearable && (
					<StyledHeader>
						{field.value.length ? (
							field.value.length
						) : (
							<FormattedMessage id="components.sortableMiniList.none" />
						)}{' '}
						<FormattedMessage id="components.sortableMiniList.selected" />
						<StyledClearButton
							variant="text"
							onClick={handleClearAll}
						>
							<FormattedMessage id="components.sortableMiniList.clearAll" />
						</StyledClearButton>
					</StyledHeader>
				)}
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="list" isDropDisabled={!sortable}>
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<ListItems
									items={listOrder.items}
									sortable={sortable}
									removeItem={
										removable ? onRemoveItem : undefined
									}
								/>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</StyledMiniList>
			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</StyledFormElement>
	);
};

export default SortableMiniList;
