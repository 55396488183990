// Function to convert a number to seconds
export function convertToSeconds(minutes: number): number {
	// Throw error if not a number
	if (Number.isNaN(minutes)) {
		throw new Error('Minutes is not a number');
	}

	return minutes * 60;
}

// Function to convert a number to minutes
export function convertToMinutes(seconds: number): number {
	// Throw error if not a number
	if (Number.isNaN(seconds)) {
		throw new Error('Minutes is not a number');
	}

	return seconds / 60;
}
