import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import ColourPicker from 'components/form-inputs/colour-picker/colour-picker.component';
import DisplayValue from 'components/form-inputs/display-value/display-value.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getBrandsList } from 'modules/brand/brand.slice';
import { IBrandItem } from 'modules/brand/brand.types';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import { getMenuPreferences } from 'modules/menu/menu.slice';
import { ICuisine } from 'modules/menu/menu.types';
import { updateVenueDetails } from 'modules/venue/slices/venue.slice';
import { IVenueDetailsFormValues } from 'modules/venue/venue.types';

const StyledVenueDetailsForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledSubtitle = styled.p`
	margin-bottom: 15px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledCheckboxGroup = styled.div`
	margin-bottom: -20px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;

	& > div {
		width: calc(50% - 20px);
	}
`;

const StyledH2 = styled.h2`
	margin-top: 40px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IVenueDetailsFormProps {
	handleDelete: Function;
	venueId: string;
}

// Venue details form
const VenueDetailsForm: FunctionComponent<IVenueDetailsFormProps> = ({
	handleDelete,
	venueId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active venue details from store
	const activeVenueDetails: IVenueDetailsFormValues | undefined = useSelector(
		(state: RootState) => state?.venue?.activeVenueDetails
	);

	// Get brands from store and prepare for select
	const brandOptions: IOption[] = useSelector(
		(state: RootState) => state.brand.brands
	).map((value: IBrandItem) => ({
		// Format brand as IOption
		label: value.name,
		value: value.id,
	}));

	// Get cuisine options from store
	const { cuisines: cuisineOptions } = useSelector(
		(state: RootState) => state?.menu?.preferences
	);

	useEffect(() => {
		// Get venue data by venue id
		const getVenueData = async (id: string) => {
			await dispatch(
				getBrandsList({
					accountUUID: activeVenueDetails?.accountId,
					pageSize: 500,
				})
			);
			await dispatch(getMenuPreferences());
		};
		venueId && getVenueData(venueId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [venueId, dispatch]);

	const createPaymentAlertsLink = (): string => {
		switch (process.env.REACT_APP_ENVIRONMENT) {
			case 'production':
				return `https://payment-alerts.orderpay.com/${venueId}/`;
			case 'staging':
				return `https://payment-alerts.staging.orderpay.com/${venueId}/`;
			case 'alpha':
				return `https://payment-alerts.alpha.orderpay.com/${venueId}/`;
			default:
				return `https://localhost:4200/${venueId}/`;
		}
	};

	// Initial form values
	const initialValues: IVenueDetailsFormValues = {
		name: (venueId && activeVenueDetails?.name) || '',
		vatNo: (venueId && activeVenueDetails?.vatNo) || '',
		brandId: (venueId && activeVenueDetails?.brandId) || '',
		description: (venueId && activeVenueDetails?.description) || '',
		address: {
			line1: (venueId && activeVenueDetails?.address?.line1) || '',
			line2: (venueId && activeVenueDetails?.address?.line2) || '',
			city: (venueId && activeVenueDetails?.address?.city) || '',
			postcode: (venueId && activeVenueDetails?.address?.postcode) || '',
			country: (venueId && activeVenueDetails?.address?.country) || '',
		},
		contact: {
			website: (venueId && activeVenueDetails?.contact?.website) || '',
			email: (venueId && activeVenueDetails?.contact?.email) || '',
			phone: (venueId && activeVenueDetails?.contact?.phone) || '',
			name: (venueId && activeVenueDetails?.contact?.name) || '',
		},
		cuisine: (venueId && activeVenueDetails?.cuisine) || [],
		logoUrl: (venueId && activeVenueDetails?.logoUrl) || '',
		coverUrls:
			(venueId &&
				activeVenueDetails?.coverUrls &&
				JSON.parse(activeVenueDetails?.coverUrls)) ||
			[],
		useLogoBackground:
			(venueId && activeVenueDetails?.useLogoBackground) || false,
		logoTintColor: (venueId && activeVenueDetails?.logoTintColor) || '',
	};

	// Validation schema
	const formValidationSchema = object<IVenueDetailsFormValues>().shape({
		contact: object().shape({
			email: string().email(validationMessages.email('email')),
		}),
		logoTintColor: string().matches(
			/^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
			validationMessages.hexValue('logoTintColor')
		),
	});

	// Handle form submission
	const handleSubmit = async (
		values: IVenueDetailsFormValues,
		{ setSubmitting }: FormikHelpers<IVenueDetailsFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		// Check we have required data
		if (
			activeVenueDetails?.brandId &&
			cuisineOptions &&
			cuisineOptions.length
		) {
			// Update venue
			const response = await dispatch(
				updateVenueDetails(venueId, values, cuisineOptions)
			);

			// Return on fail
			if (!response) {
				// Set formik submission state to false
				setSubmitting(true);
				return;
			}
			// Show success alert
			await dispatch(
				addAlert({
					title: intl.formatMessage({
						id: 'alerts.success.title',
					}),
					message: intl.formatMessage({
						id: 'venueForm.alerts.updated.message',
					}),
					type: 'success',
				})
			);
		}

		// Set formik submission state to true
		setSubmitting(true);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, dirty, isSubmitting }) => (
				<StyledVenueDetailsForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<StyledFieldGroup>
						<StyledColumn>
							<h2>
								<FormattedMessage id="venueForm.headings.details.information" />
							</h2>
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.venueName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.venueName.label',
								})}
							/>
							<DisplayValue
								label={intl.formatMessage({
									id: 'form.fields.venueId.label',
								})}
								value={venueId}
								copyValue={true}
							/>
							<DisplayValue
								label={intl.formatMessage({
									id: 'form.fields.paymentAlertsLink.label',
								})}
								value={createPaymentAlertsLink()}
								copyValue={true}
							/>
							<Field
								component={Select}
								label={intl.formatMessage({
									id: 'form.fields.brand.label',
								})}
								value={brandOptions.find(
									(brandOpt) =>
										brandOpt.value === values.brandId
								)}
								isSearchable={true}
								placeholder={intl.formatMessage({
									id: 'form.fields.brand.label',
								})}
								name="brandId"
								selectOptions={brandOptions}
							/>
							<Field
								component={TextArea}
								name="description"
								label={intl.formatMessage({
									id: 'form.fields.description.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.description.label',
								})}
							/>
							<Field
								component={Input}
								name="vatNo"
								label={intl.formatMessage({
									id: 'form.fields.vatNo.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.vatNo.label',
								})}
								isPrivate={true}
							/>
							<StyledSubtitle>
								<FormattedMessage id="form.fields.cuisines.label" />
							</StyledSubtitle>
							<StyledCheckboxGroup>
								{cuisineOptions?.map((item: ICuisine) => (
									<Field
										key={item.id}
										component={Checkbox}
										name={`cuisine[${item.id}]`}
										label={item.title}
									/>
								))}
							</StyledCheckboxGroup>
							<StyledH2>
								<FormattedMessage id="venueForm.headings.details.images" />
							</StyledH2>
							<StyledFieldGroup>
								<StyledColumn>
									<Field
										component={ImageUpload}
										name="logoUrl"
										label={intl.formatMessage({
											id:
												'form.fields.brandLogoOverride.label',
										})}
										placeholder={intl.formatMessage({
											id:
												'form.fields.brandLogoOverride.label',
										})}
										emptyMessage={intl.formatMessage({
											id:
												'form.fields.venue.logoUrl.emptyMessage',
										})}
										uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/venue/images/logo`}
									/>
								</StyledColumn>
								<StyledColumn>
									<Field
										component={ImageUpload}
										name="coverUrls"
										label={intl.formatMessage({
											id: 'form.fields.coverUrls.label',
										})}
										placeholder={intl.formatMessage({
											id: 'form.fields.coverUrls.label',
										})}
										uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/venue/images/cover`}
									/>
								</StyledColumn>
							</StyledFieldGroup>

							<Field
								component={Switch}
								name="useLogoBackground"
								label={intl.formatMessage({
									id: 'form.fields.useLogoBackground.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.useLogoBackground.onText',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.useLogoBackground.offText',
								})}
							/>
							<Field
								component={ColourPicker}
								name="logoTintColor"
								label={intl.formatMessage({
									id: 'form.fields.logoTintColor.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.logoTintColor.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<h2>
								<FormattedMessage id="venueForm.headings.details.address" />
							</h2>
							<Field
								component={Input}
								name="address.line1"
								label={intl.formatMessage({
									id: 'form.fields.address.line1.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.address.line1.label',
								})}
							/>
							<Field
								component={Input}
								name="address.line2"
								label={intl.formatMessage({
									id: 'form.fields.address.line2.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.address.line2.label',
								})}
							/>
							<Field
								component={Input}
								name="address.city"
								label={intl.formatMessage({
									id: 'form.fields.address.city.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.address.city.label',
								})}
							/>
							<Field
								component={Input}
								name="address.postcode"
								label={intl.formatMessage({
									id: 'form.fields.address.postcode.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.address.postcode.label',
								})}
							/>
							<Field
								component={Input}
								name="address.country"
								label={intl.formatMessage({
									id: 'form.fields.address.country.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.address.country.label',
								})}
							/>
							<StyledH2>
								<FormattedMessage id="venueForm.headings.details.contact" />
							</StyledH2>
							<Field
								component={Input}
								name="contact.website"
								label={intl.formatMessage({
									id: 'form.fields.website.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.website.label',
								})}
							/>
							<Field
								component={Input}
								name="contact.email"
								label={intl.formatMessage({
									id: 'form.fields.email.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.email.label',
								})}
							/>
							<Field
								component={Input}
								name="contact.phone"
								label={intl.formatMessage({
									id: 'form.fields.phone.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.phone.label',
								})}
							/>
							<Field
								component={Input}
								name="contact.name"
								label={intl.formatMessage({
									id: 'form.fields.contactName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.contactName.label',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{venueId && (
								<Button
									variant="secondary"
									onClick={() => {
										handleDelete(setFormSubmission);
									}}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="venueForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledVenueDetailsForm>
			)}
		</Formik>
	);
};

export default VenueDetailsForm;
