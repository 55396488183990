import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Button from 'components/button/button.component';
import Icon from 'components/icons/icon.component';

// Interface for component props
interface IComponentProps {
	toggleContainer: Function;
	deleteImage: Function;
	imageUrl: string;
}

const StyledImageContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 4;
`;

const StyledContent = styled.div`
	width: 100%;
	min-width: 780px;
	max-width: 780px;
	position: relative;
	background: ${brand.white};
	border-radius: 5px;
	padding: 25px;
	max-height: 90%;
	overflow: scroll;
`;

const StyledHeader = styled.header`
	margin: 0 0 25px;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;

	h3 {
		margin: 0;
	}
`;

const StyledClose = styled.div`
	width: 20px;
	height: 20px;
	cursor: pointer;
`;

const StyledImg = styled.img`
	width: 100%;
	height: auto;
	background: ${brand.borders};
`;

const StyledButtons = styled.div`
	display: flex;
	justify-content: center;
	margin: 25px 0;
`;

/** Renders image modal component */
const ImageContainer: FunctionComponent<IComponentProps> = ({
	imageUrl,
	toggleContainer,
	deleteImage,
}) => {
	return (
		<StyledImageContainer>
			<StyledContent data-testid="image-container-component">
				<StyledHeader>
					<StyledClose
						data-testid="close"
						onClick={() => {
							toggleContainer(false);
						}}
					>
						<Icon name="close" width={20} height={20} />
					</StyledClose>
				</StyledHeader>
				<StyledImg src={imageUrl} />
				<StyledButtons>
					<Button onClick={() => deleteImage()}>
						<FormattedMessage id="imageUpload.management.delete" />
					</Button>
				</StyledButtons>
			</StyledContent>
		</StyledImageContainer>
	);
};

export default ImageContainer;
