import { History } from 'history';
import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import useReportPusherEvent from './hooks/use-report-pusher-event.hook';
import ReportRemittance from './report-remittance/report-remittance.component';
import ReportTransaction from './report-transaction/report-transaction.component';
import ReportsForm from './reports-form/reports-form.component';

import FormWrapper from 'components/form-wrapper/form-wrapper.component';
import SectionHeading from 'components/section-heading/section-heading.component';
import TabList from 'components/tab-list/tab-list.component';
import { intl } from 'modules/core/i18n/i18n.config';
import withNav from 'modules/navigation/with-nav.component';

const StyledHeader = styled.header`
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

interface IComponentProps {
	accountId?: string;
	brandId?: string;
	venueId?: string;
	parentUrl: string;
	history: History;
}

const ReportPage: FunctionComponent<IComponentProps> = ({
	accountId,
	brandId,
	venueId,
	parentUrl,
	history,
}) => {
	// Get active tab from url params
	const { primaryTab }: { primaryTab?: string } = useParams();

	// Tab state
	const [activeTab, setActiveTab] = useState(primaryTab || 'reports');

	// Handle asyncronous report flow.
	// Binds to pusher channel
	useReportPusherEvent();

	return (
		<>
			<StyledHeader>
				<SectionHeading
					title="reports.title"
					subtitle="reports.subtitle"
				/>
			</StyledHeader>
			<TabList
				{...{ activeTab, setActiveTab }}
				navigateOnClick={true}
				history={history}
				parentUrl={parentUrl}
				tabs={[
					{
						id: 'reports',
						title: intl.formatMessage({
							id: 'reports.tabs.reports.primary.reports',
						}),
					},
					{
						id: 'transaction',
						title: intl.formatMessage({
							id: 'reports.tabs.reports.primary.transaction',
						}),
					},
					{
						id: 'remittance',
						title: intl.formatMessage({
							id: 'reports.tabs.reports.primary.remittance',
						}),
					},
				]}
			/>
			<FormWrapper>
				{activeTab === 'reports' && <ReportsForm />}
				{activeTab === 'transaction' && (
					<ReportTransaction
						{...{ accountId, brandId, venueId, history }}
					/>
				)}
				{activeTab === 'remittance' && (
					<ReportRemittance
						{...{ accountId, brandId, venueId, history }}
					/>
				)}
			</FormWrapper>
		</>
	);
};

export default withNav(ReportPage);
