import React, { FunctionComponent } from 'react';

import FormToolTip, { IToolTip } from '../form-tooltip/form-tooltip.component';
import { StyledLabelWrapper, StyledLabel } from './field-label.styles';

interface IComponentProps {
	fieldName: string;
	label: string;
	toolTip?: IToolTip;
	labelOnClick?: () => void;
}

const FieldLabel: FunctionComponent<IComponentProps> = ({
	fieldName,
	label,
	toolTip,
	labelOnClick,
}) => (
	<StyledLabelWrapper>
		<StyledLabel htmlFor={fieldName} onClick={labelOnClick}>
			{label}
		</StyledLabel>
		{toolTip && (
			<FormToolTip
				title={toolTip.title}
				description={toolTip.description}
			/>
		)}
	</StyledLabelWrapper>
);

export default FieldLabel;
