import MockAdapter from 'axios-mock-adapter/types';

import { ILoginCredentials, IResetPasswordParams } from './auth.types';

/** MOCKED ENDPOINTS DATA */

const loginSuccessParams: ILoginCredentials = {
	email: 'realdude@orderpay.com',
	password: 'legitpa55w0rd',
};

const loginFailureParams: ILoginCredentials = {
	email: 'joebob@orderpay.com',
	password: 'gophers',
};

const resetPasswordSuccessParams: IResetPasswordParams = {
	code: 'mockHash',
	email: loginSuccessParams.email,
	password: loginSuccessParams.password,
};

const resetPasswordFailureParams: IResetPasswordParams = {
	code: 'failHash',
	email: loginFailureParams.email,
	password: loginFailureParams.password,
};

/** MOCKED ENDPOINTS CONFIG */

const createLogin = {
	request: loginSuccessParams,
	response: {
		accessToken: {
			expiry: new Date().getTime() / 1000 + 1000,
		},
		refreshToken: {
			expiry: new Date().getTime() / 1000 + 1000,
		},
		user: {
			id: 'ebe789a3-1dc4-47a8-b11f-f483f14fa6dc',
		},
	},
};

const createRefresh = {
	response: {
		accessToken: {
			expiry: new Date().getTime() / 1000 + 1000,
		},
		refreshToken: {
			expiry: new Date().getTime() / 1000 + 1000,
		},
	},
};

const createResetPassword = {
	request: resetPasswordSuccessParams,
};

/** MOCKED ENDPOINTS */

export const mockedAuthEndpoints = (mockHttpClient: MockAdapter) => {
	mockHttpClient
		.onPost('auth/refresh')
		.reply(200, mockAuth.endpoints.createRefresh.response);
	mockHttpClient
		.onPost('auth/login')
		.reply(200, mockAuth.endpoints.createLogin.response);
	mockHttpClient.onPost('auth/forgot-password').reply(200);
	mockHttpClient.onPost('auth/check-code').reply(200);
};

const mockAuth = {
	data: {
		loginSuccessParams,
		loginFailureParams,
		resetPasswordFailureParams,
	},
	endpoints: {
		createLogin,
		createRefresh,
		createResetPassword,
	},
};
export default mockAuth;
