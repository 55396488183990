import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Checkbox from 'components/form-inputs/checkbox/checkbox.component';
import { formatWithPriceBands } from 'helpers/price-band.helper';
import { IProduct } from 'modules/menu/menu.types';

const StyledListItem = styled.li`
	margin: 5px 0 0 0;
	padding: 0 10px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: ${brand.neutral};
	overflow: hidden;

	img {
		width: 56px;
		margin-right: 25px;
	}
`;

const StyledColumn = styled.div`
	min-width: 120px;
	min-height: 56px;
	margin: 0 45px 0 0;
	padding-left: 5px;
	display: flex;
	align-items: center;
	overflow-wrap: anywhere;

	&:first-child {
		min-width: 30px;
		width: 30px;
		margin-right: 15px;
	}

	&:nth-child(2) {
		min-width: 50px;
		width: 50px;
		padding: 0;
		margin: 0 10px;

		img {
			margin: 0;
		}
	}

	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(5) {
		width: calc(50% - 80px);
		max-width: 300px;
	}
`;

const StyledCheckbox = styled(Checkbox)`
	margin: 0;
`;

// Props for component
interface IComponentProps {
	item: IProduct;
}
// category product list item component
const CategoryProductListItem: FunctionComponent<IComponentProps> = ({
	item,
}) => (
	<StyledListItem key={item.id} aria-label="category-product-item">
		<StyledColumn>
			<Field component={StyledCheckbox} name={item.id} />
		</StyledColumn>
		<StyledColumn>
			{!!item.imageUrl && (
				<img
					src={item.imageUrl!}
					alt={`${item.name} product`}
					width="50"
				/>
			)}
		</StyledColumn>
		<StyledColumn>{item.name}</StyledColumn>
		<StyledColumn>{item.sku}</StyledColumn>
		<StyledColumn>{formatWithPriceBands(item)}</StyledColumn>
	</StyledListItem>
);

export default CategoryProductListItem;
