import { rgba } from 'polished';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { removeToast } from './toast.slice';
import { IToastMessage } from './toast.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';

const StyledToasts = styled.div`
	display: flex;
	flex-flow: column-reverse;
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 1;
`;

const errorHeaderStyling = css`
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
`;

const successHeaderStyling = css`
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
`;

const defaultHeaderStyling = css`
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
`;

const StyledToast = styled.div<IToastMessage>`
	width: 350px;
	overflow: hidden;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	box-shadow: 0 5px 15px ${rgba(brand.black, 0.1)};
	margin-bottom: 5px;
	background-color: ${brand.white};

	${({ type }) => {
		if (type === 'error') {
			return css`
				border-color: #f5c6cb;
			`;
		}
		if (type === 'success') {
			return css`
				border-color: #c3e6cb;
			`;
		}
		return css`
			border-color: #bee5eb;
		`;
	}}

	.toast-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.75rem 1.25rem;

		${({ type }) => {
		if (type === 'error') {
			return errorHeaderStyling;
		}
		if (type === 'success') {
			return successHeaderStyling;
		}
		return defaultHeaderStyling;
	}}

		h4 {
			margin: 0;
			color: inherit;
		}

		.button-close {
			padding: 0;
			line-height: 1;
			border: none;
			cursor: pointer;
			color: inherit;
			background: transparent;
			font-size: ${fonts.sizes.large};

			&:focus {
				outline: 0;
			}
		}
	}

	.toast-content {
		padding: 0.75rem 1.25rem;

		p {
			font-size: ${fonts.sizes.med};

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
`;

const ToastMessage: FunctionComponent<IToastMessage> = ({
	title,
	message,
	type,
	id,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	return (
		<StyledToast type={type}>
			<div className="toast-header" data-testid="toast-header">
				<h4>{title}</h4>
				<button
					type="button"
					className="button-close"
					aria-label="Close"
					onClick={() => {
						dispatch(removeToast(id!));
					}}
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="toast-content">
				<p>{message}</p>
			</div>
		</StyledToast>
	);
};

/** Renders Toast component */
const Toasts: FunctionComponent = () => {
	const toasts: IToastMessage[] = useSelector(
		(state: RootState) => state.toast.toasts
	);

	return (
		<StyledToasts>
			{toasts.map((toast: IToastMessage, index) => (
				<ToastMessage
					key={toast.id}
					id={toast.id}
					title={toast.title}
					type={toast.type}
					message={toast.message}
				/>
			))}
		</StyledToasts>
	);
};

export default Toasts;
