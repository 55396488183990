import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';

import dayjs from 'helpers/dayjs.helper';

interface IComponentProps {
	time?: Date | string;
	interval?: number;
}
const TimeSince: FunctionComponent<IComponentProps> = ({
	time,
	interval = 20000,
}) => {
	const [displayValue, setDisplayValue] = useState('');

	const date = useMemo(
		() =>
			typeof time !== 'undefined' &&
			// Prevents the component from rendering a message for a time in the future
			Math.min(new Date(time).getTime(), Date.now() - 1),
		[time]
	);

	useEffect(() => {
		const update = () => setDisplayValue(date ? dayjs(date).fromNow() : '');
		const intervalRef = setInterval(update, interval);
		update();
		return () => clearInterval(intervalRef);
	}, [interval, date]);

	return displayValue ? <span>{displayValue}</span> : null;
};

export default TimeSince;
