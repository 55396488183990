import currency from 'currency.js';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { IReportData } from '../../report.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

interface IComponentProps {
	reportData?: IReportData[];
}

const StyledResults = styled.div`
	display: grid;
	grid-auto-flow: row;
	overflow: auto;
`;

const StyledHeadings = styled.div`
	display: flex;
	align-items: center;
	background: ${brand.black};
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.line_height.large};
	user-select: none;
	padding: 7px 5px;
`;

const StyledHeading = styled.div`
	color: ${brand.white};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.medium};
	display: flex;
	align-items: center;
	min-width: 85px;
	margin: 0 10px 0 0;
	user-select: none;
	width: calc(12%);

	:focus {
		outline: none;
	}

	&:nth-child(2),
	&:nth-child(3) {
		min-width: 165px;
	}

	&:nth-child(7),
	&:nth-child(8) {
		min-width: 121px;
	}
`;

const StyledData = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		padding: 7px 5px;
		margin: 0;
		display: flex;

		&:nth-child(2n + 2) {
			background: ${brand.neutral};
		}

		> div {
			display: flex;
			align-items: center;
			min-width: 85px;
			margin: 0 10px 0 0;
			user-select: none;
			width: calc(12%);

			:focus {
				outline: none;
			}

			&:nth-child(2),
			&:nth-child(3) {
				min-width: 165px;
			}

			&:nth-child(7),
			&:nth-child(8) {
				min-width: 121px;
			}
		}
	}
`;

const GiftExpiryReportResults: FunctionComponent<IComponentProps> = ({
	reportData,
}) => {
	const headings: string[] = [
		'giftCode',
		'account',
		'brand',
		'dateCreated',
		'expiryDate',
		'giftAmount',
		'amountRedeemed',
		'amountExpired',
	];

	const mappedHeadings = headings.map((heading: string) => (
		<StyledHeading key={heading}>
			<FormattedMessage id={`reports.results.headings.${heading}`} />
		</StyledHeading>
	));

	return (
		<StyledResults>
			<StyledHeadings>{mappedHeadings}</StyledHeadings>
			<StyledData>
				{reportData?.map((data) => (
					<li key={data.identifier}>
						<div>{data.code}</div>
						<div>{data.account}</div>
						<div>{data.brand}</div>
						<div>{data.created}</div>
						<div>{data.expires}</div>
						<div>
							{data?.amount
								? currency(data.amount).format({ symbol: '£' })
								: '£0.00'}
						</div>
						<div>
							{data?.amountRedeemed
								? currency(data.amountRedeemed).format({
									symbol: '£',
								  })
								: '£0.00'}
						</div>
						<div>
							{data?.amountExpired
								? currency(data.amountExpired).format({
									symbol: '£',
								  })
								: '£0.00'}
						</div>
					</li>
				))}
			</StyledData>
		</StyledResults>
	);
};

export default GiftExpiryReportResults;
