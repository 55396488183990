import { Formik, Field, Form, FormikHelpers } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import DisplayValue from 'components/form-inputs/display-value/display-value.component';
import Switch from 'components/form-inputs/switch/switch.component';
import { getVenueOrderingUrl } from 'helpers/get-venue-ordering-url.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';
import {
	refreshVenueMenuCache,
	getVenueConfig,
	updateVenuePublish,
} from 'modules/venue/slices/venue.slice';
import { IVenuePublishFormValues } from 'modules/venue/venue.types';

const StyledDisplayValue = styled(DisplayValue)`
	justify-content: flex-start;

	> div {
		&:first-child {
			padding-left: 100px;
		}
	}
`;

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	handleDelete: Function;
	venueId: string;
}

// Venue publish form
const VenuePublishForm: FunctionComponent<IComponentProps> = ({
	handleDelete,
	venueId,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active venue publish from store
	const activeVenuePublish: IVenuePublishFormValues | undefined = useSelector(
		(state: RootState) => state?.venue?.activeVenuePublish
	);

	useEffect(() => {
		// Get venue data by venue id
		const getVenueData = async (id: string) => {
			await dispatch(getVenueConfig(id));
		};
		getVenueData(venueId);
	}, [venueId, dispatch]);

	// Initial form values
	const initialValues: IVenuePublishFormValues = {
		isPublished: (venueId && activeVenuePublish?.isPublished) || false,
		isPublishedForWeb:
			(venueId && activeVenuePublish?.isPublishedForWeb) || false,
		isTestVenue: (venueId && activeVenuePublish?.isTestVenue) || false,
	};

	// Handle form submission
	const handleSubmit = async (
		values: IVenuePublishFormValues,
		{ setSubmitting }: FormikHelpers<IVenuePublishFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);
		// Update venue
		const response = await dispatch(
			updateVenuePublish(venueId, {
				isPublished: values.isPublished,
				isPublishedForWeb: values.isPublishedForWeb,
				isTestVenue: values.isTestVenue,
			})
		);

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}
		// Show success alert
		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.alerts.updated.message',
				}),
				type: 'success',
			})
		);
		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);
	};

	const handleCacheRefresh = async () => {
		// Confirm user wishes to bust menu cache
		const confirmBust = await fireDialog({
			title: intl.formatMessage({
				id: 'venueForm.publish.dialogs.confirmRefresh.title',
			}),
			text: intl.formatMessage({
				id: 'venueForm.publish.dialogs.confirmRefresh.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmBust.value) {
			return;
		}

		// Delete action
		const response =
			venueId && (await dispatch(refreshVenueMenuCache(venueId)));

		// Return if fail
		if (!response) {
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'venueForm.publish.alerts.confirmRefresh.message',
				}),
				type: 'success',
			})
		);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="venueForm.headings.publish" />
					</h2>

					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Switch}
								name="isPublished"
								label={intl.formatMessage({
									id: 'form.fields.publish.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.publish.on',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.publish.off',
								})}
							/>
							<Field
								component={Switch}
								name="isPublishedForWeb"
								label={intl.formatMessage({
									id: 'form.fields.publish-web.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.publish-web.on',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.publish-web.off',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Switch}
								name="isTestVenue"
								label={intl.formatMessage({
									id: 'form.fields.testVenue.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.testVenue.on',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.testVenue.off',
								})}
							/>
							<StyledDisplayValue
								label={intl.formatMessage({
									id: 'form.fields.testVenue.url.label',
								})}
								value={getVenueOrderingUrl(venueId)}
								copyValue
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledActions>
						<div>
							<Link to="/venues">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{venueId && (
								<Button
									variant="secondary"
									onClick={() => {
										handleDelete(setFormSubmission);
									}}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="venueForm.button.delete" />
								</Button>
							)}
						</div>
						<div>
							<Button
								onClick={handleCacheRefresh}
								ariaLabel="refresh-button"
							>
								<FormattedMessage id="venueForm.publish.button.refreshMenu" />
							</Button>
							<Button
								type="submit"
								disabled={isSubmitting}
								ariaLabel="submit-button"
							>
								<FormattedMessage id="form.button.save" />
							</Button>
						</div>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default VenuePublishForm;
