import { FieldProps } from 'formik';
import { rgba } from 'polished';
import React, { FunctionComponent, ReactHTMLElement } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as ReactSelect, Theme } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { isArray } from 'util';

import FieldLabel from '../field-label/field-label.component';
import { IToolTip } from '../form-tooltip/form-tooltip.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FieldError from 'components/field-error/field-error.component';
import FormElement from 'components/form-element/form-element.component';
import { intl } from 'modules/core/i18n/i18n.config';

export interface IOption {
	label?: string;
	value?: string | boolean | number | null;
}

// Interface for component props
interface ISelectProps extends ReactHTMLElement<HTMLSelectElement> {
	label?: string;
	handleChangeEvent?: Function;
	hideInitialValue?: boolean;
	initialValue?: string;
	isClearable?: boolean;
	isDisabled?: boolean;
	isMulti?: boolean;
	placeholder?: string;
	selectOptions: IOption[];
	value?: IOption | IOption[];
	formElementVariant?: 'header';
	size?: 'sm';
	testId?: string;
	className?: string;
	isAsync?: boolean;
	loadOptions?: Function;
	defaultOptions?: IOption[];
	toolTip?: IToolTip;
}

// Create styled select component
const StyledSelect = styled(ReactSelect)<ISelectProps>`
	width: 100%;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	font-size: ${fonts.sizes.standard};

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}

	[class$='control'] {
		min-height: ${({ size }) => (size === 'sm' ? '35px' : '40px')};
		border: none;
		border-radius: 5px;
		box-shadow: none;
	}

	.react-select__option--is-selected {
		font-weight: ${fonts.weights.medium};
	}

	.react-select__multi-value,
	.react-select__multi-value__label {
		border-radius: 5px;
		font-size: 100%;
	}

	.react-select__indicator {
		padding: 6px 8px;
	}
`;

const StyledAsyncSelect = styled(AsyncSelect)<ISelectProps>`
	width: 100%;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	font-size: ${fonts.sizes.standard};

	&.has-error {
		background: ${rgba(brand.validation_error, 0.05)};
		border-color: ${brand.validation_error};
	}

	[class$='control'] {
		min-height: ${({ size }) => (size === 'sm' ? '35px' : '40px')};
		border: none;
		border-radius: 5px;
		box-shadow: none;
	}

	.react-select__option--is-selected {
		font-weight: ${fonts.weights.medium};
	}

	.react-select__multi-value,
	.react-select__multi-value__label {
		border-radius: 5px;
		font-size: 100%;
	}

	.react-select__indicator {
		padding: 6px 8px;
	}
`;

/** Renders Select component */
const Select: FunctionComponent<FieldProps & ISelectProps> = ({
	field,
	form,
	label,
	isClearable,
	isDisabled,
	isMulti,
	placeholder,
	selectOptions,
	handleChangeEvent,
	formElementVariant,
	value,
	size,
	testId,
	className,
	isAsync = false,
	loadOptions,
	defaultOptions,
	toolTip,
}) => {
	const { touched, errors } = form;
	const { name } = field;
	const isTouched = touched?.[`${field.name}`] || false;
	const hasErrors = errors?.[`${field.name}`] || false;

	return (
		<FormElement
			variant={formElementVariant}
			testId={testId}
			className={className}
			isDisabled={isDisabled}
		>
			{label && (
				<FieldLabel
					fieldName={field.name}
					label={label}
					toolTip={toolTip}
				/>
			)}
			{isAsync ? (
				<StyledAsyncSelect
					{...name}
					defaultOptions={defaultOptions}
					size={size}
					classNamePrefix="react-select"
					className={isTouched && hasErrors && 'has-error'}
					isMulti={isMulti}
					isClearable={isClearable}
					isDisabled={isDisabled}
					aria-label={`select-${field.name}`}
					loadOptions={loadOptions}
					placeholder={
						placeholder ||
						intl.formatMessage({ id: 'form.select.default' })
					}
					onChange={(option: IOption | IOption[]) => {
						// Update formik field
						form.setFieldValue(
							field.name,
							isArray(option) ? option : option?.value
						);

						// Set field as touched within timeout due to formik race condition
						setTimeout(
							() => form.setFieldTouched(field.name, true, true),
							1
						);

						// Run custom change event function
						handleChangeEvent &&
							handleChangeEvent({
								fieldValue: isArray(option)
									? option
									: option?.value,
								form,
							});
					}}
					theme={(theme: Theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: rgba(brand.primary, 0.25),
							primary75: rgba(brand.primary, 0.75),
							primary50: rgba(brand.primary, 0.5),
							primary: brand.primary,
						},
					})}
					value={value}
				/>
			) : (
				<StyledSelect
					{...name}
					size={size}
					classNamePrefix="react-select"
					className={isTouched && hasErrors && 'has-error'}
					isMulti={isMulti}
					isClearable={isClearable}
					isDisabled={isDisabled}
					aria-label={`select-${field.name}`}
					options={selectOptions}
					placeholder={
						placeholder ||
						intl.formatMessage({ id: 'form.select.default' })
					}
					onChange={(option: IOption | IOption[]) => {
						// Update formik field
						form.setFieldValue(
							field.name,
							isArray(option) ? option : option?.value
						);

						// Set field as touched within timeout due to formik race condition
						setTimeout(
							() => form.setFieldTouched(field.name, true, true),
							1
						);

						// Run custom change event function
						handleChangeEvent &&
							handleChangeEvent({
								fieldValue: isArray(option)
									? option
									: option?.value,
								form,
							});
					}}
					theme={(theme: Theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: rgba(brand.primary, 0.25),
							primary75: rgba(brand.primary, 0.75),
							primary50: rgba(brand.primary, 0.5),
							primary: brand.primary,
						},
					})}
					value={value}
				/>
			)}

			{isTouched && hasErrors && (
				<FieldError ariaLabel={`${field.name}-error`}>
					{hasErrors}
				</FieldError>
			)}
		</FormElement>
	);
};

export default Select;
