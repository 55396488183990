import { FieldProps } from 'formik';
import Fuse from 'fuse.js';
import React, {
	FunctionComponent,
	useState,
	ChangeEvent,
	useEffect,
} from 'react';
import { AutoSizer, List } from 'react-virtualized';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

import { ICheckListItem } from './checklist.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import FormElement from 'components/form-element/form-element.component';
import Icon from 'components/icons/icon.component';
import { intl } from 'modules/core/i18n/i18n.config';

// Interface for main component props
interface IComponentProps {
	label?: string;
	items: ICheckListItem[];
}

const StyledFormElement = styled(FormElement)`
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
`;

const StyledLabel = styled.label`
	margin-bottom: 10px;
	display: block;
	line-height: ${fonts.line_height.med};
	font-size: ${fonts.sizes.med};
	font-weight: ${fonts.weights.regular};
`;

const StyledCheckList = styled.div`
	height: 400px;
	user-select: none;
	display: block;

	.ReactVirtualized__List:focus {
		outline: none;
	}
`;

const StyledCheckboxWrapper = styled.div`
	display: flex;
`;

const StyledCheckbox = styled.label`
	width: 18px;
	height: 18px;
	margin-right: 10px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${brand.body};
	position: relative;
	overflow: hidden;
	cursor: pointer;
	user-select: none;

	input {
		width: 0;
		height: 0;
		opacity: 0;
		cursor: pointer;
		position: absolute;
	}
`;

const StyledChecked = styled.div`
	width: 100%;
	height: 100%;
	background: ${brand.primary};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledCheckboxLabel = styled.label`
	display: block;
	font-size: ${fonts.sizes.standard};
	font-weight: ${fonts.weights.regular};
	line-height: 18px;
	user-select: none;
`;

const StyledSearchWrapper = styled.div`
	position: relative;
	margin-bottom: 9px;
`;

const StyledInput = styled.input`
	width: 100%;
	height: 35px;
	line-height: 35px;
	padding: 0 14px;
	border: 1px solid ${brand.borders};
	border-radius: 5px;
	font-size: ${fonts.sizes.small};
	background: ${brand.neutral};

	&::placeholder {
		color: ${brand.placeholder};
	}

	&:focus {
		border-color: ${brand.link};
		outline: none;
	}
`;

const StyledIcon = styled(Icon)`
	height: 100%;
	position: absolute;
	right: 10px;
	bottom: 0;
`;

const CheckList: FunctionComponent<IComponentProps & FieldProps> = ({
	form,
	field,
	label,
	items,
}) => {
	// Store list items in component state
	const [listItems, setListItems] = useState(items);

	// Store search query in component state
	const [searchQuery, setSearchQuery] = useState('');
	// Debounce searchQuery changes
	const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

	/** Searchterm list filtering */
	useEffect(() => {
		if (debouncedSearchQuery) {
			// Create fuse fuzzy search
			const fuse = new Fuse(items, {
				threshold: 0.1,
				keys: ['title'],
			});

			// Update list items
			setListItems(fuse.search(debouncedSearchQuery).map((i) => i.item));
		} else {
			// Reset list items
			setListItems(items);
		}

		// Only run on search query change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchQuery]);

	/** Hand click event on checkbox */
	const handleCheckboxClick = (itemId: string) => {
		//  Search through field.value array for our itemId
		const updatedFieldValue = field.value.some(
			(fieldItem: string) => itemId === fieldItem
		)
			? // If we found it - remove it
			  field.value.filter((fieldItem: string) => itemId !== fieldItem)
			: // Else - add it
			  [...field.value, itemId];

		// Update the field value
		form.setFieldValue(field.name, updatedFieldValue);
	};

	// Interface for check list item props
	interface IChecklistItemProps {
		key: string;
		index: number;
		isScrolling: boolean;
		isVisible: boolean;
		style: Object;
	}

	/** Item in list */
	const CheckListItem = ({
		key,
		index,
		isScrolling,
		isVisible,
		style,
	}: IChecklistItemProps) => (
		<StyledCheckboxWrapper
			data-testid="checklist-item"
			key={key}
			style={style}
			onClick={() => {
				handleCheckboxClick(listItems[index].id);
			}}
		>
			<StyledCheckbox>
				{field.value.includes(listItems[index].id) && (
					<StyledChecked>
						<Icon
							name="checkbox"
							colour="white"
							width={18}
							height={18}
						/>
					</StyledChecked>
				)}
			</StyledCheckbox>
			<StyledCheckboxLabel>{listItems[index].title}</StyledCheckboxLabel>
		</StyledCheckboxWrapper>
	);

	return (
		<StyledFormElement testId="checklist">
			{label && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
			<StyledSearchWrapper>
				<StyledInput
					data-testid="checklist-search"
					onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
						setSearchQuery(changeEvent.target.value);
					}}
					placeholder={intl.formatMessage({
						id: 'form.search.placeholder',
					})}
				/>
				<StyledIcon name="search" width={12} height={12} />
			</StyledSearchWrapper>
			<StyledCheckList>
				<AutoSizer>
					{({ height, width }) => (
						<List
							width={width || 400}
							height={height || 400}
							rowCount={listItems.length}
							rowHeight={33}
							rowRenderer={CheckListItem}
						/>
					)}
				</AutoSizer>
			</StyledCheckList>
		</StyledFormElement>
	);
};

export default CheckList;
