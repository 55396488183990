import React, { ReactElement, PropsWithChildren } from 'react';
import { RawIntlProvider } from 'react-intl';

import { intl } from './i18n.config';

// Interface for component props
interface iI18nProviderProps {}

/** Higher order component for providing locale */
const I18nProvider = (
	props: PropsWithChildren<iI18nProviderProps>
): ReactElement => (
	<RawIntlProvider value={intl}>{props.children}</RawIntlProvider>
);

export default I18nProvider;
