import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ValidationResultItem from './validation-result-item.component';
import { StyledTable } from './venue-menu-validation.styles';

import { IWorkflowRunDetails } from 'modules/venue/venue-menu-validation.types';

interface IComponentProps {
	runs?: IWorkflowRunDetails[];
}

const ValidationResults: FunctionComponent<IComponentProps> = ({ runs }) => {
	const [openResult, setOpenResult] = useState<string>();

	return (
		<>
			{runs && (
				<>
					{runs.length < 1 ? (
						<div>
							<p>
								<FormattedMessage id="menuValidation.validationResults.noneFound" />
							</p>
						</div>
					) : (
						<StyledTable>
							<thead>
								<tr>
									<th scope="col" className="stretch">
										<FormattedMessage id="menuValidation.validationResults.header.date" />
									</th>
									<th scope="col">
										<FormattedMessage id="menuValidation.validationResults.header.status" />
									</th>
									<th scope="col">
										<FormattedMessage id="menuValidation.validationResults.header.valid" />
									</th>
									<th scope="col">
										<FormattedMessage id="menuValidation.validationResults.header.warnings" />
									</th>
									<th scope="col">
										<FormattedMessage id="menuValidation.validationResults.header.errors" />
									</th>
									<td />
									<td className="slim" />
								</tr>
							</thead>
							<tbody>
								{runs.map((item) => (
									<ValidationResultItem
										key={item.validationTestId}
										run={item}
										open={
											item.validationTestId === openResult
										}
										onViewDetails={() => {
											setOpenResult(
												item.validationTestId ===
													openResult
													? ''
													: item.validationTestId
											);
										}}
									/>
								))}
							</tbody>
						</StyledTable>
					)}
				</>
			)}
		</>
	);
};

export default ValidationResults;
