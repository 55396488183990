import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ModalProvider } from 'styled-react-modal';

import ErrorBoundary from './modules/core/error/error-boundary.component';
import I18nProvider from './modules/core/i18n/i18n-provider.component';
import AppRouter from './modules/core/routing/app-router.component';
import store, { persistor } from './modules/core/state/store.config';

import Alert from 'components/alert/alert.component';
import Loading from 'components/loading/loading.component';
import { modalBackground } from 'components/modal/modal.component';
import Toasts from 'components/toast/toast.component';
import PusherComponent from 'modules/core/pusher/pusher.component';
import StoreVersion from 'modules/core/state/store-version/store-version.component';

/** Renders app component */
const App: FunctionComponent = () => (
	<ErrorBoundary>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<I18nProvider>
					<ModalProvider backgroundComponent={modalBackground}>
						<PusherComponent>
							<StoreVersion />
							<Alert />
							<Loading />
							<Toasts />
							<AppRouter />
						</PusherComponent>
					</ModalProvider>
				</I18nProvider>
			</PersistGate>
		</Provider>
	</ErrorBoundary>
);

export default App;
