import { Formik, Field, Form, FormikHelpers } from 'formik';
import { History } from 'history';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { object, string, boolean } from 'yup';

import { updateBrand, createBrand, deleteBrand } from '../../brand.slice';
import BrandColourExample from '../brand-colour-example/brand-colour-example.component';

import brand from 'assets/styles/variables/brand';
import { addAlert } from 'components/alert/alert.slice';
import Button from 'components/button/button.component';
import ColourPicker from 'components/form-inputs/colour-picker/colour-picker.component';
import DisplayValue from 'components/form-inputs/display-value/display-value.component';
import ImageUpload from 'components/form-inputs/image-upload/image-upload.component';
import Input from 'components/form-inputs/input/input.component';
import Select, {
	IOption,
} from 'components/form-inputs/select/select.component';
import Switch from 'components/form-inputs/switch/switch.component';
import TextArea from 'components/form-inputs/text-area/text-area.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	genericValidationString,
	genericValidationNumber,
} from 'helpers/validation.helper';
import { getAccountsList } from 'modules/account/account.slice';
import { IAccount } from 'modules/account/account.types';
import { IBrandDetailsFormValues } from 'modules/brand/brand.types';
import formatFullBrandFromFormValues from 'modules/brand/helpers/format-full-brand-from-form-values.helper';
import formatFullBrand from 'modules/brand/helpers/format-full-brand.helper';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { validationMessages } from 'modules/core/i18n/i18n-validation.helper';
import { intl } from 'modules/core/i18n/i18n.config';
import { RootState } from 'modules/core/state/root.reducer';

const StyledForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledFieldGroup = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledColumn = styled.div`
	width: calc(50% - 30px);
`;

const StyledH2 = styled.h2`
	margin-top: 40px;
`;

const StyledActions = styled.div`
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid ${brand.borders};
	display: flex;
	align-items: center;
	justify-content: space-between;

	Button:first-child {
		margin-right: 20px;
	}
`;

interface IComponentProps {
	brandId?: string;
	history: History;
}

// Brand form page
const BrandDetailsForm: FunctionComponent<IComponentProps> = ({
	brandId,
	history,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();
	// Variable for when form is submitted
	const [formSubmission, setFormSubmission] = useState(false);

	// Get active brand from store
	const activeBrand: IBrandDetailsFormValues | undefined = useSelector(
		(state: RootState) =>
			state.brand.activeBrand && formatFullBrand(state.brand.activeBrand)
	);

	useEffect(() => {
		// Get brand account list
		const getData = async (id: string | undefined) => {
			await dispatch(getAccountsList());
		};
		getData(brandId);
	}, [brandId, dispatch]);

	// Get accounts from store and prepare for select
	const accountOptions: IOption[] = useSelector(
		(state: RootState) => state.account.accounts
	).map((value: IAccount) => ({
		label: value.name,
		value: value.id,
	}));

	// Initial form values
	const initialValues: IBrandDetailsFormValues = {
		name: (brandId && activeBrand?.name) || '',
		vatNo: (brandId && activeBrand?.vatNo) || '',
		description: (brandId && activeBrand?.description) || '',
		website: (brandId && activeBrand?.website) || '',
		logoUrl: (brandId && activeBrand?.logoUrl) || '',
		coverUrls: (brandId && activeBrand?.coverUrls) || [],
		showDiscountMessage:
			(brandId && activeBrand?.showDiscountMessage) || false,
		campaignEventName: (brandId && activeBrand?.campaignEventName) || '',
		bgPrimary: (brandId && activeBrand?.bgPrimary) || '#000000',
		lblPrimary: (brandId && activeBrand?.lblPrimary) || '#FFFFFF',
		zonalApiKey: (brand && activeBrand?.zonalApiKey) || '',
		zonalBundleIdentifier:
			(brand && activeBrand?.zonalBundleIdentifier) || '',
		zonalEstateId: (brand && activeBrand?.zonalEstateId) || '',
		zonalUserAgent: (brand && activeBrand?.zonalUserAgent) || '',
		vatConfig: {
			standardRate:
				(brandId && activeBrand?.vatConfig?.standardRate) || '20',
			reducedRate:
				(brandId && activeBrand?.vatConfig?.reducedRate) || '5',
			zeroRate: (brandId && activeBrand?.vatConfig?.zeroRate) || '0',
		},
		hideMenuImages: (brandId && activeBrand?.hideMenuImages) || false,
		hideVenueOpeningTimes:
			(brandId && activeBrand?.hideVenueOpeningTimes) || false,
		isBrandCreate: !brandId,
	};

	// If form is in create mode
	if (!brandId) {
		// Set default value for accountUUID
		initialValues.accountUUID = '';
	}

	// Interface for form validation
	interface IFormValdation {
		accountUUID?: string;
		name: string;
		description: string;
		website: string;
		showDiscountMessage: boolean;
		bgPrimary: string;
		lblPrimary: string;
		zonalApiKey?: string;
		zonalBundleIdentifier?: string;
		zonalEstateId?: string;
		zonalUserAgent?: string;
		vatConfig?: {
			standardRate?: number;
			reducedRate?: number;
			zeroRate?: number;
		};
	}

	// Login validation schema
	const formValidationSchema = object<IFormValdation>().shape(
		{
			accountUUID: string().when('isBrandCreate', {
				is: true,
				then: genericValidationString({
					fieldName: 'accountUuid',
				}),
			}),
			name: genericValidationString({
				fieldName: 'name',
			}),
			description: string().required(
				validationMessages.required('description')
			),
			website: string().required(validationMessages.required('website')),
			zonalApiKey: string().when(
				['zonalBundleIdentifier', 'zonalUserAgent', 'zonalEstateId'],
				{
					is: (
						zonalBundleIdentifier,
						zonalUserAgent,
						zonalEstateId
					) =>
						!!zonalBundleIdentifier ||
						!!zonalUserAgent ||
						!!zonalEstateId,
					then: genericValidationString({ fieldName: 'zonalApiKey' }),
				}
			),
			zonalBundleIdentifier: string().when(
				['zonalApiKey', 'zonalUserAgent', 'zonalEstateId'],
				{
					is: (zonalApiKey, zonalUserAgent, zonalEstateId) =>
						!!zonalApiKey || !!zonalUserAgent || !!zonalEstateId,
					then: genericValidationString({
						fieldName: 'zonalBundleIdentifier',
					}),
				}
			),
			zonalUserAgent: string().when(
				['zonalApiKey', 'zonalBundleIdentifier', 'zonalEstateId'],
				{
					is: (zonalApiKey, zonalBundleIdentifier, zonalEstateId) =>
						!!zonalApiKey ||
						!!zonalBundleIdentifier ||
						!!zonalEstateId,
					then: genericValidationString({
						fieldName: 'zonalUserAgent',
					}),
				}
			),
			zonalEstateId: string().when(
				['zonalApiKey', 'zonalBundleIdentifier', 'zonalUserAgent'],
				{
					is: (zonalApiKey, zonalBundleIdentifier, zonalUserAgent) =>
						!!zonalApiKey ||
						!!zonalBundleIdentifier ||
						!!zonalUserAgent,
					then: genericValidationString({
						fieldName: 'zonalEstateId',
					}),
				}
			),
			vatConfig: object().shape({
				standardRate: genericValidationNumber({
					fieldName: 'vatConfig.standardRate',
					min: 0,
					max: 100,
				}),
				reducedRate: genericValidationNumber({
					fieldName: 'vatConfig.reducedRate',
					min: 0,
					max: 100,
				}),
				zeroRate: genericValidationNumber({
					fieldName: 'vatConfig.zeroRate',
					min: 0,
					max: 100,
				}),
			}),
			bgPrimary: genericValidationString({
				fieldName: 'backgroundPrimary',
				hexValidation: true,
			}),
			lblPrimary: genericValidationString({
				fieldName: 'labelPrimary',
				hexValidation: true,
			}),
			isBrandCreate: boolean(),
			showDiscountMessage: boolean(),
		},
		[
			['zonalApiKey', 'zonalBundleIdentifier'],
			['zonalApiKey', 'zonalUserAgent'],
			['zonalApiKey', 'zonalEstateId'],
			['zonalBundleIdentifier', 'zonalUserAgent'],
			['zonalBundleIdentifier', 'zonalEstateId'],
			['zonalUserAgent', 'zonalEstateId'],
		]
	);

	// Handle form submission
	const handleSubmit = async (
		values: IBrandDetailsFormValues,
		{ setSubmitting }: FormikHelpers<IBrandDetailsFormValues>
	) => {
		// Set formik submission state to true
		setSubmitting(true);

		const brandDetails = formatFullBrandFromFormValues(values);

		// Create / update brand
		const response = await (brandId
			? dispatch(updateBrand(brandId, brandDetails))
			: dispatch(createBrand(brandDetails)));

		// Return on fail
		if (!response) {
			// Set formik submission state to false
			setSubmitting(false);
			return;
		}

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: brandId
					? intl.formatMessage({
						id: 'brandForm.alerts.updated.message',
					  })
					: intl.formatMessage({
						id: 'brandForm.alerts.created.message',
					  }),
				type: 'success',
			})
		);

		// Set formik submission state to false
		setSubmitting(false);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// If we're creating - redirect user to brands list
		!brandId && history.push(`/brands/edit/${response?.id}/details`);
	};

	// Handle brand deletion
	const handleDelete = async () => {
		// Confirm user wishes to delete brand
		const confirmDelete = await fireDialog({
			title: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.title',
			}),
			text: intl.formatMessage({
				id: 'brandForm.dialogs.confirmDelete.text',
			}),
			showCancelButton: true,
		});

		// If user clicked cancel
		if (!confirmDelete.value) {
			return;
		}

		// Delete brand action
		brandId && (await dispatch(deleteBrand(brandId)));

		await dispatch(
			addAlert({
				title: intl.formatMessage({
					id: 'alerts.success.title',
				}),
				message: intl.formatMessage({
					id: 'brandForm.alerts.deleted.message',
				}),
				type: 'success',
			})
		);

		// Set form submission to true to remove routing prompt
		setFormSubmission(true);

		// Redirect user to brands page
		history.push('/brands');
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={formValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ dirty, isSubmitting }) => (
				<StyledForm>
					<Prompt when={dirty && !formSubmission} message="" />
					<h2>
						<FormattedMessage id="brandForm.headings.info" />
					</h2>
					<StyledFieldGroup>
						<StyledColumn>
							{!brandId ? (
								<div data-testid="accountUUID-select">
									<Field
										component={Select}
										isSearchable={true}
										label={intl.formatMessage({
											id: 'form.fields.account.label',
										})}
										name="accountUUID"
										selectOptions={accountOptions}
									/>
								</div>
							) : (
								<DisplayValue
									label={intl.formatMessage({
										id: 'form.fields.accountName.label',
									})}
									value={activeBrand?.account?.name}
									link={`/accounts/edit/${activeBrand?.account?.id}/details`}
								/>
							)}
							<Field
								component={Input}
								name="name"
								label={intl.formatMessage({
									id: 'form.fields.brandName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.brandName.label',
								})}
							/>
							<DisplayValue
								label={intl.formatMessage({
									id: 'form.fields.brandId.label',
								})}
								value={brandId}
								copyValue={true}
							/>
							<Field
								component={TextArea}
								name="description"
								label={intl.formatMessage({
									id: 'form.fields.description.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.description.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="website"
								label={intl.formatMessage({
									id: 'form.fields.website.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.website.label',
								})}
							/>
							<Field
								component={Input}
								name="vatNo"
								label={intl.formatMessage({
									id: 'form.fields.vatNo.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.vatNo.label',
								})}
								isPrivate={true}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledH2>
						<FormattedMessage id="brandForm.headings.configuration" />
					</StyledH2>
					<StyledFieldGroup data-private={true}>
						<StyledColumn>
							<Field
								component={Input}
								name="zonalApiKey"
								label={intl.formatMessage({
									id: 'form.fields.zonalApiKey.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.zonalApiKey.label',
								})}
							/>
							<Field
								component={Input}
								name="zonalUserAgent"
								label={intl.formatMessage({
									id: 'form.fields.zonalUserAgent.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.zonalUserAgent.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="zonalBundleIdentifier"
								label={intl.formatMessage({
									id:
										'form.fields.zonalBundleIdentifier.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.zonalBundleIdentifier.label',
								})}
							/>
							<Field
								component={Input}
								name="zonalEstateId"
								label={intl.formatMessage({
									id: 'form.fields.zonalEstateId.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.zonalEstateId.label',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledH2>
						<FormattedMessage id="brandForm.headings.vatConfig" />
					</StyledH2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Input}
								name="vatConfig.standardRate"
								type="number"
								label={intl.formatMessage({
									id:
										'form.fields.vatConfig.standardRate.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.vatConfig.standardRate.label',
								})}
							/>
							<Field
								component={Input}
								name="vatConfig.zeroRate"
								type="number"
								label={intl.formatMessage({
									id: 'form.fields.vatConfig.zeroRate.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.vatConfig.zeroRate.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Input}
								name="vatConfig.reducedRate"
								type="number"
								label={intl.formatMessage({
									id:
										'form.fields.vatConfig.reducedRate.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.vatConfig.reducedRate.label',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledH2>
						<FormattedMessage id="brandForm.headings.generalConfig" />
					</StyledH2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={Input}
								name="campaignEventName"
								label={intl.formatMessage({
									id: 'form.fields.campaignEventName.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.campaignEventName.label',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<Field
								component={Switch}
								name="showDiscountMessage"
								label={intl.formatMessage({
									id: 'form.fields.discountMessage.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.discountMessage.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.discountMessage.onText',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.discountMessage.offText',
								})}
							/>
						</StyledColumn>
					</StyledFieldGroup>
					<StyledH2>
						<FormattedMessage id="brandForm.headings.branding" />
					</StyledH2>
					<StyledFieldGroup>
						<StyledColumn>
							<Field
								component={ColourPicker}
								name="bgPrimary"
								label={intl.formatMessage({
									id: 'form.fields.backgroundPrimary.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.backgroundPrimary.label',
								})}
							/>
							<BrandColourExample />
							<Field
								component={Switch}
								name="hideMenuImages"
								label={intl.formatMessage({
									id: 'form.fields.hideMenuImages.label',
								})}
								placeholder={intl.formatMessage({
									id: 'form.fields.hideMenuImages.label',
								})}
								onText={intl.formatMessage({
									id: 'form.fields.hideMenuImages.onText',
								})}
								offText={intl.formatMessage({
									id: 'form.fields.hideMenuImages.offText',
								})}
							/>
							<Field
								component={Switch}
								name="hideVenueOpeningTimes"
								label={intl.formatMessage({
									id:
										'form.fields.hideVenueOpeningTimes.label',
								})}
								placeholder={intl.formatMessage({
									id:
										'form.fields.hideVenueOpeningTimes.label',
								})}
								onText={intl.formatMessage({
									id:
										'form.fields.hideVenueOpeningTimes.onText',
								})}
								offText={intl.formatMessage({
									id:
										'form.fields.hideVenueOpeningTimes.offText',
								})}
							/>
						</StyledColumn>
						<StyledColumn>
							<StyledFieldGroup>
								<StyledColumn>
									<Field
										component={ImageUpload}
										name="logoUrl"
										label={intl.formatMessage({
											id: 'form.fields.logoUrl.label',
										})}
										placeholder={intl.formatMessage({
											id: 'form.fields.logoUrl.label',
										})}
										uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/brand/images/logo`}
									/>
								</StyledColumn>
								<StyledColumn>
									<Field
										component={ImageUpload}
										name="coverUrls"
										label={intl.formatMessage({
											id: 'form.fields.coverUrls.label',
										})}
										placeholder={intl.formatMessage({
											id: 'form.fields.coverUrls.label',
										})}
										uploadEndpoint={`${process.env.REACT_APP_API_BASE_URL}/brand/images/cover`}
									/>
								</StyledColumn>
							</StyledFieldGroup>
						</StyledColumn>
					</StyledFieldGroup>

					<StyledActions>
						<div>
							<Link to="/brands">
								<Button variant="secondary">
									<FormattedMessage id="form.button.back" />
								</Button>
							</Link>
							{brandId && (
								<Button
									variant="secondary"
									onClick={handleDelete}
									ariaLabel="delete-button"
								>
									<FormattedMessage id="brandForm.button.delete" />
								</Button>
							)}
						</div>
						<Button
							type="submit"
							disabled={isSubmitting}
							ariaLabel="submit-button"
						>
							<FormattedMessage id="form.button.save" />
						</Button>
					</StyledActions>
				</StyledForm>
			)}
		</Formik>
	);
};

export default BrandDetailsForm;
